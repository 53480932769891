import {KundeDto} from "./kunde-dto";

export class Kunde {
  className: string = 'Kunde';

  id: string = '';
  name: string = '';

  map(dto: KundeDto) {
    this.name = dto.name || '';
  }
}
