import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericOAuth2, OAuth2AuthenticateOptions, OAuth2RefreshTokenOptions } from '@capacitor-community/generic-oauth2';
import { firstValueFrom } from 'rxjs';
import { TokenResponseDto } from 'src/app/data/auth/token-response-dto';
import { Environment } from 'src/app/utils/environment/environment';
import { StorageKeys, StorageService } from '../database/storage/storage.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isRefreshTokenActive: boolean = false;

  constructor(
    private userService: UserService,
    private storage: StorageService,
    private httpClient: HttpClient
  ) {
  }

  getAzureB2cOAuth2Options(): OAuth2AuthenticateOptions {
    return {
      appId: "a37ef292-8aee-44d1-8ac7-589ea027c1e2",
      authorizationBaseUrl: `https://tourdatatourdriverstage.b2clogin.com/tourdatatourdriverstage.onmicrosoft.com/B2C_1_SignUpSignIn/oauth2/v2.0/authorize`,
      scope: 'https://tourdatatourdriverstage.onmicrosoft.com/api/driver.default',
      accessTokenEndpoint: `https://tourdatatourdriverstage.b2clogin.com/tourdatatourdriverstage.onmicrosoft.com/B2C_1_SignUpSignIn/oauth2/v2.0/token`,
      responseType: 'code',
      pkceEnabled: true,
      logsEnabled: false, //TODO: Set true for debugging
      additionalParameters: {client_secret: 'J6w8Q~e~XODmoF.IOjljuq2ZLryOOYQAcw6Gea8a'},
      web: {
        redirectUrl: Environment.isLocalhost() ? "http://localhost:8100/auth" : "https://stage.webapp.tourdriver.ch/auth",
        windowOptions: 'location=no,toolbar=no,width=800,height=600',
      },
      android: {
        redirectUrl: 'msauth://ch.tourdata.tourdriver/TM1y1qAoNvtlqTd53hu50DEXzN4%3D',
      },
      ios: {
        pkceEnabled: true,
        redirectUrl: 'msauth.ch.tourdata.tourdriver://auth',
      },
    };
  }


  getAzureB2cOAuth2OptionsLogout(): OAuth2AuthenticateOptions {
    return {
      appId: "a37ef292-8aee-44d1-8ac7-589ea027c1e2",
      authorizationBaseUrl: `https://tourdatatourdriverstage.b2clogin.com/tourdatatourdriverstage.onmicrosoft.com/B2C_1_SignUpSignIn/oauth2/v2.0/logout`,
      scope: 'https://tourdatatourdriverstage.onmicrosoft.com/api/driver.default',
      accessTokenEndpoint: `https://tourdatatourdriverstage.b2clogin.com/tourdatatourdriverstage.onmicrosoft.com/B2C_1_SignUpSignIn/oauth2/v2.0/token`,
      responseType: 'code',
      pkceEnabled: true,
      logsEnabled: true,
      additionalParameters: {client_secret: 'J6w8Q~e~XODmoF.IOjljuq2ZLryOOYQAcw6Gea8a'},
      web: {
        redirectUrl: Environment.isLocalhost() ? "http://localhost:8100/auth" : "https://stage.webapp.tourdriver.ch/auth",
        windowTarget: '_blank',
      },
      android: {
        redirectUrl: 'msauth://ch.tourdata.tourdriver/TM1y1qAoNvtlqTd53hu50DEXzN4%3D',
      },
      ios: {
        pkceEnabled: true,
        redirectUrl: 'msauth.ch.tourdata.tourdriver://auth',
      },
    };
  }


  async getAzureB2cOAuth2RefreshOption(): Promise<OAuth2RefreshTokenOptions> {
    return {
      appId: "a37ef292-8aee-44d1-8ac7-589ea027c1e2",
      scope: 'https://tourdatatourdriverstage.onmicrosoft.com/api/driver.default',
      accessTokenEndpoint: `https://tourdatatourdriverstage.b2clogin.com/tourdatatourdriverstage.onmicrosoft.com/B2C_1_SignUpSignIn/oauth2/v2.0/token`,
      refreshToken: await this.storage.get(StorageKeys.REFRESH_TOKEN),
    };
  }

  async isAuthorized(): Promise<boolean> {
    var access_token = await this.storage.get(StorageKeys.ACCESS_TOKEN);

    if (this.tokenExpired(access_token)) {
      await this.refreshToken().then(async token => {
        if (token != "") {
          access_token = token;
        }
      })
    }
    return access_token != null && !this.tokenExpired(access_token);
  }

  async refreshToken(): Promise<string> {

    if(this.isRefreshTokenActive) {
      return "";
    }

    this.isRefreshTokenActive = true;
    var refreshToken = await this.storage.get(StorageKeys.REFRESH_TOKEN);
    if (refreshToken != null) {
      var url = `https://tourdatatourdriverstage.b2clogin.com/tourdatatourdriverstage.onmicrosoft.com/B2C_1_SignUpSignIn/oauth2/v2.0/token`;
      let body = new URLSearchParams();
      body.set('grant_type', 'refresh_token');
      body.set('client_id', 'a37ef292-8aee-44d1-8ac7-589ea027c1e2');
      body.set('scope', 'https://tourdatatourdriverstage.onmicrosoft.com/api/driver.default');
      body.set('refresh_token', refreshToken);
      body.set('redirect_uri', Environment.isLocalhost() ? "http://localhost:8100/auth" : "https://stage.webapp.tourdriver.ch/auth");

      let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      };


      var response = this.httpClient.post<TokenResponseDto>(url, body, options);
      var dto = await firstValueFrom(response);

      await this.storage.set(StorageKeys.ACCESS_TOKEN, dto.access_token);
      await this.storage.set(StorageKeys.REFRESH_TOKEN, dto.refresh_token);
      this.isRefreshTokenActive = false;
      return dto.access_token;
    }
    this.isRefreshTokenActive = false;
    return "";
  }

  async login(): Promise<boolean> {

    /*if (isDevMode()) {
      const accessTokenDev = 'eyJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiIzMWJjYWUzNS0yZGUzLTRlODktOWUyZC1kNjkwODY0YjY0NjciLCJpc3MiOiJodHRwczovL3RvdXJkYXRhdG91cmRyaXZlcnN0YWdlLmIyY2xvZ2luLmNvbS80NjEwOGE4Yy1iZTRmLTQyOTUtOTE2MS04NmRjMmFhMWNiZmUvdjIuMC8iLCJleHAiOjE3MjU1NDc4NDUsIm5iZiI6MTcyNTU0NDI0NSwib2lkIjoiZDBkNzc1NmUtMmUxMC00YzFkLWI1ZGItMzE5MDRkOTgxOGFmIiwic3ViIjoiZDBkNzc1NmUtMmUxMC00YzFkLWI1ZGItMzE5MDRkOTgxOGFmIiwiZW1haWxzIjpbIm5vYWgua3JpZXNpQHRvdXJkYXRhLmNoIl0sInRmcCI6IkIyQ18xX1NpZ25VcFNpZ25JbiIsInNjcCI6ImRyaXZlci5kZWZhdWx0IiwiYXpwIjoiYTM3ZWYyOTItOGFlZS00NGQxLThhYzctNTg5ZWEwMjdjMWUyIiwidmVyIjoiMS4wIiwiaWF0IjoxNzI1NTQ0MjQ1fQ.YMFXbrBwX8f6SHbKv1Dc3lrUb1gCMnYVBA4Gku1YMlZsiKsnboVkR3QH3CHyt3rHqjvTZ3z-Fh9YUjhUhcWjgsLhX_P8sl8XysDopeH6FfIWI4X7p_WDwHoUqT88WUxzaxSCLzOOByOtRGtUJN2yhdINsKbOF07KggklowMpNOx8REoQUXjxPdxubvyOs8y_CQsfOedFX534FMEasdbLEJ9WJ6r9VQ0db_ADd9V6echp3GPj2DCYh4i5a8phQYc2CwNCd5L4kbhT7htLHBh5w8oH1U-UazLrwDOWlvxYgeTefzdO_AZIQ7nRzoyuXbkceLcLVkr7T_SwIEAQEUtDMA';
      await this.storage.set(StorageKeys.ACCESS_TOKEN, accessTokenDev);
      return true;
    }*/

    return await GenericOAuth2.authenticate(this.getAzureB2cOAuth2Options())
      .then(async response => {
        const accessToken = response.access_token_response.access_token;
        await this.storage.set(StorageKeys.ACCESS_TOKEN, accessToken);

        const refreshToken = response.access_token_response.refresh_token;
        await this.storage.set(StorageKeys.REFRESH_TOKEN, refreshToken);

        return true;
      })
      .catch(reason => {
        console.error('OAuth rejected', reason);
        return false;
      });
  }

  async logout() {
    //await this.pushService.unregisterDevice();
    await GenericOAuth2.authenticate(this.getAzureB2cOAuth2OptionsLogout())
      .then(async response => {
        this.storage.remove(StorageKeys.ACCESS_TOKEN);
      })
      .catch(async reason => {
        this.storage.remove(StorageKeys.ACCESS_TOKEN);
      });
  }

  private tokenExpired(token: string) {
    if (token == null || token == "") {
      return true;
    }
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}

