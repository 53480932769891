
export class SQLiteHelper {

  static getType(value: any): string {

    var type: string = '';
    if (value instanceof ArrayBuffer) {
      type = DataTypes.ARRAYBUFFER;
    } else {
      type = typeof value;
    }
    return type;
  }


  static createTable(object: any): string {
    const instanceProps = Object.getOwnPropertyNames(object);

    const className: string = object['className'];
    let schema: string = '';

    instanceProps.forEach(prop => {
      const type: string = this.getType(object[prop]);

      if (prop === 'className') {
        return;
      }

      switch (type) {
        case DataTypes.STRING: {
          schema += `${prop} TEXT${prop === 'id' ? ' PRIMARY KEY' : ''}, `;
          break;
        }
        case DataTypes.NUMBER:
        case DataTypes.BOOLEAN: {
          schema += `${prop} INTEGER${prop === 'id' ? ' PRIMARY KEY' : ''}, `;
          break;
        }
        case DataTypes.ARRAYBUFFER: {
          schema += `${prop} BLOB, `;
          break;
        }
        default: {
          return;
        }
      }
    });

    schema = `CREATE TABLE IF NOT EXISTS ${className} (` + schema;

    schema = schema.slice(0, -2);
    schema += ');';
    return schema;
  }


  static insertOrUpdate(objects: any[], logsEnabled: boolean = false): { query: string, values: any[] } {

    if(logsEnabled) {
      console.log('SQLiteHelper.insertOrUpdate', objects);
    }

    if (objects.length === 0) {
      return {query: '', values: []};
    }

    const className: string = objects[0]['className'];
    let columns: string = '';
    let allPlaceholders: string = '';

    // Collect the column names from the first object
    const instanceProps = Object.getOwnPropertyNames(objects[0]);
    const validDataTypes = [DataTypes.STRING, DataTypes.NUMBER, DataTypes.BOOLEAN, DataTypes.ARRAYBUFFER];
    let columnNames: string[] = [];

    instanceProps.forEach(prop => {
      if (prop !== 'className') {
        const type = this.getType(objects[0][prop]) as DataTypes;
        if (validDataTypes.includes(type)) {
          columnNames.push(prop);
        }
      }
    });

    if (logsEnabled) {
      console.log('SQLiteHelper.insertOrUpdate', columnNames);
    }

    columns = columnNames.join(', ');
    let valuesList: any[] = [];

    objects.forEach(object => {
      let placeholders: string = '';
      const values: any[] = [];

      columnNames.forEach(prop => {
        const type: string = this.getType(object[prop]);

        switch (type) {
          case DataTypes.STRING:
          case DataTypes.NUMBER:
          case DataTypes.BOOLEAN: {
            values.push(object[prop]);
            break;
          }
          case DataTypes.ARRAYBUFFER: {
            //values.push(null);
            values.push(object[prop].byteLength > 0 ? new Uint8Array(object[prop]) : null);
            break;
          }
          default: {
            values.push(null);
            break;
          }
        }
        placeholders += `?, `;
      });

      // Trim the last comma and space
      placeholders = placeholders.slice(0, -2);
      allPlaceholders += `(${placeholders}), `;
      valuesList.push(...values);
    });

    // Trim the last comma and space
    allPlaceholders = allPlaceholders.slice(0, -2);

    const query = `INSERT OR REPLACE INTO ${className} (${columns}) VALUES ${allPlaceholders};`;
    return {query, values: valuesList};
  }

  static delete(tablename: string, where?: string): string {
    return `DELETE FROM ${tablename}` + (where ? ` WHERE ${where}` : '') + ';';
  }


  /*static createTable(object: any): string {
      const instanceProps = Object.getOwnPropertyNames(object);

      var className: string = object['className'];
      var shema: string = '';

      instanceProps.forEach(prop => {
          let type: string = this.getType(object[prop]);

          if (prop === 'className') {
              return;
          }

          switch (type) {
              case DataTypes.STRING: {
                  if (prop === 'id') {
                      shema += `${prop} TEXT PRIMARY KEY, `;
                  }
                  else {
                      shema += `${prop} TEXT, `;
                  }
                  break;
              }
              case DataTypes.NUMBER:
              case DataTypes.BOOLEAN: {
                  if (prop === 'id') {
                      shema += `${prop} INTEGER PRIMARY KEY, `;
                  }
                  else {
                      shema += `${prop} INTEGER, `;
                  }
                  break;
              }
              case DataTypes.ARRAYBUFFER: {
                  shema += `${prop} BLOB, `;
                  break;
              }
          }
      });

      shema = `CREATE TABLE IF NOT EXISTS ${className} (` + shema;

      shema = shema.slice(0, -2);
      shema += ');';

      console.log(shema);

      return shema;
  }*/


  /*static insertOrUpdate(object: any): string {
      const instanceProps = Object.getOwnPropertyNames(object);

      var className: string = object['className'];
      var columns: string = '';
      var values: string = '';

      instanceProps.forEach(prop => {
          let type: string = this.getType(object[prop]);

          if (prop === 'className') {
              return;
          }

          switch (type) {
              case DataTypes.STRING: {
                  columns += `${prop}, `;
                  values += `'${object[prop]}', `;
                  break;
              }
              case DataTypes.NUMBER:
              case DataTypes.BOOLEAN: {
                  columns += `${prop}, `;
                  values += `${object[prop]}, `;
                  break;
              }
              case DataTypes.ARRAYBUFFER: {
                  columns += `${prop}, `;
                  values += `'${object[prop]}', `;
                  break;
              }
          }
      });

      columns = columns.slice(0, -2);
      values = values.slice(0, -2);

      return `INSERT OR REPLACE INTO ${className} (${columns}) VALUES (${values});`;
  }*/

}


export enum DataTypes {
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
  ARRAYBUFFER = 'ArrayBuffer'
}
