import {MenuNavDto} from "./menu-nav-dto";

export class MenuNav {
  className = 'MenuNav'

  mandant: string = '';
  profilDatenVerfuegbar: boolean = false;
  kontenVerwaltenVerfuegbar: boolean = false;
  informationenVerfuegbar: boolean = false;
  hilfeVerfuegbar: boolean = false;
  neuigkeitenVerfuegbar: boolean = false;
  nachrichtenVerfuegbar: boolean = false;
  meineFarhtberichteVerfuegbar: boolean = false;
  nachrichtAnSupportVerfuegbar: boolean = false;

  map(dto: MenuNavDto) {
    this.profilDatenVerfuegbar = dto.profilDatenVerfuegbar;
    this.kontenVerwaltenVerfuegbar = dto.kontenVerwaltenVerfuegbar;
    this.informationenVerfuegbar = dto.informationenVerfuegbar;
    this.hilfeVerfuegbar = dto.hilfeVerfuegbar;
    this.neuigkeitenVerfuegbar = dto.neuigkeitenVerfuegbar;
    this.nachrichtenVerfuegbar = dto.nachrichtenVerfuegbar;
    this.meineFarhtberichteVerfuegbar = dto.meineFarhtberichteVerfuegbar;
    this.nachrichtAnSupportVerfuegbar = dto.nachrichtAnSupportVerfuegbar;
  }
}
