import {SachbearbeiterDto} from "./sachbearbeiter-dto";

export class Sachbearbeiter {
  className: string = 'Sachbearbeiter';

  id: string = '';
  name: string = '';
  telefonnummer: string = '';
  email: string = '';

  map(dto: SachbearbeiterDto) {
    this.name = dto.name || '';
    this.telefonnummer = dto.telefonnummer || '';
    this.email = dto.email || '';
  }
}
