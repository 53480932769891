import {IconDto} from "./icon-dto";

export class Icon {
  className = 'Icon'

  id: string = '';
  dateiName: string = '';
  iconBase64: string = '';
  iconType: number = 0;
  mandantenKuerzel: string = '';

  map(dto: IconDto) {
    this.id = dto.dateiName + dto.mandantenKuerzel;
    this.dateiName = dto.dateiName || '';
    this.iconBase64 = dto.iconBase64 || '';
    this.iconType = dto.iconType || 0;
    this.mandantenKuerzel = dto.mandantenKuerzel || '';
  }
}
