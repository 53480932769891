import { Injectable, signal, WritableSignal } from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import { MenuNav } from 'src/app/data/menu/menu-nav';
import { MenuNavDto } from 'src/app/data/menu/menu-nav-dto';
import { Menu } from 'src/app/data/menu/menu/menu';
import { MenuDto } from 'src/app/data/menu/menu/menu-dto';
import { MenuFileDto } from 'src/app/data/menu/menufile/menu-file-dto';
import { Nachricht } from 'src/app/data/menu/nachricht/nachricht';
import { NachrichtDto } from 'src/app/data/menu/nachricht/nachricht-dto';
import { DBCodes } from 'src/app/utils/DBCodes';
import { DatabaseService } from '../database/database.service';
import { StorageKeys, StorageService } from '../database/storage/storage.service';
import { HttpMethod, WebService } from '../web/web.service';
import {SupportNachrichtDto} from "../../data/menu/support/support-nachricht-dto";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private reloadMenuNavSubject = new Subject<boolean>();
  reloadMenuNav$ = this.reloadMenuNavSubject.asObservable();

  reloadMenuNavEvent(){
    this.reloadMenuNavSubject.next(true);
  }

  private _menuNav: WritableSignal<MenuNav> = signal(new MenuNav());
  menuNav = this._menuNav.asReadonly();

  private _menuInformation: WritableSignal<Menu[]> = signal([]);
  menuInformation = this._menuInformation.asReadonly();

  private _menuNeuigkeiten: WritableSignal<Menu[]> = signal([]);
  menuNeuigkeiten = this._menuNeuigkeiten.asReadonly();

  private _menuHilfe: WritableSignal<Menu[]> = signal([]);
  menuHilfe = this._menuHilfe.asReadonly();

  private _nachrichten: WritableSignal<Nachricht[]> = signal([]);
  nachrichten = this._nachrichten.asReadonly();

  constructor(
    private webService: WebService,
    private databaseService: DatabaseService,
    private storage: StorageService
  ) {
  }

  async loadMenuNav() {
    var menuNavs = await this.databaseService.loadTable<MenuNav>('MenuNav', undefined, `mandant = '${await this.storage.get(StorageKeys.CURRENT_MANDANT)}'`);

    if (menuNavs != null && menuNavs.length > 0) {
      this._menuNav.set(menuNavs[0]);
    }

  }

  async reloadMenuNav() {
    await this.webService.sendRequest<MenuNavDto>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Menu`, HttpMethod.GET).then(async response => {
      var dto = await firstValueFrom(response);

      if (dto) {
        var menuNav = new MenuNav();
        menuNav.map(dto);
        menuNav.mandant = await this.storage.get(StorageKeys.CURRENT_MANDANT) || '';

        await this.databaseService.insertOrUpdate([menuNav]);
        this._menuNav.set(menuNav);
        await this.loadMenuNav();
      }
    });
  }

  async loadMenus(menuType: number, parentMenu?: string) {
    var menus: Menu[] = [];

    if (parentMenu != undefined) {
      menus = await this.databaseService.loadTable<Menu>('Menu', 'bezeichnung', `mandant = '${await this.storage.get(StorageKeys.CURRENT_MANDANT)}' and parentId = '${parentMenu}'`);
    } else {
      menus = await this.databaseService.loadTable<Menu>('Menu', 'bezeichnung', `mandant = '${await this.storage.get(StorageKeys.CURRENT_MANDANT)}' and parentId = '' and mnCode = ${menuType}`);
    }

    if (menus != null) {

      switch (menuType) {
        case DBCodes.CDMN_CHAUFFEUR_INFO:
          this._menuInformation.set(menus);
          break;
        case DBCodes.CDMN_CHAUFFEUR_NEWS:
          this._menuNeuigkeiten.set(menus);
          break;
          break;
        case DBCodes.CDMN_CHAUFFEUR_HILFE:
          this._menuHilfe.set(menus);
          break;
        default:
          break;
      }

    }
  }

  async loadNachrichten() {
    var nachrichten = await this.databaseService.loadTable<Nachricht>('Nachricht', "datum DESC");

    if (nachrichten != null) {
      this._nachrichten.set(nachrichten);
    }
  }

  async reloadMenusAll() {

    await this.loadMenuNav();
    //TODO: Eventuell await einbauen
    if (this.menuNav().informationenVerfuegbar) {
      this.reloadMenus(DBCodes.CDMN_CHAUFFEUR_INFO);
    }

    if (this.menuNav().neuigkeitenVerfuegbar) {
      this.reloadMenus(DBCodes.CDMN_CHAUFFEUR_NEWS);
    }

    if (this.menuNav().hilfeVerfuegbar) {
      this.reloadMenus(DBCodes.CDMN_CHAUFFEUR_HILFE);
    }
  }

  async reloadMenus(menuType: number) {
    var path = '';

    switch (menuType) {
      case DBCodes.CDMN_CHAUFFEUR_INFO:
        path = 'informationen';
        break;
      case DBCodes.CDMN_CHAUFFEUR_NEWS:
        path = 'neuigkeiten';
        break;
      case DBCodes.CDMN_CHAUFFEUR_HILFE:
        path = 'hilfe';
        break;
      default:
        break;
    }

    const mandant = await this.storage.get(StorageKeys.CURRENT_MANDANT);

    await this.webService.sendRequest<MenuDto[]>(`${mandant}/Menu/${path}`, HttpMethod.GET)
      .then(async response => {

        try {
          const dtos = await firstValueFrom(response);

          if (dtos) {
            const allMenus: Menu[] = [];
            dtos.forEach(dto => {
              const menu = new Menu();
              menu.map(dto);
              this.collectMenusWithSubMenus(mandant, menu, allMenus);
            });

            await this.databaseService.insertOrUpdate(allMenus);


            allMenus.forEach(menu => {
              this.reloadMenuFile(menu);
            });
          }
        } catch (error) {
          console.error(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Menu/${path}`, error, response);
        }

      });
  }

  async reloadMenuFile(menu: Menu) {
    await this.webService.sendRequest<MenuFileDto>(`${menu.mandant}/Menu/dokument/${menu.id}`, HttpMethod.GET)
      .then(async response => {
        const dto = await firstValueFrom(response);

        if (dto) {
          menu.mapFile(dto);
          await this.databaseService.insertOrUpdate([menu]);
          await this.loadMenus(menu.mnCode, menu.id);
        }
      });
  }

  async reloadNachrichten() {
    var response = await this.webService.sendRequest<NachrichtDto[]>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Menu/nachrichten`, HttpMethod.GET);
    var dtos = await firstValueFrom(response);
    if (dtos) {
      const allNachrichten: Nachricht[] = [];
      dtos.forEach(dto => {
        const menu = new Nachricht();
        menu.map(dto);
        allNachrichten.push(menu);
      });

      await this.databaseService.insertOrUpdate(allNachrichten);
      await this.loadNachrichten();
    }
  }

  private async collectMenusWithSubMenus(mandant: string, menu: Menu, menuList: Menu[]) {
    menu.mandant = mandant;
    menuList.push(menu);

    if (menu.subMenuInformation && menu.subMenuInformation.length > 0) {
      menu.subMenuInformation.forEach(subMenu => {
        this.collectMenusWithSubMenus(mandant, subMenu, menuList);
      });
    }
  }

  async sendSupportMessage(supportNachrichtDto: SupportNachrichtDto) {
    this.webService.sendRequest<boolean>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Menu/supportnachrichten`, HttpMethod.POST, undefined, supportNachrichtDto);
  }
}
