import { FahrtberichtDto } from "./fahrtbericht-dto";

export class Fahrtbericht {
    className = 'Fahrtbericht'

    id = '';
    mandant = '';
    layout = 0;
    sort = 0;
    gestaltungsCode = 0;
    bezeichnung = '';
    beschreibung = '';
    breite = 0;
    hoehe = 0;
    prefix = '';
    suffix = '';
    maxAntwortlaenge = 0;
    darstellungscode = 0;
    obligatorisch = 0;
    value = '';

    map(dto: FahrtberichtDto) {
        this.id = dto.id || '';
        this.layout = dto.layout || 0;
        this.sort = dto.sort || 0;
        this.gestaltungsCode = dto.gestaltungsCode || 0;
        this.bezeichnung = dto.bezeichnung || '';
        this.beschreibung = dto.beschreibung || '';
        this.breite = dto.breite || 0;
        this.hoehe = dto.hoehe || 0;
        this.prefix = dto.prefix || '';
        this.suffix = dto.suffix || '';
        this.maxAntwortlaenge = dto.maxAntwortlaenge || 0;
        this.darstellungscode = dto.darstellungscode || 0;
        this.obligatorisch = dto.obligatorisch || 0;
        this.value = dto.value || '';
    }
}