import {ListeDto} from "./liste-dto";

export class Liste {

  className = 'Liste';

  id: string = '';
  einsatzId: string = '';
  bezeichnung: string = '';
  anzahlPax: number = 0;

  map(dto: ListeDto) {
    this.id = dto.id;
    this.bezeichnung = dto.bezeichnung;
    this.anzahlPax = dto.anzahlPax;
  }
}
