import {DokumentDto} from "./dokument-dto";

export class Dokument {
  className: string = 'Dokument';

  id: string = '';
  einsatzId: string = '';
  parentId: string = '';
  ordnerName: string = '';
  dokumentName: string = '';
  mutDatum: string = '';
  inhaltBase64: string = '';

  dokumente: Dokument[] = [];

  map(dto: DokumentDto) {
    this.id = dto.id ?? '';
    this.ordnerName = dto.ordnerName ?? '';
    this.dokumentName = dto.dokumentName ?? '';
    this.mutDatum = dto.mutDatum ?? '';
    this.inhaltBase64 = dto.inhaltBase64 ?? '';

    if (dto.dokumente) {
      this.dokumente = dto.dokumente.map(x => {
        var dokument = new Dokument();
        dokument.map(x);
        dokument.parentId = this.id;
        return dokument;
      });
    }
  }
}
