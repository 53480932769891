import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { firstValueFrom } from 'rxjs';
import { DeviceRegistrationDto } from 'src/app/data/notification/device-registration-dto';
import { Environment } from 'src/app/utils/environment/environment';
import { HttpMethod, WebService } from '../web/web.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private webService: WebService) {
  }

  public async initPush() {
    if (Environment.isNativePlatform()) {
      await this.addListeners();
      await this.registerNotifications();
    }
  }

  async registerPushToken(token: string) {
    let deviceRegistrationDto: DeviceRegistrationDto = {
      platform: Capacitor.getPlatform(),
      token: token,
    }

    console.info('Registering device: ' + JSON.stringify(deviceRegistrationDto));

    await this.webService.sendRequest<boolean>(`notification/register`, HttpMethod.PUT, null, deviceRegistrationDto).then(async response => {
      var dto = await firstValueFrom(response);
      console.info('Device registered: ' + JSON.stringify(dto));
    });
  }

  private async addListeners() {

    PushNotifications.addListener(
      'registration',
      (registration) => {
        this.registerPushToken(registration.value);
        console.info('Push registration success, token: ' + registration.value);
      }
    );


    PushNotifications.addListener(
      'registrationError',
      (error: any) => {
        console.error('Error on registration: ' + JSON.stringify(error));
      }
    );

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.info('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        console.info('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }

  private async registerNotifications() {
    let permStatus = await PushNotifications.checkPermissions();
    console.info('Permission status: ' + JSON.stringify(permStatus));

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
      console.info('Permission status after request: ' + JSON.stringify(permStatus));
    }

    if (permStatus.receive !== 'granted') {
      console.error('User denied permissions!');
      return;  // frühzeitig abbrechen, wenn die Berechtigung nicht erteilt wurde
    }

    await PushNotifications.register();
  }
}
