import { Injectable, signal, WritableSignal } from '@angular/core';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { firstValueFrom } from 'rxjs';
import { Benutzer } from 'src/app/data/person/benutzer/benutzer';
import { BenutzerDto } from 'src/app/data/person/benutzer/benutzer-dto';
import { Environment } from 'src/app/utils/environment/environment';
import { DatabaseService } from '../database/database.service';
import { StorageKeys, StorageService } from '../database/storage/storage.service';
import { HttpMethod, WebService } from '../web/web.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _users: WritableSignal<Benutzer[]> = signal([]);
  users = this._users.asReadonly();

  private _user: WritableSignal<Benutzer> = signal(new Benutzer());
  user = this._user.asReadonly();

  constructor(private webService: WebService,
              private databaseService: DatabaseService,
              private storage: StorageService
  ) {
  }

  async loadCurrentUser() {

    await this.loadBenutzer();

    var id = await this.storage.get(StorageKeys.CURRENT_USER);
    var user = this.users().find(x => x.id == id)
    if (user == null) {
      this._user.set(this.users()[0]);
    } else {
      this._user.set(user);
    }

    //TODO: Logik entwicklen, dass Refresh Token gespeichert wird und bei einem Wechsel des Benutzers das Token erneuert wird
    //await this.storage.set(StorageKeys.ACCESS_TOKEN, this.user().accessToken);

  }

  async loadBenutzer() {
    var benutzer = await this.databaseService.loadTable<Benutzer>('Benutzer');
    this._users.set(benutzer);
  }

  async reloadBenutzer() {
    await this.webService.sendRequest<BenutzerDto>('Benutzer', HttpMethod.GET).then(async response => {
      var dto = await firstValueFrom(response);


      if (dto) {
        var benutzer = new Benutzer();
        benutzer.map(dto);

        benutzer.accessToken = await this.storage.get(StorageKeys.ACCESS_TOKEN) || '';

        await this.storage.set(StorageKeys.CURRENT_USER, benutzer.id);

        await this.databaseService.insertOrUpdate([benutzer]);
        await this.loadBenutzer();
      }
    });
  }

  async log() {
    const body = await this.getDeviceInfo();

    await this.webService.sendRequest<Boolean>('Benutzer/log', HttpMethod.POST, null, body).then(async response => {
      var result = await firstValueFrom(response);
    });
  }

  async getDeviceInfo() {
    const deviceInfo = await Device.getInfo();
    var appInfo = null;
    if (Environment.isNativePlatform()) {
      appInfo = await App.getInfo();
    }

    return  {
      appVersion: appInfo?.version || '',
      buildVersion: appInfo?.build || '',
      appName: appInfo?.name || '',
      appId: appInfo?.id || '',
      name: deviceInfo.name || '',
      model: deviceInfo.model || '',
      platform: deviceInfo.platform || '',
      operatingSystem: deviceInfo.operatingSystem || '',
      osVersion: deviceInfo.osVersion || deviceInfo.iOSVersion || deviceInfo.androidSDKVersion || '',
      manufacturer: deviceInfo.manufacturer || '',
      isVirtual: `${deviceInfo.isVirtual}`,
      memUsed: `${((deviceInfo.memUsed ?? 0) / 1048576).toFixed(2)} MB`,
      realDiskFree: `${((deviceInfo.realDiskFree ?? 0) / 1048576).toFixed(2)} MB`,
      realDiskTotal: `${((deviceInfo.realDiskTotal ?? 0) / 1048576).toFixed(2)} MB`,
      webViewVersion: deviceInfo.webViewVersion || ''
    }
  }
}
