import {ChauffeurDto} from "./chauffeur-dto";

export class Chauffeur {
  className: string = 'Chauffeur';

  einsatzId: string = '';
  id: string = '';
  name: string = '';
  vorname: string = '';
  telefon: string = '';
  startDatum: string = '';
  endDatum: string = '';
  abfahrtZeit: string = '';
  endZeit: string = '';
  transportnNummer: string = '';
  titel: string = '';

  map(dto: ChauffeurDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.vorname = dto.vorname;
    this.telefon = dto.telefon;
    this.startDatum = dto.startDatum;
    this.endDatum = dto.endDatum;
    this.abfahrtZeit = dto.abfahrtZeit;
    this.endZeit = dto.endZeit;
    this.transportnNummer = dto.transportnNummer;
    this.titel = dto.titel;
  }
}
