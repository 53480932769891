import { Injectable, signal, WritableSignal } from '@angular/core';
import { AppIcon } from '@capacitor-community/app-icon';
import { firstValueFrom } from 'rxjs';
import { Icon } from 'src/app/data/mandant/icon/icon';
import { Mandant } from 'src/app/data/mandant/mandant';
import { MandantDto } from 'src/app/data/mandant/mandant-dto';
import { Environment } from 'src/app/utils/environment/environment';
import { DatabaseService } from '../database/database.service';
import { StorageKeys, StorageService } from '../database/storage/storage.service';
import { HttpMethod, WebService } from '../web/web.service';

@Injectable({
  providedIn: 'root'
})
export class MandantService {

  private _mandanten: WritableSignal<Mandant[]> = signal([]);
  mandanten = this._mandanten.asReadonly();

  private _mandant: WritableSignal<Mandant> = signal(new Mandant());
  mandant = this._mandant.asReadonly();

  constructor(
    private webService: WebService,
    private databaseService: DatabaseService,
    private storage: StorageService
  ) {
  }

  async loadMandanten() {
    var mandanten = await this.databaseService.loadTable<Mandant>('Mandant');
    var currentMandant = await this.storage.get(StorageKeys.CURRENT_MANDANT);
    mandanten.forEach(async mandant => {
      if (mandant.id === currentMandant) {
        this._mandant.set(mandant);
      }
      var icons = await this.databaseService.loadTable<Icon>('Icon', undefined, `mandantenKuerzel = '${mandant.kuerzel}'`);

      mandant.icons = icons;
    });


    mandanten.sort((a, b) => a.zugriffStatus - b.zugriffStatus || a.name.localeCompare(b.name));

    this._mandanten.set(mandanten);
  }

  async reloadMandanten() {
    var response = await this.webService.sendRequest<MandantDto[]>('Benutzer/Mandant', HttpMethod.GET);
    var dtos = await firstValueFrom(response);

    if (dtos) {
      const mandanten: Mandant[] = [];

      for (const item of dtos) {
        const mandant = new Mandant();
        mandant.map(item);
        mandanten.push(mandant);

        await this.databaseService.insertOrUpdate(mandant.icons);
      }

      await this.databaseService.insertOrUpdate(mandanten);

      await this.loadMandanten();
    }
  }

  async changeMandant(mandant: Mandant) {
    await this.storage.set(StorageKeys.CURRENT_MANDANT, mandant.id);

    if (Environment.isNativePlatform()) {

      switch (mandant.kuerzel) {
        case "qt":
        case "tw":
          await this.changeIcon('twerenbold');
          break;
        default:
          await this.changeIcon('tourdriver');
        break;
      }
    }

    this._mandant.set(mandant);
  }

  iconNames: string[] = ['tourdriver', 'twerenbold']
  async changeIcon(iconName: string) {

    var disableIcons = this.iconNames.filter(name => name !== iconName)

    try {
      await AppIcon.change({ name: iconName, suppressNotification: true, disable: disableIcons });
    } catch (error) {
      console.debug(error)
    }
  }

  async requestMandant(mandant: Mandant) {
    var response = await this.webService.sendRequest(`Benutzer/${mandant.kuerzel}/anfrage`, HttpMethod.POST);
    var result = await firstValueFrom(response);
  }

  getLogo(mandant: Mandant) {
    var logo = mandant.logoBase64;
    mandant.icons?.forEach(icon => {
      if (Environment.isDarkMode()) {
        if (icon.dateiName.includes('logo') && icon.iconType === 2) {
          logo = icon.iconBase64;
        }
      } else {
        if (icon.dateiName.includes('logo') && icon.iconType === 1) {
          logo = icon.iconBase64;
        }
      }
    });
    return logo;
  }

  getIcon(mandant: Mandant) {
    var logo = mandant.logoBase64;
    mandant.icons?.forEach(icon => {
      if (Environment.isDarkMode()) {
        if (icon.dateiName.includes('icon') && icon.iconType === 2) {
          logo = icon.iconBase64;
        }
      } else {
        if (icon.dateiName.includes('icon') && icon.iconType === 1) {
          logo = icon.iconBase64;
        }
      }
    });
    return logo;
  }
}
