import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage!: Storage;

  constructor(private storage: Storage) {
  }

  async initStorage() {
    this._storage = await this.storage.create();
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(key: StorageKeys, value: any) {
    await this._storage.set(key, value);
    /*console.log("SET: " + key, value);*/
    //await SecureStoragePlugin.set({ key, value });//.then(success => console.log("SET: " + key, success));
  }

  public async get(key: StorageKeys): Promise<any> {
    return await this._storage.get(key);

    /*var value = '';

    try {
      value = (await SecureStoragePlugin.get({ key })).value;
    } catch (error) {
      
    }

    return value;*/
  }

  public async remove(key: StorageKeys): Promise<any> {
    return await this._storage?.remove(key);
    //return await SecureStoragePlugin.remove({ key });
  }
}

export enum StorageKeys {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  CURRENT_MANDANT = 'current_mandant',
  CURRENT_USER = 'current_user',
  RETURN_URL = 'return_url',
  CURRENT_SCROLL_POSITION = 'current_scroll_position',
}
