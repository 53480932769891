import {Icon} from "./icon/icon";
import {MandantDto} from "./mandant-dto";

export class Mandant {
  className = 'Mandant'

  id: string = '';
  name: string = '';
  kuerzel: string = '';
  logoBase64: string = '';
  zugriffStatus: number = 0;

  icons: Icon[] = [];

  map(dto: MandantDto) {
    this.id = dto.kuerzel;
    this.name = dto.name;
    this.kuerzel = dto.kuerzel;
    this.logoBase64 = dto.logoBase64 || '';
    this.zugriffStatus = dto.zugriffStatus;

    this.icons = dto.icons.map(x => {
      var icon = new Icon();
      icon.map(x);
      return icon;
    });
  }
}
