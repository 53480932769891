import { Halteort } from "./halteort/halteort";
import { ListeDetailDto } from "./liste-detail-dto";

export class ListeDetail {
  className: string = 'ListeDetail';

  id: string = '';
  bezeichnung: string = '';
  verspaetungsSmsVerfuegbar: boolean = false;

  halteorte: Halteort[] = [];

  map(dto: ListeDetailDto) {
    this.bezeichnung = dto.bezeichnung ?? '';
    this.halteorte = dto.halteorte.map(x => {
      var halteort = new Halteort();
      halteort.map(x);
      halteort.listeId = this.id;
      return halteort;
    });
    this.verspaetungsSmsVerfuegbar = dto.verspaetungsSmsVerfuegbar ?? false;
  }
}
