import {TeilnehmerDto} from "./teilnehmer-dto";

export class Teilnehmer {
  className = 'Teilnehmer';

  id: string = '';
  einsatzId: string = '';
  name: string = '';
  telefonnummer: string = '';
  ausstieg: string = '';
  dossierId: string = '';
  sitzplatz: string = '';
  anzahlTickets: number = 0;
  bezahlt: boolean = false;
  offenerBetrag: number = 0;
  status: number = 0;
  tlnlaufnr: number = 0;
  vprlaufnr: number = 0;

  map(dto: TeilnehmerDto) {
    this.id = dto.id || '';
    this.name = dto.name || '';
    this.telefonnummer = dto.telefonnummer || '';
    this.ausstieg = dto.ausstieg || '';
    this.dossierId = dto.dossierId || '';
    this.sitzplatz = dto.sitzplatz || '';
    this.anzahlTickets = dto.anzahlTickets || 0;
    this.bezahlt = dto.bezahlt || false;
    this.offenerBetrag = dto.offenerBetrag || 0;
    this.status = dto.status || 0;
    this.tlnlaufnr = dto.tlnlaufnr || 0;
    this.vprlaufnr = dto.vprlaufnr || 0;
  }

}
