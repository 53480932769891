import { PaxDto } from "./pax-dto";

export class Pax {
  className = 'Pax'

  id: string = '';
  dossier: string = '';
  listeId: string = '';
  halteortId: string = '';
  name: string = '';
  ausstieg: string = '';
  dossierId: number = 0;
  sitzplatz: string = '';
  anzahlTickets: number = 0;
  bezahlt: boolean = false;
  offenerBetrag: number = 0;
  status: number = 0;
  tlnlaufnr: number = 0;
  vprlaufnr: number = 0;

  map(dto: PaxDto) {
    this.id = dto.id ?? '';
    this.dossier = dto.dossier ?? '';
    this.name = dto.name ?? '';
    this.ausstieg = dto.ausstieg ?? '';
    this.dossierId = dto.dossierId ?? 0;
    this.sitzplatz = dto.sitzplatz ?? '';
    this.anzahlTickets = dto.anzahlTickets ?? 0;
    this.bezahlt = dto.bezahlt ?? false;
    this.offenerBetrag = dto.offenerBetrag ?? 0;
    this.status = dto.status ?? 0;
    this.tlnlaufnr = dto.tlnlaufnr ?? 0;
    this.vprlaufnr = dto.vprlaufnr ?? 0;
  }
}
