import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DBCodes } from './utils/DBCodes';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'einsatz',
    loadChildren: () => import('./pages/einsatz/einsatz.module').then(m => m.EinsatzPageModule)
  },
  {
    path: 'aktuell',
    loadChildren: () => import('./pages/aktuell/aktuell.module').then(m => m.AktuellPageModule)
  },
  {
    path: 'kalender',
    loadChildren: () => import('./pages/kalender/kalender/kalender.module').then(m => m.KalenderPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'settings/mandant/edit',
    loadChildren: () => import('./pages/settings/mandant-edit/mandant-edit.module').then(m => m.MandantEditPageModule)
  },
  {
    path: 'settings/menu/informationen',
    loadChildren: () => import('./pages/settings/menu/menu.module').then(m => m.MenuPageModule),
    data: {
      menuType: DBCodes.CDMN_CHAUFFEUR_INFO
    }
  },
  {
    path: 'settings/menu/informationen/:id',
    loadChildren: () => import('./pages/settings/menu/menu.module').then(m => m.MenuPageModule),
    data: {
      menuType: DBCodes.CDMN_CHAUFFEUR_INFO
    }
  },
  {
    path: 'settings/menu/neuigkeiten',
    loadChildren: () => import('./pages/settings/menu/menu.module').then(m => m.MenuPageModule),
    data: {
      menuType: DBCodes.CDMN_CHAUFFEUR_NEWS
    }
  },
  {
    path: 'settings/menu/neuigkeiten/:id',
    loadChildren: () => import('./pages/settings/menu/menu.module').then(m => m.MenuPageModule),
    data: {
      menuType: DBCodes.CDMN_CHAUFFEUR_NEWS
    }
  },
  {
    path: 'settings/menu/hilfe',
    loadChildren: () => import('./pages/settings/menu/menu.module').then(m => m.MenuPageModule),
    data: {
      menuType: DBCodes.CDMN_CHAUFFEUR_HILFE
    }
  },
  {
    path: 'settings/menu/hilfe/:id',
    loadChildren: () => import('./pages/settings/menu/menu.module').then(m => m.MenuPageModule),
    data: {
      menuType: DBCodes.CDMN_CHAUFFEUR_HILFE
    }
  },
  {
    path: 'pdf/:pdfFile',
    loadChildren: () => import('./pages/pdf/pdf/pdf.module').then(m => m.PdfPageModule)
  },
  {
    path: 'adresse',
    loadChildren: () => import('./pages/adresse/adresse.module').then(m => m.AdressePageModule)
  },
  {
    path: 'sachbearbeiter',
    loadChildren: () => import('./pages/einsatz/einsatz-detail/sachbearbeiter/sachbearbeiter.module').then(m => m.SachbearbeiterPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules /*NoPreloading*/, useHash: true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
