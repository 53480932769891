import { EinsatzDto } from "./einsatz-dto";

export class Einsatz {

  className = 'Einsatz'

  id = '';
  mandant = '';
  titel = '';
  fahrzeug = '';
  startDatum = '';
  endDatum = '';
  startZeit = '';
  endZeit = '';
  startOrt = '';
  endOrt = '';
  status = 0;
  statusText = '';
  detailsVerfuegbar = false;

  map(dto: EinsatzDto) {
    this.id = dto.id || '';
    this.titel = dto.titel || '';
    this.fahrzeug = dto.fahrzeug || '';
    this.startDatum = dto.startDatum || '';
    this.endDatum = dto.endDatum || '';
    this.startZeit = dto.startZeit || '';
    this.endZeit = dto.endZeit || '';
    this.startOrt = dto.startOrt || '';
    this.endOrt = dto.endOrt || '';
    this.status = dto.status || 0;
    this.statusText = dto.statusText || '';
    this.detailsVerfuegbar = dto.detailsVerfuegbar || false;
  }
}
