import { Injectable, signal, WritableSignal } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { Dokument } from 'src/app/data/einsatz/dokument/dokument';
import { DokumentDto } from 'src/app/data/einsatz/dokument/dokument-dto';
import { EinsatzDetail } from 'src/app/data/einsatz/einsatz-detail/einsatz-detail';
import { EinsatzDetailDto } from 'src/app/data/einsatz/einsatz-detail/einsatz-detail-dto';
import { Einsatz } from 'src/app/data/einsatz/einsatz/einsatz';
import { EinsatzDto } from 'src/app/data/einsatz/einsatz/einsatz-dto';
import { Fahrtbericht } from 'src/app/data/einsatz/fahrtbericht/fahrtbericht';
import { Kunde } from 'src/app/data/einsatz/kunde/kunde';
import { KundeDto } from 'src/app/data/einsatz/kunde/kunde-dto';
import { Liste } from 'src/app/data/einsatz/liste/liste';
import { Halteort } from 'src/app/data/einsatz/liste/liste-detail/halteort/halteort';
import { Pax } from 'src/app/data/einsatz/liste/liste-detail/halteort/pax/pax';
import { PaxDto } from 'src/app/data/einsatz/liste/liste-detail/halteort/pax/pax-dto';
import { ListeDetail } from 'src/app/data/einsatz/liste/liste-detail/liste-detail';
import { ListeDetailDto } from 'src/app/data/einsatz/liste/liste-detail/liste-detail-dto';
import { ListeDto } from 'src/app/data/einsatz/liste/liste-dto';
import { Sachbearbeiter } from 'src/app/data/einsatz/sachbearbeiter/sachbearbeiter';
import { SachbearbeiterDto } from 'src/app/data/einsatz/sachbearbeiter/sachbearbeiter-dto';
import { Teilnehmer } from 'src/app/data/einsatz/teilnehmer/teilnehmer';
import { TeilnehmerDto } from 'src/app/data/einsatz/teilnehmer/teilnehmer-dto';
import { WichtigeInformation } from 'src/app/data/einsatz/wichtige-information/wichtige-information';
import { WichtigeInformationDto } from 'src/app/data/einsatz/wichtige-information/wichtige-information-dto';
import { Chauffeur } from 'src/app/data/person/chauffeur/chauffeur';
import { ChauffeurDto } from 'src/app/data/person/chauffeur/chauffeur-dto';
import { DatabaseService } from '../database/database.service';
import { StorageKeys, StorageService } from '../database/storage/storage.service';
import { HttpMethod, WebService } from '../web/web.service';

@Injectable({
  providedIn: 'root'
})
export class EinsatzService {
  private reloadEinsaetzeSubject = new Subject<boolean>();
  reloadEinsaetze$ = this.reloadEinsaetzeSubject.asObservable();

  reloadEinsaetzeEvent(){
    this.reloadEinsaetzeSubject.next(true);
  }

  private _einsaetze: WritableSignal<Einsatz[]> = signal([]);
  einsaetze = this._einsaetze.asReadonly();

  private _einsatzDetails: WritableSignal<EinsatzDetail[]> = signal([]);
  einsatzDetails = this._einsatzDetails.asReadonly();

  private _chauffeure: WritableSignal<Chauffeur[]> = signal([]);
  chauffeure = this._chauffeure.asReadonly();

  private _wichtigeInformation: WritableSignal<WichtigeInformation> = signal(new WichtigeInformation());
  wichtigeInformation = this._wichtigeInformation.asReadonly();

  private _sachbearbeiter: WritableSignal<Sachbearbeiter> = signal(new Sachbearbeiter());
  sachbearbeiter = this._sachbearbeiter.asReadonly();

  private _kunde: WritableSignal<Kunde> = signal(new Kunde());
  kunde = this._kunde.asReadonly();

  private _teilnehmer: WritableSignal<Teilnehmer[]> = signal([]);
  teilnehmer = this._teilnehmer.asReadonly();

  private _listen: WritableSignal<Liste[]> = signal([]);
  listen = this._listen.asReadonly();

  private _listenDetails: WritableSignal<ListeDetail[]> = signal([]);
  listenDetails = this._listenDetails.asReadonly();

  private _halteorte: WritableSignal<Halteort[]> = signal([]);
  halteorte = this._halteorte.asReadonly();

  private _pax: WritableSignal<Pax[]> = signal([]);
  pax = this._pax.asReadonly();

  private _dokumente: WritableSignal<Dokument[]> = signal([]);
  dokumente = this._dokumente.asReadonly();

  private _fahrtbericht: WritableSignal<Fahrtbericht[]> = signal([]);
  fahrtbericht = this._fahrtbericht.asReadonly();

  constructor(
    private webService: WebService,
    private databaseService: DatabaseService,
    private storage: StorageService
  ) {
  }

  async loadEinsaetze(einsatzId?: string) {
    var einsaetze = await this.databaseService.loadTable<Einsatz>('Einsatz', undefined, `mandant = '${await this.storage.get(StorageKeys.CURRENT_MANDANT)}'` + (einsatzId ? ` and id = '${einsatzId}'` : ''));
    this._einsaetze.set(einsaetze);
  }

  async loadEinsatzdetails(einsatzId?: string) {
    var einsatzDetails = await this.databaseService.loadTable<EinsatzDetail>('EinsatzDetail', undefined, `mandant = '${await this.storage.get(StorageKeys.CURRENT_MANDANT)}'` + (einsatzId ? ` and id = '${einsatzId}'` : ''));
    this._einsatzDetails.set(einsatzDetails);
  }

  async loadListen(einsatzId: string) {
    var listen = await this.databaseService.loadTable<Liste>('Liste', undefined, einsatzId == undefined ? '' : `einsatzId = '${einsatzId}'`);
    this._listen.set(listen);
  }

  async loadChauffeure(einsatzId: string) {
    var chauffeure = (await this.databaseService.loadTable<Chauffeur>('Chauffeur')).filter(x => x.einsatzId == einsatzId);
    this._chauffeure.set(chauffeure);
  }

  async loadWichtigeInformation(einsatzId: string) {
    var wichtigeInformation = (await this.databaseService.loadTable<WichtigeInformation>('WichtigeInformation', undefined, `id = '${einsatzId}'`));
    this._wichtigeInformation.set(wichtigeInformation[0]);
  }

  async loadSachbearbeiter(einsatzId: string) {
    var sachbearbeiter = (await this.databaseService.loadTable<Sachbearbeiter>('Sachbearbeiter', undefined, `id = '${einsatzId}'`));
    this._sachbearbeiter.set(sachbearbeiter[0]);
  }

  async loadKunde(einsatzId: string) {
    var kunde = (await this.databaseService.loadTable<Kunde>('Kunde', undefined, `id = '${einsatzId}'`));
    this._kunde.set(kunde[0]);
  }

  async loadTeilnehmer(einsatzId: string) {
    var teilnehmer = (await this.databaseService.loadTable<Teilnehmer>('Teilnehmer', undefined, `einsatzId = '${einsatzId}'`));
    this._teilnehmer.set(teilnehmer);
  }

  async loadListenDetails(listeId: string) {
    var listeDetails = await this.databaseService.loadTable<ListeDetail>('ListeDetail', undefined, `id = '${listeId}'`);
    this._listenDetails.set(listeDetails);
  }

  async loadHalteorte(listeId: string) {
    var halteorte = await this.databaseService.loadTable<Halteort>('Halteort', 'zeit', `listeId = '${listeId}'`);
    this._halteorte.set(halteorte);
  }

  async loadPax(listeId: string, halteortId: string) {
    var pax = await this.databaseService.loadTable<Pax>('Pax', undefined, `listeId = '${listeId}' and halteortId = '${halteortId}'`);
    this._pax.set(pax);
  }

  async loadPaxWithId(paxId: string) {
    var pax = await this.databaseService.loadTable<Pax>('Pax', undefined, `id = '${paxId}'`);
    this._pax.set(pax);
  }

  async loadFahrtbericht() {
    var fahrtbericht = await this.databaseService.loadTable<Fahrtbericht>('Fahrtbericht', 'sort', `mandant = '${await this.storage.get(StorageKeys.CURRENT_MANDANT)}'`);
    this._fahrtbericht.set(fahrtbericht);
  }

  async loadDokumente(einsatzId: string, parentDokument?: string) {
    var dokumente: Dokument[] = [];

    if (parentDokument != undefined) {
      dokumente = await this.databaseService.loadTable<Dokument>('Dokument', undefined, `einsatzId = '${einsatzId}' and parentId = '${parentDokument}'`);
    } else {
      dokumente = await this.databaseService.loadTable<Dokument>('Dokument', undefined, `einsatzId = '${einsatzId}' and parentId = ''`);
    }

    this._dokumente.set(dokumente);
  }

  async reloadEinsaetze(date?: string) {
    var mandant = await this.storage.get(StorageKeys.CURRENT_MANDANT);
    var response = await this.webService.sendRequest<EinsatzDto[]>(`${mandant}/Einsatz${date ? `?startDatum=${date}` : ''}`, HttpMethod.GET);
    var dtos = await firstValueFrom(response);

    if (dtos) {
      await this.databaseService.delete('Einsatz', `mandant = '${mandant}'`);

      for (const item of dtos) {
        const einsatz = new Einsatz();
        einsatz.map(item);
        einsatz.mandant = mandant;
        await this.databaseService.insertOrUpdate([einsatz]);
      }
    }

    await this.loadEinsaetze();
  }

  async reloadEinsaetzeDetails() {
    await this.loadEinsaetze();

    this.einsaetze().forEach(async einsatz => {
      await this.reloadEinsatzDetails(einsatz.id);
    });
  }

  async reloadEinsatzDetails(einsatzId: string) {
    var mandant = await this.storage.get(StorageKeys.CURRENT_MANDANT);
    var response = await this.webService.sendRequest<EinsatzDetailDto>(`${mandant}/Einsatz/${einsatzId}/details`, HttpMethod.GET);
    var dto = await firstValueFrom(response);

    if (dto) {
      const einsatzDetail = new EinsatzDetail();
      einsatzDetail.map(dto);
      einsatzDetail.mandant = mandant;
      await this.databaseService.insertOrUpdate([einsatzDetail]);

      if (einsatzDetail.chauffeureInformationVerfuegbar) {
        this.reloadChauffeure(einsatzId);
      }
      if (einsatzDetail.wichtigeInformationVerfuegbar) {
        this.reloadWichtigeInformation(einsatzId);
      }
      if (einsatzDetail.sachbearbeiterInformationVerfuegbar) {
        this.reloadSachbearbeiter(einsatzId);
      }
      if (einsatzDetail.kundenInformationVerfuegbar) {
        this.reloadKunde(einsatzId);
      }
      if (einsatzDetail.listenVerfuegbar) {
        this.reloadListen(einsatzId);
      }
      if (einsatzDetail.teilnehmerlisteVerfuegbar) {
        this.reloadTeilnehmer(einsatzId);
      }
      if (einsatzDetail.dokumenteInformationVerfuegbar) {
        this.reloadDokumente(einsatzId);
      }

    }

  }

  async reloadChauffeure(einsatzId: string) {
    var response = await this.webService.sendRequest<ChauffeurDto[]>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${einsatzId}/chauffeure`, HttpMethod.GET);
    var dtos = await firstValueFrom(response);

    if (dtos) {
      for (const item of dtos) {
        const chauffeur = new Chauffeur();
        chauffeur.map(item);
        chauffeur.einsatzId = einsatzId;
        await this.databaseService.insertOrUpdate([chauffeur]);
      }
    }
  }


  async reloadWichtigeInformation(einsatzId: string) {
    var response = await this.webService.sendRequest<WichtigeInformationDto>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${einsatzId}/wichtigeInformationen`, HttpMethod.GET);
    var dto = await firstValueFrom(response);

    if (dto) {
      const wichtigeInformation = new WichtigeInformation();
      wichtigeInformation.id = einsatzId;
      wichtigeInformation.map(dto);
      await this.databaseService.insertOrUpdate([wichtigeInformation]);
    }
  }

  async reloadSachbearbeiter(einsatzId: string) {
    var response = await this.webService.sendRequest<SachbearbeiterDto>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${einsatzId}/sachbearbeiter`, HttpMethod.GET);
    var dto = await firstValueFrom(response);

    if (dto) {
      const sachbearbeiter = new Sachbearbeiter();
      sachbearbeiter.id = einsatzId;
      sachbearbeiter.map(dto);
      await this.databaseService.insertOrUpdate([sachbearbeiter]);
    }
  }

  async reloadKunde(einsatzId: string) {
    var response = await this.webService.sendRequest<KundeDto>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${einsatzId}/kunde`, HttpMethod.GET);
    var dto = await firstValueFrom(response);

    if (dto) {
      //TODO Kunde ausarbeiten
      const kunde = new Kunde();
      kunde.id = einsatzId;
      kunde.map(dto);
      await this.databaseService.insertOrUpdate([kunde]);
    }
  }

  async reloadTeilnehmer(einsatzId: string) {
    var response = await this.webService.sendRequest<TeilnehmerDto[]>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${einsatzId}/listen/teilnehmerliste`, HttpMethod.GET);
    var dtos = await firstValueFrom(response);

    if (dtos) {
      for (const item of dtos) {
        const teilnehmer = new Teilnehmer();
        teilnehmer.map(item);
        teilnehmer.einsatzId = einsatzId;
        await this.databaseService.insertOrUpdate([teilnehmer]);
      }
    }
  }

  async reloadListen(einsatzId: string) {
    var response = await this.webService.sendRequest<ListeDto[]>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${einsatzId}/liste`, HttpMethod.GET);
    var dtos = await firstValueFrom(response);

    if (dtos) {
      for (const item of dtos) {
        const liste = new Liste();
        liste.map(item);
        liste.einsatzId = einsatzId;
        await this.databaseService.insertOrUpdate([liste]);
        await this.reloadListenDetails(liste);
      }
    }
  }

  async reloadListenDetails(liste: Liste) {
    var response = await this.webService.sendRequest<ListeDetailDto>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${liste.id}/hinrueck`, HttpMethod.GET);
    var dto = await firstValueFrom(response);

    if (dto) {
      const listeDetail = new ListeDetail();
      listeDetail.id = liste.id;
      listeDetail.map(dto);

      await this.databaseService.insertOrUpdate([listeDetail]);
      await this.databaseService.insertOrUpdate(listeDetail.halteorte);

      await this.loadListenDetails(liste.id);

      listeDetail.halteorte.forEach(async halteort => {
        this.reloadPax(liste, halteort);
      });
    }
  }

  async reloadPax(liste: Liste, halteort: Halteort) {
    var response = await this.webService.sendRequest<PaxDto[]>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${halteort.id}/hinrueck/teilnehmer`, HttpMethod.GET);
    var dtos = await firstValueFrom(response);

    if (dtos) {

      var paxList: Pax[] = [];

      for (const item of dtos) {
        const pax = new Pax();
        pax.map(item);
        pax.listeId = liste.id;
        pax.halteortId = halteort.id;

        paxList.push(pax);
      }
      await this.databaseService.insertOrUpdate(paxList);
    }
  }

  async checkInPax(pax: Pax) {
    var response = await this.webService.sendRequest<PaxDto>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${pax.id}/eingestiegen`, HttpMethod.PUT);
    var dto = await firstValueFrom(response);

    if (dto) {
      var paxNew = new Pax();
      paxNew.map(dto);

      pax.status = paxNew.status;
      pax.className = paxNew.className;

      await this.databaseService.insertOrUpdate([pax]);
    }
  }

  async noshowPax(pax: Pax) {
    var response = await this.webService.sendRequest<PaxDto>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${pax.id}/noshow`, HttpMethod.PUT);
    var dto = await firstValueFrom(response);

    if (dto) {

      var paxNew = new Pax();
      paxNew.map(dto);

      pax.status = paxNew.status;
      pax.className = paxNew.className;

      await this.databaseService.insertOrUpdate([pax]);
    }
  }

  async reloadDokumente(einsatzId: string) {
    var response = await this.webService.sendRequest<DokumentDto[]>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${einsatzId}/dokumente`, HttpMethod.GET);
    var dtos = await firstValueFrom(response);

    if (dtos) {
      const allDokumente: Dokument[] = [];
      dtos.forEach(dto => {
        const dokument = new Dokument();
        dokument.map(dto);
        this.collectDokumenteWithSubDokumente(einsatzId, dokument, allDokumente);
      });

      await this.databaseService.insertOrUpdate(allDokumente);

      allDokumente.forEach(async dokument => {
        if (dokument.dokumentName) {
          this.reloadDokument(dokument);
        }
      });

      await this.loadDokumente(einsatzId);
    }
  }

  async reloadDokument(dokument: Dokument) {
    var response = await this.webService.sendRequest<DokumentDto>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/dokument/${dokument.id}`, HttpMethod.GET);
    var dto = await firstValueFrom(response);

    if (dto) {
      dokument.inhaltBase64 = dto.inhaltBase64;
      await this.databaseService.insertOrUpdate([dokument]);
    }
  }

  async reloadFahrtbericht() {
    var mandant = await this.storage.get(StorageKeys.CURRENT_MANDANT);
    var response = await this.webService.sendRequest<Fahrtbericht[]>(`${mandant}/Einsatz/fahrtbericht`, HttpMethod.GET);

    try {
      var dtos = await firstValueFrom(response);

      if (dtos) {

        var fahrtberichtList: Fahrtbericht[] = [];

        for (const item of dtos) {
          const fahrtbericht = new Fahrtbericht();
          fahrtbericht.map(item);
          fahrtbericht.mandant = mandant

          fahrtberichtList.push(fahrtbericht);
        }

        await this.databaseService.insertOrUpdate(fahrtberichtList);
      }
    }
    catch (e) {
      console.error(e)
    }
  }

  async sendFahrtbericht(einsatz: Einsatz, fahrtbericht: Fahrtbericht[]) {
    var mandant = await this.storage.get(StorageKeys.CURRENT_MANDANT);
    await this.webService.sendRequest<Boolean>(`${mandant}/Einsatz/${einsatz.id}/fahrtbericht`, HttpMethod.POST, undefined, fahrtbericht).then(async response => {
      await this.reloadFahrtbericht();
    });
  }

  async confirmEinsatz(einsatz: Einsatz): Promise<void> {
    var mandant = await this.storage.get(StorageKeys.CURRENT_MANDANT);

    await this.webService.sendRequest<EinsatzDto>(`${mandant}/Einsatz/${einsatz.id}/bestaetigt`, HttpMethod.PUT).then(async response => {
      var dto = await firstValueFrom(response);
      if (dto) {
        const einsatz = new Einsatz();
        einsatz.map(dto);
        einsatz.mandant = mandant;
        await this.databaseService.insertOrUpdate([einsatz]);
      }
    });
  }

  async startEinsatz(einsatz: Einsatz): Promise<void> {
    await this.webService.sendRequest<EinsatzDto>(`${await this.storage.get(StorageKeys.CURRENT_MANDANT)}/Einsatz/${einsatz.id}/imEinsatz`, HttpMethod.PUT).then(async response => {
      await firstValueFrom(response);
      await this.reloadEinsaetze();
    });
  }

  private collectDokumenteWithSubDokumente(einsatzId: string, dokument: Dokument, dokumentList: Dokument[]) {
    dokument.einsatzId = einsatzId;
    dokumentList.push(dokument);

    if (dokument.dokumente && dokument.dokumente.length > 0) {
      dokument.dokumente.forEach(subMenu => {
        this.collectDokumenteWithSubDokumente(einsatzId, subMenu, dokumentList);
      });
    }
  }
}
