import { Injectable } from '@angular/core';
import { EinsatzService } from '../einsatz/einsatz.service';
import { MandantService } from '../mandant/mandant.service';
import { MenuService } from '../menu/menu.service';
import { NotificationService } from '../notification/notification.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private einsatzService: EinsatzService,
    private mandantService: MandantService,
    private menuService: MenuService,
    private notificationService: NotificationService,
    private userService: UserService,
  ) {
  }

  async loadInitialData() {
    this.userService.log();
    this.userService.reloadBenutzer();
    this.mandantService.reloadMandanten();
    this.notificationService.initPush()

    this.menuService.reloadMenuNav().then(() => {
      this.menuService.reloadMenusAll()
      this.menuService.reloadNachrichten()
    });

    this.einsatzService.reloadFahrtbericht();
     this.einsatzService.reloadEinsaetze().then(() => {
      this.einsatzService.reloadEinsaetzeDetails();
     });
  }
}
