import {WichtigeInformationDto} from "./wichtige-information-dto";

export class WichtigeInformation {
  className: string = 'WichtigeInformation';

  id: string = '';
  informationen: string = '';

  map(dto: WichtigeInformationDto) {
    this.informationen = dto.informationen || '';
  }
}
