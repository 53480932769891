import { EinsatzDetailDto } from "./einsatz-detail-dto";

export class EinsatzDetail {
  className: string = 'EinsatzDetail';

  id: string = '';
  mandant: string = '';
  titel: string = '';
  fahrzeug: string = '';
  startDatum: string = '';
  endDatum: string = '';
  bereitstellZeit: string = '';
  abfahrtZeit: string = '';
  endZeit: string = '';
  startOrt: string = '';
  endOrt: string = '';
  anzahlPersonen: number = 0;
  transportNummer: string = '';
  wichtigeInformationVerfuegbar: boolean = false;
  teilnehmerlisteVerfuegbar: boolean = false;
  listenVerfuegbar: boolean = false;
  ticketsVerfuegbar: boolean = false;
  kundenInformationVerfuegbar: boolean = false;
  sachbearbeiterInformationVerfuegbar: boolean = false;
  chauffeureInformationVerfuegbar: boolean = false;
  dokumenteInformationVerfuegbar: boolean = false;
  smsAnAlleVerfuegbar: boolean = false;
  fahrberichtPendent: boolean = false;
  letzteMutation: string = '';

  map(dto: EinsatzDetailDto) {
    this.id = dto.id || '';
    this.titel = dto.titel || '';
    this.fahrzeug = dto.fahrzeug || '';
    this.startDatum = dto.startDatum || '';
    this.endDatum = dto.endDatum || '';
    this.bereitstellZeit = dto.bereitstellZeit || '';
    this.abfahrtZeit = dto.abfahrtZeit || '';
    this.endZeit = dto.endZeit || '';
    this.startOrt = dto.startOrt || '';
    this.endOrt = dto.endOrt || '';
    this.anzahlPersonen = dto.anzahlPersonen || 0;
    this.transportNummer = dto.transportNummer || '';
    this.wichtigeInformationVerfuegbar = dto.wichtigeInformationVerfuegbar || false;
    this.teilnehmerlisteVerfuegbar = dto.teilnehmerlisteVerfuegbar || false;
    this.listenVerfuegbar = dto.listenVerfuegbar || false;
    this.ticketsVerfuegbar = dto.ticketsVerfuegbar || false;
    this.kundenInformationVerfuegbar = dto.kundenInformationVerfuegbar || false;
    this.sachbearbeiterInformationVerfuegbar = dto.sachbearbeiterInformationVerfuegbar || false;
    this.chauffeureInformationVerfuegbar = dto.chauffeureInformationVerfuegbar || false;
    this.dokumenteInformationVerfuegbar = dto.dokumenteInformationVerfuegbar || false;
    this.smsAnAlleVerfuegbar = dto.smsAnAlleVerfuegbar || false;
    this.fahrberichtPendent = dto.fahrberichtPendent || false;
    this.letzteMutation = dto.letzteMutation || '';
  }
}
