import { HalteortDto } from "./halteort-dto";

export class Halteort {
  className = 'Halteort'

  id: string = '';

  listeId: string = '';
  bezeichnung: string = '';
  zeit: string = '';
  anzahlPaxZustieg: number = 0;
  anzahlPaxAusstieg: number = 0;
  anzahlPaxBisher: number = 0;
  anzahlPax: number = 0;
  status: number = 0;
  tags: string = '';

  map(dto: HalteortDto) {
    this.id = dto.id ?? '';
    this.bezeichnung = dto.bezeichnung ?? '';
    this.zeit = dto.zeit ?? '';
    this.anzahlPaxZustieg = dto.anzahlPaxZustieg ?? 0;
    this.anzahlPaxAusstieg = dto.anzahlPaxAusstieg ?? 0;
    this.anzahlPaxBisher = dto.anzahlPaxBisher ?? 0;
    this.anzahlPax = dto.anzahlPax ?? 0;
    this.status = dto.status ?? 0;

    this.tags = dto.tags != null ? dto.tags.join() : '';
  }
}
