import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { StorageKeys, StorageService } from '../database/storage/storage.service';
import {Environment} from "../../utils/environment/environment";

@Injectable({
  providedIn: 'root'
})
export class WebService {

  private activeRequests = new Map<string, Observable<any>>();


  constructor(private httpClient: HttpClient,
              private platform: Platform,
              private storage: StorageService
  ) {
  }

  async sendRequest<T = void>(
    functionName: string,
    method: HttpMethod,
    header: HttpHeaders | null = null,
    body: any = null,
    url: string = ''
  ): Promise<Observable<T | void>> {

    let apiUrl = `https://stage.api.tourdriver.ch/${functionName}`;

    if (Environment.isLocalhost() && !Environment.isNativePlatform()) {
      apiUrl = `https://localhost:7101/${functionName}`;
    }

    if (url !== '') {
      apiUrl = url;
    }

    let httpHeaders = new HttpHeaders();

    if (header != null) {
      httpHeaders = header;
    }

    httpHeaders = httpHeaders
      .append('Authorization', 'Bearer ' + (await this.storage.get(StorageKeys.ACCESS_TOKEN) || ''))
      .append('Content-Type', 'application/json')
      .append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Origin', '*');

    const headers = httpHeaders;

    if (typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    let returnValue: Observable<T | void>;

    const existingRequest = this.activeRequests.get(apiUrl); // Direkter Aufruf ohne has()

    if (existingRequest) {
      return of(undefined) as Observable<T | void>;
    } else {
      switch (method) {
        case HttpMethod.GET:
          returnValue = this.httpClient.get<T>(apiUrl, {headers});
          break;
        case HttpMethod.POST:
          returnValue = this.httpClient.post<T>(apiUrl, body, {headers});
          break;
        case HttpMethod.PUT:
          returnValue = this.httpClient.put<T>(apiUrl, body, {headers});
          break;
        case HttpMethod.DELETE:
          returnValue = this.httpClient.delete<T>(apiUrl, {headers, body});
          break;
        default:
          throw new Error('Unsupported HTTP method');
      }

      this.activeRequests.set(apiUrl, returnValue);

      returnValue.subscribe({
        complete: () => {
          setTimeout(() => {
            this.activeRequests.delete(apiUrl);
          }, 10000); // 10000 Millisekunden = 10 Sekunden
        },
        error: () => {
          setTimeout(() => {
            this.activeRequests.delete(apiUrl);
          }, 10000);
        }
      });

    }


    return returnValue;
  }
}

export enum HttpMethod {
  GET,
  POST,
  PUT,
  DELETE
}
