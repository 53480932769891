import { AfterViewInit, Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { DataService } from './services/data/data.service';
import { DatabaseService } from './services/database/database.service';
import { StorageKeys, StorageService } from './services/database/storage/storage.service';
import { NotificationService } from './services/notification/notification.service';
import { UserService } from './services/user/user.service';
import { AuthService } from './services/web/auth.service';

const TOURDRIVER_DATABASE = "tourdriver-database";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {

  public isInitialized: boolean = false;
  showNav: boolean = false;

  constructor(
    private databaseService: DatabaseService,
    private router: Router,
    private storage: StorageService,
    private authService: AuthService,
    private userService: UserService,
    private notificationService: NotificationService,
    private dataService: DataService
  ) {
    router.events.subscribe((val) => {
      this.onTabClick();
    });
  }


  async ngAfterViewInit(): Promise<void> {

    await SplashScreen.show({
      autoHide: false,
    });

    const jeepSqlite = document.querySelector('jeep-sqlite');
    if (jeepSqlite) {
      customElements.whenDefined('jeep-sqlite').then(async () => {
        await this.initializeApp();
        this.isInitialized = true;
      }).catch(error => {
        console.error('Error defining jeep-sqlite element', error);
      });
    } else {
      console.error('jeep-sqlite element not found in DOM');
    }
  }

  onTabClick() {
    const tabBar = document.getElementById('app-tab-bar');

    if (tabBar) {
      if (this.router.url == '/einsatz' || this.router.url == '/kalender' || this.router.url == '/aktuell') {
        //tabBar.style.display = 'flex';
        tabBar.style.height = '56px';
        this.authService.isAuthorized();
        //tabBar.style.transform = 'translateY(0)';
      } else {
        //tabBar.style.transform = 'translateY(100%)';
        //tabBar.style.display = 'none';
        tabBar.style.height = '0px';
      }
    }

  }

  @HostListener("window:beforeunload", ["$event"])
  async unloadHandler(event: Event) {
    await Promise.all([this.databaseService.saveToStore(), this.storage.set(StorageKeys.RETURN_URL, this.router.url)]);
    event.returnValue = false;
    return false;
  }

  async initializeApp() {

    await Promise.all([this.storage.initStorage(), this.databaseService.initializeDatabase()]);
    await this.databaseService.createTables();

    if (!await this.authService.isAuthorized()) {
      await this.router.navigate(['/auth']);
    } else {
      this.dataService.loadInitialData();
      await this.router.navigate(['/einsatz']);
    }

    await SplashScreen.hide();
  }


}
