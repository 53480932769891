import { Injectable } from '@angular/core';
import {
  CapacitorSQLite,
  capSQLiteChanges,
  capSQLiteResult,
  DBSQLiteValues,
  SQLiteConnection,
  SQLiteDBConnection
} from '@capacitor-community/sqlite';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Dokument } from 'src/app/data/einsatz/dokument/dokument';
import { EinsatzDetail } from 'src/app/data/einsatz/einsatz-detail/einsatz-detail';
import { Einsatz } from 'src/app/data/einsatz/einsatz/einsatz';
import { Fahrtbericht } from 'src/app/data/einsatz/fahrtbericht/fahrtbericht';
import { Kunde } from 'src/app/data/einsatz/kunde/kunde';
import { Liste } from 'src/app/data/einsatz/liste/liste';
import { Halteort } from 'src/app/data/einsatz/liste/liste-detail/halteort/halteort';
import { Pax } from 'src/app/data/einsatz/liste/liste-detail/halteort/pax/pax';
import { ListeDetail } from 'src/app/data/einsatz/liste/liste-detail/liste-detail';
import { Sachbearbeiter } from 'src/app/data/einsatz/sachbearbeiter/sachbearbeiter';
import { Teilnehmer } from 'src/app/data/einsatz/teilnehmer/teilnehmer';
import { WichtigeInformation } from 'src/app/data/einsatz/wichtige-information/wichtige-information';
import { Icon } from 'src/app/data/mandant/icon/icon';
import { Mandant } from 'src/app/data/mandant/mandant';
import { MenuNav } from 'src/app/data/menu/menu-nav';
import { Menu } from 'src/app/data/menu/menu/menu';
import { Nachricht } from 'src/app/data/menu/nachricht/nachricht';
import { Benutzer } from 'src/app/data/person/benutzer/benutzer';
import { Chauffeur } from 'src/app/data/person/chauffeur/chauffeur';
import { SQLiteHelper } from 'src/app/extensions/sqlite-helper';
import { Environment } from 'src/app/utils/environment/environment';

const TOURDRIVER_DATABASE = "tourdriver-database";
const TOURDRIVER_DATABASE_ENCRYPTION = "bBMUjbWDPdLXEMLr0o6DmXRiJa1cPXPmzJ41Tor00XB4Tv7DMv867C7FgFsfbdpAHi6ndNHyBjNK8itaXhVZqTvm5w4kYTV8enw4";

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {


  isService: boolean = false;
  sqlitePlugin: any;
  sqlite!: SQLiteConnection;
  private database!: SQLiteDBConnection;
  private dbInitialized = new BehaviorSubject<boolean>(false);

  constructor(
    private platform: Platform,
  ) {
  }

  async initializeDatabase() {
    if (this.sqlite == null) {

      await this.platform.ready();
      await this.initializePlugin();

      if (!Environment.isNativePlatform()) {
        await customElements.whenDefined('jeep-sqlite');
        const jeepSqliteEl = document.querySelector('jeep-sqlite');
        if (jeepSqliteEl != null) {
          await this.initWebStore();
        }
      }
      //await this.database.execute("PRAGMA page_size = 4096;");
      //await this.database.execute("PRAGMA cache_size = 2000;");
    }

    await this.createDBConnection();
    this.dbInitialized.next(true);
  }

  async resetDatabase() {
    await this.sqlite.deleteOldDatabases();
    await this.database.delete();
    await this.initializeDatabase();
  }

  isDatabaseInitialized() {
    return this.dbInitialized.asObservable();
  }

  async createDBConnection() {
    if (this.sqlite) {
      this.database = await this.createConnection(TOURDRIVER_DATABASE, false, 'no-encryption', 1);
      await this.database.open()
    }
  }

  async createTables() {
    await this.run(SQLiteHelper.createTable(new Einsatz()));
    await this.run(SQLiteHelper.createTable(new Benutzer()));
    await this.run(SQLiteHelper.createTable(new Mandant()));
    await this.run(SQLiteHelper.createTable(new Icon()));
    await this.run(SQLiteHelper.createTable(new MenuNav()));
    await this.run(SQLiteHelper.createTable(new Menu()));
    await this.run(SQLiteHelper.createTable(new EinsatzDetail()));
    await this.run(SQLiteHelper.createTable(new Chauffeur()));
    await this.run(SQLiteHelper.createTable(new WichtigeInformation()));
    await this.run(SQLiteHelper.createTable(new Sachbearbeiter()));
    await this.run(SQLiteHelper.createTable(new Kunde()));
    await this.run(SQLiteHelper.createTable(new Dokument()));
    await this.run(SQLiteHelper.createTable(new Teilnehmer()));
    await this.run(SQLiteHelper.createTable(new Liste()));
    await this.run(SQLiteHelper.createTable(new ListeDetail()));
    await this.run(SQLiteHelper.createTable(new Halteort()));
    await this.run(SQLiteHelper.createTable(new Pax()));
    await this.run(SQLiteHelper.createTable(new Nachricht()));
    await this.run(SQLiteHelper.createTable(new Fahrtbericht()));
  }


  async loadTable<T>(
    tablename: string,
    orderBy?: string,
    where?: string
  ): Promise<T[]> {
    let sqlQuery = `SELECT * FROM ${tablename}`;

    if (where) {
      sqlQuery += ` WHERE ${where}`;
    }

    if (orderBy) {
      sqlQuery += ` ORDER BY ${orderBy}`;
    }

    const databaseObjects = await this.query(sqlQuery);
    return databaseObjects?.values || [];
  }

  async run(sqlQuery: string, values: any[] = []): Promise<capSQLiteChanges> {
    const changes = await this.database.run(sqlQuery, values);
    return changes;
  }

  async query(sqlQuery: string): Promise<DBSQLiteValues> {
    const values = await this.database.query(sqlQuery);
    return values;
  }



  async insertOrUpdate<T>(objects: T[], logsEnabled: boolean = false): Promise<capSQLiteChanges> {
    const {query, values} = SQLiteHelper.insertOrUpdate(objects, logsEnabled);

    if (logsEnabled) {
      console.log("Query", query);
      console.log("Values", values);
    }
    const changes = await this.run(query, values);


    if (logsEnabled) {
      console.log("Changes", changes);
    }

    return changes;
  }

  async delete<T>(tablename: string, where?: string): Promise<capSQLiteChanges> {
    const query = SQLiteHelper.delete(tablename, where);
    const changes = await this.run(query);
    return changes;
  }

  /**
   * Plugin Initialization
   */
  initializePlugin(): Promise<boolean> {
    return new Promise(resolve => {
      this.sqlitePlugin = CapacitorSQLite;
      this.sqlite = new SQLiteConnection(this.sqlitePlugin);
      this.isService = true;
      resolve(true);
    });
  }


  /**
   * addUpgradeStatement
   * @param database
   * @param toVersion
   * @param statements
   */
  async addUpgradeStatement(database: string, toVersion: number, statements: string)
    : Promise<void> {
    if (this.sqlite != null) {
      try {
        //TODO: Das noch anschauen
        //await this.sqlite.addUpgradeStatement(database, toVersion, statements);
        return Promise.resolve();
      } catch (error) {
        const errMsg = error instanceof Error ? error.message : String(error);
        return Promise.reject(new Error(errMsg));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${database}`));
    }
  }


  /**
   * Create a connection to a database
   * @param database
   * @param encrypted
   * @param mode
   * @param version
   */
  async createConnection(database: string, encrypted: boolean,
                         mode: string, version: number
  ): Promise<SQLiteDBConnection> {
    if (this.sqlite != null) {
      try {
        const db: SQLiteDBConnection = await this.sqlite.createConnection(
          database, encrypted, mode, version, false);
        if (db != null) {
          return Promise.resolve(db);
        } else {
          return Promise.reject(new Error(`no db returned is null`));
        }
      } catch (error) {
        const errMsg = error instanceof Error ? error.message : String(error);
        return Promise.reject(new Error(errMsg));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${database}`));
    }
  }

  /**
   * Retrieve an existing connection to a database
   * @param database
   */
  async retrieveConnection(database: string):
    Promise<SQLiteDBConnection> {
    if (this.sqlite != null) {
      try {
        return Promise.resolve(await this.sqlite.retrieveConnection(database, false));
      } catch (error) {
        const errMsg = error instanceof Error ? error.message : String(error);
        return Promise.reject(new Error(errMsg));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${database}`));
    }
  }


  /**
   * Check if connection exists
   * @param database
   */
  async isConnection(database: string): Promise<capSQLiteResult> {
    if (this.sqlite != null) {
      try {
        return Promise.resolve(await this.sqlite.isConnection(database, false));
      } catch (error) {
        const errMsg = error instanceof Error ? error.message : String(error);
        return Promise.reject(new Error(errMsg));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Initialize the Web store
   * @param database
   */
  async initWebStore(): Promise<void> {

    if (Environment.isNativePlatform()) {
      return Promise.reject(new Error(`not implemented for this platform: ${this.platform}`));
    }
    if (this.sqlite != null) {
      try {
        await this.sqlite.initWebStore();
        return Promise.resolve();
      } catch (error) {
        const errMsg = error instanceof Error ? error.message : String(error);
        return Promise.reject(new Error(errMsg));
      }
    } else {
      return Promise.reject(new Error(`no connection open`));
    }
  }

  /**
   * Save a database to store
   * @param database
   */
  async saveToStore(): Promise<void> {
    if (Environment.isNativePlatform()) {
      return Promise.reject(new Error(`not implemented for this platform: ${this.platform}`));
    }
    if (this.sqlite != null) {
      try {
        await this.sqlite.saveToStore(TOURDRIVER_DATABASE);
        return Promise.resolve();
      } catch (error) {
        const errMsg = error instanceof Error ? error.message : String(error);
        return Promise.reject(new Error(errMsg));
      }
    } else {
      return Promise.reject(new Error(`no connection open for ${TOURDRIVER_DATABASE}`));
    }
  }


}
