import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {HttpClient} from '@angular/common/http';
import {FullCalendarModule} from '@fullcalendar/angular';
import {IonicStorageModule} from '@ionic/storage-angular';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {EinsatzDetailInfoComponent} from 'src/app/components/einsatz/einsatz-detail-info/einsatz-detail-info.component';
import {SpinnerComponent} from 'src/app/components/spinner/spinner.component';
import {ListItemComponent} from './components/list/list-item/list-item.component';
import {MandantDetailInfoComponent} from './components/mandant/mandant-detail-info/mandant-detail-info.component';
import {SidemenuUserItemComponent} from './components/sidemenu/sidemenu-user-item/sidemenu-user-item.component';
import {ModalComponent} from './components/modal/modal/modal.component';
import {HeaderComponent} from "./components/header/header.component";
import {SidemenuComponent} from './components/sidemenu/sidemenu.component';
import {
  EinstiegPaxComponent
} from './components/einsatz/einsatz-detail/listen/interaktiv/einstieg-pax/einstieg-pax.component';
import {MapComponent} from './components/shared/map/map.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';


export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.error('Missing translation for ' + params.key);
    return params.key;
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
      loader: {
        provide: TranslateLoader,
        useFactory: (setTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot(),
    FullCalendarModule,
    LeafletModule
  ],
  declarations: [
    EinsatzDetailInfoComponent,
    SpinnerComponent,
    MandantDetailInfoComponent,
    SidemenuUserItemComponent,
    ListItemComponent,
    ModalComponent,
    HeaderComponent,
    SidemenuComponent,
    EinstiegPaxComponent,
    MapComponent
  ],
  exports: [
    EinsatzDetailInfoComponent,
    SpinnerComponent,
    SidemenuUserItemComponent,
    MandantDetailInfoComponent,
    TranslateModule,
    ListItemComponent,
    ModalComponent,
    HeaderComponent,
    SidemenuComponent,
    EinstiegPaxComponent,
    MapComponent
  ]
})
export class SharedModule {
}

export function setTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
