<ion-app>
  <ion-tabs>
    <ion-tab-bar id="app-tab-bar">
      <ion-tab-button (click)="onTabClick()" tab="kalender">
        <ion-icon name="calendar-outline"></ion-icon>
      </ion-tab-button>
      <ion-tab-button (click)="onTabClick()" tab="einsatz">
        <ion-icon name="calendar-clear-outline"></ion-icon>
      </ion-tab-button>
      <ion-tab-button (click)="onTabClick()" tab="aktuell">
        <ion-icon name="stopwatch-outline"></ion-icon>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
  <jeep-sqlite></jeep-sqlite>
</ion-app>
