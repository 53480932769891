/*==========================================================================*
*
*                            DBCODES
*
*==========================================================================*
*
* Titel:                     Codes-Define
* Projekt:                   TOURDATA
* Version:                   1.0
* Autor:                     DATA-DICTIONARY.NET (Generator!)
* Datum:                     14.08.2024
*
*==========================================================================*
*
* Programmbeschreibung:      Dieses Modul wurde generiert.
*                            KEINE MANUELLEN AENDERUNGEN VORNEHMEN !
*
*==========================================================================*
*/

export class DBCodes {

  /*--------------------------------------------------------------------------*/

  public static TAB____ALLGEMEIN___ = 0    /* === Beginn der allgemeinen Codearten, d.h. diejenigen, welche generell gültig und keinem speziellen Themenbereich zugeordnet sind === */

  /*--------------------------------------------------------------------------*/

  public static TAB_VERSION = 0    /* Installierte Versionen (eff. Wert in der Kurzbez., Datum im Mutdat) */
  public static CDVERS_DB = 0    /* Datenbankversion */
  public static CDVERS_PROG = 1    /* Programmversion (Skriptdateien sind für diese Version gelaufen) */
  public static CDVERS_AUSWERT = 2    /* Access-Version */
  public static CDVERS_PROG_EFF = 3    /* Effektiv verwendete Programmversion (CDMUTDAT gibt das Datum der Installation an) */
  public static CDVERS_GROESSE = 100  /* DB-Grundgrösse (S,M,L oder X) */
  public static CDVERS_FREMDSCHL = 101  /* 1, wenn mit Fremdschlüsseln */
  public static CDVERS_FUNKT_IND = 102  /* 1, wenn mit Funktionsindizes */
  public static CDVERS_TERMINALSERVER = 200  /* "TS"=Terminalserver-Installation (z.B. stehen Mandanten übergreifende Funktionen nur eingeschränkt zur Verfügung, z.Z. im Aufbau) */

  /*--------------------------------------------------------------------------*/

  public static TAB_BEM_STATUS = 1    /* Bemerkungsstatus */
  public static CDBEM_KEINE = 0    /* keine Bemerkungen vorhanden */
  public static CDBEM_NORMALE = 1    /* Bemerkung ohne Wichtigkeitsvermerk vorhanden */
  public static CDBEM_WICHTIG = 2    /* Wichtige Bemerkung vorhanden */
  public static CDBEM_MELDUNG = 3    /* Meldung an TO vorhanden (nur im Dossier) */
  public static CDBEM_NORMAL_INTERN = 4    /* Bemerkung nur für internen Gebrauch */
  public static CDBEM_WICHTIG_INTERN = 5    /* Wichtige Bemerkung, nur für internen Gebrauch */
  public static CDBEM_IMMER = 8    /* Wichtige Bemerkung, immer anzeigen (nur für Partnerbemerkungen, die im Dossier jedesmal angezeigt werden müssen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_SPRACHCODE = 2    /* Sprachcode (es brauchen nicht alle Sprachen wirklich implementiert zu werden) */
  public static CDSPR_UNABHAENGIG = 0    /* sprachunabhängig */
  public static CDSPR_DEUTSCH = 1    /* Deutsch */
  public static CDSPR_FRANZOESISCH = 2    /* Französisch */
  public static CDSPR_ITALIENISCH = 3    /* Italienisch */
  public static CDSPR_ENGLISCH = 4    /* Englisch */
  public static CDSPR_SPANISCH = 5    /* Spanisch */
  public static CDSPR_PORTUGIESISCH = 6    /* Portugiesisch */
  public static CDSPR_MAX_VORDEFINIERT = 6    /* Letzte Sprache, die vordefiniert ist, d.h. fix zugeteilt */
  public static CDSPR_MAX_GENERELL = 9    /* Letzte Sprache, die im Internet usw. verfügbar ist */
  public static CDSPR_INTERN = 10   /* Zusatzinformationen für internen Gebrauch (z.Z. nur für Textbausteine verwendet) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RABATTCODE = 3    /* Rabattcode */
  public static CDRAB_PROZENT = 0    /* Der Rabatt ist ein Prozentsatz */
  public static CDRAB_BETRAG = 1    /* Der Rabatt ist ein feststehender Betrag  */

  /*--------------------------------------------------------------------------*/

  public static TAB_KOMMCODE = 4    /* Kommissionscode (s.a. Codeart 2026) */
  public static CDKOMM_PROZENT = 0    /* Die Kommission ist ein Prozentsatz */
  public static CDKOMM_BETRAG = 1    /* Die Kommission ist ein feststehender Betrag */

  /*--------------------------------------------------------------------------*/

  public static TAB_FARBCODE = 5    /* Farbcode: Kurzbez=RRRGGGBBB */
  public static CDFARBE_WEISS = 0

  /*--------------------------------------------------------------------------*/

  public static TAB_WOCHENTAGCODE = 6    /* Wochentagsbezeichnungen (siehe auch Codeart 20 mit den parallelen Werten als Bitmap 2^Wochentagcode) */
  public static CDWTAG_MO = 1    /* Montag */
  public static CDWTAG_DI = 2    /* Dienstag */
  public static CDWTAG_MI = 3    /* Mittwoch */
  public static CDWTAG_DO = 4    /* Donnerstag */
  public static CDWTAG_FR = 5    /* Freitag */
  public static CDWTAG_SA = 6    /* Samstag */
  public static CDWTAG_SO = 7    /* Sonntag */

  /*--------------------------------------------------------------------------*/

  public static TAB_MONATSCODE = 7    /* Monatsbezeichnungen */
  public static CDMON_JAN = 1
  public static CDMON_FEB = 2
  public static CDMON_MAE = 3
  public static CDMON_APR = 4
  public static CDMON_MAI = 5
  public static CDMON_JUN = 6
  public static CDMON_JUL = 7
  public static CDMON_AUG = 8
  public static CDMON_SEP = 9
  public static CDMON_OKT = 10
  public static CDMON_NOV = 11
  public static CDMON_DEZ = 12

  /*--------------------------------------------------------------------------*/

  public static TAB_SCHRIFTALIAS = 8    /* Aliasnamen der Schrift aus Codeart 9 */

  /*--------------------------------------------------------------------------*/

  public static TAB_SCHRIFTART = 9    /* Schriftart mit der Bezeichnung in der Form: Schrifttyp,Grösse[,F] */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZAHL = 10   /* Beschreibung von Zahlen in Worten. Die Zahl selbst entspricht dem Code. */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZIFFER = 11   /* Alle Ziffern von 0 bis 366 (Hilfstabelle für Joins) */

  /*--------------------------------------------------------------------------*/

  public static TAB_HERKUNFTSCODE = 12   /* Herkunft von Dossier, Adresse, Werbemat., Interesse (Werte 10-29 mit Codeart 1016 übereinstimmend), unter Codeart 26 sind indiv. Texte mögl. */
  public static CDHERK_LADEPROG = 0    /* Ladeprogramm (aus Tourdata 1) */
  public static CDHERK_TOURDATA = 1    /* Tourdata 2 oder 3 */
  public static CDHERK_INET_VON = 10   /* Erster möglicher Code für im Internet oder via Internet-Schnittstelle erstellte Dossiers */
  public static CDHERK_TN_VON = 10   /* Erster möglicher Code für Tournet-Buchungen */
  public static CDHERK_TN_FILIALE = 11   /* Eigene Filiale über Tournet */
  public static CDHERK_TN_RB = 12   /* Reisebüro über Tournet */
  public static CDHERK_TN_BIS = 19   /* Erster möglicher Code für Tournet-Buchungen */
  public static CDHERK_RM_VON = 20   /* Erster für den Reisemarkt reservierter Code */
  public static CDHERK_RM_TO = 21   /* Direktkunde über Veranstalter-Homepage (bei aktivem Modul durch Codes 31-33 feiner abstufbar oder durch API-Codes 2000-2999 ersetzbar) */
  public static CDHERK_RM_RB = 22   /* Direktkunde über Reisebüro-Homepage (bei aktivem Modul durch API-Codes 2000-2999 ersetzbar) */
  public static CDHERK_RM_RM = 23   /* Direktkunde über Reisemarkt */
  public static CDHERK_RM_CAR = 24   /* Direktkunde über Carferien-Reisemarkt */
  public static CDHERK_RM_FREMD = 28   /* Direktkunde über Homepage eines Drittanbieters (z.B. Zeitung, Details gem. Erf.visum, Codeart 14) */
  public static CDHERK_HP_BUCHUNG = 31   /* Während Buchungsablauf auf der Veranstalter-Homepage */
  public static CDHERK_HP_WERBEMATERIAL = 32   /* Während Werbematerialbestellablauf auf der Veranstalter-Homepage */
  public static CDHERK_HP_NEWSLETTER = 33   /* Während separater Newsletteranmeldung auf der Veranstalter-Homepage */
  public static CDHERK_RM_BIS = 39   /* Letzter für den Reisemarkt reservierter Code (Ausnahme individuelle Homepagecodes) */
  public static CDHERK_DIV_PLABE = 80   /* Von SBB via PLABE-Schnittstelle */
  public static CDHERK_HP_INDIV_VON = 2000 /* Beginn des für individuelle Homepagecodes gem. Codeart 26 reservierten Bereichs (wird z.Z. nur via API gesetzt) */
  public static CDHERK_HP_INDIV_BIS = 2999 /* Ende des für individuelle Homepagecodes reservierten Bereichs */
  public static CDHERK_INET_BIS = 2999 /* Letzter für Internetdossiers reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_AIRLINECODE = 13   /* Offizielle IATA-Airlinecodes (Code=Stellen 1-3 der Ticketnr., Kurzbez=2- oder 3-stelliges Kürzel) */
  public static CDMIN_TICCODE = 0    /* Erster Code, der direkt dem 3-stelligen Ticketcode entspricht (Kurzbez. = 2-stelliges IATA-Kürzel) */
  public static CDMAX_TICCODE = 999  /* Letzter Code, der dem 3-stelligen Ticketcode entspricht */
  public static CDMIN_OHNE_TICCODE = 1000 /* Erster Code, der keine Bedeutung hat (Kurzbez. = 2- oder 3-stelliges IATA-Kürzel) */
  public static CDMAX_OHNE_TICCODE = 9999 /* Letzter Code, der keine Bedeutung hat */

  /*--------------------------------------------------------------------------*/

  public static TAB_PLATTFORM = 14   /* Buchungsplattform gem. Erfassungsvisum bei Herkunftscode 28 (Fremdhomepages) */
  public static CDPLF_PLANETH = 1    /* Planet Holiday */

  /*--------------------------------------------------------------------------*/

  public static TAB_RUNDUNGSCODE = 15   /* Art, wie Beträge gerundet werden */
  public static CDRD_KAUFM = 0    /* kaufmännisch runden (Default): >0 und <0.5 abrunden und ab 0.5  aufrunden */
  public static CDRD_AUFRUNDEN = 1    /* aufrunden, wenn >0 */
  public static CDRD_ABRUNDEN = 2    /* abrunden, wenn >0 */

  /*--------------------------------------------------------------------------*/

  public static TAB_LAND = 16   /* Länder mit ISO-3166-1-Alpha 2-Code (CDKURZBEZ) und nummerisch (CODE) */

  /*--------------------------------------------------------------------------*/

  public static TAB_DATEITYPCODE = 17   /* Mögliche vom Tourdata unterstützte Dateitypen (Erweiterung gem. CDKURZBEZ) */
  public static CDDTYP_DEFAULT = 0    /* NULL=Standardformat der internen Textverarbeitung (RTF ohne Erweiterung) */
  public static CDDTYP_SPEICHERBAR_VON = 10   /* Erster für von der Tourdata-Textverarbeitung speicherbare Formate reservierter Code */
  public static CDDTYP_WINWORD = 10   /* Word für Windows (doc) */
  public static CDDTYP_SPEICHERBAR_BIS = 19   /* Letzter für von der Tourdata-Textverarbeitung  speicherbare Formate reservierter Code */
  public static CDDTYP_LINK_VON = 50   /* Erstes Format, das im Tourdata nur verlinkt werden kann */
  public static CDDTYP_LINK_BIS = 99   /* Letztes Format, das im Tourdata nur verlinkt werden kann */

  /*--------------------------------------------------------------------------*/

  public static TAB_LAENDERGRUPPE = 18   /* Gruppen von Ländern */
  public static CDLGR_INLAND = 0    /* Inland */
  public static CDLGR_AUSLAND = 1    /* Ausland allgemein */

  /*--------------------------------------------------------------------------*/

  public static TAB_MANDANTENCODE = 19   /* Art der Mandantencodierung */
  public static CDMA_UNABHAENGIG = 0    /* Mandantenunabhängig ("00") */
  public static CDMA_LOGIN = 1    /* Login-Mandant */
  public static CDMA_CODE = 2    /* Codemandant */
  public static CDMA_PA = 3    /* Partnermandant */
  public static CDMA_STR = 4    /* Standardreise-Produktmandant */
  public static CDMA_PR = 5    /* Produktmandant */
  public static CDMA_DS = 6    /* Dossiermandant */

  /*--------------------------------------------------------------------------*/

  public static TAB_WOCHENTAGE_BITMAP = 20   /* Wochentage in Bitmap-Strukturen (abgeleitet von 2^Wochentagcode), enthält auch kombinierte Tage */
  public static CDWTAG_BITMAP_MO = 2    /* Montag */
  public static CDWTAG_BITMAP_DI = 4    /* Dienstag */
  public static CDWTAG_BITMAP_CDSORT_EINZELTAGE_BIS = 7    /* KEIN Codeeintrag: CDSORT <= diesem Wert sind die Einzeltage, darüber einzelne vordefinerte Kombis */
  public static CDWTAG_BITMAP_MI = 8    /* Mittwoch */
  public static CDWTAG_BITMAP_DO = 16   /* Donnerstag */
  public static CDWTAG_BITMAP_FR = 32   /* Freitag */
  public static CDWTAG_BITMAP_SA = 64   /* Samstag */
  public static CDWTAG_BITMAP_SO = 128  /* Sonntag */
  public static CDWTAG_BITMAP_EIN = 1024 /* Nur beim Reiseprogramm zur Unterscheidung von den alten direkt gesetzten Wochentagen während einer Übergangsphase */

  /*--------------------------------------------------------------------------*/

  public static TAB_PERIODIZITAETSCODE = 21   /* Periodiziäten */
  public static CDPERIZ_TAG = 1    /* täglich */
  public static CDPERIZ_WOCHE_FR_SPLIT = 15   /* Wöchentlich am Freitag (Daten pro Monat splitten) */
  public static CDPERIZ_WOCHE_SA_SPLIT = 16   /* Wöchentlich am Samstag (Daten pro Monat splitten) */
  public static CDPERIZ_14_TAGE_FR_SPLIT = 35   /* Alle 14 Tage, jeweils am Freitag (Daten pro Monat splitten) */
  public static CDPERIZ_2X_MONAT = 50   /* 2x pro Monat, jeweils am 1. und 16. */
  public static CDPERIZ_MONAT = 60   /* Monatlich, jeweils am 1. des Monats */

  /*--------------------------------------------------------------------------*/

  public static TAB_RELATIVES_DATUM = 22   /* Platzhalter für Datumsfelder, die zur Ausführungszeit in Relation zum aktuellen Datum umgerechnet werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_INTERVALL = 23   /* Ausführungsintervall */
  public static CDINTV_MANUELL = 0    /* nur manuell */
  public static CDINTV_SOFORT = 1    /* Sofortausführung */
  public static CDINTV_MINUTENBASIS = 100  /* Im Minutenbereich ./. dieser Code = alle X Minuten */
  public static CDINTV_MIN_MINUTEN = 101  /* Erster für Anzahl Minuten reservierter Bereich */
  public static CDINTV_MAX_MINUTEN = 199  /* Letzter für Anzahl Minuten reservierter Bereich */
  public static CDINTV_STUNDENBASIS = 200  /* Für den Stundenbereich ./. dieser Code=alle X Stunden */
  public static CDINTV_MIN_STUNDEN = 201  /* Erster für Anzahl Stunden reservierter Bereich */
  public static CDINTV_MAX_STUNDEN = 299  /* Letzter für Anzahl Stunden reservierter Bereich */
  public static CDINTV_TAGESBASIS = 300  /* Im Tagesbereich ./. dieser Code = alle X Tage */
  public static CDINTV_MIN_TAGE = 301  /* Erster für Anzahl Tage reservierter Bereich */
  public static CDINTV_TAEGLICH = 301  /* täglich (Default) */
  public static CDINTV_MAX_TAGE = 399  /* Letzter für Anzahl Tage reservierter Bereich */
  public static CDINTV_HALBMONATLICH = 400  /* halbmonatlich */
  public static CDINTV_MONATSBASIS = 400  /* Im Monatsbereich ./. dieser Code = alle X Monate */
  public static CDINTV_MIN_MONATE = 401  /* Erster für Anzahl Monate reservierter Bereich */
  public static CDINTV_MAX_MONATE = 499  /* Letzter für Anzahl Monate reservierter Bereich */

  /*--------------------------------------------------------------------------*/

  public static TAB_UEBERMITTLUNGSCODE = 24   /* Art der Übermittlung */
  public static CDUEBM_KEINE = 0    /* keine (nur Vorlage) */
  public static CDUEBM_NETZWERK = 10   /* Datei auf lokalem Rechner oder Netzwerk */
  public static CDUEBM_SMB = 15   /* Server Message Block (Filesharing-Protokoll) */
  public static CDUEBM_SFTP = 20   /* SFTP */
  public static CDUEBM_FTP = 21   /* FTP */
  public static CDUEBM_WEBSERVICE = 40   /* Webservice */
  public static CDUEBM_GOOGLE_TAB = 41   /* Google Spreadsheet */
  public static CDUEBM_EMAIL = 50   /* E-Mailanhang */
  public static CDUEBM_EMAIL_TEXT = 51   /* Email, Daten direkt im Text (nicht als Anhang) */
  public static CDUEBM_EMAIL_HTML = 52   /* E-Mail basierend auf HTML-Template (z.Z. fix gem. 5039/5000), Daten als Anhang */
  public static CDUEBM_ODBC = 60   /* Tabelle in einer externen Datenbank, welche über ODBC angesprochen wird */
  public static CDUEBM_API = 90   /* API (nur bei individuell programmierten Übermittlungen möglich) */

  /*--------------------------------------------------------------------------*/

  public static TAB_DATENSCHUTZCODE = 25   /* Behandlung in Bezug auf den Datenschutz */
  public static CDDATS_DEFAULT = 0    /* NULL=nicht anwendbar bzw. Default je nach Art: bei Zusatzkriterien auf der Adresse wie Code 7, bei vermerkten Zusatzinfos wie Code 3 */
  public static CDDATS_KURZFRISTIG = 3    /* Daten werden nur kurzfristig benötigt, der Inhalt kann ca. 1/2 Jahr nach Rückreise gelöscht werden (nur bei vermerkten Zusatzinfos möglich) */
  public static CDDATS_AUFBEW_PFLICHT = 6    /* Gesetzliche Aufbewahrungsfrist (10 Jahre) */
  public static CDDATS_PARTNER = 7    /* Die Daten werden nur gelöscht, wenn der Partner selbst gelöscht oder anonymisiert wird */
  public static CDDATS_UNPERSOENLICH = 9    /* Nicht Personen bezogen, d.h. der Inhalt wird weder anonymisiert, gelöscht noch auf Auszügen ausgewiesen */

  /*--------------------------------------------------------------------------*/

  public static TAB_HERKUNFTSCODE_INDIV = 26   /* Codes analog Codeart 12, jedoch mandantenabhängig und mit zusätzlichen individuellen Codes */

  /*--------------------------------------------------------------------------*/

  public static TAB_LANDLISTE_GENERELL = 27   /* Generell gültige Länderlisten gem. ISO-3661-1 Alpha2 (gespeichert in Codeart 16) für bestimmte Funktionen (s.a. Codeart 5037 für Mand. abh.) */
  public static CDLANDG_EU = 10   /* Liste der Kürzel aller EU-Länder */

  /*--------------------------------------------------------------------------*/

  public static TAB_ICONTYPCODE = 28   /* Icon Dateitypen */
  public static CDICON_UNDEF = 0    /* nicht definiert: kein Icon oder auf Dateisystem */
  public static CDICON_GIF = 1    /* GIF */
  public static CDICON_PNG = 2    /* PNG */
  public static CDICON_JPG = 3    /* JPG */

  /*--------------------------------------------------------------------------*/

  public static TAB____ALLGEMEIN_BEREICHE___ = 100  /* === Beginn der allgemeinen Codearten, welche für Codeartenbereiche verwendet werden === */

  /*--------------------------------------------------------------------------*/

  public static TAB_INDIV_REGIONEN_VON = 100  /* Erste für individuelle Regionen reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB_INDIV_REGIONEN_BIS = 109  /* Letzte für individuelle Regionen reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB____PARTNER___ = 999  /* === Beginn der Partner-Codearten (eigentlich ab Codeart 1000, dann wird es jedoch immer nach dem PACODE einsortiert...) === */

  /*--------------------------------------------------------------------------*/

  public static TAB_PACODE = 1000 /* Partnercode */
  public static CDPA_KUNDE = 1    /* Kunde */
  public static CDPA_ANBIETER = 2    /* Anbieter */
  public static CDPA_ORT = 6    /* Ort, der für die genauere Bestimmung von (Halte-)Orten verwendet wird */
  public static CDPA_BANK = 8    /* Bank */
  public static CDPA_MANDANT = 9    /* Mandant */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANREDECODE = 1001 /* Anredecode für Adressen */
  public static CDANR_INDIVIDUELL = 0    /* vom Benutzer eingegebene individuelle Anrede  */

  /*--------------------------------------------------------------------------*/

  public static TAB_BRIEFANREDE = 1002 /* Briefanrede (gekoppelt mit ANREDECODE) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PASTATUS = 1003 /* Partnerstatus */
  public static CDPASTAT_AKTIV = 0    /* Normalfall */
  public static CDPASTAT_UNVOLLSTAENDIG = 1    /* Adresse unvollständig (d.h. Ort fehlt), muss im Falle einer Buchung vervollständigt werden */
  public static CDPASTAT_GESPERRT = 5    /* keine Neuverwendung erlaubt */
  public static CDPASTAT_NICHT_GEPRUEFT = 6    /* Adresse durch Kunden im Internet selbst erfasst, im Td noch nicht überprüft (Doubletten, Fakeadr usw)... */
  public static CDPASTAT_BUCHHALTUNG = 7    /* Adresse gesperrt, nur noch für Buchhaltungsabteilung sichtbar */
  public static CDPASTAT_GELOESCHT = 9    /* wird sobald als möglich physisch gelöscht */

  /*--------------------------------------------------------------------------*/

  public static TAB_FAKTSTEUERCODE = 1004 /* Faktura-Steuercode */
  public static CDFAKT_NORMAL = 0    /* Normale Fakturasteuerung */
  public static CDFAKT_OHNE_SEITE1 = 1    /* Seite 1 beim Erfassen der Faktura überspringen */

  /*--------------------------------------------------------------------------*/

  public static TAB_EINZSCHEINCODE = 1005 /* Art des Einzahlungsscheins */
  public static CDEZ_KEIN = 0    /* kein Einzahlungsschein */
  public static CDEZ_ESR = 1    /* ESR bzw. ESR+ */
  public static CDEZ_QR_RG = 2    /* QR-Rechnung */
  public static CDEZ_BAZ = 10   /* BAZ (Deutschland) */

  /*--------------------------------------------------------------------------*/

  public static TAB_KOMM_DETAILCODE = 1006 /* Art der Detaillierung der Kommission auf der Rechnung */
  public static CDKDET_GESAMT = 0    /* Nur Gesamtkommission (./. 1790.--) */
  public static CDKDET_DETAILLIERT = 1    /* Detailliert (./. 20% 1000.--, ./. 8% 790.--) */

  /*--------------------------------------------------------------------------*/

  public static TAB_TELCODE1 = 1007 /* Normale Telefoncodes (ACHTUNG! die Codes müssen sich von denen aus TELCODE2 unterscheiden) */
  public static CDTEL_TEL1 = 1    /* 1. Telefon (standardmässig Privattelefon) */
  public static CDTEL_TEL2 = 2    /* 2. Telefon (standardmässig Geschäft) */
  public static CDTEL_TEL3 = 3    /* 3. Telefon (standardmässig für Mobiltelefon) */
  public static CDTEL_TEL_SERVICE_RB = 3    /* Telefonische Buchungsunterstützung für Reisebüros (nur bei Mandanten im Tournet) */
  public static CDTEL_TEL_BUCHHALTUNG = 5    /* Telefon der Buchhaltungsabteilung (nur beim Mandanten; für Mahnlisten usw.) */
  public static CDTEL_FAX_BUCHHALTUNG = 6    /* Fax der Buchhaltungsabteilung (nur beim Mandanten; für Mahnlisten usw.) */
  public static CDTEL_TEL_OPERATING = 7    /* Telefon des Operatings (nur beim Mandanten; für Zimmerlisten usw.) */
  public static CDTEL_FAX_OPERATING = 8    /* Fax des Operatings (nur beim Mandanten; für Zimmerlisten usw.) */
  public static CDTEL_TEL_NOTFALL = 9    /* Notfallkontakt */

  /*--------------------------------------------------------------------------*/

  public static TAB_TELCODE2 = 1008 /* Kommunikations-Nummern (Codes müssen sich von denen aus TELCODE1 unterscheiden) */
  public static CDTEL_FAX = 10   /* Nummer des Telefax-Anschlusses */
  public static CDTEL_TELEX = 19   /* Nummer des Telex-Anschlusses */
  public static CDTEL_EMAIL = 20   /* Email-Adresse */
  public static CDTEL_EMAIL_SERVICE_RB = 21   /* Email-Adresse für die direkte Buchungsunterstützung des Reisebüros (nur im Tournet) */
  public static CDTEL_EMAIL_INTERNET_KOPIE = 22   /* Email-Adresse, an die Kopien sämtlicher Internetbestätigungen geschickt werden (nur Tournet-DB) */
  public static CDTEL_EMAIL_BUCHHALTUNG = 25   /* Emailadresse der Buchhaltungsabteilung */
  public static CDTEL_MAX_SUCHTELCODE = 29   /* Letzter TELCODE, dessen Eintrag bei der generellen Suche nach Telefonnummern gesucht wird und der entsprechend indiziert ist */
  public static CDTEL_FTP = 30   /* FTP-Server (nur im Tournet) */
  public static CDTEL_PUSH_URL = 31   /* URL, z.Z. nur für die Bestätigung einer FTP-Übermittlung im Tournet */
  public static CDTEL_ADRLINK_PARAM = 90   /* Dyn. Parameter für externen Adressbearbeitungslink (beginnt mit "="; s. a.Codeart 5000,Codes 115+116) */
  public static CDTEL_TOKEN_VON = 1000 /* Erster für Push-Token reservierter Bereich */
  public static CDTEL_CHAUFFEUR_APP_VON = 1000 /* Erster für die ID der Chauffeur-App reservierter Bereich */
  public static CDTEL_CHAUFFEUR_APP_ANDROID = 1000 /* Token für die ID der Chauffeur-App für Android-Handys und -Tablets */
  public static CDTEL_CHAUFFEUR_APP_APPLE = 1005 /* Token für die ID der Chauffeur-App für iPhone und iPad */
  public static CDTEL_CHAUFFEUR_APP_BIS = 1019 /* Letzter für die ID der Chauffeur-App reservierter Bereich */
  public static CDTEL_TOKEN_BIS = 1999 /* Letzter für Push-Token reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_ABTCODE = 1009 /* Abteilungscode */

  /*--------------------------------------------------------------------------*/

  public static TAB_BERECHTCODE = 1010 /* Berechtigungscode */
  public static CDBER_NUR_APP = -1   /* Bei vorhandenem PERSVIS kann nur via App zugegriffen werden, nicht im Tourdata selbst (Fahrer, RL) */
  public static CDBER_DEFAULT = 0    /* Default-User, nur für Erstanmeldung */
  public static CDBER_ADRESSEN = 5    /* nur Adressen erfassen und bereinigen */
  public static CDBER_VERKAEUFER = 10   /* Verkäufer */
  public static CDBER_PROD_O_STR = 20   /* Produzent ohne Standardreise (Kontingente) */
  public static CDBER_PRODUZENT = 25   /* Produzent mit Standardreise */
  public static CDBER_STAB = 40   /* Stabsstelle und Abteilungsleitung */
  public static CDBER_DISPONENT = 45   /* Disponent */
  public static CDBER_CHEFDISPONENT = 46   /* Disponent, der zusätzlich zu den eigenen Mandanten auch alle gem. Codeart 5009/150 disponieren darf. */
  public static CDBER_BUCHHALTUNG = 50   /* Buchhaltung und Geschaeftsleitung */
  public static CDBER_SYSTEM = 90   /* Systemverwalter (Codes, Mandanten) */

  /*--------------------------------------------------------------------------*/

  public static TAB_WMAT_CODE = 1011 /* Werbematerial (s.a. 1021 für Brieftexte und 1043 für fakultative 100er-Gruppenzuteilung) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZAHLUNGSCODE = 1012 /* Art der Rechnungsbegleichung */
  public static CDZAHL_VESR = 0    /* VESR */
  public static CDZAHL_LSV = 1    /* Lastschriftverfahren (BDD, LSV+) */
  public static CDZAHL_BANKEINZUG = 2    /* Bankeinzug */
  public static CDZAHL_OHNE_VESR = 9    /* Ohne Einzahlungsschein */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZBED_CODE = 1013 /* Art der Berücksichtigung der Tage für die An- und Restzahlung */
  public static CDZBED_RECHNUNG_ABREISE = 1    /* Anzahlungsdatum gem. Rechnungsdatum, Restzahlungsdatum gem. Abreisedatum */
  public static CDZBED_RECHNUNG = 2    /* An- und Restzahlungsdatum gem. Rechnungsdatum */
  public static CDZBED_RECHNUNG_NKORR = 12   /* analog 2, das Verfalldatum wird jedoch nie dem Abreisedatum angepasst (Verfall nach Abreise möglich) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PERSSTATUS = 1014 /* Personenstatus */
  public static CDPERSSTAT_AKTIV = 0    /* Person ist aktiv */
  public static CDPERSSTAT_GESPERRT = 5    /* Person ist vorübergehend gesperrt */
  public static CDPERSSTAT_GELOESCHT = 9    /* Person wird sobald als möglich physisch gelöscht */

  /*--------------------------------------------------------------------------*/

  public static TAB_PERSFUNKCODE = 1015 /* Funktion einer Person */
  public static CDPERSFUNK_ROLLE = -10  /* Benutzerrolle ohne Visum (für Rechteverwaltung; ohne Codeeintrag in Datenbank) */
  public static CDPERSFUNK_KEINE = 0    /* keine spezielle Funktion (NULL) */
  public static CDPERSFUNK_MIN_NICHT_IN_STATISTIK = 1    /* Minimalwert, der in Umsatz- und ähnlichen Statistiken nicht als Teilnehmer gezählt wird (Mitarbeiter) */
  public static CDPERSFUNK_MIN_DISPO = 10   /* Minimalwert, der bei der Dispo automatisch vorgeschlagen wird (und Auswirkungen auf Statistiken hat) */
  public static CDPERSFUNK_MIN_FAHRER = 10   /* Minimalwert für Fahrer in der Dispo */
  public static CDPERSFUNK_FAHRER = 10   /* Fahrer */
  public static CDPERSFUNK_WERKSTATT = 13   /* Werkstattmitarbeiter, der auch fahren darf */
  public static CDPERSFUNK_INDIV_FAHRER = 14   /* Code standardmässig nicht vorhanden, kann bei Bedarf für eine individuelle Fahrerart verwendet werden */
  public static CDPERSFUNK_AUSH_FAHRER = 15   /* Aushilfsfahrer */
  public static CDPERSFUNK_FREMDFAHRER = 16   /* Fremdfahrer (erhalten in der App nur die Dispodaten und keine internen Informationen) */
  public static CDPERSFUNK_FAHRLEHRER = 17   /* Fahrlehrer */
  public static CDPERSFUNK_FAHRER_SAISONIER = 18   /* Saisoniers */
  public static CDPERSFUNK_SCHULBUS_FAHRER = 19   /* Fahrer von Schulbussen */
  public static CDPERSFUNK_MAX_FAHRER = 19   /* Letzter für Fahrer reservierter Code */
  public static CDPERSFUNK_REISELEITER = 20   /* Reiseleitung */
  public static CDPERSFUNK_MIN_REISELEITER = 20   /* Erster für Reiseleiter reservierter Wert in der Dispo */
  public static CDPERSFUNK_HOSTESS = 21   /* Hostess, Stewart */
  public static CDPERSFUNK_INDIV_REISELEITER = 24   /* Code standardmässig nicht vorhanden, kann bei Bedarf für eine individuelle Reiseleiterart verwendet werden */
  public static CDPERSFUNK_RL_EINF = 25   /* Einführung in Reiseleitung */
  public static CDPERSFUNK_STUDIENREISE = 28   /* Studienreise */
  public static CDPERSFUNK_MAX_REISELEITER = 29   /* Letzter für Reiseleiter reservierter Wert */
  public static CDPERSFUNK_MAX_DISPO = 49   /* Maximalwert, der bei der Dispo autmatisch vorgeschlagen wird (und Auswirkungen auf Statistiken hat) */
  public static CDPERSFUNK_MAX_NICHT_IN_STATISTIK = 49   /* Maximalwert, der in Umsatz- und ähnlichen Statistiken nicht als Teilnehmer gezählt wird (Mitarbeiter) */
  public static CDPERSFUNK_HOTLINE = 50   /* Generelle Auskunftsperson */
  public static CDPERSFUNK_KONTAKT = 51   /* Kontaktperson (beim Mandanten für Preismeldungen) */
  public static CDPERSFUNK_GASTROKONTAKT = 52   /* Kontaktperson für Gastrofälle(beim Mand. für Meldungen bzgl. Textänderungen ohne sep. Gastrodossiers) */
  public static CDPERSFUNK_MIN_INDIV = 80   /* Erster für individuelle Funktionen eines Veranstalters reservierter Code (im Prog. wie Kunden o.Code) */
  public static CDPERSFUNK_MAX_INDIV = 99   /* Letzter für individuelle Funktionen eines Veranstalters reservierter Code */
  public static CDPERSFUNK_FOTOTREFF = 100  /* Teilnehmer, der das Fototreffen organisiert */
  public static CDPERSFUNK_MIN_NUR_TEILNEHMER = 100  /* Erste Funktion, die nur bei Teilnehmern aber nicht bei Personen selbst vorkommt */
  public static CDPERSFUNK_MIN_TECHNISCHE_EINTRAEGE = 500  /* Ab diesem Code stehen die Einträge bei normalen Teilnehmerauswahlen nicht zur Verfügung */
  public static CDPERSFUNK_BASIS_GASTRO_CHECKBOX = 500  /* Basis für Gastro-Checkboxen (bei den Seen) reservierte Codes: Einzelne Codes sind als Bitmap ab dieser Basis kombinierbar */
  public static CDPERSFUNK_GASTRO_CHECKBOX1 = 501  /* Erste Gastro-Checkbox */
  public static CDPERSFUNK_GASTRO_CHECKBOX2 = 502  /* Zweite Gastro-Checkbox (z.Z. nicht programmiert) */
  public static CDPERSFUNK_GASTRO_CHECKBOX3 = 504  /* Dritte Gastro-Checkbox (z.Z. nicht programmiert) */
  public static CDPERSFUNK_GASTRO_CHECKBOX4 = 508  /* Vierte Gastro-Checkbox (z.Z. nicht programmiert) */
  public static CDPERSFUNK_MAX_GASTRO_CHECKBOX = 563  /* Letzter für Gastro-Checkboxen reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAVCODE = 1016 /* Art der Partnerverbindung (Codes 10-29 mit Codeart 12 übereinstimmend; s.a. Codearten 1026 und 1027) */
  public static CDPAV_MARKT_TO = 1    /* Im Tournet generell buchbare Veranstalter: Markt (Tournet) - Tour Operator */
  public static CDPAV_MARKT_TO_INTERN = 2    /* Wie Code 1, jedoch nur für berechtigte Reisebüros sichtbar (keine grauen Icons) */
  public static CDPAV_TO_FILIALE = 11   /* Im Tournet buchbare Veranstalter (Tour Operator) - durch Filiale */
  public static CDPAV_TO_RB = 12   /* Im Tournet buchbare Veranstalter - durch Reisebüro */
  public static CDPAV_TO_RB_INAKTIV = 18   /* Im Tournet durch ein Reisebüro noch nicht buchbare Veranstalter (temporär bis zur Aktivierung) */
  public static CDPAV_TO_RB_GESPERRT = 19   /* Im Tournet durch ein Reisebüro NICHT buchbare Veranstalter (absichtlich gesperrt) */
  public static CDPAV_RM_VON = 20   /* Erste für den Reisemarkt reservierte Buchungsvariante */
  public static CDPAV_RM_TO = 21   /* Reisemarkt Touroperator Homepage: TO (buchbar) - TO (Einstieg) */
  public static CDPAV_RM_RB = 22   /* Reisemarkt Reisebüro Homepage:TO (buchbar) - Reisebüro (Einstieg) */
  public static CDPAV_RM_RM = 23   /* Reisemarkt: TO (buchbar) - Reisemarkt (Einstieg) */
  public static CDPAV_RM_CAR = 24   /* Reisemarkt Carferien: TO (buchbar) - Carferien (Einstieg) */
  public static CDPAV_RM_FREMD = 28   /* Reisemarkt fremde Homepage: TO (buchbar) - Fremde Organisation */
  public static CDPAV_RM_BIS = 29   /* letzte für den Reisemarkt reservierte Buchungsvariante */
  public static CDPAV_HP_WARTUNG = 31   /* Verwaltung der Homepage - durch Partner */
  public static CDPAV_AUSWERTUNGEN = 41   /* Auswertungen aufbereiten: Veranstalter - Reisebüro, das Veranstalterlisten aufbereiten darf */
  public static CDPAV_WERBUNG = 101  /* Freundschaftswerbung (Werber - geworbene Adresse) */
  public static CDPAV_LSV_GRUPPE = 111  /* Empfänger der LSV bzw. BDD-Sammelabrechnung - ursprünglicher Rechnungsempfänger */
  public static CDPAV_FIRMENGRUPPE = 112  /* Firmengruppe (Mutterhaus - Tochterfirma; Verbindung wird auch für Provisionsabrechnungen verwendet) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PABESTAETIGUNGSCODE = 1017 /* Weg der Bestätigungen an den  Kunden  (Codes 300-399 haben Entsprechungen in Codeart 3002, Ds.gesch.) */
  public static CDPABEST_MIN_INTERN = 0    /* Erste Tourdata intern mögliche Bestätigungsart */
  public static CDPABEST_DEFAULT = 0    /* Standard des Mandanten */
  public static CDPABEST_DRUCK = 1    /* Ausdruck */
  public static CDPABEST_EMAIL = 20   /* Email-Anhang (RTF bzw. PDF gem. genereller Einstellung) */
  public static CDPABEST_MAX_INTERN = 299  /* Letzter Tourdata intern möglicher Code */
  public static CDPABEST_MIN_TOURNET = 300  /* Erste im Tournet mögliche Bestätigungsart */
  public static CDPABEST_RTF = 300  /* Als Textdatei im RTF-Format */
  public static CDPABEST_MIN_TOURDATA = 310  /* Erster für Tourdata als Empfängersystem reservierter Wert */
  public static CDPABEST_TOURDATA3 = 310  /* CMIR-Record für Tourdata */
  public static CDPABEST_MAX_TOURDATA = 319  /* letzter für Tourdata reservierter Wert */
  public static CDPABEST_MIN_UMBRELLA = 320  /* Erster für Umbrella reservierter Wert */
  public static CDPABEST_UMBRELLA_NT = 320  /* B2B-Record, Version 2.1 für Umbrella NT (bis April 2008) */
  public static CDPABEST_UMBRELLA_CMIR = 321  /* CMIR-Record für Umbrella */
  public static CDPABEST_MAX_UMBRELLA = 329  /* letzter für Umbrella reservierter Wert */
  public static CDPABEST_MIN_HIT = 330  /* Erster für HIT reservierte Wert */
  public static CDPABEST_HIT = 330  /* B2B-Record, Version 2.1 für HIT (Hotelplan) */
  public static CDPABEST_MAX_HIT = 339  /* letzter für HIT reservierter Wert */
  public static CDPABEST_MIN_TICOFFICE = 340  /* erster für TicOffice reservierter Wert */
  public static CDPABEST_TICOFFICE = 340  /* B2B-Record, Version 2.1 für TicOffice (SBB) */
  public static CDPABEST_MAX_TICOFFICE = 349  /* letzter für TicOffice reservierter Wert */
  public static CDPABEST_MIN_TOURONLINE = 350  /* erster für Tour Online reservierter Wert */
  public static CDPABEST_TOURONLINE = 350  /* TD-XML über DLL (bis April 2008) */
  public static CDPABEST_TOURONLINE_CMIR = 351  /* Touronline mit CMIR-Records über DLL */
  public static CDPABEST_MAX_TOURONLINE = 359  /* letzter für Tour Online reservierter Wert */
  public static CDPABEST_MAX_TOURNET = 399  /* Letzte im Tournet mögliche Bestätigungsart */

  /*--------------------------------------------------------------------------*/

  public static TAB_RUHETAGCODE = 1018 /* Ruhetag */
  public static CDRUHETAG_UNBEKANNT = 0    /* Ruhetag nicht bekannt */
  public static CDRUHETAG_MO = 1    /* Montag */
  public static CDRUHETAG_DI = 2    /* Dienstag */
  public static CDRUHETAG_MI = 3    /* Mittwoch */
  public static CDRUHETAG_DO = 4    /* Donnerstag */
  public static CDRUHETAG_FR = 5    /* Freitag */
  public static CDRUHETAG_SA = 6    /* Samstag */
  public static CDRUHETAG_SO = 7    /* Sonntag */
  public static CDRUHETAG_KEINER = 50   /* Kein Ruhetag */
  public static CDRUHETAG_KEINER_CAR = 51   /* Kein Ruhetag für Carunternehmen */

  /*--------------------------------------------------------------------------*/

  public static TAB_MARKTPLATZCODE = 1019 /* Das Angebot steht auf den entsprechenden Marktplätzen zur Verfügung (die Werte können addiert werden) */
  public static CDMARKT_CARTOUR = 128  /* Cartour */
  public static CDMARKT_TAXI = 256  /* Taxi */

  /*--------------------------------------------------------------------------*/

  public static TAB_REGIONENCODE = 1020 /* Angebotsregion */

  /*--------------------------------------------------------------------------*/

  public static TAB_WMAT_TEXTBAUSTEIN = 1021 /* Im Access für den Werbematerial verwendet: Code muss, wenn vorhanden, mit Codeart 1011 übereinstimmen */

  /*--------------------------------------------------------------------------*/

  public static TAB_NEWSLETTERSTATUS = 1022 /* Bestimmt, ob und wie Newsletter zugestellt werden */
  public static CDNLSTAT_NICHT_DEFINIERT = 0    /* nicht definiert, abgespeichert als NULL */
  public static CDNLSTAT_WENIG_ABBESTELLT_VON = 1    /* Bei aktiver Option "W" werden die Codes von hier bis WENIG_ABBESTELLT_BIS durch Code 2 ersetzt */
  public static CDNLSTAT_KEIN = 1    /* kein Newsletter erwünscht */
  public static CDNLSTAT_ABBESTELLT = 2    /* Newsletter abbestellt */
  public static CDNLSTAT_INAKTIVIERT = 5    /* (automatisch) inaktiviert, wegen z.B. ungültiger Email-Adresse */
  public static CDNLSTAT_WENIG_ABBESTELLT_BIS = 6    /* Letzter für Codes, die bei Option "W" durch Code 2 ersetzt werden, reservierter Code */
  public static CDNLSTAT_PENDENT_VON = 7    /* Erster für die "Bestätigung durch den Kunden ausstehend" reservierter Code */
  public static CDNLSTAT_NICHT_BESTAETIGT = 8    /* Newsletter bestellt, aber noch nicht bestätigt (Double-Opt-In ausstehend) */
  public static CDNLSTAT_AUTOM_NICHT_BESTAETIGT = 9    /* Newsletter automatisch auf bestellt gesetzt, aber noch nicht bestätigt (Opt-In ausstehend) */
  public static CDNLSTAT_PENDENT_BIS = 9    /* Letzter für die "Bestätigung durch den Kunden ausstehend" reservierter Code */
  public static CDNLSTAT_EMAIL_VON = 10   /* Email-Versand des Newsletters ab diesem Code */
  public static CDNLSTAT_WENIG_EMAIL_VON = 10   /* Bei aktiver Option "W" werden die Codes von hier bis WENIG_EMAIL_BIS durch Code 10 ersetzt */
  public static CDNLSTAT_EMAIL = 10   /* Newsletter wird per Email verschickt */
  public static CDNLSTAT_NICHT_ZUSTELLBAR = 11   /* Der Newsletter konnte nicht zugestellt werden (möglicherweise falsche Email-Adresse) */
  public static CDNLSTAT_WENIG_EMAIL_BIS = 14   /* Letzter für Codes, die bei Option "W" durch Code 10 ersetzt werden, reservierter Code */
  public static CDNLSTAT_EMAIL_OHNE_TRACKER = 15   /* Newsletter darf nur ohne Trackinginformationen per Email verschickt werden */
  public static CDNLSTAT_EMAIL_BIS = 19   /* Email-Versand bis zu diesem Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_MGACODE = 1024 /* Art der Mitgliedschaft */
  public static CDMGA_KEINE = 0    /* keine Mitgliedschaft */
  public static CDMGA_ADR = 1    /* Mitgliedschaft pro Adresse */
  public static CDMGA_UNPERS = 2    /* Mitgliedschaft pro Teilnehmer, aber unpersönlich, d.h. übertragbar und nicht auf Adresse vermerkt */
  public static CDMGA_PERS = 3    /* Mitgliedschaft persönlich, d.h. in Personentabelle vermerkt */

  /*--------------------------------------------------------------------------*/

  public static TAB_MGASTATUS = 1025 /* Status der Mitgliedschaftsart */
  public static CDMGASTAT_AKTIV = 0    /* Mitgliedschaftsart ist aktiv und generell verwendbar */
  public static CDMGASTAT_INTERN = 2    /* Mitgliedschaftsart kann nur intern ausgewählt werden (also nicht im Reisemarkt/Tournet) */
  public static CDMGASTAT_ADRVERW = 5    /* Mitgliedschaftsart ist aktiv, aber nur intern in der Adressverwaltung auswählbar */
  public static CDMGASTAT_GELOESCHT = 9    /* Mitgliedschaftsart kann für neue Adressen nicht mehr verwendet werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAVCODE_VON = 1026 /* Texte für die Partnerverbindungscodes aus Sicht PAID_VON (Codedefines gem.  Codeart 1016) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAVCODE_NACH = 1027 /* Texte für die Partnerverbindungscodes aus Sicht PAID_NACH (Codedefines gem. Codeart 1016) */

  /*--------------------------------------------------------------------------*/

  public static TAB_MGSTATUS = 1028 /* Status der Mitgliedschaft */
  public static CDMGSTAT_KEINE = 0    /* Mitgliedschaft der Art "keine" (MGACODE 0) */
  public static CDMGSTAT_ERFASST = 1    /* Mitgliedschaft erfasst, Dossier für Mitgliedschaftsrechnung noch nicht eröffnet */
  public static CDMGSTAT_MIN_DS = 2    /* Erster Status, der zwingend mit einem Dossier verknüpft ist */
  public static CDMGSTAT_DSERFASST = 2    /* Dossier für Mitgliederrechnung erfasst, noch nicht alle Rechnungen bezahlt */
  public static CDMGSTAT_MIN_OK = 5    /* Erster Status, der für den Kartendruck berechtigt */
  public static CDMGSTAT_DSOK = 5    /* Dossier Status ok und alle Rechnungen bezahlt */
  public static CDMGSTAT_MAX_DS = 5    /* letzter Status, der zwingend mit einem Dossier verknüpft ist */
  public static CDMGSTAT_GRATIS = 6    /* Gratismitgliedschaft ohne Rechnung (kein Preis oder vom Sachbearbeiter gewählt) */
  public static CDMGSTAT_MAX_OK = 7    /* Letzter Status, der für den Kartendruck berechtigt */

  /*--------------------------------------------------------------------------*/

  public static TAB_MGAERNEUERUNGSCODE = 1029 /* Art, wie Mitgliedschaften erneuert werden können */
  public static CDMGAERN_KEINE = 0    /* (NULL)=keine Erneuerung möglich */
  public static CDMGAERN_MIN_RECHNUNG = 200  /* Erste Erneuerungsart auf feste Rechnung */
  public static CDMGAERN_MAX_RECHNUNG = 299  /* Letzte Erneuerungsart auf feste Rechnung */
  public static CDMGAERN_MIN_EINLADUNG = 300  /* Erste Erneuerungsart für Einladungen mit automatischer Löschung bei Nichtbezahlen */
  public static CDMGAERN_MAX_EINLADUNG = 399  /* Letzte Erneuerungsart für Einladungen mit automatischer Löschung bei Nichtbezahlen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PREISCODE_ERNEUERUNG = 1030 /* Preiscode für Mitgliedschafts-Erneuerungen */
  public static CDPRNEU_NORMAL = 0    /* Normalpreis */
  public static CDPRNEU_ERMAESSIGT = 1    /* Ermässigter Preis */
  public static CDPRNEU_GRATIS = 9    /* Erneuerung ist gratis möglich */

  /*--------------------------------------------------------------------------*/

  public static TAB_MGERNEUERUNGSSTATUS = 1031 /* Zustand der aktuellen Erneuerung (1-5) bzw. Möglichkeit zur zukünftigen Erneuerung (6-9) */
  public static CDMGERN_KEINE = 0    /* NULL=für diese Mitgliedschaftsart nicht vorgesehen */
  public static CDMGERN_MOEGL_BEZAHLUNG_VON = 1    /* Erster Wert, der bei Bezahlung auf 6 (Erneuerung) zurückgesetzt wird */
  public static CDMGERN_AUTOMATISCH = 1    /* Die Mitgliedschaft wurde automatisch eröffnet und kann bei Nichtbezahlung automatisch wieder gelöscht werden. */
  public static CDMGERN_ERINNERT = 2    /* Kunde nochmals auf die automatische Erneuerung aufmerksam gemacht, löschen bei Nichtbezahlung möglich........ */
  public static CDMGERN_ERWUENSCHT = 4    /* Kunde hat autom. erneuerten Mitgliedschaft zugestimmt oder bereits benutzt (keine autom. Löschung) */
  public static CDMGERN_MOEGL_BEZAHLUNG_BIS = 5
  public static CDMGERN_ZUKUENFTIG_VON = 6    /* Erste Art, wie zukünftige Erneuerungen erfolgen */
  public static CDMGERN_MOEGLICH = 6    /* Die Mitgliedschaft kann automatisch erneuert werden */
  public static CDMGERN_ERSTELLT = 7    /* Automatische Erneuerung wurde erstellt (möglicherweise aber wieder gelöscht, weil nicht bezahlt) */
  public static CDMGERN_NEIN = 9    /* Automatische Erneuerung nicht erwünscht */
  public static CDMGERN_ZUKUENFTIG_BIS = 9    /* Letzte Art, wie Erneuerungen erfolgen können */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAVSUBCODE = 1032 /* Genauere Spezifikation eines PAVCODEs (je 50 Codes pro PAVCODE ab PAVCODE*50, sowie 50 generelle Cd.) */
  public static CDPAVS_GENERELL_VON = 0    /* Erster für Codes, die bei verschiedenen PAVCODEs eingesetzt werden können, reservierter Wert */
  public static CDPAVS_NORMAL = 0    /* (NULL)=keine genauere Beschreibung nötig (Default) */
  public static CDPAVS_GENERELL_BIS = 49   /* Letzter für generelle Codes reservierter Wert */
  public static CDPAVS_GRUPPEN_GROESSE = 50   /* Multiplikationsfaktor für Bereichsberechnung (PAVCODE*CDPAVS_GRUPPEN_GROESSE bis +_GRUPPEN_GROESSE-1) */
  public static CDPAVS_RM_FREMD_TOP = 1410 /* zu PAVCODE 28: Es werden nur die Top-Angebote im XML-Format übermittelt */
  public static CDPAVS_RM_FREMD_ALLE = 1411 /* zu PAVCODE 28: Es werden alle Reisedaten im XML-Format übermittelt */
  public static CDPAVS_RM_FREMD_ALLE_PLUS_PRO_REISE = 1412 /* zu PAVCODE 28: Wie PAVCODE 1411, zusätzlich wird pro Standardreise ein separates XML erstellt */
  public static CDPAVS_RM_FREMD_PRICEFEED = 1420 /* zu PAVCODE 28: Die Preisinformation werden nach Kuoni-Vorgaben aufbereitet (Dateiname gem. Mandant) */
  public static CDPAVS_FW_PRUEFEN_VON = 5060 /* Erster zu prüfender Subcode für die Gutscheine der Freundschaftswerbung */
  public static CDPAVS_FW_NEUKDGS_PEND = 5060 /* Neukundengutschein ausstehend */
  public static CDPAVS_FW_NEUKDGS_OK = 5062 /* Neukundengutschein erstellt */
  public static CDPAVS_FW_PRUEFEN_BIS = 5065 /* Letzter zu prüfender Subcode für die Gutscheine der Freundschaftswerbung */
  public static CDPAVS_FW_NEUKDGS_VERF = 5067 /* Neukundengutschein verfallen */
  public static CDPAVS_FW_GS_OK = 5068 /* Gutscheine erstellt */
  public static CDPAVS_PROVISIONSABRECHNUNG = 5610 /* zu PAVCODE 112: Provisionsabrechnung über Mutterhaus */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZBED_TEXT = 1033 /* Zahlungsbed.text wie er z.Z. nur im Internet verwendet wird (CDKURZBEZ entspricht der TXBID, s.a. Codeart 1034 für vollautom. Internetbuch.) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZBED_TEXT_AUTOM = 1034 /* Zahlungsbedingungstext für vollautom. Internetbuchungen (bis Version 3.44b war die Landliste noch unter dieser Codeart, neu siehe 5037) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAGCODE = 1035 /* Partnergeschichtsart */
  public static CDPAG_ADR_GESPERRT = 15   /* Adresse gesperrt (Status auf 5 geändert) */
  public static CDPAG_ADR_GELOESCHT = 19   /* Adresse gelöscht (Status auf 9 geändert) */
  public static CDPAG_ADR_ENTSPERRT = 25   /* Adresse entsperrt (Status von 5 auf <> 9 geändert) */
  public static CDPAG_ADR_GEPRUEFT = 26   /* Adresse geprüft (Status von 6 auf <> 5, 9 geändert) */
  public static CDPAG_ADR_REAKTIVIERT = 29   /* Adresse reaktiviert (Status von 9 geändert) */
  public static CDPAG_NAME_AEND = 200  /* Anrede, Vorname oder Name verändert */
  public static CDPAG_ADR_AEND = 201  /* Adresszus., Strasse, Land, PLZ u/o Ort geändert */
  public static CDPAG_SPRACH_AEND = 202  /* Änderung der Sprache */
  public static CDPAG_TEL_AEND = 210  /* Änderung der Telefonnummer(n) */
  public static CDPAG_EMAIL_AEND = 217  /* Emailadresse geändert */
  public static CDPAG_EMAIL_UNGUELTIG = 218  /* Emailadresse ungültig */
  public static CDPAG_NEWSLETTER_AEND = 220  /* Newsletterhäkchen geändert */
  public static CDPAG_BESTAET_ART_AEND = 221  /* Gewünschte Bestätigungsart geändert */
  public static CDPAG_KOMM_AEND = 225  /* Kommission oder Alternativkommission geändert (z.Z. nur via Skript möglich) */
  public static CDPAG_INTERESSENSREGELN = 230  /* Interessensregeln geändert */
  public static CDPAG_INTERESSE = 235  /* Status eines Interessensgebietes direkt in der Adresse verändert */
  public static CDPAG_INTERESSE_BESTELLUNG = 236  /* Status eines Interessensgebiets bei einer Bestellung verändert */
  public static CDPAG_BEARB_MANDANT = 240  /* Bearbeitungsmandant wurde manuell geändert */
  public static CDPAG_BEARB_MANDANT_AUTO = 241  /* Bearbeitender Mandant wurde durch die erste Buchung automatisch verändert */
  public static CDPAG_HOMEPAGE_PW_BEGRUESSUNG = 250  /* Begrüssungsmail zur Homepageanmeldung geschickt (mit Link, um ein Passwort zu hinterlegen) */
  public static CDPAG_HOMEPAGE_PW_VERGESSEN = 251  /* Mail zum Setzen eines neuen Passworts verschickt */
  public static CDPAG_HOMEPAGE_PW_GEANDERT = 255  /* Passwort geändert */
  public static CDPAG_HOMEPAGE_PW_GELOESCHT = 259  /* Passwort zur Anmeldung entfernt */
  public static CDPAG_ZKR_AEND = 270  /* Zusatzkriterium geändert */
  public static CDPAG_ZKR_JA = 275  /* Häkchen-Zusatzkriterium angekreuzt */
  public static CDPAG_ZKR_NEIN = 276  /* Häkchen-Zusatzkriterium entfernt */
  public static CDPAG_NEWSLETTER_EXTERN = 280  /* Newsletteranmeldung bei externem Programm */
  public static CDPAG_NEWSLETTER_EXTERN_NOK = 281  /* Newsletteranmeldung bei externem Programm fehlgeschlagen */
  public static CDPAG_KDNR_AEND = 290  /* Kundennr. verändert (z.Z. nur via Skript möglich) */
  public static CDPAG_AUTO_ADRAEND = 291  /* Automatische Adressänderung (z.Z. nur via Skript möglich) */
  public static CDPAG_FREMDSYSTEMID_AEND = 292  /* Fremdsystem-ID geändert */
  public static CDPAG_TRAVID_AEND = 295  /* Travi-Id und/oder Verbindungsart geändert (nur Tournet) */
  public static CDPAG_DS_UEBERGEBEN = 296  /* Dossiers und Posten an andere Kundennr. übergeben (z.Z. nur via Skript möglich) */
  public static CDPAG_DS_UEBERNOMMEN = 297  /* Dossiers und Posten von anderer Kundennr. übernommen (z.Z. nur via Skript möglich) */
  public static CDPAG_DS_GELOESCHT = 299  /* Altes Dossier gelöscht (Archivierungsprozess) */
  public static CDPAG_GS_ERSTELLT = 300  /* Gutschein manuell erstellt */
  public static CDPAG_GS_GEDRUCKT = 301  /* Manuell erstellten Gutschein ausgedruckt */
  public static CDPAG_GS_EMAIL = 302  /* Manuell erstellten Gutschein per E-Mail verschickt */
  public static CDPAG_ZUST_NEU = 800  /* Zuständigkeit hinzugefügt (unter gleichem Code auch bei der Produktgeschichte vorhanden) */
  public static CDPAG_ZUST_PERS_AEND = 801  /* Zuständige Person geändert (unter gleichem Code auch bei der Produktgeschichte vorhanden) */
  public static CDPAG_ZUST_GELOESCHT = 809  /* Zuständigkeit gelöscht (unter gleichem Code auch bei der Produktgeschichte vorhanden) */
  public static CDPAG_DATENSCHUTZ_VON = 1000 /* Erster für Datenschutzbelange reservierter Geschichtseintrag */
  public static CDPAG_DATENSCHUTZ_AEND_INFOMAIL = 1010 /* Information zur Datenschutzänderung per Mail verschickt */
  public static CDPAG_DATENSCHUTZ_BESTAETIGT = 1011 /* Datenschutzbestimmungen zur Kenntnis genommen */
  public static CDPAG_DATENBEARB_ZUSTAND = 1012 /* Datenbearbeitungszustand verändert (im Text werden NEUER Zustand, fak. Kontaktarten gem. Codeart 1050 und freier Text angefügt) */
  public static CDPAG_DATENBEARB_AUSZUG = 1015 /* Datenbearbeitungsauszug für ganze Adresse erstellt */
  public static CDPAG_DATENBEARB_TEILAUSZUG = 1016 /* Datenbearbeitung Teilauszug für eine bestimmte Person erstellt */
  public static CDPAG_DATENSCHUTZ_BIS = 1099 /* Letzter für Datenschutzbelange reservierter Geschichtseintrag */
  public static CDPAG_EXTERNE_DATEI = 9000 /* Verweis auf externe Datei mit Namen PAID + PAGLAUFNR + Orginalendung */
  public static CDPAG_MANUELL = 9900 /* Manueller Eintrag */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZUSTCODE = 1036 /* Zuständigkeitscode (Codeart 1054 für Codes 5000-5999 für Zuständigkeiten ausserhalb des Mandanten, Codeart 2960 für Prod.abhängige) */
  public static CDZUST_BERECHTIGUNG_VON = 1    /* Beginn des für die von den früheren Berechtigungsstufen abgeleiteten Zuständigkeiten reservierten Bereichs (spätere Erweiterungen möglich) */
  public static CDZUST_PROG_ANMELDUNG = 10   /* Berechtigung für das Anmelden am Programm */
  public static CDZUST_ADRESSEN = 100  /* Berechtigung für die Adressverwaltung */
  public static CDZUST_PRODUKTION = 200  /* Berechtigung für das Einrichten von Landleistungen und Transportketten */
  public static CDZUST_LANDLEISTUNG_LESEN = 205  /* Darf die Landleistungen anschauen (v.a. wg. Kontingenten und PAX-Listen für Verkaufsmitarbeiter) */
  public static CDZUST_TRANSPORTMITTEL = 210  /* Berechtigung für das Einrichten von Transportmitteln */
  public static CDZUST_STANDARDREISEN = 240  /* Berechtigung für das Einrichten von Standardreisen inkl. Preisen */
  public static CDZUST_STAMMDATEN = 260  /* Bei den Systemeinstellungen Systemtabellen, Codes, Destinationen, Standardrouten, Transportges., Terminvorlagen einrichten. */
  public static CDZUST_RESERVATIONEN = 300  /* Schnellbuchen und Dossiers bearbeiten */
  public static CDZUST_RES_ERWEITERT = 301  /* Erweiterte Reservationsfunktionen (wie z.B. unvollständige Fragebogendaten als vollständig akzeptieren) */
  public static CDZUST_BAUKASTEN = 305  /* Individuelle Transport- und Landleistungen importieren oder manuell hinzufügen, anpassen, löschen (Trsp- und LL-Register beim Schnellbuchen) */
  public static CDZUST_DOSSIER_BELEGDAT = 310  /* Belegdatum beim Verbuchen einer Rechnung anpassen */
  public static CDZUST_EINSTANDSPREISE = 320  /* Einstandspreise in Preistabelle und Kosten in Kopfdaten sicht- und änderbar */
  public static CDZUST_DOSSIER_SPERREN = 330  /* Dossiers kurze Zeit für sich sperren, sodass niemand anderes diese bearbeiten darf */
  public static CDZUST_DOSSIER_ADMIN = 390  /* Von anderen Mitarbeitern gesperrte Dossiers entsperren, Teilnehmeränderungen auch noch kurz vor Abflug durchführen + evtl. Erf.Mand. ändern */
  public static CDZUST_BUCHHALTUNG = 400  /* Berechtigung für die Buchhaltung */
  public static CDZUST_BH_AUSWERTUNGEN = 410  /* Berechtigung für die Auswertungen in der Buchhaltungsverwaltung */
  public static CDZUST_DISPOSITION = 500  /* Berechtigung für die Disposition */
  public static CDZUST_DISPO_VERBUNDENE_MAND = 510  /* Berechtigung für die Disposition verbunderer Mandanten */
  public static CDZUST_GUTSCHEINE = 600  /* Darf Gutscheinprodukte und -regeln eröffnen, ändern oder löschen. */
  public static CDZUST_INTERNET = 700  /* Berechtigung für das Internetregister */
  public static CDZUST_TEXTBAUSTEINE = 720  /* Berechtigung für die Textbausteine */
  public static CDZUST_AUSWERT = 800  /* Berechtigung für die Auswertungsregister */
  public static CDZUST_AUSWERT_GRUPPE_BASIS = 800  /* Dieser Wert + Code (1-9) gem. Codeart 5800 = Berechtigung für entsprechende Auswertungsgruppe von List & Label bzw. Access */
  public static CDZUST_AUSWERT_GRUPPE1 = 801  /* Für die 1. Auswertungsgruppe (Codeart 5801) reservierter Code */
  public static CDZUST_AUSWERT_GRUPPE2 = 802  /* Für die 2. Auswertungsgruppe (Codeart 5802) reservierter Code */
  public static CDZUST_AUSWERT_GRUPPE3 = 803  /* Für die 3. Auswertungsgruppe (Codeart 5803) reservierter Code */
  public static CDZUST_AUSWERT_GRUPPE4 = 804  /* Für die 4. Auswertungsgruppe (Codeart 5804) reservierter Code */
  public static CDZUST_AUSWERT_GRUPPE5 = 805  /* Für die 5. Auswertungsgruppe (Codeart 5805) reservierter Code */
  public static CDZUST_AUSWERT_GRUPPE6 = 806  /* Für die 6. Auswertungsgruppe (Codeart 5806) reservierter Code */
  public static CDZUST_AUSWERT_GRUPPE7 = 807  /* Für die 7. Auswertungsgruppe (Codeart 5807) reservierter Code */
  public static CDZUST_AUSWERT_GRUPPE8 = 808  /* Für die 8. Auswertungsgruppe (Codeart 5808) reservierter Code */
  public static CDZUST_AUSWERT_GRUPPE9 = 809  /* Für die 9. Auswertungsgruppe (Codeart 5809) reservierter Code */
  public static CDZUST_MAND_WECHSEL_AUSWERT = 820  /* Mandantenwechsel bei den Auswertungen erlaubt */
  public static CDZUST_AUSWERT_DOKU = 850  /* Berechtigung für das Dokumentenregister bei den Auswertungen */
  public static CDZUST_ADMIN_ALLGEMEIN = 905  /* Zugriff auf Codebaum, Interessensregeln und Admin-Register bei den Systemeinstellungen, darf den SAP-Export pro Rechnung manuell auslösen */
  public static CDZUST_ARCHIVIERUNG = 910  /* Berechtigung für die Archivierungsfunktionen */
  public static CDZUST_BENUTZERVERWALTUNG = 930  /* Berechtigung für die Benutzerverwaltung */
  public static CDZUST_BENUTZERROLLEN = 931  /* Berechtigung für die Rollenverwaltung */
  public static CDZUST_TOURNET_ADMIN = 990  /* Nur im Tournet: Darf auch fremde Dossiers anschauen */
  public static CDZUST_BERECHTIGUNG_BIS = 999  /* Ende des für die von den früheren Berechtigungsstufen reservierten Bereichs */
  public static CDZUST_GENERELL_VON = 1000 /* Beginn des Bereichs mit den generellen Zuständigkeiten */
  public static CDZUST_GEN_MAIL_ABSENDER = 1010 /* Standard-Mailabsender */
  public static CDZUST_DOK_MAIL_ABSENDER = 1011 /* Mailabsender für den Dokumentenversand (Rechnung, Voucher, Reiseprogramm) */
  public static CDZUST_GUTSCHEINDOK_MAIL_ABSENDER = 1012 /* Mailabsender für den Dokumentenversand von Gutscheindossiers (GS-Verkauf, Kulanz) */
  public static CDZUST_AUFTRAG_AUTOM_MAIL = 1025 /* Genereller Absender für automatische Mails zu Auftragsfahrtendossiers (z.B. Umfragen) */
  public static CDZUST_DISPO_MAIL_ABSENDER = 1030 /* Genereller Absender für automatische Mails der Disposition (z.B. Einsatzpläne) */
  public static CDZUST_BH_MAIL_ABSENDER = 1040 /* Genereller Absender für Buchhaltungs-E-Mails an Kunden (z.B. BDD) */
  public static CDZUST_ZUSKRIT_MUT = 1050 /* Benutzer darf alle Zusatzkriterien mit ZKROBLIG 3 mutieren, ungeachtet der Mandanten-Einstellungen */
  public static CDZUST_INTERESSE_MUT = 1051 /* Exklusive Interessensgebiete bearbeiten */
  public static CDZUST_ADRESSEN_ZUSAMMENLEGEN = 1052 /* Berechtigung für das Zusammenlegen von Doppeladressen */
  public static CDZUST_DATENSCHUTZ = 1055 /* Zuständig für Datenschutzbelange (ausserhalb Buchhaltung sperren, anonymisieren, physisch löschen, Auszug) */
  public static CDZUST_MITGLIEDSCHAFTEN = 1056 /* Druckdatum von Mitgliedschaften von Hand setzen oder löschen */
  public static CDZUST_NEWSLETTER_IMPORT = 1057 /* Darf Newsletteradressen importieren, sofern das entsprechende Modul aktiv ist */
  public static CDZUST_MAND_OHNE_BENUTZER = 1058 /* Mandant anzeigen ohne Benutzerverwaltung */
  public static CDZUST_ADRESS_IMPORT = 1059 /* Darf Adressen importieren, sofern das entsprechende Modul aktiv ist */
  public static CDZUST_LOG = 1060 /* Darf Logeinträge auswerten und analysieren */
  public static CDZUST_TESTDB = 1065 /* Darf die Testdatenbank löschen und neu laden (über alle Mandanten hinweg) */
  public static CDZUST_TESTDB_MAIL_EMPFAENGER = 1066 /* Erhält bei Testdatenbanken Mails, SMS + Push-Up-Nachrichten anstelle des normalen Kunden oder Anbieters, wenn Name nicht Wort "TEST" enthält */
  public static CDZUST_DASHBOARD = 1070 /* Darf das Dashboard verwenden */
  public static CDZUST_NUMMERNVERWALTUNG = 1100 /* Berechtigung zur Verwaltung individiueller Nummern für Gutscheincodes, Reisegeutscheine usw. */
  public static CDZUST_GUTSCHEIN_MANUELL = 1110 /* Darf manuell Gutscheine ausstellen, sofern die entsprechende Art dafür vorgesehen sind */
  public static CDZUST_DISPO_LOHN = 1120 /* Berechtigung zum Ansehen und Erfassen der Lohnfelder im Dispoerfassungsfenster */
  public static CDZUST_SELL_REPORT_MAIL = 1210 /* Standard-Mailabsender für Sell- & Reports */
  public static CDZUST_ANBIETER_MAIL = 1220 /* Standard-Mailabsender für übrige Listen (ohne Sell & Report) an Anbieter/Agent */
  public static CDZUST_ANBIETER_BH_MAIL = 1221 /* Absender für automatische Buchhaltungsmails an Anbieter (z.B. EP-Abrechnung; Default gem. Code 1220) */
  public static CDZUST_ANBIETER_FLUGLISTEN_MAIL = 1222 /* Absender Fluglisten-E-Mails an Anbieter/Agent (wird z.Z. nur in der Automationstabelle verwendet) */
  public static CDZUST_ANBIETER_HOTELLISTEN_MAIL = 1223 /* Absender für Hotellisten E-Mails an Anbieter/Agent (wird z.Z. nur in der Automationstabelle verwendet) */
  public static CDZUST_OFF_ABEST_MAILKOPIE = 1310 /* Erhält Kopien von Offert- und Auftragsbestätigungs-E-Mails */
  public static CDZUST_PROVABR_MAILKOPE = 1316 /* Erhält Kopien von Provisionsabrechnungs-E-Mails */
  public static CDZUST_MARKETING_MAIL = 1320 /* Empfänger von Marketing-Mails bei vordefinierten Änderungen einer Standardreise */
  public static CDZUST_GENERELL_BIS = 1999 /* Ende des Bereichs mit generellen Zuständigkeiten */
  public static CDZUST_AGENDA_VON = 2000 /* Beginn des Bereichs mit Agenda bezogenen Zuständigkeiten */
  public static CDZUST_AGENDA_SUPERUSER = 2000 /* Agendaeinträge, für die sonst niemand zuständig ist, so wie die von gelöschten Sachbearbeitern */
  public static CDZUST_AGENDA_AKTIV = 2001 /* Agenda aktiv (nur relevant, wenn pro Mitarbeiter indiv. gem.Codeart 5009, Code 1100, Kurzbez mit "I") */
  public static CDZUST_AGENDA_DOK_SUPERUSER = 2010 /* Erhält Dossiers kurz vor Abreise, die weder Doku-Sachbearbeiter noch Doku-Versanddatum enthalten */
  public static CDZUST_AGENDA_TICKETVERSAND = 2011 /* Erhält Dossiers kurz vor Abreise, bei denen noch nicht alle Tickets aufbereitet wurden */
  public static CDZUST_AGENDA_DS_SUPERUSER = 2012 /* Erhält Dossiers, die kurz vor Abreise neu gebucht, im Teilnehmerregister verändert oder storniert wurden */
  public static CDZUST_AGENDA_APP = 2013 /* Erhält Dossiers, die kurz vor Abreise noch nicht an die App übermittelt wurden */
  public static CDZUST_AGENDA_DS_KDWUNSCH = 2020 /* Erhält kurzfristige Kundenwünsche zu einem Dossier, die auf elektronischem Weg eingehen (z.B. Antworten auf Upselling-Pushnachrichten) */
  public static CDZUST_AGENDA_ADR_PRUEFEN = 2050 /* Berechtigt zu Prüfung/Zusammenlegen von Adressen. Erhält, sofern nicht "zusätzlich", Agendaeinträge Adressprüfung bei Internetbuchungen. */
  public static CDZUST_AGENDA_LL_INET_GELOESCHT = 2060 /* Erhält Agendaeinträge, wenn eine LL durch Löschen der STR oder des STR-I'nethäkch. nicht mehr im Inet ist */
  public static CDZUST_AGENDA_DS_FLUG_STORNIERT = 2070 /* Erhält Agendaeinträge, wenn ein gebuchter Flug aus einem Dossier storniert oder herabgesetzt wurde. */
  public static CDZUST_AGENDA_ZLG_PRUEFEN = 2080 /* Erhält Agendaeinträge für Dossiers, bei denen die An- oder Restzahlungen geprüft werden müssen */
  public static CDZUST_AGENDA_DECKUNGSBEITRAG = 2100 /* Erhält Meldungen, wenn die Marge automatisch berechneter Einstandspreise unter einen bestimmten Wert fällt oder kein Preis gefunden wurde. */
  public static CDZUST_AGENDA_STAMMDATEN = 2120 /* Erhält bei Fehlerhaften Stammdaten die entsprechenden Fehlermeldungen. */
  public static CDZUST_AGENDA_GASTRO = 2301 /* Meldung an Gastroabteilung, wenn dafür kein separates Dossier in einem zweiten Mandanten erfasst wird (Kursschifffahrt) */
  public static CDZUST_AGENDA_BIS = 2999 /* Ende des Bereichs mit Agenda bezogenen Zuständigkeiten */
  public static CDZUST_PROD_VON = 4000 /* Erster für produktabhängige Zuständigkeiten reservierter Code */
  public static CDZUST_PROD_BIS = 4999 /* Letzter für produktabhängige Zuständigkeiten reservierter Code */
  public static CDZUST_ZUSTCODE2_VON = 5000 /* Beginn des (auch) für ausserhalb des Mandanten reservierten Codebereichs gem. Codeart 1054 */
  public static CDZUST_ANBIETER_VON = 5200 /* Beginn des für Anbieter-Zuständigkeiten gem. Codeart 1054 reservierten Codebereichs */
  public static CDZUST_ANBIETER_BIS = 5399 /* Ende des für Anbieter-Zuständigkeiten reservierten Codebereichs */
  public static CDZUST_ZUSTCODE2_BIS = 5999 /* Ende des (auch) für ausserhalb des Mandanten reservierten Codebereichs */
  public static CDZUST_APP_VON = 8000 /* Beginn für App-bezogene Zuständigkeiten */
  public static CDZUST_APP_CHAUFFEUR_RL = 8010 /* Zuständig f. allgem. Chauffeur- + RL-Fragen oder wenn kein TRK-/DS-SB vorhanden ist auch für Fahrtber */
  public static CDZUST_APP_CHAUFFEUR_DISPO = 8011 /* Zuständig für Chat-Meldungen vom Chauffeur an die Disposition, sofern kein eindeutiger Sachbearbeiter zugeordnet wurde. */
  public static CDZUST_APP_CHAUFFEUR_MASTERLOGIN = 8019 /* Kann Chauffeur-App aus Sicht einzelner Chauffeure anschauen */
  public static CDZUST_APP_CHAUFFEUR_FAHRBERICHT_MAIL = 8030 /* Erhält Kopien der in der Chauffeur-App erstellten Fahrberichte als E-Mail */
  public static CDZUST_APP_BIS = 8999 /* Ende des Bereichs für App-Zuständigkeiten */
  public static CDZUST_INTERNET_VON = 9000 /* Beginn des Bereichs mit Internet-Zuständigkeiten (ohne solche, die auch die Agenda benötigen) */
  public static CDZUST_INTERNET_SUPERUSER = 9000 /* Internet-Dinge, für die sonst niemand zuständig ist, so wie eingehende Internet-Buchungen verteilen */
  public static CDZUST_INTERNET_PREIS = 9201 /* Empfänger für automatische Meldungen möglicherweise falscher Internetpreise */
  public static CDZUST_INTERNET_BORDMANIFEST = 9301 /* Bordmanifest kontrollieren, das im Internet erfasst wurde */
  public static CDZUST_INTERNET_UMFRAGE_ABSENDER = 9310 /* Absenderadresse für automatische Umfrage-Emails */
  public static CDZUST_INTERNET_UMFRAGE = 9311 /* Mailempfänger für ausgefüllte Umfragen (zur Kontrolle) */
  public static CDZUST_INTERNET_UMFRAGE_NICHT_GEPRUEFT = 9312 /* Agenda- oder Mailempfänger von Umfragen, deren Status auf "nicht geprüft" stehen */
  public static CDZUST_INTERNET_BUCHUNG_KURZFRISTIG = 9321 /* Erhält bei kurzfristigen Buchungen aus dem Internet ein SMS und/oder eine E-Mailkopie der Kundenbestätigung */
  public static CDZUST_INTERNET_KOPIE_VON = 9500 /* Beginn des Internet-Teilbereichs, der in die Tournet-DB übermittelt werden muss */
  public static CDZUST_TOURNET_KONTAKT = 9500 /* Kontaktperson für Fragen der Reisebüros im Tournet */
  public static CDZUST_INTERNET_AUTOM_FEHLER = 9600 /* Fehler bei zeitkritischen automatischen Internetprozessen */
  public static CDZUST_INTERNET_API_FEHLER = 9700 /* Fehler bei Verarbeitungen durch APIs und ähnliche Eingangsschnittstellen */
  public static CDZUST_INTERNET_KOPIE_BIS = 9999 /* Ende des Teilbereichs, der in die Tournet-DB kopiert werden muss */
  public static CDZUST_INTERNET_BIS = 9999 /* Ende des Bereichs mit Internet-Zuständigkeiten */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZUSTSTATUS = 1037 /* Zuständigkeitsstatus */
  public static CDZUSTSTAT_AKTIV_VON = 0    /* Erster aktiver Status */
  public static CDZUSTSTAT_NORMAL = 0    /* normale, aktive Zuständigkeit */
  public static CDZUSTSTAT_INDIV_VON = 2    /* Erster Status, der nur zur Verfügung steht, wenn die Anfangsbuchstaben der CDKURZBEZ des ZUSTCODES in der des ZUSTSTATUS vorkommen */
  public static CDZUSTSTAT_ZUSAETZLICH = 4    /* Aktiv, aber nur zusätzlich zuständig. Je nach Zuständigkeit sind Einschränkungen möglich (z.B. Adressen prüfen ohne eigene Agendaeinträge). */
  public static CDZUSTSTAT_NUR_LESEN = 5    /* Nur Leseberechtigung */
  public static CDZUSTSTAT_AKTIV_BIS = 5    /* Letzter aktiver Status */
  public static CDZUSTSTAT_INDIV_BIS = 7    /* Letzter Status, der nur zur Verfügung steht, wenn die Anfangsbuchstaben der CDKURZBEZ des ZUSTCODES in der des ZUSTSTATUS vorkommen */
  public static CDZUSTSTAT_ENTZOGEN = 9    /* Berechtigung einer übergeordneten Ebene entzogen */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANGZI_CODE = 1038 /* Art der Angebots-Zusatzinformation */
  public static CDANGZI_URL_VON = 1    /* Erster für URLs reservierter Code */
  public static CDANGZI_HOMEPAGE = 1    /* Haupt-URL zum Angebot */
  public static CDANGZI_URL_BIS = 9    /* Letzter für URLs reservierter Code */
  public static CDANGZI_VORLAGE_VON = 51   /* Beginn des Bereichs mit Codes für Kopiervorlagen */
  public static CDANGZI_VORLAGE1 = 51   /* Erster reiner Text als Kopiervorlage */
  public static CDANGZI_VORLAGE2 = 52   /* Zweiter reiner Text als Kopiervorlage */
  public static CDANGZI_VORLAGE3 = 53   /* Dritter reiner Text als Kopiervorlage */
  public static CDANGZI_VORLAGE_BIS = 59   /* Letzter für Kopiervorlagen reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_MGAGRUPPENCODE = 1039 /* Mitgliedschaftsgruppe und -level */
  public static CDMGAGRP_ALLE = 0    /* Nur bei den Zusatzleistungen: Gilt für alle Mitgliedschaften mit Gruppe >= 1 */
  public static CDMGAGRP_KEINE = 0    /* Nur bei Mitgliedschaftentabelle: NULL=keiner Gruppe zugeteilt */
  public static CDMGAGRP_MIN_STD = 1    /* Erste normale Gruppe von Mitgliedschaften (tiefster Level) */

  /*--------------------------------------------------------------------------*/

  public static TAB_IGEBCODE = 1040 /* Interessensgebiete (sprachunabhängiger Eintrag für ID der Clever Reach-Schnittstelle) */

  /*--------------------------------------------------------------------------*/

  public static TAB_IRVORSCHLAGSCODE = 1041 /* Art wie ein Interessensgebiet vorgeschlagen wird */
  public static CDIRV_NICHT_VORSCHLAGEN = 1    /* Nicht vorschlagen, aber in Adresse je nach Berechtigung änderbar */
  public static CDIRV_OHNE_HAEKCHEN = 10   /* Ohne Häkchen vorschlagen */
  public static CDIRV_MIT_HAEKCHEN = 11   /* Mit angeklicktem Häkchen vorschlagen */
  public static CDIRV_AUTOMATISCH = 19   /* Das Häkchen wird automatisch ohne Rückfrage gesetzt */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAISTATUS = 1042 /* Status des Partnerinteresses */
  public static CDPAI_NICHT_DEFINIERT = 0    /* NULL=Noch nicht bestimmt */
  public static CDPAI_NICHT_ERWUENSCHT = 1    /* Nicht erwünscht */
  public static CDPAI_ABBESTELLT = 5    /* Abbestellt (nachdem er mal bestellt war) */
  public static CDPAI_PENDENT_VON = 7    /* Erster für die "Bestätigung durch den Kunden ausstehend" reservierter Code */
  public static CDPAI_NICHT_BESTAETIGT = 8    /* Bestellt, aber noch nicht bestätigt (für Double-Opt-In) */
  public static CDPAI_AUTOM_NICHT_BESTAETIGT = 9    /* Automatisch auf bestellt gesetzt, aber noch nicht bestätigt (Opt-In ausstehend) */
  public static CDPAI_PENDENT_BIS = 9    /* Letzter für die "Bestätigung durch den Kunden ausstehend" reservierter Code */
  public static CDPAI_AKTIV_VON = 10   /* Alle Codes ab hier sind bestellte */
  public static CDPAI_BESTELLT = 10   /* Bestellt durch manuelles setzen des Häkchens oder bestätigen des Opt-In bzw. Double-Opt-In */
  public static CDPAI_BESTELLT_VORSCHLAG = 11   /* Bestellt mit bereits vorgeschlagenem Häkchen */
  public static CDPAI_AUTOMATISCH = 19   /* Automatisch auf bestellt gesetzt (Kunde wurde nicht gefragt) */
  public static CDPAI_AKTIV_BIS = 19   /* Alle Codes bis hier sind aktiv bestellt */

  /*--------------------------------------------------------------------------*/

  public static TAB_WMAT_GRUPPE = 1043 /* Gruppen der Werbematerialien (pro 100er der WMAT gem. Codeart 1011, gültig sind somit Codes 0-99) */

  /*--------------------------------------------------------------------------*/

  public static TAB_AWCODE = 1044 /* Art des Ausweises */
  public static CDAW_UNDEFINIERT = 0    /* 0/NULL=Art des Ausweises ist nicht definiert */
  public static CDAW_PASS = 1    /* Pass */
  public static CDAW_ID = 2    /* Identitätskarte */

  /*--------------------------------------------------------------------------*/

  public static TAB_ADRSICHTBARCODE = 1045 /* Gibt an, welche Adressangaben (Anbieter-/Agent) auf autom. Kundenpapieren, b2b-Records und App stehen */
  public static CDADRS_UNDEFINIERT = 0    /* NULL=Wenn die Angabe gar nicht möglich ist */
  public static CDADRS_KEINE = 0    /* Keine Daten veröffentlichen */
  public static CDADRS_ALLE = 1    /* Alle Daten dürfen veröffentlicht werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_IRINTERNCODE = 1046 /* Definiert, wie Interessensregeln bei internen Partnermutationen berücksichtigt werden */
  public static CDIRINT_NIE = 0    /* Reine Kundenmutationen haben keine Auswirkungen auf den Newsletterstatus (Default) */
  public static CDIRINT_ADRESSE = 1    /* Wenn die Postanschrift vollständig erfasst wird */
  public static CDIRINT_EMAIL = 2    /* Wenn die E-Mailadresse auf dem Kunden erfasst wird */
  public static CDIRINT_ADR_EMAIL = 3    /* Wenn sowohl Postanschrift als auch E-Mailadresse erfasst werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_IRABLAUFCODE = 1047 /* Definiert, ob die Regel bei Buchungen bzw. Werbematerialbestellungen zum Tragen kommt, und wenn ja, nur bestimmte Arten gilt. */
  public static CDIRABL_NIE = 0    /* Die Regel wird bei Buchungen bzw. Werbematerialbestellungen nicht berücksichtigt */
  public static CDIRABL_IMMER = 1    /* Die Regel gilt für Buchungen bzw. Werbemat.bestellungen, sofern die generellen Bedingungen des Interessensgebietes erfüllt sind (Default) */
  public static CDIRABL_EINE = 10   /* Die Regel gilt für Buchungen bzw. Werbematerialbestellungen, die mindestens eine Bedingung gem. Interessensregelteil erfüllen */

  /*--------------------------------------------------------------------------*/

  public static TAB_IRTCODE = 1048 /* Bestimmt für welchen Vorgang der Interessensregelteil berücksichtigt werden muss */
  public static CDIRT_WERBEMAT = 10   /* Werbematerialbestellung */
  public static CDIRT_BUCHUNG = 20   /* Buchung */

  /*--------------------------------------------------------------------------*/

  public static TAB_DATENBEARBCODE = 1049 /* Gibt an, ob und wie die Kundendaten bearbeitet werden dürfen */
  public static CDDATB_NICHT_DEFINIERT = 0    /* (NULL)=keine Aussage oder nicht nötig */
  public static CDDATB_MIN_JA = 1    /* Erster für grundsätzlich zur Datenbearbeitung freigegebene Adressen reservierter Code */
  public static CDDATB_JA = 1    /* Datenbearbeitung zulässig */
  public static CDDATB_JA_WERBUNG_NICHT_DEFINIERT = 3    /* Datenbearbeitung zulässig, jedoch keine Aussage zu Direktwerbung */
  public static CDDATB_JA_OHNE_WERBUNG = 5    /* Datenbearbeitung zulässig, aber keine Direktwerbung */
  public static CDDATB_MAX_JA = 49   /* Letzter für grundsätzlich zur Datenbearbeitung freigegebene Adressen reservierter Code */
  public static CDDATB_MIN_NEIN = 50   /* Erster für von der Datenbearbeitung ausgeschlossene Adressen reservierter Code */
  public static CDDATB_NICHT_INFORMIERT = 50   /* Kunde noch nicht um Erlaubnis gebeten */
  public static CDDATB_WIDERRUFEN = 60   /* Zustimmung widerrufen */
  public static CDDATB_MIN_VOLLSTAENDIGE_ADR = 70   /* Erster Code, der nur für vollständige Adressen verwendet werden darf */
  public static CDDATB_BUCHHALTUNG = 70   /* Die Adresse darf nur noch für Buchhaltungsbelange verwendet werden */
  public static CDDATB_ANONYM = 90   /* Daten anonymisiert */
  public static CDDATB_MAX_VOLLSTAENDIGE_ADR = 98   /* Letzter Code, der nur für vollständige Adressen verwendet werden darf */
  public static CDDATB_PHYISCH_LOESCHEN = 99   /* Adresse löschen (wird nie gespeichert, steht aber als Auswahl zur Verfügung, um die Adresse physisch löschen zu können) */
  public static CDDATB_MAX_NEIN = 99   /* Letzter für von der Datenbearbeitung ausgeschlossene Adressen reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_PARTNERKONTAKT = 1050 /* Art des Kontakts mit dem Kunden/Anbieter */
  public static CDPAKT_HP_BUCHUNG = 20   /* Homepage-Buchung */
  public static CDPAKT_HP_WERBEMATERIAL = 21   /* Homepage-Prospektbesellung */
  public static CDPAKT_HP_NEWSLETTER = 22   /* Homepage-Newsletterbestellung */
  public static CDPAKT_AUTOM = 40   /* Automatisch */
  public static CDPAKT_TEL = 60   /* Telefonanruf */
  public static CDPAKT_EMAIL = 61   /* E-Mail */
  public static CDPAKT_ADR_IMPORT = 80   /* Adressimport */
  public static CDPAKT_FREI_VON = 100  /* Erster für frei definierbare Codes reservierter Bereich */
  public static CDPAKT_FREI_BIS = 199  /* Letzter für frei definierbare Codes reservierter Bereich */

  /*--------------------------------------------------------------------------*/

  public static TAB_TELSTATUS = 1051 /* Zustand der (Telefon-)Nummer */
  public static CDTELS_NORMAL = 0    /* normal */
  public static CDTELS_PW_FALSCH_VON = 21   /* Passwort 1x falsch eingegeben; erster für hintereinander falsch eingegebene Passwörter reservierter Code (alle 8 Codes z.Z. nicht verwendet) */
  public static CDTELS_PW_FALSCH_BIS = 28   /* Passwort 8x hintereinander falsch eingegeben; Letzter für hintereinander falsch eingegeben Passwörter reservierter Code */
  public static CDTELS_PW_GESPERRT = 29   /* Passwort gesperrt (z.Z. nicht verwendet) */
  public static CDTELS_MAX_GUELTIG = 49   /* Letzter Code für grundsätzlich gültige Einträge */
  public static CDTELS_MIN_UNGUELTIG = 50   /* Erster Code für nicht verwendbare Einträge */
  public static CDTELS_UNGUELTIG = 60   /* ungültig */
  public static CDTELS_KEINE = 80   /* keine */

  /*--------------------------------------------------------------------------*/

  public static TAB_DATENAUSZUG = 1052 /* Textkonstanten und Gesetzestexte für den Datenauszug eines Partners (CDBEZ für Titel/Überschriften, CDBESCHR für übrige Texte) */
  public static CDDAUSZ_EINLEITUNG = 1021 /* Generelle Einleitung */
  public static CDDAUSZ_EINLEITUNG_GES = 1041 /* Generelle Einleitung, Fortsetzung für Gesamtauszug */
  public static CDDAUSZ_EINLEITUNG_TEIL = 1042 /* Generelle Einleitung, Fortsetzung für Teilauszug */
  public static CDDAUSZ_GESETZ = 1200 /* Generelle Gesetzesgrundlagen */
  public static CDDAUSZ_KUNDE = 1211 /* Kundendaten */
  public static CDDAUSZ_MITGLIED = 1220 /* Mitgliedschaften */
  public static CDDAUSZ_INTERESSE = 1221 /* Interessen */
  public static CDDAUSZ_PERSON = 1225 /* Personendaten */
  public static CDDAUSZ_DOSSIER = 1250 /* Dossierdaten */
  public static CDDAUSZ_FRAGEBOGEN = 1251 /* Fragebogendaten */
  public static CDDAUSZ_SCHLUSS = 1960 /* Genereller Abschlusstext */

  /*--------------------------------------------------------------------------*/

  public static TAB_PERSLOHNARTCODE = 1053 /* Individuelle Lohnart der Person */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZUSTCODE2 = 1054 /* Zuständigkeiten ausserhalb des Mandanten (nur Codes 5000-5999 erlaubt, s.a. Codeart 1036 für die Mandanten-Zuständigkeiten, gleicher Prefix) */
  public static CDZUST_ANB_KDKONTAKT = 5200 /* Anbieter-Kundenkontakt */
  public static CDZUST_ANB_BUMELDUNG = 5220 /* Empfänger für Buchungsmeldungen wie z.B. Buchzungszahlen, Sell & Report, Hotelliste usw. */
  public static CDZUST_ANB_REQUEST = 5221 /* Empfänger für Request (falls nicht angegeben, gilt die generelle Zuständigkeit 5220 für Buchungsmeldungen auch für den Request) */
  public static CDZUST_ANB_BUCHHALTUNG = 5300 /* Buchhaltungskontakt beim Anbieter (z.Z. keine Automation) */
  public static CDZUST_ANB_SONSTIGE = 5390 /* Sonstige Anbieterkontakte (aus Tourdata-Sicht rein informativ) */

  /*--------------------------------------------------------------------------*/

  public static TAB_TAB_ZUSTMELDECODE = 1055 /* Art, wie Informationen gemeldet werden */
  public static CDZUSTMLD_KEINE = 0    /* keine */
  public static CDZUSTMLD_SMS = 1    /* SMS */
  public static CDZUSTMLD_EMAIL = 2    /* E-Mail */
  public static CDZUSTMLD_AGENDA = 4    /* Agenda */

  /*--------------------------------------------------------------------------*/

  public static TAB_PA_VID = 1056 /* Verschlüsselungsregeln für Partner-VIDs (virtuelle IDs) */

  /*--------------------------------------------------------------------------*/

  public static TAB____PARTNER_BEREICHE___ = 1100 /* === Beginn der Partner-Codearten, welche für Codeartenbereiche verwendet werden === */

  /*--------------------------------------------------------------------------*/

  public static TAB_PATYPCODE = 1100 /* Untertyp eines Partnercodes gem. PATYPCODE + partner.pacode (1100-1109) --> keine Überschneidungen <-- */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANGCODE = 1100 /* Unterart eines Partnertypcodes gem. ANGCODE+partner.patypcode (1120-1129) --> keine Überschneidungen <-- */

  /*--------------------------------------------------------------------------*/

  public static TAB_PATYPKUNDE = 1101 /* Kundenarten */
  public static CDKD_NORMAL = 0    /* Normalkunde */
  public static CDKD_REISEBUERO = 1    /* Reisebürokunde */
  public static CDKD_BUCHUNGSSTELLE = 2    /* Buchungsstelle */
  public static CDKD_VERANSTALTER = 3    /* Veranstalter (wie Reisebüro, er erhält jedoch eine Netto-Rechnung, ber der die Kommission nicht separat ausgewiesen werden darf) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PATYPANBIETER = 1102 /* Anbieterarten */
  public static CDANB_FLUGGES = 20   /* Fluggesellschaft */
  public static CDANB_HOTEL = 21   /* Hotel, Restaurant */
  public static CDANB_BAHN = 23   /* Eisenbahn, Bergbahn */
  public static CDANB_SCHIFFFAHRT = 24   /* Schifffahrtsgesellschaften */
  public static CDANB_AGENT = 25   /* Agent */
  public static CDANB_LIEFERANT = 26   /* Lieferanten */
  public static CDANB_PARKPLATZ = 27   /* Parkplätze */
  public static CDANB_AUSFLUGSZIEL = 28   /* Sehenswürdigkeit, Museum, usw. */
  public static CDANB_UEBRIGE = 29   /* übrige Anbieter */

  /*--------------------------------------------------------------------------*/

  public static TAB_PATYPORT = 1106 /* Ortsarten */
  public static CDPAORT_HALTEORT = 60   /* Halteort */

  /*--------------------------------------------------------------------------*/

  public static TAB_PATYPBANK = 1108 /* Bankarten */
  public static CDBANK_BANK = 80   /* Bank */
  public static CDBANK_POST = 81   /* Post */

  /*--------------------------------------------------------------------------*/

  public static TAB_PATYPMANDANT = 1109 /* Mandantenarten */
  public static CDMAND_NORMAL = 90   /* Normaler Mandant */
  public static CDMAND_MARKTPLATZ = 91   /* Marktplatz (nur für Tournet) */
  public static CDMAND_MWSTLAND = 95   /* MWST-Land */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANGCODE_HOTEL = 1121 /* Angebotsarten für Hotels und Restaurants (PATYPCODE 21, es sind nur die Codes 1000-1999 erlaubt) */
  public static CDANG_HOTEL_VON = 1000 /* Erster möglicher Wert, der für Hotels und Restaurants reserviert ist */
  public static CDANG_HOTEL = 1001 /* Hotel */
  public static CDANG_GASTHOF = 1002 /* Gasthof */
  public static CDANG_RESTAURANT = 1020 /* Restaurant */
  public static CDANG_AUTORAST = 1021 /* Autoraststätte */
  public static CDANG_HOTEL_BIS = 1999 /* Letzter möglicher Wert, der für Hotels und Restaurants reserviert ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANGCODE_BAHN = 1123 /* Angebotsarten für Bahnen (PATYPCODE 23, es sind nur die Codes 3000-3999 erlaubt) */
  public static CDANG_BAHN_VON = 3000 /* Erster möglicher Wert, der für Bahnen reserviert ist */
  public static CDANG_EISENBAHN = 3001 /* "normale" Eisenbahn */
  public static CDANG_SCHMALSPURBAHN = 3002 /* Schmalspurbahn */
  public static CDANG_BERGBAHN = 3020 /* Bergbahn */
  public static CDANG_STANDSEILBAHN = 3021 /* Standseilbahn */
  public static CDANG_LUFTSEILBAHN = 3022 /* Luftseilbahn */
  public static CDANG_BAHN_BIS = 3999 /* Letzter möglicher Wert, der für Bahnen reserviert ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANGCODE_SCHIFF = 1124 /* Angebotsarten für die Schifffahrt (PATYPCODE 24, erlaubt sind nur die Codes 4000-4999) */
  public static CDANG_SCHIFF_VON = 4000 /* Erster möglicher Wert, der für die Schifffahrt reserviert ist */
  public static CDANG_KURSSCHIFF = 4001 /* Kursschiff */
  public static CDANG_SCHIFF_BIS = 4999 /* Letzter möglicher Wert, der für die Schifffahrt reserviert ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANGCODE_LIEFERANT = 1126 /* Angebotsarten für Lieferanten (PATYPCODE 26, erlaubt sind nur die Codes 6000-6999) */
  public static CDANG_LIEFERANT_VON = 6000 /* Erster möglicher Wert, der für die Lieferanten reserviert ist */
  public static CDANG_LIEFERANT = 6001 /* Lieferant allgemein */
  public static CDANG_PAKETER = 6020 /* Paketanbieter */
  public static CDANG_VERKEHRSBUERO = 6040 /* Verkehrsbüro */
  public static CDANG_LIEFERANT_BIS = 6999 /* Letzter möglicher Wert, der für die Lieferanten reserviert ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANGCODE_AUSFLUG = 1128 /* Angebotsarten für Ausflugsziele (PATYPCODE 28, erlaubt sind nur die Codes 8000-8999) */
  public static CDANG_AUSFLUG_VON = 8000 /* Erster möglicher Wert, der  für Ausflugsziele reserviert ist */
  public static CDANG_NATUR = 8001 /* Naturereignis */
  public static CDANG_MUSEUM = 8020 /* Museum */
  public static CDANG_SCHAUKAESEREI = 8040 /* Schaukäserei */
  public static CDANG_ERLEBNISPARK = 8060 /* Erlebnispark */
  public static CDANG_BERGWERK = 8080 /* Bergwerk */
  public static CDANG_AUSFLUG_BIS = 8999 /* Letzter möglicher Wert, der für Ausflüge reserviert ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAZUSKRITBASIS = 1201 /* Erstes frei definierbare Zusatzkriterium für Partner mit PACODE 0 */
  public static CDPAZKR_FAKTOR = 20   /* Das erste zu verwendende frei definierbare Zusatzkriterium errechnet sich wie folgt: PAZUSKRITBASIS+CDPAZKR_FAKTOR*partner.pacode+0..9 */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAZUSKRITENDE = 1206 /* Zur Zeit letztmögliches Zusatzkriterium für Partner mit PACODE 0 */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAZUSKRITBBASIS = 1211 /* Erstes frei definierbare Ja/Nein-Zusatzkriterium für Partner mit PACODE 0 */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAZUSKRITBENDE = 1220 /* Letzmögliches freies Ja/Nein-Zusatzkriterium für Partner mit PACODE 0 */

  /*--------------------------------------------------------------------------*/

  public static TAB_PAZUSKRITRESERVE = 1399 /* Von PAZUSKRITBASIS bis PAZUSKRITRESERVE sind alle Codes für Partner-Zusatzkriterien reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_PERSZUSKRIT_VON = 1401 /* Erste für Personenzusatzkriterien reservierte Codeart (Defines für einzelne Codebereiche s.a. Codeart 3101) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PERSZUSKRIT_BIS = 1406 /* Letzte für Personenzusatzkriterien reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB_PERSKL_ZUSKRIT_VON = 1501 /* Erste für Personenklassierungen reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB_PERSKL_ZUSKRIT_BIS = 1509 /* Letzte für Personenklassierungen reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB____PRODUKT___ = 2000 /* === Beginn der Produkt-Codearten (Bereich voll, Fortsetzung Codeart 2800 uff.) === */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRCODE = 2000 /* Produktecode (Codes werden z.T. auch unter Codeart 2072 eingesetzt)("#Z" in CDKURZBEZ = Zusatzkriterien möglich) */
  public static CDPR_STR = 1    /* Standardreise */
  public static CDPR_PRG = 2    /* Produktegruppe */
  public static CDPR_TRK = 3    /* Transportkette */
  public static CDPR_TRS = 4    /* Transportstrecke */
  public static CDPR_LL = 5    /* Landleistung */
  public static CDPR_KAT = 6    /* Kategorie */
  public static CDPR_ZUS = 7    /* Zusatz */
  public static CDPR_GSC = 10   /* Gutscheincode */
  public static CDPR_TRM = 11   /* Transportmittel (für Sitzreservation) */
  public static CDPR_SRO = 21   /* Standardroute (für Ein-/Austiegsorte) */
  public static CDPR_AGB = 25   /* Agendabaustein als Standardvorlage */
  public static CDPR_CRS_TRS = 34   /* Transportstrecke aus CRS-System oder Handeingabe */
  public static CDPR_KONT = 40   /* Kontingentsprodukt */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRSTATUS = 2001 /* Produktestatus */
  public static CDPRSTAT_AKTIV = 0    /* Normalfall */
  public static CDPRSTAT_BELASSEN = 1    /* Evt. aktiv, aber im Internet wo sinnvoll auch anzeigen, wenn keine aktiven Daten mehr vorhanden sind */
  public static CDPRSTAT_INTERN = 2    /* Darf nur intern gebucht werden (Sperre evt. auf Von-/Bis-Bereich beschränkt) */
  public static CDPRSTAT_GESPERRT = 7    /* Darf überhaupt nicht gebucht werden (Sperre evt. auf Von-/Bis-Bereich beschränkt) */
  public static CDPRSTAT_ALT_INTERN = 8    /* Internet-Gesperrt-Flag wie es bis 15.8.06 erfasst wurde und bis auf Weiteres noch gilt (neu Code 2) */
  public static CDPRSTAT_GELOESCHT = 9    /* wird sobald als möglich physisch gelöscht */

  /*--------------------------------------------------------------------------*/

  public static TAB_VERARBCODE = 2002 /* Verarbeitungscode */
  public static CDVERARB_DIREKT = 0    /* Direkt buchbar (Standardreisen, Transportketten und Landleistungen: auch im Internet) */
  public static CDVERARB_EINGESCHRAENKT = 1    /* Unter gewissen Einschränkungen im Internet direkt buchbar (z.B. Landleistungen mit Zimmersucher) */
  public static CDVERARB_INHOUSE = 2    /* nur Inhouse buchbar (nicht auf externen Reservationssystemen wie Tournet usw.) */
  public static CDVERARB_ABHAENGIG = 9    /* nur innerhalb des übergeordneten Produkts buch- und wartbar */
  public static CDVERARB_DOSSIER = 10   /* Wartung über Dossier (CRS-Produkt oder manuelle Eingabe) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PERIODENCODE = 2003 /* Periodenberücksichtigung */
  public static CDPER_KEINE = 0    /* keine Perioden festhalten / nicht relevant */
  public static CDPER_START = 1    /* alle möglichen Startzeitpunkte festhalten (bei Standardreisen und Transportstrecken) */
  public static CDPER_ALLE = 2    /* alle Daten zwischen Startdatum und einem Tag vor Enddatum festhalten (Nächte; bei Landleistungen) */
  public static CDPER_NAECHTE = 2    /* Synonym für alle, da damit ja die Nächte gemeint sind */
  public static CDPER_TAGE = 3    /* Tage statt Nächte zählen (Abreisetag wird auch gezählt; bei Landleistungen möglich) */
  public static CDPER_ZEIT = 4    /* zeitgenaue Berechnung: Wenn Rückgabezeit nach Abholzeit werden Tage sonst Nächte gezählt */

  /*--------------------------------------------------------------------------*/

  public static TAB_KONTCODE = 2004 /* Art der Kontingentsführung */
  public static CDKONT_KEIN = 0    /* Kein Kontingent auf dieser Ebene */
  public static CDKONT_INTERN = 1    /* interne Kontingentsführung */
  public static CDKONT_OHNE_PRUEFUNG = 2    /* Kontingentsführung ohne Prüfung (im Leist.datum bleibt das Feld KONTINGENT leer; nur bei LL, muss durch Modulcode 1241 "O" aktiviert werden) */
  public static CDKONT_UNTERKONT = 5    /* Als Unterkontingent abgebucht (nur im vermerkten Produkt verwendet) */
  public static CDKONT_NUR_ZUS_KONT = 10   /* Wie 0, es wird jedoch auf mindestens einem abhängigen Produkt mit VPRRESTEILNR >= 100 ein Kontingent geführt (nur im vermerkten Produkt) */
  public static CDKONT_BELEGUNG2_VON = 20   /* Ab diesem Code wird die Belegung in den separaten Feldern, die auf ...2 enden, geführt */
  public static CDKONT_EXTERN_VON = 50   /* Erster Code, der externe Res.systeme umfasst: Kontingentsprüfung extern, Belegung intern nachführen */
  public static CDKONT_GALILEO = 50   /* Kontingent gem. Galileo (Webservice mit neutralem Kontingent) */
  public static CDKONT_AMADEUS = 70   /* Kontingent gem. Amadeus (API mit neutralem und/oder Negospace) */
  public static CDKONT_KOMET = 90   /* Kontingent gem. Komet (Kuoni-Webservices mit neutralem Kontingent) */
  public static CDKONT_EXTERN_BIS = 99   /* Der letzte Code, der externe Reservationssystem umfasst, die intern nachgeführt werden */
  public static CDKONT_BELEGUNG2_BIS = 99   /* Letzter Code, deren Belegung über die ...2-Felder geführt wird */

  /*--------------------------------------------------------------------------*/

  public static TAB_KONTFREIGABECODE = 2005 /* Zeitpunkt der Kontingentsfreigabe */
  public static CDKFR_MIN_VERFALL2 = 0    /* Erster Code, der beim 2. Verfall möglich ist */
  public static CDKFR_KEINER = 0    /* kein Verfall (nur beim 2. Verfall möglich) */
  public static CDKFR_MIN_VERFALL1 = 1    /* Erster Code, der beim ersten Verfall möglich ist */
  public static CDKFR_MO = 1    /* montags */
  public static CDKFR_DI = 2    /* dienstags */
  public static CDKFR_MI = 3    /* mittwochs */
  public static CDKFR_DO = 4    /* donnerstags */
  public static CDKFR_FR = 5    /* freitags */
  public static CDKFR_SA = 6    /* samstags */
  public static CDKFR_SO = 7    /* sonntags */
  public static CDKFR_MAX_VERFALL2 = 9    /* Letzter Code, der beim 2. Verfall möglich ist */
  public static CDKFR_TAEGLICH = 10   /* täglich (nur beim ersten Verfall möglich) */
  public static CDKFR_FIX_VERFALLVON = 20   /* fixes Datum bezogen auf das Verfall-Vondatum (nur beim ersten Verfall möglich) */
  public static CDKFR_MAX_VERFALL1 = 39   /* Letzter Code, der beim ersten Verfall möglich ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRSPAUSWAHLCODE = 2006 /* Transport-Auswahlcode */
  public static CDTRSPAUSW_FREIWILLIG = 0    /* Das Auswählen einer Transportleistung ist freiwillig */
  public static CDTRSPAUSW_OBLIG = 1    /* Das Auswählen einer Transportleistung pro Richtung ist obligatorisch */
  public static CDTRSPAUSW_GESAMT = 2    /* Die Transportleistungen sind aus dem Gesamtangebot frei wählbar */
  public static CDTRSPAUSW_OBLIG_EINE_RICHTUNG = 3    /* Das Auswählen einer Trsp.leist. ist oblig., One-Way (egal, ob Hin- oder Rückreise) ist jedoch erlaubt */
  public static CDTRSPAUSW_AUFTRAG = 10   /* Auftragsstandardreise ohne Transporte */

  /*--------------------------------------------------------------------------*/

  public static TAB_LANDLEIST_AUSWAHLCODE = 2007 /* Landleistungs-Auswahlcode */
  public static CDLLAUSW_FREIWILLIG = 0    /* Das Auswählen einer Landleistung ist freiwillig */
  public static CDLLAUSW_OBLIG = 1    /* Das Auswählen einer Landleistung ist obligatorisch */
  public static CDLLAUSW_GESAMT = 2    /* Die Landleistungen sind aus dem Gesamtangebot frei wählbar */
  public static CDLLAUSW_AUFTRAG = 10   /* Auftragsstandardreise ohne Landleistungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_STRSTATUSCODE = 2008 /* Zustand der Standardreise */
  public static CDSTR_OK = 0    /* Standardreise ist auf Vollständigkeit geprüft worden und kann verwendet werden (evt. beschränkt) */
  public static CDSTR_IN_ARBEIT = 2    /* Standardreise ist in Bearbeitung, höchstens bis zum definierten Datum uneingeschränkt verwendbar */
  public static CDSTR_ERFASST = 4    /* STR erfasst, aber Preise noch nicht kontrolliert. Nur mit Einschränkungen übers Datum hinaus verw. */

  /*--------------------------------------------------------------------------*/

  public static TAB_SAISONZUTEILCODE = 2009 /* Art der Saisonbestimmung */
  public static CDSAISON_START = 0    /* Saisonzuteilung gem. Startdatum der Reise */
  public static CDSAISON_DATUM = 1    /* Datumgenaue Saisonzuteilung (z.B. 1. Woche Saison A, 2. Woche Saison B) */
  public static CDSAISON_LANDLEIST = 2    /* Pro Landleistung unterschiedliche Saisons. Berechnung gem. Aufenthalt */
  public static CDSAISON_LANDLEIST_RBEG = 3    /* Pro Landleistung unterschiedliche Saison. Berechnung gem. Reisebeginn */
  public static CDSAISON_DATUM_NTEIL = 4    /* Datumgenaue Zuteilung, wobei eine Preisperiode nicht unterteilt wird */
  public static CDSAISON_LANDLEIST_NTEIL = 5    /* Pro Landleistung unterschiedliche Saison, Berechnung gem. Aufenthalt, Preisperiode nicht unterteilen */

  /*--------------------------------------------------------------------------*/

  public static TAB_KOSTENTRCODE = 2010 /* Art der Kostenträgerbestimmung */
  public static CDKTR_KEIN = 0    /* kein Kostenträger */
  public static CDKTR_DSNR = 1    /* Dossiernr. = Kostenträgernr. */
  public static CDKTR_STR = 2    /* ein Kostenträger für alle Daten der Standardreise */
  public static CDKTR_DATUM = 3    /* separate Kostenträger für alle Daten der Standardreise */

  /*--------------------------------------------------------------------------*/

  public static TAB_MWST_CODE = 2011 /* Mehrwertsteuercode */
  public static CDMWST_KEINE = 0    /* keine automatisch eingesetzte Mehrwertsteuer (Handeingabe möglich) */
  public static CDMWST_TEILNEHMER = 1    /* steuerpflichtiger Betrag pro Teilnehmer */
  public static CDMWST_RECHNUNG = 2    /* ganzer Rechnungsbetrag (steuerpflichtiger Betrag angezeigt) */
  public static CDMWST_TEILN_BRUTTO = 6    /* Bruttobetrag inkl. MWST pro Teilnehmer */
  public static CDMWST_RG_BRUTTO = 7    /* ganzer Rechnungsbetrag (Bruttobetrag inkl. MWST angezeigt) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANZAHLUNGSCODE = 2012 /* Anzahlungscode */
  public static CDANZLG_PROZENT = 0    /* Anzahlung ist ein Prozentsatz */
  public static CDANZLG_BETRAG = 1    /* Anzahlung ist ein feststehender Betrag */
  public static CDANZLG_HAND = 2    /* Der Anzahlungsbetrag wird beim Buchen abgefragt */

  /*--------------------------------------------------------------------------*/

  public static TAB_RICHTUNGSCODE = 2013 /* Reiserichtung */
  public static CDRI_KEINE = 0    /* (noch) keine Strecke erfasst */
  public static CDRI_HINREISE = 1    /* nur Hinreise */
  public static CDRI_RUECKREISE = 2    /* nur Rückreise */
  public static CDRI_BEIDE = 3    /* Hin- und Rückreise */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRKPERIODENCODE = 2014 /* Art der Periodenprüfung */
  public static CDPRKPER_KEINE = 0    /* keine Überprüfung */
  public static CDPRKPER_BEGINN = 1    /* Reisebeginn innerhalb Periode */
  public static CDPRKPER_REISE = 2    /* Gesamtreise innerhalb der Periode */
  public static CDPRKPER_PERIODE = 3    /* Gesamte Periode innerhalb der gesamten Reise */
  public static CDPRKPER_TAGE = 4    /* Preis gem. Anzahl Tagen, die die dazugehörige Leistung innerhalb der Periode liegt */
  public static CDPRKPER_LEISTUNGSBEGINN = 5    /* Beginn der einzelnen Leistung innerhalb der Periode */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVFUNKCODE = 2015 /* Art der Produkteverbindung */
  public static CDPRV_NORMAL = 0    /* Normalleistung */
  public static CDPRV_REFERENZREISE = 10   /* Verweis auf gleiche/ähnliche Reise z.B. aus früheren Jahren (max. 1 pro Reise; aber mehrere können auf die gleiche Reise verweisen) */
  public static CDPRV_AUSFLUGSPAKET = 20   /* Ausflugspaket (Nebenleistung) */
  public static CDPRV_HINREISE = 30   /* Hinreise */
  public static CDPRV_RUECKREISE = 31   /* Rückreise */
  public static CDPRV_BASISHOTEL = 50   /* Basishotel */
  public static CDPRV_VERWEIS = 51   /* Verweis vom Basishotel */
  public static CDPRV_TRANSPORT_ABHAENGIG_VON = 55   /* Erster für An- und Heimreise-Landleistungen reservierter Bereich (nur bei Nebenleistungen) */
  public static CDPRV_VOR_ERSTEM_TRANSPORT = 55   /* Landleistung, die vor dem ersten Transport während der Anreise zum Tragen kommt */
  public static CDPRV_AB_ERSTEM_TRANSPORT = 56   /* Landleistung, die ab dem ersten Transport während der Hauptreise zum Tragen kommt */
  public static CDPRV_NACH_LETZTEM_TRANSPORT = 58   /* Landleistung, die nach dem letzten Transport während der Heimreise zum Tragen kommt */
  public static CDPRV_TRANSPORT_ABHAENGIG_BIS = 58   /* Letzter für An- und Heimreiseleistungen reservierter Bereich */
  public static CDPRV_STAMMZL = 70   /* Verbindung zu einer Zusatzleistung in einer Stammstandardreise */
  public static CDPRV_KONTINGENT = 400  /* spezielles Kontingent */
  public static CDPRV_QUERVERWEIS_KONT = 401  /* Querverweis auf Kontingent */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVPRIOCODE = 2016 /* Produkteverbindungs-Prioritätscode (Einträge für ZL sind zusätzlich in der Codeart 2035 vermerkt, für NL in Codeart 2049) */
  public static CDPRVPRIO_MIN_NL_STD = 0    /* Nur bei Nebenleistungen (betrifft entsprechend Codeart 2049): Erster Code, der generell verwendet werden kann */
  public static CDPRVPRIO_NORMAL = 0    /* Normalleistung (bei Zusatz- und Nebenleistungen "fakultativ") */
  public static CDPRVPRIO_OBLIG = 1    /* Obligatorisch (ohne Anzeige) */
  public static CDPRVPRIO_AUTOM = 2    /* Automatische Anzeige */
  public static CDPRVPRIO_MANUELL = 3    /* Anzeige nur auf Verlangen */
  public static CDPRVPRIO_RES = 4    /* Obligatorisch bei Platzreservation */
  public static CDPRVPRIO_OBLIG_TLNPR = 5    /* Obligatorisch, jedoch ins Teilnehmerprodukt übernehmen (wie fakultative Leistungen) */
  public static CDPRVPRIO_DEFAULT = 6    /* Fakultativ, Defaultwert (automatisch vorselektiert) */
  public static CDPRVPRIO_ALTERNATIV = 7    /* Fakultativ, Alternative (pro Gruppe ist nur ein Wert Default/Alternativ) möglich */
  public static CDPRVPRIO_OBLIG_DEFAULT = 8    /* Genau einer aus Auswahl obligatorisch, Defaultwert (ins Teilnehmerprodukt übernehmen) */
  public static CDPRVPRIO_OBLIG_ALTERNATIV = 9    /* Genau einer aus Auswahl obligatorisch, Alternativwert (ins Teilnehmerprodukt übernehmen) */
  public static CDPRVPRIO_OBLIG_KD = 11   /* Obligatorisch nur bei Direktkunden (nicht bei Reisebüros) */
  public static CDPRVPRIO_OBLIG_INF = 12   /* Obligatorisch nur für Infants (keine Berechnung für Kinder und Erwachsene) */
  public static CDPRVPRIO_TOURNET = 13   /* Automatisch bei Tournet-Buchungen (sonst nicht) */
  public static CDPRVPRIO_REISEMARKT = 14   /* Automatisch bei Internet-Direktbuchungen (weder Inhouse noch Tournet) */
  public static CDPRVPRIO_OBLIG_BUCHKL = 15   /* Obligatorisch bei bestimmter Buchungsklasse */
  public static CDPRVPRIO_OBLIG_BUCHKL_ALLE = 16   /* Obligatorisch, wenn alle Transportstrecken eine bestimmte Buchungsklasse haben */
  public static CDPRVPRIO_OBLIG_RB = 17   /* Obligatorisch bei Reisebüros und Buchungsstellen (nicht bei Direktkunden) */
  public static CDPRVPRIO_OBLIG_SOFORT = 18   /* Obligatorisch bei Sofortzahlung (z.B. via Saferpay-Modul) */
  public static CDPRVPRIO_OBLIG_NACHBSITZ = 19   /* Obligatorisch, wenn Nachbarssitz freigehalten wird (mitreserviert) */
  public static CDPRVPRIO_OBLIG_EIGENSYSTEM = 20   /* Obligatorisch bei Buchung über eigenes System (Tourdata intern +Internet ohne PAVCODE 28 Fremdsystem) */
  public static CDPRVPRIO_OBLIG_MITGLIED = 21   /* Obligatorisch bei Mitgliedschaft (zusätzlich fak. Angabe einer bestimmten Mitgliedschaftsgruppe) */
  public static CDPRVPRIO_OBLIG_LAND = 22   /* Obligatorisch je nach Kundendomizil (zus. PRVPREISABHCODE 0=Inland, 1=Ausland) */
  public static CDPRVPRIO_OBLIG_VOLLZAHLER = 23   /* Obligatorisch, wenn bei Landleistung weder Zusatzpersonenrabatt noch Kinderermässigung */
  public static CDPRVPRIO_OBLIG_WAEH = 24   /* Obligatorisch, wenn entsprechende Währung gem. PRVPREISABHCODE (100=Landes-, 101=Fremdwährung) */
  public static CDPRVPRIO_OBLIG_NICHTMITGLIED = 25   /* Obligatorisch bei fehlender Mitgliedschaft (zus. fak. Angabe einer bestimmten Mitgliedschaftsgruppe) */
  public static CDPRVPRIO_OBLIG_WOCHENTAG_BEGINN = 26   /* Obligatorisch bei Leistungsbeginn an einem der angegebenen Wochentage gem. PRVPREISABHCODE (Bitmap. gem. Codeart 20) */
  public static CDPRVPRIO_OBLIG_GUTSCHEINCODE = 27   /* Obligatorisch bei Gutscheincode (PREISABHCODE 0=Reise muss via Gutscheinregel fregegeben sein; <>0=GSC genügt, GS-Regeln nicht beachten) */
  public static CDPRVPRIO_OBLIG_RECHNUNG = 28   /* Obligatorisch bei Zahlung mit Rechnung (egal, ob auf Papier oder per E-Mail; d.h. bei allen, die nicht sofort zahlen) */
  public static CDPRVPRIO_MIN_ALTER = 30   /* Erster für altersabhängige Einstellungen reservierter Code */
  public static CDPRVPRIO_OBLIG_ALTER = 30   /* Obligatorisch nur bei bestimmtem Alter (PRPBELEGUNG=jeweiliges Maximalalter statt Anzahl) */
  public static CDPRVPRIO_OBLIG_ALTER_ALTERNATIV = 31   /* Obligatorische Alternativauswahl, nur bei passendem Alter möglich (PRPBELEGUNG=Maximalalter statt Anzahl) */
  public static CDPRVPRIO_MAX_ALTER = 39   /* Letzter für altersabhängige Einstellungen reservierte Codes */
  public static CDPRVPRIO_OBLIG_ZUSKRIT1 = 41   /* Obligatorisch bei bestimmtem ersten Kunden-Zusatzkriterium (muss Typ Code sein) */
  public static CDPRVPRIO_MIN_KD_ZUSKRIT = 41   /* Erster für Kunden-Zusatzkriterien reservierter Code */
  public static CDPRVPRIO_OBLIG_ZUSKRIT2 = 42   /* Obligatorisch bei bestimmtem zweiten Kunden-Zusatzkriterium (muss Typ Code sein) */
  public static CDPRVPRIO_OBLIG_ZUSKRIT3 = 43   /* Obligatorisch bei bestimmtem dritten Kunden-Zusatzkriterium (muss Typ Code sein) */
  public static CDPRVPRIO_OBLIG_ZUSKRIT4 = 44   /* Obligatorisch bei bestimmtem vierten Kunden-Zusatzkriterium (muss Typ Code sein) */
  public static CDPRVPRIO_OBLIG_ZUSKRIT5 = 45   /* Obligatorisch bei bestimmtem fünften Kunden-Zusatzkriterium (muss Typ Code sein) */
  public static CDPRVPRIO_OBLIG_ZUSKRIT6 = 46   /* Obligatorisch bei bestimmtem sechsten Kunden-Zusatzkriterium (muss Typ Code sein) */
  public static CDPRVPRIO_MAX_KD_ZUSKRIT = 49   /* Letzter für Kunden-Zusatzkriterien reservierter Code */
  public static CDPRVPRIO_OBLIG_ZUSHAEKCHEN1 = 51   /* Obligatorisch bei erstem Kunden-Zusatzkriterium-Häkchen (ja/nein je nach PRVPREISABHCODE) */
  public static CDPRVPRIO_MIN_KD_ZUSHAEKCHEN = 51   /* Erster für Kunden-Zusatzkriterien-Häkchen reservierter Code  */
  public static CDPRVPRIO_OBLIG_ZUSHAEKCHEN2 = 52   /* Obligatorisch bei zweitem Kunden-Zusatzkriterium-Häkchen */
  public static CDPRVPRIO_OBLIG_ZUSHAEKCHEN3 = 53   /* Obligatorisch bei drittem Kunden-Zusatzkriterium-Häkchen */
  public static CDPRVPRIO_OBLIG_ZUSHAEKCHEN4 = 54   /* Obligatorisch bei viertem Kunden-Zusatzkriterium-Häkchen */
  public static CDPRVPRIO_MAX_KD_ZUSHAEKCHEN = 59   /* Letzter für Kunden-Zusatzkriterien-Häkchen reservierter Code */
  public static CDPRVPRIO_OBLIG_ROUTENHALT = 60   /* Obligatorisch bei Routenhalt gem. Produktpreis HOID */
  public static CDPRVPRIO_OBLIG_TEILSTRECKE = 61   /* Obligatorisch bei gebuchter Teilstrecke gem. Produktpreis HOID+HOID_AUSSTIEG */
  public static CDPRVPRIO_OBLIG_DEST_ABREISE = 70   /* Obligatorisch für die erste Transportstrecke ab einer DESTID gem. Produktpreis (ZL direkt unter der Standardreise) */
  public static CDPRVPRIO_OBLIG_TLN1 = 81   /* Obligatorisch beim ersten Teilnehmer */
  public static CDPRVPRIO_OBLIG_TLNFUNK = 85   /* Obligatorisch bei Teilnehmer mit Funktion (zus. fak. bestimmte Funktion) */
  public static CDPRVPRIO_MAX_NL_STD = 99   /* Nur bei Nebenleistungen (betrifft entsprechend Codeart 2049): Letzter Code, der generell verwendet werden kann */
  public static CDPRVPRIO_MIN_GRP = 100  /* Erster für Zusatzleistungsgruppen reservierter Code */
  public static CDPRVPRIO_GRP_OBLIG_AUSWAHL = 102  /* Gruppierung von Zusatzleist., wovon genau eine gewählt werden muss, sofern gem. anderen Bed. möglich */
  public static CDPRVPRIO_GRP_FAK_AUSWAHL = 103  /* Gruppierung von Zusatzleistungen, wovon genau 1 ausgewählt werden kann, sofern gem. and. Bed. möglich */
  public static CDPRVPRIO_GRP_MIN_1_AUSWAHL = 104  /* Gruppierung von Zusatzleistungen, aus denen mindestens 1 ausgewählt werden muss, sofern gem. anderen Bedingungen möglich */
  public static CDPRVPRIO_GRP_FAK_MEHRERE_MOEGL = 105  /* Gruppierung von Zusatzleistungen, wovon 1 oder mehrere ausgewählt werden können, sofern gem. anderen Bedingungen möglich */
  public static CDPRVPRIO_MAX_GRP = 119  /* Letzter für Zusatzleistungsgruppen reservierter Code */
  public static CDPRVPRIO_MIN_AUTO_STR = 140  /* Erster für Zusatzleistungen, die nur in der Auto-Standardreise möglich sind, reservierter Code */
  public static CDPRVPRIO_FAK_REISEART = 140  /* Fakultativ, jedoch nur bei Reiseart (nur in Auto-Standardreise) */
  public static CDPRVPRIO_MAX_AUTO_STR = 159  /* Letzter für Zusatzleistungen, die nur in der Auto-Standardreise möglich sind, reservierter Code */
  public static CDPRVPRIO_MEHR_FREI_ALS_PROZENT = 200  /* Nur buchbar, wenn mehr als die Anzahl Plätze in Prozent vom Kontingent frei sind (z.Z.nur für LL/TRK) */
  public static CDPRVPRIO_MEHR_FREI_KEINE_ZIRES = 201  /* wie 200, jedoch auch keine Zimmerreservation möglich */
  public static CDPRVPRIO_OBLIG_HAUPTLEISTUNG = 211  /* Obligatorisch (nur für Hauptleistungen vom Typ Transportkette bzw. Landleistung) */
  public static CDPRVPRIO_MIN_GSC_ABGABE = 300  /* Erster für Gutscheincode-Abgaben reservierter Bereich (Verbindung zum Gutscheinprodukt) */
  public static CDPRVPRIO_BASIS = 300  /* Basis (pro Gutscheinprodukt gilt nur der Basiseintrag, der auf der höchsten Ebene, bei Konflikten der Eintrag mit dem höchsten Wert) */
  public static CDPRVPRIO_ZUSAETZLICH = 310  /* Zusätzlich zur Basis oder anderen zusätzlichen Verbindungen zu berücksichtigter Eintrag für die Gutscheinabgabe */
  public static CDPRVPRIO_MAX_GSC_ABGABE = 399  /* Letzter für Gutscheincode-Abgaben reservierter Bereich */
  public static CDPRVPRIO_KULANZ_RUECKERSTATTUNG_GS = 1001 /* Zusätzlicher Kulanzbetrag, wenn der Kunde eine Rückerstattung als Gutschein akzeptiert */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVPREISCODE = 2017 /* Zu verwendende Preise des untergeordneten Produkts */
  public static CDPRVPR_KEIN = 0    /* kein eigener Preis */
  public static CDPRVPR_MANUELL = 1    /* Preiseingabe manuell bei der Buchung */
  public static CDPRVPR_NORMAL = 2    /* Normalpreis des Produkts verwenden */
  public static CDPRVPR_GRUPPE = 3    /* speziellen Produktgruppenpreis verwenden */
  public static CDPRVPR_STR = 4    /* speziellen Preis für diese Standardreise verwenden */
  public static CDPRVPR_SABRE = 10   /* Preis aus SABRE verwenden */

  /*--------------------------------------------------------------------------*/

  public static TAB_SAISONCODE = 2018 /* Saisoncode */
  public static CDSAISON_ALLE = -1   /* Preis gilt für alle Saisonfarben (nur mögl., wenn weitere Einschränkungen Mehrdeutigkeiten verhindern) */
  public static CDSAISON_KEINE = 0    /* Keine Saison zugeordnet */

  /*--------------------------------------------------------------------------*/

  public static TAB_FREIGEPAECKCODE = 2019 /* Art der Freigepäcksangabe */
  public static CDGEP_KG = 1    /* Gewicht der Koffer */
  public static CDGEP_STUECK = 2    /* Anzahl Koffer */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRKCODE_BITMAP = 2020 /* Art der Produktkonditionen+ -preise für Bitmapspeicherungen wie in PRVPREISCODE2 (keine Codeeinträge) */
  public static CDPRK_BITMAP_KEINE = 0    /* keine Konditionen und/oder Preise vorhanden (Wert nicht kombinierbar) */
  public static CDPRK_BITMAP_VERKAUF = 2    /* Verkaufspreise (2^CDPRK_VERKAUF) */
  public static CDPRK_BITMAP_EINKAUF = 4    /* Einkaufspreise (2^CDPRK_EINKAUF) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRKCODE = 2020 /* Art der Produktekonditionen und -preise (siehe auch nachfolgende, separaten Defines für Bitmaps) */
  public static CDPRK_VERKAUF = 1    /* Verkaufspreis */
  public static CDPRK_EINKAUF = 2    /* kalkullierter Einkaufspreis */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVPREISABHCODE = 2021 /* Gibt an, unter welchen Voraussetzungen die Preise gelten */
  public static CDPRVPABH_KEINE = 0    /* Die Preise gelten immer */
  public static CDPRVPABH_OHNE_LL = 1    /* Preis gilt nur, wenn keine Landleistungen gebucht wurden */
  public static CDPRVPABH_MIN_SITZRES = 65   /* Codes ab hier bis MAX_SITZRES  analog Codeart 2131 (Platzres.) */
  public static CDPRVPABH_MAX_SITZRES = 90   /* Codes ab MIN_SITZRES bis hier analog Codeart 2031 (Platzres.) */
  public static CDPRVPABH_MIN_WAEH = 100  /* Codes ab hier für Währungsdefinitionen */
  public static CDPRVPABH_LANDESWAEH = 100  /* Landeswährung */
  public static CDPRVPABH_FREMDWAEH = 101  /* Fremdwährung */
  public static CDPRVPABH_MAX_WAEH = 109  /* Codes bis hier für Währungsdefinitionen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PLATZANZEIGE = 2022 /* Art der angezeigten Plätze */
  public static CDPLANZ_KONTINGENT = 1
  public static CDPLANZ_BELEGT = 2
  public static CDPLANZ_OPTIONEN = 3
  public static CDPLANZ_REQUESTS = 4
  public static CDPLANZ_FREI = 5

  /*--------------------------------------------------------------------------*/

  public static TAB_DAUERCODE = 2023 /* Art, wie die Dauer berechnet werden muss */
  public static CDDAUER_MANUELL = 0    /* Dauer in Tagen gem. separater Eingabe (Default) */
  public static CDDAUER_BIS_REISEENDE = 1    /* bis Ende der Reise (=Start des Rückflugs) */
  public static CDDAUER_BIS_NAECHSTEN_EINTRAG = 2    /* bis Beginn der nächsten Leistung (=nächstes Reiseprogrammteil) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PREISARTCODE = 2024 /* Art des Preises */
  public static CDPRART_PRO_EINHEIT = 0    /* Preis pro gebuchter Einheit */
  public static CDPRART_PRO_PERSON = 1    /* Preis pro Teilnehmer */
  public static CDPRART_RABATTAGE = 2    /* Rabatttage statt Preise angegeben (günstigster Preis jeder Periode = Rabatttage) => alter Define in alter Deutscher Rechtschreibung */
  public static CDPRART_RABATTTAGE_GUENSTIGSTE = 2    /* Rabatttage statt Preise angegeben (günstigster Preis jeder Periode = Rabatttage) */
  public static CDPRART_AB_PERSON = 3    /* Preis gilt ab X. Person pro Einheit (oder X+n, wenn dazwischen keine weiteren Preise definiert wurden) */
  public static CDPRART_PRO_UNTEREINHEIT = 5    /* Z.Z. nur bei Zusatzleistung direkt unter Landleistung möglich: Preis pro Kategorie */
  public static CDPRART_PRO_RECHNUNG = 8    /* Preis gilt pro Kundenrechnung */
  public static CDPRART_PROZENT = 10   /* Prozent auf allen Preisen der direkt übergeordneten sowie allen dort untergeordneten Leistungen */
  public static CDPRART_PROZENT_INKL_HL = 11   /* Prozent wie Code 10, gilt jedoch auch für die übergeordnete Hauptleistung (nur auf NL möglich) */
  public static CDPRART_RABATTTAGE_LETZTE = 29   /* Rabatttage statt Preise angegeben (die letzten Tage jeder Periode sind gratis) */
  public static CDPRART_GRATIS = 50   /* Gratis, es sind weder Preise noch weitere Einstellungen auf den Produktkonditionen möglich (z.Z. nur bei Kategorien möglich) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZWISCHENCODE = 2025 /* Art der Zwischenleistung */
  public static CDZW_NORMAL = 0    /* keine Zwischenleistung */
  public static CDZW_HINREISE = 1    /* Zwischenleistung Hinreise */
  public static CDZW_RUECKREISE = 2    /* Zwischenleistung Rückreise */
  public static CDZW_NEBENLEISTUNG = 11   /* Nebenleistung */
  public static CDZW_QUERVERWEIS_NL = 12   /* Querverweis auf eine Nebenleistung */
  public static CDZW_QUERVERWEIS_NLTEIL = 13   /* Querverweisteil (Transportstrecke oder Kategorie aus darüberliegender Nebenleistung) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRKOMMPRIOCODE = 2026 /* Priorität der Kommissionsverwendung */
  public static CDKPRIO_PRODUKT = 0    /* Es gilt der Eintrag beim Produkt, wenn beim Kunden KOMMPRPRIO gesetzt ist */
  public static CDKPRIO_PARTNER = 1    /* Es gilt der Eintrag beim Partner (Kunden) */
  public static CDKPRIO_BEIDE = 2    /* Die Eintraege beim Produkt und Partner werden addiert */
  public static CDKPRIO_PARTNER2 = 3    /* Es gilt der 2. Eintrag beim Partner (Kunden) */
  public static CDKPRIO_BEIDE2 = 4    /* Die Einträge bei Produkt und Partner (2. Komm) werden addiert */
  public static CDKPRIO_PARTNER3 = 5    /* Es gilt der 3. Eintrag beim Partner (Kunden) */
  public static CDKPRIO_BEIDE3 = 6    /* Die Einträge bei Produkt und Partner (3. Komm) werden addiert */
  public static CDKPRIO_HOTEL1_INKL_RAB = 10   /* gem. erster Landleistung, Auswahl gilt auch für Rabatt */
  public static CDKPRIO_HOTEL1_NUR_KOMM = 11   /* gem. erster Landleistung */

  /*--------------------------------------------------------------------------*/

  public static TAB_RESCODE = 2027 /* Reservationscode */
  public static CDRES_KEINE = 0    /* keine Reservation / Grundmuster */
  public static CDRES_MIN_ALT = 1    /* Erstmögliche nicht mehr aktuelle Reservation (Umbuchung erforderlich) */
  public static CDRES_MAX_ALT = 9    /* Letztmögliche nicht mehr aktuelle Reservation */
  public static CDRES_MIN_AKTIV = 10   /* Gültige Reservationen haben mindestens diesen Code */
  public static CDRES_PLAN = 10   /* Reservation gem. Tagesplan */
  public static CDRES_EXTERN = 11   /* Reservation gem. Plan aus externem Reservationssystem */
  public static CDRES_GEL_PLAN = 19   /* Reservation gem. Plan, der jedoch (aus Platzgründen) gelöscht wurde */
  public static CDRES_AUSWAHL = 20   /* Reservation aus einer Liste möglicher Einheitsnummern */
  public static CDRES_MANUELL = 30   /* Einheitsnummer wurde manuell eingegeben */

  /*--------------------------------------------------------------------------*/

  public static TAB_RESSTATUS = 2028 /* Reservationsstatus (die Werte entsprechen den abgespeicherten ASCII-Codes) */
  public static CDRESSTAT_INF = 1    /* Dieser Wert kann zu BELEGT bzw. OPTION dazugezählt werden, wenn zusätzlich ein INF auf einem Sitz ist */
  public static CDRESSTAT_NACHBSITZ = 4    /* Dieser Wert kann bei einem freizuhaltenden Nachbarssitz zu BELEGT bzw. OPTION dazugezählt werden */
  public static CDRESSTAT_LEER = 32   /* Leerraum (am Stringende NULL) */
  public static CDRESSTAT_BELEGT = 66   /* B=belegt (=gebucht) */
  public static CDRESSTAT_BELEGT_INF = 67   /* C=Belegt mit Infant (gebucht) */
  public static CDRESSTAT_BELEGT_NACHBSITZ = 70   /* F=Belegt als freizuhaltender Nachbarssitz (gebucht) */
  public static CDRESSTAT_OPTION = 79   /* O=optioniert */
  public static CDRESSTAT_OPTION_INF = 80   /* P=Optioniert mit Infant */
  public static CDRESSTAT_OPTION_NACHBSITZ = 83   /* S=Optioniert als freizuhaltender Nachbarssitz */
  public static CDRESSTAT_FREI = 102  /* f=frei verfügbar */
  public static CDRESSTAT_GESPERRT = 103  /* g=nicht reservierbar */
  public static CDRESSTAT_FREI_HALTEN = 104  /* h=solange wie möglich frei halten */
  public static CDRESSTAT_NUR_INTERN = 105  /* i=nur intern buchbar */

  /*--------------------------------------------------------------------------*/

  public static TAB_REQUESTCODE = 2029 /* Gibt an, in welchen Fällen ein Request möglich ist */
  public static CDRQ_GENERELL_VON = 0    /* Erster auf allen Ebenen möglicher Eintrag */
  public static CDRQ_KEIN = 0    /* Es ist überhaupt kein Request möglich */
  public static CDRQ_VOR_VERFALL = 1    /* Ein Request ist nur vor Verfall möglich */
  public static CDRQ_NACH_VERFALL = 2    /* Ein Request ist erst ab Verfalldatum möglich */
  public static CDRQ_IMMER = 3    /* Ein Request ist immer möglich */
  public static CDRQ_INTERN_VON = 6    /* Erster für interne Requests reservierter Eintrag */
  public static CDRQ_INTERN_IMMER = 8    /* Ein Request ist immer möglich, jedoch nur intern */
  public static CDRQ_INTERN_BIS = 9    /* Letzter für interne Requests reservierter Eintrag */
  public static CDRQ_GENERELL_BIS = 9    /* Letzter auf allen Ebenen möglicher Eintrag */
  public static CDRQ_GEM_KAT = 16   /* Ob ein Request möglich ist oder nicht, wird auf der Kategorie bestimmt (Code nur bei LL möglich) */

  /*--------------------------------------------------------------------------*/

  public static TAB_UEBERMSTATUS = 2030 /* Uebermittlungsstatus (zusätzlich gelten alle Defines unter Codeart 2031) */
  public static CDUEM_KEINE = 0    /* Keine Daten übermittelt */
  public static CDUEM_GESPERRT = 90   /* gesperrt, darf nicht übermittelt werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_VERKAUFSSYSTEM = 2031 /* Verkaufssystem (Codes addierbar, max. Einzelcode 32, da mit Codearten 2030 und 2924 verbunden) */
  public static CDVERKS_TOURDATA = 1    /* Tourdata-intern */
  public static CDVERKS_REISEMARKT = 2    /* Reisemarkt */
  public static CDVERKS_TOURNET = 4    /* Tournet */
  public static CDVERKS_KATALOG = 32   /* Erscheint im Katalog (nur bei Schnittstellen, die Daten in ein Katalogsystem übermitteln, relevant) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RSMA_TDSTATUS = 2032 /* Status des Reisemarktangebots im Tourdata-System */
  public static CDRSMAS_AKTIV = 0    /* Angebot ist aktiv */
  public static CDRSMAS_ZU_LOESCHEN = 1    /* Angebot ist zu löschen, kann aber noch gebucht werden, bis es gelöscht wird */
  public static CDRSMAS_GESPERRT = 8    /* Angebot ist gesperrt, d.h. z.Z. nicht buchbar */
  public static CDRSMAS_GELOESCHT = 9    /* Angebot ist gelöscht, nicht mehr buchbar */

  /*--------------------------------------------------------------------------*/

  public static TAB_PNLCODE = 2033 /* Möglichkeiten der autom. PNL-Übermittlung */
  public static CDPNL_KEINE = 0    /* PNL-Übermittlung nicht möglich */
  public static CDPNL_MANUELL = 1    /* Übermittlung nur manuell möglich */
  public static CDPNL_2_MAILS = 12   /* 2 separate Mails, wenn entsprechende Zeiten eingetragen sind 2 Tage vor Abreise */
  public static CDPNL_CSV = 20   /* CSV-Format, ansonsten wie Standard */
  public static CDPNL_AIRBERLIN = 51   /* Dateiformat (SPFF) und Periodizität gem. Air Berlin */
  public static CDPNL_SPFF = 51   /* Simple Passenger File Format (in Deutschland weit verbreitetes PNL-Format) */
  public static CDPNL_BRITISH_AIRWAYS = 61   /* Dateiformat gemäss British Airways (CSV-Spezialformat) */
  public static CDPNL_EDELWEISS = 62   /* Dateiformat gemäss Edelweiss (CSV-Spezialformat) */
  public static CDPNL_HELVETIC_AIRWAYS = 63   /* Dateiformat gemäss Helvetic (2L; CSV-Spezialformat - wie Edelweiss, jedoch ohne "D" hinter der Dossiernr.) */
  public static CDPNL_EINAUS_DRUCK = 101  /* Ein-/Ausstiegsliste automatisch nach Verfall drucken (Kursreservationen) */
  public static CDPNL_EINAUS_MAIL = 102  /* Ein-/Ausstiegsliste automatisch nach Verfall an Agent, bzw. wenn keiner, an Anbieter mailen  */
  public static CDPNL_EINAUS_DRUCK_MAIL = 103  /* Ein-/Ausstiegsliste automatisch drucken und an Agent bzw. Anbieter mailen */

  /*--------------------------------------------------------------------------*/

  public static TAB_HOCODE = 2034 /* Art des Halteorts (Ein-/Ausstiegsort) */
  public static CDHO_DS = 0    /* Dossierspezifischer Halteort */
  public static CDHO_AUSWAHL_VON = 1    /* Erster in der Standardauswahl angezeigter Halteortcode */
  public static CDHO_STD = 1    /* Standardhalteort */
  public static CDHO_WICHTIG = 2    /* Wichtiger Halteort (wird hervorgehoben) */
  public static CDHO_INTERN = 4    /* nur intern buchbarer Halteort */
  public static CDHO_AUSWAHL_BIS = 5    /* Letzter in der Standardauswahl angezeigter Code */
  public static CDHO_TECHNISCH = 7    /* Technischer Halteort, steht in Standardrouten und bei individuellen Routen der Transportbewegung NICHT zur Verfügung */
  public static CDHO_ZUORDNUNG = 8    /* Nur für Zuordnung einer PLZ zu einem effektiven Halteort (nicht in Routen möglich) */
  public static CDHO_GELOESCHT = 9    /* Halteort steht nicht mehr zur Verfügung */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVPRIOCODE_ZL = 2035 /* Prioritätscode der Produkteverbindungen für Zusatzleistungen (Defines gem. Codeart 2016) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZLGRUPPE = 2036 /* Gruppe der Zusatzleistungen (pro 100er der Codeart 2107), gültige Codes sind somit 0-99 (s.a. fak. Untergruppen in Codeart 2936) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PREISAUSZEICHNUNGSCODE = 2037 /* Art der Preisauszeichnung */
  public static CDPRAUSZ_SEPARAT = 0    /* Preis wird auf einer separaten Zeile ausgewiesen */
  public static CDPRAUSZ_ARRANGEMENT = 1    /* Preis ist Bestandteil des Arrangementpreises */
  public static CDPRAUSZ_KATEGORIE = 2    /* Jede Kategorie separat */
  public static CDPRAUSZ_OBERE_ZEILE = 3    /* Preis wird in die nächstliegende obere Preiszeile integriert, die keinen Zusammenfassungscode hat */
  public static CDPRAUSZ_IN_KATEGORIE = 4    /* Bestandteil des Kategoriepreises */
  public static CDPRAUSZ_TAG = 5    /* Preis pro Aufenthaltsnacht bzw. -tag separat ausweisen */
  public static CDPRAUSZ_SEPARAT_NULL = 8    /* Wie separat, aber auch aufbereiten wenn der Betrag 0 ist */
  public static CDPRAUSZ_SEPARAT_HK = 9    /* Wie separat, aber nur errechneten Betrag in Hauptkolonne anzeigen und weder Anzahl noch Ansatz */
  public static CDPRAUSZ_NUR_MWST = 10   /* Preis wird nur zur MWST-Berechnung herangezogen und erscheint nicht auf der Rechnung */
  public static CDPRAUSZ_NUR_MGRABATT = 11   /* Preis wird nur zur Berechnung des Mitgliederrabatts verwendet und erscheint nicht auf der Rechnung */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRKSUCHCODE = 2038 /* Art wie gesucht werden kann */
  public static CDSUCHE_STD = 0    /* erscheint nicht in der erweiterten Suche */
  public static CDSUCHE_HINREISE = 1    /* es erscheint nur die Hinreise in der erweiterten Suche */

  /*--------------------------------------------------------------------------*/

  public static TAB_KOMMID = 2039 /* Beschreibung der Kommissionseinträge (abhängig von PRMANDANT_STR bzw. PRMANDANT) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RABID = 2040 /* Beschreibung der Rabatteinträge (abhängig vom PRMANDANT_STR bzw. PRMANDANT) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RABPERSONENCODE = 2041 /* Art der Berücksichtigung der Personen bei Zusatzleistungen */
  public static CDRABP_KEINE = 0    /* nicht berücksichtigen, es gilt immer der Standardrabatt (im Zimmer der Eltern) */
  public static CDRABP_EIGENE = 1    /* Personen, die diese Zusatzleistung gebucht haben */
  public static CDRABP_ZIMMER = 2    /* (erstes) gebuchtes Hotelzimmer */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVGESPERRT = 2042 /* Art der Sperrung einer Produktverbindung für den Buchungsvorgang */
  public static CDPRVGESP_NICHT = 0    /* Produktverbindung ist nicht gesperrt */
  public static CDPRVGESP_IMMER = 1    /* Produktverbindung steht für keine Buchung zur Verfügung */
  public static CDPRVGESP_EXTERN = 2    /* Produktverbindung kann nur intern gebucht werden (gesperrt für Tournet u.a.) */
  public static CDPRVGESP_NICHT_EINZELN = 3    /* Nicht einzeln buchbar (vereinfachte Form der Nebenleistung; intern erscheint nur eine Warnung) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PASSCODE = 2043 /* Passbestimmungen (Wenn CDBESCHR ausgefüllt, CDBEZ=Bezeichnung und CDBESCHR=Passbestimmung, sonst CDBEZ=Passbestimmung) */
  public static CDPASS_KEIN_HINW = 0    /* Kein Hinweis auf Passbestimmungen machen */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRKBUCODE_SUMME = 2044 /* Effektive Codes siehe TRKBUCHUNGSCODE, hier sind nur die Bedeutung von Ziffern+ Positionen definiert) */
  public static CDTRKBUS_NICHT_RELEVANT = 0    /* Position muss nicht überprüft werden */
  public static CDTRKBUS_RUECKREISE = 1    /* Andere Richtung muss identisch sein */
  public static CDTRKBUS_KETTE = 1    /* Einerstelle=Transportkette */
  public static CDTRKBUS_RICHTUNG = 2    /* Alle Strecken der gleichen Richtung müssen identisch sein */
  public static CDTRKBUS_ALLE = 3    /* Alle Strecken müssen identisch sein (1+2 kombiniert) */
  public static CDTRKBUS_NEGOSPACE = 4    /* bei Buchung mit Negospace wie 1, sonst wie 2 */
  public static CDTRKBUS_KLASSE = 10   /* Zehner-Stelle=Buchungsklasse */
  public static CDTRKBUS_GESELLSCHAFT = 100  /* Hunderter-Stelle=Transportgesellschaft */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRKBUCHUNGSCODE = 2044 /* Art wie die Strecken einer Kette gebucht werden können (Defines zum Addieren siehe anschl. Codeart) */
  public static CDTRKBU_BELIEBIG = 0    /* Hin- und Rückreise beliebig */
  public static CDTRKBU_GLEICHE_KETTE = 1    /* Hin- und Rückreise müssen aus derselben Kette gebucht werden (intern nur Warnung) */
  public static CDTRKBU_GLEICHE_KETTE_GLEICHE_KL = 11   /* Hin- und Rückreise müssen aus der selben Kette und Buchungsklasse sein */
  public static CDTRKBU_ALLE_GES = 300  /* Alle Strecken müssen von der gleichen Transportgesellschaft sein */
  public static CDTRKBU_ALLE_GES_RUECK_KL = 310  /* Alle Strecken  von der gleichen Gesellschaft und passende Rückreise von der gleichen Klasse */
  public static CDTRKBU_ALLE_GES_RICHTUNG_KL = 320  /* Alle Strecken von der gleichen Gesellschaft und weitere der gleichen Richtung in der selben Klasse */
  public static CDTRKBU_ALLE_GES_ALLE_KL = 330  /* Alle Strecken mit gleicher Gesellschaft und Buchungsklasse */
  public static CDTRKBU_ALLE_GES_NEGOSPACE_KL = 340  /* Bei Buchung mit mindestens einer Negospace-Klasse wie Code 310 sonst wie Code 320 */

  /*--------------------------------------------------------------------------*/

  public static TAB_DISPOSITION = 2045 /* Bestimmt die Art, wie eine Transportkette disponiert wird */
  public static CDTRKDI_KEINE = 0    /* keine Disposition auf dieser Kette */
  public static CDTRKDI_SEPARAT = 1    /* Hin- und Rückreise werden separt disponiert */
  public static CDTRKDI_GEMEINSAM = 2    /* Hin- und Rückreise werden gemeinsam disponiert (zeigen beide auf denselben Dispoeintrag) */
  public static CDTRKDI_MANUELL = 7    /* Die Dispoeinträge werden bei Bedarf manuell auf einzelnen Transportbewegungen hinzugefügt */

  /*--------------------------------------------------------------------------*/

  public static TAB_EINAUSSTIEGSCODE = 2046 /* Bestimmt, ob Ein- und oder Ausstiegsorte definiert wurden */
  public static CDEINAUS_KEINE = 0    /* weder Ein- noch Ausstiegsorte vorhanden */
  public static CDEINAUS_EINSTIEG = 1    /* Einstiegsorte gem. Routenhalt */
  public static CDEINAUS_AUSSTIEG = 2    /* Ausstiegsorte gem. Routenhalt */
  public static CDEINAUS_BEIDE = 3    /* Ein- und Ausstieg gem. Routenhalt (z.Z. nicht implementiert) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVAUSWAHLCODE = 2047 /* Gibt an, welche Bedingungen bei der Auswahl beachtet werden müssen */
  public static CDPRVAUSW_KEINE_BED = 0    /* Keine Bedingungen (Default) */
  public static CDPRVAUSW_ALLE = 1    /* Alle, die dieselbe übergeordnete Leistung buchen, müssen diese Leistung gemeinsam buchen oder keiner */
  public static CDPRVAUSW_DARUNTER_GLEICH = 2    /* Wenn dieses Produkt abgebucht wird, müssen alle Teilnehmer die gleiche untergeordnete Leistung buchen */
  public static CDPRVAUSW_DARUNTER_MEHRERE = 3    /* Pro Teilnehmer sind mehrere untergeordnete Leistungen buchbar */
  public static CDPRVAUSW_DARUNTER_GLEICH_UND_MEHRERE = 4    /* Kombination von Code 3+4: Pro Teilnehmer sind mehrer untergeordnete Leistungen möglich, es müssen aber alle Teilnehmer die gleichen buchen */
  public static CDPRVAUSW_UNTERSCH_DATEN_GLEICH = 5    /* Wenn die Leistung an unterschiedlichen Daten angeboten wird, muss pro Teilnehmer an jedem Datum dieselbe Kategorie abgebucht werden. */
  public static CDPRVAUSW_AUTOM_UEBER = 10   /* Zuteilung automatisch gem. übergeordneter Leistung */
  public static CDPRVAUSW_AUTOM_UEBER_NICHT_ANZEIGEN = 12   /* Zuteilung automatisch gem. übergeordneter Leistung, Leistung selbst nicht anzeigen */
  public static CDPRVAUSW_KEIN_EIN_AUSSTIEG = 30   /* Es kann kein Ein-/Ausstiegsort gewählt werden (bei Transportketten, die normalerweise einen Ein-/Ausstiegsort haben) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVABCODE = 2048 /* Bezugsbasis des Feldes PRVAB; wird auch für den VPRABCODE verwendet, dort gilt die Basis  */
  public static CDPRVAB_VOR_HAUPTLEIST = -2   /* Vor Beginn der Hauptleistung */
  public static CDPRVAB_HAUPTLEISTUNG = 0    /* Die Hauptleistung selbst */
  public static CDPRVAB_BEGINN_HAUPTLEIST = 2    /* Nach Beginn der Hauptleistung */
  public static CDPRVAB_WAEHREND_HAUPTLEIST = 3    /* Alle möglichen Daten während der Hauptleistung */
  public static CDPRVAB_EINES_WAEHREND_HAUPTLEIST = 4    /* Ein Datum aus allen möglichen während der Hauptleistung */
  public static CDPRVAB_ENDE_HAUPTLEIST = 9    /* Tage vor oder nach Ende der Hauptleistung */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVPRIOCODE_NL = 2049 /* Prioritätscodes der Produkteverbindungen für Nebenleistungen (Defines gem. Codeart 2016) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRREPORTCODE = 2050 /* Art wie Buchungen und Requests gemeldet werden */
  public static CDPRREP_MIN_LANDLEIST = 0    /* Erster für Landleistungen vorgesehener Reportcode */
  public static CDPRREP_MIN_NORM_LANDLEIST = 0    /* Erster für alle Landleistungen verfügbarer Reportcode */
  public static CDPRREP_NICHT = 0    /* keine automatische Meldung (Eintrag in DB als NULL) */
  public static CDPRREP_MIN_AUTOM = 11   /* Erster für automatische Sell- & Reports reservierter Code */
  public static CDPRREP_AUTOM_ANBIETER = 11   /* Autom. Sell & Report pro Dossier an den Anbieter */
  public static CDPRREP_AUTOM_AGENT = 12   /* Autom. Sell & Report pro Dossier an den Agenten */
  public static CDPRREP_MAX_AUTOM = 29   /* Letzter für Sell & Report reservierter Code */
  public static CDPRREP_MAX_NORM_LANDLEIST = 29   /* Letzter für alle Landleistungen verfügbarer Reportcode */
  public static CDPRREP_HOTELRES = 30   /* Automatische Meldung via XML-Datei an Hotelreservationssystem (z.Z. nur Universal, das 6. Zusatzkriterium muss den Hotelcode beinhalten) */
  public static CDPRREP_ACS_XML = 31   /* ACS XML Datei */
  public static CDPRREP_DB_RAILFLY = 40   /* Meldung inkl. Ticketerstellung via Rail- & Fly-Webservice der Deutschen Bahn */
  public static CDPRREP_DB_RAILCRUISE = 41   /* Deutsche Bahn Rail&Cruise-Ticket */
  public static CDPRREP_PARKSYSTEM = 45   /* Meldung an Parksystem (aktuell nur Monocard unterstützt) */
  public static CDPRREP_MAX_LANDLEIST = 49   /* Letzter für Landleistungen vorgesehener Reportcode */
  public static CDPRREP_MIN_POOLING = 50   /* Erster für die Transport-Poolingschnittstellen reservierter Code (der Bereich stimmt mit MLDSUBCODE überein) */
  public static CDPRREP_AIRBERLIN_POOLING = 50   /* *** ALT *** Air Berlin durch Eurowings ersetzt */
  public static CDPRREP_EUROWINGS_POOLING = 50   /* Automatischer Sell & Report via Eurowings-Pooling-Schnittstelle inkl. möglicher Kontingentsanpassung durch Eurowings */
  public static CDPRREP_CONDOR_POOLING = 51   /* Automatischer Sell & Report via Condor-Pooling-Schnittstelle inkl. möglicher Kontingentsanpassung durch Condor */
  public static CDPRREP_MAX_POOLING = 59   /* Letzter für die Poolingschnittstellen reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_BESTAETIGUNGSINFOCODE = 2052 /* Zusatzinfos auf Internet- Direktbuchungskunden- Bestätigungen */
  public static CDBESTINFO_KEINE_INFO = 0    /* NULL=Keine Zusatzinformationen, d.h. keine Direktbuchung möglich */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRGEBDATCODE = 2053 /* Gibt an, ob bei Buchungen gewisse Anforderungen an das Geburtsdatum der Teilnehmer gestellt werden */
  public static CDPRGEB_KEINE = 0    /* keine speziellen Anforderungen (als NULL gespeichert) */
  public static CDPRGEB_OBLIG = 1    /* Geburtsdatum muss auch bei Erwachsenen erfasst werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_GOBJCODE = 2054 /* Art der Sperrung eines Objekts (Zimmer bzw. Kabine) */
  public static CDGOBJ_NICHT_GESPERRT = 0    /* Objekt grundsätzlich buchbar (in der Tabelle GESPERRTE_EINHEIT wird dieser Code nie gespeichert) */
  public static CDGOBJ_INTERNET_GESPERRT_VON = 10   /* Erster Code, bei dem das Objekt übers Internet überhaupt nicht gebucht werden kann (letzter Code gem. GESPERRT_BIS) */
  public static CDGOBJ_NUR_INTERN = 10   /* Objekt kann nur intern gebucht werden */
  public static CDGOBJ_FREI_HALTEN = 11   /* frei halten (Objekt nach Möglichkeit nicht einsetzen, fürs Internet gesperrt) */
  public static CDGOBJ_GESPERRT_VON = 20   /* Erster Code, bei dem das Objekt überhaupt nicht buchbar ist (letzter Code gem. GESPERRT_BIS) */
  public static CDGOBJ_GESPERRT = 20   /* Gesperrt aus diversen Gründen */
  public static CDGOBJ_GESPERRT2 = 21   /* Gesperrt aus diversen Gründen, dunklere Farbe zur Unterscheidung von Code 20 */
  public static CDGOBJ_CHARTER = 30   /* Schiff wird mit anderem Partner gemeinsam geführt */
  public static CDGOBJ_UNTERKONT = 31   /* Unterkontingent an anderen Partner */
  public static CDGOBJ_DOSSIER = 80   /* Aktives Dossier (Objekt, Request, Buchung). Code wird nur in der Tournet-DB gefüllt. */
  public static CDGOBJ_GESPERRT_BIS = 99   /* Letzter Wert, bei dem das Objekt überhaupt nicht gebucht werden kann */
  public static CDGOBJ_INTERNET_GESPERRT_BIS = 99   /* Letzter Wert, bei dem ein Objekt übers Internet überhaupt nicht gebucht werden kann */

  /*--------------------------------------------------------------------------*/

  public static TAB_LDATSTATUS = 2055 /* Zustand des Leistungsdatums */
  public static CDLDATSTAT_NORMAL = 0    /* NULL=Normalfall (keine spezielle Einstellung) */
  public static CDLDATSTAT_INTERN = 2    /* Datum kann nur intern gebucht werden (nur für Standardreise und im separaten Internet-Status für TRB) */
  public static CDLDATSTAT_NUR_REQUEST = 3    /* Das Angebot wurde auf Stop Sale gesetzt, Requests sind jedoch noch möglich (nur für LL) */
  public static CDLDATSTAT_KEIN_REQUEST = 4    /* Buchungen können innerhalb des Kontingents durchgeführt werden, aber kein Request mehr möglich (TRB) */
  public static CDLDATSTAT_AUSGEBUCHT = 5    /* Angebot ist ausgebucht (kein Request möglich) */
  public static CDLDATSTAT_GESPERRT = 7    /* Abreise ist (noch) gesperrt (wie Code 8 weder Buchung noch Request möglich; z.Z. ebenfalls nur auf Ebene Standardreise) */
  public static CDLDATSTAT_ANNULLIERT = 8    /* Abreise wurde annulliert, d.h. es kann weder gebucht noch ein Request abgesetzt werden (z.Z. nur auf Ebene Standardreise) */
  public static CDLDATSTAT_OHNE_PREIS = 10   /* Für dieses Datum ist der Preis noch nicht bestimmt, man kann aber  unverbindlich buchen (nur STR) */
  public static CDLDATSTAT_VORGARANTIERT = 24   /* Für den Kunden wie garantiert, intern (z.B. in Reiseleiterdispo) jedoch erst als garantiert vorgesehen (nur auf Ebene Standardreise) */
  public static CDLDATSTAT_GARANTIERT = 25   /* Abreise garantiert (nur auf Ebene Standardreise) */

  /*--------------------------------------------------------------------------*/

  public static TAB_OBJTCODE = 2056 /* Art des Objektteils, -gruppe bzw. Events(OBJTGRUPPENCODE, KONTINGENTSKLASSE, [VPR]BUCHUNNGSKLASSE[N]) */
  public static CDOBJT_GRUPPE_VON = 48   /* Erster für die Objektteil-Gruppe reservierter Code (ASCII-Code von '0') */
  public static CDOBJT_GRUPPE_BIS = 57   /* Letzter für die Objektteil-Gruppe reservierter Code (ASCII-Code von '9') */
  public static CDOBJT_EVENTGRUPPE = 64   /* Gruppencode für Events (ASCII-Wert von '@') */
  public static CDOBJT_EVENT_VON = 65   /* Erster für Events reservierter Code (ASCII-Code von 'A') -> zusätzlicher Bereich siehe ab EVENT2_VON */
  public static CDOBJT_EVENT_BIS = 90   /* Letzter für Events reservierter Code (ASCII-Code von 'Z') */
  public static CDOBJT_TEIL_VON = 97   /* Erster für Objektteile reservierter Code (ASCII-Code von 'a') */
  public static CDOBJT_TEIL_BIS = 122  /* Letzter für Objektteile reservierter Code (ASCII-Code von 'z') */
  public static CDOBJT_EVENT2_VON = 161  /* Beginn des zusätzlich für Events reservierten Bereichs (ASCII-Code von '¡'=spez. Sonderzeichen) */
  public static CDOBJT_EVENT2_BIS = 221  /* Ende des zusätzlichen für Events reservieren Bereichs (ASCII-Code von 'Ý'=Länderspez.Grossbuchst.) */

  /*--------------------------------------------------------------------------*/

  public static TAB_MGRABATTCODE = 2057 /* Zum Tragen kommender Mitgliederrabatt (=Stufen gem. Mitgliedschaftsart) */
  public static CDMGRAB_KEIN = 0    /* (NULL)=Kein spezieller Rabatt für Mitglieder */
  public static CDMGRAB_VON = 1    /* Erste mögliche Rabattstufe */
  public static CDMGRAB_BIS = 5    /* Letzte mögliche Rabattstufe */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRAGEBOGENCODE = 2058 /* Art des benötigten Fragebogens */
  public static CDFRBO_GEM_STR = -1   /* Nur bei Landleistungen möglich: Es gilt der Fragebogen der Standardreise (nur für das automatische Bordmanifest via App von Bedeutung) */
  public static CDFRBO_KEIN = 0    /* NULL=kein Fragebogen nötig */

  /*--------------------------------------------------------------------------*/

  public static TAB_BUCHUNGSANFRAGECODE = 2059 /* Art der möglichen Buchungsanfrage */
  public static CDBUANFR_KEINE = 0    /* keine Buchungsanfrage möglich (NULL) */
  public static CDBUANFR_MIT_PREIS = 1    /* Buchungsanfrage mit Dossiererstellung und Preisanzeige */
  public static CDBUANFR_OHNE_PREIS = 2    /* Buchungsanfrage mit Dossiererstellung, aber ohne Preisanzeige */

  /*--------------------------------------------------------------------------*/

  public static TAB_BUCHUNGSABLAUFCODE = 2060 /* Art, wie normale Buchungen ablaufen */
  public static CDBUABL_DEFAULT = 0    /* NULL=Default gem. Reiseart (bei Tagesfahrten 10 sonst 20) */
  public static CDBUABL_OHNE_TEILNEHMER = 10   /* Es müssen keine Teilnehmer erfasst werden und es wird keine Rechnung erstellt (nur Preis angezeigt) */
  public static CDBUABL_OHNE_RECHNUNG = 11   /* Es wird keine Rechnung erstellt (nur Preis angezeigt), Teilnehmernamen werden jedoch abgefragt */
  public static CDBUABL_RECHNUNG = 20   /* Normale Rechnung, Teilnehmernamen, Zahlungsart im Internet gem. Standardeinstellungen des Mandanten */
  public static CDBUABL_RECHNUNG_EMAIL = 21   /* wie Rechnung, diese wird jedoch bei einer Internetbuchung direkt verbucht und nur per Mail verschickt */
  public static CDBUABL_RECHNUNG_EMAIL_DOK = 22   /* wie Rechnung-E-Mail, jedoch alle Dokumente per Mail */
  public static CDBUABL_GRUPPE = 30   /* Nur 1 Teilnehmer für ganze Gruppe hinterlegen, Anzahl PAX im Vornamen; Rechn. nur, wenn Preise in STR... */
  public static CDBUABL_GRUPPE_KURSFAHRT = 31   /* wie GRUPPE, zus. spez. Buchungsmaske für Kursfahrten mit Ein-/Ausstieg und Platzart (keine LL mögl.) */
  public static CDBUABL_GRUPPE_KURSFAHRT_INETRG = 32   /* wie 31, Rechnung jedoch nur bei Internetbuchungen automatisch */
  public static CDBUABL_KURSRESERVATION = 40   /* Nur Teilnehmer (Adresse gem. Reiseart-Kurzbez.), keine Rechnung, Buchungsmaske mit Hin-/Rückfahrtsort */
  public static CDBUABL_FREIER_VERKAUF = 90   /* Verkauf Gutscheine+Waren ohne fixes Kontingent (Abreisedatum+Teilnehmer nur intern aus techn.Gründen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_REISEDAUER = 2061 /* Reisedauerbereiche für die Auswahl (v.a. Internet) */
  public static CDRD_ALLE = 0    /* keine Einschränkung der Reisedauer */

  /*--------------------------------------------------------------------------*/

  public static TAB_RHEINAUSSTIEGSCODE = 2062 /* Art des Routenhalts (Ein-/Ausstiegsorts) für Kursfahrten */
  public static CDRHEABEIDE = 0    /* NULL (nicht 0)=Ein- und Ausstieg möglich (Default) */
  public static CDRHEAEINSTIEG = 10   /* nur Einstieg möglich */
  public static CDRHEAAUSSTIEG = 20   /* nur Ausstieg möglich */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_STDROUTE = 2063 /* Vordefinierte Produktarten für Standardrouten (PRCODE 21) */
  public static CDSRO_STANDARD = 0    /* Standardroute für Transportbewegung */
  public static CDSRO_ZUBRINGER = 1    /* Zubringerrouten für die Dispo */
  public static CDSRO_KURS = 2    /* Route für Kursfahrten (Ein- und Ausstiegsorte gleichzeitig bzw. markierbar, ob nur Ein- oder Ausstieg) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RSPBEZCODE = 2064 /* Reiseprogrammbezeichnung */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZOLLAMTCODE = 2065 /* Ein-/Ausreisezollamt für grenzüberschreitende Fahrten */

  /*--------------------------------------------------------------------------*/

  public static TAB_STRSUCHCODE = 2066 /* Erweiterte Suchmöglichkeiten einer Standardreise */
  public static CDSTRSUCHE_STD = 0    /* Erscheint nicht in speziellen Suchmasken (Default, NULL) */
  public static CDSTRSUCHE_KALENDER = 1    /* Erscheint im Reisekalender */

  /*--------------------------------------------------------------------------*/

  public static TAB_DIREKTZAHLUNGSCODE = 2067 /* Direktzahlungsmöglichkeit bzw. -pflicht bei Direktkunden-Internetbuchungen */
  public static CDDIRZ_DEFAULT = 0    /* NULL=Default gem. Mandanteneinstellung (Codeart 5009, Code 1090, 3. Stelle) */
  public static CDDIRZ_FAK = 1    /* Fakultativ (analog Mandantenoption "R") */
  public static CDDIRZ_FAK_SOFORT_OBLIG = 3    /* Fakultativ, bei sofortiger Fälligkeit jedoch Gesamtbetrag obligatorisch (analog Mandantenoption "K") */
  public static CDDIRZ_FAK_ANZLG_OBLIG = 5    /* Fakultativ, Anzahlung bzw. bei kurzfristiger Buchung Gesamtbetrag oblig. (analog Mandantenoption "A") */
  public static CDDIRZ_VZ_OBLIG_AB_10_PAX = 9    /* Ab 10 Pax obligatorisch oder Vorauszahlung (wenn nicht kurzfristig), darunter fakultativ */
  public static CDDIRZ_OBLIG_AB_10_PAX = 10   /* Ab 10 PAX obligatorisch, darunter fakultativ */
  public static CDDIRZ_OBLIG = 19   /* Gesamtbetrag obligatorisch (analog Mandantenoption "D") */
  public static CDDIRZ_NEIN = 21   /* Direktzahlung nicht möglich */
  public static CDDIRZ_NEIN_SOFORT_OBLIG = 23   /* Nicht möglich, ausser bei sofortiger Fälligkeit, dann obligatorisch */
  public static CDDIRZ_NEIN_ANZLG_OBLIG = 25   /* Nicht möglich, ausser bei kurzfristiger Buchung Anzahlung bzw. wenn ohne Anzahlung Gesamtbetrag obligtorisch */

  /*--------------------------------------------------------------------------*/

  public static TAB_ANZBELEGT = 2068 /* Spezielle Werte für das Leistungsdatum-Kontingentsfeld (nicht den Produkt-Kontcode!) */
  public static CDANZBELEGT_PRUEFEN = -1   /* Nur in Internet-DB: Bei Std.reisen Datum nicht anzeigen, wenn beim Reiseprog. kein Transport frei ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRGCODE = 2069 /* Produktgeschichtseintragsarten */
  public static CDPRG_MAREKING_MAIL = 100  /* Info-E-Mail an Marketingzuständige verschickt (==> Define falsch geschrieben, aus Kompatiblitätsgründen noch bestehen lassen) */
  public static CDPRG_MARKETING_MAIL = 100  /* Info-E-Mail an Marketingzuständige verschickt */
  public static CDPRG_EP_VERARB = 280  /* Art der Einkaufspreisverarbeitung verändert */
  public static CDPRG_EP_PERIODIZITAET = 281  /* EP-Verarbeitungszeitpunkt verändert */
  public static CDPRG_ZUST_NEU = 800  /* Zuständigkeit hinzugefügt (unter gleichem Code auch bei der Partnergeschichte vorhanden) */
  public static CDPRG_ZUST_PERS_AEND = 801  /* Zuständige Person geändert (unter gleichem Code auch bei der Partnergeschichte vorhanden) */
  public static CDPRG_ZUST_STATUS_AEND = 802  /* Zuständigkeitsstatus geändert */
  public static CDPRG_ZUST_GELOESCHT = 809  /* Zuständigkeit gelöscht (unter gleichem Code auch bei der Partnergeschichte vorhanden) */
  public static CDPRG_UMFRAGE = 1030 /* Umfrageeintrag verändert */
  public static CDPRG_STR_GEL = 1090 /* Standardreise gelöscht */
  public static CDPRG_TRK_GEL = 3090 /* Transportkette gelöscht */
  public static CDPRG_KONT_ART = 3100 /* Kontingentsart verändert */
  public static CDPRG_POOLING_EIN = 3101 /* Pooling eingeschalten */
  public static CDPRG_POOLING_AUS = 3102 /* Pooling ausgeschalten */
  public static CDPRG_TRB_DATUM_NEU = 3200 /* Abreisedatum hinzugefügt */
  public static CDPRG_TRB_DATUM_GEL = 3201 /* Abreisedatum entfernt */
  public static CDPRG_KONT = 3210 /* Kontingent verändert */
  public static CDPRG_FIXKONT = 3211 /* Festkontingent verändert */
  public static CDPRG_PLATZ = 3220 /* Gesamtplatz verändert */
  public static CDPRG_TRSP_ZEIT = 3221 /* Transportzeiten verändert (Dauer, Checkin, Abreise, Ankunft und/oder Reisezeit) */
  public static CDPRG_TRSP_VERFALL = 3222 /* Verfall (Datum und/oder Zeit) */
  public static CDPRG_BUKLASSEN = 3230 /* Buchungsklassen verändert */
  public static CDPRG_POOLING_KONT = 3250 /* Kontingent duch Poolingschnittstelle verändert */
  public static CDPRG_POOLING_TRSP_ZEIT = 3251 /* Transportzeiten durch Poolingschnittstelle verändert */
  public static CDPRG_REQUEST_UEBERBUCHEN_OK = 3290 /* In einem Dossier wurde ein Request durch Überbuchung auf ok gesetzt */
  public static CDPRG_LL_GEL = 5090 /* Landleistung gelöscht */
  public static CDPRG_LL_KONT = 5210 /* Landleistungskontingent verändert */
  public static CDPRG_LL_KONT_STORNO_UNV = 5211 /* LL-Kontingent trotz (Teil-)Storno NICHT reduziert */
  public static CDPRG_SELL_REPORT = 5900 /* Sammel-Sell & Report verschickt */
  public static CDPRG_EXTERNE_DATEI = 9000 /* Verweis auf externe Datei mit Namen PRID + PRGLAUFNR + Originalendung */
  public static CDPRG_MANUELL = 9900 /* Manueller Geschichtseintrag durch den Benutzer */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZLDOKU = 2070 /* Namen der Dokumente, die dem Kunden bei Buchung der Zusatzleist. mit gleichem Code mitgeschickt werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_VECODE = 2071 /* Art der vermerkten Einheit */
  public static CDVE_OBJEKT = 1    /* Automatisch gem. Auswahl aus Objekten */
  public static CDVE_MANUELL = 5    /* Manuelle Eingabe im Dossier selbst (Format wie Objekt) ohne Prüfung auf Doppelbuchungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_AGENDABAUSTEIN = 2072 /* Vordefinierte Produktarten für Agenda-Bausteine (PRCODE 25), Codes 1-99 entsprechen dem PRCODE gem. Codeart 2000 */
  public static CDAGB_STR = 1    /* Standardreisen */
  public static CDAGB_TRK = 3    /* Transportketten */
  public static CDAGB_LL = 5    /* Landleistungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_AGVSTATUS = 2073 /* Zustand der Vorlage */
  public static CDAGVSTAT_AKTIV = 0    /* Vorlage ist aktiv */
  public static CDAGVSTAT_GESPERRT = 5    /* Vorlage ist gesperrt, d.h. wird beim Kopieren der Vorlage bzw. Erstellen der Agendaeinträge nicht berücksichtigt */
  public static CDAGVSTAT_GELOESCHT = 9    /* Gelöschter Eintrag ab Baustein */

  /*--------------------------------------------------------------------------*/

  public static TAB_AGVEMPFAENGERCODE = 2074 /* Art des Empfängers für den Agendaeintrag */
  public static CDAGVEMPF_DEFAULT = 0    /* Default gem. automatischer Verarbeitung (nur beim AGVPROCODE nicht NULL erlaubt) */
  public static CDAGVEMPF_STANDARDREISE = 1    /* Standardreisen-Sachbearbeiter */
  public static CDAGVEMPF_REISEDATUM = 2    /* Reisedatum-Verantwortlicher */
  public static CDAGVEMPF_PRODUKT = 5    /* Produkt-Sachbearbeiter (Transportkette/Landleistung) */
  public static CDAGVEMPF_PROD_VON = 4000 /* Erster für produktabhängige Zuständigkeiten reservierter Code (aus Codeart 2960) */
  public static CDAGVEMPF_STR_VON = 4100 /* Erster für Standardreisen reservierter Code */
  public static CDAGVEMPF_STR_INDIV_VON = 4180 /* Erster für individuelle Standardreisen-Zuständigkeiten reservierter Code */
  public static CDAGVEMPF_STR_INDIV_BIS = 4199 /* Letzter für individuelle Standardreisen-Zuständigkeiten reservierter Code */
  public static CDAGVEMPF_STR_BIS = 4199 /* Letzter für Standardreisen reservierter Code */
  public static CDAGVEMPF_PROD_BIS = 4999 /* Letzter für produktabhängige Zuständigkeiten reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_AGVDATCODE = 2075 /* Art der Datumsabhängigkeit des zu erstellenden Agendaeintrags */
  public static CDAGVD_ABREISE = 1    /* Abreisedatum der Standardreise */
  public static CDAGVD_MIN_RUECKREISE = 2    /* Abreisedatum + minimale Reisedauer der Standardreise */
  public static CDAGVD_VERFALL = 5    /* Verfalldatum (nur bei Transport und Landleistung möglich) */
  public static CDAGVD_TRANSPORT = 8    /* Abflug- bzw. Abfahrtsdatum bei Transporten */
  public static CDAGVD_PRO_DOSSIER_VON = 50   /* Spezifisch pro Dossier von */
  public static CDAGVD_RECHNUNG1 = 51   /* 1. Rechnung */
  public static CDAGVD_PRO_DOSSIER_BIS = 69   /* Spezifisch pro Dossier bis */

  /*--------------------------------------------------------------------------*/

  public static TAB_AGVFILTERCODE = 2076 /* Produktteile, auf die sich der Eintrag bezieht */
  public static CDAGVF_ALLE = 0    /* (NULL)=alle Produktteile */
  public static CDAGVF_HINREISE = 30   /* Nur Hinreise (Code analog PRVFUNKCODE) */
  public static CDAGVF_RUECKREISE = 31   /* Nur Rückreise (Code analog PRVFUNKCODE) */

  /*--------------------------------------------------------------------------*/

  public static TAB_GIT_BUCHUNGSKLASSE = 2077 /* GIT-Buchungsklassen pro Airline (Hin-/Rückreise beide GIT, evt. zus. OSI-Segment) */

  /*--------------------------------------------------------------------------*/

  public static TAB_MAHLZEITEN = 2078 /* Mahlzeitencodes gem. offiz. CRS-Buchstaben mitCodes 45,65-90 (-,A-Z) zus. indiv. für Cars 97-122(a-z) */

  /*--------------------------------------------------------------------------*/

  public static TAB_BKLZUTEILUNGSCODE = 2079 /* Bestimmt wie PAX einer Buchungsklasse zugeteilt werden, wenn die Freiplätze nicht für alle ausreichen */
  public static CDBKLZ_TEILEN = 0    /* PAX exakt zwischen Klassen aufteilen */
  public static CDBKLZ_ALLE = 1    /* Alle Pax dieser Buchungsklasse zuteilen */
  public static CDBKLZ_KEINE = 2    /* Dieser Buchungsklasse kein PAX zuteilen */

  /*--------------------------------------------------------------------------*/

  public static TAB_DESTSTATUS = 2080 /* Zustand der Destination */
  public static CDDESTSTAT_AKTIV = 0    /* Aktive Destination */
  public static CDDESTSTAT_GELOESCHT = 9    /* Destination wird nicht mehr neu verwendet */

  /*--------------------------------------------------------------------------*/

  public static TAB_KATGRUPPE = 2081 /* Gruppe der Kategorien (pro 100er der Codeart 2106), gültige Codes sind somit 0-99 */

  /*--------------------------------------------------------------------------*/

  public static TAB_BKLGRUPPE = 2082 /* Gruppe der Buchungsklassen (Die CDKURZBEZ enthält alle Buchungsklassenbuchstaben) */

  /*--------------------------------------------------------------------------*/

  public static TAB_STRPREISCODE = 2083 /* Bestimmt, welche Preise berücksichtigt werden sollen */
  public static CDSTRPR_DEFAULT = 0    /* (NULL)=Preis in Standardreise hat 1. Priorität (Default) */
  public static CDSTRPR_VP_NUR_STR = 1    /* Es gelten nur die in der Standardreise hinterlegten Verkaufspreise */

  /*--------------------------------------------------------------------------*/

  public static TAB_PREPCODE = 2084 /* Art, wie Einstandspreise erfasst und allenfalls gegenüber dem Leistungsträger abgerechnet werden */
  public static CDPREP_KEIN = 0    /* Kein Einstandspreis nötig */
  public static CDPREP_OBLIG_VON = 10   /* Erster für obligatorische EP-Erfassung reservierter Code */
  public static CDPREP_VERR_VON = 20   /* Erster für die Verrechnungskontrolle reservierter Wert */
  public static CDPREP_MAN_KONTROLLE = 20   /* Manuelle Abrechnungskontrolle */
  public static CDPREP_AUTOM_VON = 30   /* Erster für die automatische Abrechnung reservierter Code */
  public static CDPREP_AUTOM_DRUCK = 30   /* Automatischer Ausdruck einer Hotelabrechnung */
  public static CDPREP_AUTOM_ANBIETER = 31   /* Automatische Übermittlung einer Hotelabrechnung per Mail an den Anbieter (Rechnung von Hotel erwartet) */
  public static CDPREP_AUTOM_AGENT = 32   /* Automatische Übermittlung einer Hotelabrechnung per Mail an den Agenten (Rechnung von Hotel erwartet) */
  public static CDPREP_AUTOM_RG_ANBIETER = 41   /* Automatische Übermittlung einer Hotelrechnung per Mail an den Anbieter */
  public static CDPREP_AUTOM_RG_AGENT = 42   /* Automatische Übermittlung einer Hotelrechnung per Mail an den Agenten */
  public static CDPREP_AUTOM_BIS = 49   /* Letzter für die automatische Abrechnung reservierter Code */
  public static CDPREP_VERR_BIS = 99   /* Letzter für die Verrechnungskontrolle reservierter Wert */
  public static CDPREP_OBLIG_BIS = 99   /* Letzter für obligatorische EP-Erfassung reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRKDAUERCODE = 2085 /* Art wie die Dauer bei den Produktkonditionen berücksichtigt werden soll */
  public static CDPRKD_KEINE = 0    /* keine von der Dauer abhängige Preise (nur PREIS1 möglich) */
  public static CDPRKD_BASIS_VERL = 1    /* Erst Spalte ist der Basis- und die übrigen sind Verlängerungspreise */
  public static CDPRKD_VOLLSTAENDIG = 5    /* Erste Dauer, die ganz in der Buchungsperiode Platz hat, verwenden. Mit dem Rest gleich verfahren. */
  public static CDPRKD_TAG_VOLLSTAENDIG = 6    /* Tagespreise, erste Dauer die ganz in der Buchungsperiode Platz hat, verwenden. Mit dem Rest gleich verfahren. */
  public static CDPRKD_TAG_DAUER = 7    /* Tagespreise abhängig von der gebuchten Aufenthaltsdauer */
  public static CDPRKD_GENAUE_DAUER = 9    /* Nur die Dauer berücksichtigen, bei der die Anzahl Tage genau übereinstimmt. Zusatzleistung ignorieren, wenn keine Anzahl genau passt. */
  public static CDPRKD_STAFFELPREIS = 10   /* Staffelpreis (Bezugsbasis gem. PRKDAUER1, erste Dauer = Bis-Betrag, zweite Dauer = Preis") */
  public static CDPRKD_EINFACH_RETOUR = 20   /* Hin- und Retourtarif (keine Dauer, nur PREIS1 für Hinfahrt und PREIS2 für Hin- und Rückfahrt) */
  public static CDPRKD_TOTAL_VON = 50
  public static CDPRKD_GESTOT_FIX = 50   /* Fixpreis abhängig von Gesamtanzahl */
  public static CDPRKD_GESTOT_TAG = 51   /* Nacht/Tag abhängig von Gesamtanzahl */
  public static CDPRKD_FIX_DIV_GESTOT = 55   /* Fixpreis dividiert durch Gesamtanzahl */
  public static CDPRKD_TAG_DIV_GESTOT = 56   /* Nacht/Tag dividiert durch Gesamtanzahl */
  public static CDPRKD_AKTTOT_VON = 60
  public static CDPRKD_AKTTOT_TAG = 61   /* Nacht/Tag abhängig von aktueller Anzahl */
  public static CDPRKD_AKTTOT_BIS = 69
  public static CDPRKD_TOTAL_BIS = 69

  /*--------------------------------------------------------------------------*/

  public static TAB_REISEFUEHRERCODE = 2086 /* Art des digitalen Reiseführers */
  public static CDRF_KEIN = 0    /* Kein Reiseführer */
  public static CDRF_STD = 1    /* Standardreiseführer */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZLGR_DOKU_NGEB = 2087 /* Beilagennamen, wenn aus der Gruppe mit gleichem Code Zus.leist. mögl- wären, aber keine gebucht wurden */

  /*--------------------------------------------------------------------------*/

  public static TAB_BUCHUNGSREIHENFOLGECODE = 2088 /* Reihenfolge der einzelnen Elemente in einem Buchungsablauf */
  public static CDBURF_DEFAULT = 0    /* NULL=Standardreihenfolge des entsprechenden Buchungsablaufs */
  public static CDBURF_TRANSPORT = 10   /* Transport zuerst */
  public static CDBURF_LANDLEISTUNG = 20   /* Landleistung zuerst */
  public static CDBURF_NEBENLEISTUNG = 30   /* Nebenleistung zuerst */
  public static CDBURF_MAX_GENERELL = 49   /* Letzter Code, der generell gültig ist */
  public static CDBURF_KOMPAKT = 50   /* Kompakt (ohne Kinder, Transport nur Einstiegsorte; zur Zeit nur beim Buchungsablauf "ohne Teilnehmernamen" möglich) */

  /*--------------------------------------------------------------------------*/

  public static TAB_GUTSCHEINCODE = 2089 /* Gutscheincodes (Codes < 1 spezielle Bedeutung, ohne Codeintrag) */
  public static CDGS_KEINE_ERLAUBT = -1   /* Kein Gutscheincode erlaubt (nur auf der Standardreise möglich) */
  public static CDGS_LEER = 0    /* NULL= Ebene Standardreise nur allgemeine Gutscheincodes möglich; Ebene Dossier kein Gutschein ausgew. */

  /*--------------------------------------------------------------------------*/

  public static TAB_GSRSTATUS = 2090 /* Zustand der Gutscheinregel */
  public static CDGSRS_AKTIV = 0    /* Regel ist aktiv */
  public static CDGSRS_INDIV = 2    /* Regel gilt nur für die Abgabe und/oder Einlösung indivdueller Gutscheine (die CDKURZBEZ hat dann keine Bedeutung) */
  public static CDGSRS_NUR_ABGABE = 3    /* Regel gilt nur für die Prüfung der Abgabe */
  public static CDGSRS_GESPERRT = 5    /* Regel ist für die Nutzung z.Z. gesperrt, kann aber eingerichtet werden */
  public static CDGSRS_GELOESCHT = 9    /* gelöscht */

  /*--------------------------------------------------------------------------*/

  public static TAB_GSRTYPCODE = 2091 /* Art wie bei Standardreisen das Gutscheincode-Feld ausgefüllt sein muss */
  public static CDGSRT_KEINE_EINSCHRAENKUNGEN = 0    /* (NULL) = keine Einschränkungen (z.Z. nicht implementiert) */
  public static CDGSRT_NULL_ODER_BELIEBIG = 1    /* leer oder irgendein Gutscheincode > 0 */
  public static CDGSRT_NULL_ODER_IDENTISCH = 2    /* leer oder gleicher Gutscheincode wie bei der Regel */
  public static CDGSRT_IDENTISCH = 3    /* gleicher Gutscheincode wie bei der Regel */
  public static CDGSRT_ZUSATZLEISTUNGEN = 10   /* Die Regel gilt nur, wenn auch passende Zusatzleistungen mit diesem Gutscheincode versehen sind (z.Z. nicht verwendet) */

  /*--------------------------------------------------------------------------*/

  public static TAB_GSRMITGLIEDSCHAFTSCODE = 2092 /* Art wie Mitgliedschaften beim Gutscheincode berücksichtigt werden sollen */
  public static CDGSRMG_NICHT_MITGLIEDER = 1    /* Gilt nur, wenn Kunde NICHT Clubmitglied ist */
  public static CDGSRMG_MITGLIEDER = 2    /* Kunde muss Clubmitglied sein */

  /*--------------------------------------------------------------------------*/

  public static TAB_GSRRABATTCODE = 2093 /* Art, wie der Gutscheinrabatt berechnet werden muss */
  public static CDGSRRAB_KEIN = 0    /* NULL=kein Rabatt (z.B. zum Ausschluss bestimmter Reisen) */
  public static CDGSRRAB_BUCHUNG = 1    /* pro Buchung */
  public static CDGSRRAB_TEILNEHMER = 2    /* pro Teilnehmer (ohne Infant) */
  public static CDGSRRAB_ERWACHSENE = 3    /* pro erwachsene Person (=keine Kinderermässigung) */
  public static CDGSRRAB_KINDER = 4    /* pro Kind (ohne Infants) */
  public static CDGSRRAB_ZUSATZLEISTUNG = 8    /* Es gelten die Beträge der Zusatzleistung (z.Z. nicht implementiert) */

  /*--------------------------------------------------------------------------*/

  public static TAB_GSRRABATT_PROZENTCODE = 2094 /* Basis, von der Prozentbeträge berechnet werden (z.Z. gleiche Codeart viw GSRBETRAGSCODE) */
  public static CDGSRPR_RGBETR = 1    /* Rechnungsbetrag */
  public static CDGSRPR_ARRANGEMENT = 2    /* Nur auf dem Arrangementpreis */
  public static CDGSRPR_REISEPREIS = 6    /* Rechnungsbetrag ohne Staffelpreise und ohne Zusatzleistungsgruppen "nicht Bestandteil des Reisepreises" */
  public static CDGSRPR_LEISTUNG_AUS_KAT = 16   /* Kategorien, die unter "eine Leistung aus" in den Gutscheinregelteilen hinterlegt sind, inkl. allfälligen obligatorischen Zuschlägen */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRSPKLASSE = 2095 /* ** ALTER DEFINE ** (neu TAB_TRSPKLASSE_BUS verwenden) für ausgeschriebene Transportklassenbezeichnungen für Busse mit Einstiegsorten */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRSPKLASSE_BUS = 2095 /* Ausgeschriebene Transportklassenbezeichnungen für Busse mit Einstiegsorten */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRAPPCODE = 2096 /* Gibt an, ob und wie das Produkt in der App angezeigt werden kann */
  public static CDPRAPP_NORMAL = 0    /* NULL=normale Anzeige (Default) */
  public static CDPRAPP_NICHT_ANZEIGEN = 90   /* Das Produkt kann in der App nicht angezeigt werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRICODE = 2097 /* Art der Produktinformationen */
  public static CDPRI_STR_ANSICHT = 10   /* Reisebilder */
  public static CDPRI_TRK_ANSICHT = 30   /* Transportbilder */
  public static CDPRI_LL_ANSICHT = 50   /* Ansichten des Hotels bzw. Schiffs */
  public static CDPRI_ETAGENPLAN = 51   /* Etagen- oder Deckplan */
  public static CDPRI_KAT_ANSICHT = 60   /* Ansichten eines Beispielzimmers bzw. einer Beispielkabine einer Kategorie  */
  public static CDPRI_ZUS_ANSICHT = 70   /* Bilder zur Zusatzleistung */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRGKLASSENCODE = 2098 /* Ausgeschriebene Bezeichnungen für Standard-Transportklassen der Tabelle Transportges. (ersetzt in Zukunft die Verwendungsart TRSPKLASSE_STD) */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRSPKLASSE_STD = 2098 /* Ausgeschriebene Bezeichnungen für Standard-Transportklassen ohne Bus, sofern individuelle Texte verwendet werden sollen (s.a.TRGKLASSENCODE) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRIVERWENDUNGSCODE = 2099 /* Art der Verwendungsmöglichkeiten der Produktinfo (Defines bitweise kombinierbar) */
  public static CDPRIV_NICHT_DEF = 0    /* NULL=nicht definiert */
  public static CDPRIV_INTERNET = 1    /* Internetablauf */
  public static CDPRIV_KUNDENAPP = 2    /* Kunden-App */
  public static CDPRIV_RECHNUNG = 4    /* Offerte, Rechnung */
  public static CDPRIV_REISEPROG = 8    /* Reiseprogramm */

  /*--------------------------------------------------------------------------*/

  public static TAB____RPODUKT_BEREICHE___ = 2100 /* === Beginn der Produkt-Codearten, welche für Codeartenbereiche verwendet werden === */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYPCODE = 2100 /* Untertypen eines Produktecodes gem. PRTYPCODE+produkt.prcode (s.a. Codeart 2063 für Standardrouten) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_STR = 2101 /* Standardreise */
  public static CDSTR_MIN_TAGESFAHRTEN = 5000 /* Ab diesem Wert handelt es sich um Tagesfahrten (keine Teilnehmernamen, keine Rechnung) */
  public static CDSTR_MIN_TAGESFAHRTEN_GRATIS = 5900 /* Erster für Gratistagesfahrten (z.B. firmeneigene Ferienmesse oder sonstige Gratisevents ohne Teilnehmer und Rechnung) reservierter Wert */
  public static CDSTR_MAX_TAGESFAHRTEN_GRATIS = 5999 /* Letzter für Gratistagesfahrten reservierter Wert */
  public static CDSTR_MAX_TAGESFAHRTEN = 5999 /* Bis zu diesem Wert handelt es sich um Tagesfahrten */
  public static CDSTR_MIN_GUTSCHEINE = 6000 /* Erster für Gutscheine reservierter Code (höhere Berechtigung notwendig, Gutschein pro Preiszeile) */
  public static CDSTR_MIN_GUTSCHEINE_PZL = 6000 /* Erster für den alten Gutscheinverkauf via Preiszeilen reservierter Code */
  public static CDSTR_MIT_GUTSCHEINE_PZL = 6000 /* (Schreibfehler in bestehendem Define, daher noch belassen - neu stattdessen MIN_GUTSCHEINE_PZL verwenden). */
  public static CDSTR_MAX_GUTSCHEINE_PZL = 6099 /* Letzter für den alten Gutscheinverkaufn via Preiszeilen reservierter Code */
  public static CDSTR_MIN_GUTSCHEINE_NR = 6100 /* Erster für Gutscheinverkäufe mit Nummernverwaltung reservierter Code (separater Internetablauf) */
  public static CDSTR_MAX_GUTSCHEINE_NR = 6199 /* Letzter für Gutscheinverkäufe mit Nummernverwaltung reservierter Code */
  public static CDSTR_MAX_GUTSCHEINE = 6199 /* Letzter für Gutscheine reservierter Code */
  public static CDSTR_MIN_CHANGE = 7000 /* Erster für den Change reservierter Code (Vorschlag RGCODE 40 bei neuer Rechnung) */
  public static CDSTR_MAX_CHANGE = 7099 /* Letzter für den Change reservierter Code */
  public static CDSTR_MIN_STAMMZL = 9000 /* Erster für Standardreisen, die nur Zusatzleistungen als Vorlage für andere Standardreisen enthalten, reservierter Code */
  public static CDSTR_MAX_STAMMZL = 9099 /* Letzter für Standardreisen, die nur Zusatzleistungen als Voralge für andere Standardreisen enthalten, reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_GRP = 2102 /* Gruppe */
  public static CDGRP_NORMAL = 0    /* Normale Gruppe in den Hauptleistungen, bei der die Bezeichnung von Hand eingegeben wird */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_TRK = 2103 /* Transportkette */
  public static CDTRK_MIN_BUS = 1000 /* Erster Wert für Bus-/Schifftransporte mit speziellen Eingabefeldern (Ein-/Ausstiegsort, Fahrauftrag) */
  public static CDTRK_MIN_EIN_AUS = 1040 /* Erster Spezialfall mit der Möglichkeit zur Erfassung von Ein- UND Ausstiegsorten auf einer Route */
  public static CDTRK_MIN_LLORT = 1040 /* Erster Spezialfall für Busse, Ein- UND Ausstiegsort erfassbar, aber der zweite Ort wird bei der Buchung nicht zugeordnet (LL bestimmt ihn) */
  public static CDTRK_MAX_LLORT = 1059 /* Letzter Spezialfall für Busse, Ein- UND Ausstiegsort erfassbar, aber der zweite Ort wird bei der Buchung nicht zugeordnet (LL bestimmt ihn) */
  public static CDTRK_MIN_NUR_EIN = 1060 /* Erster Spezialfall, bei dem im Internet nur die Einstiegszeit angezeigt wird */
  public static CDTRK_MAX_NUR_EIN = 1079 /* Letzter Spezialfall, bei dem im Internet nur die Einstiegszeit angezeigt wird */
  public static CDTRK_MIN_KURS = 1080 /* Erster Spezialfall für Kursbusse bzw. -schiffe (Ein- UND Ausstiegsort ab gleichem Routenhalt) */
  public static CDTRK_MIN_KURS_TEILKONT = 1100 /* Erster Spezialfall für Kurse, bei denen das Kontingent der gebuchten Teilstrecke geprüft wird */
  public static CDTRK_MIN_GRUPPEN = 1200 /* Erster Spezialfall für Gruppenbuchungen (nur 1 Teilnehmer zuweisen, unabh. von Anz. gebuchter Plätze).... */
  public static CDTRK_MAX_EIN_AUS = 1299 /* Erster Spezialfall für Ein-/Ausstiegsorte */
  public static CDTRK_MAX_KURS_TEILKONT = 1299 /* Letzter Spezialfall für Kursbuchungen mit Kontingentsprüfung auf gebuchter Strecke */
  public static CDTRK_MAX_KURS = 1299 /* Letzter Spezialfall für Kursbusse/-schiffe */
  public static CDTRK_MAX_GRUPPEN = 1399 /* Letzter Spezialfall für Gruppenbuchungen */
  public static CDTRK_MIN_NACHBSITZ = 1400 /* Erster Spezialfall, um bei Sitzplatzreservationen den Nachbarssitz freizuhalten */
  public static CDTRK_MAX_NACHBSITZ = 1499 /* Letzter Spezialfall für Sitzplatzreservationen mit Nachbarssitz freihalten */
  public static CDTRK_MAX_BUS = 1999 /* letzter Bus-/Schiff-Spezialfall */
  public static CDTRK_MIN_NEBENLEIST = 2000 /* Erster Spezialfall für Nebenleistungen */
  public static CDTRK_MAX_NEBENLEIST = 3999 /* Letzter Spezialfall für Nebenleistungen */
  public static CDTRK_MIN_NACHBSITZ_FLUG = 5400 /* Erster Spezialfall, um bei Sitzplatzreservationen im Flugzeug den Nachbarssitz freizuhalten */
  public static CDTRK_MAX_NACHBSITZ_FLUG = 5499 /* Letzter Spezialfall, um bei Sitzplatzreservationen im Flugzeug den Nachbarssitz freizuhalten */
  public static CDTRK_MIN_GRUPPENPNR = 5900 /* Erster Spezialfall mit möglicher Hinterlegung der Gruppen-PNR in der Transportbewegung (nur bei aktiver Amadeus-Importschnittstelle möglich) */
  public static CDTRK_MAX_GRUPPENPNR = 5999 /* Letzter Spezialfall für die Gruppen-PNR */
  public static CDTRK_MIN_UNSICHTBAR = 7000 /* Erste Transportkette, die nicht angezeigt, aber automatisch mitgebucht wird (z.B. für alle PAX) */
  public static CDTRK_MAX_UNSICHTBAR = 7099 /* Letzte Transportkette, die nicht angezeigt, aber automatisch mitgebucht wird */
  public static CDTRK_MIN_DIV = 9000 /* Erster Spezialfall diverse unter Transport eingerichtete Leist. (z.B. Tickets): Ohne Internet-Symbol */
  public static CDTRK_MAX_DIV = 9999 /* Letzter Spezialfall für diverse Leistungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_TRS = 2104 /* Transportstrecke */
  public static CDTRS_MIN_PSEUDO = 1000 /* Erster Wert f.Pseudostrecken (Rundreisen),werden beim effektiven Abreiseort ignoriert (z.B.Tln.liste) */
  public static CDTRS_MAX_PSEUDO = 1999 /* Letzter Wert für Pseudostrecken */
  public static CDTRS_MIN_GENBEM = 2000 /* Erster Wert für generelle Teilnehmerbemerkung (z.B. "EPAY"; CDKURZBEZ auf PNR+evt. Flugliste aufber.) */
  public static CDTRS_MAX_GENBEM = 2999 /* Letzter Wert für generelle Teilnehmerbemerkung */
  public static CDTRS_MIN_BEM_ABFRAGE = 3000 /* Erster Wert für die zusätzliche Abfrage der Teilnehmerbemerkung mit Prompt gem. CDBESCHR */
  public static CDTRS_MAX_BEM_ABFRAGE = 3999 /* Letzter Wert für die zusätzliche Abfrage der Teilnehmerbemerkungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_LL = 2105 /* Landleistung */
  public static CDLL_MIN_ZIRES = 1000 /* Erster Spezialfall mit Zimmerreservationen */
  public static CDLL_MIN_KABINE = 1100 /* Erster Spezialfall für Kabinen statt Zimmer mit Reservation */
  public static CDLL_MAX_KABINE = 1199 /* Letzter Spezialfall für Kabinen statt Zimmer mit Reservation */
  public static CDLL_MIN_ZI_UNSORTIERT = 1200 /* Erster Spezialfall für nach OBJID (=Eingabereihenfolge) sortierte Zimmernummern */
  public static CDLL_MAX_ZI_UNSORTIERT = 1299 /* Letzter Spezialfall für nach OBJID (=Eingabereihenfolge) sortierte Zimmernummern */
  public static CDLL_MAX_ZIRES = 1999 /* Letzter Spezialfall mit Zimmerreservation */
  public static CDLL_MIN_NEBENLEIST = 2000 /* Erster Spezialfall für Nebenleistungen */
  public static CDLL_MIN_TICKET = 2000 /* Erster Spezialfall für Tickets */
  public static CDLL_MIN_FUER_ERSTEN_TRANSPORT = 2100 /* Erster Spezialfall, der sich auf den ersten Hinreisetransport bezieht */
  public static CDLL_MIN_AN_HEIMREISE = 2100 /* Erster Spezialfall für Nebenleistungen vom Typ An-/Heimreise */
  public static CDLL_MIN_ANREISE = 2100 /* Erster Spezialfall für Nebenleistungen vom Typ Anreise */
  public static CDLL_MAX_ANREISE = 2119 /* Letzter Spezialfall für Nebenleistungen vom Typ Anreise */
  public static CDLL_MIN_PARKPLATZ = 2120 /* Erster Spezialfall für Nebenleistungen vom Typ Parkplatz während der Dauer der Reise */
  public static CDLL_MAX_PARKPLATZ = 2139 /* Letzter Spezialfall für Nebenleistungen vom Typ Parkplatz während der Dauer der Reise */
  public static CDLL_MAX_FUER_ERSTEN_TRANSPORT = 2139 /* Letzter Spezialfall, der sich auf den ersten Hinreisetransport bezieht */
  public static CDLL_MIN_HEIMREISE = 2180 /* Erster Spezialfall für Nebenleistungen vom Typ Heimreise */
  public static CDLL_MIN_FUER_LETZTEN_TRANSPORT = 2180 /* Erster Spezialfall, der sich auf den letzten Rücktransport bezieht */
  public static CDLL_MAX_FUER_LETZTEN_TRANSPORT = 2199 /* Letzter Spezialfall, der sich auf den letzten Rücktransport bezieht */
  public static CDLL_MAX_AN_HEIMREISE = 2199 /* Letzter Spezialfall für Nebenleistungen vom Typ An-/Heimreise */
  public static CDLL_MAX_HEIMREISE = 2199 /* Letzter Spezialfall für Nebenleistungen vom Typ Heimreise */
  public static CDLL_MIN_TICKET_MIT_NR = 2900 /* Erster Spezialfall für Nebenleistungen mit Ticketnummernverwaltung */
  public static CDLL_MAX_TICKET_MIT_NR = 2999 /* Letzter Spezialfall für Nebenleistungen mit Ticketnummernverwaltung */
  public static CDLL_MAX_TICKET = 2999 /* Letzter Spezialfall für Tickets */
  public static CDLL_MIN_NUR_1_KAT = 3100 /* Erster Spezialfall, wenn alle PAX die gleiche Kategorie wählen müssen (Häkchen statt Anzahl) */
  public static CDLL_MAX_NUR_1_KAT = 3199 /* Letzter Spezialfall, wenn alle PAX die gleiche Kategorie wählen müssen */
  public static CDLL_MAX_NEBENLEIST = 3999 /* Letzter Spezialfall für Nebenleistungen */
  public static CDLL_MIN_GRUPPEN = 4000 /* Erster Spezialfall für Gruppenbuchungen (nur 1 Teilnehmer zuordnen, unabh. von Anz. gebuchter Kateg.) */
  public static CDLL_MIN_GRUPPEN_EIN_AUS = 4100 /* Erster für Gruppenbuchungen mit hinterlegtem möglichen Ein-/Ausstiegsorten reservierter Bereich */
  public static CDLL_MAX_GRUPPEN_EIN_AUS = 4199 /* Letzter für Gruppenbuchungen mit hinterlegten möglichen Ein-/Ausstiegsorten reservierter Bereich */
  public static CDLL_MAX_GRUPPEN = 4999 /* Letzter Spezialfall für Gruppenbuchungen */
  public static CDLL_MIN_UNSICHTBAR = 7000 /* Erste Landleistung, die nicht angezeigt, aber autom. mitgebucht wird (LL aus techn. Gründen vorhand.) */
  public static CDLL_MAX_UNSICHTBAR = 7099 /* Letzte unsichtbare Landleistung */
  public static CDLL_MIN_VERANSTALTUNG = 8000 /* Erster Spezialfall für Veranstaltungskalender (Saison=Veranstaltungsdaten, -farbe=Wo.tag, keine Kat.) */
  public static CDLL_MAX_VERANSTALTUNG = 8999 /* Letzter Spezialfall für Veranstaltungskalender */
  public static CDLL_MIN_DIV = 9000 /* Erster Spezialfall diverse unter Landleistungen einger. Leist.(z.B. Tickets): ohne Symbol im Internet */
  public static CDLL_MIN_GUTSCHEIN = 9100 /* Erster Spezialfall für Gutscheinverkäufe */
  public static CDLL_MAX_GUTSCHEIN = 9199 /* Letzter Spezialfall für Gutscheinverkäufe */
  public static CDLL_MAX_DIV = 9999 /* Letzter Spezialfall für diverse Leistungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_KAT = 2106 /* Kategorie */
  public static CDKAT_CRS_IMPORT = 0    /* Bei CRS-Importen wird kein passender Code vorausgesetzt, da der Text direkt übernommen wird. */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_ZUS = 2107 /* Zusatzleistungen (jeder 1000er wird zu einer Gruppe gem. Codeart 2036 zusammengefasst, fak. CDSORT für Untergruppen gem. Codeart 2936) */
  public static CDZUS_MIN_TAX = 1000 /* Ab diesem Wert handelt es sich um Transporttaxen (für IATA-Ticket) */
  public static CDZUS_MAX_TAX = 1999 /* Letzter Tax-Spezialfall */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_GSC = 2110 /* Gutscheincode */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_TRM = 2111 /* Transportmittel */
  public static CDTRM_MIN_BUS = 1000 /* Erster für Busse reservierter Code */
  public static CDTRM_MIN_FREMDFAHRZEUG = 1900 /* Erster für Fremdfahrzeug reservierter Code */
  public static CDTRM_MAX_FREMDFAHRZEUG = 1999 /* Letzter für Fremdfahrzeug reservierter Code */
  public static CDTRM_MAX_BUS = 1999 /* Letzter für Busse reservierter Code */
  public static CDTRM_MIN_ZUBEHOER = 2000 /* Erster für unter Dispositionszubehör verwaltetes Zubehör reservierter Code */
  public static CDTRM_MIN_ANHAENGER = 2000 /* Erster für Anhänger reservierter Code */
  public static CDTRM_MAX_ANHAENGER = 2099 /* Letzter für Anhänger reservierter Code */
  public static CDTRM_MIN_KISTE = 2100 /* Erster für Kisten reservierter Code */
  public static CDTRM_MAX_KISTE = 2199 /* Erster für Anhänger reservierter Code */
  public static CDTRM_MAX_ZUBEHOER = 2999 /* Letzter für unter Dispositionszubehör verwaltetes Zubehör reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYPCODERESERVE = 2119 /* von PRTYPCODE bis PRTYPCODERESERVE sind die Codes für die Typencodes reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_RPLCODE1 = 2120 /* Untertypen von reservierbaren Einheiten gem. RPLCODE1+PRCODE */
  public static CDRPL_MIN_RES = 65   /* A=Kennzeichen der ersten reservierbaren Einheitsart */
  public static CDRPL_MAX_RES = 90   /* Z=Kennzeichen der letzten reservierbaren Einheitsart */

  /*--------------------------------------------------------------------------*/

  public static TAB_RPLCODE1_TRM = 2131 /* Sitzplatzarten in einem Transportmittel */
  public static CDRPLTRM_NICHTBEHINDERT = 3    /* zum Normalwert (65-67) addieren, wenn nur Nichtbehinderte auf diesen Platz sitzen dürfen */
  public static CDRPLTRM_NICHTRAUCHER = 65   /* A=Nichtraucher */
  public static CDRPLTRM_MOEGL_RAUCHER = 66   /* B=möglicherweise Raucherplatz */
  public static CDRPLTRM_RAUCHER = 67   /* C=Raucherplatz */
  public static CDRPLTRM_MIN_SPEZSITZ = 71   /* Erste Sitzart, die in der separaten Auswahlbox erscheint. Diese sind nicht kombinierbar mit Raucher usw. */
  public static CDRPLTRM_REIHE1 = 71   /* G=1. Reihe (Symbol in Anzeige '1') */
  public static CDRPLTRM_REIHE2 = 72   /* H=2. Reihe (Symbol '2') */
  public static CDRPLTRM_REIHE3 = 73   /* I=3. Reihe (Symbol '3') */
  public static CDRPLTRM_REIHE4 = 74   /* J=4. Reihe (Symbol '4') */
  public static CDRPLTRM_REIHE5 = 75   /* K=5. Reihe (Symbol '5') */
  public static CDRPLTRM_FAUTEUIL = 76   /* L=Fauteuil (Symbol 'F') */
  public static CDRPLTRM_COUCHETTE = 77   /* M=Couchette (Symbol 'C') */
  public static CDRPLTRM_RUECKWAERTS = 78   /* 'N'=Rückwärtssitz (als Symbol Sitz umgekehrt dargestellt) */
  public static CDRPLTRM_NICHT_NACHBARSSITZ = 85   /* 'U'=Sitz kann nicht als Nachbarssitz verwendet werden (v.A. aus technischen Gründen) */
  public static CDRPLTRM_MAX_SPEZSITZ = 90   /* Letzte Sitzart, die in einer separaten Auswahlbox erscheint */
  public static CDRPLTRM_GEMISCHT = 99   /* nur fuer Ausdruck auf Rechnung (gemischt) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RPLCODE1RESERVE = 2139 /* Von RPLCODE1 bis hier sind die Codes für den Reservationsplan reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_RPLCODE2 = 2140 /* Untertypen von Leerraumarten gem. RPLCODE2+PRCODE (Codes müssen ausserhalb RPLCODE1 liegen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RPLCODE2_TRM = 2151 /* Leerraumarten für Transmittel */
  public static CDRPLTRM_EXIT = 101  /* e=Exit */
  public static CDRPLTRM_TRAGFLAECHE = 102  /* f=Tragfläche */
  public static CDRPLTRM_GANG = 103  /* g=Gang */
  public static CDRPLTRM_TOILETTE = 116  /* t=Toilette */

  /*--------------------------------------------------------------------------*/

  public static TAB_RPLCODE2RESERVE = 2159 /* Von RPLCODE2 bis hier sind die Codes für Leerraumarten reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYPCODE_EXTERN = 2160 /* Unterarten eines Produktecodes gem. PRTYPCODE_EXTERN+produkt.prcode */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYPCODE_EXTERN_RESERVE = 2179 /* Von PRTYPCODE_EXTERN bis PRTYPCODE_EXTERN_RESERVE sind die Codes für die externen Produktunterarten reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRZUSKRITBASIS = 2201 /* Erstes frei definierbare Zusatzkriterium für Produkte mit PRCODE 0 (Defines für einzelne Codebereiche s.a. Codeart 3101) */
  public static CDPRZKR_FAKTOR = 20   /* Das für das jeweilige Produkt erstmögliche Zusatzkriterium errechnet sich wie folgt: TAB_PRZUSKRITBASIS+CDPRZKR_FAKTOR*produkt.prcode+0..19 */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRZUSKRITENDE = 2219 /* letztmögliche Nummer für das Zusatzkriterium des Produkts mit PRCODE 0 */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRZUSKRITRESERVE = 2399 /* Von PRZUSKRITBASIS bis PRZUSKRITRESERVE sind alle Codes für die Produkte-Zusatzkriterien reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_ERW_ZUSKRITLISTE = 2400 /* Liste der erweiterten Kriterien */
  public static CDERWK_STR_VON = 1    /* Erster möglicher Codeeintrag für die Liste der erweiterten Kriterien für Standardreisen */
  public static CDERWK_STR_BIS = 99   /* Letzter möglicher Codeeintrag für die Liste der erweiterten Kriterien der Standardreise */

  /*--------------------------------------------------------------------------*/

  public static TAB_ERW_ZUSKRIT_VON = 2400 /* Erste für erweiterte Zusatzkriterien reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB_ERW_ZUSKRIT_BIS = 2599 /* Letzte für erweiterte Zusatzkriterien reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRIBEZCODE = 2600 /* Untertypen für Produktinformationsbezeichnungen gem. CDPRIBEZCODE + PRICODE */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRIBEZ_STR = 2610 /* Produktinformationsbezeichnungen für Standardreisenbilder */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRIBEZ_TRK = 2630 /* Produktinformationsbezeichnungen für Transportkettenbilder */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRIBEZ_LL = 2650 /* Produktinformationsbezeichnungen für Landleistungsbilder */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRIBEZ_ETAGE = 2651 /* Produktinformationsbezeichnungen von Etagen-/Deckplänen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRIBEZ_KAT = 2660 /* Produktinformationsbezeichnungen für Kategorienbilder */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRIBEZ_ZUS = 2670 /* Produktinformationsbezeichnungen für Zusatzleistungsbilder */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRIBEZ_BIS = 2699 /* Für Produktionformationsbezeichnungen sind die Codearten bis hierhin reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB____PRODUKT2___ = 2800 /* === Weitere Produkt-Codearten (Fortsetzung der Codes 2000-2099), z.Z. ab 2900, Codearten ab 2800 sind als Reserve vorgesehen === */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRGGEPAECKCODE = 2900 /* Bestimmungen für das aufgegebene Gepäck */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRGHANDGEPAECKCODE = 2901 /* Bestimmungen für das Handgepäck */

  /*--------------------------------------------------------------------------*/

  public static TAB_TRGINFGEPAECKCODE = 2902 /* Bestimmungen für das aufgegebenen Gepäck von Infants */

  /*--------------------------------------------------------------------------*/

  public static TAB_GSRTEILCODE = 2903 /* Bestimmt, ob Gutscheinregel-Teile vorhanden sind und wie diese verwendet werden */
  public static CDGSRT_OHNE = 0    /* NULL=keine Gutscheinregel-Teile vorhanden */
  public static CDGSRT_EIN = 1    /* Es muss mindestens eine Leistung gebucht werden, die den Regeln eines Teils entspricht */
  public static CDGSRT_PRO_EBENE = 3    /* Für jede Regelteil-Ebene muss eine passende Leistung vorhanden sein (eine Leistung kann mehrere Bedingungen erfüllen) */
  public static CDGSRT_PRO_FELD = 5    /* Für jedes Regelteil-Feld muss eine passende Leistung vorhanden sein (eine Leistung kann mehrere Bedingungen erfüllen) */
  public static CDGSRT_ALLE = 8    /* Für jeden Regelteil muss eine passende Leistung vorhanden sein (eine Leistung kann mehrere Bedingungen erfüllen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRAUTOMATIONSCODE = 2904 /* Art, wie Automationen für dieses Produkt verwendet werden */
  public static CDPRAUT_DEFAULT = 0    /* in normalen Abläufen gem. Grundeinstellungen des Mandanten erlaubt oder nicht, Sofortübermittlungen müssen nie geprüft werden */
  public static CDPRAUT_NICHT_ERLAUBT = 1    /* Verwendung nicht erlaubt */
  public static CDPRAUT_NORMAL = 2    /* nur in normalen Abläufen erlaubt, Sofortübermittlungen müssen nie geprüft werden */
  public static CDPRAUT_MIN_SOFORT = 10   /* Erster Wert, ab dem bei Buchungen bzw. Buchungsänderungen überprüft werden muss, ob Sofortübermittlungen ausgelöst werden müssen */
  public static CDPRAUT_SOFORT = 10   /* Normale Abläufe und Sofortübermittlung möglich */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRPRIO_GSC = 2905 /* Priorität eines Produkt vom Typ Gutscheincode */
  public static CDPRPRIO_BELIEBIG = 0    /* beliebig kombinierbar, auch mit Codes 3-7 */
  public static CDPRPRIO_NICHT_PRIO1 = 3    /* nicht kombinierbar, höchste Priorität */
  public static CDPRPRIO_NICHT_PRIO2 = 4    /* nicht kombinierbar, 2. Priorität */
  public static CDPRPRIO_NICHT_PRIO3 = 5    /* nicht kombinierbar, 3. Priorität */
  public static CDPRPRIO_NICHT_PRIO4 = 6    /* nicht kombinierbar, 4. Priorität */
  public static CDPRPRIO_NICHT_PRIO5 = 7    /* nicht kombinierbar, 5. Priorität */
  public static CDPRPRIO_NUR_MIT_BELIEBIG = 9    /* beliebig kombinierbar mit den anderen beliebig kombinierbaren Codes 0 oder 9, aber tiefere Priorität als die nicht kombinierbaren Codes 3-7 */

  /*--------------------------------------------------------------------------*/

  public static TAB_NUMMERNARTCODE = 2906 /* Nummernart */
  public static CDNRA_KEINE = 0    /* NULL=keine Nummernart mit diesem Produkt verbunden */
  public static CDNRA_GSC = 10   /* Gutscheincode (Gratisabgabe, verbunden mit Gutscheinprodukt) */
  public static CDNRA_REISEGS = 20   /* Reisegutschein (Gutscheinverkauf, verbunden mit Gutscheinprodukt) */
  public static CDNRA_TICKET = 30   /* Ticket (Buchung, verbunden über Kategorie) */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRABUCHUNGSABGABECODE = 2907 /* Zeitpunkt, wann Nummern bei einer Buchung abgegeben werden (Codes 10-69 haben eine Übereinstimmung beim DSGUTSCHEINABGABESTATUS) */
  public static CDNRABUA_KEINE = 0    /* Keine Abgabe bei einer Buchung vorgesehen */
  public static CDNRABUA_MIN_RECHNUNG_INTERNET = 11   /* Erster für Gutscheine, die falls möglich direkt mit der Rechnung im Internet abgegeben werden können, reservierter Code */
  public static CDNRABUA_SOFORT = 11   /* Direkt bei der Buchung */
  public static CDNRABUA_DEFINITIV = 13   /* Bei der definitiven Buchung (Weiter-Knopf) */
  public static CDNRABUA_RECHNUNG = 15   /* Bei der Rechnungsaufbereitung */
  public static CDNRABUA_RECHNUNG_INTERN = 16   /* Bei der Aufbereitung der internen Rechnung */
  public static CDNRABUA_MAX_RECHNUNG_INTERNET = 19   /* Letzter für Gutscheine, die falls möglich direkt mit der Rechnung im Internet abgegeben werden können, reservierter Code */
  public static CDNRABUA_ZAHLUNG = 21   /* Bei Zahlungseingang */
  public static CDNRABUA_REISEPROG = 30   /* Bei der Reiseprogrammaufbereitung */
  public static CDNRABUA_AUTOM_VOR_ABREISE = 36   /* Automatisch, kurz vor Abreise, sofern bezahlt */
  public static CDNRABUA_UMFRAGEVERSAND = 40   /* Beim Versand der Umfrage */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRASPEZIALABGABECODE = 2908 /* Spezielle Arten, wie Nummern unabhängig von einer Buchung abgegeben werden können, Werte als Bitmap kombinierbar */
  public static CDNRASPA_KEINE = 0    /* Keine Abgabe ausserhalb einer Buchung vorgesehen */
  public static CDNRASPA_FREIER_DRUCK = 1    /* freier Ausdruck über die integrierte Textverarbeitung */
  public static CDNRASPA_ADR_EXP = 2    /* Adressexport */
  public static CDNRASPA_EXP_DRITTSYSTEM = 4    /* Export blockweise an Drittsystem */
  public static CDNRASPA_NEWSLETTER = 8    /* Automatisch bei einer Newsletter-Bestellung (Einlösen nur durch gleiche Kundennr. erlaubt) */
  public static CDNRASPA_GUTSCHEIN = 16   /* Auch bei Gutscheinverkauf */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRAAKTIVIERUNGSCODE = 2909 /* Art, wie abgegebene Nummern vor dem Einlösen aktiviert werden müssen, als Bitmap kombinierbar */
  public static CDNRAAKT_KEINE = 0    /* keine separate Aktivierung notwendig, eine Nummer kann sofort nach Abgabe eingelöst werden */
  public static CDNRAAKT_SACHBEARB = 1    /* durch Sachbearbeiter mit entsprechender Zuständigkeit */
  public static CDNRAAKT_ZAHLUNG = 4    /* automatisch nach Zahlungseingang */
  public static CDNRAAKT_DRITTSYSTEM_SACHBEARB = 32   /* Abgaben von Drittsystemen müssen durch einen Sachbearbeiter mit entsprechender Zuständigkeit freigegeben werden */
  public static CDNRAAKT_WEBSERVICE = 64   /* Bestätigung durch Drittsystem über Webservice */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRAEINLOESECODE = 2910 /* Art, wie Nummern eingelöst werden */
  public static CDNRAEINL_NORMAL = 0    /* keine speziellen Bedingungen ausser evt. Gutscheinregeln */
  public static CDNRAEINL_RW_VERFALL = 1    /* Restwertverfall */
  public static CDNRAEINL_ZLG_PRUEFEN = 2    /* Beim Einlösen des Gutscheins muss geprüft werden, ob der Gutschein vollständig bezahlt ist */
  public static CDNRAEINL_API_KONTROLLE = 80   /* Kontrolle über externes API */
  public static CDNRAEINL_EXTERN = 90   /* Einlösung ausserhalb Tourdata (keine Rückmeldung) */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRENEUCODE = 2911 /* Art wie neue Nummern vergeben werden */
  public static CDNRENEU_MIN_IM_VORAUS = 10   /* Erster für Nummern, die blockweise im Voraus vergeben werden, reservierter Code */
  public static CDNRENEU_NR_TAB = 10   /* Nummerntabelle ab Importdatei einlesen */
  public static CDNRENEU_ZUFALL_VORAUS = 20   /* Zufallsgenerator blockweise im voraus */
  public static CDNRENEU_LAUFNR_VORAUS = 30   /* Fortlaufende Nummer blockweise */
  public static CDNRENEU_MAX_IM_VORAUS = 49   /* Letzter für Nummern, die blockweise im Voraus vergeben werden, reservierter Code */
  public static CDNRENEU_MIN_BEI_ABGABE = 50   /* Erster für Nummern, die direkt bei Abgabe generiert werden, resevierter Code */
  public static CDNRENEU_HAND = 50   /* Handeingabe bei der Abgabe */
  public static CDNRENEU_DOSSIERNR = 60   /* Dossiernr. bei der Abgabe */
  public static CDNRENEU_ZUFALL_ABGABE = 70   /* Zufallsgenerator bei der Abgabe, so viele wie gerade nötig */
  public static CDNRENEU_PLATZHALTER_ABGABE = 71   /* Platzhalter gem. NREFORMAT, allenfalls kombiniert mit Zufallsgenerator, bei der Abgabe */
  public static CDNRENEU_LAUFNR_ABGABE = 80   /* Fortlaufende Nummer bei der Abgabe */
  public static CDNRENEU_MAX_BEI_ABGABE = 89   /* Letzter für Nummern, die direkt bei Abgabe generiert werden, resevierter Code */
  public static CDNRENEU_MIN_BEI_EINLOESUNG = 100  /* Erster für Nummern, die erst bei der Einlösung bezogen werden, reservierter Code */
  public static CDNRENEU_API_EINLOESUNG = 100  /* Nummer wird, sofern noch nicht bekannt, bei der Einlösung über ein externes API bezogen */
  public static CDNRENEU_GSC_EINLOESUNG = 110  /* Gemäss Gutscheincode bei Einlösung */
  public static CDNRENEU_MAX_BEI_EINLOESUNG = 149  /* Letzte für Nummern, die erst bei der Einlösung bezogen werden, reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRESTATUS = 2912 /* Zustand einer Nummerneinheit */
  public static CDNREST_AKTIV = 0    /* aktiv (nur bei der letzten NRELAUFNR innerhalb einer Nummernart erlaubt) */
  public static CDNREST_ABGEBEN_EINLOESEN = 2    /* nur noch bestehende abgeben und einlösen */
  public static CDNREST_EINLOESEN = 4    /* nur noch bestehende einlösen */
  public static CDNREST_GESPERRT = 5    /* weder abgeben noch einlösen möglich */

  /*--------------------------------------------------------------------------*/

  public static TAB_NUSTATUS = 2913 /* Zustand der Nummer */
  public static CDNUSTAT_FREI = 1    /* neu oder wieder frei verwendbar */
  public static CDNUSTAT_WERT_UNKLAR = 3    /* Der Wert des Gutscheins, ist 0, wurde noch nicht bestimmt oder wurde nachträglich durch z.B. einen Rechnungsstorno wieder aufgehoben */
  public static CDNUSTAT_BESTAETIGEN = 5    /* bereit für die Abgabe, aber noch nicht  bestätigt */
  public static CDNUSTAT_ABGEGEBEN = 10   /* abgegeben und verfügbar fürs Einlösen */
  public static CDNUSTAT_MIN_EINGELOEST = 11   /* Erster Code für ganz oder teilweise eingelöste Gutscheine */
  public static CDNUSTAT_TEILW_EINGELOEST = 11   /* teilweise eingelöst */
  public static CDNUSTAT_MIN_ABGESCHLOSSEN = 20   /* Erster für abgeschlossene Fälle vorgesehener Code */
  public static CDNUSTAT_KEINE_EINLOESEPRUEFUNG = 20   /* abgegeben, Einlösung wird im Tourdata nicht kontrolliert */
  public static CDNUSTAT_EINGELOEST = 21   /* vollständig eingelöst */
  public static CDNUSTAT_MAX_EINGELOEST = 27   /* Letzter Code für ganz oder teilweise eingelöste Gutscheine */
  public static CDNUSTAT_VERFALLEN = 29   /* verfallen */
  public static CDNUSTAT_MIN_GESPERRT = 50   /* Erster für gesperrte Nummern reservierter Bereich */
  public static CDNUSTAT_ABGABESPERRE_NR = 50   /* Abgabesperre einzelne Nummer */
  public static CDNUSTAT_ABGABESPERRE_BLOCK = 51   /* Abgabesperre Block */
  public static CDNUSTAT_ABGABESPERRE_EINHEIT = 52   /* Abgabesperre Nummerneinheit */
  public static CDNUSTAT_SPERRE_NR = 60   /* Einlösesperre einzelne Nummer */
  public static CDNUSTAT_SPERRE_BLOCK = 61   /* Einlösesperre Block */
  public static CDNUSTAT_SPERRE_EINHEIT = 62   /* Einlösesperre Nummerneinheit */
  public static CDNUSTAT_MAX_GESPERRT = 69   /* Letzter für gesperrte Nummern reservierter Bereich */
  public static CDNUSTAT_MIN_STORNIERT = 70   /* Erster für stornierte Nummern reservierter Bereich */
  public static CDNUSTAT_NICHT_FREIGEGEBEN = 70   /* Freigabe verweigert */
  public static CDNUSTAT_STORNIERT = 71   /* storniert */
  public static CDNUSTAT_ERSETZT = 75   /* ersetzt */
  public static CDNUSTAT_ZURUECK = 76   /* zurückgegeben */
  public static CDNUSTAT_GELOESCHT = 91   /* Freie Nummer gelöscht (wurde nie ausgegeben) */
  public static CDNUSTAT_MAX_STORNIERT = 99   /* Letzter für stornierte Nummern resevierter Bereich */
  public static CDNUSTAT_MAX_ABGESCHLOSSEN = 99   /* Letzter für abgeschlossene Fälle vorgesehener Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRVCODE = 2914 /* Art der Nummernverwendung */
  public static CDNRV_MIN_ABGABE = 1    /* Erster für die Abgabe reservierter Bereich */
  public static CDNRV_ABGABE = 1    /* Abgabe */
  public static CDNRV_MAX_ABGABE = 20   /* Letzter für die Abgabe reservierter Bereich */
  public static CDNRV_MIN_EINLOESEN = 21   /* Erster fürs Einlösen reservierter Bereich */
  public static CDNRV_EINLOESEN = 21   /* Einlösen */
  public static CDNRV_MAX_EINLOESEN = 40   /* Letzter fürs Einlösen reservierter Bereich */
  public static CDNRV_MIN_SONSTIGES = 61   /* Erster für buchhaltungsneutrale Änderungen reservierter Bereich */
  public static CDNRV_NEUER_KUNDE = 61   /* Kundennr., die der Abgabe nachträglich manuell neu zugeteilt wurde (wichtig bei persönlichen Gutscheinen zum Einlösen) */
  public static CDNRV_MAX_SONSTIGES = 99   /* Letzter für buchhaltungsneutrale Änderungen reservierter Bereich */
  public static CDNRV_MIN_QUERVERWEIS = 200  /* Erster für rein informative Querverweise reservierter Bereich */
  public static CDNRV_REFERENZKUNDE = 201  /* Referenzkunde */
  public static CDNRV_MAX_QUERVERWEIS = 299  /* Letzter für rein informative Querverweise reservierter Bereich */

  /*--------------------------------------------------------------------------*/

  public static TAB_RHSTATUS = 2915 /* Status des Routenhalts */
  public static CDRHS_AKTIV = 0    /* Routenhalt ist aktiv und kann generell verwendet werden */
  public static CDRHS_INTERN = 2    /* Routenhalt kann nur intern ausgewählt werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_NREZEICHENCODE = 2916 /* Gültige Zeichen für zufällig generierte Nummern (sprachabhängig für die Bezeichnung der Auswahl, sprachunabhänig für die effektiven Zeichen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_NREGUELTIG_ABCODE = 2917 /* Zeitpunkt, ab dem der Gutscheincode gültig ist */
  public static CDNREGAB_ABGABE = 0    /* Zeitpunkt der Abgabe oder für Tourdata nicht relevant (Default) */
  public static CDNREGAB_RUECKREISE = 110  /* Rückreisedatum */

  /*--------------------------------------------------------------------------*/

  public static TAB_NREVERFALLCODE = 2918 /* Einheit der Verfalldauer */
  public static CDNREVERF_KEIN = 0    /* kein Verfall */
  public static CDNREVERF_TAGE = 3    /* Tage */
  public static CDNREVERF_MONATE = 5    /* Monate */
  public static CDNREVERF_JAHRE = 9    /* Jahre */
  public static CDNREVERF_MIN_0_ERLAUBT = 10   /* Erster Verfallcode, bei dem die Dauer 0 erlaubt ist */
  public static CDNREVERF_MONATSENDE = 55   /* Monate, Ende des berechneten Monats einsetzen */
  public static CDNREVERF_JAHRESENDE = 99   /* Jahre, Ende des berechneten Jahres einsetzen */

  /*--------------------------------------------------------------------------*/

  public static TAB_GSRREISEBUEROCODE = 2919 /* Gibt an, ob und wie eine Gutscheinregel auch für Reisebüros gilt */
  public static CDGSRRB_NEIN = 0    /* (NULL)=Gutscheinregel gilt nur für Direktkunden */
  public static CDGSRRB_BRUTTO = 1    /* Auch für Reisebüros gültig, die Berechnung erfolgt auf dem Bruttobetrag (sofern es sich um einen Wertgutschein handelt) */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRAWERTCODE = 2920 /* Zeitpunkt, an dem der Wert des Gutscheins bestimmt wird */
  public static CDNRAW_KEIN = 0    /* NULL=kein Geldwert (Default) */
  public static CDNRAW_ABGABE = 1    /* bei Abgabe des Gutscheins */
  public static CDNRAW_EINLOESEN = 10   /* Beim Einlösen des Gutscheins */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRAEINHEITSCODE = 2921 /* Einheit für die die Nummer erstellt wird */
  public static CDNRAEINH_DEFAULT = 0    /* Default */
  public static CDNRAEINH_DOSSIER = 1    /* Dossier (bei Gutscheinprodukten erster Dossierpartner vom Typ Kunde) */
  public static CDNRAEINH_DSPA = 2    /* Dossierpartner */

  /*--------------------------------------------------------------------------*/

  public static TAB_GS_TEXT = 2922 /* Gutscheintext wie er z.Z. nur im Internet verwendet wird (CDKURZBEZ entspricht der TXBID) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRKDAUER2_STAFFEL = 2923 /* Preisbasis für Staffelpreis */
  public static CDPRKST_RECHNUNG = 5    /* Rechnungspreis ohne die Leistung selbst und ohne andere Staffelpreise */
  public static CDPRKST_REISEPREIS = 6    /* Rechnung ohne die aktuelle Leistung, ohne andere Staffelpreise und ohne Zusatzleistungen der Gruppe "R" (Nicht Bestandteil des Reisepreises) */
  public static CDPRKST_ARRANGEMENT = 8    /* Nur Preiszeilen vom Typ Arrangement (PZLCODE 2) */
  public static CDPRKST_UEBERGEORDNET = 20   /* Nur direkt übergeordnete Leistung */

  /*--------------------------------------------------------------------------*/

  public static TAB_LDATVERKAUFSSYSTEMCODE = 2924 /* Leistungsdatum-Aufschaltmöglichkeiten (für Veranstalter verfügbare Kombinationsmöglichkeiten aus Codeart 2031) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RSPTYPCODE = 2925 /* Art des Reiseprogramms */
  public static CDRSPTYP_HAUPTLEISTUNG = 0    /* Hauptleistungen bündeln (Default) */
  public static CDRSPTYP_RUNDREISE = 8    /* Rundreisehotels */

  /*--------------------------------------------------------------------------*/

  public static TAB_ABPREISCODE = 2926 /* Art, wie der Preis beim Ab-Preis berücksichtigt werden soll */
  public static CDABPR_STANDARD = 0    /* NULL=normal berücksichtigen */
  public static CDABPR_STREICHPREIS = 1    /* Streichpreis (normal berücksichtigen; zusätzlich separater Ab-Preis vor Berücksichtigung dieses Preises) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZLUNTERGRUPPE = 2936 /* Untergruppen für ausgewählte Zusatzleistungsgruppen (Codeart 2036), werden bei der Zusatzleistung (Codeart 2107) im Feld CDSORT gespeichert */

  /*--------------------------------------------------------------------------*/

  public static TAB_STBID = 2937 /* Beschreibung der Stornobedingungseinträge (abhängig vom PRMANDANT_STR bzw. PRMANDANT) */

  /*--------------------------------------------------------------------------*/

  public static TAB_STBTYPCODE = 2938 /* Unterart der Stornobedingungen */
  public static CDSTBTYP_GESAMT = 0    /* Gesamtstorno (gilt auch für Teilstorno und Umbuchungen, sofern für diese keine individuellen Einstellungen bestehen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_LEISTUNGSARTCODE = 2939 /* Art der Leistung */
  public static CDLAC_UNDEFINIERT = 0    /* Nicht definiert, übrige (NULL) */
  public static CDLAC_PAUSCHAL = 1    /* Pauschalreise */
  public static CDLAC_EINZEL = 2    /* Einzelleistung */
  public static CDLAC_VERMITTLER = 3    /* Vermittler */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRTYP_KONTINGENT = 2940 /* Kontingensproduktarten */
  public static CDKOP_SEPARAT = 0    /* separates Kontingent */
  public static CDKOP_MAX_STANDARD = 9    /* letzter für standardmässig freigeschaltete Kontingentsarten reservierter Code */
  public static CDKOP_BELEGUNG = 20   /* Von der Belegung abhängiges Kontingent */
  public static CDKOP_UNTERKONT_VON = 50   /* Erster für Unterkontingente reservierter Code */
  public static CDKOP_UNTERKONT = 50   /* Unterkontingent */
  public static CDKOP_UNTERKONT_VERK = 55   /* Verkaufsunterkontingent */
  public static CDKOP_UNTERKONT_BIS = 59   /* Letzter für Unterkontingente reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVZUSKONTCODE = 2941 /* Erweiterte Kontingentsarten, die neben der aktuellen Produktverbindung berücksichtigt werden müssen/können (Codes als Bitmap kombinierbar) */
  public static CDPRVZK_KEINE = 0    /* Keine zusätzlichen Kontingente vorhanden oder für diese Produktverbindung nicht relevant */
  public static CDPRVZK_ANDERE = 2    /* Es sind Kontingente mit anderer Gruppe vorhanden, welche jedoch bei einer Buchung nicht berücksichtigt werden müssen */
  public static CDPRVZK_MIN_BUCHUNG = 8    /* Kleinster Code, der bei der Buchung berücksichtigt werden muss (tiefere Werte haben nur informativen Charakter) */
  public static CDPRVZK_VERK = 8    /* separates (Unter-)Kontingent für Vertriebspartner */
  public static CDPRVZK_ZUS = 64   /* zusätzlich zu berücksichtigendes Kontingent */
  public static CDPRVZK_UNBEK = 4096 /* Verweis auf Produkt aus anderer Basisleistung, ob diese relevant sind oder nicht, ist erst nach dem Lesen klar */

  /*--------------------------------------------------------------------------*/

  public static TAB_NAECHSTE_TICKETNR = 2942 /* Wird inzwischen als Code verwendet, welcher nur noch angibt, ob Tickets werden können, bzw., ob gar kein Reiseprogramm gedruckt werden darf */
  public static CDTID_KEIN_REISEPROG = -1   /* Kein Ticketdruck, ausserdem darf für das ganze Dossier kein Reiseprogramm aufbereitet werden */
  public static CDTID_NEIN = 0    /* Kein Ticketdruck */
  public static CDTID_NEIN_BIS = 0    /* Letzter für "kein Ticketdruck" reservierter Code */
  public static CDTID_JA_VON = 1    /* Erster für Ticketdruck freigegebener Code */
  public static CDTID_NORMAL = 1    /* Ticketdruck normal möglich */

  /*--------------------------------------------------------------------------*/

  public static TAB_GSRMAX_PERIODENCODE = 2943 /* Periode, innerhalb der der Gutschein nur die angegebene Anzahl Mal eingelöst werden darf */
  public static CDGSRMP_KEINE = 0    /* NULL=ohne zeitliche Einschränkungen */
  public static CDGSRMP_ABREISE_VON = 1    /* Beginn der für Abreise spezifische Perioden reservierten Codes */
  public static CDGSRMP_ABREISEJAHR = 1    /* pro Abreisejahr */
  public static CDGSRMP_ABREISE_BIS = 19   /* Ende der für Abreise spezifischen Perioden reservierten Codes */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVBI_CODE = 2944 /* Art der Verwendung des Produkt-Von-Bis-Info-Eintrags */
  public static CDPRVBI_DEFAULT = 0    /* Default (Vorschlag für vermerkte Produktinfos bzw. alle möglichen Halteorte bei den anderen Leistungen). Max. 1 Eintrag pro Produkt erlaubt. */
  public static CDPRVBI_ALTERNATIV = 1    /* Alternativvorschlag */

  /*--------------------------------------------------------------------------*/

  public static TAB_AGVPROCODE = 2945 /* Ebene, auf der ein Agendaeintrag erstellt wird */
  public static CDAGVPRO_PRODUKT = 0    /* NULL=Produkt als Ganzes (Default) */
  public static CDAGVPRO_DOSSIER = 100  /* Dossier */

  /*--------------------------------------------------------------------------*/

  public static TAB_AGVTYPCODE = 2946 /* Art der Verarbeitung, die automatisch ausgeführt wird */
  public static CDAGVTYP_MANUELL = 0    /* NULL=Keine automatische Verarbeitung */
  public static CDAGVTYP_PRO_DOSSIER_VON = 50   /* Arten in diesem Bereich sind zwingend dossierspezifisch */
  public static CDAGVTYP_DOK_NICHT_VERSENDET = 92   /* Dossiers, die X Tage vor Abreise entweder keinen Versand-SB oder kein -Datum haben, in Agenda anzeigen */
  public static CDAGVTYP_PRO_DOSSIER_BIS = 99   /* Bereich gem. VON */
  public static CDAGVTYP_PRO_EMAIL_TEMPLATES_VON = 1000 /* Reservierter Bereich für mögliche E-Mail Templates analog Codeart 5038 */
  public static CDAGVTYP_PRO_EMAIL_TEMPLATES_BIS = 5999 /* Bereich gem. VON */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVAUSGABECODE = 2947 /* Stellen, an denen diese Leistung ausgewiesen werden soll */
  public static CDPRVAUSG_IMMER = -1   /* immer */
  public static CDPRVAUSG_DEFAULT = 0    /* Default */

  /*--------------------------------------------------------------------------*/

  public static TAB_IFVERWENDUNGSCODE = 2948 /* Verwendungszweck als Bitmap */
  public static CDIFVERW_MIN_DOK = 1    /* Erster für Dokus reservierter Code */
  public static CDIFVERW_RECHNUNG = 1    /* Verwendung in der Rechnung */
  public static CDIFVERW_OFFERTE = 2    /* Verwendung in der Offerte */
  public static CDIFVERW_REISEPROG = 16   /* Verwendung im Reiseprogramm */
  public static CDIFVERW_VOUCHER = 32   /* Verwendung im Voucher */
  public static CDIFVERW_REQUEST = 64   /* Verwendung im Request */
  public static CDIFVERW_MAX_DOK = 256  /* Letzter für Dokus reservierter Code */
  public static CDIFVERW_BITMAP_DOK = 511  /* Bitmap aller Dokumente */
  public static CDIFVERW_APP = 1024 /* Verwendung in der Kunden-App */
  public static CDIFVERW_POPUP_EXTERN = 2048 /* Verwendung in externem Popup */
  public static CDIFVERW_POPUP_INTERN = 4096 /* Verwendung in internem Popup */

  /*--------------------------------------------------------------------------*/

  public static TAB_IFCODE = 2949 /* Art der Information */
  public static CDIF_BESCHR = 1    /* Bestandteil der Beschreibung und/oder des Buchungsablaufs */
  public static CDIF_ANHANG = 2    /* Anhang */

  /*--------------------------------------------------------------------------*/

  public static TAB_IFSTATUS = 2950 /* Zustand der Information */
  public static CDIFSTATUS_AKTIV = 0
  public static CDIFSTATUS_GELOESCHT = 9

  /*--------------------------------------------------------------------------*/

  public static TAB_IFBPRTYPKURZBEZ = 2951 /* Informationsbedingung Produkttyp */

  /*--------------------------------------------------------------------------*/

  public static TAB_IFBDATCODE = 2952 /* Art der Datumsprüfung */
  public static CDIFBDAT_REISEBEGINN = 1    /* Beginndatum von/bis (nur ein Datum obligatorisch) */
  public static CDIFBDAT_LEIST_BEGINN = 5    /* Leistungsbeginn */
  public static CDIFBDAT_LEIST_TAG = 6    /* Leistung mindestens ein 1 Tag/Nacht innerhalb von/bis (beide Daten obligatorisch) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PREPBERECHNUNGSCODE = 2953 /* Art bzw. Zeitpunkt der automatischen Berechnung der Einstandspreise */
  public static CDPREPB_KEINE = 0    /* Keine automatische Berechnung */
  public static CDPREPB_AUTOM_TAG = 11   /* Ab Anzahl Tagen gem. 5011/311 vor Abreise wird der Preis für dieses Produkt täglich neu berechnet */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRKANZCODE = 2954 /* Basis aufgrund der die Gesamtanzahl für die Anzahlfelder PRKVONANZ, PRKBISANZ, PRKANZx bestimmt wird */
  public static CDPRKANZ_KEINE = 0    /* keine (alle erwähnten Anzahl Felder müssen NULL sein) */
  public static CDPRKANZ_LL = 5    /* Landleistung (mit gleichem Leistungs- bzw. Reisebeginn) */
  public static CDPRKANZ_KAT = 6    /* Kategorie (mit gleichem Leistungs- bzw. Reisebeginn) */
  public static CDPRKANZ_ZAEHLER = 50   /* Reisezähler (mit gleichem Datum) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRVDATCODE = 2955 /* Art, wie die Einträge in der Tabelle Produktverbindungsdatum verwendet werden */
  public static CDPRVDAT_KEIN = 0    /* kein */
  public static CDPRVDAT_REISEBEGINN = 1    /* Reisebeginn */
  public static CDPRVDAT_LEISTUNGSBEGINN = 5    /* Leistungsbeginn */
  public static CDPRVDAT_AUFENTHALT = 6    /* Aufenthalt */
  public static CDPRVDAT_MIN_AUSSCHLUSS = 20   /* Untergrenze für Ausschluss */
  public static CDPRVDAT_AUSSCHLUSS_AUFENTHALT = 26   /* Ausschluss Aufenthalt */
  public static CDPRVDAT_MAX_AUSSCHLUSS = 29   /* Obergrenze für Ausschluss */

  /*--------------------------------------------------------------------------*/

  public static TAB_IFAUSWAHLCODE = 2956 /* Gibt an, wie die Information ausgewählt werden kann */
  public static CDIFAUSW_INDIV = 0    /* individuell */
  public static CDIFAUSW_GENERELL = 9    /* generelle Information */

  /*--------------------------------------------------------------------------*/

  public static TAB_IFSUBCODE = 2957 /* Unterart der Information, bestimmt, an welcher Stelle sie aufbereitet wird */
  public static CDIFS_DEFAULT = 0    /* Default */
  public static CDIFS_STR_VON = 100  /* Erster für Standardreisen reservierter Code */
  public static CDIFS_REISEBESCHR = 100  /* Reisebeschreibung */
  public static CDIFS_STR_ALLGEMEIN = 110  /* Reise allgemein */
  public static CDIFS_STR_BIS = 199  /* Letzter für Standardreisen reservierter Code */
  public static CDIFS_LL_KAT_VON = 500  /* Erster für Landleistungen oder Kategorien reservierter Code */
  public static CDIFS_LL_VON = 500  /* Erster für Landleistungen reservierter Code */
  public static CDIFS_LLBESCHR = 500  /* Hotelbeschreibung */
  public static CDIFS_LL_BIS = 599  /* Letzter für Landleistungen reservierter Code */
  public static CDIFS_KAT_VON = 600  /* Erster für Kategorien reservierter Code */
  public static CDIFS_KATBESCHR = 600  /* Kategoriebeschreibung */
  public static CDIFS_KAT_INDIV_BESCHR = 605  /* Kategorie indiv. Beschreibung */
  public static CDIFS_KAT_BIS = 699  /* Letzter für Kategorien reservierter Code */
  public static CDIFS_LL_KAT_BIS = 699  /* Letzter für Landleistungen oder Kategorien reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_STRECKEN_AUFTEILEN = 2958 /* Art wie mehrere Strecken einer Richtung behandelt werden sollen */
  public static CDSTAUFT_ZUSAMMEN = 0    /* zusammenfassen */
  public static CDSTAUFT_DOK_SEP = 1    /* detailliert auf Dokumenten */
  public static CDSTAUFT_UNABH_NUR_EINE = 5    /* unabhängig buchbar */

  /*--------------------------------------------------------------------------*/

  public static TAB_LDATSTATUS_EINKAUF = 2959 /* Leistungsdatum Status Einkauf */
  public static CDLDATEKS_NICHT_DEF = 0    /* Nicht definiert */
  public static CDLDATEKS_PENDENT_VON = 1    /* Status pendent von */
  public static CDLDATEKS_KEINE_VON = 1    /* Bereich für Stati, bei denen noch keine Vorarbeiten getroffen wurden (von) */
  public static CDLDATEKS_KEINE_BIS = 19   /* Bereich für Stati, bei denen noch keine Vorarbeiten getroffen wurden (bis) */
  public static CDLDATEKS_VORBER_VON = 20   /* Bereich für Vorbereitungsarbeiten (von) */
  public static CDLDATEKS_VORBER_BIS = 39   /* Bereich für Vorbereitungsarbeiten (bis) */
  public static CDLDATEKS_RQ_VON = 40   /* Anfragestatusbereich (von) */
  public static CDLDATEKS_RQ_BIS = 59   /* Anfragestatusbereich (bis) */
  public static CDLDATEKS_RESERV_VON = 60   /* Bereich für reservierte Stati (von) */
  public static CDLDATEKS_RESERV_BIS = 79   /* Bereich für reservierte Stati (bis) */
  public static CDLDATEKS_PENDENT_BIS = 99   /* Status pendent bis */
  public static CDLDATEKS_OK_VON = 200  /* Bereich für buchbare Stati (von) */
  public static CDLDATEKS_NORMAL_OK_VON = 220  /* Bereich für normal buchbare Stati (von) */
  public static CDLDATEKS_NORMAL_OK_BIS = 239  /* Bereich für normal buchbare Stati (bis) */
  public static CDLDATEKS_GARANTIERT_VON = 240  /* Bereich für garantierte Durchführungen (von) */
  public static CDLDATEKS_GARANTIERT_BIS = 259  /* Bereich für garantierte Durchführungen (bis) */
  public static CDLDATEKS_OK_BIS = 299  /* Bereich für buchbare Stati (bis) */
  public static CDLDATEKS_NOK_VON = 400  /* Bereich für nicht mehr buchbare Stati (von) */
  public static CDLDATEKS_GESCHLOSSEN_VON = 420  /* Bereich für geschlossene Stati (von) */
  public static CDLDATEKS_GESCHLOSSEN_BIS = 439  /* Bereich für geschlossene Stati (bis) */
  public static CDLDATEKS_NOK_BIS = 499  /* Bereich für nicht mehr buchbare Stati (bis) */
  public static CDLDATEKS_RUECKBAU_VON = 600  /* Bereich für zurückzubauende oder bereits zurückgebaute Stati (von) */
  public static CDLDATEKS_RUECKBAU_IN_ARBEIT_VON = 620  /* Bereich für Rückbau in Arbei (von) */
  public static CDLDATEKS_RUECKBAU_IN_ARBEIT_BIS = 639  /* Bereich für Rückbau in Arbeit (bis) */
  public static CDLDATEKS_RUECKBAU_FERTIG_VON = 680  /* Bereich für erledigte Rückbauarbeiten (von) */
  public static CDLDATEKS_RUECKBAU_FERTIG_BIS = 699  /* Bereich für erledigte Rückbauarbeiten (bis) */
  public static CDLDATEKS_RUECKBAU_BIS = 699  /* Bereich für zurückzubauende oder bereits zurückgebaute Stati (bis) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZUSTCODE_PROD = 2960 /* Produktabhängige Zuständigkeiten nur Codes 4000-4999 erlaubt, s.a. Codeart 1036 für Mandanten-Zuständigkeiten mit Präfix sowie Codeart 2074 */
  public static CDZUST_STR_VON = 4100 /* Erster für Standardreisen reservierter Code */
  public static CDZUST_STR_INDIV_VON = 4180 /* Erster für individuelle Standardreisen-Zuständigkeiten reservierter Code */
  public static CDZUST_STR_INDIV_BIS = 4199 /* Letzter für individuelle Standardreisen-Zuständigkeiten reservierter Code */
  public static CDZUST_STR_BIS = 4199 /* Letzter für Standardreisen reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRABINDUNGSCODE = 2961 /* Art der Gutscheinbindung bei der Einlösung */
  public static CDNRABIND_KEINE = 0    /* NULL=keine Bindung */
  public static CDNRABIND_PAID = 10   /* Kundennr. */

  /*--------------------------------------------------------------------------*/

  public static TAB_UPSELLINGCODE = 2962 /* Art von Upselling */
  public static CDUPS_AUF_MOEGLICH = 1    /* auf diese Leistung möglich */

  /*--------------------------------------------------------------------------*/

  public static TAB_PRREPORTFORMATCODE = 2963 /* Format der Buchungs-/Requestmeldungen (100er-Stelle=Hauptformat, 10er=allfällige Versionsnr., 1er=allfällig mitzuschickendes normal lesbares */
  public static CDPRREPFDEFAULT = 0    /* Default */
  public static CDPRREPFTEXT = 1    /* Textdatei */
  public static CDPRREPFVERSION = 10   /* Die 10er-Stelle gibt eine allfällige Versionsnr. an */
  public static CDPRREPFHAUPTFORMAT = 100  /* Die 1000er- und 100er-Stellen geben das (maschinell verarbeitbare) Hauptformat an */
  public static CDPRREPFCSV = 100  /* Tourdata-CSV */
  public static CDPRREPFCSV_TEXT = 101  /* Tourdata-CSV und Textdatei */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSCODE = 3000 /* Dossiercode */
  public static CDDS_OPTIONEN = 1    /* Das Dossier beinhaltet Optionen */
  public static CDDS_REQUEST = 2    /* Teile des Dossiers sind auf Anfrage (Request) */
  public static CDDS_BUCHUNGEN = 3    /* Das Dossier beinhaltet nur Buchungen */
  public static CDDS_AUFTRAG = 10   /* Es handelt sich um ein Auftragsbearbeitungsdossier */
  public static CDDS_INTERN = 90   /* Technische Dossiers für interne Zwecke */

  /*--------------------------------------------------------------------------*/

  public static TAB____DOSSIER___ = 3000 /* === Beginn der Dossier-Codearten === */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSSTATUS = 3001 /* Zustand des Dossiers */
  public static CDDSST_KOPF_NOK = 1    /* Kopfdaten nicht vollständig */
  public static CDDSST_FREMD_NOK = 2    /* Fremdleistungen nicht vollständig zugewiesen */
  public static CDDSST_TEILN_NOK = 3    /* Es sind noch nicht alle Leistungen den Teilnehmern zugewiesen */
  public static CDDSST_ZUSINFO_NOK = 4    /* Noch nicht alle Zusatzinformationen erfasst */
  public static CDDSST_KEIN_AUFTRAG = 5    /* Noch kein Auftrag erfasst */
  public static CDDSST_OFFERTANFRAGE = 6    /* Offertanfrage neu (nur ab Internet möglich) */
  public static CDDSST_OFFERTE_KONTR = 7    /* Offerte zu kontrollieren */
  public static CDDSST_DATEN_VOLLSTAENDIG = 9    /* Buchungsdaten vollständig, Kontingente noch nicht definitiv abgebucht (Weiter-Knopf noch nicht gedrückt) */
  public static CDDSST_MIN_RG_BEREIT = 10   /* Wert, ab dem Aufträge erfasst werden können */
  public static CDDSST_OFFERTE_NOK = 10   /* Nicht alle Offerten ausgedruckt */
  public static CDDSST_OFFERTE_OK = 11   /* Alle Offerten ausgedruckt */
  public static CDDSST_AUFTRBEST_NOK = 13   /* Nicht alle Auftragsbestätigungen ausgedruckt */
  public static CDDSST_AUFTRBEST_OK = 14   /* Alle Auftragsbestätigungen ausgedruckt */
  public static CDDSST_FAHRAUFTR_NOK = 16   /* Nicht alle Fahraufträge ausgedruckt */
  public static CDDSST_FAHRAUTR_OK = 17   /* Alle Fahraufträge ausgedruckt */
  public static CDDSST_NEBENL_ERF = 20   /* Tournet: Nebenleistung unvollständig */
  public static CDDSST_ADR_ERF = 21   /* Tournet: Adresse noch nicht erfasst (Endkunde bei Direkt- bzw. Buchungsstellenbuchung) */
  public static CDDSST_TEILN_ERF = 22   /* Tournet: Teilnehmernamen nicht erfasst */
  public static CDDSST_HAUPT_ZUWEIS = 23   /* Tournet: Hauptleistungen nicht zugewiesen */
  public static CDDSST_ZIMMERNR = 24   /* Tournet: Zimmernummer nicht erfasst */
  public static CDDSST_NEBENL_ZUWEIS = 25   /* Tournet: Nebenleistungen nicht zugewiesen */
  public static CDDSST_EINST_ORT = 26   /* Tournet: Einstiegsort nicht zugewiesen */
  public static CDDSST_SITZPLATZ = 27   /* Tournet: Sitzplatz nicht bestimmt */
  public static CDDSST_BU_BEST = 29   /* Tournet: Buchungsbestätigung ausstehend */
  public static CDDSST_MIN_OK = 30   /* Mindestwert für Dossiers in gutem Zustand */
  public static CDDSST_RGDRUCK_NOK = 30   /* Es sind noch nicht alle Rechnungen ausgedruckt */
  public static CDDSST_DEB_NOK = 31   /* Die Debitoren sind noch nicht vollständig verbucht */
  public static CDDSST_KONTR_NOK = 32   /* Das Dossier wurde noch nicht kontrolliert */
  public static CDDSST_RQ_ANFRAGE = 35   /* Requestanfrage noch nicht an Leistungsträger verschickt */
  public static CDDSST_BU_REPORT = 36   /* Nicht alle Buchungen an Leistungsträger gemeldet */
  public static CDDSST_KD_BESTAETIGUNG = 38   /* Noch nicht alle Dossier-Änderungen automatisch an Kunde (z.Z. nur Reisebüro via Tournet) gemeldet */
  public static CDDSST_TICKET_VERARBEITUNG = 40   /* Noch nicht alle Tickets verabeitet */
  public static CDDSST_FRAGEB_UNVOLLST = 50   /* Es sind noch nicht alle Fragebogen vollständig erfasst */
  public static CDDSST_FRAGEB_NKONTR = 51   /* Es wurden noch nicht alle Fragebogen überprüft */
  public static CDDSST_GESPERRT = 69   /* Dossier ist grundsätzlich ok, wurde jedoch manuell gesperrt */
  public static CDDSST_OK = 70   /* Ok */
  public static CDDSST_MIN_STORNO = 80   /* Erster Wert für stornierte Dossiers */
  public static CDDSST_STORNO_RGNOK = 80   /* Storniertes Dossier mit nichtgedruckten Rechnungen */
  public static CDDSST_STORNO_DEBNOK = 81   /* Storniertes Dossier mit noch nicht verbuchten Rechnungen */
  public static CDDSST_ANNUL_REPORT = 86   /* Nicht alle Annullationen an Leistungssträger gemeldet */
  public static CDDSST_STORNO_TICKET = 87   /* Noch nicht alle Tickets storniert */
  public static CDDSST_STORNO_GESPERRT = 89   /* Storniertes Dossier wurde manuell gesperrt */
  public static CDDSST_STORNIERT = 90   /* Das Dossier wurde storniert (Rechnungen können noch erstellt werden; Codes 80-89 sind möglich) */
  public static CDDSST_GELOESCHT = 99   /* Dossier wird sobald als möglich von der DB entfernt */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSGCODE = 3002 /* Grund des Eintrags in der Dossiergeschichte (Codes 300-399 s.a. Codeart 1017) */
  public static CDDSG_NEU_OPT = 1    /* Eröffnung mit Optionen */
  public static CDDSG_NEU_REQ = 2    /* Eröffnung mit Requests */
  public static CDDSG_NEU_BUCH = 3    /* Eröffnung mit Buchungen */
  public static CDDSG_NEU_AUFTRAG = 10   /* Eröffnung eines Auftragsdossiers */
  public static CDDSG_UMWANDLUNG = 10   /* Basisdefine für die Werte 11-13 analog 1-3, jedoch für (Rück-)Umwandlungen */
  public static CDDSG_UMW_OPT = 11   /* Rückumwandlung in eine Option */
  public static CDDSG_UMW_REQ = 12   /* Rückumwandlung in einen Request */
  public static CDDSG_UMW_BUCH = 13   /* Umwandlung in Buchungen */
  public static CDDSG_EMAIL_VERSAND = 20   /* Rechnung oder rechnungsähnliches Dokument per EMail versandt */
  public static CDDSG_RG_VERBUCHT = 21   /* Rechnung verbucht */
  public static CDDSG_RG_STORNIERT = 22   /* Rechnung storniert */
  public static CDDSG_FAX_VERSAND = 23   /* Rechnung oder rechnungsähnliches Dokument per Fax versandt */
  public static CDDSG_AUSDRUCK = 24   /* Ausdruck eines rechnungsähnlichen Papiers (ohne Rechnungscodes 50-59) */
  public static CDDSG_RPRG_GEDRUCKT = 25   /* Reiseprogramm gedruckt */
  public static CDDSG_ATB_ERSTELLT = 26   /* ATB erstellt */
  public static CDDSG_VOUCHER_GEDRUCKT = 27   /* Voucher gedruckt */
  public static CDDSG_REQUEST_GEDRUCKT = 28   /* Request gedruckt oder per E-Mail verschickt */
  public static CDDSG_BESTAETIGUNG_GEDRUCKT = 29   /* Buchungsbestätigung gedruckt oder per E-Mail verschickt */
  public static CDDSG_REQUEST_OK = 30   /* Request in Option umgewandelt mit Kontingentsanpassung */
  public static CDDSG_REQUEST_AB_KONTINGENT = 31   /* Request in Option umgewandelt. Anzahl ab bestehendem Kontingent abgebucht */
  public static CDDSG_REQUEST_UEBERBUCHEN_OK = 32   /* Request in Option umgewandelt, Anzahl ab bestehendem Kontingent abgebucht, obwohl nicht genügend Freiplätze vorhanden sind */
  public static CDDSG_REQUEST_ONLINE_GELESEN = 35   /* Änderungen bei den Requestdaten online durch Agent/Anbieter gelesen */
  public static CDDSG_REQUEST_ONLINE_OK = 36   /* Request in Option umgewandelt mit Kontingentsanpassung, ausgelöst direkt durch Agent/Anbieter */
  public static CDDSG_REQUEST_ONLINE_NOK = 37   /* Request abgelehnt direkt durch Agent/Anbieter */
  public static CDDSG_ZINR_NEU = 40   /* Zimmer- bzw. Kabinennummer wurde nachträglich hinzugefügt */
  public static CDDSG_ZINR_VERAENDERT = 41   /* Zimmer- bzw. Kabinennummer  verändert */
  public static CDDSG_ZINR_GELOESCHT = 42   /* Zimmer- bzw. Kabinennummer  nachträglich entfernt */
  public static CDDSG_LL_BEM = 44   /* Teilnehmerbemerkungen auf Ebende Kategorie verändert */
  public static CDDSG_LL_NEU = 45   /* Neue Landleistung hinzugefügt */
  public static CDDSG_LL_ERHOEHT = 46   /* Anzahl reservierter Landleistungen erhöht */
  public static CDDSG_LL_HERABGES = 47   /* Anzahl reservierter Landleistungen herabgesetzt */
  public static CDDSG_LL_GELOESCHT = 48   /* Landleistung aus dem Dossier entfernt */
  public static CDDSG_LL_ZUS_VERAEND = 49   /* teilnehmerspez. Zusatzleistungen auf Landleistungen hinzugefügt, geändert oder gelöscht */
  public static CDDSG_SITZ_NEU = 50   /* Nachträglich einen Sitzplatz reserviert */
  public static CDDSG_SITZ_VERAENDERT = 51   /* Nachträglich einen anderen Sitzplatz zugeteilt */
  public static CDDSG_SITZ_GELOESCHT = 52   /* Nachträglich eine Sitzplatzreservation entfernt */
  public static CDDSG_EINSTIEGSORT = 53   /* Nachträglich einen Einstiegsort verändert, hinzugefügt oder gelöscht */
  public static CDDSG_TR_BEM = 54   /* Teilnehmerbemerkungen zum Transport verändert */
  public static CDDSG_TR_NEU = 55   /* Neuen Transport hinzugefügt */
  public static CDDSG_TR_ERHOEHT = 56   /* Anzahl reservierter Transportleistungen erhöht */
  public static CDDSG_TR_HERABGES = 57   /* Anzahl reservierter Transporte herabgesetzt */
  public static CDDSG_TR_GELOESCHT = 58   /* Transport aus dem Dossier gelöscht */
  public static CDDSG_TLN_AENDERUNG = 60   /* Nachträgliche Änderung am Teilnehmer (Name, Vorname, Geb.dat.) */
  public static CDDSG_TLN_NEU = 61   /* Nachträglich Teilnehmer hinzugefügt */
  public static CDDSG_TLN_GELOESCHT = 62   /* Nachträglich Teilnehmer entfernt */
  public static CDDSG_UMBUCHUNG = 80   /* Umbuchung von einer Kette auf eine andere */
  public static CDDSG_STR_ERSETZT = 81   /* Standardreise nachträglich ersetzt */
  public static CDDSG_LEIST_VERSCHOBEN = 82   /* Leistung verschoben */
  public static CDDSG_STR_BEM = 84   /* Generelle Teilnehmerbemerkungen verändert */
  public static CDDSG_AENDERUNG = 89   /* andere nachträgliche Dossieränderung */
  public static CDDSG_STORNIERT = 90   /* Dossier wurde storniert */
  public static CDDSG_STORNO_VERFALL = 91   /* Dossier automatisch storniert auf Grund des Optionen-Verfalldatums */
  public static CDDSG_AUTOM_LOESCHUNG = 92   /* Dossier wurde automatisch gelöscht (Internet-Dossiers ohne Adresse, die längere Zeit nicht weiter bearbeitet wurden) */
  public static CDDSG_AUTOM_STORNO = 93   /* Dossier automatisch storniert */
  public static CDDSG_ERSETZT = 94   /* Dossier ersetzt durch (und vorliegendes Dossier gleichzeitig storniert) */
  public static CDDSG_REAKTIVIERT = 99   /* storniertes Dossier wieder reaktiviert */
  public static CDDSG_MANUELL = 100  /* Manueller Eintrag auf Wunsch des Benutzers */
  public static CDDSG_KONTINGENT_OPT = 101  /* Rückumwandlung der Kontingente von gebucht auf optioniert ("Hinzufügen", nur bei Buchungsdossiers) */
  public static CDDSG_EXTERNE_DATEI = 102  /* Externe Datei, unter dem Namen DSID + DSGLAUFNR + Originalendung gespeichert */
  public static CDDSG_MELDUNG_AN_TO = 110  /* Meldung von Kunde an Touroperator */
  public static CDDSG_MELDUNG_TO_GELESEN = 119  /* Meldung von Kunde durch Touroperator gelesen */
  public static CDDSG_MELDUNG_AN_KUNDE = 120  /* Meldung von Touroperator an Kunde */
  public static CDDSG_MELDUNG_KUNDE_GELESEN = 129  /* Meldung von Touroperator durch Kunde gelesen */
  public static CDDSG_ERF_BESTAETIGT = 150  /* Interneterfassung durch Kunden/Reisebüro durch Druck auf roten Knopf bestätigt */
  public static CDDSG_ERF_TD_BEARBEITET = 151  /* Nicht bestätigtes Internet-Dossier intern weiter bearbeitet */
  public static CDDSG_ERF_BUANFR = 152  /* Buchungsanfrage im Internet erfasst */
  public static CDDSG_ERF_BUANFR_BEARBEITET = 153  /* Buchungsanfrage im TD weiterbearbeitet */
  public static CDDSG_ERF_KONTR = 154  /* Buchung kontrolliert */
  public static CDDSG_ERF_GLEICHER_TLN = 155  /* Es wurde bereits ein anderes Dossier mit gleichem Teilnehmernamen und -vornamen und ähnlichem Erfassungsdatum erfasst */
  public static CDDSG_OFFERTE_ZU_KONTR = 156  /* Dossier wurde mit "Offerte zu kontrollieren" gekennzeichnet */
  public static CDDSG_OFFERTE_KONTR = 157  /* Offerten des Dossiers wurde kontrolliert */
  public static CDDSG_ZIRES_OK = 160  /* Internet-Zimmerreservation dem buchenden Reisebüro bestätigt */
  public static CDDSG_ZIRES_ALTERNATIVE = 161  /* Internet-Zimmerreservation: Alternative zum gewünschten Zimmer angeboten */
  public static CDDSG_ZIRES_NOK = 162  /* Internet-Zimmerreservation abgelehnt */
  public static CDDSG_UEBERBUCHT_OK = 170  /* Sachbearbbeiter bestätigt, dass Überbuchung ok ist */
  public static CDDSG_UNTERBELEGUNG_OK = 171  /* Sachbearbeiter hat Unterbelegung einer Zimmerkategorie explizit gewünscht und bestätigt */
  public static CDDSG_NICHT_ALLE_OK = 172  /* Sachbearbeiter hat bestätigt, dass nicht alle Teilnehmer zugeteilt werden müssen */
  public static CDDSG_OBLIG_LOESCHUNG_OK = 173  /* Sachbearbeiter hat die Löschung einer obligatorischen Leistung bestätigt */
  public static CDDSG_MG_HOMEPAGE = 180  /* Buchung ab Mitgliederbereich der Homepage */
  public static CDDSG_DOK_WUNSCH = 181  /* Versandart, wie der Kunde seine Dokumente wünscht, sofern über Internet erfolgt */
  public static CDDSG_NOTIZ_INFO = 182  /* Der Kunde wurde über die Produktnotiz informiert */
  public static CDDSG_FRAGEB_GEDRUCKT = 200  /* Teilnehmerfragebogen nachträglich (nochmals) ausgedruckt */
  public static CDDSG_FRAGEB_ERFASST = 201  /* Teilnehmerfragebogen erfasst */
  public static CDDSG_FRAGEB_VERAENDERT = 202  /* Teilnehmerfragebogen nachträglich verändert */
  public static CDDSG_FRAGEB_UNVOLLST_OK = 207  /* Unvollständigen Teilnehmerfragebogen als ok bestätigt */
  public static CDDSG_FRAGEB_OK = 208  /* Teilnehmerfragebogen kontrolliert */
  public static CDDSG_UMFR_AUSGEFUELLT = 210  /* (Reisespezifische) Umfrage beantwortet */
  public static CDDSG_SAFERPAY_AUFRUF = 220  /* Aufruf der Kreditkartenplattform Saferpay */
  public static CDDSG_SAFERPAY_OK = 221  /* Zahlung via Saferpay erfolgreich abgeschlossen */
  public static CDDSG_SAFERPAY_ABBRUCH = 225  /* Saferpay-Modul durch Benutzer abgebrochen */
  public static CDDSG_SAFERPAY_FEHLER = 226  /* Fehlermeldung durch Saferpay-Modul (Zahlung nicht erfolgreich abgeschlossen) */
  public static CDDSG_ERI_MANUELL = 240  /* Erinnerungscode bzw. Datum von Hand verändert */
  public static CDDSG_ERI_EMAIL = 245  /* Erinnerungsmail erstellt */
  public static CDDSG_MARKETINGSTATUS = 246  /* Marketingversand geändert auf */
  public static CDDSG_UPSELLING_INFO = 247  /* Information Upselling-Möglichkeit erstellt */
  public static CDDSG_ZLG_UMGEBUCHT_AUF = 250  /* Zahlung auf ein anderes Dossier umgebucht */
  public static CDDSG_ZLG_UMGEBUCHT_VON = 251  /* Zahlung von einem anderen Dossier umgebucht */
  public static CDDSG_EP_VOLLSTAENDIG = 260  /* "EP vollständig" wurde manuell ausgelöst, aber nicht alle zwingenden EPs erfasst */
  public static CDDSG_EP_RG_NEU = 261  /* Es wurde nach vollständiger Erfassung ein Papier mit EP neu erfasst */
  public static CDDSG_EP_RG_GELOESCHT = 262  /* Rechnung mit bereits verrechneten Einstandspreisen wurde nachträglich gelöscht */
  public static CDDSG_EP_NACHBEARB = 263  /* Die Einstandspreisbearbeitung wurde wieder aktiviert, nachdem sie schon mal als abgeschlossen markiert wurde */
  public static CDDSG_EP_VPR_GELOESCHT = 264  /* Vermerktes Produkt gelöscht, nachdem der EP bereits verrechnet wurde */
  public static CDDSG_EP_VERRDAT_NEU = 265  /* Das Verrechnungsdatum wurde von Hand eingetragen */
  public static CDDSG_EP_VERRDAT_AENDERUNG = 266  /* Ein Verrechnungsdatum wurde nachträglich verändert */
  public static CDDSG_EP_VERRDAT_GELOESCHT = 267  /* Ein Verrechnungsdatum wurde wieder gelöscht */
  public static CDDSG_EP_KONTROLLIERT = 269  /* Manuell festgehaltener EP, wenn dieser kontrolliert wurde */
  public static CDDSG_EP_FOLGEUEBERM = 271  /* Dle Leistung wurde nachträglich so verändert, dass sie nochmals an die EP-Schnittstelle übermittelt werden muss */
  public static CDDSG_EP_UEBERMITTELT = 275  /* Der Einstandspreis wurde an ein verbundenes System übermittelt */
  public static CDDSG_GSCODE_ERFASST = 280  /* Gutscheincode erfasst */
  public static CDDSG_GSCODE_GELOESCHT = 281  /* Gutscheincode gelöscht */
  public static CDDSG_GSCAUTOM_ABGABE_GESPERRT = 290  /* Abgabe von automatischen Gutscheincodes ausgeschlossen */
  public static CDDSG_GSCAUTOM_ABGABE_REAKTIVIERT = 291  /* Abgabe von automatischen Gutscheincodes wieder zugelassen */
  public static CDDSG_GS_RUECKERSTATTUNG_UEBER = 295  /* Rückerstattung als Gutschein über dieses Dossier */
  public static CDDSG_GS_RUECKERSTATTUNG_FUER = 296  /* Gutschein als Rückerstattung für Dossier */
  public static CDDSG_GS_KULANZ_UEBER = 298  /* Kulanzzahlung als Gutschein über Dossier */
  public static CDDSG_GS_KULANZ_FUER = 299  /* Gutschein als Kulanz für Dossier */
  public static CDDSG_DOK_RTF = 300  /* RTF-Dokument über Tournet abgeholt */
  public static CDDSG_DOK_TOURDATA3 = 310  /* B2B-Record an Tourdata3 geschickt */
  public static CDDSG_DOK_UMBRELLA_NT = 320  /* B2B-Record an Umbrella NT geschickt (bis April 2008) */
  public static CDDSG_DOK_UMBRELLA_CMIR = 321  /* CMIR-Record an Umbrella geschickt */
  public static CDDSG_DOK_HIT = 330  /* B2B-Record an HIT geschickt */
  public static CDDSG_DOK_TICOFFICE = 340  /* B2B-Record an TicOffice geschickt */
  public static CDDSG_DOK_TOURONLINE = 350  /* TD-XML-Record an Tour Online geschickt (bis April 2008) */
  public static CDDSG_DOK_TOURONLINE_CMIR = 351  /* CMIR-Record an Touronline geschickt */
  public static CDDSG_RAILFLY_ERSTELLT = 430  /* Rail&Fly-Gutscheincodes der Deutschen Bahn erstellt */
  public static CDDSG_RAILFLY_ERSTELLFEHLER = 431  /* Fehler beim Erstellen eines Rail&Fly-Gutscheincodes der Deutschen Bahn */
  public static CDDSG_RAILFLY_AKTUALISIERT = 433  /* Rail&Fly-Daten der Gutscheincodes der Deutschen Bahn aktualisiert */
  public static CDDSG_RAILFLY_AKTUALISIERUNGSFEHLER = 434  /* Fehler beim Aktualisieren eines Rail&Fly-Gutscheincodes */
  public static CDDSG_RAILFLY_BEREITS_AUSGESTELLT = 436  /* Rail&Fly-Ticket war vor der Änderung des Gutscheincodes bereits ausgestellt */
  public static CDDSG_RAILFLY_GS_STORNIERT = 438  /* Einzelnen Gutscheincode aus Rail&Fly-Gutscheincodes storniert */
  public static CDDSG_RAILFLY_STORNIERT = 439  /* Alle Rail&Fly-Gutscheincodes storniert */
  public static CDDSG_TICKET_ERSTELLT = 440  /* (Spezial-)Ticket erstellt */
  public static CDDSG_TICKET_ERSTELLFEHLER = 444  /* (Spezial-)Ticket konnte nicht erstellt werden */
  public static CDDSG_TICKET_STORNIERT = 449  /* (Spezial-)Ticket storniert */
  public static CDDSG_PNR_GALILEO_ERSTELLT = 450  /* Galileo-PNR automatisch erstellt */
  public static CDDSG_PNR_GALILEO_MANUELL = 451  /* Record-Locator für Galileo-PNR manuell eingegen */
  public static CDDSG_PNR_GALILEO_WARNUNG = 455  /* Galileo-PNR-Warnung *PNR manuell anpassen" akzeptiert */
  public static CDDSG_PNR_GALILEO_STORNIERT = 459  /* Galileo-PNR automatisch storniert */
  public static CDDSG_PNR_AMADEUS_ERSTELLT = 470  /* Amadeus-PNR automatisch erstellt */
  public static CDDSG_PNR_AMADEUS_MANUELL = 471  /* Record-Locator für Amadeus-PNR manuell eingegeben */
  public static CDDSG_PNR_AMADEUS_WARNUNG = 475  /* Amadeus-PNR Warnung "PNR manuell anpassen" akzeptiert */
  public static CDDSG_PNR_AMADEUS_STORNIERT = 479  /* Amadeus-PNR automatisch storniert */
  public static CDDSG_PNR_ERSTELLT = 490  /* PNR (übrige Systeme) automatisch erstellt */
  public static CDDSG_PNR_MANUELL = 491  /* Record-Locator für PNR (übrige Systeme) manuell eingegeben */
  public static CDDSG_PNR_GEANDERT = 494  /* PNR (übrige Systeme) automatisch geändert */
  public static CDDSG_PNR_WARNUNG = 495  /* PNR-Warnung (übrige Systeme) "PNR muss manuell nachgeführt werden" bestätigt */
  public static CDDSG_PNR_STORNIERT = 499  /* PNR (übrige Systeme) automatisch storniert */
  public static CDDSG_GASTRO_ERF = 501  /* Gastrohinweis erfasst */
  public static CDDSG_GASTRO_AEND = 502  /* Gastrohinweis bearbeitet */
  public static CDDSG_GASTRO_GEL = 503  /* Gastrohinweis gelöscht */
  public static CDDSG_GASTRO_MENU = 505  /* Menuplan an Kunde geschickt */
  public static CDDSG_GASTRO_OK = 508  /* Gastrohinweis durch Gastroabteilung bestätigt */
  public static CDDSG_GASTRO_DS = 511  /* Gastrodossier eröffnet */
  public static CDDSG_GASTRO_DS_GEL = 513  /* Gastrodosssier gelöscht */
  public static CDDSG_GASTRO_DS_OK = 518  /* Gastrodossier ok */
  public static CDDSG_TICKET_GEDRUCKT = 600  /* Ticket wurde ausgedruckt */
  public static CDDSG_MAHNUNG_GEDRUCKT = 601  /* Mahnung gedruckt */
  public static CDDSG_MAHNUNG_EMAIL = 602  /* Mahnung per Mail geschickt */
  public static CDDSG_FRAGEBOGENLINK_EMAIL = 603  /* Link auf Online-Fragebogen per E-Mail verschickt */
  public static CDDSG_BUCHUNGSBEST_EMAIL = 604  /* Automatische Buchungsbestätigung per vordefiniertem E-Mail verschickt (nicht Textverarbeitung) */
  public static CDDSG_LLTICKET_EMAIL = 605  /* Landleistungstickets per E-Mail verschickt */
  public static CDDSG_LLTICKET_EMAIL_FEHLER = 606  /* Automatische Landleistungstickets konnten nicht zugestellt werden */
  public static CDDSG_PNL_AIRBERLIN = 610  /* Automatisches AirBerlin-PNL nach kurzfristiger Buchung/Änderung erstmals übermittelt */
  public static CDDSG_DOK_SB = 620  /* Dokumentenversand-Sachbearbeiter verändert */
  public static CDDSG_DOK_BEM = 621  /* Dokumentenversand-Bemerkungen verändert */
  public static CDDSG_DOK_DATUM = 622  /* Dokumentenversand-Datum verändert */
  public static CDDSG_MOB_BUCHUNG = 700  /* Buchungsabschluss mit Mobiltelefon */
  public static CDDSG_SMS_BESTAETIGUNG = 720  /* Kurzversion der Buchungsbestätigung per SMS geschickt */
  public static CDDSG_SMS_VERSAND_VON = 720  /* Beginn des für verrechnenbare SMS-Versände vorgesehenen Bereichs */
  public static CDDSG_SMS_CHAUFFEURAPP = 721  /* SMS-Versand aus Chauffeur-App */
  public static CDDSG_SMS_AUTOM_VERARBEITUNG = 729  /* SMS-Versand aus sonstigen automatischen Verarbeitungen (z.B. Dienst) */
  public static CDDSG_SMS_VERSAND_BIS = 729  /* Ende des für verrechenbare SMS-Versände vorgesehenen Bereichs */
  public static CDDSG_APP_DS_NEU = 750  /* Dossier im App erstmalig geladen */
  public static CDDSG_APP_DS_INIT = 755  /* Dossierdaten erstmalig an App übermittelt */
  public static CDDSG_APP_DS_UPD = 756  /* Dossierdaten nach Änderungen an App übermittelt */
  public static CDDSG_DSABREISE_MANUELL = 800  /* Dossierabreisedatum manuell gesetzt */
  public static CDDSG_DSABREISE_GELOESCHT = 809  /* Manuell gesetztes Dossierabreisedatum wieder gelöscht */
  public static CDDSG_BEARB_MANDANT = 810  /* Bearbeitungsmandant wurde manuell geändert */
  public static CDDSG_MARGENPRUEF_MAN = 820  /* Margenprüfung wurde manuell geändert */
  public static CDDSG_SPEZ_AENDERUNG = 900  /* Ausserprogrammliche Änderungen, welche nicht an Leistungsträger übermittelt werden. Wird z.B. durch einmalige Korrekturskripte erstellt. */
  public static CDDSG_BSTABR_DS_ERSTELLT = 1000 /* Buchungsstellenabrechnungsdossier erstellt */
  public static CDDSG_BSTABR_AUSSCHLUSS1 = 1001 /* (Temporärer) Ausschluss einer Rechnung von der Buchungsstellenabrechnung */
  public static CDDSG_BSTABR_NICHT_ERFOLGT = 1009 /* Die Buchungsstellenabrechnung konnte nicht durchgeführt werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_SBDS_TYPCODE = 3004 /* Art des Sachbearbeiter-Dossier-Eintrags */
  public static CDSBDS_BEARBEITET = 1

  /*--------------------------------------------------------------------------*/

  public static TAB_VPRSTATUS = 3005 /* Reservationszustand des vermerkten Produkts */
  public static CDVPR_KEINE = 0
  public static CDVPR_OPTION = 1
  public static CDVPR_TEMP_OPTION = 2
  public static CDVPR_WARTELISTE = 3
  public static CDVPR_REQUEST = 4
  public static CDVPR_BUCHUNG = 5
  public static CDVPR_TEILREQUEST = 8
  public static CDVPR_EX_REQUEST = 14
  public static CDVPR_EX_REQUEST_GELOESCHT = 24
  public static CDVPR_EX_TEILREQUEST = 28
  public static CDVPR_PLUS_ZUS_KONT = 100  /* Zum normalen Code (0-99) addieren bei zusätzlichen Kontingenten */
  public static CDVPR_ZUS_KEINE = 100
  public static CDVPR_ZUS_TEMP_OPTION = 102
  public static CDVPR_ZUS_WARTELISTE = 103
  public static CDVPR_ZUS_REQUEST = 104
  public static CDVPR_ZUS_BUCHUNG = 105
  public static CDVPR_ZUS_TEILREQUEST = 108
  public static CDVPR_ZUS_EX_REQUEST = 114
  public static CDVPR_ZUS_EX_REQUEST_GELOESCHT = 124
  public static CDVPR_ZUS_EX_TEILREQUEST = 128

  /*--------------------------------------------------------------------------*/

  public static TAB_VPRHERKUNFTSCODE = 3006 /* Herkunft der Produktinformationen */
  public static CDVPRHERK_VORDEF = 0    /* Vordefiniertes Produkt in Tourdata */
  public static CDVPRHERK_GMIR = 10   /* Galileo GMIR */
  public static CDVPRHERK_SABRE = 20   /* Sabre */
  public static CDVPRHERK_AMADEUS = 30   /* Amadeus */
  public static CDVPRHERK_CETS = 40   /* CETS */
  public static CDVPRHERK_TOURONLINE = 50   /* Tour Online */
  public static CDVPRHERK_TOURDATA = 60   /* Tourdata */
  public static CDVPRHERK_SPRK = 70   /* SPRK (Lufthansa-Gruppe inkl. Swiss) */
  public static CDVPRHERK_MANUELL = 90   /* Handeingabe */
  public static CDVPRHERK_TRAVIA = 100  /* Travia */

  /*--------------------------------------------------------------------------*/

  public static TAB_TLNBRIEFANREDE = 3008 /* Briefanrede für Teilnehmer (gekoppelt mit Codeart 3009) */

  /*--------------------------------------------------------------------------*/

  public static TAB_TLNANREDECODE = 3009 /* Teilnehmeranredecodes (neben den angegebenen Codes sind frei definierbare möglich) */
  public static CDTLNANR_KEINE = 0    /* ohne Anrede */
  public static CDTLNANR_CHD = 90   /* Child (Kind) */
  public static CDTLNANR_INF = 91   /* Infant (Kleinkind) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RGCODE = 3010 /* Rechnungsart (+100=Text für Storno; ergänzende Einstellungen bei Sprachcode 0; s.a. Codeart 5013, Codes 1500-1599 für E-Mail-Dateinamen) */
  public static CDRG_RG = 1    /* Rechnung */
  public static CDRG_ERSATZRG = 2    /* Ersatzrechnung */
  public static CDRG_ZUSATZRG = 3    /* Zusatzrechnung */
  public static CDRG_ANNULLRG = 4    /* Annullationsrechnung */
  public static CDRG_ANZLGRG = 5    /* Anzahlungsrechnung */
  public static CDRG_MIN_FREI_RG = 7    /* Erster für frei definierbare zu verbuchende Rechnungsarten reservierter Code */
  public static CDRG_MAX_FREI_RG = 9    /* Letzter für frei definerbare zu verbuchende Rechnungsarten reservierter Code */
  public static CDRG_GS = 11   /* Gutschrift */
  public static CDRG_ERSATZGS = 12   /* Ersatzgutschrift */
  public static CDRG_PROVISIONSGS = 15   /* Provisionsabrechnung Gutschrift */
  public static CDRG_MIN_OHNE_VERBUCHUNG = 20   /* Minimaler Wert für rechnungsähnliche Papiere ohne Verbuchungsmöglichkeit (Max. bei CDRG_STORNO-1) */
  public static CDRG_NACHKALKULATION = 30   /* Nachkalkulation (nur Einkaufspreise möglich) */
  public static CDRG_CHANGE = 40   /* Change (Anz. 6-stellig für FW, Ansatz 4 Nachkommast. Kurs, Totalspalte CHF editierbar, keine 0% MWST) */
  public static CDRG_MIN_MAHNUNG = 50   /* Erstes f. Mahnungen reserviertes Papier (s.Codeart 3580 MAHNSTUFENCODE, der dazu addiert werden kann) */
  public static CDRG_MIN_MAHNUNG_OHNE_GS = 50   /* Erstes für Mahnungen (ohne Gutschriften) reserviertes Papier */
  public static CDRG_MAX_MAHNUNG_OHNE_GS = 58   /* Letztes für Mahnungen (ohne Gutschriften) reserviertes Papier */
  public static CDRG_MAX_MAHNUNG = 59   /* Letztes für Mahnungen oder Gutschriften reserviertes Papier */
  public static CDRG_FREIES_DOK = 60   /* Freies Dokument (üblicherweise Links) */
  public static CDRG_REQUEST = 70   /* Request (Dossierpartner, d.h. Empfänger ist ein Anbieter oder Agent) */
  public static CDRG_OFFERTE = 80   /* Offerte */
  public static CDRG_AUFTRAGSBEST = 81   /* Auftragsbestätigung */
  public static CDRG_FAHRAUFTR = 82   /* Fahrauftrag */
  public static CDRG_VOUCHER = 84   /* Voucher */
  public static CDRG_REISEPROG = 85   /* Reiseprogramm */
  public static CDRG_MIN_TO_OFFERTE = 87   /* Erstes Papier, das bei Optionen und Requests erstellt werden kann ohne den Dossierstatus zu verändern (Offerte, Vorreservationen) */
  public static CDRG_TO_OFFERTE = 87   /* Normalerweise für TO-Offerte verwendet (wichtig bei speziellen Ablaufsteuerungen für Offertabläufe) */
  public static CDRG_MAX_TO_OFFERTE = 89   /* Letztes Papier, das bei Optionen und Requests erstellt werden kann, ohne den Dossierstatus zu verändern */
  public static CDRG_MIN_FREI_DEFINIERBAR = 90   /* Minimaler Wert für frei definierbare, nicht verbuchbare Papiere (max. bei CDRG_STORNO-1, bzw. neu auch CDRG_MAX_FREI_DEFINIERBAR) */
  public static CDRG_QUITTUNG = 90   /* Quittung */
  public static CDRG_MAHNUNG = 91   /* Mahnung (alt) */
  public static CDRG_MAX_FREI_DEFINIERBAR = 99   /* Max. Wert für frei definierbare, nicht verbuchte Papiere (im Programm wird auch CDRG_STORNO-1 verwendet, da der MAX-Define später hinzukam) */
  public static CDRG_STORNO = 100  /* + 100 = Text für storniert */
  public static CDRG_MIN_TECHNISCH = 900  /* Ab hier reserviert für spezielle Dokumente ohne Druck, Verbuchung und Storniermöglichkeit (direkt löschen möglich) */
  public static CDRG_MIN_PZL = 900  /* Erster für Preiszeilenvorschläge reservierter Code (nur in Dossier gem. Codeart 5009 möglich) */
  public static CDRG_AUTOM_PZL_AUTOM_RG_ENDE = 901  /* Bei automatisch berechneten Rechnungen ohne Rückfrage hinzugefügte Preiszeilen (am Ende der Rechnung; z.Z. noch nicht implementiert) */
  public static CDRG_AUTOM_PZL_MAN_RG = 902  /* Bei manuellen Pauschalreisenrechnungen ohne Rückfrage hinzugefügte Preiszeilen (z.Z. noch nicht implementiert) */
  public static CDRG_AUTOM_PZL_AUFTR = 905  /* Bei neuen Dokumenten von Auftragsdossiers ohne Rückfrage hinzugefügte Preiszeilen (z.Z. noch nicht implementiert) */
  public static CDRG_WAHL_PZL_PAUSCHAL = 911  /* Preiszeilen, die bei Pauschalreisendossiers bei einer neuen Rechnung sowie beim Hinzufügen-Knopf zur Auswahl angeboten werden */
  public static CDRG_WAHL_PZL_AUFTR = 912  /* Preiszeilen, die bei Auftragsdossiers bei einer neuen Rechnung sowie beim Hinzufügen-Knopf zur Auswahl angeboten werden */
  public static CDRG_MAX_PZL = 919  /* Letzter für automatische Preiszeilenvorschläge reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_PZLCODE = 3011 /* Art der Preiszeile (s.a. Codeart 3012) */
  public static CDPZL_MAN_PREIS = 1    /* manuell erfasster Preis */
  public static CDPZL_ARR_PREIS = 2    /* Arrangementpreis (allenfalls manuell verändert) */
  public static CDPZL_ZUS_PREIS = 3    /* Preiszuschlag (ev. man. verändert) */
  public static CDPZL_GUTSCHEIN = 4    /* Gutschein über den entsprechenden Betrag (alte Variante) */
  public static CDPZL_TAG = 5    /* Tagespreis */
  public static CDPZL_GUTSCHEIN_NR = 6    /* Gutschein aus Tourdata-Nummernverwaltung (neue Variante) */
  public static CDPZL_STORNOGEB = 7    /* Stornogebühren */
  public static CDPZL_INFO = 10   /* Zus. Informationen, z.Z. nur für Verkaufsgutscheine, kann den Platzhalter {Nr.} für die dynamsiche Aufbereitung der Gutscheinnr. beinhalten */
  public static CDPZL_MAN_ERM = 21   /* manuell erfasste Ermässigung */
  public static CDPZL_KIND_ERM = 22   /* Kinderermässigung (allenfalls manuell verändert) */
  public static CDPZL_KIND_ZUSERM = 23   /* Zus. Kindermässigung 2. uff. Kinder (%d=durch Nr. ersetzen) */
  public static CDPZL_ERW_ZUSERM = 24   /* Zus. Ermässigung für weitere Erwachsene Personen (%d=durch Nummer ersetzen) */
  public static CDPZL_MG_ERM = 25   /* Mitgliedschaftsermässigung */
  public static CDPZL_GSCODE_ERM = 26   /* Gutscheincode-Ermässigung */
  public static CDPZL_GSINDIV_CODE_ERM = 27   /* Ermässigung durch einen individuellen Gutscheincode */
  public static CDPZL_KIND_ZUSZUSCH = 33   /* Zusätzlicher Kinderzuschlag 2. uff. Kinder (%d durch Nr. ersetzen) */
  public static CDPZL_MIN_SPEZZEILEN = 40   /* Spezialzeilen, die berechnet werden */
  public static CDPZL_IND_KOMM = 41   /* individuelle Kommission pro Preiszeile (pro % eine Zeile) */
  public static CDPZL_GEN_KOMM = 42   /* generelle Kommission (nur 1x möglich) */
  public static CDPZL_KD_RAB = 43   /* Kundenrabatt (nur 1x möglich) */
  public static CDPZL_SUPERKOMM = 44   /* Superkommission (nur 1x möglich) */
  public static CDPZL_KK_KOMM = 45   /* Kreditkartenkommission (nur 1x möglich) */
  public static CDPZL_MIN_OHNE_VPRLAUFNR = 48   /* Ab diesem Code werden keine Referenzen zu vermerkten Produkten gelegt */
  public static CDPZL_MWST_BST_KOMM = 49   /* Mehrwertsteuer für die Buchungsstelle (Deutsche Provisionsbesteuerung), Pauschal über gesamten Kommissionsbetrag (Kommission=100% exkl.MWST) */
  public static CDPZL_MWST_PAUSCHAL = 50   /* Preiszeilen, die nur zur MWST-Berechnung herangezogen werden (für Pauschalsätze) */
  public static CDPZL_EXKL_MWST = 51   /* Mehrwertsteuer, die zum Zwischentotal dazugezählt werden muss */
  public static CDPZL_INKL_MWST = 52   /* Mehrwertsteuer, die im Total bereits inbegriffen ist */
  public static CDPZL_EXKL_MWST_BRUTTO = 56   /* Analog Code 51, Ausgangswert  jedoch Brutto- statt pflichtiger Betrag */
  public static CDPZL_INKL_MWST_BRUTTO = 57   /* Analog Code 52, Ausgangswert jedoch Brutto- statt pflichtiger Betrag */
  public static CDPZL_OHNE_MWST = 59   /* Beträge, die nicht MWST-pflichtig sind */
  public static CDPZL_MWST_KUNDE = 62   /* Mehrwertsteuer für den Endkunden */
  public static CDPZL_MWST_KUNDE_BRUTTO = 67   /* Analog Code 62, jedoch Brutto- statt pflichtiger Betrag */
  public static CDPZL_OHNE_MWST_KUNDE = 69   /* Beträge für den Endkunden, die nicht MWST-pflichtig sind */
  public static CDPZL_TEILSUMME = 90   /* Teilsumme (Total aller darüberliegender Zeile bis unter eine andere Teilsummen-Zeile) */
  public static CDPZL_ZW_TOT = 91   /* Zwischentotal (z.Z. nur 1x möglich) */
  public static CDPZL_GES_TOT = 92   /* Gesamttotal (nur 1x möglich) */
  public static CDPZL_ZAHLUNG = 93   /* Bereits erhaltene Zahlung */
  public static CDPZL_REST_BETR = 94   /* Restbetrag (Gesamttotal ./. Zahlung) */
  public static CDPZL_UMRECHNUNG = 95   /* Umrechnung des Gesamtbetrags in eine Zweitwährung */
  public static CDPZL_REST_GUTHABEN = 96   /* Restbetrag, wenn der Kunde zu viel bezahlt hat (=Guthaben des Kunden), sonst analog PZLCODE 94 */
  public static CDPZL_TEIL_TITEL = 99   /* Teiltrennzeile und Titel für das Folgeteil */

  /*--------------------------------------------------------------------------*/

  public static TAB_PZLTEXTCODE = 3012 /* Analog PZLCODE jedoch Texte für die Rechnung mit Zusatzcodes ab 100 */
  public static CDPZL_LL_PREIS = 102  /* Text vor Hoteleinzelpreis (anstelle Arrangementpreis) */
  public static CDPZL_STORNOBEARBGEB = 107  /* Text für fixe Bearbeitungsgebühren bei Storni (wird nur für PZLCODE 7 verwendet) */
  public static CDPZL_AUFPREIS = 110  /* Aufpreis (Text vor der nachfolgend aufgeführten Leistung, die eine andere, günstigere ersetzt; wird z.B. für Ausflüge verwendet) */
  public static CDPZL_ABSCHLAG = 111  /* Abschlag (Text vor der nachfolgend aufgeführten Leistung, die eine andere, teurere ersetzt; wird z.B. für Ausflüge verwendet) */
  public static CDPZL_OHNE = 116  /* ohne (Text vor der nachfolgend aufgeführten Leistung, welche aus einem Paket entfernt wurde; wird für Ausflüge verwendet) */
  public static CDPZL_RAB_STATT_IND_KOMM = 141  /* Abweichende Beschriftung der individuellen Kommissionspreiszeile, wenn als Rabatt statt Kommission auszuweisen */
  public static CDPZL_RAB_STATT_GEN_KOMM = 142  /* Abweichende Beschriftung der generellen Kommissionspreiszeile, wenn als Rabatt statt Kommission auszuweisen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PZLMANTEXTCODE = 3013 /* Generelle Auswahltexte für manuelle Preiszeilen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PZLBETRAGSCODE = 3014 /* Art der Betragsberechnung */
  public static CDPZLB_KEIN_BETRAG = 0    /* Die Zeile beinhaltet nur Text */
  public static CDPZLB_BETRAG = 1    /* Betrag direkt in der Hauptkolonne */
  public static CDPZLB_ANZAHL_ANSATZ = 2    /* Anzahl * Ansatz = Betrag */
  public static CDPZLB_PROZENT_ANSATZ = 3    /* Prozent von Ansatz = Betrag */
  public static CDPZLB_GETEILT = 4    /* Anzahl / Ansatz */
  public static CDPZLB_EP = 10   /* nur Einstandspreis berechnen (Text wird auf Rechnung nie angezeigt; auf PZLEPBETRAGSCODE nicht mögl.) */
  public static CDPZLB_MASSEINHEITEN_VON = 200  /* Beginn des Bereichs mit Masseinheiten (z.B. kg); gleich behandeln wie Code 2 (Anzahl*Ansatz=Betrag) */
  public static CDPZLB_WAEH_VON = 290  /* Erster für Währungen reservierter Code */
  public static CDPZLB_WAEH_BIS = 299  /* Letzter für Währungen reservierter Code */
  public static CDPZLB_MASSEINHEITEN_BIS = 299  /* Letzter für Masseinheiten reservierter Code */
  public static CDPZLB_LANDESWAEH = 490  /* Anzahl (LW-Betrag) / Ansatz (Wechselkurs) */

  /*--------------------------------------------------------------------------*/

  public static TAB_DEF_PZL_MAN = 3015 /* Vorgeschlagene Preiszeilen bei manuell erstellten Rechnungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_DEF_PZL_AUTOM = 3016 /* Preiszeilen, die zusätzlich bei der automatischen Berechnung hinzugefügt werden */
  public static CDPZLA_MAX_VORHER = 999  /* Alle Zeilen mit Code <= 999 werden VOR den automatisch erstellten Zeilen eingefügt (die anderen danach) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RG_KOPIE = 3017 /* Beschriftung der Rechnungsdurchschlaege (Code 0=Originalbeleg, 1-9=Durchschlaege Codeeintrag) */

  /*--------------------------------------------------------------------------*/

  public static TAB_IATA_REMARKS = 3018 /* Von der IATA standardisierte Teilnehmerbemerkungen zu den Fluegen */

  /*--------------------------------------------------------------------------*/

  public static TAB__ALT_DESHERKUNFTSCODE = 3019 /* In Version 2.21 ersetzt durch Codeart 12, DB-Einträge aus Kompatibilitätsgründen noch vorhanden */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSWAHL = 3020 /* Auswahl für Dossierstati */

  /*--------------------------------------------------------------------------*/

  public static TAB_PZLMWSTCODE = 3021 /* Art der Mehrwertsteuer einer Preiszeile (s.a. Codeart 3050 für individuelle Bezeichnungen) */
  public static CDPZLMWST_KEINE = 0    /* keine Mehrwertsteuer */
  public static CDPZLMWST_1 = 1    /* Erster MWST-Satz */
  public static CDPZLMWST_2 = 2    /* Zweiter Mehrwertsteuersatz */
  public static CDPZLMWST_3 = 3    /* Dritter Mehrwertsteuersatz */
  public static CDPZLMWST_KM = 11   /* Erster Satz, jedoch nur gem. Inlandanteil der gefahrenen Kilometer */
  public static CDPZLMWST_KEINE_VON = 20   /* keine Mehrwertsteuer erster Eintrag */
  public static CDPZLMWST_KEINE_KOMM = 30   /* keine Mehrwertsteuer, auch nicht auf Kommission */
  public static CDPZLMWST_KEINE_BIS = 39   /* keine Mehrwertsteuer letzter Eintrag */

  /*--------------------------------------------------------------------------*/

  public static TAB_DEF_PZL_AUFTR = 3022 /* Vorgeschlagene Preiszeilen bei Auftragsdossiers (analog Codeart 3015/16) */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSSTATISTIKCODE = 3023 /* Dossierstatistikcode für individuelle Auswertungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_TICCODE = 3024 /* Ticketarten */
  public static CDTC_TICKET = 1    /* normales Ticket (oder Void) */
  public static CDTC_MIN_REFTICKET = 10   /* Alle Einträge ab hier haben eine Referenz auf ein ursprüngliches Ticket */
  public static CDTC_CONJUNCTION = 10   /* Fortsetzungsticket, ist immer einem Ticket mit Code 1 zugeordnet */
  public static CDTC_EXCHANGE = 20   /* Austauschticket, ist immer einem Ticket mit Code 1 oder 10 zugeordnet */
  public static CDTC_REFUND = 50   /* zurückerstattetes Ticket, ist immer einem Ticket mit Code 1-20 zugeordnet */

  /*--------------------------------------------------------------------------*/

  public static TAB_TLNSTATUS = 3025 /* Teilnehmerstatus */
  public static CDTLNS_AKTIV = 0    /* Teilnehmer ist aktiv */
  public static CDTLNS_GELOESCHT = 9    /* Teilnehmer ist nicht mehr aktiv */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSLINKCODE = 3026 /* Dossierverbindungscode */
  public static CDDSL_KEIN = 0    /* keine Dossierverbindung vorhanden (NULL) */
  public static CDDSL_SCHIFF = 1    /* Verbindung zum Schiffsdossier (Eintrag beim Gastrodossier) */
  public static CDDSL_GASTRO = 2    /* Verbindung zum Gastrodossier (Eintrag beim Schiffsdossier) */
  public static CDDSL_RUECKERSTATTUNG_FUER = 11   /* Verbindung zum Dossier, das die Grundlage für diese Rückerstattung war */
  public static CDDSL_KULANZ_FUER = 15   /* Verbindung zum Dossier, das die Grundlage für diese Kulanzbuchung war */
  public static CDDSL_ERSETZT_DURCH = 90   /* Verbindung zum Dossier, das das bestehende, stornierte Dossier ersetzt */

  /*--------------------------------------------------------------------------*/

  public static TAB_GASTROSTATUS = 3027 /* Gastrostatus */
  public static CDGASTRO_OHNE = 0    /* ohne Essen */
  public static CDGASTRO_PENDENT_VON = 1    /* Erster Status eines von der Gastroabteilung noch zu bearbeitenden Dossiers */
  public static CDGASTRO_NEU = 1    /* Neuer Eintrag für die Gastroabteilung */
  public static CDGASTRO_AEND = 2    /* Änderung für die Gastroabteilung */
  public static CDGASTRO_LOESCH = 3    /* Bestehender Gastroeintrag gelöscht */
  public static CDGASTRO_PENDENT_BIS = 4    /* Letzter Status eines von der Gastroabteilung zu bearbeitendes Dossier */
  public static CDGASTRO_NACHHAKEN_VON = 5    /* 1. Status von Dossiers, die in der Gastroabteilung bekannt sind, aber der Kunde nicht bestätigt hat */
  public static CDGASTRO_KUNDE = 5    /* Kunde avisiert */
  public static CDGASTRO_DS = 7    /* Gastrodossier in Arbeit */
  public static CDGASTRO_NACHHAKEN_BIS = 7    /* Letzter Status von Dossiers, die der Kunde noch nicht bestätigt hat */
  public static CDGASTRO_OK = 8    /* Gastroabteilung hat Eintrag bestätigt */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSBEM_CODE = 3028 /* Dossierbemerkungsart */
  public static CDDSBEM_NORMAL = 1    /* Normale Dossiernotiz */
  public static CDDSBEM_GASTRO = 10   /* Gastronotiz */
  public static CDDSBEM_MENU_NICHT_IN_APP = 9000 /* *** Temporärer Eintrag ***: Komma getrennte Auflistung aller Menupunkte (MNPLAUFNR), welche nicht in der App angezeigt werden dürfen */

  /*--------------------------------------------------------------------------*/

  public static TAB_SPOCODE = 3029 /* Art des Sammelpostens (zu verbuchende Codes sind auch unter Codeart 3502 [BUABLAUFCODE1] abgelegt) */
  public static CDSPO_BDD = 5    /* Business Debit Direct (BDD) oder LSV+ */

  /*--------------------------------------------------------------------------*/

  public static TAB_TLNFRAGEBOGENSTATUS = 3030 /* Teilnehmerfragebogenstatus */
  public static CDFRBOS_KEINE = 0    /* keine Zusatzinformationen nötig (NULL gespeichert) */
  public static CDFRBOS_MIN_NOK = 1    /* Erster Status, der für den Dossierstatus als noch nicht OK */
  public static CDFRBOS_FEHLEN = 1    /* Die Zusatzinformationen fehlen */
  public static CDFRBOS_UNVOLLST = 3    /* Zusatzinformationen sind erst teilweise erfasst */
  public static CDFRBOS_MAX_NOK = 4
  public static CDFRBOS_NICHT_KONTR = 5    /* Zusatzinformationen durch Kunden direkt erfasst, vollständig aber noch nicht kontrolliert */
  public static CDFRBOS_MIN_OK = 6    /* Erster Status, der für den Dossierstatus als OK gewertet wird */
  public static CDFRBOS_MAN_OK = 7    /* Zusatzinformationen fehlen oder sind unvollständig, wurden aber vom Sachbearbeiter manuell als ok bestätigt */
  public static CDFRBOS_OK = 8    /* Zusatzinformationen sind vollständig */
  public static CDFRBOS_GELOESCHT_OK = 9    /* Zusatzinformationen nach Rückreise gelöscht, waren mal ok */

  /*--------------------------------------------------------------------------*/

  public static TAB_PZLDARSTELLUNGSCODE = 3031 /* Art wie Preiszeilen auf der Rechnung dargestellt werden */
  public static CDPZLD_NORMAL = 0    /* Normale Aufbereitung (NULL) */
  public static CDPZLD_ERSTE_ZEILE = 1    /* Auf erster Zeile integrieren (Symbol x) */
  public static CDPZLD_OBERE_ZEILE = 2    /* Auf erster darüber liegenden Zeile integrieren, bei der PZLDARSTELLUNGSCODE leer ist (Symbol o) */
  public static CDPZLD_NULLBETRAG = 8    /* wie Normal, 0-Beträge werden jedoch auch aufbereitet (Symbol n) */
  public static CDPZLD_EXTERNE_RG = 20   /* Nur bei PZLCODE 93 möglich: Offener Betrag nicht ausweisen, wenn 0 (Symbol r für externe Rechnung) */
  public static CDPZLD_MWST_FW = 30   /* Nur bei PZLCODE 51-57 + 62-67 möglich: Beträge in Rechnungs- und nicht wie üblich in Landeswährung (Symbol f für Fremdwährung) */
  public static CDPZLD_KEINE_AENDERUNGEN = 50   /* Z.Z. nur bei PZLCODE 93 möglich: Änderungen an Betrag und Text sind nicht erlaubt; löschen möglich, wenn nicht verbucht (z.Z. kein Symbol) */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSVERANSTALTERCODE = 3032 /* Veranstaltercode bei Reisebürobuchungen (CDBESCHR ist leer oder beinhaltet alle mögl. Catalog Codes) */

  /*--------------------------------------------------------------------------*/

  public static TAB_LL_REMARKS = 3033 /* Individuell hinterlegte Teilnehmerbemerkungen für Landleistungen für Übersetzungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSPA_CODE = 3034 /* Art des Dossierpartners */
  public static CDDSPA_DEFAULT = 0    /* NULL=alte Fälle, Default gem. Adresse (bei PATYPCODE=2 wie 2 sonst 1 behandeln) */
  public static CDDSPA_KUNDE = 1    /* als Rechnungsempfänger für Teilnehmerzuordnung nutzbar (mehrere pro Dossier möglich) */
  public static CDDSPA_BUCHUNGSSTELLE = 2    /* Buchungsstelle (nur 1 pro Dossier möglich) */
  public static CDDSPA_ANBIETER = 10   /* Leistungsträger, Agent (nur für Doku-Register Voucher usw.) */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSSTORNOCODE = 3035 /* Individueller Grund für statistische Auswertungen, warum ein Dossier storniert wurde */

  /*--------------------------------------------------------------------------*/

  public static TAB_VPRSPEZRESCODE = 3036 /* Spezielle Reservationshinweise (bitweise kombinierbar) */
  public static CDVPRSR_KEINE = 0    /* NULL=keine speziellen Reservationshinweise */
  public static CDVPRSR_UNTERBELEGUNG = 1    /* Unterbelegung ist ok (bewusst gewählt durch Sachbearbeiter; bei Landleistungen) */
  public static CDVPRSR_NICHT_ALLE = 2    /* Obligatorische Leistungen müssen nicht von allen oder nicht alle gleich gebucht werden (durch SB gewählt; z.B. bei oblig. Nebenleistungen) */
  public static CDVPRSR_KONT_UEBERBUCHUNG = 4    /* Kontingentsüberbuchung ok (nur bei zusätzlichen Kontingenten vorgesehen) */
  public static CDVPRSR_NICHT_IN_DSABREISE = 32   /* Leistung wird bei der Bestimmung des Dossierabreisedatums nicht berücksichtigt */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSPA_ERINNERUNGSCODE = 3037 /* Art der Erinnerung, die als nächstes aussteht (3200+Code ist auch in Codeart 5038 vorhanden) */
  public static CDDSPA_ERI_KEINE_VON = 0    /* Erster für keine Erinnerung reservierter Code */
  public static CDDSPA_ERI_KEINE = 0    /* (NULL)=Z.Z. keine Erinnerung vorgesehen oder möglich */
  public static CDDSPA_ERI_KEINE_ERWUENSCHT = 1    /* Erinnerungsfunktion manuell ausgeschaltet, evt. nur bis zu einem bestimmten  Zeitpunkt */
  public static CDDSPA_ERI_ERI_DAT_OBLIG_VON = 2    /* Ab diesem Code ist das Datum der nächsten Erinnerung obligatorisch */
  public static CDDSPA_ERI_KEINE_BIS = 9    /* Letzter für keine Erinnerung reservierter Code */
  public static CDDSPA_ERI_VERBUCHDAT_VON = 20   /* Erster Code  für vom Verbuchungsdatum der ersten nicht stornierten Rechnung abhängige Erinnerung */
  public static CDDSPA_ERI_FRAGEBOGEN1 = 21   /* Erste Erinnerung zum Ausfüllen des Fragebogens */
  public static CDDSPA_ERI_FRAGEBOGEN2 = 22   /* Zweite Erinnerung zum Ausfüllen des Fragebogens */
  public static CDDSPA_ERI_VERBUCHDAT_BIS = 39   /* Letzter Code für vom Verbuchungsdatum abhängige Erinnerung */
  public static CDDSPA_ERI_ABREISEDAT_VON = 50   /* Erster Code für vom Abreisedatum abhängige Erinnerung */
  public static CDDSPA_ERI_APPINFO = 51   /* Informationen zum Downloaden der mobilen App */
  public static CDDSPA_ERI_ABREISEDAT_BIS = 69   /* Letzter Code für vom Abreisedatum abhängige Erinnerung */
  public static CDDSPA_ERI_RUECKREISE_VON = 80   /* Erster Code für vom Rückreisedatum abhängige Erinnerung */
  public static CDDSPA_ERI_BEWERTUNG1 = 81   /* Erste Erinnerung zum Ausfüllen des Kundenbewertungsformulars */
  public static CDDSPA_ERI_BEWERTUNG2 = 82   /* Zweite Erinnerung zum Ausfüllen des Kundenbewertungsformulars */
  public static CDDSPA_ERI_RUECKREISE_BIS = 99   /* Letzter Code für vom Rückreisedatum abhängige Erinnerung */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSEPSTATUS = 3038 /* Zustand der Einstandspreise */
  public static CDDSEP_KEIN = 0    /* Kein Einstandspreis erfasst */
  public static CDDSEP_UNVOLLST = 1    /* EP teilweise erfasst */
  public static CDDSEP_AUTOM = 20   /* EP automatisch erfasst */
  public static CDDSEP_GESPERRT_VON = 21   /* Ab hier sind die Einstandspreise teilweise oder ganz vor versehentlichen Veränderungen geschützt */
  public static CDDSEP_ERFASST = 21   /* EP-Erfassung vollständig (durch Benutzer bestätigt), aber noch nichts verrechnet */
  public static CDDSEP_TEIL_VERRECHNET = 22   /* Teilweise verrechnet */
  public static CDDSEP_OK = 70   /* alle nötigen Schritte vorgenommen */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSPRUEFSTATUS = 3039 /* Gibt an, ob das Dossier noch speziell geprüft werden muss */
  public static CDDSPS_KEINE = 0    /* NULL=keine Prüfung nötig */
  public static CDDSPS_OFFERTE = 11   /* Offerte muss noch geprüft werden */
  public static CDDSPS_NACHRG_PRUEFEN_VON = 30   /* Erster für nach der Rechnungsverbuchung noch zu überprüfende Dossiers reservierter Code */
  public static CDDSPS_INTERNET = 30   /* Internetbuchung prüfen */
  public static CDDSPS_NACHRG_PRUEFEN_BIS = 39   /* Letzter für nach der Rechnungsverbuchung noch zu überprüfende Dossiers reservierter Code */
  public static CDDSPS_OK = 70   /* Alle Prüfungen durchgeführt */

  /*--------------------------------------------------------------------------*/

  public static TAB_VPRSPEZPREISCODE = 3040 /* Spezialbehandlung bei automatischer Preisberechnung (Codes als 2er-Potenzen aufgebaut) */
  public static CDVPRSP_STD = 0    /* (NULL)=Standardpreisberechnung */
  public static CDVPRSP_KEIN_VP = 1    /* Keinen Verkaufspreis berechnen */
  public static CDVPRSP_KEIN_EP = 2    /* Keinen Einstandspreis berechnen */
  public static CDVPRSP_KEIN_VP_EP = 3    /* Weder Ein- noch Verkaufspreis berechnen */
  public static CDVPRSP_PROD_VP = 4    /* Verkaufspreis immer gem. individuellem Produkt berechnen (unabhängig von Eintrag/Preis auf Std.reise) */
  public static CDVPRSP_PROD_EP = 8    /* Einstandspreis immer gem. individuellem Produkt berechnen (unabhängig von Eintrag/Preis auf Std.reise) */
  public static CDVPRSP_PROD_VP_EP = 12   /* Sowohl Verkaufs- als auch Einstandspreis gem. indivduellem Produkt berechnen */

  /*--------------------------------------------------------------------------*/

  public static TAB_VPREPSTATUS = 3041 /* Zustand des Einstandspreises, sofern er übermittelt werden muss (z.Z. nur bei Kuoni-SAP) */
  public static CDVPREP_KEIN = 0    /* (NULL)=(Noch) keine Übermittlung erforderlich */
  public static CDVPREP_UEBERMITTELBAR_VON = 20   /* Beginn des Bereichs für grundsätzlich übermittelbare Stati (ACHTUNG: Dies können auch bereits übermittelte Daten sein!) */
  public static CDVPREP_ZU_UEBERMITTELN_VON = 20   /* Beginn des Bereichs von Stati, die eine Übermittlung auslösen */
  public static CDVPREP_ERSTUEBERM = 21   /* Erstübermittlung ausstehend */
  public static CDVPREP_UEBERMITTELT_VON = 22   /* Beginn des Bereichs von Stati, die für bereits einmal übermittelte Einstandspreise verwendet werden */
  public static CDVPREP_FOLGEUEBERM = 22   /* Folgeübermittlung ausstehend (es wurde früher schon mal ein EP übermittelt, am Produkt jedoch der Preis oder eine Ticketnr. verändert) */
  public static CDVPREP_ZU_UEBERMITTELN_BIS = 25   /* Ende des Bereichs von Stati, die eine Übermittlung auslösen */
  public static CDVPREP_UEBERMITTELT = 30   /* Der Einstandspreis wurde erfolgreich übermittelt und wurde nicht mehr verändert */
  public static CDVPREP_UEBERMITTELT_BIS = 30   /* Ende des Bereichs von Stati, die für bereits einmal übermittelte Einstandspreise verwendet werden */
  public static CDVPREP_UEBERMITTELBAR_BIS = 30   /* Ende des Bereichs für grundsätzlich übermittelbare Stati */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSGUTSCHEINABGABESTATUS = 3042 /* Zeigt an, ob Gutscheincodes zu einem bestimmten Zeitpunkt geplant sind, bereits abgegeben wurden oder nicht autom. zugeteilt werden dürfen */
  public static CDDSGSAB_KEIN = 0    /* NULL=Es wurde (noch) kein Gutscheincode zur Abgabe festgehalten und auch keiner geplant */
  public static CDDSGSAB_GESPERRT = 1    /* Es dürfen keine Gutscheine automatisch abgegeben werden (manuelle Sperre) */
  public static CDDSGSAB_MIN_VERWENDUNG = 5    /* Erster Eintrag, bei dem zu diesem Dossier in der NUMMERNVERWENDUNG mind. ein Abgabeeintrag (aktiv oder storniert) vorhanden oder geplant ist */
  public static CDDSGSAB_STORNIERT = 9    /* Es sind nur stornierte Gutscheine hinterlegt */
  public static CDDSGSAB_MIN_PRUEFUNG = 10   /* Erster für die nächste Prüfung resevierter Status (der Bereich stimmt mit den Codes gem. Codeart 2907 NRABUCHUNGSABGABECODE überein) */
  public static CDDSGSAB_SOFORT = 11   /* Unmittelbar bei Buchung (Kontingentsreservierung) */
  public static CDDSGSAB_BEI_DEFINITIV = 13   /* Bei der definitiven Verbuchung (Weiter-Knopf) */
  public static CDDSGSAB_BEI_RECHNUNG = 15   /* Bei der Rechnungsaufbereitung */
  public static CDDSGSAB_BEI_RECHNUNG_INTERN = 16   /* Bei der Aufbereitung der internen Rechnung */
  public static CDDSGSAB_BEI_ZAHLUNG = 21   /* Spezialauslösung bei Zahlungseingang */
  public static CDDSGSAB_BEI_REISEPROG = 30   /* Bei Aufbereitung des Reiseprogramms */
  public static CDDSGSAB_AUTOM_VOR_ABREISE = 36   /* Automatisch kurz vor Abreise, wenn die Rechnungen ausgeglichen sind */
  public static CDDSGSAB_BEI_UMFRAGEVERSAND = 40   /* Beim Versand einer Umfrage */
  public static CDDSGSAB_MAX_PRUEFUNG = 69   /* Letzter für die nächste Prüfung reservierter Status */
  public static CDDSGSAB_ABGEGEBEN = 80   /* Alle Gutscheine abgegeben */
  public static CDDSGSAB_MAN_ABGEGEBEN = 81   /* Keine automatischen Gutscheine abgegeben, es wurden jedoch individuelle ausgestellt */
  public static CDDSGSAB_MAX_VERWENDUNG = 99   /* Letzter Eintrag, bei dem zu diesem Dossier in der NUMMERNVERWENDUNG mind. ein Abgabeeintrag (aktiv od. storniert) vorhanden oder geplant ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSGUTSCHEINEINLOESESTATUS = 3043 /* Zeit an, ob im Dossier individuelle Codes zur Einlösung hinterlegt wurden */
  public static CDDSGSEIN_KEIN = 0    /* NULL=Es wurde (noch) kein Gutscheincode zur Einlösung festgehalten */
  public static CDDSGSEIN_MIN_VERWENDUNG = 5    /* Erster Eintrag, bei dem zu diesem Dossier in der NUMMERNVERWENDUNG mindestens ein Einlöseeintrag (aktiv oder storniert) vorhanden ist */
  public static CDDSGSEIN_STORNIERT = 9    /* Es sind nur stornierte Gutscheine hinterlegt */
  public static CDDSGSEIN_EINGELOEST = 80   /* Es wurden ein oder mehrere individuelle Gutscheine eingelöst */
  public static CDDSGSEIN_MAX_VERWENDUNG = 99   /* Letzter Eintrag, bei dem zu diesem Dossier in der NUMMERNVERWENDUNG mindestens ein Einlöseeintrag (aktiv oder storniert) vorhanden ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_REISEJUBILAEUM = 3044 /* Jubiläumshinweis für Anzahl Reisen gem. Code, wird bei aktiver Listenoption z.B. auf dem Bordmanifest ausgewiesen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PZLANZAHLUNGSCODE = 3045 /* Anzahlungsart, sofort von Standard abweichend (Codeart wird auch vom Feld PRKANZAHLUNGSCODE verwendet) */
  public static CDPZLA_STANDARD = 0    /* NULL=normal */
  public static CDPZLA_GESAMTBETRAG = 1    /* Sofern der Kunde eine Anzahlung leisten muss, muss er diesen Betrag vollständig anzahlen */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSINDIV_STATUS = 3046 /* Individueller Status für Dossiers */

  /*--------------------------------------------------------------------------*/

  public static TAB_TLNPR_KONTCODE = 3047 /* Art der Kontingentsberücksichtigung, sofern vom Standard gem. Alter abweichend */
  public static CDTKONT_NORMAL = 0    /* (NULL)=Standardeinstellung gem. Alter des Teilnehmers */
  public static CDTKONT_IMMER = 1    /* Es wrid ein Kontingent belegt (auch bei Infants), ein allfälliger Preis gem.Alter berechnet und eine eigene Sitzplatzreservation ist möglich */
  public static CDTKONT_NIE = 9    /* Es wird kein Kontingent belegt (auch bei Erwachsenen nicht), weder Preis berechnet noch Sitzplatzreservation erlaubt */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSABREISECODE = 3048 /* Art des auf dem Dossier gespeicherten Abreisedatums */
  public static CDDSABR_DEFAULT = 0    /* NULL=nicht definiert (z.B. alte oder Auftragsdossiers, Reise ohne Leistungsdatum, Modul nicht aktiv usw.; technisch wie kein Abreisedatum) */
  public static CDDSABR_KEIN = 1    /* kein Abreisedatum, d.h. es gilt für alle Belange das Abreisedatum gem. vermerktem Produkt der Standardreise */
  public static CDDSABR_STR = 3    /* Automatisch Leistungsdatum der Standardreise, das gemäss gebuchten Leistungen (ohne NL und Leistungen aus Fremd-STR) am nächsten liegt */
  public static CDDSABR_MANUELL_VON = 10   /* Erstes für manuell bestimmte Abreisedaten reservierter Code */
  public static CDDSABR_MANUELL_KEINES = 10   /* Es wurde manuell bestimmt, dass kein Abreisedatum gespeichert wird (d.h. es gilt für alle Belange das Abreisedatum gem. vermerktem Produkt) */
  public static CDDSABR_MANUELL_STR = 13   /* Manuell erfasstes Datum, das einem Leistungsdatum der Standardreise entspricht */
  public static CDDSABR_MANUELL = 19   /* Manuell erfasstes, frei bestimmtes Datum (muss nicht in der Standardreise vorhanden sein) */
  public static CDDSABR_MANUELL_BIS = 19   /* Letzter für manuell bestimmte Abreisedaten reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_RGABLAUFSTATUS = 3049 /* Status dieser Rechnung beim entsprechenden Ablauf (z.Z. nur Provisionsabrechnung) */
  public static CDRGABLS_KEIN = 0    /* NULL=(noch) kein Ablauf erfolgt */
  public static CDRGABLS_MIN_GESPERRT = 10   /* Erster für gesperrte Werte reservierter Status (RGABLAUFNR_BUCHUNG und RGABLAUFNR_STORNO sind NULL) */
  public static CDRGABLS_KEIN_GESPERRT1 = 11   /* Von Hand für 1x gesperrt */
  public static CDRGABLS_KEIN_GESPERRT_FIX = 13   /* Von Hand permanent gesperrt */
  public static CDRGABLS_MAX_GESPERRT = 19   /* Letzter für gesperrte Werte reservierter Status */
  public static CDRGABLS_MIN_BUCHUNG_ODER_STORNO = 20   /* Erster Status, beim dem der Buchungs- und/oder Stornoablauf angestossen und/oder durchgeführt wurde (RGABLAUFNR_BUCHUNG ausgefüllt) */
  public static CDRGABLS_MIN_NUR_BUCHUNG = 20   /* Erster Status, bei dem der Buchungsablauf angestossen oder bereits durchgeführt wurde (RGABLAUFNR_BUCHUNG ausgefüllt), aber nicht der Storno */
  public static CDRGABLS_BUCHUNG_PENDENT = 21   /* Buchungsablauf pendent */
  public static CDRGABLS_BUCHUNG_OK = 25   /* Buchungsablauf durchgeführt */
  public static CDRGABLS_BUCHUNG_ALT = 28   /* Alte Rechnung (kein Abrechnungsdossier, Ablaufnr. Buchung -1) */
  public static CDRGABLS_MIN_BUCHUNG_GESPERRT = 30   /* Erster Status mit abgeschlossenem Buchungsablauf, der für Storni gesperrt ist */
  public static CDRGABLS_BUCHUNG_GESPERRT1 = 31   /* Buchungsablauf durchgeführt, von Hand 1x für Stornoablauf gesperrt */
  public static CDRGABLS_BUCHUNG_GESPERRT_FIX = 33   /* Buchungsablauf durchgeführt, von Hand permanent vom Stornoablauf gesperrt */
  public static CDRGABLS_MAX_BUCHUNG_GESPERRT = 39   /* Letzter Status mit abgeschlossenem Buchungsablauf, der für Storni gesperrt ist */
  public static CDRGABLS_MAX__NUR_BUCHUNG = 39   /* Letzter Status, bei dem nur der Buchungsablauf angestossen oder bereits durchgeführt wurde */
  public static CDRGABLS_MIN_STORNO = 40   /* Erster Status, bei dem der Buchungsablauf abgeschlossen und der Stornoablauf angestossen oder bereits durchgeführt wurde (RGABLAUFNR_STORNO) */
  public static CDRGABLS_STORNO_PENDENT = 41   /* Stornoablauf pendent */
  public static CDRGABLS_STORNO_OK = 45   /* Stornoablauf durchgeführt */
  public static CDRGABLS_STORNO_ALT = 48   /* Alte stornierte Rechnung (kein Abrechnungsdossier, Ablaufnr. Buchung +Storno -1) */
  public static CDRGABLS_BUCHUNG_STORNO_GLEICH = 51   /* Buchung und Storno sind im gleichen Zeitraum erfolgt, die Rechnung erscheint nicht auf der Abrechnung */
  public static CDRGABLS_MAX_STORNO = 59   /* Letzter Status, bei dem sowohl Buchungs- als auch Stornoablauf vorhanden sind */
  public static CDRGABLS_MAX_BUCHUNG_ODER_STORNO = 59   /* Letzter Status, beim dem der Buchungs- und/oder Stornoablauf angestossen und/oder durchgeführt wurde */
  public static CDRGABLS_MIN_ABRECHNUNG = 80   /* Erster für Abrechnungen reservierter Bereich */
  public static CDRGABLS_ABRECHNUNG = 80   /* Abrechnung */
  public static CDRGABLS_SAMMELABRECHNUNG = 81   /* Sammelabrechnung */
  public static CDRGABLS_MAX_ABRECHNUNG = 89   /* Letzter für Abrechnungen reservierter Bereich */

  /*--------------------------------------------------------------------------*/

  public static TAB_PZLMWSTTEXTCODE = 3050 /* Individuelle Bezeichnungen für die MWST-Arten (s. Codeart 3021 für die Codedefines und vordefinierte Texte) */

  /*--------------------------------------------------------------------------*/

  public static TAB_RGMARGENCODE = 3051 /* Zustand der Margenbesteuerung */
  public static CDRGM_KEINE = 0    /* NULL=keine Margenbesteuerung vorgesehen */
  public static CDRGM_PENDENT = 1    /* Margenbesteuerung oder deren Aktualisierung ausstehend */
  public static CDRGM_VERBUCHT = 2    /* Marge verbucht */
  public static CDRGM_BEREINIGEN = 7    /* Marge komplett neu berechnen und bei Fehlern vorgängige Buchungen - egal von welchem WVJ - stornieren und aktuelles Total einbuchen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PLZ_AUFTRAGSMANDANT = 3052 /* Zuteilung von PLZ zu Auftragsmandanten bei automatisch erstellten Dossiers (CDKURZBEZ=2-stellige Mandantennr; CDBEZ=PLZvon-PLZbis) */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSPA_APPSTATUS = 3053 /* Aktualisierungsstand einer mit dem Dossier verknüpften App (bzw. dem Übermittlungsserver) */
  public static CDDSPA_APP_KEINE = 0    /* NULL=Nicht in App und auch nicht vorgesehen */
  public static CDDSPA_APP_MIN_PENDENT = 10   /* Erster für zu übermittelnde Dossiers reservierter Status */
  public static CDDSPA_APP_NEU = 10   /* Erstübermittlung ausstehend */
  public static CDDSPA_APP_VERAENDERT = 11   /* Daten verändert/storniert, App-Aktualisierung noch ausstehend */
  public static CDDSPA_APP_MAX_PENDENT = 19   /* Letzter für zu übermittelnde Dossiers reservierter Status */
  public static CDDSPA_APP_MIN_OK = 70   /* Erster für korrekt übermittelte Dossiers reservierter Status */
  public static CDDSPA_APP_AKTUELL = 70   /* Daten in App aktuell */
  public static CDDSPA_APP_GELOESCHT = 90   /* Dossier gelöscht und Info an App übermittelt */
  public static CDDSPA_APP_MAX_OK = 99   /* Letzter für korrekt übermittelte Dossiers reservierter Status */

  /*--------------------------------------------------------------------------*/

  public static TAB_RGBEILAGENCODE = 3054 /* Art der Beilagenbehandlung, Codes > 0 sind als Bitmap kombinierbar */
  public static CDRGBL_RECHNUNG = 1    /* Beilage für Rechnungen (RGCODE 1+2, d.h. inkl. Ersatzrechnungen, jedoch ohne die anderen Rechnungs- und Gutschriftenarten) */
  public static CDRGBL_OFFERTE = 2    /* Beilage für Offerten (RGCODE 80+87, d.h. inkl. TO-Offerten) */
  public static CDRGBL_REISEPROG = 16   /* Beilage für Reiseprogramme */
  public static CDRGBL_VOUCHER = 32   /* Beilage für Voucher */
  public static CDRGBL_REQUEST = 64   /* Beilage für Requests (jedoch nicht für Buchungsbestätigungen) */
  public static CDRGBL_KDAPP = 1024 /* Beilage in der Kunden-App */

  /*--------------------------------------------------------------------------*/

  public static TAB_VPRIBEZCODE = 3055 /* Textauswahl für die Bezeichnung von Vermerkten Produktinfos */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSMARGENPRUEFSTATUS = 3056 /* Gibt an, ob/wie die Marge gebprüft werden soll bzw. wurde */
  public static CDDSMPS_DEFAULT = 0    /* Standard (prüfen falls nötig) */
  public static CDDSMPS_MIN_MAN_OK = 80   /* manuell ok gesetzte Codes */
  public static CDDSMPS_OK = 89   /* ok (manuell gesetzt, keine automatische Prüfung durchführen, auch nicht bei nachträglichen Änderungen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSPA_MARKETINGSTATUS = 3057 /* Art wie Marketing-Versände bezogen auf die Buchung aufbereitet werden dürfen */
  public static CDDSPA_MKT_NICHT_DEFINIERT = 0    /* Standard */
  public static CDDSPA_MKT_KEIN = 1    /* nicht erwünscht */

  /*--------------------------------------------------------------------------*/

  public static TAB_DSABL_CODE = 3058 /* Art der Dossierablage */
  public static CDDSABL_INDIV = 0    /* individuelle Dossierablage */

  /*--------------------------------------------------------------------------*/

  public static TAB_TLNRPR_STATUS = 3059 /* Zustand des Teilnehmers mit diesem Produkt */
  public static CDTLNPRSNICHT_DEFINIERT = 0    /* nicht definiert */
  public static CDTLNPRSEINGESTIEGEN = 50   /* eingestiegen */
  public static CDTLNPRSNO_SHOW = 90   /* no show */

  /*--------------------------------------------------------------------------*/

  public static TAB_VPRI_ZEITCODE = 3060 /* Zeitcode für vermerkte Produktinfos Zeitangaben */
  public static CDVPRIZ_DEFAULT = 0    /* Je nach Produktart, Einstellungen, Vorhandensein eines Fluges und obligatorischer Eingabe (bisheriges Verhalten) */
  public static CDVPRIZ_GEM_FLUG = 1    /* gemäss Flug, es muss ein passender Flug vorhanden sein. Das Zeitfeld bleibt leer. */
  public static CDVPRIZ_MAX_GEM_FLUG_MOEGLICH = 2    /* letzter Code, bei dem die Zeit gem. Flug erlaubt ist */
  public static CDVPRIZ_HANDEINGABE = 8    /* Die Zeit muss von Hand eingegeben werden, ein allfälliger Flug wird nicht berücksichtigt */

  /*--------------------------------------------------------------------------*/

  public static TAB____DOSSIER_BEREICHE___ = 3100 /* === Beginn der Dossier-Codearten, welche für Codeartenbereiche verwendet werden === */

  /*--------------------------------------------------------------------------*/

  public static TAB_TLNZUSKRIT_VON = 3101 /* Erstes für Teilnehmer reserviertes Zusatzkriterium */
  public static CDZKR_FRBO_BEDINGUNG_OK_VON = 1    /* Bei Fragebogen (gilt auch für Pers+Prod.Zus.krit): Beginn des Codebereichs, bei dem, wenn ausgewählt, davon abhängige Fragen gestellt werden */
  public static CDZKR_FRBO_BEDINGUNG_OK_BIS = 8999 /* Bei Fragebogen: Ende des Codebereichs, der abhängige Fragen aktiviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_TLNZUSKRIT_BIS = 3199 /* Letztes für Teilnehmer reserviertes Zusatzkritierium */

  /*--------------------------------------------------------------------------*/

  public static TAB_KONTOCODE = 3500 /* Art des Kontos */
  public static CDKTO_KEIN_VORSCHLAG = 0
  public static CDKTO_DEBITOR = 1    /* Debitor */
  public static CDKTO_KREDITOR = 2    /* Kreditor */
  public static CDKTO_BILANZ = 11   /* Bilanzkonto */
  public static CDKTO_ERFOLG = 12   /* Erfolgskonto */

  /*--------------------------------------------------------------------------*/

  public static TAB____BUCHHALTUNG___ = 3500 /* === Beginn der Buchhaltungs-Codearten === */

  /*--------------------------------------------------------------------------*/

  public static TAB_BUACODE = 3501 /* Interne Buchungsart */
  public static CDBUA_RECHNUNG = 0    /* Rechnung oder Gutschrift */
  public static CDBUA_ZAHLUNG = 10   /* Ein- oder Auszahlung */
  public static CDBUA_KURSDIFF = 50   /* Ausgleich einer Kursdifferenz */
  public static CDBUA_MARGE = 80   /* Margenverbuchung */

  /*--------------------------------------------------------------------------*/

  public static TAB_BUABLAUFCODE1 = 3502 /* Buchung automatisch erstellt durch Ablauf (teilweise mit Codeart 3029 [SPOCODE] übereinstimmend; Code mit Codeart 3503 abstimmen) */
  public static CDBUABL1_MAN = 0    /* manuell erstellt (wird als NULL abgespeichert) */
  public static CDBUABL1_LSV = 1    /* Lastschriftverfahren */
  public static CDBUABL1_INT_VERR = 3    /* Interne Verrechnung während BDD-Lauf */
  public static CDBUABL1_BDD = 5    /* BDD-/LSV+-Zahlung (enspricht SPOCODE) */
  public static CDBUABL1_CHAPP = 30   /* Chauffeur-App-Buchung */
  public static CDBUABL1_FREMDSYSTEM = 90   /* Die Zahlung kam von einem Fremdsystem z.B. über eine API-Schnittstelle herein (dazugehörige ID des Fremdsystems in BUBEM) */

  /*--------------------------------------------------------------------------*/

  public static TAB_BUABLAUFCODE2 = 3503 /* Beleg aufgrund der Buchung automatisch erstellt durch Ablauf (Code muss mit Codeart 3502 abgestimmt sein, sofern Nummernmaster benötigt) */
  public static CDBUABL2_KEIN = 0    /* kein automatischer Beleg (wird als NULL abgespeichert) */
  public static CDBUABL2_VASR = 50   /* VASR-Check */
  public static CDBUABL2_RUECKZLG = 60   /* Rückzahlung via Bank/Post */
  public static CDBUABL2_REISEGUTSCHEIN = 70   /* Reisegutschein (teilweise) eingelöst */
  public static CDBUABL2_SAFERPAY = 80   /* Saferpay-Abrechnung */

  /*--------------------------------------------------------------------------*/

  public static TAB_WVJBUCODE = 3504 /* Art der Weiterverrechnungsjournalbuchung */
  public static CDWVJ_RGBRUTTO = 1    /* Bruttobetrag der Rechnung inkl. Kommission ohne MWST-pflichtigem Anteil */
  public static CDWVJ_RGMWST = 2    /* Mehrwertsteuerpflichtiger Anteil der Rechnung */
  public static CDWVJ_RGKOMM = 3    /* Kommissionsanteil der Rechnung */
  public static CDWVJ_RGBRUTTO_O_MWST = 4    /* Bruttobetrag der Rechnung inkl. Kommission ohne abzulieferndem MWST-Betrag */
  public static CDWVJ_RGMWSTB = 5    /* Abzuliefernder Mehrwertsteuerbetrag der Rechnung */
  public static CDWVJ_ZAHL = 11   /* Zahlung */
  public static CDWVJ_MARGE = 80   /* Margenverbuchung */

  /*--------------------------------------------------------------------------*/

  public static TAB_POSTATCODE = 3505 /* Code für Postenstatistiken */
  public static CDPOSTAT_MANUELL = 0    /* Posten wurde manuell erstellt */
  public static CDPOSTAT_STANDARD = 1    /* Posten aus Standarddossier */
  public static CDPOSTAT_AUFTRAG = 10   /* Posten auf Auftragsdossier */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZAHLUNGSMITTEL = 3506 /* Art der intern oder extern unterschiedenen und unterstützten (Saferpay-)Zahlungsmittel */

  /*--------------------------------------------------------------------------*/

  public static TAB_BUABEZ = 3507 /* Fakultative sprachabhängige Bezeichnungen der Buchungsarten (CDKURZBEZ entspricht der BUART) */

  /*--------------------------------------------------------------------------*/

  public static TAB_MAHNSTUFENCODE = 3508 /* Mögliche Mahnstufen (korrespondiert mit RGARTCODE 50+MAHNSTUFENCODE) */
  public static CDMST_KEINE = 0    /* Posten wurde noch nie gemahnt (wenigstens nicht über die automatische Mahnung) */
  public static CDMST_ANZLG_VON = 1    /* Erste für Anzahlungen reservierte Mahnstufe */
  public static CDMST_ANZLG1 = 1    /* 1. Anzahlungsmahnung */
  public static CDMST_ANZLG2 = 2    /* 2. Anzahlungsmahnung */
  public static CDMST_ANZLG3 = 3    /* 3. Anzahlungsmahnung */
  public static CDMST_ANZLG_BIS = 3    /* Letzte für Anzahlungen reservierte Mahnstufe */
  public static CDMST_RESTZLG_VON = 4    /* Erste für Restzahlungen reservierte Mahnstufe */
  public static CDMST_RESTZLG1 = 4    /* 1. Restzahlungsmahnung */
  public static CDMST_RESTZLG2 = 5    /* 2. Restzahlungsmahnung */
  public static CDMST_RESTZLG3 = 6    /* 3. Restzahlungsmahnung */
  public static CDMST_RESTZLG4 = 7    /* 4. Restzahlungsmahnung */
  public static CDMST_RESTZLG5 = 8    /* 5. Restzahlungsmahnung */
  public static CDMST_RESTZLG_BIS = 8    /* Letzte für Restzahlungen reservierte Mahnstufe */
  public static CDMST_GUTSCHRIFT = 9    /* Für Guthaben des Kunden  reservierte "Mahnstufe" */

  /*--------------------------------------------------------------------------*/

  public static TAB_BUART_LISTE = 3509 /* Verknüpfung der Buchungsarten (BUART) für bestimmte Funktionen */
  public static CDBUAL_CHAPP_ZAHLUNG_VON = 100  /* Erster für mögliche Buchungsarten für Kundenzahlungen in der Chauffeur-App reservierter Code */
  public static CDBUAL_CHAPP_ZAHLUNG_BIS = 109  /* Letzter für mögliche Buchungsarten für Kundenzahlungen in der Chauffeur-App reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_KONTO_LISTE = 3510 /* Liste unterschiedlicher Konten */
  public static CDKTOL_MARGE = 1    /* Liste aller Umsatzkonten, für die die Margenbesteuerung gilt (mehrere Konten durch Kommas getrennt) */
  public static CDKTOL_RG_SPEZ_AUSSCHLUSS = 6    /* Konten, die bei der Spezialprogrammierung bei Codeart 3010, Option "U" nicht verändert werden dürfen */
  public static CDKTOL_PROV_INLAND = 10   /* Provisionskonto für Inland-Reisebüros bzw. -Buchungsstellen */
  public static CDKTOL_PROV_EU = 11   /* Provisionskonto für Reisebüros/Buchungsstellen in der EU (gem. Codeart 27, Code 10) */
  public static CDKTOL_PROV_DRITTLAND = 15   /* Provisionskonto für Reisebüros/Buchungsstellen, die sich ausserhalb der EU befinden */

  /*--------------------------------------------------------------------------*/

  public static TAB_BUART_LISTE2 = 3511 /* Liste unterschiedlicher Buchungsarten */
  public static CDBUAL2_ZLG_BESTAETIGUNG = 1    /* Alle Buchungsarten, die bei manuell eingebuchten Zahlungen eine automatische Bestätigung auslösen */

  /*--------------------------------------------------------------------------*/

  public static TAB_KRCODE = 3512 /* Art der Kreditorenrechnung */
  public static CDKR_DOSSIER = 1    /* Dossierspezifische Rechnung (beinhaltet nur Preiszeilen eines Dossiers) */
  public static CDKR_PERIODE = 2    /* Dossier übergreifende Rechnung (kann Preiszeilen mehrerer Dossiers umfassen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_KRSTATUS = 3513 /* Zustand der Kreditorenrechnung */
  public static CDKRSIN_ARBEIT = 1    /* in Arbeit (noch unvollständig) */
  public static CDKRSPENDENT = 10   /* pendent (Preiszeilen zugewiesen, aber noch nicht verrechnet) */
  public static CDKRSPENDENT_VOLLST = 20   /* pendent vollständig (Preiszeilen vollständig zugewiesen aber noch nicht oder nur z.T. verrechnet) */
  public static CDKRSOK = 70   /* ok, verrechnet (keine Änderungen an Preiszeilen mehr möglich) */
  public static CDKRSSTORNIERT = 90   /* storniert (keine Preiszeilen mehr verbunden) */

  /*--------------------------------------------------------------------------*/

  public static TAB_KRPERIODENCODE = 3514 /* Art der Periode */
  public static CDKRPERKEINE = 0    /* keine (nur bei Dossier spezifischen Rechnungen erlaubt) */
  public static CDKRPERLEIST_BEGINN = 1    /* Leistungsbeginn */
  public static CDKRPERLEIST_ENDE = 2    /* Leistungsende */

  /*--------------------------------------------------------------------------*/

  public static TAB____BUCHHALTUNG_BEREICHE___ = 3600 /* === Beginn der Buchhaltungs-Codearten, welche für Codeartenbereiche verwendet werden === */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZAHLDATEI = 3600 /* Liste aller Dateiformate, die als Einzahlung eingelesen werden koennen (Codeart 3600+Code beschreibt dieses Format). Es sind die Codes 1-19 */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZAHLRECORDS = 3601 /* Beschreibt die Zahlrecords einer Zahldatei. Die Codes gelten fuer alle Codearten 3601-3619. */
  public static CDZR_FILEART = 1    /* F=Ascii-Datei mit festen Feldlaengen */
  public static CDZR_DATEINAME = 10   /* Pfad und Dateiname (evt. mit Wildcards) */
  public static CDZR_RECLG = 11   /* (max.) Recordlaenge */
  public static CDZR_RECENDE = 12   /* C=mit CR/LF am Zeilenende; (leer)=der naechste Record beginnt unmittelbar nachher */
  public static CDZR_RECARTPOS = 20   /* Startposition der Recordart im Record (1. Stelle = Position 1) */
  public static CDZR_RECARTLG = 21   /* Laenge des Recordartfeldes */
  public static CDZR_UEBERLESEN = 22   /* J=unbekannte Records ueberlesen, N=Fehlermeldung bei unbekanntem Record */
  public static CDZR_DATFORMAT = 50   /* T=TTMM[JJ]JJ, J=[JJ]JJMMTT, .=TT.MM.[JJ]JJ */
  public static CDZR_DATLG = 51   /* Laenge der Datumfelder */
  public static CDZR_BETRFORMAT = 60   /* 1=Betrag wird mit Kommastellen ausgewiesen, 100=Betrag wird in Rappen/Cents ausgewiesen */
  public static CDZR_HD_RECART = 100  /* Recordart(en) des Headers */
  public static CDZR_HD_BUCHDATPOS = 110  /* Position des generellen Buchungsdatum (0=keines) */
  public static CDZR_HD_VERARBDATPOS = 120  /* Position des generellen Verarbeitungsdatums (0=keines) */
  public static CDZR_HD_ZUSINFOPOS = 190  /* Startposition des Zusatzinfo-Feldes */
  public static CDZR_HD_ZUSINFOLG = 191  /* Max. Laenge des Zusatzinfofeldes */
  public static CDZR_DET_RECART = 200  /* Recordarten des Detailrecords */
  public static CDZR_DET_STORECART = 201  /* Recordarten der Storno-Detailrecords */
  public static CDZR_DET_BUCHDATPOS = 210  /* Startposition des Buchungsdatums (0=Buchungsdatum gem. Code 110 verwenden) */
  public static CDZR_DET_VERARBDATPOS = 220  /* Startposition des Verarbeitungsdatums (0=kein individuelles Verarbeitungsdatum) */
  public static CDZR_DET_BETRPOS = 230  /* Startposition des Betragsfeldes */
  public static CDZR_DET_BETRLG = 231  /* Laenge des Betragsfeldes */
  public static CDZR_DET_STOBETRPOS = 240  /* Startposition des Stornobetrag-Feldes (0=keines) */
  public static CDZR_DET_STOBETRLG = 241  /* Laenge des Stornobetragfeldes */
  public static CDZR_DET_REFPOS = 250  /* Startposition unserer Referenznummer (4 Stellen Mandant, 6 Dossiernr, fak. 1 Pruefziffer bzw. Anzahlungscode) */
  public static CDZR_DET_REFLG = 251  /* Laenge der Referenznummer (12 oder 13) */
  public static CDZR_DET_HBKTOPOS = 260  /* Startposition des Hauptbuchkontos (0=gem. Buchungsart) */
  public static CDZR_DET_HBKTOLG = 261  /* Laenge des Hauptbuchkontos (max. 8 Stellen) */
  public static CDZR_DET_BUART = 262  /* Zu verwendende Buchungsart */
  public static CDZR_DET_ZUSINFOPOS = 290  /* Startposition der Zusatzinfos */
  public static CDZR_DET_ZUSINFOLG = 291  /* Laenge der Zusatzinformationen */
  public static CDZR_INFO = 300  /* Dazuaddieren, wenn statt des normalen Detailrecords der Inforecord verwendet wird (Codes 5xx) */
  public static CDZR_INFO_RECART = 500  /* Beschreibung des Informationsrecords ohne Verbuchung (analog Codes 2xx) */
  public static CDZR_TOT_RECART = 800  /* Recordarten des Totalrecords */
  public static CDZR_TOT_BETRPOS = 830  /* Startposition der Kontrollsumme */
  public static CDZR_TOT_BETRLG = 831  /* Laenge des Kontrollsummenfeldes */
  public static CDZR_TOT_TOLERANZ = 832  /* Abweichungstoleranz +/- */
  public static CDZR_TOT_ZUSINFOPOS = 890  /* Startposition der Zusatzinformationen */
  public static CDZR_TOT_ZUSINFOLG = 891  /* Laenge der Zusatzinformationen */
  public static CDZR_END_RECART = 900  /* Recordart des Schlussrecords */
  public static CDZR_END_ZUSINFOPOS = 990  /* Startposition der Zusatzinformationen */
  public static CDZR_END_ZUSINFOLG = 991  /* Laenge der Zusatzinformationen */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZAHLRECORDS_ENDE = 3619 /* Letzte für Zahlrecords reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB____DISPOSITION___ = 3800 /* === Beginn der Dispositions-Codearten === */

  /*--------------------------------------------------------------------------*/

  public static TAB_DICODE = 3800 /* Art des Dispositionseintrags */
  public static CDDI_PAUSCHAL = 1    /* Pauschalreise (Querverweis von einer oder zwei Transportbewegungen) */
  public static CDDI_AUFTRAG = 2    /* Auftragsfahrt (Querverweis von der Rechnung) */
  public static CDDI_REISELEITER = 4    /* separate Reiseleiterdisposition (Querverweis vom Leistungsdatum der Standardreise) */
  public static CDDI_MANUELL_VON = 5    /* Erster für manuell in der Dispo erfasste Einträge reservierter Code */
  public static CDDI_MANUELL_ARBEIT_VON = 5    /* Erster für manuell in der Dispo erfasste Arbeitseinsätze reservierter Code */
  public static CDDI_SPEZIAL = 5    /* Spezialfahrten (z.B. Zubringer, Überfahrten) */
  public static CDDI_INTERN = 7    /* interne Arbeiten ohne Fahrzeug (z.B. Schulung, Instruktionen, Garage, Bereitschaft) */
  public static CDDI_MANUELL_ARBEIT_BIS = 8    /* Letzter für manuell in der Dispo erfasste Arbeitseinsätze reservierter Code */
  public static CDDI_AUSFALL = 9    /* Ausfallzeit (Service, Ferien usw.) */
  public static CDDI_MANUELL_BIS = 9    /* Letzter für manuell in der Dispo erfasste Einträge reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_DISTATUS = 3801 /* Zustand der Disposition */
  public static CDDIS_OHNE_DISPO = 0    /* Dieser Dispositionseintrag wird überhaupt nicht disponiert (Pseudo-Dispoteil) */
  public static CDDIS_OFFERTE = 1    /* Offerte als Kopiervorlage für Auftragsbestätigungen */
  public static CDDIS_IN_DISPO = 9    /* Ab diesem Wert können die Daten in der Dispo angeschaut und bearbeitet werden */
  public static CDDIS_OFFERTE_IN_DISPO = 9    /* Offerte, die in der Dispo erscheinen muss, bis der Auftrag bestätigt oder die Offerte gelöscht wird */
  public static CDDIS_OPTION = 10   /* Noch keine Auftragsbestätigung erfolgt */
  public static CDDIS_UNVOLLST = 11   /* Eintrag ist noch unvollständig */
  public static CDDIS_VOLLSTAENDIG = 12   /* Eintrag ist vollständig */
  public static CDDIS_FA_GEDRUCKT = 15   /* Fahrauftrag gedruckt */
  public static CDDIS_ANNULLIERT = 19   /* Eintrag annulliert */

  /*--------------------------------------------------------------------------*/

  public static TAB_DIPERSCODE = 3802 /* Funktion einer Person bei einem Auftrag (keine Überschneidungen mit Codeart 3803 erlaubt) */
  public static CDDIP_FAHRER1 = 1    /* 1. Chauffeur */
  public static CDDIP_FAHRER2 = 2    /* 2. Chauffeur */
  public static CDDIP_REISELEITUNG = 5    /* Reiseleiter */

  /*--------------------------------------------------------------------------*/

  public static TAB_DIPERSCODE2 = 3803 /* Grund der Absenz (keine Überschneidungen mit Codeart 3802 erlaubt) */
  public static CDDIP_LINIE = 10   /* Einsatz auf Linienbus */
  public static CDDIP_FREMD = 11   /* Fahrt für Fremdunternehmen */
  public static CDDIP_AUSBILDUNG = 20   /* Ausbildung */
  public static CDDIP_FERIEN = 30   /* Ferien */
  public static CDDIP_KRANK = 31   /* Krankheit oder Unfall */
  public static CDDIP_MILITAER = 32   /* Militär, Zivildienst oder -schutz */
  public static CDDIP_UEBR_BEZAHLT = 39   /* Andere bezahlte Absenz */
  public static CDDIP_UNBEZ_URLAUB = 40   /* unbezahlter Urlaub */
  public static CDDIP_UEBR_ABSENZ = 49   /* andere unbezahlte Absenz */
  public static CDDIP_FREIZEIT = 90   /* freie Zeit */

  /*--------------------------------------------------------------------------*/

  public static TAB_DIINDIV_STATUS = 3804 /* Individueller Status für einen Dispositionsteil (z.B. Auftrag im Büro, Auftrag für Chauffeur verfügbar) */

  /*--------------------------------------------------------------------------*/

  public static TAB_DIPERSINDIV_STATUS = 3805 /* individueller Status (vordef. Bereiche) für eine Person (z.B. wurde informiert, hat bestätigt usw.) */
  public static CDDIPI_MIN_PENDENT = 0    /* Erster für Auftrag in Bearbeitung reservierter Status */
  public static CDDIPI_MAX_PENDENT = 9    /* Letzter für Auftrag in Bearbeitung reservierter Status */
  public static CDDIPI_MIN_DEFINITIV = 10   /* Erster Status für definitiv zugeteilte Personen */
  public static CDDIPI_MAX_DEFINITIV = 19   /* Letzter Status für definitiv zugeteilte Personen */
  public static CDDIPI_MIN_BESTAETIGT = 20   /* Erster Status für vom Chauffeur bestätigten Auftrag */
  public static CDDIPI_BESTAETIGT_APP = 25   /* Bestätigt via Chauffeur-App */
  public static CDDIPI_MAX_BESTAETIGT = 29   /* Letzter Status für vom Chauffeur bestätigter Auftrag */
  public static CDDIPI_MIN_EINSATZ = 30   /* Erster  für "im Einsatz" reservierter Status */
  public static CDDIPI_EINSATZ_APP = 35   /* Im Einsatz via App bestätigt */
  public static CDDIPI_MAX_EINSATZ = 39   /* Letzter für im Einsatz reservierter Status */

  /*--------------------------------------------------------------------------*/

  public static TAB_DIBALKEN = 3806 /* Beschriftung der Dispobalken (Die Codes 1-9 entsprechen dem DICODE) */
  public static CDDIB_DEFAULT = 0    /* Default-Beschriftung, wenn der individuelle Code gem. DICODE fehlt */
  public static CDDIB_PAUSCHAL = 1
  public static CDDIB_AUFTRAG = 2
  public static CDDIB_REISELEITER = 4
  public static CDDIB_SPEZIAL = 5
  public static CDDIB_INTERN = 7
  public static CDDIB_AUSFALL = 9

  /*--------------------------------------------------------------------------*/

  public static TAB_DIDOK_CODE = 3807 /* Art des Dokuments */
  public static CDDIDOK_FAHRAUFTR = 82   /* Fahrauftrag */
  public static CDDIDOK_INDIV_VON = 101  /* Beginn des für individuelle Beilagen reservierten Bereichs */
  public static CDDIDOK_BEILAGE = 101  /* Allgemeine, individuelle Beilage (alter Define aus Kompatibilitätsgründen) */
  public static CDDIDOK_INDIV_ALLG = 101  /* Allgemeine, individuelle Beilage */
  public static CDDIDOK_INDIV_DISPO = 110  /* Individuelles Dispodokument */
  public static CDDIDOK_INDIV_PROD = 120  /* Individuelles Produktionsdokument */
  public static CDDIDOK_INDIV_BIS = 199  /* Ende des für individuelle Beilagen reservierten Bereichs */
  public static CDDIDOK_NICHT_IN_APP_VON = 900  /* Ab diesem Code werden die Dokumente nicht in die App übermittelt */
  public static CDDIDOK_FAHRAUFTR_DOKUTEIL = 900  /* Dokumententeil für den Fahrauftrag, sofern dieser aus einer externen Quelle (z.B. via API) als RTF-Datei ins Tourdata gespiesen wird */
  public static CDDIDOK_PROG_GEM_ANFRAGE = 910  /* Programm gem. Offertanfrage übers Internet (kann in der Textvearbeitung mit {Dispo.Programm} eingezogen werden) */
  public static CDDIDOK_NICHT_IN_APP_BIS = 999  /* Letzter Code für Dokumente, die nicht die App übermittelt werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_LOHNFUNKID = 3808 /* Lohnfunktion für die Reiseleiterdisposition */

  /*--------------------------------------------------------------------------*/

  public static TAB_DIHINWEIS = 3809 /* Auswahl für den Dispohinweistext, falls dieser keine reine Texteingabe sein soll */

  /*--------------------------------------------------------------------------*/

  public static TAB_DIGERAETE = 3810 /* Fakultative Textvorschläge für das Gerätefeld der Disposition */

  /*--------------------------------------------------------------------------*/

  public static TAB_DIZCODE = 3811 /* Verwendung des Dispositionszubehörs */
  public static CDDIZ_ANHAENGER = 1    /* Anhänger */
  public static CDDIZ_KISTE = 5    /* Kiste */

  /*--------------------------------------------------------------------------*/

  public static TAB_DISPOZUBEHOER = 3812 /* Gibt an, ob das entsprechende Zubehör (z.Z. Anhänger bzw. Kiste) benötigt und wie es verwaltet wird */
  public static CDDIZB_DISPOZUBEHOER = -1   /* Diese Art von Zubehör wird benötigt und muss über die Tabelle Dispositionszubehör disponiert werden */
  public static CDDIZB_NEIN = 0    /* Diese Art von Zubehör wird nicht benötigt */
  public static CDDIZB_JA = 1    /* Diese Art von Zubehör wird benötigt, muss jedoch nicht vom System disponiert werden (alte Einträge oder Modul nicht aktiv) */
  public static CDDIZB_FREMD = 6    /* Diese Art von Zubehör wird benötigt, muss jedoch nicht vom System disponiert werden, da es z.B. durch den Fremdanbieter gestellt wird */

  /*--------------------------------------------------------------------------*/

  public static TAB____INTERNET___ = 4000 /* === Beginn der Codearten, die nur fürs Internet verwendet werden === */

  /*--------------------------------------------------------------------------*/

  public static TAB_REGIONCODE = 4001 /* Regionen für den Reisemarkt */

  /*--------------------------------------------------------------------------*/

  public static TAB_REISEARTCODE = 4002 /* Reisearten für den Reisemarkt */

  /*--------------------------------------------------------------------------*/

  public static TAB_KATEGORIECODE = 4006 /* Zimmerkategorien für den Reisemarkt (Planet Holiday) */

  /*--------------------------------------------------------------------------*/

  public static TAB_KOCODE = 4007 /* Art des Kommentars */
  public static CDKO_OEFFENTLICH = 1    /* Öffentlicher, für alle einsehbarer Kommentar */
  public static CDKO_REISEGRUPPE = 5    /* Nur für Personen einsehbar, die auf der gleichen Reise inkl. Datum gebucht haben */
  public static CDKO_MAX_INTERNET = 7    /* Alle Codes bis und mit diesem stehen im Internet zur Verfügung */
  public static CDKO_VERANSTALTER = 8    /* nur für den Veranstalter sichtbarer Kommentar */

  /*--------------------------------------------------------------------------*/

  public static TAB_KOSTATUS = 4008 /* Zustand des Kommentars */
  public static CDKOS_NICHT_SICHTBAR = 0    /* Im Internet nicht sichtbar, da noch nicht kontrolliert */
  public static CDKOS_MIN_SICHTBAR = 1    /* Erster im Internet sichtbarer Status */
  public static CDKOS_PROVISORISCH = 1    /* Im Internet provisorisch sichtbar, aber noch nicht kontrolliert */
  public static CDKOS_OHNE_KONTROLLE = 3    /* Im Internet ohne Kontrolle sichtbar */
  public static CDKOS_KONTROLLIERT = 5    /* Fürs Internet nach Kontrolle freigeschalten */
  public static CDKOS_MAX_SICHTBAR = 7    /* Letzter im Internet sichtbarer Status */
  public static CDKOS_GELOESCHT = 9    /* Kommentar als gelöscht markiert */

  /*--------------------------------------------------------------------------*/

  public static TAB_KOACODE = 4009 /* Art des Anhangs */
  public static CDKOA_MIN_BILD = 1    /* Erster für Bilddateien reservierter Code */
  public static CDKOA_JPG = 1    /* JPG-Datei */
  public static CDKOA_MAX_BILD = 19   /* Letzter für Bilddateien reservierter Code */
  public static CDKOA_MIN_VIDEO = 20   /* Erster für Videodateien reservierte Code */
  public static CDKOA_FLASH = 20   /* Flash-Datei (FLV) */
  public static CDKOA_MAX_VIDEO = 39   /* Letzer für Videodateien reservierter Code */
  public static CDKOA_MIN_AUDIO = 40   /* Erster für Audiodateien reservierter Code */
  public static CDKOA_MP3 = 40   /* MP3-Datei */
  public static CDKOA_MAX_AUDIO = 59   /* Letzer für Audiodateien reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_KOBEWERTUNGSCODE = 4010 /* Bewertung durch den Kunden */
  public static CDKOBEW_KEINE = 0    /* NULL=keine Bewertung abgegeben */
  public static CDKOBEW_MIN_BEWERTUNG = 1    /* Tiefste "normale" Bewertungsstufe */
  public static CDKOBEW_MAX_BEWERTUNG = 5    /* Höchste "normale" Bewertungsstufe */
  public static CDKOBEW_MIN_SPEZ_EINTRAG = 20   /* Erster speziell markierter Eintrag */
  public static CDKOBEW_REISELEITER = 20   /* Reiseleiter-Kommentar */
  public static CDKOBEW_BILDERSHOW = 21   /* Bilder zu einer Reise */
  public static CDKOBEW_MAX_SPEZ_EINTRAG = 49

  /*--------------------------------------------------------------------------*/

  public static TAB_LINK = 4011 /* Alle externen Links */
  public static CDLINK_GRP_GROESSE = 10   /* Anzahl Links, die in eine Gruppe gehören (1-10=1. Gruppe, 11-20=2. Gruppe usw. z.Z. bis max. 40) */
  public static CDLINK_WICHTIG = 101  /* Wichtiger Link, der oberhalb der anderen im Menu versteckten Links separat angezeigt wird */

  /*--------------------------------------------------------------------------*/

  public static TAB_MYDOSSIERCODE = 4012 /* Mögl. (Zusatz-)Funktionen für Verwaltung v.Dossiers dieser Std.reisen im Internet (bis 8191 Bitmaske) */
  public static CDMYDS_DEFAULT = 0    /* NULL=Standardeinstellung je nach Art der Standardreise */
  public static CDMYDS_KOMMENTAR = 1    /* Gästekommentare zur Reise schreiben und lesen */
  public static CDMYDS_GRUPPE = 2    /* Mails an Teilnehmer der gleichen Gruppe (Abreisedatum) schreiben */
  public static CDMYDS_ENDE_BITMAP = 8191 /* Letzter Code der als Bitmap addiert werden muss (nachfolgende Codes müssen einzeln betrachtet werden) */
  public static CDMYDS_KEINE_REISE = 9000 /* Spezial, keine Reisefunktionen und -begriffe verwenden */
  public static CDMYDS_NICHT_ZEIGEN = 9900 /* Dossiers überhaupt nicht anzeigen */

  /*--------------------------------------------------------------------------*/

  public static TAB____UEBERGREIFEND___ = 5000 /* === Beginn der verwaltungsübergreifenden Codearten, bei denen die Codes in der Regel nicht als Auswahl angeboten werden === */

  /*--------------------------------------------------------------------------*/

  public static TAB_FILE = 5000 /* Physische Filenamen und Kommunikationspfade (s.a. Codeart 5027) */
  public static CDF_EZ_PFAD = 1    /* Defaultpfad für Dateien mit den Einzahlungen zum Einlesen */
  public static CDF_LSV = 2    /* Defaultpfad für LSV-Dateien und SEPA-Rückzahlungen */
  public static CDF_TXB = 3    /* Hauptpfad für Textbausteine */
  public static CDF_RECHNUNG = 4    /* Hauptpfad für Rechnungstexte */
  public static CDF_VESRPROT = 5    /* Dateiname des VESR-Protokolls */
  public static CDF_EZ2_PROT = 6    /* Pfad des Protokolls für die alternative Verarbeitung von Zahlungen (gem. 5009/122) */
  public static CDF_VASRPROT = 7    /* Vasr-Protokolldatei */
  public static CDF_WORKDIR = 8    /* temporäres Arbeitsverzeichnis */
  public static CDF_ADREXP = 9    /* Adressexport-File */
  public static CDF_EMAIL = 10   /* Pfad fuer den Email-Versand */
  public static CDF_TRACE = 11   /* Name des Programm-Tracefiles */
  public static CDF_EZ2_PFAD = 12   /* Pfad, an dem die alternativen Dateien mit den Zahlungen zum Einlesen liegen (gem. 5009/122) */
  public static CDF_PNLCMD = 13   /* Kommando-String für das automatische Versenden von PNL's ueber Email */
  public static CDF_RMSEND = 14   /* Verzeichnis zum Versenden von Dateien an den Reisemarkt */
  public static CDF_SRV_BASIS = 15   /* Laufwerksersatz, wenn das Programm auf dem Server ausgeführt wird */
  public static CDF_WVJ = 16   /* Pfad für die Exportdatei des Weiterverrechnungsjournals */
  public static CDF_LISTENVORLAGEN = 17   /* Hauptpfad für die Listenvorlagen */
  public static CDF_PROTOKOLLE = 18   /* Hauptpfad für Protokolldateien */
  public static CDF_UMS_ABEST = 19   /* Umsatz- und Auftragsbestand-Exportpfad (Access) */
  public static CDF_TWIXTEL = 20   /* Verzeichnis der Twixtel-CD */
  public static CDF_FTPSEND = 21   /* Pfad auf das Verzeichnis mit den FTP-Batchfiles und dem FTP-Programm (fürs Access) */
  public static CDF_FTP_BUCHBARE_REISEN = 22   /* FTP-Server inkl. Dateiname für den Export der buchbaren Reisen (fürs Access) */
  public static CDF_MARGENKONTROLLE = 23   /* Pfad für Exportdateien der Margenkontrolle */
  public static CDF_FTP_DIENSTMELDUNGSEXPORT = 24   /* Pfad (ohne Dateinamen) für den Dienstmeldungsexport im XML-Format (Listenoption -X) */
  public static CDF_DIENSTMELDUNGSEXPORT = 24   /* Pfad (ohne Dateinamen) für den Diensmeldungsexport im CSV-Format (Listenoption -X2) */
  public static CDF_ALT_REISEPROG = 25   /* Alternativer Reiseprogrammdrucker (nimmt Codes 520-539 statt 500-519 der Codeart 5008) */
  public static CDF_DSG_TEXTE = 26   /* Hauptpfad für gespeicherte Originaldokumente zu Dossiergeschichtseinträgen => neu Code 29 verwenden */
  public static CDF_AUSWERT = 27   /* Pfad und Name der zu verwendenden Access-Datenbank für die Auswertungen */
  public static CDF_IMP_DOK = 28   /* Pfad für Dokumentenimport */
  public static CDF_GESCHICHTSTEXTE = 29   /* Hauptpfad für gespeicherte Originaldokumente zu Partner-, Produkt- und Dossiergeschichtseinträgen */
  public static CDF_CRS_IN = 30   /* alter Define, neu: CRS_GALILEO */
  public static CDF_CRS_GALILEO = 30   /* Verzeichnis für Galileo-Dateien */
  public static CDF_CRS_SABRE = 31   /* Verzeichnis für Sabre-Dateien (inkl. Datei-Wildcards z.B. c:\*.prt) */
  public static CDF_CRS_AMADEUS = 32   /* Verzeichnis für Amadeus-Dateien */
  public static CDF_CRS_CETS = 33   /* Verzeichnis für CETS-Dateien */
  public static CDF_CRS_TOURONLINE = 35   /* Verzeichnis für Touronline-Daten */
  public static CDF_CRS_SPRK = 37   /* Verzeichnis für Spark-Dateien (Lufthansa-Gruppe) */
  public static CDF_CRS_BUCHUNGSARTEN = 39   /* Beim CRS-Import zu berücksichtigende Bu'arten (CR,MB usw.; Default=alle; "-" am Anfang=Ausschluss) */
  public static CDF_RGDIR_USER = 40   /* User um auf das Rechnungsverzeichnis zugreifen zu können (für Support) */
  public static CDF_RGDIR_PW = 41   /* Passwort, um auf das Rechnungsverzeichnis zugreifen zu können (für Support) */
  public static CDF_RGDIR_PFAD = 42   /* UNC-Pfad des Laufwerks (für Support) */
  public static CDF_TICKETS = 45   /* Hauptpfad für (externe) Tickets wie z.B. Deutsche Bahn Rail & Fly */
  public static CDF_PDF_EXPORT = 46   /* Pfad für im Tourdata integrierten PDF-Export beim Druckerdialog (statt über PDF-Druckertreiber) */
  public static CDF_TXB_EXTERN = 50   /* Standardverzeichnis für externe Texte (Tournet:  Internet-Homepage-Subverzeichnis für die Beschreibungstexte) */
  public static CDF_SERVICEMAILABS_NOREPLY = 55   /* Absender für automatische Servicemails an Veranstalter ohne Antwortmöglichkeit (nur Tournet, z.B. für Kopien von Umfrageantworten) */
  public static CDF_SERVICEMAILABS_TICKET = 56   /* Absender für automatische Servicemails an Veranstalter, die bei einer Antwort ans Ticketsystem gehen (nur Tournet, z.B. für falsche Preise) */
  public static CDF_EXP_TOURDATA = 61   /* Exportpfad für Tourdata-Dokumente (nur im Tournet) */
  public static CDF_EXP_UMBRELLA = 62   /* Exportpfad für Dokumente an Umbrella (nur im Tournet) */
  public static CDF_EXP_HIT = 63   /* Exportpfad (FTP) für Dokumente an HIT (nur im Tournet) */
  public static CDF_EXP_TICOFFICE = 64   /* Exportpfad für Dokumente an TicOffice (nur im Tournet) */
  public static CDF_EXP_TOURONLINE = 65   /* Exportpfad für Dokumente an TourOnline (nur im Tournet) */
  public static CDF_PNL_TO = 70   /* Email-Adresse, an die das PNL geschickt werden soll (EDS fürs Checkin) */
  public static CDF_PNL_FROM = 71   /* Emailadresse, die als Absender und Reply-Adresse für PNLs angegeben wird (leer=Default des Mailprogs) */
  public static CDF_SMTP_DEFAULT_KONTO = 72   /* Default-Absenderkonto für den SMTP-Versand, wenn bei der Person kein indiv. Konto angegeben wurde (wenn auch hier leer: Konto=E-Mailadresse) */
  public static CDF_SMTP_DEFAULT_PW = 73   /* Fakultatives Passwort (Blowfish verschlüsselt) für Default-Absenderkonto gem. Code 72 */
  public static CDF_DIENST_EMAIL_FROM = 74   /* Absender-Emailadresse für Dienste, wenn das Mail ab Tourdata-Server verschickt werden soll */
  public static CDF_SMTP_BCC = 76   /* Von allen Mails, die über das SMTP-Protokoll verschickt werden, geht eine (zusätzliche) Blindkopie an diese E-Mailadresse */
  public static CDF_SMTP_AUTH = 77   /* Fakultative Art der Passwortübermittlung f. interne Mails: 0=ignorieren, 1=Klartext (Default), 2=NTML */
  public static CDF_SMTP_PORT = 78   /* Fakultative Portnummer des SMTP-Servers für interne Mails */
  public static CDF_SMTP_SERVER = 79   /* Name des SMTP-Servers für interne Mails */
  public static CDF_HTML_EDITOR = 80   /* Programm inkl. Verzeichnis, das zum Editieren von HTML-Dateien verwendet wird */
  public static CDF_NEWSLETTER_PROG = 90   /* Programm mit Verzeichnis und allfälliger Startparameter für den Email-/Newsletterversand */
  public static CDF_NEWSLETTER_PROJ = 91   /* Pfad mit Datei-Wildcards für die Newsletter-Projektdateien */
  public static CDF_NEWSLETTER_CONFIG = 92   /* Konfigurationsdatei für das Newsletterprogramm */
  public static CDF_SMS_ABSENDERKENNUNG = 105  /* Veranstalterkennung (max. 11 Zeichen), die beim Empfänger als Absender angezeigt wird (Default erste 11 Zeichen des Mandantennamens) */
  public static CDF_ADR_SEND_FTP = 111  /* FTP-Server für das Senden von Adressmutationen an eine externe Anwendung */
  public static CDF_ADR_READ_PFAD = 112  /* Pfad inkl. Datei-Wildcards für das Einlesen von Adressmutationen eines Fremdsystems */
  public static CDF_ADR_KOMM_ART = 113  /* Kommunikationsart des Adressaustausches ("kuonicrm" oder "protel") mit einem Fremdsystem */
  public static CDF_ADR_KOMM_TOCODE = 114  /* Touroperator-Code für den Adressaustausch mit einem Fremdsystem */
  public static CDF_ADRLINK_PROG = 115  /* Die Adressen sind über diesen Befehl in einer Drittanwendung aufrufbar */
  public static CDF_ADRLINK_PARAM = 116  /* Statischer Parameter für den Aufruf des externen Programms gem. Code 115 (zus. Telcode 90) */
  public static CDF_ADRLINK2_URL = 117  /* Die Adressen sind über diese URL in einer Drittanwendung aufrufbar, wobei die ID aus dem Zusatzkriterium gem. 5009/329 angefügt wird */
  public static CDF_RG_SEND_PFAD = 120  /* Serverpfad für das Versenden verbuchter Rechnungen (UNC-Notation) */
  public static CDF_RG_SEND_LAUFWERK = 121  /* Laufwerksbuchstabe für den Rechnungsversand */
  public static CDF_RG_SEND_USER = 122  /* Benutzer für das Laufwerk zum Rechnungsversand */
  public static CDF_RG_SEND_PW = 123  /* Passwort für das Laufwerk zum Rechnungsversand */
  public static CDF_HOT_SEND_PFAD = 131  /* Pfad, in dem Hotelbuchungen übermittelt werden, wenn das Hotel beim 6. Zusatzkrit. einen Eintrag hat und Sell & Reportcode 30 ist */
  public static CDF_ACS_FTP = 135  /* Pfad für Übermittlung der ACS-Sell- & Reportdateien an den Systemanbieter */
  public static CDF_PLABE_TU = 140  /* Transportunternehmen-Code (TU-Text) für den Mandanten beim PLABE-Import */
  public static CDF_PLABE_PAID = 141  /* Kundennr., auf die PLABE-Dossiers automatisch gebucht werden */
  public static CDF_PLABE_STR = 142  /* PRID der Standardreise, auf die PLABE-Dossiers verbucht werden */
  public static CDF_PLABE_TU2 = 143  /* Allfälliger 2. Transportunternehmen-Code (TU-Text) für den Mandanten beim PLABE-Import */
  public static CDF_PLABE_STR2 = 144  /* Für den 2. TU-Code werden die Dossiers unter dieser Standardreise (PRID) angelegt (Default gem. Code 142) */
  public static CDF_PLABE_KL1 = 145  /* Kontingentsgruppencode, der als 1. Klasse interpretiert wird (mehrere durch Kommas trennen) */
  public static CDF_PLABE_KL2 = 146  /* Kontingentsgruppencode, der als 2. Klasse interpretiert wird (mehrere durch Kommas trennen) */
  public static CDF_PLABE_MAX_LAUFNR = 147  /* Höchste PLABE-Laufnr., die als neu verarbeitet werden darf (Default 1). Ab Laufnr. 2 muss zusätzlich die Fremddossiernr. geprüft werden. */
  public static CDF_FTP_EINKDATEN = 151  /* Pfad für Übermittlung der Einkaufsdaten */
  public static CDF_KTOBL_EXPORTPFAD = 160  /* Exportpfad für den Spezialexport des Kontoblatts Hauptbuch (Addison) */
  public static CDF_KTOBL_GEGENKONTO = 161  /* Einzutragendes Gegenkonto (Addison) */
  public static CDF_KTOBL_STEUERSCHLUESSEL = 162  /* Einzutragender Steuerschlüssel (Addison) */
  public static CDF_WVJ_WAEHRUNGSSCHLUESSEL = 165  /* Einzutragender Währungsschlüssel (Addison) für den Weiterverrechnungsjournalexport */
  public static CDF_WVJ_SFTP = 180  /* SFTP-Server inkl. User und Passwort für die WVJ-Exportschnittstelle */
  public static CDF_WVJ_FINGERPRINT = 181  /* Fingerabdruck des empfangenden Servers */
  public static CDF_WVJ_TYP = 182  /* Übermittlungsart und -format (z.Z. nun "SAP" für Kuoni-SAP-Format; WVJ-Export) */
  public static CDF_WVJ_FILEPREFIX = 183  /* Dateinamen-Prefix (WVJ-Export) */
  public static CDF_WVJ_MANDANT = 184  /* Mandantennr. des Zielsystems (WVJ-Export) */
  public static CDF_WVJ_COMPANY = 185  /* Companycode des Zielsystems (WVJ-Export) */
  public static CDF_WVJ_SENDER = 186  /* Senderidentifikation (WVJ-Export) */
  public static CDF_WVJ_PAID_PREFIX = 187  /* Prefix für die Kundennr. (ST01-Record; WVJ-Export) */
  public static CDF_WVJ_KOSTENSTELLE = 188  /* Kostenstelle für FA01 und ZV01 (WVJ-Export) */
  public static CDF_WVJ_ZKR_GRUPPE = 189  /* Zusatzkriterium der Standardreise, das zur Erkennung von Gruppenreisen verwendet wird (#g am Schluss der CDKURZBEZ; WVJ-Export) */
  public static CDF_WVJ_SERVICETYPE = 190  /* Servicetype (WVJ-Export) */
  public static CDF_WVJ_GUTSCHEINKONTO = 191  /* Für Buchungen auf diesem Hauptbuchkonto (für Geschenkgutscheinverkauf; Soll- oder Habenseite) wird fix der Servicetype "GEGU" verwendet */
  public static CDF_WVJ_ZUSKRIT_LIFNR = 193  /* Zusatzkriterium der Anbieteradresse, das die Lieferantennr. im Zielsystem beinhaltet (WVJ-Export - Einstandspreise) */
  public static CDF_WVJ_IATACODE = 194  /* IATA-Code des Veranstalters (WVJ-Export für BSP-Abrechnung der Einstandspreise auf Flügen) */
  public static CDF_AGB = 200  /* Verweis auf Webseite mit den allgemeinen Geschäftsbedingungen (sprachabhängig!!) */
  public static CDF_HOMEPAGE_FRAME = 201  /* Verweis auf Webseite, die als Frame die aktuelle Internet-Buchungsseite integriert (sprachabhängig!!) */
  public static CDF_HOMEPAGE_GLOBALFRAME = 202  /* Verweis auf Webseite, die als Frame die aktuelle Internetseite von Tourdata integriert (sprachabh.!!)))) */
  public static CDF_DATENSCHUTZ_LINK = 203  /* Verweis auf Webseite mit den Datenschutzbestimmungen (sprachabhängig!!) */
  public static CDF_HOMEPAGE_NLBESTAETIGUNG = 204  /* Verweis auf externe Webseite für die Bestätigung nach erfolgreicher Newsletteranmeldung (bei aktivem Double Opt-In erst nach KD-Bestätigung) */
  public static CDF_HOMEPAGE_NLFRAGE = 205  /* Verweis auf externe Webseite für die Internet-Newsletterabmeldung  (sprachabhängig!!) */
  public static CDF_HOMEPAGE_BESTAETIGUNG = 206  /* Verweis auf Seite, die statt der Tourdata eigenen Bestätigungsseite bei einer Buchung aufgerufen wird */
  public static CDF_HOMEPAGE_GAESTEBUCH = 207  /* Seite des Kunden, die angezeigt wird, nachdem ein Gästebucheintrag erstellt wurde (üblicherweise Gästebuch-Einstiegsseite) */
  public static CDF_HOMEPAGE_TEMPLATE = 208  /* Verweis auf Webseite, über die das Webtemplate automatisch neu geladen werden kann (z.Z. nicht ausprogrammiert) */
  public static CDF_STORNO_LINK = 209  /* Verweis auf externe Webseite, über die ein Dossier storniert werden kann (sprachabhängig!!!) */
  public static CDF_HOMEPAGE_BASIS = 210  /* Basis-URL für generierte Seiten und Homepage-Generator (Default=to.reisemarkt.ch) */
  public static CDF_HOMEPAGE_EMAIL_TEMPLATE = 211  /* Template, das bei automatischen Mails (Fragebogen, Umfragen) für die URL der aufgerufenen Seiten verwendet werden soll */
  public static CDF_SAFERPAY_URL = 220  /* Fakultative Basis-URL für Zahlungen via Saferpay (z.B. für Testaccount, Default=Standard-URL) */
  public static CDF_SAFERPAY_ACCOUNTID = 221  /* Account-Id für Kreditkartenzahlungen über Saferpay (über Internet durch Kunde selbst) */
  public static CDF_SAFERPAY_JSON_USER = 222  /* Benutzer für die neue Saferpay JSON-API */
  public static CDF_SAFERPAY_JSON_PW = 223  /* Passwort (verschlüsselt) für die neue Saferpay JSON-API */
  public static CDF_SAFERPAY_PROG_ACCOUNTID = 226  /* Account-Id für Kreditkartenzahlungen über Saferpay aus dem Programm heraus */
  public static CDF_SAFERPAY_PROG_JSON_USER = 227  /* Benutzer für den Aufruf aus dem Programm heraus (Kompatibilität: JSON-Schnittstelle nur verwenden, wenn CDBEZ <> CDBEZ von Code 222) */
  public static CDF_SAFERPAY_PROG_JSON_PW = 228  /* Passwort (verschlüsselt) für den Aufruf aus dem Programm heraus */
  public static CDF_HOMEPAGE_BACKLINK = 240  /* Webseite für die Zurück-Funktion von der ersten Buchungsseite zur externen Seite (div. Platzhalter möglich; sprachabhängig!!!) */
  public static CDF_HOMEPAGE_WMAT_BESTAETIGUNG = 241  /* Link auf externe Bestätigungsseite nach Prospektbestellungen, der Parameter Prospektherkunft= wird beim Aufruf automatisch hinzugefügt */
  public static CDF_TRACKING_BUCHUNG = 270  /* HTML-Code, der auf der Buchungsbestätigungsseite eingebaut wird (nach dem OK) */
  public static CDF_TRACKING_WMAT_BESTELLT = 275  /* HTML-Code, der auf der Werbematerial-Bestätigungsseite eingebaut wird (nach dem OK) */
  public static CDF_GOOGLE_CONV_ID = 280  /* Convertion-ID für Google Adwords */
  public static CDF_GOOGLE_CONV_COLOR = 281  /* Farbcode (hexadezimal) für Google Adwords */
  public static CDF_GOOGLE_CONV_LANGUAGE = 282  /* Sprache (2-stelliger ISO-Code in Kleinbuchstaben) für Google Adwords */
  public static CDF_GOOGLE_CONV_FORMAT = 283  /* Formatcode (Zahl) für Google Adwords */
  public static CDF_GOOGLE_CONV_VALUE = 284  /* Zusatzwert (Zahl) für Google Adwords */
  public static CDF_GOOGLE_CONV_LBUCH = 285  /* Label für Google Adwords: Buchung */
  public static CDF_GOOGLE_CONV_LWMAT = 286  /* Label für Google Adwords: Prospektbestellung */
  public static CDF_GOOGLE_CONV_LNEWSL = 287  /* Label für Google Adwords: Newsletter */
  public static CDF_GOOGLE_CONV_REMARKETING = 288  /* Parameter für Google AdWords: Re-Marketing (fakultativ) */
  public static CDF_GOOGLE_CONV_FACEBOOK = 289  /* Parameter für Google-Adworks: Zusätzlicher Link für Facebook (fakultativ) */
  public static CDF_GOOGLE_ANALYTICS_ID = 290  /* Id für Google Analytics */
  public static CDF_GOOGLE_ANALYTICS_DOMAIN = 291  /* Domain unter der Google Analytics die Statistik sammelt (fakultativ) */
  public static CDF_GOOGLE_ANALYTICS_SKRIPT = 292  /* Art des Skripts, welches für Google Analytics verwendet werden soll */
  public static CDF_GOOGLE_MAPS_KEY = 295  /* Google Maps API-Key (für GPS-Daten) */
  public static CDF_DB_URL = 300  /* EndPointURL für TICKeos-Webservice (Deutsche Bahn Rail & Fly) */
  public static CDF_DB_SERVICE_USER = 301  /* Anmelde-User für TICKeos-Webservice */
  public static CDF_DB_SERVICE_PW = 302  /* Anmeldepasswort für TICKeos-Webservice */
  public static CDF_DB_AUTH_TOKEN = 304  /* Authorisierungs-Token für TICKeos-Webservice */
  public static CDF_DB_SYSTEMID = 305  /* System-ID des Systemanbieters bei eos.uptrade ( für TICKeos-Webservice) */
  public static CDF_DB_ORGANIZERID = 306  /* ID des Veranstalters bei eos.uptrade (für Abrechnung des TICKeos-Webservice) */
  public static CDF_DB_TEMPLATEID = 308  /* Zu verwendendes Ticket-Template für TICKeos-Webservice */
  public static CDF_DBV_URL = 310  /* URL für die neuen Raily & Fly-Voucher-APIs von Viator für die Deutsche Bahn */
  public static CDF_DBV_EMAIL = 311  /* E-Mailadresse als Anmeldebenutzer für die Viator-API */
  public static CDF_DBV_PW = 312  /* Passwort für die Anmeldung an der Viator-API */
  public static CDF_DBV_BRAND_ID = 313  /* Brand_ID für die Viator-API */
  public static CDF_DBV_BRAND_CRUISE_FLEX = 315  /* Brand-ID für den Rail & Cruise-Flextarif in der Viator-API */
  public static CDF_DBV_BRAND_CRUISE_SPAR = 316  /* Brand-ID für den Rail & Cruise-Spartarif in der Viator-API */
  public static CDF_DBV_LANDPREFIX = 318  /* 2-stelliger Länderprefix für die Bahnnummern in der Viator-API */
  public static CDF_DBV_TESTUSER = 319  /* Benutzer, die vor dem eigentlichen Startdatum neue Rail&Fly nach dem neuen Verfahren aufbereiten und bearbeiten können (Tests). */
  public static CDF_GALILEO_URL = 350  /* EndPointURL für Galileo-Webservices */
  public static CDF_GALILEO_SCHEME = 351  /* WinHTTPAuthScheme für Galileo-Webservices */
  public static CDF_GALILEO_USERID = 352  /* AuthUser für Galileo-Webservices */
  public static CDF_GALILEO_PW = 353  /* AuthPassword für Galileo-Webservices */
  public static CDF_GALILEO_ACTION = 354  /* SoapAction für Galileo-Webservices */
  public static CDF_GALILEO_ATTR2 = 356  /* SoapAttribute2 für Galileo-Webservices */
  public static CDF_GALILEO_HAP = 357  /* HAP für Galileo-Webservices */
  public static CDF_GALILEO_PCC = 358  /* PCC für die Queue, in die ein automatisch erstelltes PNR eingetragen werden muss */
  public static CDF_GALILEO_QUEUE_NR = 359  /* Nr. der Queue, in die ein automatisch erstelltes PNR eingetragen werden muss */
  public static CDF_AMADEUS_ADR = 370  /* Amadeus-Internet-Adresse für die API-Schnittstelle */
  public static CDF_AMADEUS_PORT = 371  /* Port für die Amadeus-API-Schnittstelle */
  public static CDF_AMADEUS_FIRMENID = 372  /* Firmenidentifikation für die Amadeus-API-Schnittstelle */
  public static CDF_AMADEUS_USERID = 373  /* Benutzeridentifikation für die Amadeus-API-Schnittstelle */
  public static CDF_AMADEUS_PW = 374  /* Passwort für die Amadeus-API-Schnittstelle */
  public static CDF_AMADEUS_QUEUE_OFFICE_ID = 375  /* Office-Id für die Queue, in die ein automatisch erstelltes PNR gestellt werden muss */
  public static CDF_AMADEUS_QUEUE_NR = 376  /* Nr. der Queue, in die ein automatisch erstelltes PNR gestellt werden muss. Office-Id gem. Code 375 */
  public static CDF_MIN_POOLING = 380  /* Erster fürs Pooling reservierter Code */
  public static CDF_EUROWINGS_TO_CODE = 380  /* Veranstaltercode für Eurowings */
  public static CDF_CONDOR_TO_CODE = 381  /* Verantaltercode für Condor */
  public static CDF_MAX_POOLING = 389  /* Letzter fürs Pooling reservierter Code */
  public static CDF_AIRBERLIN_TO_CODE = 391  /* Veranstaltercode bei AirBerlin für PNL-Übermittlungen (***alter Define***, da neu generell für SPFF-Formate verwendet) */
  public static CDF_SPFF_TO_CODE = 391  /* Veranstaltercode bei der Fluggesellschaft für PNL-Übermittlungen im SPFF-Format  */
  public static CDF_AIRBERLIN_POOLING = 392  /* Pfad für die Übermittlung der Poolingdaten an/von Air Berlin */
  public static CDF_AIRBERLIN_BZ = 393  /* Pfad für die Übermittlung der Buchungszahlen (Unterordner paxcount) + des PNL (Unterordner paxnames) */
  public static CDF_KOMET_URL = 400  /* EndPointURL für Komet-Webservices */
  public static CDF_KOMET_USER = 402  /* Auth-User für Komet-Webservices */
  public static CDF_KOMET_PW = 403  /* Auth-Password für Komet-Webservices */
  public static CDF_KOMET_FROM = 404  /* Kürzel des Absenders für Komet-Webservices */
  public static CDF_KOMET_TO = 405  /* Empfängercode für Komet-Webservices */
  public static CDF_KOMET_CATALOG = 406  /* Katalogcode für Komet-Webservices */
  public static CDF_VERS_URL = 410  /* Basis-URL für den bei der Buchungsbestätigung eingeblendeten Versicherungslink */
  public static CDF_VERS_TYP = 411  /* *** z.Z. nicht mehr verwendet *** Art der bei obigem Link zus. aufbereiteten var. Parameter: "HM"=Hanse Merkur */
  public static CDF_BA_TO_CODE = 421  /* Veranstaltercode bei British Airways */
  public static CDF_SPRK_URL = 440  /* URL für die SPRK-Schnittstelle */
  public static CDF_SPRK_USER = 441  /* User für die SPRK-Schnittstelle */
  public static CDF_SPRK_PW = 442  /* Passwort  für die SPRK-Schnittstelle */
  public static CDF_SPRK_PCC = 443  /* Pseudocitycode für die SPRK-Schnittstelle */
  public static CDF_SPRK_AGENT = 444  /* Agent für die SPRK-Schnittstelle */
  public static CDF_SPRK_AGENT_PW = 445  /* Passwort für die SPRK-Schnittstelle (verschlüsselt) */
  public static CDF_SPRK_AGENT_ROLE = 446  /* Agent Role für die SPRK-Schnittstelle */
  public static CDF_SPRK_AGENCY = 447  /* Agency für die SPRK-Schnittstelle */
  public static CDF_SPRK_TRACE = 448  /* Trace für die SPRK-Schnittstelle */
  public static CDF_SPRK_QUEUE = 449  /* Queue für die SPRK-Schnittstelle */
  public static CDF_SPRK_API_KEY = 450  /* Ocp-Apim-Subscription-Key (API-Schlüssel verschlüsselt) */
  public static CDF_TRAVIA_URL = 460  /* URL für Travia API */
  public static CDF_TRAVIA_TOKEN = 461  /* Token für Travia API */
  public static CDF_TRAVIA_AGENT = 462  /* Agenten-ID des Veranstalters bei Travia */
  public static CDF_TRAVIA_LL_MAPPING_ZUSKRIT = 465  /* Landleistungs-Zusatzkriterium (1-6) vom Typ Text für die Travia-ID (Mapping) */
  public static CDF_CARTOUR_USER = 500  /* User fürs autom. Cartour-Login (verschlüsselt) */
  public static CDF_CARTOUR_PW = 501  /* Passwort fürs autom. Cartour-Login (verschlüsselt) */
  public static CDF_TOURNET_URL = 600  /* URL für den Tournet-Webservice der Importschnittstelle */
  public static CDF_TOURNET_CODE = 602  /* Tournet-Code des anmeldenden Reisebüros für den Tournet-Webservice */
  public static CDF_TOURNET_USER = 603  /* Benutzer für den Tournet-Webservice */
  public static CDF_TOURNET_PW = 604  /* Passwort für die Anmeldung an den Tournet-Webservice */
  public static CDF_TOURNET_FTP_USER = 610  /* FTP-User für die Übermittlung allgemeiner Dokumente für die Buchungsmaschine (Stammdaten, Templates usw.) ins Tournetsystem (verschlüsselt) */
  public static CDF_TOURNET_FTP_PW = 611  /* Passwort zum externen FTP-Benutzer (verschlüsselt) */
  public static CDF_TOURNET_FTP_INTERN_USER = 615  /* FTP-User für die Übermittlung interner Dokumente (z.B. b2b-Rechnungen) ins Tournetsystem (verschlüsselt) */
  public static CDF_TOURNET_FTP_INTERN_PW = 616  /* Passwort zum internen FTP-Benutzer (verschlüsselt) */
  public static CDF_RF_TOOL = 710  /* Verwaltungstool für den Reiseführer */
  public static CDF_RF_APP_LINK = 711  /* Link auf eine JSON-Datei mit dem Reiseführer */
  public static CDF_RF_WEB_LINK = 712  /* Testlink für den Reiseführer für die Vorausansicht im PDF-Format via Standardreise */
  public static CDF_BESCHR_URL = 750  /* URL, unter der die externen Bschreibungen und Bilder abgespeichert sind (z.B. von Pressmind) */
  public static CDF_BESCHR_USER = 751  /* Benutzer für die externen Beschreibungen und Bilder */
  public static CDF_BESCHR_PW = 752  /* Passwort für die externen Beschreibungen und Bilder (verschlüsselt) */
  public static CDF_BESCHR_URL_HTML = 760  /* URL unter der die externen Beschreibungen direkt in den Internetablauf eingebunden werden können (Standardreisekürzel wird autom. hinzugef.) */
  public static CDF_BESCHR_URL_VORSCHAUBILD = 761  /* URL unter der die externen Bilder direkt in den Internetablauf eingebunden werden können (Standardreisekürzel wird automatisch hinzugefügt) */
  public static CDF_LOHNEXP_PFAD = 800  /* Pfad ohne Dateinamen für Lohnexport */
  public static CDF_LOHNEXP_DATEI1 = 801  /* Name der ersten CSV-Exportdatei für den Lohnexport */
  public static CDF_LOHNEXP_FUNK1 = 802  /* Zu berücksichtigende Personenfunktioncodes (getrennt durch Kommas) für die erste Lohnexportdatei */
  public static CDF_LOHNEXP_DATEI2 = 803  /* Name der zweiten CSV-Exportdatei für den Lohnexport */
  public static CDF_LOHNEXP_FUNK2 = 804  /* Zu berücksichtigende Personenfunktioncodes (getrennt durch Kommas) für die zweite Lohnexportdatei */
  public static CDF_LOHNEXP_DATEI3 = 805  /* Name der dritten CSV-Exportdatei für den Lohnexport */
  public static CDF_LOHNEXP_FUNK3 = 806  /* Zu berücksichtigende Personenfunktioncodes (getrennt durch Kommas) für die dritte Lohnexportdatei */
  public static CDF_LOHNEXP_MONATSAUSLASTUNG = 809  /* Anzahl durchschnittlich pro Monat zu leistender Arbeitstage für eine 100%-Auslastung */
  public static CDF_GOOGLE_TOKEN = 900  /* URL für das Lösen eines Google Tokens */
  public static CDF_GOOGLE_TAB_APPEND = 912  /* URL für das Anfügen von Daten an ein Google Tabellen-Dokument (je ein Platzhalter %s für Datei- und Datenblattname) */
  public static CDF_PARKSYSTEM_PFAD = 1000 /* Exportpfad (SMB) für das Parksystem */
  public static CDF_PARKSYSTEM_VON = 1000 /* Erster für Parksysteme reservierte Codes (max. 10 Parksysteme zu 10 Codes) */
  public static CDF_PARKSYSTEM_USER = 1001 /* Benutzername für den Parksystem-Export */
  public static CDF_PARKSYSTEM_PW = 1002 /* Passwort für den Parksystem-Export */
  public static CDF_PARKSYSTEM_BIS = 1099 /* Letzter für Parksysteme reservierte Codes */
  public static CDF_IVU_EXPORTPFAD = 1100 /* URL (HTTPS-Pfad), um Auftragsdispositionsdaten (Schifffahrt) an IVU-Schnittstelle übermitteln zu können */
  public static CDF_NEWSLETTER_CLIENTID = 1200 /* Identifikation beim CleverReach für die Newsletter An-/Abmeldungen */
  public static CDF_NEWSLETTER_CLIENTSECRET = 1201 /* Secret-Parameter für die Identifikation beim CleverReach */
  public static CDF_NEWSLETTER_GROUPID = 1205 /* Identifikation der Newslettergruppe beim CleverReach. Kann bei Interessensgebieten über Codeart 1040 übersteuert werden */
  public static CDF_NEWSLETTER_ATTRIBUTE = 1207 /* Attributsliste für die Newsletter beim CleverReach ({Kunde.....}-Platzhalter möglich), pro Interessensgebiet analog groupId übersteuerbar */
  public static CDF_EMAIL_API_ANBIETER = 1210 /* Anbieter des APIs für E-Mailprozesse über Fremdanbieter */
  public static CDF_EMAIL_API_URL = 1211 /* URL für das API */
  public static CDF_EMAIL_API_KEY = 1212 /* Key für das API (verschlüssel) */
  public static CDF_AZURE_URL = 1300 /* URL des Webservices für die Azure-Cloud-API */
  public static CDF_AZURE_USER = 1301 /* Benutzer für die Token-Generierung für die Azure-Cloud-API */
  public static CDF_AZURE_PW = 1302 /* Passwort für die Token-Generierung für die Azure-Cloud-API */
  public static CDF_AZURE_SCOPE = 1303 /* Berechtigungsgruppe(n) auf Webservice-Ebene für die Azure-Cloud-API ("api.webagency" "api.printagency") */
  public static CDF_AZURE2_URL = 1310 /* URL des Webservices für eine 2. Azure-Cloud-API */
  public static CDF_AZURE2_USER = 1311 /* Benutzer für die Token-Generierung für die 2. Azure-Cloud-API */
  public static CDF_AZURE2_PW = 1312 /* Passwort für die Token-Generierung für die 2. Azure-Cloud-API */
  public static CDF_AZURE2_SCOPE = 1313 /* Berechtigungsgruppe(n) auf Webservice-Ebene für die 2. Azure-Cloud-API ("api.webagency" "api.printagency") */
  public static CDF_AZURE_TDINTERN_URL = 1400 /* URL des Webservices für die Tourdata interne Azure-Cloud-API */
  public static CDF_AZURE_TDINTERN_USER = 1401 /* Passwort für die Token-Generierung für die Tourdata interne Azure-Cloud-API */
  public static CDF_AZURE_TDINTERN_PW = 1402 /* URL des Webservices für die Tourdata interne Azure-Cloud-API */
  public static CDF_AZURE_TDINTERN_SCOPE = 1403 /* Berechtigungsgruppe(n) auf Webservice-Ebene für die Tourdata interne Azure-Cloud-API (z.Z. fix "api.intern") */
  public static CDF_PAXCONNECT_ID = 1500 /* ID des Veranstalters bei PaxConnect (Offersource) */
  public static CDF_PAXCONNECT_URL_OFFERTE = 1501 /* Basisbestandteil der URL für die Browser-App von PaxConnect für Offerten */
  public static CDF_MTRIP_AZURE_URL = 1600 /* Azure Storage Account URL für MTrip File Upload */
  public static CDF_MTRIP_AZURE_NAME = 1601 /* Azure Storage Account Name für MTrip File Upload */
  public static CDF_MTRIP_AZURE_KEY = 1602 /* Azure Storage Account Zugriffsschlüssel für MTrip File Upload */
  public static CDF_MTRIP_AZURE_CONTAINER = 1603 /* Azure Storage Account Container Name für MTrip File Upload */
  public static CDF_MTRIP_PFAD_DOK_ALLG = 1604 /* Azure Storage Account Verzeichnis für allgemeine Dokumente */
  public static CDF_MTRIP_PFAD_DOK_DS = 1605 /* Azure Storage Account Verzeichnis für dossier bezogene Dokumente */
  public static CDF_MTRIP_URL = 1610 /* API URL von MTrip */
  public static CDF_MTRIP_URL_DOK_ALLG = 1611 /* Pfad für allgemeine Dokumente für MTrip */
  public static CDF_MTRIP_URL_DOK_DS = 1612 /* PFad für Dossierbezogene Dokumente für MTrip */
  public static CDF_MTRIP_AUTH_HEADER = 1620 /* API Authorization Header */
  public static CDF_MTRIP_OFFICEID = 1621 /* MTrip Office ID */
  public static CDF_MTRIP_TEST_AUTH_HEADER = 1630 /* Testsystem API Authorization Header */
  public static CDF_MTRIP_TEST_OFFICEID = 1631 /* Testsystem MTrip Office ID */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZPCODE = 5001 /* Art des Zeitpunkteintrags (z.T. auch Bestandteil von Codeart 5067; AUTSUBCODE 8000+ZPCODE) */
  public static CDZP_DISPOSITION = 10   /* Disposition bis zu diesem Zeitpunkt grundsätzlich abgeschlossen */
  public static CDZP_EINAUSSTIEGSLISTE = 11   /* Automatische Ein-/Ausstiegsliste bis zu diesem Verfallzeitpunkt aufbereitet */
  public static CDZP_EINAUSSTIEG_MAIL = 12   /* wie 11, jedoch für Mailempfänger */
  public static CDZP_EINAUSSTIEG_NOTFALL = 13   /* wie 11, jedoch Notfallliste am Vorabend (vorgesehener Versand gem. CODE 5011 / 13) */
  public static CDZP_TOURNET_UEBERMITTLUNG = 20   /* Tournet-Übermittlung im Gange */
  public static CDZP_ZIMMERSUCHER = 21   /* Zimmersucher beim Veranstalter ativiert (d.h. es können Zimmernummern reserviert werden) */
  public static CDZP_TOURNET_GESPERRT = 22   /* Tournet-Buchungen sind ab dem Zeitpunkt gem. ZPDAT nicht möglich (z.B. wegen Datensicherung) */
  public static CDZP_ZIMMER_AUTOMATISCH = 23   /* Zimmernrn. dürfen in diesen Aufenthaltsperioden im Tournet direkt gebucht werden, sofern opt. Anz.Pax */
  public static CDZP_ZIMMER_MIN_SPEZ = 24   /* Zimmer in diesem Zeitraum mit mindestens der Anzahl gem. OBJBEM (Stelle 1=#, Stelle 2=Anzahl) Pers. */
  public static CDZP_TOURNET_DIENST = 30   /* Tournetdienst-Kontrolleintrag (=letztes Lebenszeichen des Dienstes) */
  public static CDZP_TOURNET_AUTOM_UEBERMITTLUNG = 31   /* Tournetdienst Kontrolleintrag der letzten automatischen Datenübermittlung aller Mandanten */
  public static CDZP_ADR_ABGLEICH = 40   /* Zeitpunkt der letzten Adressübermittlung an Fremd-DB zwecks Abgleich (ZPBISDAT=letztes ber. Mut.dat.) */
  public static CDZP_KOMET_AB = 50   /* Erstes Abflugdatum, ab dem Flüge über die Kometschnittstelle abgebucht werden */
  public static CDZP_POOLING_SENDEN = 52   /* Letztmaliger Versand der kompletten Sync-Datei und der Buchungszahlen aus der Poolingschnittstelle */
  public static CDZP_POOLING_EMPFANGEN = 53   /* Letzter Empfang der Pooling-Syncdatei */
  public static CDZP_POOLING_PNL = 54   /* Letzte Übermittlung (Tournet) der automatischen PNL-Übermittlung */
  public static CDZP_POOLING_PREISIMPORT = 55   /* Letztmaliger Import der Preise aus der Poolingschnittstelle (Tournet) */
  public static CDZP_EINK_ABGLEICH = 60   /* Zeitpunkt der letzten Übermittlung von Einkaufsdaten an ein Drittsystem zwecks Abgleich */
  public static CDZP_SELL_REPORT = 61   /* Zeitpunkt der letzten Durchführung des täglichen Sell & Reportversands */
  public static CDZP_HOTELABRECHNUNG = 62   /* Zeitpunkt des letzten Hotelabrechnungsversands */
  public static CDZP_PARKSYSTEM_EXPORT = 65   /* Zeitpunkt der letzten Aufbereitung der Exportdatei für das Parksystem */
  public static CDZP_ERI_EMAILS = 70   /* Letzter Versand von Erinnerungsmails via Dienst */
  public static CDZP_ANBIETER_EMAILS = 71   /* Letzter Versand von allgemeinen Listen an Anbieter via Dienst */
  public static CDZP_RADLISTEN_EMAILS = 72   /* *TEMPORÄR*: Letzter Versand der Radlisten via Dienst */
  public static CDZP_FLUGHAFENPLAN_EMAILS = 73   /* *TEMPORÄR*: Letzter Versand des detaillierten Flughafenplans an die Hotelagenten via Dienst */
  public static CDZP_WVJ_EXPORT = 80   /* Zeitpunkt der letzten WVJ-Übermittlung */
  public static CDZP_PROVISIONSABRECHNUNG = 81   /* Zeitraum der letzten Provisionsabrechnung */
  public static CDZP_TD_LIZENZ = 90   /* Ablauf Tourdatalizenz */
  public static CDZP_AUTOM_SKRIPT = 91   /* Letztmalige Durchführung der automatischen SQL-Testskripte gem. Codeart 5020 */
  public static CDZP_TESTDB_IMP = 92   /* Wenn mindestens ein Eintrag vorhanden ist, kann die Testdatenbank kann nur zwischen diesen Zeiten aktualisiert werden (Datum wird ignoriert) */
  public static CDZP_LLTICKET_EMAILS = 100  /* Letzter Versand von Landleistungstickets via Dienst */

  /*--------------------------------------------------------------------------*/

  public static TAB_NRCODE = 5002 /* Art des Nummernmaster-Eintrags */
  public static CDNR_PARTNER = 1    /* Partnernummer */
  public static CDNR_PRODUKT = 2    /* Produktenummer */
  public static CDNR_DOSSIER = 3    /* Dossiernummer */
  public static CDNR_WVJ = 4    /* Weiterverrechnungsjournal */
  public static CDNR_MWSTJ = 5    /* Mehrwertsteuerjournal */
  public static CDNR_POSTEN = 6    /* Postennummer */
  public static CDNR_BUCHUNG = 7    /* Buchhaltungsbuchung */
  public static CDNR_GUTSCHEIN = 8    /* Gutscheinnummer */
  public static CDNR_INTERN = 9    /* Interne Nummern, von denen der Benutzer keine Ahnung hat */
  public static CDNR_PNL = 10   /* Laufnummer für PNL-Datei */
  public static CDNR_ANGEBOT = 11   /* Angebotsidentifikation Reisemarkt */
  public static CDNR_DISPOSITION = 12   /* Dispositionsnummer */
  public static CDNR_OBJEKT = 13   /* Objektnummer */
  public static CDNR_MELDUNG = 14   /* Laufnummer für das Meldewesen */
  public static CDNR_BEZEICHNUNG = 15   /* Interne Id für Bezeichnungen */
  public static CDNR_KOMMENTAR = 16   /* ID für den Kommentar */
  public static CDNR_AUSWERTUNGSVORLAGE = 17   /* Interne ID für die Auswertungsvorlage */
  public static CDNR_UMFRAGE = 18   /* Interne ID für eine Umfrage */
  public static CDNR_FRAGE = 19   /* Interne ID für eine Frage */
  public static CDNR_UMFRAGEANTWORT = 20   /* Interne ID für eine Umfrageantwort */
  public static CDNR_GUTSCHEINREGEL = 21   /* Interne ID einer Gutscheinregel */
  public static CDNR_AUTOMATIONSGRUPPE = 22   /* Interne ID für eine Automationsgruppe */
  public static CDNR_NUMMER = 23   /* Interne ID für einen individuellen Nummerneintrag (Gutschein, Reisegutschein, Ticket usw.) */
  public static CDNR_BSTABR = 24   /* Buchungsstellenabrechnungs-Journalnr. */
  public static CDNR_KREDITORENRG = 25   /* Kreditorenrechnung */
  public static CDNR_WVJ_SEQNR = 80   /* Weiterverrechnungsjournal-Sequenznr. für Übermittlung ans Kuoni-SAP */
  public static CDNR_ABLAUF = 100  /* + Wert von BUABLAUFCODE1 bzw. 2 = Nummer des Ablaufs */
  public static CDNR_MITGLIED = 200  /* generelle Mitgliedschaftsnummer */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZKRTYPCODE = 5003 /* Datentyp des Kriteriums */
  public static CDZKR_BOOLEAN = 0    /* Ja/Nein-Feld */
  public static CDZKR_ZEICHEN = 1    /* Zeichenkette */
  public static CDZKR_ZAHL = 2    /* Zahl */
  public static CDZKR_DATUM = 3    /* Datum */
  public static CDZKR_CODE = 9    /* Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_PFUNK = 5004 /* Funktionstasten für Programmaufrufe (z.Z. nur Werte von 41-52 = Ctrl+F...) */
  public static CDPF_SHIFT = 20   /* zu addieren bei Shift-Tastendruck */
  public static CDPF_CTRL = 40   /* zu addieren bei Ctrl-Tastendruck */
  public static CDPF_ALT = 80   /* zu addieren bei Alt-Tastendruck */

  /*--------------------------------------------------------------------------*/

  public static TAB_PMENU = 5005 /* Menubeschriftung für Programme (gleiche Codes wie bei TAB_PFUNK) */

  /*--------------------------------------------------------------------------*/

  public static TAB_SLCODE = 5006 /* Art des Suchlaufs */
  public static CDSL_PA_AUSWERT = 1    /* Partner-Auswertungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_SKR_VERGLEICHSCODE = 5007 /* Vergleichsfunktion des Suchbegriffs */
  public static CDSKR_KEIN_VERGLEICH = 0    /* Feld ist nicht Teil der Suche */
  public static CDSKR_AUSWAHL = 1    /* Auswahl mit = bzw. IN(a,b,c) */
  public static CDSKR_BEREICH = 2    /* Von-Bis-Bereich BETWEEN a AND b */
  public static CDSKR_NICHT = 10   /* für Vergleichsumkehr +10 des Originalwertes */
  public static CDSKR_NICHT_IN_AUSWAHL = 11   /* Alle selektieren, die nicht in der Auswahl vorkommen */
  public static CDSKR_NICHT_IM_BEREICH = 12   /* Alle selektieren, die NICHT im Bereich liegen */

  /*--------------------------------------------------------------------------*/

  public static TAB_DRUCKERCODE = 5008 /* Positions- und Längenangaben für Druckoutput */
  public static CDDR_DRUCKER_VON = 1    /* Erster für Druckerkennungen reservierter Code */
  public static CDDR_DRUCKER_BIS = 99   /* Letzter für Druckerkennungen reservierter Code */
  public static CDDR_ESR1_POS_Y = 100  /* Y-Startposition des 1. ESR in cm */
  public static CDDR_ESR2_POS_Y = 101  /* Y-Startposition des 2. ESR in cm */
  public static CDDR_TAB_ANZAHL = 111  /* Tabulatorposition des Anzahlfeldes im Preisblock (rechtsbündig, in 1/1000cm) */
  public static CDDR_TAB_BETRCODE = 112  /* Tabulatorposition des Zeichens (*, %) im Preisblock (rechtsbündig, in 1/1000cm) */
  public static CDDR_TAB_ANSATZ = 113  /* Tabulatorposition des Ansatzfeldes im Preisblock (rechtsbündig, in 1/1000cm) */
  public static CDDR_TAB_TOTAL = 114  /* Tabulatorposition des Totalfeldes im Preisblock (rechtsbündig, in 1/1000cm) */
  public static CDDR_TAB_KOMM = 115  /* Tabulatorposition des Kommissionsfeldes im Preisblock (rechtsbündig, in 1/1000cm) */
  public static CDDR_ZWTOT_ANZ_BLANKS = 116  /* Anzahl Leerzeichen vor den Trennstrichen "-" */
  public static CDDR_ZWTOT_ANZ_STRICHE = 117  /* Anzahl Striche für die Trennzeilen "-" */
  public static CDDR_TOT_ANZ_BLANKS = 118  /* Anzahl Leerzeichen vor dem Unterstrich des Totalbetrags "=" */
  public static CDDR_TOT_ANZ_STRICHE = 119  /* Anzahl "=" für das Unterstreichen der Totalbeträge */
  public static CDDR_LG_WAEH = 120  /* Länge der Währung im Preisblock für die Anzeige (Anzahl Zeichen) */
  public static CDDR_TAB_WAEH = 121  /* Tab.pos. der Währung im Preisblock (linksbündig zw. Ansatz+Total, in 1/1000cm, leer=nicht drucken) */
  public static CDDR_TAB_MWST = 122  /* Tab.pos. des MwSt-Satzes im Preisblock (rechtsbündig nach Komm., in 1/1000cm, leer=nicht drucken) */
  public static CDDR_PB_TEXT = 130  /* Breite des Textfeldes im Preisblock in 1/1000 cm (wenn <> 0 wird die Preistabelle nach neu angezeigt) */
  public static CDDR_PB_ANZAHL = 131  /* Breite des Anzahl-Feldes im Preisblock in 1/1000 cm */
  public static CDDR_PB_EINHEIT = 132  /* Breite der Einheitsspalte (%, x usw.) im Preisblock in 1/1000 cm */
  public static CDDR_PB_ANSATZ = 133  /* Breite der Ansatz-Felder im Preisblock in 1/1000 cm */
  public static CDDR_PB_WAEHRUNG = 134  /* Breite des Währungsfeldes im Preisblock in 1/1000 cm (leer oder 0=Währungsspalte nicht anzeigen) */
  public static CDDR_PB_BETRAG = 135  /* Breite der Betragsspalte im Preisblock in 1/1000 cm */
  public static CDDR_PB_INLAND = 136  /* Breite der Inland-Betragsspalte im Preisblock in 1/1000 cm (leer oder 0=Inlandspalte nicht anzeigen) */
  public static CDDR_PB_MWST = 137  /* Spalte für die MWST-Prozente im Preisblock in 1/1000 cm (leer oder 0=keine MWST-Spalte anzeigen) */
  public static CDDR_PB_KOMMISSION = 138  /* Spalte für die Kommissionszeile im Preisblock in 1/1000 cm */
  public static CDDR_BAZ1_POS_Y = 150  /* Y-Startposition des 1. BAZ in cm */
  public static CDDR_BAZ2_POS_Y = 151  /* Y-Startposition des 2. BAZ in cm */
  public static CDDR_RG_RAND_OBEN = 200  /* Oberer Rand der Rechnung in cm */
  public static CDDR_RG_RAND_LINKS = 201  /* Linker Rand der Rechnung in cm */
  public static CDDR_RG_RAND_RECHTS = 202  /* Rechter Rand der Rechnung in cm */
  public static CDDR_RG_RAND_UNTEN = 203  /* Unterer Rand der Rechnung in cm */
  public static CDDR_RG_PAPIERFORMAT = 206  /* Rechnungspapierformat: leer oder "90"=A4, "DRUCKER"=Default gem. Drucker, "120"=1 1/3 A4 */
  public static CDDR_RG_ESR_RAND_OBEN = 210  /* Oberer Rand der Seite mit ESR in 1/1000cm */
  public static CDDR_RG_ESR_RAND_LINKS = 211  /* Linker Rand der Seite mit ESR in 1/1000cm */
  public static CDDR_RG_ESR_RAND_RECHTS = 212  /* Rechter Rand der Seite mit ESR in 1/1000cm */
  public static CDDR_RG_ESR_RAND_UNTEN = 213  /* Unterer Rand der Seite mit dem ESR in 1/1000cm */
  public static CDDR_RG_ESR_KOPFZEILE = 214  /* 1=Die Kopfzeile wird auf dem ESR in gleicher Höhe wie bei den normalen Seiten gedruckt (Default=Die Höhe wird auf 0 cm eingestellt) */
  public static CDDR_RG_BEILAGEN_RAND_OBEN = 220  /* Oberer Rand der Seiten mit Beilagen, die für den Druck ins Hauptdokument integriert werden in cm */
  public static CDDR_RG_BEILAGEN_RAND_LINKS = 221  /* Linker Rand der Seiten mit Beilagen, die für den Druck ins Hauptdokument integriert werden in cm */
  public static CDDR_RG_BEILAGEN_RAND_RECHTS = 222  /* Rechter Rand der Seiten mit Beilagen, die für den Druck ins Hauptdokument integriert werden in cm */
  public static CDDR_RG_BEILAGEN_RAND_UNTEN = 223  /* Unterer Rand der Seiten mit Beilagen, die für den Druck ins Hauptdokument integriert werden in cm */
  public static CDDR_RG_BEILAGEN_INTEGRIEREN = 224  /* 1=Beilagen werden für den Druck ins Hauptdokument integriert (Default=nicht, bzw. über Programm wählbar) */
  public static CDDR_RG_BEILAGEN_1 = 225  /* Kurzbezeichnung der Zusatzleistung, dessen Beilage als erstes integriert werden soll (agb=für AGBs generell; Default=wie in Standardreise) */
  public static CDDR_RG_BEILAGEN_2 = 226  /* Kurzbezeichnung der Zusatzleistung, dessen Beilage als zweites integriert werden soll (analog Code 225) */
  public static CDDR_OFF_RAND_OBEN = 252  /* Oberer Rand der Offerten in 1/1000cm */
  public static CDDR_OFF_RAND_LINKS = 253  /* Linker Rand der Offerten in 1/1000 cm */
  public static CDDR_OFF_RAND_UNTEN = 254  /* Unterer Rand der Offerten in 1/1000 cm */
  public static CDDR_OFF_RAND_RECHTS = 255  /* Rechter Rand der Offerten in 1/1000 cm */
  public static CDDR_TI_COUPON_POS_X = 282  /* X-Startposition des Ticketteils in cm *** alt *** */
  public static CDDR_TI_COUPON_POS_Y = 283  /* Y-Startposition des Ticketteils in cm *** alt *** */
  public static CDDR_TI_SEITE_BREITE = 284  /* Seitenbreite des Ticketformulars in 1/1000cm  *** alt *** */
  public static CDDR_TI_SEITE_HOEHE = 285  /* Seitenhöhe des Ticketformulars in 1/1000cm *** alt *** */
  public static CDDR_TI_RAND_OBEN = 286  /* Oberer Rand des Tickets in 1/1000cm *** alt *** */
  public static CDDR_TI_RAND_LINKS = 287  /* Linker Rand des Tickets in 1/1000cm *** alt *** */
  public static CDDR_TI_RAND_UNTEN = 288  /* Unterer Rand des Tickets in 1/1000cm *** alt *** */
  public static CDDR_TI_RAND_RECHTS = 289  /* Rechter Rand des Tickets in 1/1000cm *** alt *** */
  public static CDDR_ASR_POS_X = 300  /* X-Position des ASR-Schecks in cm */
  public static CDDR_ASR_POS_Y = 301  /* Y-Position des ASR-Schecks in cm */
  public static CDDR_ASR_RAND_LINKS = 304  /* Linker Rand der ASR-Seite in 1/1000cm */
  public static CDDR_ASR_RAND_RECHTS = 305  /* Rechter Rand der ASR-Seite in 1/1000cm */
  public static CDDR_ASR_RAND_OBEN = 306  /* Oberer Rand der ASR-Seite in 1/1000cm */
  public static CDDR_ASR_RAND_UNTEN = 307  /* Unterer Rand der ASR-Seite in 1/1000cm */
  public static CDDR_VOUCHER_RAND_OBEN = 402  /* Oberer Rand der Voucher-Seite in 1/1000cm */
  public static CDDR_VOUCHER_RAND_LINKS = 403  /* Linker Rand der Voucher-Seite in 1/1000cm */
  public static CDDR_VOUCHER_RAND_UNTEN = 404  /* Unterer Rand der Voucher-Seite in 1/1000cm */
  public static CDDR_VOUCHER_RAND_RECHTS = 405  /* Rechter Rand der Voucher-Seite in 1/1000cm */
  public static CDDR_VOUCHER_POS1 = 410  /* Position des ersten Vouchers in cm */
  public static CDDR_VOUCHER_POS2 = 411  /* Position des 2. Vouchers in cm */
  public static CDDR_VOUCHER_POS3 = 412  /* Position des 3. Vouchers in cm */
  public static CDDR_VOUCHER_PRO_SEITE = 420  /* Anzahl Endlosvoucher pro Seite */
  public static CDDR_TICKET_RAND_OBEN = 502  /* Oberer Rand der Seite mit dem Ticket in 1/1000cm */
  public static CDDR_TICKET_RAND_LINKS = 503  /* Linker Rand der Seite mit dem Ticket in 1/1000cm */
  public static CDDR_TICKET_RAND_UNTEN = 504  /* Unterer Rand der Seite mit dem Ticket in 1/1000cm */
  public static CDDR_TICKET_RAND_RECHTS = 505  /* Rechter Rand der Seite mit dem Ticket in 1/1000cm */
  public static CDDR_FLIGHT_COUPON_POS_Y = 510  /* Y-Position des Flight-Coupons in 1/1000cm */
  public static CDDR_TICKETS_PRO_SEITE = 511  /* Anzahl Endlostickets pro Seite */
  public static CDDR_AD_TICKET_RAND_OBEN = 522  /* Ticketrand oben in 1/1000cm für Alternativdrucker */
  public static CDDR_AD_TICKET_RAND_LINKS = 523  /* Ticketrand links in 1/1000cm für Alternativdrucker */
  public static CDDR_AD_TICKET_RAND_UNTEN = 524  /* Ticketrand unten in 1/1000cm für Alternativdrucker */
  public static CDDR_AD_TICKET_RAND_RECHTS = 525  /* Ticketrand rechts in 1/1000cm für Alternativdrucker */
  public static CDDR_AD_FLIGHT_COUPON_POS_Y = 530  /* Y-Position des Flightcoupons in 1/1000cm */
  public static CDDR_DRUCKERSCHACHT_DECKBLATT = 600  /* Druckerschacht, der für ein Deckblatt verwendet wird */
  public static CDDR_DRUCKERSCHACHT_1 = 601  /* Druckerschacht 1 */
  public static CDDR_DRUCKERSCHACHT_2 = 602  /* Druckerschacht 2 */
  public static CDDR_DRUCKERSCHACHT_3 = 603  /* Druckerschacht 3 */
  public static CDDR_DRUCKERSCHACHT_4 = 604  /* Druckerschacht 4 */
  public static CDDR_DRUCKERSCHACHT_5 = 605  /* Druckerschacht 5 */
  public static CDDR_DRUCKERSCHACHT_KOPIE = 606  /* Wenn angegeben, werden sämtliche Kopien auf diesen Druckerschacht ausgedruckt (statt wie Original) */
  public static CDDR_DRUCKERSCHACHT_WEISS = 607  /* Druckerschacht weiss (wenn angegeben, wird er für bestimmte Dokumente, die zwingend auf weissem Papier gedruckt werden müssen, verwendet) */
  public static CDDR_POS1 = 611  /* Druckerposition 1 in 1/1000cm */
  public static CDDR_POS2 = 612  /* Druckerposition 2 in 1/1000cm */
  public static CDDR_POS3 = 613  /* Druckerposition 3 in 1/1000cm */
  public static CDDR_DRUCKER_STD = 650  /* Standardrucker beim Aufstarten von Tourdata */
  public static CDDR_FA_RAND_OBEN = 702  /* Fahrauftrag oberer Rand in 1/1000cm */
  public static CDDR_FA_RAND_LINKS = 703  /* Fahrauftrag linker Rand in 1/1000cm */
  public static CDDR_FA_RAND_UNTEN = 704  /* Fahrauftrag unterer Rand in 1/1000cm */
  public static CDDR_FA_RAND_RECHTS = 705  /* Fahrauftrag rechter Rand in 1/1000cm */
  public static CDDR_PROT_RAND_OBEN = 802  /* Protokoll Rand oben in 1/1000cm */
  public static CDDR_PROT_RAND_LINKS = 803  /* Protokoll Rand links in 1/1000cm */
  public static CDDR_PROT_RAND_UNTEN = 804  /* Protokoll Rand unten in 1/1000cm */
  public static CDDR_PROT_RAND_RECHTS = 805  /* Protokoll Rand rechts in 1/1000cm */
  public static CDDR_DEFAULT_SCHRIFT = 2000 /* Standardschrifteinstellung in der Textverarbeitung (Grösse in CDKURZBEZ, Art in CDBEZ) */
  public static CDDR_TEXTVERARBEITUNG = 9995 /* "W20"=neue Textverarbeitung (TE_Edit 20 statt 13) */
  public static CDDR_SCHRIFT_OK = 9999 /* Befehl, die Schrift zu verwenden in der Textvearbeitung (s. Code 9998) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PROGOPT = 5009 /* Programmoptionen (Steuerwert in der Kurzbezeichnung) */
  public static CDOPT_CURSOR_RGDET = 1    /* "IREF"=Cursor auf Ihre Referenz statt auf Details setzen */
  public static CDOPT_CURSOR_DSKOPF = 2    /* "HINW"=Cursor auf Hinweis statt auf Standardreise setzen, wenn ein Dossier direkt in den Kopfdaten neu erstellt wird */
  public static CDOPT_TRANSPORTMITTEL = 10   /* "BUS"=Prog.Grundausrichtung Bus-Tourismus statt Flüge, "HOTEL"=für ein Hotel, ""KREUZF"=für Kreuzfahrt, SCHIFF"=für (Seen-)Schifffahrt */
  public static CDOPT_MIN_PROGVERS = 11   /* Minimal erforderliche Programmversion (Default keine Minimalanforderung) */
  public static CDOPT_MIN_ACCESSVERS = 12   /* Minimal erforderliche Accessversion (Default keine Minimalanforderung) */
  public static CDOPT_DEF_POSTEN = 20   /* "OP"=Voreinstellung auf Offene Posten anzeigen (statt alle Posten) */
  public static CDOPT_KREDITKARTENSTORNO = 25   /* "WAHL"=Beim Stornieren einer KK-Zahlung, die über Saferpay erstellt wurde, ist wählbar, ob sie bei Saferpay autom. mitstorniert werden soll */
  public static CDOPT_EP_MOEGLICH = 30   /* "1"=Zur Rechnung können manuell Einstandspreise erfasst werden (EP-Knopf sichtbar), Default=nicht ("1K"=zusätzlich Kontrollknopf) */
  public static CDOPT_ARR_PREIS = 31   /* Aufteilung des Arrangementpreises ("HOTEL"=Jedes Hotel separat ausweisen) */
  public static CDOPT_MWST = 32   /* "KDMWST"=bei Reisebüros Kundenmehrwertsteuer zusätzlich ausweisen */
  public static CDOPT_MWST_BEI_OHNE_PREIS = 33   /* "MWST"=Die MWST wird auch bei neuen Rechnungen ohne Preis ab Standardreise vorgeschlagen */
  public static CDOPT_OHNE_MWST = 34   /* "INKL"=bei RGs mit MWST, die inkl. ausgewiesen werden, erscheint zus. 1 Zeile ohne MWST (Code 59/69) */
  public static CDOPT_KINDER_PREISBASIS = 35   /* "BASISn"=Kinder bei  Preisbasis ignorieren, wenn mind. n% Rab. "NICHT"=100% überh. nicht berücksicht. */
  public static CDOPT_ABREISE_STORNO = 36   /* "OFF"=Abreisedatum bei stornierten Dossiers NICHT aus dem Posten löschen */
  public static CDOPT_VERFALLDAT = 37   /* "AUTOM"=bei Erstrg. autom. berechnen ohne Meldung, "ALLE"=do. bei Zusatzrg., "AUFTR"=nur bei Auftr.rg... */
  public static CDOPT_TN_MELDUNG = 38   /* "MLD"=Bei RB- oder Bu'stellen-Buchung bei RG-Änderung eine Meldung übers Tournet schicken */
  public static CDOPT_BER_BELEGDAT = 39   /* "00"-"99"=Ab Ber.stufe für Belegdat. ändern bei RG-Verbuchung (Default=niemand), ab 3.79 nur noch Zus.Option "S"=Storno Def. urspr. RG-Datum */
  public static CDOPT_PAID_NRMANDANT = 41   /* Mandant für die Vergabe der Adressnr., so fern nicht PAMANDANT */
  public static CDOPT_DSID_NRMANDANT = 43   /* Mandant für die Vergabe der Dossiernr., so fern nicht  DSMANDANT */
  public static CDOPT_PERSID_NRCODE = 45   /* NRCODE (gem. PAMANDANT bzw. Code 41), falls für die Personen individuelle Nummern vergeben werden */
  public static CDOPT_PAID_BUCHSTABE = 46   /* Grossbuchstabe (A-Z, keine Umlaute), wird einer automatisch zugeteilten Partner-Nr. vorangestellt oder an Position gem. Code 47 eingefügt */
  public static CDOPT_PAID_BUCHSTABENPOS = 47   /* Position des Buchstabens gem. Code 46 bei einer automatisch neu zugeteilten Partner-Nr. (Default 1) */
  public static CDOPT_ABLAUF_SUFFIX = 50   /* Suffix für die Ablaufsteuerungen von Offerte, Auftragsbestätigung, Fahraufträgen u.a. rechnungsähnlichen Dokumenten */
  public static CDOPT_FA_PREIS = 51   /* "PREIS"=Preistabelle auch in Fahrauftrag übernehmen */
  public static CDOPT_SB_ABSENDER = 52   /* "STR"=Der Sachbearbeiter für den Versand von Email-Rechnungen wird anhand der Standardreise bestimmt */
  public static CDOPT_VOUCHER = 55   /* "HOTEL"=1 Voucher pro Hotel statt pro Zimmer, "AGNL"=1 Voucher pro Hotelagent inkl. Nebenleistungen */
  public static CDOPT_REISEPROG = 56   /* "KUNDE"=Reiseprogramm pro Kunde statt pro Teilnehmer */
  public static CDOPT_REQUEST = 57   /* "AGENT"=Aufbereitung des Requests pro Agent bzw. Anbieter statt pro Kategorie; "ZI"=pro Zimmer statt pro Kategorie */
  public static CDOPT_PR_MARK = 58   /* "PRMARK"=Beginn+Ende der Texte f.Transp. Hin-, Rückreise+Kategorien bei kundenspez.Papieren markieren... */
  public static CDOPT_EMAIL_RGBEILAGEN = 59   /* "BEST"=Die Beilagen werden standardmässig nur mitgesandt, wenn sie durch den Kunden explizit bestellt wurden */
  public static CDOPT_KATEGORIE = 60   /* "KURZ"=Kurz- statt Langbez. der Hotelkategorie anzeigen (Buchungsmaske) */
  public static CDOPT_ZIMMERNR = 61   /* "ZINR"=Zimmernummer statt -grösse anzeigen beim Schnellbuchen */
  public static CDOPT_KAT_SEL = 62   /* "OFF"=Deaktiviert die automatische Selektion von nachfolgenden Kategorien bei der Schnellbuchung  */
  public static CDOPT_MEHR = 63   /* "DATUM"=Beim Mehr wird das Ab-Datum (z.B. 27.5.) statt des Ab-Tags in der Reise (1. Tag) angezeigt */
  public static CDOPT_BUCHUNGSPOOL = 64   /* Buchungen sind auch für die angegebenen Mandantennrn. (max. 5 ohne Abstand) möglich */
  public static CDOPT_SB_VARIANTE = 65   /* "2"=Neue Version (ab Prog.version 2.60) des Schnellbuchens verwenden */
  public static CDOPT_SB_REG = 66   /* Schnellb.reg.: "KF"=Kursfahrten (Schiff),"PR"=Platzres. (Postauto),"ZI"=Zimmerübers,"-"=ohne Standard... */
  public static CDOPT_LLBEZ = 67   /* "K+L"=Landleistungen im Schnellbuchen mit Kurz- und Langbezeichnung anzeigen (statt nur Langbez.) */
  public static CDOPT_PRBES_STR = 68   /* "ALLE"=Produktbeschreibungen der Standardreise aus dem Schnellbuchen (Word-Knopf) neu erfassen und ändern durch alle Mitarbeiter möglich */
  public static CDOPT_SB_STR_ZKRID = 69   /* ID des Standardreise-Zusatzkriteriums bzw. des erweiterten Kriteriums, welches direkt in der Schnellbuchungsmaske hervorgehoben wird. */
  public static CDOPT_FAK_ZUSATZ = 70   /* "INF"=angewählte Zusatzleistungen bei alle Leistungen auch Infants zuweisen */
  public static CDOPT_DSNOTIZ_WICHTIG = 71   /* "WICHTIG"=Wichtige Dossiernotizen werden beim Aufrufen des Dossiers automatisch angezeigt */
  public static CDOPT_TSR = 72   /* "ON"=Ticket Sales Report-Modul wird aktiviert */
  public static CDOPT_LINKMANDANT = 73   /* Mandant, der die Schifffahrtsdossiers beinhaltet (Eintragen beim Gastromandanten) */
  public static CDOPT_DS_ADRSUCHE = 74   /* "ADR1"=Adressmaske anzeigen, wenn nur ein Treffer bei Suche in Dossier */
  public static CDOPT_DS_MUTMAND = 75   /* Dossiers der aufgeführten Mandanten können ebenfalls mutiert werden (max. 5 ohne Abstand) */
  public static CDOPT_LEIST_MARKIEREN = 76   /* "CTL"=mehrere Leistungen im rechten Tln.reg. nur durch Drücken der Control-Taste gemeinsam markierbar */
  public static CDOPT_STORNOCODE = 77   /* "AUF"=Auswahl eines Stornogrundes bei Auftragsdossiers obligatorisch; "ALLE"=do. bei allen DS; "POFF"=do. bei Pauschalreisenofferten ohne RG */
  public static CDOPT_DEF_STDREISE = 78   /* "AUF"=Letzte gebuchte Auftragsfahrten-Standardreise als Default beim Kunden festhalten */
  public static CDOPT_GASTROTEXT = 79   /* "TXB"=Der Gastrotext wird als Texdok. in einer Datei gespeichert (statt unformatiert in Dossierbem.) */
  public static CDOPT_TR_NICHT_AUTOM_LADEN = 80   /* "ON"=Transporte werden im Schnellbuchen bei Programmen mit Dauer 0 Tagen nicht automatisch geladen */
  public static CDOPT_REISEBEGINN_ANPASSEN = 81   /* "OFF"=Reisebeginn nicht autom. anpassen, wenn dieser nach Änderungen vor der ersten Leistung liegt */
  public static CDOPT_STATISTIKCODE = 82   /* "OBLIG"=Statistikcode bei Inhousebuchung oblig.; "RG"=do. vor RG; "MAN"=do. man.; "-"=fak.; "IFAK"=im Internet fakultativ */
  public static CDOPT_FREMDDOSSIERS = 83   /* "OFF"=Dossiers von fremden Mandanten in der Dossierübersicht nicht anzeigen */
  public static CDOPT_ADRZUS_DSHINWEIS = 84   /* Nr. des Adresszus.krit., das von der 1. Adresse im Dossier autom. in den Dossierhinweis kopiert wird */
  public static CDOPT_ADRZUS_LIMITE = 85   /* Nr. des Adresszusatzkriteriums (Kreditlimite), das zus. mit den Ausständen des Kunden angezeigt wird */
  public static CDOPT_TLNBEM_TAGESFAHRTEN = 86   /* "OFF"=Preise bei Tagesfahrten-Dossiers nicht auf die Teilnehmerbemerkungen übertragen */
  public static CDOPT_LLZUS_MAHLZEITEN = 87   /* Nr. des Landleistungs-Zusatzkriteriums für Mahlzeiten bei CRS-Buchungen (v.A. CETS)  */
  public static CDOPT_ADRZUS_TN_AUTOM = 88   /* Nr. des Adr.zuskrit.-Häkchens, das veranlasst, dass Daten bei Rg-Verbuchen autom. über Tournet gesendet werden. */
  public static CDOPT_RQ_WARNUNG = 89   /* "CHG"=Warnung nur, wenn die Anz. Freiplätze seit der Anzeige unter die benötigte Anzahl gefallen ist */
  public static CDOPT_DATUMFORMAT = 90   /* Datumdarstellung in der Textverarbeitung (Default= "DD.MM.YY") ERSETZT DURCH CODE 5013/40. */
  public static CDOPT_RGNR = 91   /* "O.PKT."=Rechnungsnummer wird auf der Rechnung ohne Punkt dargestellt */
  public static CDOPT_REFNR = 92   /* spez. Darstellung der ESR-Referenznummer. "LX"=gem. Crossair (Bankref./Kostentr./Rgnr. ohne Mandant) */
  public static CDOPT_ZEIT_FMT = 93   /* "24"=Mitternacht (0:00) wird bei Texten als "24:00" dargestellt. */
  public static CDOPT_TXB_UNVERAENDERT = 94   /* "T"=Bei den Teilnehmer-Textbausteinen werden abschliessende Zeilenvorschübe nicht mehr abgetrennt */
  public static CDOPT_SB_FELDER = 95   /* "VN"=Zusammengesetzte Sachbearbeiter-Felder aus Vor- und Nachnamen zusammensetzen (Default Anrede, 1. Buchstabe Vorname, Nachname) */
  public static CDOPT_WMAT_SORT = 100  /* "AUFST"=Sortierung des Werbematerials in der Auswahlbox auf- statt absteigend nach Code */
  public static CDOPT_OBLIG_TELCODES = 101  /* Welche Telefoncodes bei Kundenadr. oblig. "B" dahinter für Bemerk., "H" nur auf Homepage, "I" nur intern. "T"=1 Telnr., "R"=gem. Reiseart */
  public static CDOPT_WMAT_MANDANT = 102  /* 2-stelliger Eintrag. Dazugeh. Codes der Codeart 1001 haben die gleichen ersten 2 Stellen in CDKURZBEZ */
  public static CDOPT_ADR_EXKL_ZUSKRIT_H = 103  /* "1"-"6"=Erlaubt das Setzen/Löschen des entsprechenden exklusiven (ZKROBLIG=3) Zusatzkriteriums */
  public static CDOPT_ADR_PAUSCHAL_ZUSKRIT = 104  /* "1"-"4"=Adresszusatzkrit., das bei Pauschalreisendossiers oder WMat-Bestellung autom. gesetzt wird */
  public static CDOPT_ADR_AUFTRAG_ZUSKRIT = 105  /* "1"-"4"=Adresszusatzkriterium, das  bei Autragsfahrten-Offerten automatisch gesetzt wird */
  public static CDOPT_MAND_MARKE = 106  /* "1"-"6"=Adresszus.krit. des Mandanten für die Markenzugehörigkeit. Wird im Kd.-Finanzstatus angezeigt */
  public static CDOPT_ADR_LAND = 107  /* xx=Bei Adressen ohne Land stattdessen Mandant.Land_Default speichern, bei "xx" kein Land speichern */
  public static CDOPT_ADR_MARKETING_ZUSKRIT = 108  /* "1"-"6"=Adresszuskrit. Fakultative Auswahl, wie der Kunde von uns erfahren hat, im Tourdata rot */
  public static CDOPT_ADR_AB_BERECHTIGUNG = 109  /* **ALT** Mind. Berechtigungsstufe, ab der Adressen neu erfasst, geändert od.gelöscht werden dürfen (Reiter 1 inkl. Zuskrit, Komm,Kond) -3.78z */
  public static CDOPT_LDAT_MAXJAHRE = 110  /* "1"=Leistungsdaten werden bis max. 1 Jahr zurück (1.1. des Vorjahres) angezeigt */
  public static CDOPT_DEST_MANDANT = 111  /* "PR"=Destination gem. PRMANDANT statt fix "01" */
  public static CDOPT_KONT_BERECHNUNG = 112  /* "BER"=Kontingentsberechnungsart bei Landleistungen zulassen (Default=nur Nachtkontingente) */
  public static CDOPT_KONT_FREIGEBEN_NACH_STORNO = 113  /* Landleistungsart, bei der das Kontingent beim Storno nach Rückfrage entsprechend herabgesetzt wird */
  public static CDOPT_REISEPROG_TEXTE = 114  /* "RG"=Reiseprogrammtexte von Rechnung nehmen, falls keine individuellen Reiseprog.texte vorhanden sind */
  public static CDOPT_PR_NOTIZEN_AENDERN = 115  /* "SBU"=Produktnotizen können beim Buchungsvorgang verändert werden, auch wenn der Sachbearbeiter ansonsten keine Berechtigung hat */
  public static CDOPT_HALTEORT_DEST = 116  /* "FAK"=Destination zu Halteorten möglich */
  public static CDOPT_KOND_MANDANT = 117  /* "PR"=Konditionen, d.h. Kommissionen und Rabatte gem. PRMANDANT führen (statt generell gem. PRMANDANT_STR und nur bei LL-Preisen gem. PRMAND) */
  public static CDOPT_KONT_AUSGEGBUCHT = 118  /* "RQINT"=Beim manuellen Kontingentsstatus 5 "ausgebucht" ist ein Request programmintern noch möglich */
  public static CDOPT_ANBIETER_AUF_DOK = 119  /* "RSP"=Anbieter- und Agentenadressen dürfen nur auf Reiseprogramm + App angezeigt werden (nicht auf Offerte, Rechnung, b2b, Internetbuchung) */
  public static CDOPT_ESR_ALTERNATIV_SUCHE = 120  /* Wenn ein ESR beim Einlesen nicht zugeordnet werden kann, erfolgt ein 2. Versuch mit diesem Mandanten */
  public static CDOPT_ESR_PROTOKOLL = 121  /* "MWST"=Auf ESR-Protokoll MWST mit ausweisen */
  public static CDOPT_EZ2_TYP = 122  /* 2. zu verarb. Art von Einzahlungen: "V11" = 2. Kanal Standardformat, "STA"=MT940-Record, "IND"=indiv. */
  public static CDOPT_EZ_KONTO_PRUEFEN = 123  /* 1-10 Stellen Kontonr.=Zahlungen für andere Konti werden ignoriert + nicht protokolliert; "KTO"=do. jedoch das im TD hinterlegte Konto prüfen */
  public static CDOPT_ESR_BELEGDAT = 124  /* "BUDAT"=Buchungsdatum als Belegdatum übernehmen (Default Valutadatum), "BUDAT1"/"BUDAT2"=do. nur für Standard- bzw. Alternativkanal */
  public static CDOPT_EZ_KONTO_PRUEFEN2 = 125  /* 1-10 Stellen Kontonr.=2. zu prüfendes Konto analog Code 123, jedoch verbuchen mit Buchungsart "11" */
  public static CDOPT_EZ_TYP = 126  /* "TXT"=Bei der normalen ESR-Datei wird auch der freie Text nach Dossier- und Kundennummern untersucht, wenn keine ESR-/QR-Ref vorhanden ist */
  public static CDOPT_ESR_MANDANTEN = 127  /* "CD"=ESR nur für Posten von Mandanten mit gleichem CDMandant einlesen (bei Terminalserver automatisch) */
  public static CDOPT_MAND_FARBE = 130  /* Wenn angegeben, wird der Mandant in der Farbe des entsprechenden Codes der Codeart 5 angezeigt */
  public static CDOPT_EMAILVERSAND = 131  /* "OUTLOOK"=E-Mails werden über Outlook via OLE verschickt */
  public static CDOPT_EMAIL_BCC = 132  /* "ABS"=Bei E-Mails über einen SMTP-Server BCC an den Absender schicken, "LOGIN"=do. an den angem. SB */
  public static CDOPT_EMAIL_ABSTURZ = 133  /* "STD"=Fehler-E-Mails beim Programmabsturz werden wie normale E-Mails verschickt (Default über SMTP-Server gem. Codeart 5059, Codes 1 uff.) */
  public static CDOPT_HINTERGRUNDFARBE = 134  /* Wenn angegeben, wird die Hintergrundfarbe (Icons, Statuszeile, Rahmen) in der Farbe des entsprechenden Codes der Codeart 5 angezeigt */
  public static CDOPT_SPRACHCODE_D_BEVORZUGT = 135  /* Sprachcode der bevorzugten Deutschvariante (für D-CH bei DE-Mandanten bzw. D-DE bei CH-Mandanten) */
  public static CDOPT_SPRACHCODE_D_AUTOM_CH = 136  /* "KD"=Ersetzt in allen Kundentexten der deutschen Sprache "?" automatisch durch "ss". In Zukunft ist auch der Ersatz ganzer Begriffe denkbar. */
  public static CDOPT_DS_VERFALL = 140  /* "DS"=Nur Verfalldatum von Dossiers des eigenen Mandanten prüfen */
  public static CDOPT_FB_REMINDER = 141  /* "INET"=Fragebogen-Erinnerung nur bei Internbuchungen (Default, wenn E-Mailadresse vorhanden) */
  public static CDOPT_ANZ_FB_REMINDER = 142  /* "0"=keine Fragebogen-Erinnerungen, "1"=nur 1. Erinnerung, auch wenn 2 definiert sind */
  public static CDOPT_SELL_REPORT_REQUESTS = 143  /* "RQ"=Bestätigte Requests bei der Sell & Report-Tagesverarbeitung gem.Code 1040, Option "Z" ebenfalls aufbereiten, sofern ab CDMUTDAT erfasst */
  public static CDOPT_DI_MAND = 150  /* Einsehbare Dispositionsmandanten von-bis (z.B. '01-29'). Default=alle mit gleichem PRMANDANT */
  public static CDOPT_DI_MAND_PERS = 151  /* DIMANDANT, dessen Chauffeure und Reiseleiter ebenfalls direkt eingesetzt sowie dessen Aufträge mitdisponiert werden können */
  public static CDOPT_DI_ZEITEN_NACHFUEHREN = 152  /* "MAN"=Die anderen Zeiten werden nicht mehr autom. geändert bei nachträglicher Änderung einer Zeit */
  public static CDOPT_DI_LESEBERECHTIGUNG = 153  /* **ALT** Minimal nötige Stufe für nur Leseberechtigung (Default = keine Nur-Leseberechtigung) bis Version 3.78z */
  public static CDOPT_DI_FAHRAUFTRAG = 154  /* "SP"=Fahrauftrag kann auch bei aktivierter Disposition gespeichert werden */
  public static CDOPT_DI_OFFERTE_IN_DISPO = 155  /* "DEF"=Bei Offerte Häkchen "In Disposition" angehakt vorschlagen (Default sonst nur bei Schiff) */
  public static CDOPT_DI_FAHRER_SORT = 156  /* "NAME"=Aushilfsfahrer in erster Linie nach Name sortieren (Default absteigend nach Anzahl Einsätzen); "AUFTNAME"=do. zuerst die mit Aufträge */
  public static CDOPT_DI_FAHRER_FILTER = 157  /* "FAHRER"=In der Dispoübersicht-Fahrerauswahl als Default nur Fahrer anzeigen (statt auch Aushilfen) */
  public static CDOPT_HOMANDANT = 158  /* "DI"=Halteortmandant gem. Disposition statt gem. Produkt */
  public static CDOPT_DI_ABREISEZEIT = 159  /* "VON"=In Transportbew. veränderte Abreisezeiten wie vor V. 3.58d in der Garagenzeit (VONDAT) statt neu in Abfahrtszeit (STARTDAT) schreiben */
  public static CDOPT_TRB_EIN_AUSSTIEG = 160  /* "SR"=Die Transportbewegung kann auf Standardrouten verweisen statt die Ein-/Ausstiegsorte übernehmen */
  public static CDOPT_TOURENPLAN = 161  /* "TP"=Tourenplan möglich (Standardrouten mit Blockeinteilung, Zuordnungs-Halteorte) */
  public static CDOPT_BUKLASSEN_ABGLEICH = 162  /* "KO"=Freie Buchungsklassen werden vom Komet regelmässig (Zeit gem. Codeart 5011, Code 81) übernommen */
  public static CDOPT_FLUGHAFENPLAN_AUTOM = 163  /* "FLGPL"=Flughafenplan detailliert automatisch verschicken (in Tournet-DB: Codeart 5011, Code 137; Zeitpunkttabelle Code 73) */
  public static CDOPT_PROD_MAN_FLUEGE = 164  /* Wenn im Dossier Flüge manuell hinzugefügt werden, wird das Produkt mit dem angegebenen Kürzel vorgeschlagen statt wie beim Import "crs" usw. */
  public static CDOPT_TRK_TRG = 165  /* Transportgesellschaftsinformationen werden in Transportketten, die mit diesem Kürzel beginnen, abgespeichert */
  public static CDOPT_TR_OBLIG_PRUEFEN = 166  /* "INET"=Die Einstellungen Transport "obligatorisch" oder "mind. one way obligatorisch" in der Standardreise werden nur im Internet geprüft */
  public static CDOPT_STRECKEN_AUFTEILEN = 167  /* "DEF"="Strecken detailliert auf Dokumente" wird bei neuen Transportketten defaultmässig angekreuzt vorgeschlagen */
  public static CDOPT_TRK_KEIN_REISEPROGRAMM = 168  /* "NICHTS"=Wenn ein Dossier mind. eine TRK mit NAECHSTE_TICKETNR= -1 hat, wird das ganze Dossier beim Aufbereiten des Reiseprogramms ignoriert */
  public static CDOPT_DSG_REQUEST = 170  /* "RTF"=Request-+Buchungsbestätigungstexte werden im Original zus. mit Dossiergeschichte gespeichert */
  public static CDOPT_DSG_REISEPROG = 171  /* "RTF"=Reiseprogrammtexte werden im Original zusammen mit der Dossergeschichte gespeichert */
  public static CDOPT_DSG_VOUCHER = 172  /* "RTF"=Vouchertexte werden im Original zusammen mit der Dossiergeschichte gespeichert */
  public static CDOPT_DSG_RECHNUNG = 173  /* "RTF"=Rechnung beim Ausdruck im Original zusammen mit Dossiergeschichte speichern */
  public static CDOPT_DSG_MAN_AENDERBAR = 179  /* "KURZ"=Manuelle Dossiergeschichtseinträge können nur geändert werden, solange das Dossier im Speicher ist, "EIG"=eigene Einträge sind immer änderbar */
  public static CDOPT_AG_PEND_DOSSIER = 180  /* "STR"=Pendente Dossiers beim Standardreise- statt dem Dossier-Sachbearbeiter in der Agenda, "STR-F", do. jedoch für nicht kontr. Fragebögen */
  public static CDOPT_AG_AUTOM_EINTRAEGE = 181  /* "MAN"=Automatische Agendaeinträge nicht anzeigen, wenn manuelle Einträge vorhanden sind "RQ"=nur Requests nicht anzeigen */
  public static CDOPT_AG_ANZAHLUNG = 182  /* 1-99=Fällige Anzahlungen n Tg. nach RG-Stellung in Agenda prüfen (keine Prüfung auf Zahlungseingang); + "F1"=in Agenda dunkelgrün anzeigen */
  public static CDOPT_WARNDATUM = 190  /* "WD"=Warndatum beim Standardreise-Reisedatum obligatorisch, sofern Abreisedatum in Zukunft */
  public static CDOPT_KOSTENTRAEGER = 191  /* "OFF"=Kostenträger ausblenden in Standardreise, Rechnung und Preiszeile; "STR"=Als Kostenträger automatisch Kürzel der Standardreise in RG */
  public static CDOPT_NL_PRVPRIOCODE_DEFAULT = 192  /* "FAK"=Bei Verbindungen zu Nebenleistungen PRVPRIOCODE 0 (fakultativ) vorschlagen (Default 1=oblig.) */
  public static CDOPT_STR_MARKETING_MAILS = 193  /* "L"=Beim Löschen einer Standardreise Mail an Marketing-Zuständigen */
  public static CDOPT_STR_ERSTVERSAND_ZUSKRIT = 194  /* "1"-"4"=Standardreise-Zusatzkriteriumshäkchen, das bestimmt, ob die Reiseunterlagen schon beim Erstversand mitgeschickt werden */
  public static CDOPT_STR_LDATTEXT = 195  /* "BUHINW"=Das Textfeld im Leistungsdatum wird für Buchungsinformationen, die dem Kunden mitgeteilt werden, verwendet. */
  public static CDOPT_STR_ZL_INTERN_NICHT_OBLIG_ZUSKRIT = 196  /* "1"-"6"=Standardreisezusatzkriterium, das eine Zusatzleistungsgruppe beinhalten kann, deren ZL oblig.Auswahl intern nicht obligatorisch sind */
  public static CDOPT_STR_MARGE_MAND = 197  /* Dossiers von Standardreisen dieser Mandanten, die die gleiche PRKURZBEZ haben, werden mit ausgewiesen */
  public static CDOPT_STR_LDATINDIV_CODE = 198  /* nnnn=ZKRID des Standardreise-Zusatzkriteriumshäkchens (2221-2224) als Vorgabe für den individuellen Code des Leistungsdatums */
  public static CDOPT_STR_TR_UPSELLING_ZUSKRIT = 199  /* Standardreisen-Zusatzkriteriumshäkchen, das gesetzt sein muss, damit das Transport-Upselling erlaubt ist */
  public static CDOPT_TLNBEM_SCHIFFFAHRT = 200  /* "BEM"=Teilnehmerbem. sind  intern+nicht für die Tischkarte bestimmt; "NTK"=nur TK, kein Gastrostatus */
  public static CDOPT_PR_ZUSINFO = 201  /* "RG"=In Produkt-Zusatzinfofenster auch Rechnungsinformationen abfragen und für alle LL möglich */
  public static CDOPT_NL_AUSWAHL = 202  /* "OBLIG"=Pro Dossier für alle PAX die gleiche Auswahl bei Nebenleist. Obligatorisch Default/Alternativ */
  public static CDOPT_PR_ZUSINFO_ORT = 203  /* 1=Destination statt Halteort für Vorschläge des Abhol-/Rückbringortes verwenden, "AUSW"=nur Auswahl aus Halteorten, keine freie Eingabe */
  public static CDOPT_LLZUS_EINSTIEGSZEIT = 204  /* Zusatzkriterium der Landleistung (1-6) für die Einstiegszeit der Rückfahrt */
  public static CDOPT_BUABL_ADRESSE = 205  /* "ADR"=Wechsel in Adressverwaltung bei internem Buchungsblauf zwingend */
  public static CDOPT_FB_INTERN = 206  /* "NK"=automatisch ausgefüllte Fragebögen auch intern auf nicht kontrolliert setzen */
  public static CDOPT_EP_VORSCHLAG = 207  /* "NULL"=Bei LL, die nicht in der STR sind, EP 0 vorschlagen statt gem. LL */
  public static CDOPT_STR_TLN_BEM = 208  /* "+"=Im Tln.bem.feld zur Standardreise muss die Eingabe mit "+" beginnen, danach "1"-"9", anschliessend nur Zahlen oder Blanks. "M"=Mobiltel. */
  public static CDOPT_RG_KDSB = 209  /* nn=Wenn beim Kunden eine Person mit diesem Funktionscode vorhanden ist, wird diese automatisch als Rechnungsempfänger vorgeschlagen */
  public static CDOPT_TLN_ZL = 210  /* "7"=Mehr als 6 Zusatzleist. erlaubt, ab 7. in sep. Tabelle Teilnehmerzusatzleistung speichern; "1"=do. jedoch alle in sep. Tabelle speichern */
  public static CDOPT_TLN_MOBILTEL = 211  /* "FAK"=Mobiltel.nr. pro Teilnehmer abfragen (Eingabe fakultativ) + bei der Person speichern, "OBLIG"=do. obligatorisch; Format gem. 5009/323 */
  public static CDOPT_PR_ZUSINFO_NICHT_GEM_FLUG = 212  /* "f", "t", "w" (oder Kombination): Die Beginn-/ Endzeiten dürfen bei diesen Produkt-Zusatzinformationen NICHT gem. Flug vorgeschlagen werden */
  public static CDOPT_PR_ZUSINFO_RESNR = 213  /* "EINH"=Mehrere durch "," oder "/" getrennte Reservationsnrn. werden pro Einheitsnr. auf die Teilnehmerprodukte verteilt (Ticketnr-Feld) */
  public static CDOPT_BU_OHNE_KONTINGENT = 214  /* "ON"=Buchung ohne Kontingent möglich */
  public static CDOPT_SITZPLATZ_AEND_DSSTATUS_ZURUECK = 215  /* "IMMER"=Setzt bei einer Sitzplatzänderung den Dossierstatus zurück auf "nicht alle Teilnehmer/Leistungen zugeordnet" */
  public static CDOPT_GS_FREMDMANDANT = 216  /* Pseudo-Kundennr., über die die automatische Kontokorrent-Buchungen erstellt werden, wenn ein GS eines Fremdmandanten eingelöst wird */
  public static CDOPT_BU_PRNOTIZ = 217  /* "B"=Bestätigung nötig (Geschichtseintrag), "BD"="B"+Text als Datei, "N"=normale Notizen auch anzeigen, "S"=Bereits beim Schnellbuchen zeigen */
  public static CDOPT_KK_BEI_MITTEILUNG = 218  /* "ON"=Kreditkartenzahlungen übers Internet sind auch erlaubt, wenn gleichzeitig Mitteilungen an den Veranstalter eingegeben werden */
  public static CDOPT_TEILREQUEST = 219  /* "KTPER"=Bei Requests werden keine Teilperioden angefragt, sondern immer die ganze Periode */
  public static CDOPT_ANZLG_RUNDUNG = 220  /* Rundung prozentmässiger Anzahlungen (statt gem. Mandant) */
  public static CDOPT_ZLG_AUSWEISEN = 221  /* "IND"=Zahlungen mit individuellen Buchungstexten ausweisen; "NIE"=Zahlungen gar nicht ausweisen */
  public static CDOPT_PZL_UMSATZKONTO = 222  /* "MAN"=Auf jeder manuell hinzugefügten Preiszeile muss ein Umsatzkonto angegeben werden */
  public static CDOPT_EP_RUNDUNG = 223  /* Rundungsfaktor für Einstandspreise (Default analog Verkaufspreise) */
  public static CDOPT_ZLG_VORSCHLAGEN = 224  /* "OFF"=Zahlung nie vorschlagen bei Folgerechnung */
  public static CDOPT_TOTAL_IN_FW = 225  /* "MOEGL"=zusätzliches Total in Fremdwährung möglich (Verbuchung in CHF) */
  public static CDOPT_ZLG_ZUORDNUNG = 226  /* "RG"=Zahlung gehört zur Rechnung (Belegnr. identisch) statt zum Posten */
  public static CDOPT_EXTERNE_RG = 227  /* buart=Wenn diese Buchungsart (=Zahlung) in Rechn. vorkommt, wird ein offener 0-Betrag nicht angezeigt */
  public static CDOPT_EP_ANZEIGE = 228  /* "POPUP"=Einstandspreise werden als separates Popup-Fenster angezeigt statt rechts vom VP */
  public static CDOPT_CRS_PREISE = 229  /* "EP"=Preise eingezogener CRS-Leistungen werden nur auf Einkaufsseite berücksichtigt */
  public static CDOPT_ANZ_KOMMASTELLEN = 230  /* "GLEICH"=Die Anzahl Nachkommastellen (0-2) bei Anzahl + Kommission der längsten Variante anpassen */
  public static CDOPT_EP_DEFAULTPRODUKT = 231  /* "STR"=Vorgeschlagenes Produkt für manuelle EP-Zeilen ist die Standardreise (VPRLAUFNR 0; Default gem. Zeile darüber) */
  public static CDOPT_FWKAT_RUNDUNG = 232  /* Rundungsfaktor für Währungsumrechnungen von Landleistungs-/Kategorienpreisen anstelle der Standardrundung */
  public static CDOPT_FWKAT_ABRUNDEN = 233  /* Hunderterspezialregel bei Wäh.umrechnungen von LL-/KAT-Preisen: Abrunden auf letzten Wert des vorg. Hunderters, wenn letzte Stellen<=Eintrag */
  public static CDOPT_FWRG_MWST = 234  /* "FW"=Bei Fremdwährungsrechnungen wird die MWST ebenfalls in der Fremdwährung ausgewiesen und verbucht (Default Landeswährung) */
  public static CDOPT_BST_MWST = 235  /* "1"=Bei Buchungsstellenbuchungen pauschale Kommissionsbesteuerung mit erstem MWST-Satz (generiert PZLCODE 49 erstellen) */
  public static CDOPT_MWST_NEU_GEMAESS = 236  /* "DSERF"=Neue MWST-Sätze gem. Dossiererfassungsdatum berücksichtigen (Default=Abreisedatum); "ANNU"=Bei Annullationsrg. gem. RG-Erfassungsdat */
  public static CDOPT_RG_PREISBER_PZL_KOPIEREN = 237  /* "MAN"=Beim Ersetzen einer Rechnung mit Preisberechnung werden alle manuellen Preiszeilen von der alten Rechnung übernommen */
  public static CDOPT_DEFAULT_KOMMISSION = 238  /* "KOM"=Bei Produkten ohne Kommission (auch nicht 0%) gilt die normale Kommission der Partneradresse unabhängig vom Produkthäkchen */
  public static CDOPT_RABATTTAGE_GUELTIGKEIT = 239  /* "EXAKT"=Die Rabatttage einer ZL gelten nur, wenn die gebuchte Anzahl Nächte exakt einer Dauer der ZL entspricht (Default >= und addieren) */
  public static CDOPT_KOSTEN_AB_BER = 240  /* **ALT** Die Kostenfelder + EP im Dossier können ab dieser Berecht.stufe verändert werden (Default 50) => ab 3.79 durch Zuständigkeit ersetzt */
  public static CDOPT_AUTOM_BESTAETIGUNG = 241  /* "HPRQ"=Bei Requestbuchungen über Homepage bei der Verbuchung der 1. RG autom. Bubest.+FB-Link mailen */
  public static CDOPT_AUTOM_STORNO = 242  /* "RG"=Beim Stornieren des Dossiers Rechnungen automatisch mitstornieren; "GS"=do. eingelöste Reisegutscheine; Optionen kombinierbar */
  public static CDOPT_RG_OBLIG = 243  /* "AKTIV"=Dossierstatus "Nicht alle RG", wenn bei nicht stornierten Pauschalreisendossiers keine RG */
  public static CDOPT_DOKART_AUSWAHL = 244  /* "VA"=Muss im Voraus gewählt (via Popup) und kann nachträglich nicht mehr verändert werden */
  public static CDOPT_DS_ZUSSUCHE = 245  /* "FREMD"=Im Dossier-Kopfdatenregister kann zusätzlich nach der Fremddossiernr. gesucht werden */
  public static CDOPT_EMAIL_AB_DOSSIER = 246  /* "EMAIL"=Freies Mail direkt aus Doku.übersicht verschicken + in der Geschichte speichern aktivieren */
  public static CDOPT_DOK_SORT = 247  /* "NEU"=Standardmässig neueste Dokumente zuoberst anzeigen statt zuunterst */
  public static CDOPT_DS_SPERREN = 248  /* **ALT** "KURZnn"=Dossiers kurzfristig sperrbar (nn=fakultativ, sperren nur für Mitarbeiter mit mindestens dieser Berechtigungsstufe) -3.78z */
  public static CDOPT_ALTER_ANZEIGEN = 249  /* "ALLE"=Im Teilnehmerregister bei allen Namen mit hinterlegtem Geburtsdatum in Klammern das Alter bei Abreise+letztem Tag der Reise anzeigen */
  public static CDOPT_MARGE = 250  /* "AUFR"=Aufrechnungsfaktor statt Marge berechnen */
  public static CDOPT_BU_KDZUSKRIT_NOTIZ = 251  /* Kundenzusatzkriterium (1-6), das bei Buchungen wie wichtige Notizen automatisch in einem Popup angezeigt wird (ausser CDKURBEZ endet auf *) */
  public static CDOPT_DS_KDNOTIZ_WICHTIG = 252  /* "INET"=Bei Internetdossiers (Herkunft CDHERK_INET_VON - CDHERK_INET_BIS) wichtige Kundennotiz anzeigen, wenn Erf.vis noch gleich Mut.vis. */
  public static CDOPT_DS_KDZUSKRIT_ANZEIGEN = 253  /* Kundenzusatzkriterium (1-6), das bei Kundenart "Kunde" im Dossier-Teilnehmerregister hinter der Adresse angezeigt wird bzw. "???", wenn leer */
  public static CDOPT_STORNO_GSDS_RUECKERSTATTUNG = 254  /* "FRAGE"=Beim Storno eines Rückerstattungsgutscheindossiers zurückfragen, ob GS-Betrag dem ursprünglichen DS wieder gutzuschreiben ist */
  public static CDOPT_BST_ABR_KDZUSKRIT_NACH_ABREISE = 255  /* "1"-"10"=Adresszusatzkriteriumshäkchen für Buchungsstellen, die auf der Provisionsabrechnung nach Abreise statt Buchung abgerechnet werden */
  public static CDOPT_STR_BSTABR_ABREISE = 256  /* Standardreise, die für Buchungsstellenabrechnungen nach Abreisedatum verwendet wird */
  public static CDOPT_STR_BSTABR_BELEG = 257  /* Standardreise, die für Buchungsstellenabrechnungen nach Belegdatum verwendet wird */
  public static CDOPT_DS_GLEICHE_TLN_PRUEFEN = 258  /* Prüfen, ob ein Dossier maximal vor dieser Anzahl Tage (1-99) mit gleichem Teilnehmernamen und -vornamen erfasst wurde. Wenn ja, DSG-Eintrag */
  public static CDOPT_DS_ZL_GRUPPEN_ANZEIGEN = 259  /* "AUSGEW"=Gruppen mit Zusatzleist. standardmässig nur geöffnet anzeigen, wenn mindestens eine daraus ausgewählt wurde (Default immer) */
  public static CDOPT_FARBE_PLATZ_AUSGEWAEHLT = 260  /* Farbcode gem. Codeart 5 für aktuell ausgewählte Sitzplätze und Zimmer (Default: weisse Schrift auf dunkelblauem Hintergrund) */
  public static CDOPT_FARBE_PLATZ_NICHT_VERFUEGBAR = 261  /* Farbcode gem. Codeart 5 für aktuell nicht verfügbare Sitzplätze und Zimmer (Default: schwarze Schrift auf hellgrauem Hintergrund) */
  public static CDOPT_TOLERANZ_HINTERGRUNDFARBE = 269  /* Toleranzwert 0-99 für das Ausfüllen der Hintergrundfarbe (0=keine Toleranz; 30=Default, mittlere Toleranz; 99=grosse Toleranz) */
  public static CDOPT_GROESSE_HAUPTBILD = 280  /* Grösse für Hauptbild-Felder (Default 4 oder kleiner) */
  public static CDOPT_GROESSE_BILD = 281  /* Grösse für übrige Bildfelder (Default 2 oder kleiner) */
  public static CDOPT_RB_GUTSCHEINCODEABGABE = 282  /* "NVERB"=Bei Reisebüros werden abgegebene Gutscheincodes auf einem nicht verbuchten Dokument statt auf der Rechnung aufbereitet */
  public static CDOPT_GUTSCHEINRG_BETREFF = 283  /* "STR"=Bei normalen Rechnungen in Gutscheindossiers wird beim E-Mailbetreff die Standardreisenbezeichnung statt der Rechnungsart eingesetzt */
  public static CDOPT_PAID_ZAHLBAR_AN = 284  /* Pseudokundennr., die auf dem QR-Zahlschein bei den Mandantenfeldern statt des Mandanten selbst verwendet wird (d.h. Kontoinhaber abweichend) */
  public static CDOPT_DRUCKERDIALOG = 285  /* "DRUCK"=Der Druckerdialog erscheint erst beim Druck (Default beim Öffnen des Dokuments) */
  public static CDOPT_ZLTEXT_ZUSFASS = 286  /* "ANZ"=In Textfeldern von Zusatzleistungen wird die Anzahl aufbereitet, wenn mehr als ein Teilnehmer verarbeitet wird */
  public static CDOPT_TLNBEM_ZUSFASS = 287  /* "GLEICH"=Teilnehmerbemerkungen in Textbausteinen zusammenfassen und die Anzahl davorsetzen, wenn sie vollständig identisch sind */
  public static CDOPT_RQ_TXB_ABLAUF = 288  /* "RA"=Für einzelne Reisearten können separate Request-Ablaufsteuerung hinterlegt werden (Name  "rqra" + Code der Reiseart) */
  public static CDOPT_INFOTXB_ZLVORSCHUB = 289  /* Anzahl Zeilenvorschubzeichen, die zwischen zwei Informationstexten (Texten über allgemeine Regeln) auf der gleichen Ebene hinzufügt werden. */
  public static CDOPT_AUSW_VORSCHAU = 300  /* "SEITE"=In Vorschau von L&L-Berichten standardmässig ganze Seite zeigen (Default Breite vollständig) */
  public static CDOPT_AUSW_BERECHT = 301  /* "ZUST"=Die Berechtigungen für die L&L-Auswertungsgruppen werden über Zuständigkeiten gesteuert (Default gem. Berechtigungsstufe) */
  public static CDOPT_PERSID = 320  /* "TRM"=Im Reiter Transportmittel - Personal kann manuell eine eindeutige Personalnr. (PERSID) von Mitarbeitern eingegeben werden */
  public static CDOPT_DATENSCHUTZ = 321  /* "DSB"=Datenschutzbearbeitungsinfos (+"A"=nur für Auslandskunden, +"Zn"="keine Werbung" zus. über ZKR-Häkchen n) */
  public static CDOPT_TEL_SCHNITTSTELLE = 322  /* "CALLTO"=Tel.nrn können bei auf dem PC installierter Tel.software direkt aus Tourdata heraus über die Windows-Schnittstelle angerufen werden */
  public static CDOPT_TEL_FORMAT = 323  /* "+"=Telefonnummern müssen mit "+" beginnen, danach "1"-"9", anschliessend nur Zahlen oder Blanks. */
  public static CDOPT_UMFRAGE_DANKE_GUT = 324  /* "GBnnnn"=Wenn die Gesamtbewertungsfrage mindestens die Bewertung nnnn erhalten hat, auf der Dankesseite alternativen Text 5013/2205 anzeigen */
  public static CDOPT_KEINE_UMFRAGE_ZUSKRIT = 325  /* "1"-"9"=Adress-Zusatzkriteriumshäkchen, das für "keine Umfrage erwünscht" verwendet wird */
  public static CDOPT_EMAIL_EINDEUTIG = 326  /* "KD"=Auf Kundenebene darf die gleiche E-Mailadresse nicht doppelt vergeben werden */
  public static CDOPT_MEHRERE_EMAILADR = 327  /* "KDM"=Auf Kundenebene sind im E-Mailfeld mehrere E-Mailadressen, getrennt durch Strichpunkt, erlaubt; "RBM"=do. nur Reisebüros + Bu'stellen */
  public static CDOPT_KDGESCH_DEFAULT = 328  /* Vorausgewählte Einstellungen bei der Kundengeschichte: "S"=Häkchen "mit stornierten Dossiers", "O"=Häkchen "mit Optionen/Requests" */
  public static CDOPT_FREMDSYSTEMID2_ZUSKRIT = 329  /* "1"-"6"=Adresszusatzkriterium, das als 2. Fremdsystem-ID für weitere Schnittstellen verwendet wird */
  public static CDOPT_ADR_GEBDAT = 330  /* "IMMER"=Auf der Adresse wird ein Geburtsdatum geführt. Wenn ausgefüllt, wird automatisch ein Teilnehmer mit gleichem Namen+Vornamen geführt */
  public static CDOPT_ADR_ZUSKRIT_FRWERB = 331  /* Bei geworbenen Neuadressen automatisch zu setzendes Adresszusatzkriterium inkl. Code ab Pos. 3 (z.B. "2:17"=Kriterium 2, Wert 0017) */
  public static CDOPT_HINWEIS_INTERN = 336  /* Interne Hinweistexte: "PR"=bei Produkten, "CD"=bei Codearten */
  public static CDOPT_LL_ZIRES_INTERN_ZUSKRIT = 340  /* "1"-"4"=Zusatzkrit.häkchen für Landleistungen, bei denen die Zimmer-/Kabinennrn. dem Kunden nicht mitgeteilt werden darf (b2b,Rechnung usw.) */
  public static CDOPT_LLTYP_IN_UMFRAGE = 341  /* Einer der Buchstaben muss am Anfang der LL-Kurzbezeichnung mit einem Blank danach vorkommen, damit die LL in Umfragen berücksichtigt wird */
  public static CDOPT_DI_MAND_NICHT_AUF_LOHNABR = 350  /* Mandant, dessen Mitarbeiter auf der Lohnabrechnung nicht mitaufbereitet werden */
  public static CDOPT_DI_FAHRZEUG_SORT = 351  /* "ART,MA"=Fahrzeuge in Dispo (Fahrzeugansicht und -zuordnung in den anderen Ansichten) zuerst nach Transportmittelart +FZ-Mandant sortieren */
  public static CDOPT_DI_PAX_AUFTEILEN = 352  /* "PAX"=Im Dispofenster steht die Funktion "Anzahl PAX aufteilen" zur Verfügung */
  public static CDOPT_DI_HINWEIS_AUSWAHL = 353  /* Bei Auftragsfahrten, deren Standardreisekürzel mit diesen Zeichen beginnt, ist der Dispohinweis mit Auswahl gem. Codeart 3809 obligatorisch */
  public static CDOPT_DI_FZ_WUNSCH = 354  /* "TRB"=Fahrzeugwunsch bereits in zukünftigen Transportbewegungen zwingend bei Transporten mit Einstiegsorten, die in Dispo sind */
  public static CDOPT_DI_PAX_TEIL = 355  /* "PAX"=Im Dispofenster steht das Feld "PAX (Fahrzeug)" zur manuellen Eingabe zur Verfügung */
  public static CDOPT_DI_KM = 356  /* "KM"=Im Dispofenster steht das Kilometer-Feld zur manuellen Eingabe pro Fahrzeug (Dispositionsteil) zur Verfügung. */
  public static CDOPT_DI_DARSTELLUNG = 357  /* Neue Dispodarstellung: "LFB"=Zeilen Langbez. der Reise, Fahrer, Reise bis; "W"=wichtige Halteorte hervorheben +Spalte Ein-/Umstieg */
  public static CDOPT_KONTINGENT_SB = 360  /* "S"=Kontingents-Sachbearbeiter möglich (in Zukunft wären auch "T"=Transportkette, "L"=Landleistung möglich; Buchstaben kombinierbar) */
  public static CDOPT_STAMMDATEN_SB = 361  /* Stammdaten-Sachbearbeiter ist auf folgenden Ebenen möglich: "S"=Standardreise, "T"=Transp.kette, "L"=Landleistung (Buchstaben kombinierbar) */
  public static CDOPT_PRBEZ_SUCHE = 362  /* "SPR"=Die Suche nach der Produktbezeichnung berücksichtigt auch die sprachabhängigen Bezeichnungen */
  public static CDOPT_GSR_BEZUG = 363  /* "RP"=Gutscheinregeln beziehen sich auf den Reisepreis statt Rechnungsbetrag; "RPGS"=do.+ Staffelpreise berücksichtigen eingelöste Gutscheine */
  public static CDOPT_EPWAEH = 364  /* "LWDEF"=Bei Einstandspreisen kann auch die Landeswährung ausgewählt werden und ist dann auch Default bei neu angelegten Preisen */
  public static CDOPT_ZL_NICHT_GEBUCHT_TEXTE = 365  /* "GRP"=Kurzbez. "T" auf der ZL-Gruppe, wenn die ZL-Texte bei nicht gebuchten statt gebuchten ZL aufbereitet werden (Default bei fak. ZL) */
  public static CDOPT_INDIV_PROD_SB = 367  /* "S"=Aktiviert die individuellen Sachbearbeiter auf Standardreiseebene */
  public static CDOPT_RGABREISEDAT = 380  /* "WAHL"=Das für die Verbuchung gültige Abreisedatum ist bei manuell erstellten Rechnungen aus aktuellem und bisherigen Abreisedaten wählbar */
  public static CDOPT_ADR_ZUSKRIT_KONTOERG = 381  /* "1"-"6"=Adresszusatzkrit., dessen Code bei Umsatzkonti anstelle der "?" eingesetzt wird */
  public static CDOPT_PZL_SORT = 382  /* Preiszeilen nach Produktarten sortieren: "S" (Std.reise), "T" (Transport), "L" (Landleist.), Kleinbuchst.=Abzüge, sofern sep. einsortieren */
  public static CDOPT_ADR_ZUSRKIT_ANZLG_PROZ = 383  /* "1"-"6"= Kundenadresszuskrit., das, wenn etwas hinterlegt ist, die zu leistende Anzahlung in % angibt (höhere Prio als Angabe bei Std.reise) */
  public static CDOPT_RG_AN_VERANSTALTER = 384  /* "RAB"=Bei Rechnung an Veranstalter Rabatt statt Kommission ausweisen/"NETTO"=Preise auf der Preiszeile Netto, Kommission nicht ausweisen */
  public static CDOPT_ZUST_MAND_PR_VON = 400  /* Erste für die Produkte reservierte Einstellung für zuständige Mandanten (+PRCODE dazu addieren) */
  public static CDOPT_ZUST_MAND_VON = 400  /* Erste für die zuständigen Mandanten reservierte Option. (Jeweils "ERF"=Bei Neuerfass. Login-Mandant) */
  public static CDOPT_ZUST_MAND_STR = 401  /* Zuständiger Mandant für Produkt Standardreise (siehe Code 400) */
  public static CDOPT_ZUST_MAND_TRK = 403  /* Zuständiger Mandant Produkt Transportkette (siehe Code 400) */
  public static CDOPT_ZUST_MAND_LL = 405  /* Zuständiger Mandant Produkt Landleistung (siehe Code 400) */
  public static CDOPT_ZUST_MAND_PR_BIS = 479  /* Letztes für Produkte reservierter Code */
  public static CDOPT_ZUST_MAND_PA = 480  /* Zuständiger Mandant für den Partner (siehe Code 400, zus. "BEARB"=Bearbeitender Mand., man. änderbar) */
  public static CDOPT_ZUST_MAND_WMAT = 481  /* Zuständiger Mandant für Werbematerialbestellungen (siehe Code 400) */
  public static CDOPT_ZUST_MAND_DS = 485  /* Zuständiger Mandant für Dossiers (siehe Code 400) */
  public static CDOPT_ZUST_MAND_BIS = 499  /* Letzter für die zuständigen Mandanten reservierter Code */
  public static CDOPT_ZI_AUSSTATTUNG = 500  /* Gibt das Zusatzkriterium der Landleistung an, das die Zimmerausstattung beinhaltet */
  public static CDOPT_LL_PREFIX = 501  /* Nr. des Zusatzkriteriums der Landleistung, das im Internet vor der Bezeichnung aufbereitet wird */
  public static CDOPT_AUSFLUGSBESCHR = 502  /* "AZURE"=Zu den Ausflügen können Beschreibungen für den Interentbuchungsablauf über die Travel API aus der Azure-Cloud geladen werden */
  public static CDOPT_AUSFLUGSUEBERSCHR = 503  /* "OBEN"=Bei Ausflügen wird im Internet die Überschriftenzeile nur 1x ganz oben angezeigt statt pro Datum */
  public static CDOPT_ADR_HERKUNFT = 510  /* Zusatzkriterium des Kunden, das die Adressherkunft enthält. Autom. gem. Buchungsstelle bei Neuerf. */
  public static CDOPT_ADR_ZUSKRIT = 511  /* Im Tournet abzufragendes Zusatzkriterium */
  public static CDOPT_ADR_HERKUNFT_RM = 512  /* Code des 1. Zusatzkriteriums, welcher bei Reisemarktbuchungen in neue Adressen übernommen wird. */
  public static CDOPT_ADR_ZUSKRIT_HAKEN = 513  /* Bei Tournetbuchungen automatisch zu setzendes Adress-Zusatzkriteriums-Häkchen */
  public static CDOPT_ADR_NEWSLETTER = 514  /* Gibt das Zusatzkriterium an, welches Newsletterinteressen kennzeichnet */
  public static CDOPT_ADR_ZUSKRIT_AUTOM = 515  /* Automatisch zu setzendes Zusatzkriterium inkl. Code ab Pos. 3 (z.B. "2:17"=Kriterium 2, Wert 0017) */
  public static CDOPT_ADR_RB_VERTRETER = 516  /* Adresshäkchen für Reisebüros: im Internet keine Rechnung mit Komm.abzug, dafür Mitarbeiternr erfassen */
  public static CDOPT_ADR_PRUEFEN = 517  /* "TLN"=1 Adresse=1 Teilnehmer, bei Suche auch Vorname vergleichen; "KEIN"=keine Suche, wenn Adresse direkt vollständig eingegeben wird */
  public static CDOPT_ADR_SPRACHCODES = 518  /* Erlaubte Kundensprachcodes im Internet (mehrere mit Kommas trennen; Default=1,2+akt.Homepagesprache) */
  public static CDOPT_EMAILADR_CHECK = 519  /* 2=Emailadresse auf Homepage 2x eingeben zur Sicherheit; 2S=do., auch in spez. Smartphoneabläufen */
  public static CDOPT_SITZPLAN = 520  /* "ON"=Sitzplan darf im Tournet schon auf der Übersichtsseite angezeigt werden */
  public static CDOPT_SITZPLAN_DIREKTKD = 521  /* "ON"=Sitzplan bei Direktkundenbuchung möglich, "BUCHEN"=Sitzplan nur bei Buchungsvorgang möglich */
  public static CDOPT_ZIMMERPLAN_TOURNET = 525  /* "ON"=Zimmerplan darf im Tournet schon auf der Übersichtsseite angezeigt werden */
  public static CDOPT_ZIMMERPLAN_DIREKTKD = 526  /* "ON"=Zimmerplan für Direktkunden schon in der Übersicht,"BUCHEN"=Zimmerplan erst beim Buchungsvorgang */
  public static CDOPT_ZIMMERPLAN_RQ = 527  /* "KEIN"=Zimmerplan ausblenden, wenn alle Kategorien auf Request sind */
  public static CDOPT_ZIMMERSUCHER = 529  /* "ON"=Einzelne Zimmernummern können z.T. nur nach manueller Kontrolle beim Veranstalter gebucht werden (Zimmersucher) */
  public static CDOPT_STR_BESCHR = 530  /* "ON"=Standardreisebeschreibung darf ins Tournet übermittelt werden */
  public static CDOPT_STR_BESCHR_FENSTER = 531  /* Breite [ x Höhe ] des Standardreise-Beschreibungsfensters in Pixeln */
  public static CDOPT_HOT_BESCHR_FENSTER = 532  /* Breite [ x Höhe ] des Hotel-Beschreibungsfensters in Pixeln */
  public static CDOPT_HTML_BESCHR = 533  /* "ON"=HTML-Beschreibung in Standardreisen- und Landleistungsregister aktiv */
  public static CDOPT_PDF_BESCHR = 534  /* "DET"=In der Standardreise kann eine PDF-Beschreibung für das Detailprog. in der App hinterlegt werden */
  public static CDOPT_HOMEPAGE_ABPREIS = 540  /* "OPT"=Der Abpreis wird auf der Homepage für die optimale Zimmergrösse berechnet (Default=max.Grösse) */
  public static CDOPT_HOMEPAGE_PREISEBENE = 541  /* "STR"=Datum übergreifenden Ab-Preis auf Standardreise in Übersichtsmasken anzeigen */
  public static CDOPT_HOMEPAGE_ZKR_MIN_ABPREIS = 542  /* Zusatzkriteriumshäkchen (1-4) der Landleistung. Angekreuzt gilt bei dieser LL als Ab-Preis der günstigste Preis der günstigsten Kategorie. */
  public static CDOPT_HOMEPAGE_WAEH = 543  /* "FREMD"=Der Kunde kann Währung wählen (LW und FW gem. 5009/1023), wenn er im Ausland wohnt oder bei ihm eine Fremdwährung hinterlegt ist */
  public static CDOPT_INFANTS = 550  /* "OFF"=keine zusätzlichen Teilnehmerzeilen für Infants anzeigen (Defaults=2 Zeilen) */
  public static CDOPT_SEP_BESTAETIGUNG = 551  /* "RB"=Bei Reisebürobuchungen wird eine separate Bestätigung zusätzlich zur Rechnung ausgedruckt */
  public static CDOPT_TOURNET_BESTAETIGUNGSREC = 552  /* "ALLE"=Tournet-Reisebüros wird bei RG-Verbuchung autom. ein Record an ihr Inhouse-System geschickt */
  public static CDOPT_TOURNET_RECHNUNG = 553  /* "NVERB"=Tournet-Rechnung nicht automatisch verbuchen */
  public static CDOPT_INTERNET_VOUCHER = 554  /* "E+P"=Bei Internet-Bestätigungen wird am Ende des Emails und zusätzl. als PDF der Voucher aufbereitet */
  public static CDOPT_MYDOSSIER_AB_JAHR = 555  /* Erstes Abreisejahr, ab dem die Dossiers im Internet-Kundenbereich aufbereitet werden */
  public static CDOPT_INTERNET_EMAIL_ABSENDER = 556  /* "SB"=Standardreisen-Sachbearbeiter statt generelle Emailadresse */
  public static CDOPT_INTERNET_KOPIE_ABSENDER = 557  /* "TN"=Allfällige Email-Kopien separat versenden mit Absender kopie@tournet.ch statt der Empfängeradr. */
  public static CDOPT_INTERNET_DSMANDANT = 558  /* Dossiers der aufgeführten Mandanten können im Internet (TourApp) ebenfalls abgerufen werden (max. 5) */
  public static CDOPT_INTERNET_AUTOM = 559  /* "VOLL"=Vollautomatismus bei Internetbuchung (RG direkt verbuchen, RG-Mail mit RG, AGB+ZL-Anhängen); dahinter sind weitere Optionen möglich */
  public static CDOPT_WMAT_BEM = 560  /* "NOTIZ"=Werbemat.bestellungen sind mit Bemerkungen möglich und werden als wichtige Notiz gespeichert */
  public static CDOPT_WMAT_ZUSKRIT = 561  /* Bei Werbematerialbestellungen abzufragendes Adresszusatzkriterium (oder anderes Zusatzkriterium, dann jedoch ohne Speicherung im Tourdata) */
  public static CDOPT_WMAT_BILDGROESSE = 562  /* Breite [ x Höhe ] der Prospektbilder in Pixel */
  public static CDOPT_BUABLAUF = 570  /* Zu verwendender Internet-Buchungsablauf: "2"=Ablauf 2.0 (eingeführt im Nov. 2007) */
  public static CDOPT_TRSP_SORT = 571  /* "ZEIT"=Transporte werden nach 1. Gebunden, 2. Datum/Zeit statt gem. STR sortiert */
  public static CDOPT_ABRDAT = 572  /* "ALLE"=Die Abreisedaten aller Standardreisen werden bei der Suche als Auswahl angeboten */
  public static CDOPT_HOTELMASKE = 573  /* "OFF"=Hotelmaske überspringen, "O.KONT"=Kontingent nicht anzeigen, "IMMER"=auch bei 1 Hotel zeigen */
  public static CDOPT_KONT_ANZEIGE = 574  /* "OFF"=Kontingentsanzeige (rot/gelb/grün) unterdrücken */
  public static CDOPT_AUSGEBUCHT_UNTERDR = 575  /* "KONT"=Ausgebuchte Reisen (wg. Kontingents bzw. Ausgebucht-Flag), "ANNU"=Annulationen nicht zeigen */
  public static CDOPT_DEF_REISEDAT = 576  /* "NDAT"=Erstes Reisedatum statt günstigstes als Default vorschlagen */
  public static CDOPT_REISEKALENDER_ZUS_FASS = 577  /* Reisen mit dieser Anzahl oder mehr Abreisedaten werden im Internet-Reisekalender zusammengefasst */
  public static CDOPT_ABFLUGHAFEN = 578  /* Eintrag vorhanden=Abflughafen anzeigen/auswählen lassen+wenn identisch mit einem, diesen als Default */
  public static CDOPT_STR_MASKE = 579  /* "IMMER"=Standardreisemaske wird im Internet auch dann angezeigt, wenn es nur eine Standardreise hat */
  public static CDOPT_STR_GRUPPE = 580  /* "SEP"=Standardreisegruppen werden auf einer vorgelagerten Seite zur Auswahl angeboten */
  public static CDOPT_KURS_DATEN = 581  /* "LISTE"=Bei Kursfahrten mögliche Daten als Liste anzeigen statt via Kalender auswählbar */
  public static CDOPT_BUCHUNGSCODE = 582  /* "OFF"=Kein Buchungscode in Tournet-Ablauf */
  public static CDOPT_REISEPROG_ALS_KNOEPFE = 583  /* Höchstanzahl Reiseprogramme in einer Reise, die als Radiobuttons statt als Listbox dargestellt werden (Default 0=immer Listbox) */
  public static CDOPT_ZL_TLN = 584  /* "AUF"=Zusatzleistungen im Responsiveablauf aufgeklappt anzeigen (Teilnehmer sind direkt ohne Drücken des Plus-Knopfs einzeln zuweisbar) */
  public static CDOPT_BUBEST_SEITE_DETAILS = 585  /* "KNOPF"=Buchungsdetails im Responsiveablauf auf Bestätigungsseite erst auf Knopfdruck, "UNTEN"=unterhalb Bestät.knopf, "KNOPFUNTEN"=Kombinat */
  public static CDOPT_TERMIN_FILTER = 586  /* Zusätzliche Filtermöglichkeiten auf der Terminseite (Optionen kombinierbar): "K"=Kategorien, "D"=Reiseprogramm-Dauer, "B"=Buchbar */
  public static CDOPT_BUBEST_AGB = 587  /* "O.BOX"=Auf der Bestätigungsseite wird keine Checkbox zum Anhaken der AGB angezeigt */
  public static CDOPT_FB_BESCHR = 588  /* "ÜBER"=Die Fragebogenbeschreibung gem. Codeart 2058 erscheint im Internet über dem allgemeinen Hinweis (Default anstatt) */
  public static CDOPT_FB_TEILNEHMER = 589  /* "TLN"=Jeder Teilnehmer kann seinen Fragebogen selbst erfassen */
  public static CDOPT_FRAME_SIZE = 590  /* "FS"=Passt die I-Frame-Grösse via Javaskript an die benötigte Grösse an (verhindert sep.Scrollbalken) */
  public static CDOPT_INTERNET_ZUGRIFF = 591  /* "KEIN"=Kein Zugriff vom Internet ins Inhousesystem, stattdessen nur E-Mail an Verantworlichen */
  public static CDOPT_META_ROBOTS = 592  /* "NO"=Wenn Webtemplates angegeben werden, wird das Meta-Tag für die Robots auf "noindex, nofollow" gesetzt. */
  public static CDOPT_ADR_TLN = 600  /* "WAHL"=Teilnehmer kann aus Adresse ausgewählt werden */
  public static CDOPT_ADR_AENDERN = 601  /* "NA"=Name und Adresse bestehender Kundenadressen können im Internet geändert werden (Default nur Telefonnummern, E-Mailadresse und Sprache) */
  public static CDOPT_INTERNET_BUKONTR = 610  /* "AUTO"=Internetbuchungen, die automatisch verbucht werden, müssen speziell geprüft werden */
  public static CDOPT_INTERNET_BEILAGEN_RG = 611  /* "KEINE"=Bei normalen Rechnungen werden im Internet keine Beilagen-Dokumente mitgeschickt; "AGB"=do., AGBs werden jedoch mitgeschickt */
  public static CDOPT_INTERNET_BEILAGEN_RQ = 612  /* "KEINE"=Bei Requests werden im Internet keine Beilagen-Dokumente mitgeschickt; "AGB"=do., AGBs werden jedoch mitgeschickt */
  public static CDOPT_PROD_BILDGROESSE = 620  /* Grösse des Vorschaubildes in Pixel: Breite [ x Höhe ] -> ersetzt durch Codeart 5042, Code 1 */
  public static CDOPT_BUS_PROZ_FREI = 630  /* von-bis=Busse mit einer prozentualen Anzahl Freiplätze zwischen "von" und "bis" werden im XML als "FREI" gekennzeichnet (z.B. "5-55") */
  public static CDOPT_STR_NICHT_TRACKEN_ZUSKRIT = 640  /* "1"-"4"=Standardreise-Zusatzkrit.häkchen, das gesetzt werden kann, um eine Reise vom Internet-Tracking (z.B. Google Tag Mgr) auszuschliessen */
  public static CDOPT_STR_MITTEILUNGSFRAGE_ZUSKRIT = 641  /* "1"-"6"=Zusatzkriterium der Standardreise vom Typ Code, das eine Frage beinhaltet, welche im Mitteilungsfeld oblig. beantwortet werden muss */
  public static CDOPT_STR_KAT_PRIO = 642  /* "ABPR"=Im Haupt-/Nebenleistungsregister der Standardreise kann die für den Ab-Preis zu verwendende Kategorie als Default markiert werden */
  public static CDOPT_STR_SEP_BESCHR = 643  /* "AGB"=Das separate Beschreibungsfeld gem. Code 5013/1041 wird als Ergänzung zu den AGBs verwendet */
  public static CDOPT_FB_INTERNETVERSAND = 650  /* "IMMER"=Im Internet werden auch Fragebogen mit "!" und "-" automatisch verschickt; "RG"=Der Versand erfolgt nur bei autom. Internet-Rechnung */
  public static CDOPT_PREIS_NICHT_OK = 651  /* "ANFR"=Reisedaten mit keinen oder falschen Preisen im Internet ohne Preis anzeigen */
  public static CDOPT_TLN_GEBDAT = 652  /* "IMMER"=Das Geburtsdatum wird auch bei Buchungen abgefragt, wenn es nicht obligatorisch ist (die Eingabe ist dann jedoch freiwillig) */
  public static CDOPT_APP_REISELEITER = 700  /* Reiseleiter anzeigen: "VN"=Vorname+Name, "VNM"=+Handy, +"1"=do. nur 1 Tag vor Beginn bis nach Ende */
  public static CDOPT_APP_FAHRER = 701  /* Fahrer anzeigen: "VN"=Vorname+Name, "VNM"=+Handy, +"1"=do. jedoch nur 1 Tag vor Beginn bis nach Ende */
  public static CDOPT_APP_BUCHUNGSLINK = 702  /* "BL"=Buchungslink anzeigen */
  public static CDOPT_APP_TRANSPORT = 703  /* "K"=Klasse, "M"=Mahlzeiten auf Transportliste anzeigen */
  public static CDOPT_DI_FREIGABE = 750  /* "DI"=Disponiert-Flag wird manuell gesetzt */
  public static CDOPT_CHAPP_KOPFDATEN = 751  /* "B" und oder "Z"=Vom Standard abweichende Darstellung der Kopfdaten (B=zusätzlich Bereitstelldatum, Z=Zeiten nicht anzeigen) */
  public static CDOPT_CHAPP_EINAUSSTIEGSLISTE = 752  /* "A"=Ausstiegslisten anzeigen */
  public static CDOPT_CHAPP_DOKUMENTE = 753  /* "UNTEN"=Dokumente werden in der Übersicht unten angezeigt (Default oben) */
  public static CDOPT_CHAPP_MIN_PERSINDIV_STATUS = 754  /* Der individuelle Personenstatus muss >= diesem Wert sein, damit der Chauffeur in der App diesen Einsatz sieht (Default keine Einschränkung) */
  public static CDOPT_CHAPP_MENU_DATEIEN = 755  /* "DAT"=Dateien+Icons zu Menupunkten nur neu laden, wenn das Menupunkt-Mutationsdatum verändert wurde (Default alle bei veränd. Menu-Mutdat.) */
  public static CDOPT_CHAPP_MENU_VARIANTE = 756  /* "2"=Neue Menudarstellung (ab Mai 2023 möglich) */
  public static CDOPT_CHAPP_MENU_SORT = 757  /* "ALPHA"=Menu wird in der Chauffeur-App alphabetisch statt in der Reihenfolge gem. Tourdata sortiert */
  public static CDOPT_APP_HINTERGRUND = 780  /* Hintergrundfarbe (Hex-Wert inkl. # am Anfang) für die Kunden- und Chauffeur-App */
  public static CDOPT_APP_BALKEN1 = 781  /* Balkenfarbe 1 (analog Hintergrundfarbe) für die Kunden- und Chauffeur-App */
  public static CDOPT_APP_BALKEN2 = 782  /* Balkenfarbe 2 (analog Hintergrundfarbe) für die Kunden- und Chauffeur-App */
  public static CDOPT_APP_BALKEN3 = 783  /* Balkenfarbe 3 (Zubringer; analog Hintergrundfarbe) für die Kunden- und Chauffeur-App */
  public static CDOPT_MODULE_VON = 1000 /* Ab hier handelt es sich in der Regel um Module */
  public static CDOPT_FREIPLAETZE = 1000 /* "LL"=Auf der LL in STR kann bestimmt werden, ab wieviel % Freiplätzen nicht mehr gebucht werden kann */
  public static CDOPT_BAUKASTEN = 1001 /* "BK"=Baukastenmodul  */
  public static CDOPT_ERW_SUCHE = 1002 /* "UE1" bis "UE5"=Erweiterte Suche mit Zkrit 1-5 der Std.reise; "RK[S]"=Reisekalender [Schiff] */
  public static CDOPT_NEBENLEISTUNG = 1003 /* "NL"=Nebenleistungen können erfasst und gebucht werden */
  public static CDOPT_PRODUKTPREISE = 1004 /* "PP"=Preis direkt auf Produkt möglich, "LP"=do. nur auf Landleistungen, "TP"=do. nur auf Transporten */
  public static CDOPT_RES_SYSTEM = 1005 /* Direktbuchungen ab Reservationssystem möglich: "AM"=Amadeus, "GA"=Galileo, "KO"=Komet, "**"=alle */
  public static CDOPT_ZIMMERPLAN = 1006 /* "ZP"=Zimmer- bzw. Kabinenplan + gesperrte Objekte, "ZPG"=+Grafischer Plan, "GZ"=nur gesp. Zimmer, "7" dahinter=7 Tage sperren */
  public static CDOPT_TLN_IMPORT = 1007 /* "ZW"=Teilnehmer können ab Zwischenablage mit der rechter Maustaste importiert werden */
  public static CDOPT_INTERNET = 1008 /* "IN"=Internet-Beschr.register bei Standardreise und Landleistungen, "INTB"=zus. Feld interne Beschr */
  public static CDOPT_STR_EINSTANDSPREISE = 1009 /* "EP"=Einstandspreise auf Standardreise möglich (div. Unteroptionen mit "-" möglich) */
  public static CDOPT_DISPOSITION = 1010 /* "DI"=Disposition steht zur Verfügung, "DIZ"=do. inkl. Zubringer */
  public static CDOPT_DISPOBEZ = 1011 /* "OHNEDEST"=Bei der Anzeige der Dispositionsbalken wird die Destination nicht aufbereitet */
  public static CDOPT_PLANUNGSTOOL = 1012 /* "PT"=Planungstool steht zur Verfügung */
  public static CDOPT_LOHNPROG = 1013 /* "LP"=Lohnprogrammzusatz zur Disposition, "LPT"=mit Lohnasatztabelle, "LPZ" bzw. "LPTZ" zus. Zuständigkeit notwendig */
  public static CDOPT_DISPOAUSWAHL = 1014 /* "ART"=Auswahl Transportmittelart beim Einrichten des Transportmittels und  Disposition/Planungstool */
  public static CDOPT_ZOLLAMT = 1015 /* "ZOLL"=Zollamtsfelder in Transportkette und Dispo aktivieren */
  public static CDOPT_DISPOBEILAGEN = 1016 /* "APP"=Fahrauftrag+Beilagen werden in der Dispo-Dokutabelle gespeichert+stehen der App zur Verfügung */
  public static CDOPT_CHAUFFEURAPP = 1017 /* "FB"=Chauffeur-App inkl. Fahrbericht im Dispofenster aktiv, dahinter sind div. weitere Optionen möglich */
  public static CDOPT_REISELEITERDISPO = 1018 /* "RL"=Separate Reiseleiterdispo aktiv */
  public static CDOPT_DISPOZUBEHOER = 1019 /* "A"=Anhänger, "K"=Kiste, "AK"=Anhänger und Kiste über Dispositionszubehör disponieren */
  public static CDOPT_SAMMELFAKT = 1020 /* "SF"=Sammelfaktura steht zur Verfügung */
  public static CDOPT_SUPERKOMM = 1021 /* "SK"=Superkommission steht zur Verfügung. In CDBEZ die normalerweise berechtigten Kommissionssätze. "SKK"=gem. Kommissionstabelle */
  public static CDOPT_NEWSLETTER = 1022 /* "NL"=Newsletter, "NLD"=do. mit Double Opt-In, "NLE"=NL nur extern verwalten (nicht im TD) +div. weitere Optionen gem. Installationsanleitung */
  public static CDOPT_FREMDWAEH = 1023 /* Währungskürzel=Autom. FW-Umrechnung (alle Währungen), für das Währungskürzel wird zus. in der Schnellbuchen ein sep. Preisknopf angezeigt */
  public static CDOPT_MITGLIED = 1024 /* "MG"=(Club-)Mitgliederverwaltung, "MG-Z"=do.autom. Vorschlag nur bei oblig. bei fehlender Mitgliedsch */
  public static CDOPT_FRAGEBOGEN = 1025 /* nicht leer=Fragebogenmodul aktiv; Kombination  der Zeichen: "B"=Beruf, "O"=Geb.Ort, "P"=Pass, usw. */
  public static CDOPT_AUTOM_ZUSATZRG = 1026 /* "ZR"=Automatische Zusatzrechnung für bestimmte Dossiers oder ganze Abreisen erstellen */
  public static CDOPT_BESTAETIGUNGSART = 1027 /* "WAHL"=Versandart wählbar, "WAHL-E"=Email def., "WAHL-I"=im Inet Email autom., "WAHL-S"=Std wie Email */
  public static CDOPT_PERS_ZUSINFO = 1028 /* "SB"=Im Personenregistern können Firmensachbearbeiter-Statistikdaten angezeigt werden */
  public static CDOPT_PARTNERVERB = 1029 /* Partnerverbindungen für "B"=BDD-Sammelabrechnung, "F"=Freundschaftswerbung (Einträge kombinierbar) */
  public static CDOPT_VERFALL = 1030 /* "VF"=Verfall für Landleistungen und Transportbewegungen steht zur Verfügung */
  public static CDOPT_BU_KLASSEN = 1031 /* "BKLM"=Man. Bu'kl.-Eingabe bei Trsp.bew. auch ohne Schnittstelle erlaubt; "BKLT"=do. gem. sep. Tab. */
  public static CDOPT_PROD_BEREINIGUNG = 1032 /* "PR"=Möglichkeit, um autom. alle Produkte inaktiv zu setzen, die länger nicht mehr verwendet wurden */
  public static CDOPT_PROD_BELASSEN = 1033 /* "STR"=Pro Standardreise kann bestimmt werden, ob sie ohne aktive Daten im Internet belassen wird */
  public static CDOPT_ZL_VOUCHER = 1034 /* "KBEZ"=Voucher auch für Zusatzleistungen aufbereiten, wenn TXB = CDKURZBEZ des PRTYPCODES */
  public static CDOPT_STR_STATUS = 1035 /* "RSTAT"=Manuell geführter Status auf Ebene Reisedatum der Standardreise (LEISTUNGSDATUM) */
  public static CDOPT_LL_UMBUCHEN = 1036 /* "LUMB"=Landleistungs-Umbuchungsregister aktiv (mehrere Dossiers gleichzeitig umbuchen) */
  public static CDOPT_DUMMY = 1037 /* bis 3.20a (Nov 12), Codeeintr. noch vorhanden: "ERP"=Erweitertes Reiseprog (zus. Bisdatum, Wochentag)... */
  public static CDOPT_TOPANGEBOT = 1038 /* "TOP"=Einzelne Reiseprogramme können als Topangebot definiert werden, "SEL"=Topangebote nur zur Selektion, ansonsten nicht hervorgehoben */
  public static CDOPT_NUR_DIREKTLINK = 1039 /* "DL"=Standardreisen können als "nur Direktlink" markiert werden und werden sonst nicht übermittelt */
  public static CDOPT_SELL_REPORT = 1040 /* "SR"=Sell & Report steht zur Verfügung (dahinter zus. Buchstaben für Spezialeinstellungen möglich) */
  public static CDOPT_GASTRO = 1041 /* "GA"=Schiffsgastronomie */
  public static CDOPT_DSID_FORMAT = 1042 /* "MA"+Trennzeichen, so fern Mandantennr. vor der Dossiernr. mitangezeigt wird + eingegeben werden kann... */
  public static CDOPT_ONLINEREQUEST = 1044 /* "ORQ"=Online-Requestbestätigung bzw. -ablehnung möglich */
  public static CDOPT_PZL_DOSSIER = 1045 /* Technische Dossiernummer, die für Preiszeilenvorschläge verwendet wird */
  public static CDOPT_DSABREISE = 1046 /* "REISE"=Bei Pauschalreisendossiers wird ein separates Abreisedatum der Reise berechnet, welches bei Bedarfvon Hand überschrieben werden kann */
  public static CDOPT_RUECKERSTATTUNG_GS = 1047 /* Automatische Rückerstattung über Verkaufsgutschein dieser Standardardreise möglich */
  public static CDOPT_KULANZ_GS = 1048 /* Automatische Kulanzgutscheine aus dem Dossier heraus über Verkaufsgutschein dieser Standardardreise möglich */
  public static CDOPT_ZBED_SPEZ = 1049 /* Zahlungsbedingungen mit höherere Priorität auch auf Ebene Gutscheinregel ("G") und/oder Dossier ("D") definierbar */
  public static CDOPT_AUTOM_PNL = 1050 /* "PNL"=Automatischer PNL-Versand ab Tournet-Server */
  public static CDOPT_AUTOMATION = 1051 /* Generelle Automation: "MAN"=nur manuell starten, "AUT"=zus. automatisch via Dienst, "AUTS"=zus. Sofortauslösemöglichkeit bei Buchung/Änder. */
  public static CDOPT_HYPERLINK = 1060 /* "HL"Hiperlinkgenerator steht zur Verfügung */
  public static CDOPT_KOMMENTAR = 1061 /* "KO", "KO1", "KO3"=Internetkommeinkl. Gästebuch (1=Prüfung nach Veröffentlichung, 3=ohne Prüfung) */
  public static CDOPT_MOBILE = 1062 /* "MOB"=Optimierte Internetabläufe für Smartphones usw.; "MOBS"=do, mit SMS-Bestätigung */
  public static CDOPT_EMAILTEMPLATE = 1063 /* "ETPL"=Templates für Internet-Mailbestätigungen können direkt im Tourdata hinterlegt werden */
  public static CDOPT_KUNDENLOGIN = 1064 /* "EMAIL"=Kunde kann sich im Internet mit seiner E-Mailadresse und einem Passwort anmelden (Default Kundennr. + Name), "KDNR"=do. Kundenr.+PW */
  public static CDOPT_TRACKING = 1065 /* "JENTIS"=Trackingmechanismus Jentis auf Internetseiten */
  public static CDOPT_UPSELLING = 1066 /* "UPS"=Upselling möglich */
  public static CDOPT_ANGEBOT = 1070 /* "ANG"=Hinterlegte Angebote pro Anbieter stehen zur Verfügung */
  public static CDOPT_ANBIETERZUSTAENDIGKEIT = 1071 /* "PA"=Generelle Anbieter-Zuständigkeit (Kontaktperson);"PR"=Produktspezifische Anbieter-/Agenten-Zuständigkeiten möglich */
  public static CDOPT_PDF_DATEI = 1080 /* "PDF"=Dokumente können als PDF-Datei aufbereitet und als Anhang gemailt oder gespeichert werden */
  public static CDOPT_DOKU_EMAIL = 1081 /* "MAIL"=E-Mail(massen-)versand in Dokumentenregister möglich */
  public static CDOPT_KREDITKARTEN = 1090 /* "KKxyw"=Kreditkartenmodul (x: O=oblig, D=oblig bei Dir.kd, F=fak, S=gem STR; y: R=reserv, B=dir. abbuchen; w: A=auch RQ, N=nur Anzlg. mögl.) */
  public static CDOPT_AGENDA = 1100 /* "DS"=Dossier-, "AD"=Adress-, "PR"=Produktagenda, kombinierbar mit "," und "X"=kann geschlossen werden */
  public static CDOPT_AGENDAVORLAGE = 1101 /* "PR"=Agendavorlage für Produkte; "PRD"=auch Dossier spezifische Einträge möglich */
  public static CDOPT_FEIERTAGE = 1105 /* "FT"=Die Feier- und anderen speziellen Daten gem. Codeart 5033 werden geprüft und hevorgehoben */
  public static CDOPT_CHAT = 1106 /* "C"=Chat zwischen Disposition und Chauffeur via App */
  public static CDOPT_GESCHICHTSDATEI = 1110 /* Externe Dateien in Geschichte speicherbar: "DS"=Dossier, "PR"=Produkt, "PA"=Partner (kombinierbar) */
  public static CDOPT_LOG = 1111 /* Gibt an, für welche Vorgänge Log-Einträge erstellt werden müssen: "I"=Exporte bei den individuellen Auswertungen */
  public static CDOPT_GESCHICHTE_LOB = 1112 /* "DBF"=Anhänge zu Geschichtseinträgen werden nach Möglichkeit direkt in der Datenbank gespeichert, alle anderen wie bisher im Dateisystem. */
  public static CDOPT_MAX_DP_EINZELPERS = 1120 /* "KONT"=Einzelplatzkontingent bei Bustransporten möglich */
  public static CDOPT_AIRBERLIN = 1121 /* "TL"=Air Berlin-Ticketless-Verfahren (Poolingschnittstelle ist Voraussetzung) */
  public static CDOPT_TRSP_GESELLSCHAFT = 1122 /* "TRG"=Transportgesellschaft (Gepäckinformationen können pro Airline + Buchungsklasse verwaltet werden) */
  public static CDOPT_TEILSTRECKENPREIS = 1123 /* "TSPR"=Zusatzleistung "obligatorisch gem. Teilstrecke" möglich (für Kursschifffahrt) */
  public static CDOPT_UNVOLLST_ADR = 1130 /* "OFF"=Unvollständige Adressen auch für Offerten verwendbar  (Newsletter-Häkchen ist dann nicht oblig.) */
  public static CDOPT_INTERESSEN = 1131 /* "INT"=Interessensgebiete (Adressmarketing), "INTH"=do., nur ja/nein und NL-An-/Abmeldung nur für Gesamtadresse */
  public static CDOPT_ADR_ZUS_LEGEN = 1132 /* "AZ"=Intelligente Adress- und Teilnehmerzusammenlegung; "AZ1"=wie "AZ", Agendaeintrag aber nur an ersten zugeordneten Sachbearbeiter */
  public static CDOPT_UMFRAGE = 1133 /* "UMF"=Umfragemodul, "UMF-R"=do., keine Umfragen an Reisebüros/Veranstalter, "UMF-RB"=zus. auch nicht an Kunden, wenn über Bu'stellen gebucht */
  public static CDOPT_ADR_IMPORT = 1134 /* "IMP"=Adressimport bei entsprechender Zuständigkeit (Code 1059) */
  public static CDOPT_EMAIL_ABGLEICH = 1135 /* "ABGL"=ungültige E-Mailadressen können abgeglichen sowie manuell markiert werden. */
  public static CDOPT_EMAIL_BODY = 1136 /* "HTML"=E-Mails aus dem Tourdata heraus werden HTML-formatiert */
  public static CDOPT_NOTFALLKONTAKT = 1137 /* "NOTF"=Notfallkontakt (Telnr. und Kontaktname) erlauben und bei allen Buchungen abfragen */
  public static CDOPT_NEWSLETTER_IMPORT = 1138 /* "NLIMPZ"=Newsletterimport bei entsprechender Zuständigkeit (Code 1057) */
  public static CDOPT_WMAT_BRIEF = 1139 /* "WB"=Werbematerialbrief im Tourdata */
  public static CDOPT_FRWERB_GS = 1140 /* Beginn der Kurzbez. von Gutscheinen für Freundschaftswerbung; dahinter "-Bnn", für Gutschein an Best.Kd. Anz. Stunden nach Buchung des Neukd */
  public static CDOPT_FLUG_UPSELLING = 1141 /* "UPKBvnp"=Flug-Upselling ist möglich, wenn die Kurzbezeichnung an p. Stelle das Zeichen gem. v hat auf eine Kette gleichen Namens, ohne n */
  public static CDOPT_PREIS_ANFRAGE = 1150 /* "ANF"=Preis auf Anfrage bei einzelnen Reisedaten möglich */
  public static CDOPT_BEILAGENAUTOMATION = 1151 /* "PRGV"=Beilage für Voucher ab Dateien der Produktgeschichte möglich */
  public static CDOPT_RECHNUNGSBEILAGEN = 1152 /* "ZL"=Rechnungsbeilagen über Zusatzleistungen */
  public static CDOPT_BUCHUNGSABLAUF = 1153 /* Buchstaben der speziellen Buchungsabläufe, die aktiviert sind (in Codeart 2060 #+Buchstabe am Anfang) */
  public static CDOPT_STRSTATUS = 1154 /* "KONTR"=Stand der Standardreiseneinrichtung kann über die Stati kontrolliert werden und im SBU angezeigt */
  public static CDOPT_STRZUS_AUTOM_GEPAECK = 1155 /* 1-4=Nr. des Std.reisenzus.kriteriumhäkchens, das den autom.Versand der Gepäcktransportliste aktiviert */
  public static CDOPT_WAEHRUNGSKURSE = 1156 /* "WK"=Vom Datum und/oder Produkt abhängige Währungskurse möglich */
  public static CDOPT_LDAT_REISELEITER = 1157 /* "ADR"=Reiseleiter-Adresse auf Leistungsdatum möglich */
  public static CDOPT_ERW_ZUSKRIT_STR = 1158 /* 1-99=Zahl der gem. Codeart 2400 zu aktivierenden Liste der erweiterten Zusatzkriterien */
  public static CDOPT_BUCHUNGSREIHENFOLGE = 1159 /* "WB"=Buchungsreihenfolge ist in der Standardreise wählbar, dahinter fakultativ separat freizuschaltende Buchstaben (mit # in Codeart 2088) */
  public static CDOPT_GSCODE = 1160 /* "GSC"=Gutscheincode, "GSCK"=do., beim Einlösen jedoch keine Kinderermässigung */
  public static CDOPT_REISELEITER = 1161 /* "APP"=Reiseleiterfelder sind für die RL-App freigeschaltet */
  public static CDOPT_PRODUKTBILDER = 1162 /* "BILD"=Pro Produkt können mehrere Bilder unterschiedlicher Grösse gespeichert werden */
  public static CDOPT_AUTOSTR = 1163 /* "AUTO"=Standardreise "auto" (für globale Nebenleistungen) aktiv */
  public static CDOPT_NUMMERN = 1164 /* Buchstaben der individuellen Nummernverwaltung (Gutscheincodes usw.), die aktiviert sind (Codeart 2906, Cdkurzbez nimmt darauf Bezug) */
  public static CDOPT_ZL_ANZ = 1165 /* "ANZ"=Zusatzleistungen können mit einer Anzahl gebucht werden */
  public static CDOPT_INDIV_ANZLG = 1166 /* "100"=100% Anzahlung auf einzelnen Preisen möglich */
  public static CDOPT_STAFFELPREISE = 1167 /* "ST"=Staffelpreise stehen zur Verfügung */
  public static CDOPT_ANFANGSKONTINGENT = 1168 /* "KAT"=Anfangskontingent auf Ebene Landleistungskategorie möglich */
  public static CDOPT_STREICHPREISE = 1169 /* "SP"=Streichpreise für Übersichten im Internet auf Basis ausgewählter Zusatzleistungen und/oder Default-Gutscheincodes berechnen */
  public static CDOPT_GEODATEN = 1170 /* "GEO"=Geodaten möglich */
  public static CDOPT_MAHNUNG = 1180 /* "AUTO"=Automatische Mahnung, "AUTO-G"=do., jedoch Gutscheine NICHT mahnen; "AUTO-E"=do. Posten einzeln mahnen */
  public static CDOPT_RUECKZLG = 1181 /* "SEPA"=Rückzahlung via SEPA-Record (Deutschland) an Bank/Post möglich */
  public static CDOPT_MWST_LAND = 1182 /* "STR"=MWST-Land kann auf Ebene Standardreise bestimmt werden */
  public static CDOPT_ZLG_BESTAETIGUNG = 1183 /* "EINL"=Zahlungseingänge aus Einlesevorgängen werden mit einem Mail bestätigt */
  public static CDOPT_PROVISIONSABRECHNUNG = 1184 /* "DS"=Bei der Provisionsabrechnung automatisch Abrechnungsdossiers erstellen */
  public static CDOPT_STR_REFERENZ = 1200 /* "REK"=Referenz auf eine ältere gleiche oder ähnliche Reise erlaubt (rekursiv) */
  public static CDOPT_STAMMZL = 1201 /* "STZL"=Standardreisen für Stammzusatzleistungen möglich */
  public static CDOPT_AUSFLUGSPAKET = 1202 /* "A"=nur Ausflugszeiten mit Überprüfung; "AP"=Ausflugspakete möglich; "APA"=do. inkl. Erfassungsassistent */
  public static CDOPT_SPEZ_REISEPROG = 1203 /* Die Buchstaben geben die freigeschalteten Reiseprogrammarten an: "R"=Rundreisehotels */
  public static CDOPT_TRSP_DEST_VORAUSWAHL = 1204 /* "TRDEST"=Ankunftsdest. Anreise bzw. Abreisedest. Rückreise festlegen, Vorauswahl des Abreiseflughafens, Zwischentransport autom. bestimmen */
  public static CDOPT_LEISTUNGSART = 1205 /* "FAKn"=Die Leistungsart ist fakultativ; n=(Angabe fakultativ) Zahl von 1-3 ist der Default bei einer neuen Standardreise */
  public static CDOPT_STR_INDIV_VERFALL = 1206 /* "VABR"=Verfall auf der Standardreise pro Abreisedatum möglich */
  public static CDOPT_DASHBOARD = 1220 /* "DBZ"=Dashboard als separate Verwaltung für Benutzer gem. Zuständigkeit 1070 aktivieren */
  public static CDOPT_RECHTEVERWALTUNG = 1221 /* "BERCD"=Rollen+Zuständigkeiten, die auf bisherigen Berechtigungen basieren, können im Prog. angepasst werden; Zuweisung über den Berechtcode */
  public static CDOPT_MANDANTENWECHSEL = 1222 /* "MWCD"=Mandantenwechsel möglich, sofern der CDMandant identisch ist und dem Benutzer eine passende Berechtigungsrolle zugeteilt wurde */
  public static CDOPT_CODEBAUM = 1225 /* "CDB"=Codebaum möglich */
  public static CDOPT_TESTMANDANT = 1226 /* Testmandant, auf den die Daten des aktuellen Mandanten kopiert werden können */
  public static CDOPT_TESTDATENBANK = 1227 /* "TESTDB"=Es handelt sich um eine Testdatenbank, die Benutzer mit Zuständigkeit 1065 ab Produktivdatenbank neu laden können. */
  public static CDOPT_INDIV_HERKUNFT = 1228 /* "IND"=Individuelle Herkunftscodes gem. Codeart 26 möglich */
  public static CDOPT_LL_KONTWARNUNG = 1240 /* "LLH[D]"=Kontingentswarnung [D=vorangewählt] bei allen LL-Produkten mit KONTOCODE 1 möglich; Prüfung auf < freie Einheiten gem. 5011/142 */
  public static CDOPT_LL_KONTINGENTSART = 1241 /* "O"=Bei Landleistungen kann die Kontingentsart "Kontingentsführung ohne Prüfung" aktiviert werden */
  public static CDOPT_LL_VON_BIS_INFOS = 1242 /* "LL"=Erweiterte Vorgabewerte für vermerkte Produktinfos auf Landleistung möglich; "KAT"=do. auf Kategorie; Werte kombinierbar */
  public static CDOPT_APP_BEILAGEN = 1260 /* "BL"=Bei E-Mailnachrichten an den Support können Beilagen wie z.B. Bilder mitgeschickt werden */
  public static CDOPT_APP_DOK = 1261 /* "PDF"=Freie rechnungsähnliche Dokumente (RGCODE 60) vom Typ PDF können in der App angezeigt werden. */
  public static CDOPT_APP_CH_FZ_WECHSEL = 1262 /* "CFW"=Chauffeur- und Fahrzeugwechsel für alle App-Benutzer erlaubt; "CFWZ"=do., Wechsel jedoch nur mit Zuständigkeit Master-Login erlaubt */
  public static CDOPT_APP_ZAHLUNGEN = 1263 /* "ZLG"=Der Chauffeur kann direkt in der App Zahlungen erfassen, welche beim Abschliessen des Auftrags automatisch im Tourdata verbucht werden */
  public static CDOPT_APP_SMS_VERSAND = 1264 /* "SRV"=SMS-Versand aus der Chauffeur-App über unseren Server statt über die SMS-App seines Handys */
  public static CDOPT_APP_ZUSATZLEISTUNGSLISTE = 1280 /* "ZUSL"=Zusatzleistungsliste steht in der App zur Verfügung */
  public static CDOPT_APP_BORDMANIFEST = 1281 /* "BM"=Das Bordmanifest steht in der App zur Verfügung */
  public static CDOPT_APP_SITZPLAN = 1282 /* "SITZ"=Der Sitzplan steht in der App zur Verfügung */
  public static CDOPT_APP_EIN_AUSSTIEGSLISTE = 1283 /* "EINL"=Die Ein-/Ausstiegsliste steht mit der Auswahl Einstieg Hinreise in der App zur Verfügung */
  public static CDOPT_DEFAULT_GSCODE = 1300 /* Default-Gutscheincode: "Bn" Buchungsdatum von/bis oblig. (fak. n=max. Tg); "S-"=Standardreise ohne Platzhalter oblig. (kombinierbar) */
  public static CDOPT_STORNOBED = 1301 /* "V"=Stornomodul für Verkaufsbedingungen, "E"=Stornomodul für Einkaufsbedingungen, Werte kombinierbar */
  public static CDOPT_NEWSLETTER_GS = 1302 /* "ANM"=Persönlicher Gutscheincode bei Newsletteranmeldung möglich */
  public static CDOPT_VP_AB_EP = 1303 /* "BER"=Der Verkaufspreis kann aus dem Einkaufspreis berechnet werden lassen */
  public static CDOPT_ERWEITERTE_OFFERTE = 1304 /* "EO"=Erweiterte Offerte (sep. Textbausteine auf Standardreise und Landleistung möglich) */
  public static CDOPT_ERW_KONT_EBENE = 1305 /* Ebenen, auf denen erweiterte Kontingente erlaubt sind */
  public static CDOPT_ERW_KONT_ART = 1306 /* Zusätzliche Arten von erweiterten Kontingenten, die freigeschaltet werden */
  public static CDOPT_GSPROD_KUNDENNR = 1307 /* Kurzbezeichnung des Gutscheinprodukts, das bei Marketinggutscheinen verwendet wird, die mit der Kundennr. kombiniert werden */
  public static CDOPT_DESTTXB = 1308 /* "FIX"=Pro Destination kann für Rechnung und/oder Reiseprogramm in der Textverarbeitung eine Beschreibung erfasst werden (TXBID=DESTID) */
  public static CDOPT_INFO_BEILAGEN = 1309 /* "BED"=Informationen können über Bedingungen gesteuert als Beilagen zu gebuchten Produkten mitaufbereitet werden */
  public static CDOPT_EP_TOTAL = 1310 /* "TOT"=Einstandspreise in Abhängigkeit von der Anzahl anderer Dossiers berechnen */
  public static CDOPT_PRVDAT = 1311 /* "MD"=Mehrere Daten zu Produktverbindungen möglich */
  public static CDOPT_INFO_TEXTE = 1312 /* "S"=Informationstexte auf Ebene Standardreise möglich/"-l"=bisherige Produktbeschreibungen ignorieren */
  public static CDOPT_DS_ERSETZEN = 1340 /* "AUFT"=Auftragsdossiers können durch ein neues Dossier unter anderem Mandanten ersetzt werden */
  public static CDOPT_APP_DRITTANBIETER = 1341 /* "MAN"=Manuelle Übermittlung von Dossiers an Drittanbieter-App (via Automation) möglich */
  public static CDOPT_OFFERTKONTR = 1342 /* "MAN"=Die Offertekontrolle kann manuell bei den Kopfdaten geführt werden */
  public static CDOPT_LEIST_VERSCHIEBEN = 1343 /* "VERSCH"=Im Teilnehmerregister kann man Leistungen um ein paar Tage verschieben */
  public static CDOPT_DSABLAGE = 1344 /* "ABL"=Individuelle Dokumente eines Dossiers können in Ordnern gruppiert werden */
  public static CDOPT_MODULE_BIS = 1999 /* Bis zu diesem Code handelt es sich in der Regel um Module */
  public static CDOPT_DBLINK = 2000 /* "TD"=Tournet -> Tourdata, "TN"=Tourdata -> Tournet */
  public static CDOPT_FB_NACHBEARBEITEN = 4500 /* "FAKnnnn"=Fakultative Fragebogenfelder bis nnnn Tage vor Abreise durch den Kunden änderbar. Bei Buchung Link zur Kontrolle immer mitschicken */
  public static CDOPT_LLTYP_TLN_BEM = 4501 /* Einer der Buchstaben muss am Anfang der LL-Kurzbezeichnung mit einem Blank danach vorkommen, damit die allgem. Tln.Bemerkung übernommen wird */
  public static CDOPT_TR_RQ_UEBERBUCHEN = 4502 /* "UEB"=Bei einem Request auf einem Transport kann "Request überbuchen" gewählt werden (kann zu negativer Anzahl Freiplätzen führen!) */
  public static CDOPT_GASTRO_CHECKBOXEN = 4503 /* Anzahl aktivierter Gastronotiz-Checkboxen (z.Z. max. 1 erlaubt, Beschriftung gem. Codeart 1015, Codes 501 uff.) */
  public static CDOPT_TLN_GEBDAT_FAK = 4504 /* Das Geburtsdatum ist bei Teilnehmern in folgenden Fällen fakultativ: "F"=Personenfunktionen im Dispo-Bereich, d.h. Codes 1-49; "O"=Optionen) */
  public static CDOPT_TLNBEM_ZUSAMMENREISEND = 4505 /* "INET"=Bei Internetbuchungen mit mehr als einer Kabine pro ersten Teilnehmer pro Kabine, Text gem. 5013/1140 und Namen + Kabinennr. aufber. */
  public static CDOPT_TLN_EMAIL = 4506 /* "FAK"=E-Mailadresse pro Teilnehmer abfragen (Eingabe fakultativ) + bei der Person speichern, "OBLIG"=do. obligatorisch */
  public static CDOPT_FB_VERSAND = 4507 /* "EMAIL"=Wenn E-Mailadr. vorhanden, den Fragebogen immer per E-Mail verschicken (zur Online-Erfassung; Default nur bei Internetbuchungen) */
  public static CDOPT_DSHINWEIS_OPTIONEN = 4520 /* "ADR"=Der Dossierhinweis ist bei Optionen nicht zwingend, wenn im Dossier mindestens eine Adresse erfasst wurde */
  public static CDOPT_REISEBEZ = 4521 /* "HINW"=Der Dossierhinweis wird auch bei Pauschalreisen als Reisebezeichnung verwendet (anstelle der Standardreise selbst) */
  public static CDOPT_RGNR_LUECKENLOS = 4522 /* "ALLE"=Alle RGLAUFNR 1-99 sind lückenlos, es können nur letzte Einträge physisch gelöscht werden. */
  public static CDOPT_RQ_HERVORHEBEN = 4523 /* "FARBIG"=Leistungen, die noch auf Request sind, im Teilnehmerregister farbig (rot) anzeigen */

  /*--------------------------------------------------------------------------*/

  public static TAB_FAX = 5010 /* Einstellungen für den Faxoutput */
  public static CDFAX_NAME = 1    /* Name des Fax-"Druckers" */
  public static CDFAX_SCHRIFT = 10   /* Schriftart und -grösse, sofern eine bestimmte für Faxbefehle verwendet werden muss */
  public static CDFAX_CMD_NR = 11   /* Befehl zur Einleitung der Faxnummer (Faxnr bei %s einsetzen) */
  public static CDFAX_CMD_BETREFF = 12   /* Befehl zum Einfügen eines Betreffs (Betreff bei %s einfügen) */
  public static CDFAX_CMD_ENDE = 19   /* Befehl zum Abschluss des Dokuments */

  /*--------------------------------------------------------------------------*/

  public static TAB_TERMINE = 5011 /* Ausführungsdaten, -zeiten und -fristen (Die Kurzbezeichnung enthält den Wert) */
  public static CDT_OPT_VERFALL = 1    /* Vorschlag für die Anzahl Tage, nach der eine Option verfällt. */
  public static CDT_OFFERT_VERFALL = 2    /* Vorschlag für die Anzahl Tage, nach der eine Offerte verfällt. */
  public static CDT_RQ_VERFALL = 3    /* Vorschlag für die Anzahl Tage, nach denen ein Request überprüft werden sollte. */
  public static CDT_OPT_VERFALL_INTERNET = 4    /* Vorschlag für die Anz.Tage, nach der im Internet erfasste Option verfällt (leer=gem.Code 1, 0=sofort) */
  public static CDT_OPT_VERFALLZEIT = 5    /* Defaultverfallzeit für Optionen, wenn Leistungsverfall noch nicht erreicht */
  public static CDT_RQ_VERFALLZEIT = 7    /* Vorschlag Uhrzeit, um die ein Request überprüft werden sollte (Default Anfragezeitpunkt) */
  public static CDT_PNL_ZEIT_CATERING = 11   /* Zeitpunkt (HH:MM) für den automatischen PNL-Versand betr. Catering (2 Tage + Wochende vor Abreise) */
  public static CDT_PNL_ZEIT_CHECKIN = 12   /* Zeitpunkt (HH:MM) für autom. PNL-Versand betr. Checkin (2 Tage vor Abflug, mind. 2 Std nach Code 11) */
  public static CDT_PNL_NOTFALLLISTE = 13   /* Zeitpunkt (HH:MM) für den automatischen Versand der Notfallliste (nur für Ein-/Ausstiegsliste Kurs) */
  public static CDT_BEREITSTELLZEIT = 21   /* Vorgabe für die Anzahl Minuten, die das Fahrzeug vor Abfahrt bereitstehen muss */
  public static CDT_GARAGENZEIT = 22   /* Vorgabe für die Anzahl Minuten, die die Garagenzeit vor der Bereitstellungszeit sein muss */
  public static CDT_ABFAHRTSZEIT = 23   /* Vorgabe für die Abfahrtszeit (HH:MM, leer=keine Vorgabe) */
  public static CDT_ENDZEIT = 24   /* Vorgabe für die Endzeit (HH:MM, leer=keine Vorgabe) */
  public static CDT_GARAGEN_ENDZEIT = 25   /* Vorgabe Anz.Minuten nach Endzeit (leer=kein Feld Garagen-Endzeit, Zeit in der Datenbank gem. Endzeit) */
  public static CDT_APP_TAGE_VOR_ABREISE_VOLLSTAENDIG = 26   /* In der Chauffeur-App werden Dispoeinträge frühestens diese Anzahl Tage vor Abreise vollständig angezeigt (davor nur Kopfdaten) */
  public static CDT_APP_TAGE_VOR_ABREISE = 26   /* *** NICHT MEHR VERWENDEN *** Alter Define bis Version 3.84a. Bedeutung des Codes wurde verändert, daher nur noch neuen Define verwenden! *** */
  public static CDT_APP_TAGE_NACH_RUECKREISE = 27   /* In der Chauffeur-App werden Dispoeinträge bis zu dieser diese Anzahl Tage nach Rückreise angezeigt */
  public static CDT_APP_TAGE_VOR_ABREISE_PENDENT = 28   /* In der Chauffeur-App werden pendente Dispoeinträge frühestens diese Anzahl Tage vor Abreise angezeigt */
  public static CDT_APP_TAGE_VOR_ABREISE_DOKU = 29   /* In der Chauffeur-App manuell hinzugefügte Dokumente frühestens diese Anzahl Tage vor Abreise anzeigen (bei pend. Dossiers nie vor Code 28) */
  public static CDT_TOURNET_ALLE_DATEN = 30   /* Zeitpunkt, an dem die Tournetdaten aller Mandanten automatisch neu übermittelt werden (nur im Tournet) */
  public static CDT_TOURNET_KONT_AB = 31   /* Erster Zeitpunkt an dem Kontingente automatisch an Tournet übermittelt werden */
  public static CDT_TOURNET_KONT_BIS = 38   /* Letzter Zeitpunkt an dem Kontingente automatisch an Tournet übermittelt werden */
  public static CDT_TOURNET_MANUELL = 39   /* Zeitpunkt, an dem die manuelle Datenübermittlung an Tournet durchgeführt wird (Default: sofort) */
  public static CDT_VERFALLZEIT1 = 41   /* Erste mögliche Zeit für einen Verfall */
  public static CDT_VERFALLZEIT2 = 42   /* Zweite mögliche Zeit für einen Verfall */
  public static CDT_VERFALLZEIT_BIS = 48   /* letzter Code, der für Verfallzeiten benützt werden kann */
  public static CDT_VERFALL_BUS_VOR_ABREISE = 49   /* Verfall für Busse mit Einstiegsorten X Min. vor Abreise: Wenn ausgewählt, wird er autom.  nachgeführt */
  public static CDT_AGENDAZEIT = 50   /* Vorgabe für die Zeit von neuen Agendaeinträgen (HH:MM) */
  public static CDT_WARTEZEIT_PRIO1 = 51   /* Wartezeit (1. Priorität für z.B. Zimmernr.anfragen) in Sek. bis  Meldungen erneut geladen werden */
  public static CDT_WARTEZEIT_AGENDA = 55   /* Wartezeit in Sek., bis Agenda (evt. ohne Dossier) aktualisiert wird */
  public static CDT_WARTEZEIT_DSAGENDA = 56   /* Wartezeit, bis Dossieragenda aktualisiert wird (Default=Code 55) */
  public static CDT_SICHERUNG_VON = 61   /* Startzeit (HH:MM) der Datensicherung */
  public static CDT_SICHERUNG_BIS = 62   /* Zeit (HH:MM), an der die Datensicherung sicher fertig sein sollte... */
  public static CDT_ADR_ABGLEICH = 71   /* Zeit (HH:MM), wann die Übermittlung der veränderten Adressen an die Fremd-DB stattfindet (nur Tournet) */
  public static CDT_WVJ_UEBERM = 72   /* Zeit (HH:MM) der Übermittlung der WVJ-Daten an ein Drittsystem (nur Tournet) bzw. WVJ-Nr. die wiederholt werden muss (TN, aber pro Mandant) */
  public static CDT_SQL_EXPORT = 73   /* Zeit (HH:MM), wann der vordefinierte automatische SQL-Export (z.Z. fix Martis Adressexport für die Telefonzentrale) ausgeführt wird */
  public static CDT_KOMET_ABGLEICH = 81   /* Zeit (HH:MM), wann die Kontingente mit dem Komet-System abgeglichen werden */
  public static CDT_AIRBERLIN_SENDEN = 82   /* *** alt *** Zeit (HH:MM), wann die Kontingente + Buchungszahlen an Air Berlin übermittelt werden */
  public static CDT_AIRBERLIN_EMPFANGEN = 83   /* *** alt *** Zeit (HH:MM), wann die Kontingente von Air Berlin abgeholt werden (Default unmittelbar nach Code 82) */
  public static CDT_AIRBERLIN_DIFF_TAKT = 84   /* *** alt *** Abstand in Minuten zwischen zwei Differenzdatenübermittlungen (ohne Kurzfrist) */
  public static CDT_AIRBERLIN_PNL_TAKT = 85   /* *** alt *** Abstand in Minuten zwischen zwei PNL-Übermittlungen an Air Berlin (ohne kurzfristige Änderungen) */
  public static CDT_AIRBERLIN_PNL_FEHLER = 86   /* Zeit in Min., nach der bei wiederholtem Misserfolg der Übermittlung eine Fehlermeldung gemailt wird */
  public static CDT_AUFTR_NICHT_FAKT = 91   /* Aufträge, die X Tage nach Rückreise  noch nicht fakturiert sind, in Agenda anzeigen */
  public static CDT_DOK_NICHT_VERSENDET = 92   /* Dossiers, die X Tage vor Abreise weder Versand-SB noch -Datum haben, in Agenda des Dok-Superusers anzeigen */
  public static CDT_TICKETS_NICHT_AUFBEREITET = 93   /* Dossiers, bei denen X Tage vor Abreise nicht alle Tickets gedruckt sind, in Agenda der Ticket-SBs (Abweichungen im Produkt bestimmbar) */
  public static CDT_DS_KURZFRISTIG = 94   /* Dossiers, die X oder weniger Tage vor Abreise neu gebucht wurden, in Agenda des Kurzfristige-Buchung-Sachbearbeiters anzeigen */
  public static CDT_DS_NICHT_IN_APP = 95   /* Dossiers, die X Tage vor Abreise nicht in der App sind, in der Agenda anzeigen */
  public static CDT_MIN_TR_KONT_OPT = 101  /* Min. freie Transportkontingente damit im Tournet noch Optionen erfasst werden können */
  public static CDT_MAX_TR_KONT_O_BINDUNG = 102  /* Höchstanzahl Freiplätze, damit die Bindung von Hin- und Rückreise nicht mehr erforderlich ist */
  public static CDT_MAX_TR_KONT_BUKLASSE = 103  /* Höchstanzahl Freiplätze einer Buchungsklasse, die im Internet angezeigt werden darf */
  public static CDT_MAX_CRS_WEITERFLUG = 104  /* Höchstanzahl Stunden zwischen Ankunft und Weiterflug vom selben Ort, damit beim Folgeflug das Produkt CRS_WEITER vorgeschlagen wird. */
  public static CDT_MAX_TG_TICKET_TLN_AENDERBAR = 105  /* Anz. Tage vor Abreise, bis zu der bei Transporten mit "Ticketdruck möglich" Verkäufer Teilnehmer verändert dürfen, sofern Ticket nicht gedr. */
  public static CDT_TR_UPSELLING = 106  /* Anzahl Tage vor Beginn der Transportleistung, ab der auf Transport-Upselling-Möglichkeiten geprüft wird */
  public static CDT_MAX_WMAT_INTERNET = 111  /* Höchstanzahl gleichzeitig bestellbarer Prospekte im Internet */
  public static CDT_BUZEIT_INTERNET = 121  /* Spätester Internet-Buchungszeitpunkt (HH:MM) am Tag gem. Code 122 vor der Abreise (Default 17 Uhr) */
  public static CDT_BUDAT_INTERNET = 122  /* Letzter Tag vor Abreise, an dem die Internetbuch. bis um Zeit gem.Code 121 möglich ist (Def.1=Vortag) */
  public static CDT_UMFRAGE_VON = 123  /* Umfrage ist frühestens X Tage vor Rückreise möglich (Default 0=Rückreisedatum) */
  public static CDT_UMFRAGE_BIS = 124  /* Umfrage kann bis spätestens X Tage nach Rückreise ausgefüllt werden (Default 30 Tage) */
  public static CDT_KURZFRISTIG_BIS = 125  /* Anzahl Tage vor Abreise, ab der eine Buchung als kurzfristig angesehen wird (für z.B. Kreditkartenzahlung; Default 28) */
  public static CDT_UMFRAGE_MIN_OK = 126  /* Umfragen, deren Durchschnittsbewertung mindestens diesem Wert entsprechen, können direkt Ok gesetzt werden. */
  public static CDT_UMFRAGE_LISTBOX_AB = 127  /* Anzahl Auswahlwerte, ab der unabhängige Codes als Listbox statt als Radiobuttons oder Checkboxen angezeigt werden (Default 0=immer) */
  public static CDT_DSABREISE_NACH_EFFEKTIVER = 128  /* Das Dossierabreisedatum darf maximal diese Anzahl Tage nach dem effektiven Abreisedatum der Reise sein (Default 7) */
  public static CDT_MAX_LAENGE_MITTEILUNG = 129  /* Maximale Länge des Mitteilungsfeldes für Gutscheine (Default 250 Zeichen) */
  public static CDT_EINKDATEN_UEBERM = 131  /* Zeit (HH:MM) der Übermittlung der Einkaufsdaten an ein Drittsystem */
  public static CDT_SELL_REPORT = 132  /* Zeit (HH:MM) für autom. Übermittlung von Sammel-Sell & Reports (Option "Z" bei Codeart 5009, Cd 1040) */
  public static CDT_ERI_EMAILS = 133  /* Zeit (HH:MM) Erinnerungs-E-Mails */
  public static CDT_GEPAECK_EMAILS = 134  /* Zeit (HH:MM) für autom. E-Mailversand von Gepäcktransportlisten */
  public static CDT_EP_ABRECHNUNG = 135  /* Zeitpunkt (HH:MM) der automatischen Einstandspreismeldung (Hotelabrechnung) */
  public static CDT_RADLISTEN = 136  /* *TEMPORÄR*: Zeit und Wochentag (HH:MMWW), an dem die Radlisten automatisch versendet werden  */
  public static CDT_FLUGHAFENPLAN = 137  /* *TEMPORÄR*: Zeit (HH:MM) des automatischen Versands des Flughafenplans an den Agenten der 1. (Ankunft) bzw. letzten (Abflug) Landleistung */
  public static CDT_PARKSYSTEM_EXPORT = 138  /* Zeitpunkt (HH:MM) für die Aufbereitung der Exportdatei für das Parksystem */
  public static CDT_KEINE_ZINR_RES_AB = 140  /* Ab dieser Anzahl Tage vor Abreise können keine Zimmernummern mehr reserviert werden */
  public static CDT_MIETWAGEN_MAX_TOLERANZ = 141  /* Anz. Minuten die zwischen Abgabe- + Rückgabezeit liegen darf, ohne dass ein zus. Tag berechnet wird */
  public static CDT_LLKONT_WARN_ANZ = 142  /* Default bzw. Vorschlag für Anzahl Freiplätze auf Landleistungskontingenten, unter der eine Warnung ausgelöst wird */
  public static CDT_LLTICKET_ZEIT = 143  /* Zeit (HH:MM) für den automatischen Versand der Landleistungstickets (nur Tournet) */
  public static CDT_LLTICKET_AB = 144  /* Ab dieser Anzahl Tage vor Abreise werden die Landleist.tickets per Batch automatisch verschickt (Tournet) bzw. sofort angestossen (Tourdata) */
  public static CDT_ZUG_VOR_ABFLUG_MINUTEN = 145  /* Anzahl Minuten vor Abflug, die ein Zug spätestens am Flughafen ankommen muss, damit er vorgeschlagen wird (z.Z. nur für DB-Viator; Def. 120) */
  public static CDT_LLBISDAT_ANPASSEN_BEI_MIN_PROZ_FLUEGEN = 146  /* Minimal nötige Rück- oder Weiterflüge nach einer Landleistung in Prozent, die zwischen 0:00 und 3:00 Uhr starten müssen */
  public static CDT_KEINE_AUTOM_MAHNUNG = 150  /* Ab dieser Anzahl Tage vor Abreise wird keine Mahnung mehr automatisch verschickt */
  public static CDT_MIN_ANZAHLUNG = 151  /* Anzahlungen über weniger als diesen Betrag in Landeswährung werden nicht in Rechnung gestellt und auch nicht gemahnt */
  public static CDT_MAX_FREIER_GUTSCHEIN = 152  /* Freie Gutscheine können bis zu diesem Maximalbetrag ausgestellt werden (Default 100.-) */
  public static CDT_MIN_GSBETRAG = 153  /* Beim Verkauf von Gutscheinen mit frei wählbarem Betrag, ist dies der Mindestbetrag (Default 10.-) */
  public static CDT_MAX_GSBETRAG = 154  /* Beim Verkauf von Gutscheinen mit frei wählbarem Betrag, ist dies der Maximalbetrag (Default 2000.-) */
  public static CDT_MIN_ANZAHLUNGSMAHNUNG_REISEART = 155  /* Anzahlungen über weniger als diesen Betrag in Landeswährung werden nicht gemahnt, sofern die Reiseart mit #A gekennzeichnet ist */
  public static CDT_PUNKTWERT = 156  /* Wert eines Punktes in Landeswährung bei Gratis-gutscheinen für die Berechnung des Textfeldes {Gutscheincode.Punkte} (Default 1)  */
  public static CDT_MAX_AUTOM_AUSBUCHUNG = 157  /* Maximalbetrag eines Postens, der bei Kundenschulden beim Mahnlauf automatisch mit Buart "41" ausgebucht werden darf (Default 1.00) */
  public static CDT_MIN_TAGE_SOFORT = 158  /* Min. Tage Zahlungsfrist, wenn Zlg.bed. gem. Abreisedatum+Betrag sofort fällig ist (ausser bei Stoni nicht übers Abreisedatum hinaus ändern) */
  public static CDT_MAHNUNG_BIS_VERFALL_TAGE = 159  /* Default Anzahl Tage vor heute für das Feld "Bis Verfalldatum" beim automatischen Mahnlauf (Default 8) */
  public static CDT_ARC_DIDOK_MONATE = 160  /* Autom. Archivierung (Löschung) von Dispo-Dokumenten frühestens n Monate nach Abreise (Default 60) */
  public static CDT_ARC_PARTNER_MIT_DS_JAHRE = 165  /* Anzahl Jahre nach denen Adressen mit Dossiers anonymisiert werden dürfen (Default 10) */
  public static CDT_CDT_AUTOM_SKRIPT = 170  /* Zeit (HH:MM), wann automatische SQL-Skripte gem. Codeart 5020 ausgeführt werden */
  public static CDT_CHAT_TAGE_OFFEN = 180  /* Anzahl Tage, die ein Chatverlauf ohne neue Einträge standardmässig noch angezeigt wird (Default 30) */
  public static CDT_MAX_TLN_KREDITKARTE = 200  /* Max. PAX, bis zu der Kreditkartenzahlungen im Internet möglich sind (Default keine Beschränkung; DIREKTZAHLUNGSCODE hat höhere Priorität) */
  public static CDT_UMFRAGE_STATISTIK_AB = 201  /* Umfragen werden ab diesem Datum (TT.MM.JJJJ) ausgewertet (z.B. für Durchschnittswerte in der Exportdatei) */
  public static CDT_MAX_TLN_INTERNET = 202  /* Max. PAX, die übers Internet gebucht werden können (Default 9) */
  public static CDT_FRAGEBOGEN_OBLIG_AB = 203  /* Obligatorische Felder von Fragebogen müssen bei Onlinebuchungen zwingend ausgefüllt werden, wenn sie höchstens Anzahl Tage davor erfolgt */
  public static CDT_UPSELLING_BIS = 204  /* Anzahl Tage vor Abreise, bis zu der Upsellings durch den Kunden selbst vorgenommen werden können */
  public static CDT_UPSELLING_ABSTAND = 206  /* Minimaler Abstand in Tagen zwischen Upselling E-Mails */
  public static CDT_FRAGEBOGEN_SPERREN_AB = 207  /* Anzahl Tage vor Abreise. ab der vollständig erfasste Fragebogen nur noch durch Dossieradministratoren verändert werden dürfen */
  public static CDT_APP_PUSH_TAGE_VOR_ABREISE = 250  /* Push-Meldungen für die App frühestens diese Anzahl Tage vor Abreise */
  public static CDT_APP_PUSH_TAGE_NACH_RUECKREISE = 251  /* Push-Meldungen für die App bis zu dieser Anzahl Tage nach Rückreise */
  public static CDT_EP_TAGE_VOR_ABREISE1 = 311  /* Einstandspreise x Tage vor Abreise automatisch aktualisieren */
  public static CDT_EP_MIN_MARGE1 = 321  /* Grenzwert für minimale Marge */
  public static CDT_EP_AUFR_FAKTOR = 331  /* Einheitlicher Aufrechnungsfaktor für die Kalkulation der Verkaufs- aus den Einstandspreisen. */

  /*--------------------------------------------------------------------------*/

  public static TAB_DEBUG = 5012 /* Optionen, die gesetzt werden können, um bestimmte Programmteile zu testen */

  /*--------------------------------------------------------------------------*/

  public static TAB_TEXTKONSTANTE = 5013 /* Kurze Textkonstanten für die Textaufbereitung (ab 1000 für Emails) */
  public static CDTXT_RUECKREISE_PROMPT = 1    /* Text, der eingesetzt wird, wenn ein Rückreisedatum  vorhanden ist */
  public static CDTXT_VOID = 2    /* Text, der eingesetzt wird, wenn noch weitere Flüge vorhanden sind */
  public static CDTXT_NACHBARSSITZ = 3    /* Erscheint hinter der gebuchten Sitzplatznr., wenn ein freizuhaltender Nachbarssitz mitgebucht wurde */
  public static CDTXT_OHNE_PNR = 4    /* Erscheint im Feld {HFL...} bzw. {RFL.Text, wenn ohne PNR}, falls Transp.buchung ohne PNR erfolgte */
  public static CDTXT_OHNE_FREIGEPAECK = 5    /* Text, der erscheint, wenn kein Gepäck aufgegeben werden kann. */
  public static CDTXT_EIN_FREIGEPAECK = 6    /* Text für "ein Freigepäck" */
  public static CDTXT_MEHR_FREIGEPAECK = 7    /* Text für mehere Stücke Freigepäck */
  public static CDTXT_KG_FREIGEPAECK = 8    /* Text für "Gepäck" ohne Anzahlangaben beim KG-Konzept */
  public static CDTXT_1_WOCHE = 11   /* Text, der eingesetzt wird, wenn etwas eine Woche dauert */
  public static CDTXT_X_WOCHEN = 12   /* Text, der eingesetzt wird, wenn etwas mehrere Wochen dauert */
  public static CDTXT_WO_UND_TG = 13   /* Trennwort, das eingesetzt wird, wenn die Dauer Wochen und Tage umfasst (fakultativ) */
  public static CDTXT_1_TAG = 14   /* Text, der eingesetzt wird, wenn etwas einen Tag dauert */
  public static CDTXT_X_TAGE = 15   /* Text, der eingesetzt wird, wenn etwas mehrere Tage dauert */
  public static CDTXT_PB_TEXT = 20   /* Preisblock-Überschrift für Textspalte */
  public static CDTXT_PB_ANZAHL = 21   /* Preisblock-Überschrift für Anzahl-Spalte */
  public static CDTXT_PB_ANSATZ = 23   /* Preisblock-Überschrift für Ansatz-Spalte */
  public static CDTXT_PB_WAEHRUNG = 24   /* Preisblock-Überschrift der Währungsspalte */
  public static CDTXT_PB_BETRAG = 25   /* Preisblock-Überschrift  für Betragsspalte */
  public static CDTXT_PB_INLAND = 26   /* Preisblock-Überschrift für MWST-Inland-Spalte */
  public static CDTXT_PB_MWST = 27   /* Preisblock-Überschrift für MWST-Prozent-Spalte */
  public static CDTXT_PB_KOMMISSION = 28   /* Preisblock-Überschrift für Kommissionsspalte */
  public static CDTXT_PB_INLAND_BESCHR = 36   /* Zusätzliche Beschreibung der Inlandspalte. Erscheint nur, wenn auch die Inlandspalte gedruckt wird. */
  public static CDTXT_DAT_FMT = 40   /* Standardformat für Datumfelder (Default "DD.MM.YY") */
  public static CDTXT_ZEIT_FMT = 41   /* Standardformat für Zeitfelder (Default "HH:MM") */
  public static CDTXT_BETR_FMT = 42   /* Standardformat für Beträge: 1. Stelle=Tsd.Trennzch. (fakultativ) 2. Stelle=Dez.Trennzch.(Default ".") */
  public static CDTXT_DAT_KURZ_FMT = 45   /* Kurzformat des Datums, z.Z. nur  für die Preiszeile (Default "DD.MM.") */
  public static CDTXT_RPGR_DAT_FMT = 46   /* Datumformatierung, wird, wenn vorhanden, beim Reiseprogramm für Datumfelder von Transport und Landleistungen anstelle von Code 40 verwendet */
  public static CDTXT_KAT_BEL1 = 51   /* Spezielle Kategoriebezeichnung, falls Kurzbezeichnung auf "#1" endend und nur durch 1 Person belegt */
  public static CDTXT_KAT_BEL2 = 52   /* Spezielle Kategoriebezeichnung, falls Kurzbez. auf "#1" endend und durch 2 oder mehr Personen belegt */
  public static CDTXT_KAT_BEL_DIV = 59   /* Anzeige vor spez. Kategoriebez., falls Kurzbez. auf "#1" endend + durch unterschiedl. Anz. Pax belegt */
  public static CDTXT_ANGEKREUZT = 60   /* Zeichen, die für ein angekreuztes Feld aufbereitet werden */
  public static CDTXT_NICHT_ANGEKREUZT = 61   /* Zeichen, die für ein nicht angekreutes Feld aufbereitet werden */
  public static CDTXT_ODER_NAECHSTER_AUSFLUG = 70   /* Text für Wort "oder", wenn alternativ der nächste Ausflug gebucht werden kann */
  public static CDTXT_VON = 71   /* Text für Wort "von" */
  public static CDTXT_NACH = 72   /* Text für Wort "nach" */
  public static CDTXT_ANK_NAECHSTER_TAG = 80   /* Text für {HFL....} bzw. {RFL.Ankunft nächster Tag}, wenn die Ankunft am nächsten Tag erfolgt (REISEDAUER 1) */
  public static CDTXT_BUSTAND_INKL = 90   /* Text für Buchungsstand */
  public static CDTXT_BUSTAND_FAHRER = 91   /* Text für Buchungsstand Fahrer */
  public static CDTXT_BUSTAND_REISELEITER = 92   /* Text für Buchungsstand Reiseleiter */
  public static CDTXT_BUSTAND_REQUEST = 95   /* Text für Buchungsstand Request */
  public static CDTXT_BUSTAND_UND = 98   /* Text für Buchungsstand "und" */
  public static CDTXT_DISPO_NICHT_BESTIMMT = 102  /* Text für Dispositionszubehör, das noch nicht bestimmt wurde */
  public static CDTXT_TLN_FMT = 120  /* Vorlage für Text bei nicht gebuchten Teilnehmern: ^AN=Teilnehmer-Anrede, ^VN=Teilnehmer-Vorname, ^NN=Teilnehmer-Nachname */
  public static CDTXT_KTO_LAUTEND_AUF = 200  /* Text für {Mandant.Konto lautend auf}  */
  public static CDTXT_REV_CHARGE = 220  /* Text für {Dossier.Reverse Charge-Hinweis} auf der Provisionsabrechnung */
  public static CDTXT_MFG = 1000 /* Text "Mit freundlichen Grüssen" */
  public static CDTXT_MAIL_ABS_BES = 1001 /* Beschriftung des Mailabsenders, Platzhalter ^SN=SB-Name, ^SV=SB-Vorname, ^VN=Veranstalter-Name, ^VV=Veranstalter-Vorname */
  public static CDTXT_SELL_REPORT_SCHLUSS = 1010 /* Schlusssatz beim automatischen Sell- & Report-Mail und bei Hotellisten mit Bestätigung */
  public static CDTXT_ANBIETER_BESTAETIGUNG = 1011 /* Text für die vom Anbieter verlangte Bestätigung auf Hotellisten (Unterschriftsüberschrift) */
  public static CDTXT_SELL_REPORT_BETREFF = 1012 /* Text anstelle Default für Betreff von Sell & Report-E-Mails (div. {}-Platzhalter möglich) */
  public static CDTXT_REQUEST_BETREFF = 1013 /* Text anstelle Default für Betreff von Request-E-Mails (div. {}-Platzhalter möglich) */
  public static CDTXT_HOTELABR_GUTSCHR = 1014 /* Gutschriftstext für die Hotelabrechnung */
  public static CDTXT_HOTELABR_STORNOGS = 1015 /* Fakultativer Text (im Sinne von "Stornogutschrift"), der vor stornierten Leistungen eingesetzt wird */
  public static CDTXT_TLN_PAX = 1020 /* Wort für "PAX", das bei Gruppenbuchungen hinter der Anzahl im Teilnehmervornamen eingetragen wird */
  public static CDTXT_TLN_PRBEM_STD = 1021 /* Bezeichnung des Feldes für die Teilnehmerbemerk. auf Ebene Standardreise (leer=Feld nicht anzeigen) */
  public static CDTXT_GSCODE = 1022 /* Fakultative individuelle Bezeichnung des Gutscheincode-Feldes (Default "Gutscheincode") */
  public static CDTXT_DSINDIV_STATUS = 1023 /* Bezeichnung des individuellen Dossierstatus-Feldes (Default="Individueller Status") */
  public static CDTXT_BESCHR_VON = 1040 /* Beginn der Bezeichnungen der Beschreibungsfelder, +PRCODE=zu berücksichtigender Code */
  public static CDTXT_STR_BESCHR = 1041 /* Bezeichnung des Beschreibungsfeldes auf der Standardreise (leer=Feld nicht anzeigen) */
  public static CDTXT_TRK_BESCHR = 1043 /* Bezeichnung des Beschreibungsfeldes auf der Transportkette (leer=Feld nicht anzeigen) */
  public static CDTXT_TRS_BESCHR = 1044 /* Bezeichnung des Beschreibungsfeldes auf der Transportstrecke (leer=Feld nicht anzeigen) */
  public static CDTXT_LL_BESCHR = 1045 /* Bezeichnung des Beschreibungsfeldes auf der Landleistung (leer=Feld nicht anzeigen) */
  public static CDTXT_KAT_BESCHR = 1046 /* Bezeichnung des Beschreibungsfeldes auf der Kategorie (leer=Feld nicht anzeigen) */
  public static CDTXT_BESCHR_BIS = 1059 /* Letzter für die Bezeichnungen des Beschreibungsfeldes reservierter Bereich */
  public static CDTXT_DIPERS_DAUER = 1060 /* Fakultative individuelle Bezeichnung für das Dauerfeld bei den Dispositionspersonen */
  public static CDTXT_DIPERS_BETRAG = 1061 /* Fakultative individuelle Bezeichnung für das Betragsfeld bei den Dispositionspersonen */
  public static CDTXT_DIPERS_SPESEN = 1062 /* Fakultative individuelle Bezeichnung für das Spesenfeld bei den Dispositionspersonen */
  public static CDTXT_DIPERS_TRINKGELD = 1063 /* Fakultative individuelle Bezeichnung für das Trinkgeldfeld bei den Dispositionspersonen */
  public static CDTXT_DIPERS_NACHTZULAGE = 1064 /* Fakultative individuelle Bezeichnung für das Nachzulagenfeld bei den Dispositionspersonen */
  public static CDTXT_DIPERS_KOSTENSTELLE = 1065 /* Fakultative individuelle Bezeichnung für das Kostenstellenfeld bei den Dispositionspersonen (Es wird ohne diese Bezeichnung nicht angezeigt) */
  public static CDTXT_DIPERS_BRUTTOBETRAG = 1067 /* Fakultative individuelle Bezeichnung für das Bruttobetragsfeld bei der Lohnabrechnung, wenn der erfasste Betrag hochgerechnet werden muss */
  public static CDTXT_ZLG_EINLESEN1 = 1080 /* Beschriftung für 1. Knopf für das Einlesen von (ESR- und anderen) Zahlungen */
  public static CDTXT_ZLG_EINLESEN2 = 1081 /* Beschriftung für 2. Knopf für das Einlesen von (ESR- und anderen) Zahlungen */
  public static CDTXT_BEILAGE_CRS_FLUGRES = 1100 /* Ablageort eines Dokuments zur Beilage bei Bestätigungen mit CRS-/externen Res.system-Flug-Buchungen */
  public static CDTXT_MLD_LEGENDE_FARBE_BASIS = 1120 /* Basiscode für Tooltipp-Farben Tooltipp-Code = CDTXT_MLD_TOOLTIP_FARBE_BASIS + Farbnr. (1-5) */
  public static CDTXT_MLD_LEGENDE_FARBE1 = 1121 /* Agenda-Tooltipp für Meldungsfarbe 1 (MLDSUBCODE 81) */
  public static CDTXT_TLNBEM_ZUSAMMENREISEND = 1140 /* Teilnehmerhinweistext (Travel together with) für autom. Verweise auf die anderen Teilnehmer, wenn im Dossier mehrere Kabinen gebucht wurden */
  public static CDTXT_SMS_LLTICKET_NICHT_ZUGESTELLT = 1300 /* SMS-Text, der verschickt wird, wenn ein Landleistungsticket per automatischem Versand nicht zugestellt werden konnte (Platzhalter möglich) */
  public static CDTXT_SMS_NICHT_ANTWORTEN = 1301 /* SMS-Text, der angehängt wird, wenn der Versand von SMS aus der Chauffeur-/RL-App an individuelle Kunden über unseren SMS-Server erfolgt */
  public static CDTXT_SMS_CHAUFFEUR_GRUSS = 1310 /* Grussformel am Ende der SMS, die via Handy des Chauffeurs verschickt werden */
  public static CDTXT_BUCHUNG_KK = 2000 /* Hinweis bei "Buchung OK", dass nachfolgend über die Kreditkarte abgebucht wird */
  public static CDTXT_BUCHUNG_ZBED_ZUSINFO = 2001 /* Zusatzinformationen, die vor den Zahlungsbedingungen aufbereitet werden (bevor der Kunde bestätigt) */
  public static CDTXT_REQUEST_INFO = 2002 /* Hinweis, dass Plätze nur auf Anfrage sind */
  public static CDTXT_BUCHUNG_ZBED_BESTAET = 2003 /* Zusatzinfos, die vor den Zahlungsbed. aufbereitet werden (nach Kundenbestätig.,leer=Text v.Code 2001) */
  public static CDTXT_BUCHUNG_VERS = 2004 /* Text für Verweis auf Versicherungsmöglichkeit, so fern unter Codeart 5000 / Code 410  URL hinterlegt */
  public static CDTXT_BUCHUNG_OHNE_PREIS = 2005 /* Hinweis bei Buchungen mit Preis auf Anfrage */
  public static CDTXT_BUCHUNG_BESTAET_FUSS = 2006 /* Fusstext, der nach Buchung ok  der Bestätigung angehängt wird (darf einfachen HTML-Code enthalten) */
  public static CDTXT_KEINE_ZINR_RES = 2007 /* Buchungshinweis, wenn keine Zimmernummernreservation mehr möglich ist */
  public static CDTXT_BUCHUNG_KK_OBLIG = 2008 /* Text während des Buchungsvorgangs, wenn die Reise zwingend mit Kreditkarte oder ähnlichem bezahlt werden muss. */
  public static CDTXT_NEWSLETTER_DOUBLE_OPT_IN = 2010 /* Hinweistext, wenn der Kunde die Newsletter-Anmeldung noch bestätigen muss (darf einfachen HTML-Code enthalten) */
  public static CDTXT_NEWSLETTER_AB = 2011 /* Bestätigung, dass Newsletter abbestellt wurde */
  public static CDTXT_NEWSLETTER_CHECKBOX = 2012 /* Beschriftung des Häkchenfeldes zur Newsletteranmeldung während der Buchung bzw. Werbematerialbestellung */
  public static CDTXT_NEWSLETTER_INFO = 2013 /* Zusatzhinweis, der, falls vorhanden, bei einer NL-Bestellung durch den Kunden im Internet separat bestätigt werden muss */
  public static CDTXT_NEWSLETTER_TRACKER = 2014 /* Text für Wahlmöglichkeit, ob der Kunde Tracker-Mechanismen beim Newsletter zustimmt oder nicht */
  public static CDTXT_INTERESSEN_TITEL = 2015 /* Titel der Interessenswahl-Seite (Adressmarketing) */
  public static CDTXT_INTERESSEN_TEXT = 2016 /* Erläuternder Text für die Interessenwahl-Seite (Adressmarketing) */
  public static CDTXT_KUNDENNR = 2020 /* Eingabefeld-Prompt für Kundennr. */
  public static CDTXT_ADR_ERFASSHINWEIS = 2021 /* Hinweis, der in der Adresserfassungsmaske bei Internetbuchungen erscheint (fakultativ) */
  public static CDTXT_ADRESSE = 2022 /* Text für die Überschrift bei der Adresserfassung */
  public static CDTXT_TEILNEHMER = 2023 /* Text für die Überschrift bei der Teilnehmererfassung (üblicherweise auf der Adressmaske) */
  public static CDTXT_ADR_BEREITS_KUNDE = 2024 /* Text für die Überschrift "Sie sind bereits Kunden bei ^MA", wobei ^MA=Platzhalter für Mandantenname */
  public static CDTXT_ADR_EMAIL_EINGEBEN = 2025 /* Text für die Überschrift "Bitte geben Sie Ihre E-Mailadresse ein und klicken Sie auf 'senden'" */
  public static CDTXT_ADR_NEUKUNDE = 2026 /* Text für die Überschrift "oder Sie sind Neukunde bzw. haben Ihre Kundennummer oder E-Mail nicht zur Hand" */
  public static CDTXT_ADR_MOBILTEL_HINWEIS = 2027 /* Hinweis in der Adresserfassungsmaske bei Internetbuchungen zum Grund für die Abfrage der Mobiltelefonnr. */
  public static CDTXT_ADR_GEBDAT_HINWEIS = 2028 /* Fak. Hinweistext in der Internet-Adresserfassungs- bzw. Teilnehmermaske, wenn ein Geburtsdatum abgefragt wird, wenn es nicht zwingend ist */
  public static CDTXT_AGB_HINWEIS = 2030 /* Text für allgemeine Vertragsbedingungen, Platzhalter ^VN für Veranstaltername, ^VO für Ort */
  public static CDTXT_AGB_DATENSCHUTZ_HAEKCHEN = 2031 /* Beschriftung Häkchen zur Einwilligung in die AGB + Datenschutzbestimmungen, HTML-Code erlaubt (^AGB=Link auf AGBs, ^DSB=Datenschutz-Link) */
  public static CDTXT_DATENSCHUTZ_HAEKCHEN = 2032 /* Beschriftung Häkchen zur Einwilligung Datenschutzbestimmungen (bei Prospektbestellung+Newsletter), HTML-Code erlaubt (^DSB=Datenschutz-Link) */
  public static CDTXT_AGB_HAEKCHEN_VERKAUF = 2033 /* Beschriftung Häkchen zur Einwilligung in die AGB- und Datenschutzbestimmungen bei Waren- und Gutscheinverkäufen (sonst analog Code 2031) */
  public static CDTXT_FB_DATENSCHUTZ_HAEKCHEN = 2034 /* Fakultative Beschriftung des Häkchens zur Einwilligung in die Datenschutzbestimmungen beim Fragebogen */
  public static CDTXT_PASSBESTIMMUNGEN = 2035 /* Hinweistext für nachfolgende Passbestimmungen, falls CDBEZ und CDBESCHR der Passbest. ausgefüllt. Platzhalter ^P=CDBEZ der Passbestimmungen */
  public static CDTXT_PASSNR_HINWEIS = 2036 /* Fakultativen Hinweistext zur Eingabe der Passnr. (z.B. welche Zahlen/Buchstaben üblicherweise nicht verwendet werden) */
  public static CDTXT_AGB_NICHT_BESTAETIGT = 2037 /* Fehlermeldung, wenn das AGB-Häkchen nicht angekreuzt wurde */
  public static CDTXT_AGB_NICHT_BESTAETIGT_VERKAUF = 2038 /* Fehlermeldung, wenn das AGB-Häkchen bei Waren- und Gutscheinverkäufen nicht angekreuzt wurde */
  public static CDTXT_STR_OHNE_DATUM = 2040 /* Text für Buchungsanfrageformular bei Standardreisen, die ohne aktuelle Reisedaten im Internet stehen */
  public static CDTXT_STR_AUSGEBUCHT = 2045 /* Text, wenn alle zukünftigen Daten einer Standardreise ausgebucht sind */
  public static CDTXT_STR_ABGELAUFEN = 2046 /* Text, wenn eine Reise nicht mehr buchbar ist, weil keine zukünftigen Daten vorhanden sind */
  public static CDTXT_STR_IN_PLANUNG = 2047 /* Text, wenn eine Reise nicht mehr buchbar ist, aber in Planung (Imbach-Spezial) */
  public static CDTXT_STR_PROMPT = 2051 /* Prompttext, der erscheint, wenn eine Standardreise ausgewählt werden kann */
  public static CDTXT_REISEPROG_ANZEIGEN = 2052 /* Beschriftung des Knopfs zur Anzeige des Reiseprogramms */
  public static CDTXT_MG_INFO = 2061 /* Generelle Mitgliedschaftsinfo */
  public static CDTXT_MG_ERSPARNIS = 2062 /* Text für Ersparnis bei gleichzeitiger Anmeldung als Mitglied */
  public static CDTXT_MG_ANMELDEN = 2063 /* Text für die Neuanmeldung als Mitglied */
  public static CDTXT_MG_ERNEUERN = 2064 /* Text für die Mitgliedschaftserneuerung */
  public static CDTXT_DOSSIERNR = 2070 /* Bezeichnung, die anstelle von "Dossiernr." bzw. "Dossiernummer" verwendet wird */
  public static CDTXT_DOSSIER_RGNR = 2071 /* Bezeichnung, die anstelle von "Dossier-/Rechnungsnr." verwendet wird (Anmeldemaske, z.B. für Onlinefragebogen) */
  public static CDTXT_LOGININFO_KD_DS_RG = 2072 /* Anmeldeinformation, dass hier Kunden-, Dossier- oder Rechnungsnummer nötig sind */
  public static CDTXT_LOGININFO_DS_TLN = 2073 /* Anmeldeinformationen, dass hier Dossier-/Rechnungsnr. sowie Teilnehmervor- und -nachnamen nötig sind */
  public static CDTXT_FRAGEBOGENLINK = 2080 /* Link auf Fragebogen-Erfassungsmaske (Platzhalter ^HP=Homepage des Veranstalters) */
  public static CDTXT_GUELTIGKEITSWARNUNG = 2081 /* Warnhinweis für Gültigkeit von Pass/ID von ausländischen Gästen */
  public static CDTXT_BUKLASSE_FREI_1 = 2091 /* Ergänzungstext für nur noch 1 Platz frei in Buch.klasse. Platzhalter ^PL für Anzahl (Default Cd.2092) */
  public static CDTXT_BUKLASSE_FREI_2 = 2092 /* Ergänzungstext für 2-max Freiplätze einer Buch.klasse. Platzhalter ^PL für Anzahl (Default kein Text) */
  public static CDTXT_BUKLASSE_FREI_MEHR = 2095 /* Ergänzungstext für > max. Freiplätze in Buch.kl. Platzhalter ^PL für max. Freiplätze (Def. Cd. 2092) */
  public static CDTXT_BUKLASSE_GUELTIG_BIS = 2098 /* Ergänzungstext, der vor dem Datum angezeigt wird, wenn ein Gültig bis-Datum exisitert (Default=Datum nicht anzeigen) */
  public static CDTXT_BEZAHLUNG_BAR_IM_CAR = 2100 /* Text auf der Buchungsbestätigung, wenn der Betrag bar im Car bezahlt werden muss. */
  public static CDTXT_BEZAHLUNG_KK_VORAUS = 2101 /* Text auf der Buchungsbestätigung, wenn der Betrag über die Kreditkarte abgebucht wurde. */
  public static CDTXT_BEZAHLUNG_RG_LW = 2102 /* Text auf der Buchungsbestätigung nach den Zahlungsbed. gem. Codeart 1034, wenn der Betrag in Landeswäh. auf ein Konto überwiesen werden muss */
  public static CDTXT_BEZAHLUNG_RG_FW = 2103 /* Text auf der Buchungsbestätigung nach den Zahlungsbed. gem. Codeart 1034, wenn der Betrag in Fremdwäh. auf ein Konto überwiesen werden muss */
  public static CDTXT_BEZAHLUNG_RG_VORAUS = 2104 /* Text für die Auswahl unter Zahlungsart, wenn der Betrag im Voraus per Rechnung bezahlt werden kann */
  public static CDTXT_BEZAHLUNG_NUR_KK_VORAUS = 2111 /* Wie Code 2101, Auswahl wird aber nur angezeigt, wenn keine andere Zahlungsart möglich ist (Default keine Auswahl, nur Hinweistext gem. 2000) */
  public static CDTXT_VERZICHT1_WIR = 2120 /* Text für "Wir verzichten" (vorgesehen für strikte Variante, d.h. bei ausdrücklichem Verzicht auf eine Zusatzleistung wie z.B. Versicherung) */
  public static CDTXT_VERZICHT1_ICH = 2121 /* Text für "Ich verzichte" (vorgesehen für strikte Variante) */
  public static CDTXT_VERZICHT1_MEHRERE = 2122 /* Text für "verzichten", wenn mehrere Namen davor aufgeführt werden (vorgesehen für strikte Variante) */
  public static CDTXT_VERZICHT1_EINER = 2123 /* Text für "verzichte", wenn nur ein Name davor aufgeführt wird (vorgesehen für strikte Variante) */
  public static CDTXT_VERZICHT2_WIR = 2125 /* Text für "Wir verzichten" (vorgesehen für normale Variante beim Verzicht auf eine Zusatzleistung wie z.B. die Klimakompensation) */
  public static CDTXT_VERZICHT2_ICH = 2126 /* Text für "Ich verzichte" (vorgesehen für normale Variante) */
  public static CDTXT_VERZICHT2_MEHRERE = 2127 /* Text für "verzichten", wenn mehrere Namen davor aufgeführt werden (vorgesehen für normale Variante) */
  public static CDTXT_VERZICHT2_EINER = 2128 /* Text für "verzichte", wenn nur ein Name davor aufgeführt wird (vorgesehen für normale Variante) */
  public static CDTXT_BUCHUNG_ABSCHLUSSTITEL = 2130 /* Abschlusstitel auf der letzten Buchungsseite */
  public static CDTXT_BUCHUNG_ABSCHLUSSHINWEIS = 2131 /* Abschlusshinweis auf der letzten Buchungsseite */
  public static CDTXT_BUCHUNG_ABSCHLUSSHINWEIS_AGB = 2132 /* Abschlusshinweis auf AGB bestätigen */
  public static CDTXT_HINW_BESTAET_EMAIL_STANDARD = 2133 /* Abschlusshinweis auf Buchungsbestätigung per E-Mail */
  public static CDTXT_HINW_BESTAET_EMAIL_VOLLAUTOM = 2134 /* Abschlusshinweis auf Buchungsbestätigung per E-Mail bei vollautomatischen Buchungen */
  public static CDTXT_HINW_BESTAET_EMAIL_ANFRAGE = 2136 /* Abschlusshinweis auf Anfragebestätigung per E-Mail */
  public static CDTXT_BUCHUNG_MITTEILUNG_AN_UNS = 2140 /* Überschrift Mitteilung an uns */
  public static CDTXT_BUCHUNG_MITTEILUNGSHINWEIS = 2141 /* Fakultativer Hinweis in der Buchungsmaske unterhalb "Ihre Mitteilung an uns" */
  public static CDTXT_BUCHUNG_MITTEILUNG_GUTSCHEIN = 2142 /* Überschrift Mitteilung an Gutscheinempfänger */
  public static CDTXT_TITEL_BESTAET_STANDARD = 2160 /* Titel einer normalen Bestätigung */
  public static CDTXT_TITEL_BESTAET_VOLLAUTOM = 2161 /* Titel einer Buchungsbestätigung / Rechnung bei vollautomatischen Buchungen */
  public static CDTXT_TITEL_BESTAET_BESTELLUNG = 2162 /* Titel für die Bestätigung einer Bestellung (z.B. Gutscheinen) */
  public static CDTXT_TITEL_BESTAET_REQUEST = 2165 /* Titel für die Bestätigung einer Buchungsanfrage (Request) */
  public static CDTXT_TITEL_BESTAET_PREISANFRAGE = 2166 /* Titel für die Bestätigung einer Preisanfrage */
  public static CDTXT_PLATZRES_OHNE_TICKET = 2180 /* Text, der auf der Bestätigung von Platzreservationen bei der Schifffahrt erscheint, wenn keine Tickets gebucht wurden (Default=kein Text) */
  public static CDTXT_UMFRAGE_EINLEITUNG = 2200 /* Einleitungssatz beim Umfrageformular */
  public static CDTXT_UMFRAGE_DANKE_GUT = 2205 /* Alternative Dankesseite bei positiver Gesamtbewertung */
  public static CDTXT_UMFRAGE_DANKE_STANDARD = 2206 /* Standardtext für die Dankesseite */
  public static CDTXT_KDPORTAL_TITEL = 2220 /* Willkommenstitel für das Kundenportal (z.B. Fragebogen) */
  public static CDTXT_ZLG_OK = 2260 /* Zahlung erfolgreich eingegangen und allfälliger Hinweis auf separate Bestätigung per E-Mail */
  public static CDTXT_ZLG_NOK = 2261 /* Zahlung nicht erfolgreich */
  public static CDTXT_ZLG_FUSS = 2269 /* Fusstext (Kontaktmöglichkeiten, freundliche Grüsse usw., {}-Platzhalter möglich) */
  public static CDTXT_WMAT_VERSANDHINWEIS = 2280 /* Text für Werbematerialbestellung */
  public static CDTXT_UNERWUENSCHTE_BEGRIFFE = 2500 /* Die CDBESCHR enthält eine Liste unerwünschter Wörter für z.B. Umfrageantworten */
  public static CDTXT_LEISTUNGSART_VON = 2800 /* Beginn des von der Leistungsart reservierten Codebereichs. Jeder Teilbereich umfasst 20 Codes, d.h. einen pro möglichen DSLEISTUNGSARTCODE. */
  public static CDTXT_LA_AGB_HINWEIS_VON = 2800 /* Beginn des Bereichs für Leistungsarten abhängige AGB-Hinweise (+DSLEISTUNGSARTCODE=eff. Code) */
  public static CDTXT_LA_AGB_HINWEIS_BIS = 2819 /* Ende des Bereichs für Leistungsarten abhängige AGB-Hinweise */
  public static CDTXT_LA_AGB_DATENSCHUTZ_HAEKCHEN_VON = 2820 /* Beginn des Bereichs für die Leistungsarten abhängige AGB-Häkchen-Beschriftung (+DSLEISTUNGSARTCODE=eff. Code) */
  public static CDTXT_LA_AGB_DATENSCHUTZ_HAEKCHEN_BIS = 2839 /* Ende des Bereichs für die Leistungsarten abhängige AGB-Häkchen-Beschriftung */
  public static CDTXT_LEISTUNGSART_BIS = 2999 /* Ende des von der Leistungsart reservierten Codebereichs */

  /*--------------------------------------------------------------------------*/

  public static TAB_MAHNTEXT = 5014 /* Mahntexte und Stufen (werden im Access verwendet) */

  /*--------------------------------------------------------------------------*/

  public static TAB_TO_BE_NAMED = 5015 /* Fiktive Namen für zukünftige Teilnehmer */

  /*--------------------------------------------------------------------------*/

  public static TAB_CODEART = 5016 /* Alle Codearten (Kurzbez.=Berechtigung + mandantenabhängigkeit) */

  /*--------------------------------------------------------------------------*/

  public static TAB_MLDCODE = 5017 /* Meldungscode */
  public static CDMLD_MIN_PRIORITAET = 1    /* erster Prioritätscode */
  public static CDMLD_MIN_ZINR = 11   /* erste Meldung, die Zimmernummern betrifft */
  public static CDMLD_ZINR_ANFRAGE = 11   /* Anfrage der Zimmernummer (Tournet -> Tourdata) */
  public static CDMLD_ZINR_OK = 12   /* Zimmernummer darf gebucht werden (TN -> TD) */
  public static CDMLD_ZINR_VARIANTE = 13   /* Zimmernummer nicht ok, Varianten werden hier vorgeschlagen (TD -> TN) */
  public static CDMLD_ZINR_NICHT_OK = 14   /* Zimmernummer kann  nicht verwendet werden (TD -> TN) */
  public static CDMLD_ZINR_GEBUCHT = 18   /* Zimmernummer wurde definitv abgebucht (TN -> TD) */
  public static CDMLD_ZINR_NICHT_GEBUCHT = 19   /* Zimmernummer wurde doch nicht gebucht (TN -> TD) */
  public static CDMLD_MAX_ZINR = 19   /* letzte Meldung, die Zimmernummern betrifft */
  public static CDMLD_DOKU_VERSAND = 20   /* Rechnung oder anderes Dokument vom Tourdata an ein Reisebüro senden (TD -> TN) */
  public static CDMLD_DOKU_ABHOLEN = 21   /* Rechnung oder anderes Dokument steht zur Abholung bereit (vom TD an Reisebüro mit Tourdata, TD -> TN) */
  public static CDMLD_EMAILVERSAND = 22   /* Versand eines automatischen E-Mails über den Dienst (TD -> TN) */
  public static CDMLD_SELL_REPORT = 25   /* Transport, für den die Änderungen gemeldet werden müssen (TD->TN) */
  public static CDMLD_AUTOMATION = 26   /* Generelle Automation via Automationstabelle (TD -> TN) */
  public static CDMLD_HOTELABRECHNUNG = 27   /* Hotelabrechnung (Einzelverarbeitung, wird z.Z. nur durch den Rahmenjob automatisch erstellt; TN -> TN) */
  public static CDMLD_LISTEN_EINZELVERARB = 28   /* Listeneinzelverarbeitung gem. Automation (wird durch diese automatisch erstellt; TN -> TN) */
  public static CDMLD_INTERNET_DATEN_NEU = 30   /* Alle Stammdaten (Produkte, Codes, Kurzbeschr.) eines Mandanten neu ins Internet übertragen (TD -> TN) */
  public static CDMLD_INTERNET_DATEN_LOESCHEN = 31   /* Alle Stammdaten (Produkte, Codes, Kurzbeschr.) eines Mandanten im Internet löschen (TN, man. Eintrag) */
  public static CDMLD_INTERNET_DATEN_OK = 38   /* Alle Stammdaten eines Mandanten erfolgreich im Tournet aktualisiert (TN -> TD) */
  public static CDMLD_INTERNET_DATEN_NOK = 39   /* Stammdatenaktualisierung im Internet nicht erfolgreich (TN -> TD) */
  public static CDMLD_TESTDB_NEU_LADEN = 40   /* Status des Jobs, der die Testdatenbank neu lädt */
  public static CDMLD_MAX_PRIORITAET = 49   /* letzte Prioritätsmeldung */
  public static CDMLD_MIN_CHAT = 80   /* Erster für Chats reservierter Code */
  public static CDMLD_CHAT_DISPOSITION = 81   /* Chat von/nach Dispositionsabteilung (Richtung gem. Subcode) */
  public static CDMLD_MAX_CHAT = 89   /* Letzter für Chats reservierter Code */
  public static CDMLD_MIN_MITTEILUNG = 90   /* erste rein textliche Mitteilung */
  public static CDMLD_MITTEILUNG_NORM = 95   /* Mitteilung normaler Priorität */
  public static CDMLD_MAX_MITTEILUNG = 99   /* letzte rein textliche Mitteilung */
  public static CDMLD_AGENDA = 100  /* Hauptagenda */
  public static CDMLD_MIN_AGENDA = 100  /* Erster für die Agenda reservierter Code */
  public static CDMLD_AGENDA_GASTRO = 110  /* Automatischer Agenda-Eintrag für die Gastroabteilung (nur 1 pro Dossier) */
  public static CDMLD_AGENDA_AUTOM = 120  /* Automatisch generierte Einträge aufgrund wichtiger Datenänderungen */
  public static CDMLD_AGENDA_LINK = 130  /* Verknüpfte Agendaeinträge, die automatisch erstellt/gelöscht werden, manuelle Zuordnungen+Datum mögl. */
  public static CDMLD_AGENDA_VERARB = 150  /* Agenda (automatische Verarbeitung) */
  public static CDMLD_MAX_AGENDA = 199  /* Letzter für die Agenda reservierter Code */
  public static CDMLD_ONLINE = 1000 /* Zur Zeit angemeldete Tournet- oder Reisemarkt-Benutzer (Tournet-DB) */
  public static CDMLD_TOURNET_EXE = 1001 /* Zur Zeit aktive Tournet.exe-Tasks pro Server (Anzahl in ZUSID gespeichert) */
  public static CDMLD_ZI_SUCHER_AKTIV = 1010 /* Zur Zeit angemeldete Zimmersucher (Tourdata-DB) */
  public static CDMLD_HUBSPOT = 2000 /* Nicht vollständig im Hubspot verarbeitete Adressen (werden regelmässig überprüft, bis Fehler behoben; danach wird die Meldung gelöscht) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZUSIDCODE = 5018 /* Art der zusätzlich mitgeteilten ID */
  public static CDZUSID_KEINE = 0    /* keine ID */
  public static CDZUSID_STR = 1    /* Produkt - Standardreise */
  public static CDZUSID_TRK = 3    /* Produkt - Transportkette */
  public static CDZUSID_TRS = 4    /* Produkt - Transportstrecke */
  public static CDZUSID_LL = 5    /* Produkt - Landleistung */
  public static CDZUSID_KAT = 6    /* Produkt - Kategorie */
  public static CDZUSID_ZINR = 106  /* Objekt - Zimmernummer */
  public static CDZUSID_RGLAUFNR = 200  /* Rechnungslaufnr wird in der ZUSID übergeben (ZUSMANDANT bleibt leer) */
  public static CDZUSID_BU = 230  /* Buchung */
  public static CDZUSID_DI = 250  /* Disposition */
  public static CDZUSID_PERSLAUFNR = 300  /* Personenlaufnr. und Mandant des zuständigen Sachbearbeiters */
  public static CDZUSID_UMFAID = 500  /* Umfrageantwort-Id */
  public static CDZUSID_ANZAHL = 1002 /* Anzahl (ZUSMANDANT bleibt leer) */

  /*--------------------------------------------------------------------------*/

  public static TAB_MLDSTATUS = 5019 /* Meldestatus */
  public static CDMLDS_OK = 0    /* Meldung vollständig und nicht in Bearbeitung */
  public static CDMLDS_IN_ARBEIT = 1    /* Meldung wird gerade bearbeitet */
  public static CDMLDS_MIN_PENDENT = 10   /* Erster pendenter Status */
  public static CDMLDS_MIN_NEU = 10   /* Erster Status für neue, ungelesene Meldungen */
  public static CDMLDS_NEU = 10   /* Agenda+Chat: Neue, ungelesener Eintrag */
  public static CDMLDS_MAX_NEU = 19   /* Letzter Status für neue, ungelesene Meldungen */
  public static CDMLDS_GELESEN = 20   /* Agenda: Eintrag gelesen, aber noch bearbeitet */
  public static CDMLDS_PENDENT = 30   /* Agenda: Bearbeitung anstehend */
  public static CDMLDS_ZURUECKGESTELLT = 40   /* Agenda: Bearbeitung wg. z.B. externen Einflüssen z.Z. nicht möglich */
  public static CDMLDS_MAX_PENDENT = 69   /* Agenda: Letzter pendenter Status */
  public static CDMLDS_MIN_ABGESCHLOSSEN = 70   /* Agenda: Erster abgeschlossener Status */
  public static CDMLDS_WEITER_GELEITET = 70   /* Agenda: An andere Person weitergeleitet (neuer Subeintrag erstellt) */
  public static CDMLDS_GEAENDERT = 71   /* Agenda: Eintrag so stark verändert, dass ein neuer Subeintrag erstellt werden musste */
  public static CDMLDS_GELESEN_OK = 72   /* Chat: Eintrag gelesen, Antwort nicht zwingend */
  public static CDMLDS_ERLEDIGT = 80   /* Agenda,Chat+Job: Aufgabe erledigt */
  public static CDMLDS_KEINE_AKTION = 81   /* Agenda: Bearbeitung in diesem Fall nicht möglich */
  public static CDMLDS_ABBRUCH = 85   /* Job: Aufgabe abgebrochen */
  public static CDMLDS_GELOESCHT = 90   /* Agenda: Eintrag als gelöscht markiert */
  public static CDMLDS_MAX_ABGESCHLOSSEN = 99   /* Agenda: Letzter abgeschlossener Status */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTOM_SKRIPT = 5020 /* Automatische SQL-Skripte (werden z.Z. nur im Tournet zur Konsistenzprüfung verwendet) */

  /*--------------------------------------------------------------------------*/

  public static TAB_WMATTEXT = 5021 /* Texte für Werbematerialbriefe (werden im Access verwendet) */

  /*--------------------------------------------------------------------------*/

  public static TAB_CDGELOESCHT = 5022 /* Code-Status (z.Z. mit CDRGELOESCHT identisch) */
  public static CDCDG_AKTIV = 0    /* Code ist aktiv und generell verwendbar */
  public static CDCDG_INTERN = 2    /* Code ist aktiv, aber nur intern buchbar (also nicht über Tournet und Reisemarkt) */
  public static CDCDG_EXKLUSIV = 3    /* Code ist aktiv, aber nur für speziell berechtigte Benutzer */
  public static CDCDG_GESPERRT = 5    /* Code ist aktiv, Einträge können aber weder neu angelegt, noch geändert oder gelöscht werde */
  public static CDCDG_GELOESCHT = 9    /* Code kann für neue Einträge nicht mehr verwendet werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_CDRGELOESCHT = 5022 /* Codereferenzstatus, z.Z. mit CDGELOESCHT identisch */
  public static CDCDRG_AKTIV = 0    /* Code ist aktiv und generell verwendbar */
  public static CDCDRG_EXKLUSIV = 3    /* Code ist aktiv, aber nur für speziell berechtigte Benutzer */
  public static CDCDRG_ADMIN = 4    /* Code ist aktiv, aber nur für Admin-Benutzer */
  public static CDCDRG_GESPERRT = 5    /* Code ist aktiv, Einträge können aber weder neu angelegt, noch geändert oder gelöscht werde */
  public static CDCDRG_GELOESCHT = 9    /* Code kann für neue Einträge nicht mehr verwendet werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_BONUSTEXT = 5023 /* Text für Bonusprogramm (werden im Access verwendet) */

  /*--------------------------------------------------------------------------*/

  public static TAB_BEZCODE = 5024 /* Art der Bezeichnung */
  public static CDBEZ_PAMANDANT_VON = 1    /* Erster Code, dessen Mandant gem. PAMANDANT bestimmt wird */
  public static CDBEZ_MITGLIED = 1    /* Bezeichnung der Mitgliedschaftsart */
  public static CDBEZ_PAMANDANT_BIS = 99   /* Letzter Code, dessen Mandant gem. PAMANDANT bestimmt wird */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZKROBLIG = 5025 /* Art des Obligatoriums eines Zusatzkriteriums */
  public static CDZKROBL_FAK = 0    /* fakultativ */
  public static CDZKROBL_OBLIG = 1    /* obligatorisch */
  public static CDZKROBL_EXKL = 3    /* Auswahl exklusiv für spezielle Mandanten (Rest nur anschauen) */
  public static CDZKROBL_GELOESCHT = 9    /* Zusatzkriterium ist zwar vorhanden, aber am Bildschirm nicht sichtbar (evt. technisch benutzt) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZKRBEZ = 5026 /* Wenn vorhanden, Übersetzung bzw. Anschrift der Zusatzkriterien auf offiziellen Papieren (Code=ZKRID) */

  /*--------------------------------------------------------------------------*/

  public static TAB_FILE2 = 5027 /* Einzelne Codes der Codeart 5000 können hier abhängig vom Login-Mandanten indiv. übersteuert werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_MLDSUBCODE = 5028 /* Meldungs-Unterart (Codes 1000-5999 reserviert für E-Mail-Templates gem. Codeart 5038) */
  public static CDMLDSUB_INDIVIDUELL = 0    /* individueller Text gem. MLDBETREFF (nur bei Agenda) */
  public static CDMLDSUB_MIN_ZAEHLER = 1    /* Erster für Vorgangszähler und Ähnliches reservierter Bereich (z.B. Erstversand, erste Erinnerung, zweite Erinnerung) */
  public static CDMLDSUB_ORIGINAL = 1    /* Erste Aufbereitung, z.B. Originalversand */
  public static CDMLDSUB_ERINNERUNG1 = 2    /* Zweite Aufbereitung, z.B. Erinnerung */
  public static CDMLDSUB_ERINNERUNG2 = 3    /* Dritte Aufbereitung, z.B. 2. Erinnerung */
  public static CDMLDSUB_MAX_ZAEHLER = 49   /* Letzter für Vorgangszähler reservierter Bereich */
  public static CDMLDSUB_MIN_POOLING = 50   /* Erster fürs Pooling reservierter Code (der Bereich stimmt mit PRPREPORTCODE überein) */
  public static CDMLDSUB_EUROWINGS_POOLING = 50   /* Eurowings-Pooling */
  public static CDMLDSUB_CONDOR_POOLING = 51   /* Condor-Pooling */
  public static CDMLDSUB_MAX_POOLING = 59   /* Letzter fürs Pooling reservierter Code */
  public static CDMLDSUB_BASIS_FARBE = 80   /* Basis für Farben: MLDSUBCODE = CDMLDSUB_BASIS_FARBE + Farbnr. (1-5) */
  public static CDMLDSUB_FARBE1 = 81   /* Spezialeintrag in alternativer Farbe (dunkelgrün) */
  public static CDMLDSUB_MIN_FARBE = 81   /* Erster für spezielle Farbmarkierungen genutzter Code */
  public static CDMLDSUB_MAX_FARBE = 81   /* Letzter für spezielle Farbmarkierungen aktuell genutzer Code (verschieben bis Code 85 möglich) */
  public static CDMLDSUB_TD_APP = 101  /* (Chat-)Meldung vom Tourdata zur App */
  public static CDMLDSUB_APP_TD = 102  /* (Chat-)Meldung von der App zum Tourdata */
  public static CDMLDSUB_DOKU_SUPERUSER = 320  /* Dokumenten-Versandzuteilung durch Doku-Superuser ausstehend */
  public static CDMLDSUB_DOKU_VERSAND = 321  /* Dokumentenversand ausstehend */
  public static CDMLDSUB_TICKET_VERSAND = 322  /* Ticket-Versand ausstehend */
  public static CDMLDSUB_DS_NICHT_IN_APP = 323  /* Dossier noch nicht an App geschickt */
  public static CDMLDSUB_UMFRAGE_NICHT_GEPRUEFT = 500  /* Nicht geprüft Anftwort auf Umfrage */
  public static CDMLDSUB_EMAIL_TEMPLATES_VON = 1000 /* Erster für E-Mailtemplates gem. Codeart 5038 reservierter Code */
  public static CDMLDSUB_EMAIL_TEMPLATES_BIS = 5999 /* Letzter für E-Mailtemplates reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_TXBSTATUS = 5029 /* Textbaustein-Status */
  public static CDTXBS_AKTIV = 0    /* Textbaustein ist aktiv */
  public static CDTXBS_GESPERRT = 5    /* Textbaustein kann nicht mehr neu zugeordnet werden */
  public static CDTXBS_GELOESCHT = 9    /* Textbaustein wird weder angezeigt noch aufbereitet+kann bei der nächsten Bereinigung gelöscht werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_MNCODE = 5030 /* Art des Menus */
  public static CDMN_APP_INFO = 4200 /* Info-Menu für die App */
  public static CDMN_CHAUFFEUR_VON = 4210 /* Erster für die Chauffeur-/Reiseleiter-App reservierter Menupunkt */
  public static CDMN_CHAUFFEUR_INFO = 4210 /* Info-Menu für die Chauffeur-/Reiseleiter-App */
  public static CDMN_CHAUFFEUR_NEWS = 4211 /* News-Menu für die Chauffeur-/Reiseleiter-App */
  public static CDMN_CHAUFFEUR_HILFE = 4212 /* Hilfe-Menu für die Chauffeur-/Reiseleiter-App */
  public static CDMN_CHAUFFEUR_AUFTRAG = 4215 /* Menupunkte, die bei jedem Auftrag angezeigt werden */
  public static CDMN_CHAUFFEUR_BIS = 4219 /* Letzter für die Chauffeur-/Reiseleiter-App reservierter Menupunkt */

  /*--------------------------------------------------------------------------*/

  public static TAB_MPCODE = 5031 /* Art eines Menupunkts */
  public static CDMP_ADR_VON = 1    /* Erster für Adressen reservierter Code */
  public static CDMP_ADR = 1    /* Adresse */
  public static CDMP_ADR_TO = 2    /* Adresse eines Veranstalters */
  public static CDMP_ADR_KUNDE = 3    /* Kundenadresse */
  public static CDMP_ADR_BIS = 19   /* Letzter für Adressen reservierter Code */
  public static CDMP_HALTEORT = 20   /* Halteort */
  public static CDMP_DATEI_VON = 40   /* Erster für Menupunkte mit Dateianhängen reservierter Code */
  public static CDMP_PDF = 40   /* PDF-Datei */
  public static CDMP_WEBSEITE = 45   /* Webseite, die als Datei hochgeladen wird (darf keine externen Links beinhalten, die zur Darstellung benötigt werden) */
  public static CDMP_DATEI_BIS = 49   /* Letzter für Menupunkte mit Dateianhänge reservierter Code */
  public static CDMP_GAESTEBUCH = 60   /* Link zum Gästebuch */
  public static CDMP_CHAUFFEURE = 70   /* Liste aller Chauffeure des Mandanten; in der Kunden-App z.Z. ohne Funktion */
  public static CDMP_URL = 80   /* Beliebige Internet-Adresse */
  public static CDMP_MENU_VON = 100  /* Erster für Menueinträge reservierter Code */
  public static CDMP_MENU_LISTE = 100  /* Menu mit Listeneinträgen */
  public static CDMP_MENU_ICONS = 101  /* Menu mit Einträgen als Icons */
  public static CDMP_MENU_BIS = 119  /* Letzter für Menus reservierter Code */
  public static CDMP_TITEL = 120  /* Zwischentitel ohne Funktion */

  /*--------------------------------------------------------------------------*/

  public static TAB_MPDARSTELLUNGSCODE = 5032 /* Darstellungsvariante des Menupunkts */
  public static CDMPD_STANDARD = 0    /* Standardvariante */
  public static CDMPD_KLEINER = 1    /* Kleinere, nicht fette Schrift */

  /*--------------------------------------------------------------------------*/

  public static TAB_FEIERTAGE = 5033 /* Formeln zur Berechnung verschiedener spezieller Kalenderdaten wie z.B. Feiertragen */

  /*--------------------------------------------------------------------------*/

  public static TAB_WAEHK_GRUPPENCODE = 5034 /* Währungs-Kursgruppe */
  public static CDWAEHK_GENERELL = 0    /* Generelle Kursgruppe, die zum Tragen kommt, wenn keine individuelle Gruppe nötig ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZKRMEHRFACHCODE = 5035 /* Gibt an, ob und wie ein Zusatzkriterium mehfach erfasst werden kann */
  public static CDZKRMF_KEINE = 0    /* NULL=Keine Mehrfacherfassung möglich */
  public static CDZKRMF_MOEGLICH = 1    /* Mehrfacherfassung ist möglich */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZPSTATUS = 5036 /* Zustand des Zeitpunkteintrags ( gen.Stati 0-19, sowie pro ZPCODE 20 Stati ab ZPCODE*CDZPS_ANZ_STATUS) */
  public static CDZPS_MIN_ALLGEMEIN = 0    /* Erster für allgemeine Verwendung reservierter Status */
  public static CDZPS_NICHT_DEFINIERT = 0    /* NULL=kein Status nötig */
  public static CDZPS_MAX_ALLGEMEIN = 19   /* Letzter für allgemeine Verwendung reservierter Status */
  public static CDZPS_ANZ_STATUS = 20   /* Pro ZPCODE reservierte Anzahl Stati  */

  /*--------------------------------------------------------------------------*/

  public static TAB_LANDLISTE = 5037 /* Liste der Ländercodes gem. ISO-3661-1 Alpha2 (gespeichert in Codeart 16) für bestimmte Funktionen (s.a. Codeart 27 für generell gültige) */
  public static CDLAND_KDADR_INTERNET = 1    /* Länder, die im Internet bei der Kundenadresse erlaubt sind */
  public static CDLAND_REISE_SORT = 200  /* Länder, die als erweiterte Zus.krit. vor den alphabetisch sortierten eingefügt werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_EMAILS = 5038 /* Mögliche automatische Mails (Codes 1000-5999 erlaubt, Teil von Codeart 5028; Codes 3200-3399 von Codeart 3037 abgeleitet, ref in 2946) */
  public static CDEMAIL_NL_OPT_IN = 1000 /* Mail zur Bestätigung einer automatisch gesetzten Newsletter-Anmeldung (Opt-In) */
  public static CDEMAIL_NL_DOUBLE_OPT_IN = 1001 /* Mail zur Bestätigung der Newsletter-Anmeldung (Double-Opt-In) */
  public static CDEMAIL_NL_ANMELDUNG = 1005 /* Bestätigungsmail nach der Newsletter-Anmeldung */
  public static CDEMAIL_NL_BEREITS_ANGEMELDET = 1006 /* Bestätigungsmail nach der Newsletter-Anmeldung, sofern der Kunde bereits davor angemeldet war (Default gem. Code 1005) */
  public static CDEMAIL_NL_ABMELDUNG = 1009 /* Bestätigungsmail nach der Newsletter-Abmeldung */
  public static CDEMAIL_LOGIN_VERGESSEN = 1020 /* Mail, das verschickt wird, wenn der Kunde seine Anmeldedaten nicht mehr hat (je nach Grundeinstellung Kundennr. oder Passwort) */
  public static CDEMAIL_PW_LOGIN_AKTIVIEREN = 1021 /* Mail, das verschickt wird, wenn der Kunde von der Anmeldung von Kunden-/Dossiernr. auf E-Mailadresse/Passwort umstellen möchte */
  public static CDEMAIL_HOT_ABRECHNUNG = 2500 /* Hotelabrechnungs-Avis */
  public static CDEMAIL_HOT_ABRECHNUNG2 = 2501 /* Hotelabrechnungs-Erinnerungsavis */
  public static CDEMAIL_HOT_RECHNUNG = 2505 /* Hotel-Rechnungsavis für Direktgutschrift */
  public static CDEMAIL_HOT_AUSWERT_AN_ANBIETER = 2510 /* Diverse Hotelauswertungen an Anbieter oder Agenten */
  public static CDEMAIL_BU_BESTAETIGUNG_RQ = 3005 /* Bestätigung nach Request-Buchung */
  public static CDEMAIL_BU_BESTAETIGUNG = 3006 /* Buchungsbestätigung */
  public static CDEMAIL_BU_BESTAETIGUNG_DOKU = 3007 /* Buchungsbestätigung nach vollautomatischer Buchung (Hinweis auf Vollautomatismus/kein Dokuversand per Post) */
  public static CDEMAIL_BU_GUTSCHEINVERKAUF = 3010 /* Buchungsbestätigung für Gutscheinverkäufe */
  public static CDEMAIL_BU_ABRECHNUNG = 3015 /* (Provisions-/Buchungsstellen-)Abrechnung anstelle Buchungsbestätigung */
  public static CDEMAIL_BU_FRAGEBOGEN = 3020 /* Fragebogen nach Buchung */
  public static CDEMAIL_FRBO_BESTAETIGUNG = 3025 /* Bestätigung nach ausgefülltem Fragebogen */
  public static CDEMAIL_UPSELLING_VON = 3030 /* Beginn der fürs Upselling reservierter Templates */
  public static CDEMAIL_UPSELLING = 3030 /* Hinweis auf Upselling-Möglichkeit */
  public static CDEMAIL_UPSELLING1 = 3031 /* 1. Upselling-Erinnerung */
  public static CDEMAIL_UPSELLING2 = 3032 /* 2. Upselling-Erinnerung */
  public static CDEMAIL_UPSELLING_BIS = 3034 /* Ende der fürs Upselling reservierter Templates */
  public static CDEMAIL_ZLG_BESTAETIGUNG = 3050 /* Zahlungseingangsbestätigung */
  public static CDEMAIL_ZLG_AUFFORDERUNG = 3051 /* Zahlungsaufforderung */
  public static CDEMAIL_LLTICKET = 3060 /* Versand der Landleistungstickets kurz vor Abreise */
  public static CDEMAIL_DSPA_ERINNERUNGSCODE_BASIS = 3200 /* Basis für Dossier-Erinnerungscodes (muss zum jeweiligen Code der Codeart 3037 dazuaddiert werden), reserviert bis DSPA_ERINNERUNGSCODE_BIS */
  public static CDEMAIL_FRAGEBOGEN1 = 3221 /* Erste Fragebogen-Erinnerung */
  public static CDEMAIL_FRAGEBOGEN2 = 3222 /* Zweite Fragebogen-Erinnerung */
  public static CDEMAIL_APPINFO = 3251 /* Erinnerung zum Download der App */
  public static CDEMAIL_BEWERTUNG1 = 3281 /* Kundenbewertung */
  public static CDEMAIL_BEWERTUNG2 = 3282 /* Erinnerung Kundenbewertung */
  public static CDEMAIL_DSPA_ERINNERUNGSCODE_BIS = 3399 /* Letzter für die Dossier-Erinnerungscodes reservierte Mails */
  public static CDEMAIL_TOURDATA = 5000 /* Mails aus dem dem Tourdata heraus */

  /*--------------------------------------------------------------------------*/

  public static TAB_EMAILTEMPLATES = 5039 /* Templates zu den Emails gem. Codeart 5038 (Ausnahme Texte zu den Dossiererinnerungsmails gem. Codeart 3037) */

  /*--------------------------------------------------------------------------*/

  public static TAB_EMAILFELDER = 5040 /* Platzhalter, die in E-Mail-Templates verwendet werden können (Feldnamen wo möglich mit den Textverarbeitungsfeldern identisch, inkl. Codes) */

  /*--------------------------------------------------------------------------*/

  public static TAB_AGENDAVORSCHLAGSTEXTE = 5041 /* Vorschlagstexte für manuelle Agendaeinträge */

  /*--------------------------------------------------------------------------*/

  public static TAB_BILDER = 5042 /* Erlaubte Bildgrössen (Breite x Höhe in Pixeln) */
  public static CDBILD_ORIGINAL = 0    /* Originalbild (kein Codeeintrag, da keine Grösseneinschränkungen) */
  public static CDBILD_STD_VON = 1    /* Erste Standardbildgrösse (bei Produkten fürs Internet vorgesehen) */
  public static CDBILD_VORSCHAU = 1    /* Vorschaubild (früher Codeart 5009, Code 620) */
  public static CDBILD_KLEIN = 2    /* kleines Bild */
  public static CDBILD_MITTEL = 4    /* mittelgrosses Bild */
  public static CDBILD_GROSS = 6    /* grosses Bild */
  public static CDBILD_STD_BIS = 9    /* Letzte Standardbildgrösse (bei Produkten fürs Internet vorgesehen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZKRCODECODE = 5043 /* Bedeutung des Zusatzkriteriums für Text- und Zahlenfelder. Nur NEGATIVE Werte verwenden, weil bei Codes direkt die Codeart eingetragen wird! */
  public static CDZKRCC_TEXT_VON = -1999/* Erster für Textfelder (PRTYPCODE 1) reservierter Bereich */
  public static CDZKRCC_PRODUKT_VON = -1299/* Erster für Produkt abhängige Informationen reservierter Bereich */
  public static CDZKRCC_TEXT_DEST = -1220/* Das Textfeld wird für ein Destinationskürzel verwendet */
  public static CDZKRCC_TEXT_STR = -1201/* Das Textfeld wird für ein Kürzel der Standardreise verwendet */
  public static CDZKRCC_PRODUKT_BIS = -1200/* Letzter für Produkt abhängige Informationen reservierter Bereich */
  public static CDZKRCC_TEXT_BIS = -1000/* Letzter für Textfelder reservierter Bereich */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTGCODE = 5044 /* Art der Automationsgruppe: Gibt an, für welche Zwecke die Gruppe verwendet werden kann (Codes 20-69 sind abgestimmt auf PRCODEs) */
  public static CDAUTG_ALLGEMEIN = 0    /* Generell verwendbar */
  public static CDAUTG_KUNDE = 11   /* Kann nur Kunden zugeordnet werden */
  public static CDAUTG_ANBIETER = 12   /* Kann nur Anbietern zugeordnet werden */
  public static CDAUTG_PRODUKT_VON = 20   /* Beginn des Für Produktcodes reservierten Bereichs (AUTGCODE=CDAUTG_PRODUKT_VON+PRCODE) */
  public static CDAUTG_STR = 21   /* Kann nur Standardreisen zugeordnet werden */
  public static CDAUTG_TRK = 23   /* Kann nur Transportketten zugeordnet werden */
  public static CDAUTG_LL = 25   /* Kann nur Landleistungen zugeordnet werden */
  public static CDAUTG_GS = 30   /* Kann nur Gutscheinprodukten zugeordnet werden */
  public static CDAUTG_PRODUKT_BIS = 69   /* Ende des für Produktcodes reservierten Bereichs */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTGSTATUS = 5045 /* Gibt den Zustand der Automationsgruppe an */
  public static CDAUTGS_AKTIV = 0    /* aktiv */
  public static CDAUTGS_NUR_MELDUNG = 4    /* Allfällig notwenige Sofortübermittlungen werden zwar festgehalten (Meldetabelleneintrag), aber z.Z. nicht ausgeführt */
  public static CDAUTGS_MIN_NICHT_AUSFUEHREN = 5    /* Ab diesem Code dürfen enthaltene Automationen weder durchgeführt noch via Meldetabelle angestossen werden */
  public static CDAUTGS_GESPERRT = 5    /* gesperrt (weder Meldeeintrag noch Ausführung erlaubt) */
  public static CDAUTGS_GELOESCHT = 9    /* gelöscht */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTCODE = 5046 /* Art der Automation */
  public static CDAUT_KEINE = 0    /* nur Parametervorlage für Folgeautomationen */
  public static CDAUT_AUSWERTUNGSVORLAGE = 1    /* Auswertungsvorlage gem. AVID und AVMANDANT */
  public static CDAUT_SQL = 20   /* Lesebefehl gem. AUTBEFEHL */
  public static CDAUT_EXPORT_VORDEF = 30   /* Vordefiniertes, strukturiertes Exportformat (in der Regel JSON) gem. AUTSUBCODE 3000-3999 und Bedingungen gem. AUTBEFEHL */
  public static CDAUT_TN_STANDARD = 80   /* Tournet-Standardablauf gem. AUTSUBCODE 8000-8999 (wo vorhanden, gem. früherem ZPCODE +8000) */
  public static CDAUT_SPEZIAL = 90   /* Spezialanfertigung (individuell für Kunden fest programmierter Ablauf gem. AUTBEFEHL) */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTSOFORTCODE = 5047 /* Gibt an, wie die Automation ausgelöst werden kann */
  public static CDAUTSOF_MANUELL = 0    /* Auslösung nur manuell */
  public static CDAUTSOF_NORMAL = 1    /* Normalausführung (gem. Intervall oder Vorgänger) */
  public static CDAUTSOF_SOFORT = 4    /* Sofortausführung gem. Meldetabelle */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTSOFORTDATENCODE = 5048 /* Parameter, die bei einer Sofortausführung der Meldetabelle übergeben werden müssen (Bitmap) */
  public static CDAUTSD_KEINE = 0    /* NULL=keine Sofortausführung oder keine individuellen Daten notwendig */
  public static CDAUTSD_DATUM = 1    /* Beginndatum der Leistung */
  public static CDAUTSD_ELEMENT = 2    /* Identifikation des auslösenden Elements (z.B. Transportkette) */
  public static CDAUTSD_ANBIETER = 4    /* Identifikation des Anbieters (PAID, PAMANDANT) */
  public static CDAUTSD_UEBERGEORDNET = 64   /* Die Codes 1-32 beziehen sich nicht auf das Element selbst, sondern auf dessen übergeordnetem Element (z.B. Standardreise) */
  public static CDAUTSD_DOSSIER = 128  /* Identifikation des Dossiers (DSID, DSMANDANT) */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTAKTUALISIERUNGSCODE = 5049 /* Gibt an, ob und wie die Daten dieser Automation in der verbundenen Tournetdatenbank aktualisiert werden müssen */
  public static CDAUTAKT_NICHT = 0    /* nicht vorhanden, aber auch nicht nötig */
  public static CDAUTAKT_AKTUELL = 1    /* Automation ist im Tournet vorhanden und auf dem aktuellen Stand */
  public static CDAUTAKT_BITMAP_START = 1    /* Wert, der zu CDAUTAKT_AKTUALISIEREN addiert werden kann, damit der nächste Start auch aktualisiert wird */
  public static CDAUTAKT_BITMAP_NR = 2    /* Wert, der zu CDAUTAKT_AKTUALISIEREN addiert werden kann, damit die nächste Laufnr. auch aktualisiert wird */
  public static CDAUTAKT_NEU = 32   /* Automation neu anlegen */
  public static CDAUTAKT_MIN_AKTUALSIERUNG = 64   /* Erster für Automationen, die im Tournet aktualisiert werden müssen, reservierter Bereich */
  public static CDAUTAKT_AKTUALISIEREN = 64   /* Automation aktualisieren (alle Daten ausser den Feldern zum Ausführungsstatus, dem nächsten Start und der nächsten Nr.) */
  public static CDAUTAKT_AKT_START = 65   /* Automation aktualisieren inkl. nächster Start */
  public static CDAUTAKT_AKT_NR = 66   /* Automation aktualisieren inkl. nächste Nr. */
  public static CDAUTAKT_AKT_START_NR = 67   /* Automation aktualisieren inkl. nächster Start und nächste Nr. */
  public static CDAUTAKT_MAX_AKTUALISIERUNG = 95   /* Letzter für Automationen, die im Tournet aktualisert werden müssen, reservierter Bereich */
  public static CDAUTAKT_LOESCHEN = 96   /* Automation löschen */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTSTATUS = 5050 /* Zustand der Automation */
  public static CDAUTS_BEREIT = 1    /* Automation kann verwendet werden */
  public static CDAUTS_AKTIV = 2    /* Automation ist aktuell am Laufen */
  public static CDAUTS_MIN_NICHT_AUSFUEHREN = 4    /* Erster für Automationen, die nicht ausgeführt werden dürfen, reservierter Bereich */
  public static CDAUTS_AUTOM_GESPERRT = 4    /* Automation wurde automatisch gesperrt */
  public static CDAUTS_GESPERRT = 5    /* Automation ist gesperrt und darf nicht ausgeführt werden */
  public static CDAUTS_GELOESCHT = 9    /* Automation ist gelöscht und darf nicht ausgeführt werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTDAT_ANPASSENCODE = 5051 /* Steuert, wie das errechnete Datum bei einem nicht passenden Wochentag angepasst werden soll */
  public static CDAUTDANP_DAVOR = 10   /* Möglichst nah davor liegender passender Wochentag */
  public static CDAUTDANP_DANACH = 20   /* Möglichst nah danach liegender passender Wochentag */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTDATEITYPCODE = 5052 /* Format der zu erstellenden Datei (Codes entsprechen dem List & Label) */
  public static CDAUTDT_WORD = 2    /* Worddatei */
  public static CDAUTDT_EXCEL = 3    /* Exceltabelle */
  public static CDAUTDT_PDF = 5    /* PDF-Datei */
  public static CDAUTDT_CSV = 7    /* CSV mit den Spaltennamen in der Kopfzeile (nur bei entsprechend vorbereiteten Auswertungen möglich) */
  public static CDAUTDT_CSV_OHNE_KOPFZEILE = 8    /* CSV ohne Kopfzeile, d.h. ohne Spaltennamen (nur bei entsprechend vorbereiteten Auswertungen möglich) */
  public static CDAUTDT_JSON = 20   /* JSON-Datei */
  public static CDAUTDT_XML = 21   /* XML-Datei */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTEMAIL_TEXTCODE = 5053 /* E-Mailtexte für die Automation */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTFORTSETZUNGSCODE = 5054 /* Art der direkt anschliessend zu verarbeitenden Folgeautomation */
  public static CDAUTF_KEINE = 0    /* NULL=Keine Folgeautomation vorhanden */
  public static CDAUTF_EIGENSTAENDIG = 1    /* Die Folgeautomation ist eigenständig, d.h. kann bei einem Unterbruch auch unabhängig von der vorliegenden Automation gestartet werden */
  public static CDAUTF_MIN_GEMEINSAMER_STATUS = 11   /* Erster für verbundene Automationen reservierter Code (es kann nur alles oder nichts ok gesetzt werden) */
  public static CDAUTF_VERBUNDEN = 11   /* Die Verarbeitung gilt nur als ok, wenn auch die Fortsetzung ok ist */
  public static CDAUTF_VERBUNDEN_GLEICHE_LAUFNR = 12   /* wie 11, zusätzlich wird die Laufnr. bzw. der Zeitstempel der vorliegenden Automation übernommen (weder Datum noch neue Nummer zugeteilt) */
  public static CDAUTF_VERBUNDEN_GLEICHE_AUSWERTUNG = 13   /* wie 12, es wird die gleiche Auswertung nur in einem anderen Format aufbereitet */
  public static CDAUTF_VERBUNDEN_GLEICHE_DATEI = 14   /* wie 12, die bereits aufbereitete Datei muss jedoch noch an einen anderen Empfänger zugestellt */

  /*--------------------------------------------------------------------------*/

  public static TAB_LOGTHEMENCODE = 5055 /* Thema des Logeintrags */
  public static CDLOGT_AUSWERTUNG = 1    /* Auswertung */

  /*--------------------------------------------------------------------------*/

  public static TAB_LOGPROGCODE = 5056 /* Programm, das bzw. Programmteil, der den Logeintrag ausgelöst hat */
  public static CDLOGP_TD = 100  /* Tourdata */
  public static CDLOGP_LIST_LABEL = 110  /* List & Label */
  public static CDLOGP_ACCESS = 120  /* Access */
  public static CDLOGP_DIVERSE_INTERN = 190  /* Übrige interne Anwendungen */
  public static CDLOGP_TOURNET = 200  /* Tournet */
  public static CDLOGP_HOMEPAGE = 201  /* Homepage */
  public static CDLOGP_KUNDEN_APP = 300  /* Kunden-App */
  public static CDLOGP_CHAUFFEUR_APP = 301  /* Chauffeur-/Reiseleiter-App */
  public static CDLOGP_DIENST = 500  /* Dienst */
  public static CDLOGP_DIVERSE_EXTERN = 990  /* Diverse externe Anwendungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_LOGPRIOCODE = 5057 /* Priorität des Logeintrags */
  public static CDLOGPRIO_MITTEL = 4    /* Mittlere Priorität (z.B. Adressexport mit höchstens 200 Datensätzen) */
  public static CDLOGPRIO_HOEHER = 6    /* Höhere Priorität (z.B. Adressexport mit mehr als 200 Datensätzen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ZUSATZKRITERIEN = 5058 /* Auswahl aller möglicher Zusatzkriterien-Arten (jeweils erster Wert pro Art gespeichert) */
  public static CDZKR_JA_NEIN = 10   /* Codes für die Ja-/Nein-Felder beginnen um diesen Wert später als die "normalen" Felder der gleichen Art */
  public static CDZKR_KUNDE = 1221 /* Beginn der Kunden-Zusatzkriterien */
  public static CDZKR_ANBIETER = 1241 /* Beginn der Anbieter-Zusatzkriterien */
  public static CDZKR_MANDANT = 1381 /* Beginn der Mandanten-Zusatzkriterien */
  public static CDZKR_PERS = 1401 /* Beginn der Personen-Zusatzkriterien */
  public static CDZKR_PERS_KLASSIERUNG = 1501 /* Beginn der Personen-Klassierungen */
  public static CDZKR_STR = 2221 /* Beginn der Standardreise-Zusatzkriterien */
  public static CDZKR_TRK = 2261 /* Beginn der Transportketten-Zusatzkriterien */
  public static CDZKR_LL = 2301 /* Beginn der Landleistungs-Zusatzkriterien */
  public static CDZKR_ERWEITERT = 2400 /* Beginn der erweiterten Zusatzkriterien für Produkte */
  public static CDZKR_TLN = 3101 /* Beginn der Teilnehmer-Zusatzinfos (Fragebogen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_SYSTEM = 5059 /* Die CDBEZ enthält mandantenunabhängige Basiseinstellungen für das System (alle Angaben sind, wo nicht anders erwähnt, verschlüsselt) */
  public static CDSYS_SMTP_SERVER = 1    /* Tourdata-SMTP-Server für Fehler- und Absturzmeldungen (verschlüsselt) */
  public static CDSYS_SMTP_PORT = 2    /* SMTP-Port für Fehler- und Absturzmeldungen (verschlüsselt) */
  public static CDSYS_SMTP_KONTO = 3    /* Absenderkonto für SMTP-Fehler- und Absturzmeldungen (verschlüsselt) */
  public static CDSYS_SMTP_PW = 4    /* Passwort für das SMTP-Absenderkonto (verschlüsselt) */

  /*--------------------------------------------------------------------------*/

  public static TAB_LIZENZ = 5060 /* Die CDBEZ enthält Tourdata-Lizenzeinstellungen (ganze DB oder pro CDMandant; alle Angaben sind, wo nicht anders errwähnt, verschlüsselt) */
  public static CDLIZ_ANZ_USER = 1000 /* Limite Anzahl aktiver Benutzer (dahinter darf und soll nach einem Blank ein Text stehen, der auf den Lizenznehmer hindeutet + zus. Zeichen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_CDMGRUPPENCODE = 5061 /* Gruppierungsart einer Codeart */
  public static CDCDMGR_KEINE = 0    /* Keine Gruppierung */
  public static CDCDMGR_NORMAL = 1    /* Gruppierung gem. Feld CDRCODE_GRUPPE und Eintrag in der Tabelle Codereferenz mit CDRTYPCODE 1 */

  /*--------------------------------------------------------------------------*/

  public static TAB_CDMBAUMCODE = 5062 /* Art des einer Codeart zugeordneten Baumes */
  public static CDCDMB_KEIN = 0    /* Kein Baum zugeordnet */
  public static CDCDMB_NORMAL = 1    /* Baum ohne spezielle Einschränkungen oder Sortierungen, jeder Code kann unabhängig von der Gruppe an unterschiedl. Stellen im Baum vorkommen */

  /*--------------------------------------------------------------------------*/

  public static TAB_CDRTYPCODE = 5063 /* Art der Codereferenz */
  public static CDCDRT_GRUPPIERUNG = 1    /* Gruppierung der dazugehörigen Codes aus der Codetabelle */
  public static CDCDRT_OPTION = 2    /* Mögliche Option bei den Codes einer Codeart aus der Codetabelle */

  /*--------------------------------------------------------------------------*/

  public static TAB_ERSATZTEXTE = 5064 /* SQL-Formeln zum Ersetzen spezifscher Bezeichnungen, Textbestandteilen usw. für spezielle Situationen */
  public static CDERSTXT_HOBEZ_EXTERN = 2000 /* Halteortbezeichnungen (wird z.Z. nur für die Schifffahrt verwendet), fak. Platzhalter "#." für den Tabellen-Alias der Halteorttabelle */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTVORHCODE = 5065 /* Gibt an, wie bereits vorhandene Datensätzen bzw. Dateien im Zielsystem behandelt werden sollen. */
  public static CDAUTV_DEFAULT = 0    /* NULL=Default (Dateien ersetzen, Datensätze hinzufügen) */
  public static CDAUTV_HINZUFUEGEN = 1    /* An bestehende Datei anhängen bzw. neue Datensätze in Tabelle hinzufügen */
  public static CDAUTV_ERSETZEN = 2    /* Datei bzw. Datensätze ersetzen */
  public static CDAUTV_LOESCHEN = 3    /* Datei bzw. Datensätze nur löschen, keine neuen Daten hinzufügen */

  /*--------------------------------------------------------------------------*/

  public static TAB_MPZIELGRUPPENCODE = 5066 /* Gibt an, für welche Benutzer der Menupunkt sichtbar sein soll */
  public static CDMPZ_DEFAULT = 0    /* alle eigenen Mitarbeiter (alle, ausser Fremdfahrer mit PERSFUNKCODE 16) */
  public static CDMPZ_INKL_FREMDFAHRER_KURZFRISTIG = 10   /* alle eigenen Mitarbeiter, zusätzlich ein paar Tage vor Abreise bis ein paar Tage nach Rückreise auch Fremdfahrer */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUTSUBCODE = 5067 /* Unterart der Automation (5000-5199 von Codeart 5001 ZPCODE abgeleitet) */
  public static CDAUTSC_DEFAULT = 0    /* NULL=Standard, keine Unterart nötig */
  public static CDAUTSC_AWVORLAGEN_VON = 100  /* Erster für Auswertungsvorlagen reservierter Code */
  public static CDAUTSC_PRO_PARTNER = 100  /* Auswertung für jeden Partner separat ausführen (Auswahlkriterien als WHERE-Bedingung in AUTBEFEHL; nur für Listen mit PAID als Feld) */
  public static CDAUTSC_AWVORLAGEN_BIS = 199  /* Letzter für Auswertungsvorlagen reservierter Code */
  public static CDAUTSC_VORDEF_VON = 3000 /* Erster für vordefinierte strukturierte Daten reservierter Code */
  public static CDAUTSC_PARTNER = 3100 /* Partnerstruktur */
  public static CDAUTSC_DOSSIER = 3300 /* Dossierstruktur */
  public static CDAUTSC_UMFRAGE = 3600 /* Umfragestruktur */
  public static CDAUTSC_VORDEF_BIS = 3999 /* Letzter für vordefinierte strukturierte Daten reservierter Code */
  public static CDAUTSC_TDSTANDARD_VON = 7000 /* Erster für Tourdata-Standardabläufe reservierter Code */
  public static CDAUTSC_STANDARD_VON = 8000 /* Erster für standardisierte vordefinierte Abläufe reservierter Code */
  public static CDAUTSC_ZPCODE_VON = 8000 /* Erster für Übernahmen aus der Zeitpunkttabelle reservierter Code (+ZPCODE aus Codeart 5001=passender AUTSUBCODE) */
  public static CDAUTSC_ZPCODE_BIS = 8199 /* Letzter für Übernahmen aus der Zeitpunkttabelle reservierter Code */
  public static CDAUTSC_INTERNETDS_STORNIEREN = 8400 /* Storniert nach einer gewissen Zeit automatisch unvollständige Internetdossiers */
  public static CDAUTSC_LOB_FREIGEBEN = 8410 /* Nicht mehr benötigte Large Objects (LOBs) freigeben */
  public static CDAUTSC_ADRABGLEICH_VON = 8500 /* Erster für Adressabgleiche mit Fremdsystemen reservierter Code */
  public static CDAUTSC_ADRABGLEICH_EXPORT = 8500 /* Adressabgleich mit Fremdsystem gem. AUTBEFEHL, Export der im Tourdata veränderten Daten */
  public static CDAUTSC_ADRABGLEICH_IMPORT = 8501 /* Adressabgleich mit Fremdsystem gem. AUTBEFEHL, Import der im Fremdsystem veränderten Daten */
  public static CDAUTSC_ADRABGLEICH_SYNC = 8505 /* Adressabgleich mit Fremdsystem gem. AUTBEFEHL, Synchronisation des Gesamtbestandes */
  public static CDAUTSC_ADRABGLEICH_BIS = 8509 /* Letzter für Adressabgleiche mit Fremdsystemen reservierter Code */
  public static CDAUTSC_DSABGLEICH_VON = 8520 /* Erster für Dossierabgleiche mit Fremdsystemen reservierter Code */
  public static CDAUTSC_DSABGLEICH_EXPORT = 8520 /* Dossierabgleich mit Fremdsystem gem. AUTBEFEHL, Export der im Tourdata veränderten Dossiers */
  public static CDAUTSC_DSABGLEICH_SYNC = 8525 /* Dossierabgleich mit Fremdsystem gem. AUTBEBEFEHL, Synchronisation des Gesamtbestandes */
  public static CDAUTSC_DSABGLEICH_BIS = 8529 /* Letzter für Dossierabgleiche mit Fremdsystemen reservierter Code */
  public static CDAUTSC_UPSELLING_INFO = 8600 /* Information über Transport-Upselling-Möglichkeit */
  public static CDAUTSC_GS_EINLOESEKONTROLLE = 8700 /* Gutschein-Einlösekontrolle über Fremdsystem gem. AUTBEFEHL, Kopie wird in Tourdata-Nummernverwaltung abgespeichert */
  public static CDAUTSC_STANDARD_BIS = 8999 /* Letzter für standardisierte vordefinierte Abläufe reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_CDMOPTIONENCODE = 5068 /* Art, wie Optionen in der Codeart geführt werden  */
  public static CDCDMO_KEINE = 0    /* Es werden keine Optionen geführt */
  public static CDCDMO_NORMAL = 1    /* Optionen gem. CDRKURZBEZ in der Tabelle Codereferenz mit CDRTYPCODE 2 */

  /*--------------------------------------------------------------------------*/

  public static TAB_APP_TEMPLATES = 5069 /* (HTML-)Beschreibung (CDBESCHR) für eine bestimmte Leistungsart (CDBEZ, z.B. Accomodation, Cruise) mit Platzhaltern in {} analog Textverarb. */
  public static CDAPPT_PROD_VON = 2000 /* Erster für Produkttexte reservierter Code */
  public static CDAPPT_PROD_BIS = 2999 /* Letzter für Produkttexte reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_GECODE = 5070 /* Art des Gestaltungselements */
  public static CDGE_BOOLEAN = 0    /* Ja-/Nein-Feld */
  public static CDGE_ZEICHEN = 1    /* Zeichenketten */
  public static CDGE_ZAHL = 2    /* Zahl */
  public static CDGE_DATUM = 3    /* Datum */
  public static CDGE_ZEIT = 4    /* Zeit (Stunden und Minuten) */
  public static CDGE_CODE = 9    /* mandantenabhängiger Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_GEDARSTELLUNGSCODE = 5071 /* Art, wie Gestaltungselemente dargestellt werden sollen */
  public static CDGEDARST_DEFAULT = 0    /* NULL=normale Darstellung */
  public static CDGEDARST_HERVORGEHOBEN = 2    /* Hervorgehobene Darstellung (z.B. fett, grösser, farbig - Art von App abhängig) */

  /*--------------------------------------------------------------------------*/

  public static TAB_GEFORMATCODE = 5072 /* Spezialformatierung von Gestaltungselementen */
  public static CDGEFMT_DEFAULT = 0    /* NULL=Standarddarstellung, abhängig vom GECODE */
  public static CDGEFMT_REGLER = 1    /* Schieberegler statt Checkbox (bei GECODE 0), z.Z. nicht unterstützt */
  public static CDGEFMT_BETRAG = 202  /* Betrag mit 2 Nachkommastellen statt Zahl ohne Nachkommastellen (bei GECODE 2) */
  public static CDGEFMT_TAG_MONAT = 303  /* Beim Datum nur Tag und Monat anzeigen statt des ganzen Datums (bei GECODE 3, z.Z. nicht unterstützt) */
  public static CDGEFMT_SEGMENT = 901  /* Segment (Bezeichnungen nebeneinander statt Auswahlliste (bei GECODE 9) */

  /*--------------------------------------------------------------------------*/

  public static TAB_GEMEHRFACHCODE = 5073 /* Gibt an, wie ein Gestaltungselement mehrfach erfasst werden kann */
  public static CDGEMF_KEINE = 0    /* NULL=Keine Mehrfacherfassung erlaubt */
  public static CDGEMF_MOEGLICH = 1    /* Mehrfacherfassung möglich */

  /*--------------------------------------------------------------------------*/

  public static TAB_CDRSPEZCODE = 5074 /* Spezielle Ausprägungen für Codereferenzeinträge */
  public static CDCDRSP_DEFAULT = 0    /* NULL=Default */
  public static CDCDRSP_KBEZ_BELIEBIG = 1    /* Bei CDRTYPCODE 2: Option kann auch in der CDKURZBEZ an beliebiger Stelle vorhanden sein (Kompatibilität mit älteren Versionen) */
  public static CDCDRSP_KBEZ_ANFANG = 5    /* Bei CDRTYPCODE 2: Option kann auch am Anfang der CDKURZBEZ vorhanden sein (Kompatibilität mit älteren Versionen) */
  public static CDCDRSP_KBEZ_ANFANG_BLANK = 6    /* Bei CDRTYPCODE 2: Option kann auch am Anfang der CDKURZBEZ mit einem Blank danach vorhanden sein (Kompatibilität mit älteren Versionen) */
  public static CDCDRSP_KBEZ_MINUS = 10   /* Bei CDRTYPCODE 2: Option kann in der CDKURZBEZ irgendwann nach dem Trennzeichen "-" vorkommen */
  public static CDCDRSP_KBEZ_ENDE = 20   /* Bei CDRTYPCODE 2: Option kann auch am Ende der CDKURZBEZ vorhanden sein (Kompatibilität mit älteren Versionen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_TXBCODE = 5099 /* Textbausteincode */
  public static CDTXB_RECHNUNG = 1    /* Rechnung */
  public static CDTXB_REISEPROGRAMM = 2    /* Reiseprogramm */
  public static CDTXB_VOUCHER = 3    /* Voucher */
  public static CDTXB_REQUEST = 4    /* Request absetzen */
  public static CDTXB_PRODUKTTEXT = 5    /* Produktbeschreibung */
  public static CDTXB_OFFERTE = 6    /* Offerte */
  public static CDTXB_LISTE = 7    /* Frei definierbare Listen */
  public static CDTXB_DEB_KRED = 8    /* Debitoren und Kreditoren */
  public static CDTXB_UEBRIGE = 9    /* allgemeine Textbausteine und übrige Gebiete */
  public static CDTXB_PARTNER = 10   /* Logos, Bilder und Beschreibungen zum Partner */
  public static CDTXB_WMAT = 11   /* Werbematerialbrief */

  /*--------------------------------------------------------------------------*/

  public static TAB____UEBERGREIFEND_BEREICHE___ = 5100 /* === Beginn der verwaltungsübergreifenden Codearten, welche für Codeartenbereiche verwendet werden === */

  /*--------------------------------------------------------------------------*/

  public static TAB_TXBSUBCODE = 5100 /* Textbaustein-Subcode */
  public static CDTXBS_HALTEORT = 5    /* Halteort */
  public static CDTXBS_STR = 10   /* Standardreise */
  public static CDTXBS_STR_KOPF = 11   /* Standardreise Kopfzeilen */
  public static CDTXBS_STR_FUSS = 12   /* Standardreise Fusszeilen */
  public static CDTXBS_STR_RUNDREISE = 18   /* Standardreise Rundreiseprogramm */
  public static CDTXBS_GRP_KOPF = 21   /* Gruppierung Kopftext */
  public static CDTXBS_GRP_FUSS = 22   /* Gruppierung Fusstext */
  public static CDTXBS_TRK_HINREISE = 31   /* Transportkette Hinreise */
  public static CDTXBS_TRK_RUECKREISE = 32   /* Transportkette Rückreise */
  public static CDTXBS_TRB = 45   /* Transportbewegung */
  public static CDTXBS_LL = 50   /* Landleistung */
  public static CDTXBS_ZIMMERPLAN = 51   /* Zimmerplan */
  public static CDTXBS_KAT = 60   /* Kategorie */
  public static CDTXBS_ZUS = 70   /* Zusatzleistung */
  public static CDTXBS_ZUS_NGEB = 72   /* Nicht gebuchte Zusatzleistungen */
  public static CDTXBS_ZUS_INDIV = 73   /* individuelle Dokumentbeilage zu Zusatzleistungen */
  public static CDTXBS_ZLGR_NGEB = 77   /* Zusatzleistungsgruppen, aus denen nichts gebucht wurde */
  public static CDTXBS_GSC = 100  /* Gutscheincode */
  public static CDTXBS_TRM = 110  /* Transportmittel */
  public static CDTXBS_MAHN_KOPF = 201  /* Mahntext Kopfzeilen */
  public static CDTXBS_MAHN_FUSS = 202  /* Mahntext Fusszeilen */
  public static CDTXBS_DEST = 300  /* Destination */
  public static CDTXBS_HOMEPAGE = 700  /* Homepage */
  public static CDTXBS_MENUPUNKT = 720  /* Menupunkt */
  public static CDTXBS_WMAT_TEXT = 741  /* Werbematerialtext */
  public static CDTXBS_ZBED = 800  /* Zahlungsbedingungen */
  public static CDTXBS_PZL = 810  /* Preiszeilen */
  public static CDTXBS_MITGLIED = 820  /* Mitgliedschaft */
  public static CDTXBS_FRAGEBOGEN = 830  /* Fragebogen-Ablauftexte */
  public static CDTXBS_BEILAGE = 840  /* Beilagenabläufe (z.B. Umfragen) */
  public static CDTXBS_SPEZ_TEILNEHMER = 850  /* Spezielle Teilnehmertexte */
  public static CDTXBS_STEUERUNG = 900  /* Text mit Steuercodes */
  public static CDTXBS_STEUERUNG_ZUSDOK = 902  /* Ablaufsteuerungen für Zusatzdokumente */
  public static CDTXBS_MAILTEXT = 905  /* Text für das Begleitmail */
  public static CDTXBS_TEXT = 910  /* Allgemeiner Text */
  public static CDTXBS_ZUSATZ = 911  /* Zusatztext */
  public static CDTXBS_TABELLE = 915  /* Tabelle */
  public static CDTXBS_RESERVIERT = 920  /* Text mit reservierter ID */

  /*--------------------------------------------------------------------------*/

  public static TAB_TXBSUBCODE_RESERVE = 5199 /* Von TXBSUBCODE bis hier sind die Codes für die SUBCODEs reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_FELDCODE = 5200 /* Mögliche Felder für Selektionen (Code TTCC: TT=Tabellen-, CC=Column-Define) */

  /*--------------------------------------------------------------------------*/

  public static TAB_FELDCODE_RESERVE = 5249 /* Von FELDCODE bis hier für die Codes gem. SLCODE reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSWERTCODE = 5250 /* Mögliche Auswertungen */

  /*--------------------------------------------------------------------------*/

  public static TAB_PA_AUSWERTCODE = 5251 /* Auswertungen für Partnerlisten */
  public static CDAUSW_KURZLISTE = 1    /* Adresskurzliste */
  public static CDAUSW_VOLLISTE = 2    /* Adress-Volliste */
  public static CDAUSW_ETIKETTEN = 3    /* Adressetiketten */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSWERTCODE_RESERVE = 5299 /* Von AUSWERTCODE bis hier für die Codes gem. SLCODE reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB_FELDGRUPPENCODE = 5300 /* Mögliche Feldgruppen der Textverarbeitung (Reserviert bis 5399) */
  public static CDFGRP_KUNDE = 1    /* Kunde */
  public static CDFGRP_STR = 2    /* Standardreise */
  public static CDFGRP_HFL = 3    /* Hinflug */
  public static CDFGRP_RFL = 4    /* Rückflug */
  public static CDFGRP_LL = 5    /* Landleistung */
  public static CDFGRP_DS = 6    /* Dossier */
  public static CDFGRP_TLN = 7    /* Teilnehmer */
  public static CDFGRP_GSC = 10   /* Gutscheincode */
  public static CDFGRP_DI = 20   /* Disposition */
  public static CDFGRP_BST = 40   /* Buchungsstelle */
  public static CDFGRP_MAND = 50   /* Mandant */
  public static CDFGRP_WMAT = 60   /* Werbematerial */
  public static CDFGRP_FUNK = 80   /* Funktionen */
  public static CDFGRP_ESR = 90   /* ESR-Bestandteile */
  public static CDFGRP_UNTERGRUPPEN = 100  /* Ab hier ist für Untergruppen reserviert. Pro Hauptgruppe steht ein 100er-Bereich zur Verfügung, zB. 100-199 für Untergruppen des Kd.bereich */

  /*--------------------------------------------------------------------------*/

  public static TAB_KD_FELDER = 5301 /* Kundenfelder für die Textverarbeitung */
  public static CDKDF_KDNR = 1    /* Kundennr */
  public static CDKDF_ANREDE = 2    /* Anrede */
  public static CDKDF_NAME = 3    /* (Nach-)Name */
  public static CDKDF_VORNAME = 4    /* Vorname */
  public static CDKDF_VN_NN = 5    /* Vorname und Nachname */
  public static CDKDF_ADRZUS = 6    /* Adresszusatz */
  public static CDKDF_STRASSE = 7    /* Strasse */
  public static CDKDF_ORT = 8    /* Land, PLZ und Ort */
  public static CDKDF_BRIEFANREDE = 9    /* Briefanrede des Kunden oder dessen Sachbearbeiter */
  public static CDKDF_ADRZUS_UND_STRASSE = 10   /* Adresszusatz UND Strasse (durch Komma und Blank getrennt, wenn beide ausgefüllt sind) */
  public static CDKDF_TEL1 = 11   /* Die erste Telefonnummer des Kunden oder dessen Sachbearbeiter */
  public static CDKDF_TEL2 = 12   /* Die 2. Telefonnummer des Kunden oder dessen Sachbearbeiter */
  public static CDKDF_FAX = 13   /* Fax des Kunden */
  public static CDKDF_EMAIL = 14   /* Email des Kunden */
  public static CDKDF_KOMM_MITTEL = 15   /* elektronisches Kommunikationsmittel des Kunden oder dessen Sachbearbeiter */
  public static CDKDF_KOMM_NR = 16   /* Kommunikationsnummer (z.B. Faxnr) des Kunden oder dessen Sachbearbeiter */
  public static CDKDF_ADRZUS_STRASSE = 17   /* Adresszusatz oder Strasse, wenn Adr.zus. leer ist (** alt **) */
  public static CDKDF_STRASSE_LEER = 18   /* Strasse oder leer, wenn Adr.zus. leer ist (** alt **) */
  public static CDKDF_STRASSE_PLZ_ORT = 19   /* Strasse oder Land, PLZ und Ort, wenn Adr.zus. leer ist (** alt **) */
  public static CDKDF_PLZ_ORT_LEER = 20   /* Land, PLZ und Ort oder leer, wenn Adr.zus. leer ist (** alt **) */
  public static CDKDF_ADRZL1 = 21   /* Adresszeile 1 für Anschrift (Vorname + Name) */
  public static CDKDF_ADRZL2 = 22   /* Adresszeile 2 für Anschrift (SB beim Kunden oder nachfolgende Zeile) */
  public static CDKDF_ADRZL3 = 23   /* Adresszeile 3 für Anschrift (Bemerkung des SB beim Kunden oder nachfolgende Zeile) */
  public static CDKDF_ADRZL4 = 24   /* Adresszeile 4 für Anschrift (Adresszusatz oder nachfolgende Zeile) */
  public static CDKDF_ADRZL5 = 25   /* Adresszeile 5 für Anschrift (Strasse oder nachfolgende Zeile) */
  public static CDKDF_ADRZL6 = 26   /* Adresszeile 6 für Anschrift (Land und/oder PLZ/Ort oder leer) */
  public static CDKDF_ADRZL7 = 27   /* Land in Grossbuchstaben, wenn alle Zeilen ausgefüllt und Land decodiert werden kann */
  public static CDKDF_MOBILTEL = 29   /* Mobiltelefon des Kunden */
  public static CDKDF_HOMEPAGE = 30   /* Homepage */
  public static CDKDF_SB_ANREDE = 31   /* Anrede des Ansprechpartners beim Kunden (Sachbearbeiter) */
  public static CDKDF_SB_NAME = 32   /* Name des Sachbearbeiters */
  public static CDKDF_SB_VORNAME = 33   /* Vorname des Sachbearbeiters */
  public static CDKDF_SB_VN_NN = 34   /* Vorname und Name des Sachbearbeiters */
  public static CDKDF_MGBEZ = 40   /* Bezeichnung der Kundenmitgliedschaft */
  public static CDKDF_MGERSPARNIS = 41   /* Gesparter Betrag durch Mitgliedschaft */
  public static CDKDF_MGVONDAT = 42   /* Mitgliedschaftsbeginn */
  public static CDKDF_MGBISDAT = 43   /* Mitgliedschaftsende */
  public static CDKDF_MGBESTE = 46   /* Bezeichnung der bestmöglichen Mitgliedschaft bezogen auf die aktuelle Buchung */
  public static CDKDF_MGMOEGL_ERSPARNIS = 47   /* Betrag, der gespart werden könnte, wenn die beste Mitgliedschaft gewählt würde */
  public static CDKDF_MGMOEGL_PREIS = 48   /* Normaler Mitgliedschaftspreis, der bezahlt werden müsste, um die Ersparnis zu erreichen */
  public static CDKDF_NOTFALLTEL = 50   /* Telefonnr. des Notfallkontakts */
  public static CDKDF_NOTFALLKONTAKT = 51   /* Name/Art des Notfallkontakts */
  public static CDKDF_KONTONR = 60   /* Kontonummer / IBAN */
  public static CDKDF_BIC = 61   /* BIC bzw. SWIFT-Adresse */
  public static CDKDF_ZUSKRIT1 = 71   /* Zusatzkriterium 1 */
  public static CDKDF_ZUSKRIT2 = 72   /* Zusatzkriterium 2 */
  public static CDKDF_ZUSKRIT3 = 73   /* Zusatzkriterium 3 */
  public static CDKDF_ZUSKRIT4 = 74   /* Zusatzkriterium 4 */
  public static CDKDF_ZUSKRIT5 = 75   /* Zusatzkriterium 5 */
  public static CDKDF_ZUSKRIT6 = 76   /* Zusatzkriterium 6 */
  public static CDKDF_LAND = 81   /* Land */

  /*--------------------------------------------------------------------------*/

  public static TAB_STR_FELDER = 5302 /* Standardreisenfelder für die Textverarbeitung */
  public static CDSTRF_ANREDE = 2    /* Anrede */
  public static CDSTRF_NAME = 3    /* Name */
  public static CDSTRF_VORNAME = 4    /* Vorname */
  public static CDSTRF_VN_NN = 5    /* Vorname und Nachname */
  public static CDSTRF_ADRZUS = 6    /* Adresszusatz */
  public static CDSTRF_STRASSE = 7    /* Strasse */
  public static CDSTRF_PLZ_ORT = 8    /* Land, PLZ, Ort */
  public static CDSTRF_ORT = 10   /* Ort */
  public static CDSTRF_TEL1 = 11   /* Erste Telefonnummer */
  public static CDSTRF_TEL2 = 12   /* Zweite Telefonnummer */
  public static CDSTRF_FAX = 13   /* Fax */
  public static CDSTRF_EMAIL = 14   /* Email */
  public static CDSTRF_KOMM_MITTEL = 15   /* Elektronisches Kommunikationsmittel */
  public static CDSTRF_KOMM_NR = 16   /* Kommunikationsnummer (z.B. Faxnr.) */
  public static CDSTRF_MOBILTEL = 17   /* Mobiltelefon */
  public static CDSTRF_HOMEPAGE = 30   /* Homepage */
  public static CDSTRF_KURZBEZ = 31   /* Kurzbezeichnung */
  public static CDSTRF_LANGBEZ = 32   /* Langbezeichnung */
  public static CDSTRF_SACHBEARB = 33   /* Sachbearbeiter */
  public static CDSTRF_REISEART = 34   /* Produkttyp der Standardreise */
  public static CDSTRF_BESCHR = 35   /* Produktbeschreibung in reiner Textform (PRBES_BESCHR) */
  public static CDSTRF_SB_TEL1 = 36   /* Telefon 1 (Privat) des Standardreise-Sachbearbeiters */
  public static CDSTRF_SB_TEL2 = 37   /* Telefon 2 (Geschäft) des Standardreise-Sachbearbeiters */
  public static CDSTRF_SB_KONTINGENT_VIS = 38   /* Visum des Sachbearbeiter Kontingent */
  public static CDSTRF_PASS = 40   /* Passbetimmungen */
  public static CDSTRF_PASSBEZ = 41   /* Passbestimmungsbezeichnung */
  public static CDSTRF_ZUSATZTEXT = 42   /* Zusätzlicher Textbaustein gem. TXBID_ZUSATZ */
  public static CDSTRF_ALLG_INFO = 43   /* Allgemeine Informationen */
  public static CDSTRF_TLNBEM = 45   /* Auflistung der Teilnehmerbemerkungen */
  public static CDSTRF_TICKETNR = 46   /* Auflistung der Ticketnummern aller Teilnehmer */
  public static CDSTRF_MAND_NAME = 53   /* Name des zuständigen Mandanten */
  public static CDSTRF_DESTTXB = 62   /* Textbaustein zur Destination */
  public static CDSTRF_ZUSKRIT1 = 71   /* Zusatzkriterium 1 */
  public static CDSTRF_ZUSKRIT2 = 72   /* Zusatzkriterium 2 */
  public static CDSTRF_ZUSKRIT3 = 73   /* Zusatzkriterium 3 */
  public static CDSTRF_ZUSKRIT4 = 74   /* Zusatzkriterium 4 */
  public static CDSTRF_ZUSKRIT5 = 75   /* Zusatzkriterium 5 */
  public static CDSTRF_ZUSKRIT6 = 76   /* Zusatzkriterium 6 */
  public static CDSTRF_RL_ANREDE = 82   /* Anrede des Reiseleiters */
  public static CDSTRF_RL_NAME = 83   /* Name des Reiseleiters */
  public static CDSTRF_RL_VORNAME = 84   /* Vorname des Reiseleiters */
  public static CDSTRF_ABRSTATUS_INTERN = 91   /* Abreisestatus intern (gem. Leistungsdatum) */
  public static CDSTRF_BUCHUNGSHINWEIS = 92   /* Buchungshinweis gem. Leistungsdatum (LDATTEXT) */
  public static CDSTRF_UPSELLING_BIS = 95   /* Upselling bis */
  public static CDSTRF_HAUPTBILD = 100  /* Hauptbild */
  public static CDSTRF_BILD1 = 101  /* Bild 1 (entspricht dem Hauptbild, jedoch möglicherweise in einer anderen Grösse) */
  public static CDSTRF_BILD_VON = 101  /* Beginn des für normale Einzelbilder reservierten Bereichs */
  public static CDSTRF_BILD2 = 102  /* Bild 2 */
  public static CDSTRF_BILD3 = 103  /* Bild 3 */
  public static CDSTRF_BILD_BIS = 119  /* Ende des für normale Einzelbilder reservierten Bereichs */
  public static CDSTRF_ALLE_BILDER = 121  /* alle Bilder (inkl. Hauptbild), getrennt durch ein Tabulatorzeichen */
  public static CDSTRF_WEITERE_BILDER = 122  /* weitere Bilder, d.h. alle Bilder OHNE das Hauptbild, getrennt durch ein Tabulatorzeichen */
  public static CDSTRF_LEGENDE_HAUPTBILD = 130  /* Legende zum Hauptbild */
  public static CDSTRF_LEGENDE_BILD_VON = 131  /* Beginn des für die Legende für normale Einzelbilder reservierten Beeichs */
  public static CDSTRF_LEGENDE_BILD1 = 131  /* Legende zu Bild 1 (entspricht dem Hauptbild) */
  public static CDSTRF_LEGENDE_BILD2 = 132  /* Legende zu Bild 2 */
  public static CDSTRF_LEGENDE_BILD3 = 133  /* Legende zu Bild 3 */
  public static CDSTRF_LEGENDE_BILD_BIS = 149  /* Ende des für die Legende für normale Einzelbilder reservierten Bereichs */

  /*--------------------------------------------------------------------------*/

  public static TAB_HFL_FELDER = 5303 /* Felder für die Hinreise (die Rückreise verwendet dieselben Defines) */
  public static CDFLF_DEST = 31   /* Destination */
  public static CDFLF_ART = 32   /* Transportart */
  public static CDFLF_ABR_KURZBEZ = 33   /* Abreiseort, Kurzbezeichnung */
  public static CDFLF_ABR_LANGBEZ = 34   /* Abreiseort, Langbezeichnung */
  public static CDFLF_ANK_KURZBEZ = 35   /* Ankunftsort, Kurzbezeichnung */
  public static CDFLF_ANK_LANGBEZ = 36   /* Ankunftsort, Langbezeichnung */
  public static CDFLF_BUHINW = 38   /* Buchungshinweis */
  public static CDFLF_ABR_DAT = 39   /* Abreisedatum */
  public static CDFLF_GESELLSCH = 40   /* Transportgesellschaft */
  public static CDFLF_NR = 41   /* Transportnummer */
  public static CDFLF_KLASSE = 42   /* Transportklasse */
  public static CDFLF_FREIGEP = 43   /* Freigepäck */
  public static CDFLF_CHECKIN_ZEIT = 44   /* Checkin-Zeit */
  public static CDFLF_CHECKIN_SCHALTER = 45   /* Checkin-Schalter */
  public static CDFLF_ABR_ZEIT = 46   /* Abreisezeit */
  public static CDFLF_ABR_FLUGHAFEN = 47   /* Abreiseort-Flughafen */
  public static CDFLF_ZWLAND = 48   /* Zwischenlandung */
  public static CDFLF_ANK_ZEIT = 49   /* Ankunftszeit */
  public static CDFLF_ANK_FLUGHAFEN = 50   /* Ankunftsflughafen */
  public static CDFLF_TICKETNR = 51   /* Ticketnr */
  public static CDFLF_ANK_DATUM = 52   /* Ankunftsdatum am Zielort */
  public static CDFLF_CHECKIN_DATUM = 53   /* Checkin-Datum (1 Tag vor Abreisedatum, wenn Checkin- nach Abreisezeit) */
  public static CDFLF_GERAET = 54   /* Bezeichnung des Geräts */
  public static CDFLF_TLNBEM = 55   /* Auflistung der Teilnehmerbemerkungen */
  public static CDFLF_KURZBEZ = 56   /* Kurzbezeichnung der Transportkette */
  public static CDFLF_ABR_TAG = 57   /* Wochentag der Abreise */
  public static CDFLF_ANK_TAG = 58   /* Wochentag der Ankunft am Zielort */
  public static CDFLF_INDIV_TEXT = 59   /* Individueller Textbaustein der jeweiligen Transportbewegung (...\5\45\D\prmand\JJJJ\MM_DD_prid.txb) */
  public static CDFLF_RES = 60   /* Textbaustein für die Reservation */
  public static CDFLF_PLATZ = 61   /* Sitzplatznummer */
  public static CDFLF_ABTEIL = 62   /* Sitzplatzabteil */
  public static CDFLF_MAHLZEITEN = 63   /* Mahlzeiten */
  public static CDFLF_REISEZEIT = 64   /* Dauer des Transports in Stunden und Minuten */
  public static CDFLF_PNR_ODER_DSID = 65   /* PNR oder, wenn keines vorhanden, Dossiernr (Charter) */
  public static CDFLF_TEXT_OHNE_PNR = 66   /* Text, wenn ohne PNR (=Charterflug) */
  public static CDFLF_KLASSENBEZ = 67   /* Ausgeschriebene Bezeichnung der Transportklasse (bei Bussen gem. Codeart 2095, sonst gem. Codeart 2098 oder vordefiniert) */
  public static CDFLF_TRM_BESCHR = 68   /* Transportmittel-Beschreibung */
  public static CDFLF_INDIV_KLASSENBEZ = 69   /* Individuelle Klassenbezeichnung gem. Transportgesellschaft */
  public static CDFLF_EINSTIEGSZEIT_ORT = 70   /* Einstiegszeit und -ort ( nur bei der Hinreise) */
  public static CDFLF_EINSTIEGSORT = 71   /* Einstiegsort (nur bei der Hinreise) */
  public static CDFLF_AUSSTIEGSZEIT_ORT = 75   /* Ausstiegszeit und -ort (nur bei der Rückreise) */
  public static CDFLF_AUSSTIEGSORT = 76   /* Ausstiegsort (nur bei der Rückreise) */
  public static CDFLF_VOID = 80   /* Wort VOID aus Codeart 5013, Code 2, sofern nicht letzter Flug */
  public static CDFLF_AUSF_GESELL = 81   /* Ausführende Gesellschaft (Operating Carrier) */
  public static CDFLF_AUSF_NR = 82   /* Transportnr. inkl. Kürzel der ausführenden Transportgesellschaft (Operating Carrier) */
  public static CDFLF_GEPAECKINFO = 83   /* Informationen zum möglichen Aufgabegepäck gem. gebuchter Gesellschaft (Ticket Carrier) */
  public static CDFLF_HANDGEPAECKINFO = 84   /* Informationen zum möglichen Handgepäck gem. ausführender Transportgesellschaft (Operating Carrier) */
  public static CDFLF_CHECKIN_TERMINAL = 85   /* Checkin-Terminal */
  public static CDFLF_ANK_TERMINAL = 86   /* Ankunftsterminal */
  public static CDFLF_ESSENSWUNSCH = 87   /* IATA-Mahlzeitencodes (auf ML endend) aus den Teilnehmerbemerkungen im Klartext */
  public static CDFLF_TLNBEM_OHNE_ESSEN = 88   /* Teilnehmerbemerkungen ohne IATA-Codes, die auf ML enden. IATA-Codes in Klartext umgewandelt. */
  public static CDFLF_CRS_STATUS = 90   /* Status des Reservationssystems */
  public static CDFLF_TAG_NR = 91   /* Abreisetag Nr. relativ zum Beginn der Reise */
  public static CDFLF_TAG_NR_BIS = 92   /* Ankunftstag Nr. relativ zum Beginn der Reise */
  public static CDFLF_TRKBESCHR = 95   /* Produktbeschreibung der Transportkette in reiner Textform (PRBES_BESCHR) */
  public static CDFLF_TRSBESCHR = 96   /* Produktbeschreibung der Transportstrecke in reiner Textform (PRBES_BESCHR) */
  public static CDFLF_HAUPTBILD = 100  /* Hauptbild */
  public static CDFLF_BILD_VON = 101  /* Beginn des für normale Einzelbilder reservierten Bereichs */
  public static CDFLF_BILD1 = 101  /* Bild 1 (entspricht dem Hauptbild, jedoch möglicherweise in einer anderen Grösse) */
  public static CDFLF_BILD2 = 102  /* Bild 2 */
  public static CDFLF_BILD3 = 103  /* Bild 3 */
  public static CDFLF_BILD_BIS = 119  /* Ende des für normale Einzelbilder reservierten Bereichs */
  public static CDFLF_ALLE_BILDER = 121  /* alle Bilder (inkl. Hauptbild), getrennt durch ein Tabulatorzeichen */
  public static CDFLF_WEITERE_BILDER = 122  /* weitere Bilder, d.h. alle Bilder OHNE das Hauptbild, getrennt durch ein Tabulatorzeichen */
  public static CDFLF_LEGENDE_HAUPTBILD = 130  /* Legende zum Hauptbild */
  public static CDFLF_LEGENDE_BILD_VON = 131  /* Beginn des für die Legende für normale Einzelbilder reservierten Beeichs */
  public static CDFLF_LEGENDE_BILD1 = 131  /* Legende zu Bild 1 (entspricht dem Hauptbild) */
  public static CDFLF_LEGENDE_BILD2 = 132  /* Legende zu Bild 2 */
  public static CDFLF_LEGENDE_BILD3 = 133  /* Legende zu Bild 3 */
  public static CDFLF_LEGENDE_BILD_BIS = 149  /* Ende des für die Legende für normale Einzelbilder reservierten Bereichs */
  public static CDFLF_ANK_NAECHSTER_TAG = 300  /* Text, wenn die Ankunft am nächsten Tag ist */

  /*--------------------------------------------------------------------------*/

  public static TAB_RFL_FELDER = 5304 /* Felder für die Rückreise (Defines der Hinreise verwenden) */

  /*--------------------------------------------------------------------------*/

  public static TAB_LL_FELDER = 5305 /* Landleistungsfelder */
  public static CDLLF_ANREDE = 2    /* Anrede */
  public static CDLLF_NAME = 3    /* (Nach-)Name */
  public static CDLLF_VORNAME = 4    /* Vorname */
  public static CDLLF_VN_NN = 5    /* Vorname und Nachname */
  public static CDLLF_ADRZUS = 6    /* Adresszusatz */
  public static CDLLF_STRASSE = 7    /* Strasse */
  public static CDLLF_PLZ_ORT = 8    /* Land, PLZ, Ort */
  public static CDLLF_ORT = 10   /* Ort */
  public static CDLLF_TEL1 = 11   /* Erste Telefonnummer */
  public static CDLLF_TEL2 = 12   /* Zweite Telefonnummer */
  public static CDLLF_FAX = 13   /* Faxnr. */
  public static CDLLF_EMAIL = 14   /* Email */
  public static CDLLF_KOMM_MITTEL = 15   /* elektronisches Kommunikationsmittel */
  public static CDLLF_KOMM_NR = 16   /* Kommunikationsnummer (z.B. Fax-Nr.) */
  public static CDLLF_MOBILTEL = 17   /* Mobiltelefonnr. */
  public static CDLLF_GEOGR_LAENGE = 20   /* Längengrad */
  public static CDLLF_GEOGR_BREITE = 21   /* Breitengrad */
  public static CDLLF_BRUTTOPREIS = 22   /* Summe aller dieser LL zugeordneter Preise, auf dem Voucher werden alle nicht stornierten Rechnungen/Gutschriften zusammengezählt */
  public static CDLLF_ZIMMER = 23   /* Zieht den vordefinierten Textbaustein ZIMMER pro Zimmer/Kabine einmal ein */
  public static CDLLF_ZIMMERAUSSTATTUNG = 24   /* Ausstattung der/des reservierten Hotelzimmer/s */
  public static CDLLF_QRCODE = 25   /* QR-Code als Grafik aufbereiten (z.Z. nur bei Parkplatzvoucher) */
  public static CDLLF_ZUS_GRP_O_TAB = 26   /* Zusatzleistungen mit Gruppen analog Code 42, jedoch Gruppe von ZL mit Blank getrennt statt mit Tab. */
  public static CDLLF_KATBESCHR = 27   /* Beschreibung der Kategorie gem. Codetabelle */
  public static CDLLF_ANZ_TAGE = 28   /* Anzahl Tage */
  public static CDLLF_LANDLEISTUNGSART = 29   /* Produkttyp der Landleistung (Landleistungsart) */
  public static CDLLF_HOMEPAGE = 30   /* Homepage */
  public static CDLLF_VON = 31   /* Beginn der Landleistung */
  public static CDLLF_BIS = 32   /* Ende der Leistung */
  public static CDLLF_HOTELBEZ = 33   /* Hotelbezeichnung */
  public static CDLLF_ANZ_ZIMMER = 34   /* Anzahl belegter Zimmer */
  public static CDLLF_KATBEZ = 35   /* Langbezeichnung der Kategorie gem. Codetabelle */
  public static CDLLF_ANZ_PAX = 36   /* Anzahl Personen, die diese Landleistung gebucht haben */
  public static CDLLF_ANZ_NAECHTE = 37   /* Anzahl Übernachtungen (bei Mietwagen +1, wenn Abholzeit vor Rückgabezeit) */
  public static CDLLF_ORT_VON = 38   /* Abholort */
  public static CDLLF_ORT_BIS = 39   /* Ankunftsort */
  public static CDLLF_RESERVATIONSART = 40   /* Reservationsart (Status) gem. Codeart 3005 */
  public static CDLLF_ZUS = 41   /* Zus.fassung der von den Teilnehmern gebuchten Zusatzleistungen */
  public static CDLLF_ZUS_GRP = 42   /* Zusammenfassung der von den Teilnehmern gebuchten Zusatzleistungen, die einer Gruppe zugeordnet sind */
  public static CDLLF_ZIMMERNR = 43   /* Hotelzimmer-Nummer(n) */
  public static CDLLF_ZIMMERNR_TLNBEM = 44   /* Kombination von ZIMMERNR und TLNBEM in der Reihenfolge Zi 1, Bem1.1, Bem1.2, Zi 2, Bem 2.1 */
  public static CDLLF_TLNBEM = 45   /* Auflistung der Teilnehmerbemerkungen */
  public static CDLLF_VON_TAG = 46   /* Wochentag des Beginns des Landleistungsaufenthalts */
  public static CDLLF_BIS_TAG = 47   /* Wochentag des Endes des Landleistungsaufenthalts */
  public static CDLLF_ANZ_WOCHEN = 48   /* Dauer des Aufenthalts in Wochen und Tagen (Textkonstansten gem. Codeart 5013, Codes 21-25) */
  public static CDLLF_REQUESTLINK = 49   /* Link, der in einem Request gedrückt werden kann, um den Request zu bestätigen oder abzulehnen */
  public static CDLLF_ZUS_O_GRP = 50   /* Zus.fassung der von den Teilnehmern gebuchten Zusätze OHNE solche, die einer Gruppe zugeordnet sind */
  public static CDLLF_AG_ANREDE = 52   /* Anrede des Agenten */
  public static CDLLF_AG_NAME = 53   /* Name des Agenten */
  public static CDLLF_AG_VORNAME = 54   /* Vorname des Agenten */
  public static CDLLF_AG_VN_NN = 55   /* Vorname und Nachname des Agenten */
  public static CDLLF_AG_ADRZUS = 56   /* Adresszusatz des Agenten */
  public static CDLLF_AG_STRASSE = 57   /* Strasse des Agenten */
  public static CDLLF_AG_PLZ_ORT = 58   /* Land, PLZ und Ort des Agenten */
  public static CDLLF_AG_ORT = 60   /* Ort des Agenten */
  public static CDLLF_AG_TEL1 = 61   /* Telefon 1 des Agenten */
  public static CDLLF_AG_TEL2 = 62   /* Telefon 2 des Agenten */
  public static CDLLF_AG_FAX = 63   /* Fax des Agenten */
  public static CDLLF_AG_EMAIL = 64   /* Email des Agenten */
  public static CDLLF_AG_KOMM_MITTEL = 65   /* Kommunikationsmittel des Agenten */
  public static CDLLF_AG_KOMM_NR = 66   /* Kommunikationsnummer des Agenten (z.B. Faxnr) */
  public static CDLLF_AG_MOBILTEL = 67   /* Mobiltelefonnr. des Agenten */
  public static CDLLF_ZUSKRIT1 = 71   /* Zusatzkriterium 1 der Landleistung */
  public static CDLLF_ZUSKRIT2 = 72   /* Zusatzkriterium 2 */
  public static CDLLF_ZUSKRIT3 = 73   /* Zusatzkriterium 3 */
  public static CDLLF_ZUSKRIT4 = 74   /* Zusatzkriterium 4 */
  public static CDLLF_ZUSKRIT5 = 75   /* Zusatzkriterium 5 */
  public static CDLLF_ZUSKRIT6 = 76   /* Zusatzkriterium 6 */
  public static CDLLF_TEILPERIODEN = 80   /* vordef. Textbaustein TEILPER für jede einzelne Teilperiode (Request/Option) einziehen */
  public static CDLLF_ZEIT_VON = 81   /* Zusatzinfo: Abfahrts- bzw. Abholzeit; wenn ohne Zusatzinfos oder dort leer gem. Nebenleistung der Landleistung "Zeit von" */
  public static CDLLF_ZEIT_BIS = 82   /* Zusatzinfo: Ankunfts- bzw. Rückgabezeit; wenn ohne Zusatzinfos oder dort leer gem. Nebenleistung der Landleistung "Zeit von" */
  public static CDLLF_ZUSBEZ = 83   /* Zusatzinfo: Zusätzliche Bezeichnung */
  public static CDLLF_CHECKIN_ZEIT = 84   /* Zusatzinfo: Checkin-Zeit */
  public static CDLLF_ZUSRESNR = 85   /* Zusatzinfo: Reservationsnr. */
  public static CDLLF_FREIER_TEXT = 86   /* Zusatzinfo: Freier Text */
  public static CDLLF_RECHNUNG_AN = 87   /* Adresse des in den Zusatzinfos oder bei der Landleistung erfassten Agenten */
  public static CDLLF_BESCHR = 88   /* Produktbeschreibung in reiner Textform (PRBES_BESCHR) */
  public static CDLLF_EINSTANDSPREIS = 89   /* Summe aller dieser LL zugeordneter Einstandspreise, auf dem Voucher werden alle nicht stornierten Rechnungen/Gutschriften zusammengezählt */
  public static CDLLF_ZEITDAUER = 90   /* Differenz zwischen ZEIT_VON und ZEIT_BIS in Stunden und Minuten */
  public static CDLLF_HOTELBEZ_VORGAENGER = 91   /* Bezeichnung der unmittelbar vorher endenden Landleistung */
  public static CDLLF_HOTELBEZ_GLEICHZEITIG = 92   /* Bezeichnung der gleichzeitig beginnenden Landleistung */
  public static CDLLF_HOTELBEZ_NACHFOLGER = 93   /* Bezeichnung der unmittelbar nachfolgenden Landleistung */
  public static CDLLF_HL_HOTELBEZ_VORGAENGER = 94   /* Bezeichnung der unmittelbar vorher endenden Land-Hauptleistung */
  public static CDLLF_HL_HOTELBEZ_GLEICHZEITIG = 95   /* Bezeichnung der gleichzeitig beginnenden Land-Hauptleistung */
  public static CDLLF_HL_HOTELBEZ_NACHFOLGER = 96   /* Bezeichnung der unmittelbar nachfolgenden Land-Hauptleistung */
  public static CDLLF_HAUPTBILD = 100  /* Hauptbild */
  public static CDLLF_BILD_VON = 101  /* Beginn des für normale Einzelbilder reservierten Bereichs */
  public static CDLLF_BILD1 = 101  /* Bild 1 (entspricht dem Hauptbild, jedoch möglicherweise in einer anderen Grösse) */
  public static CDLLF_BILD2 = 102  /* Bild 2 */
  public static CDLLF_BILD3 = 103  /* Bild 3 */
  public static CDLLF_BILD_BIS = 119  /* Ende des für normale Einzelbilder reservierten Bereichs */
  public static CDLLF_ALLE_BILDER = 121  /* Alle Bilder (inkl. Hauptbild), getrennt durch ein Tabulatorzeichen */
  public static CDLLF_WEITERE_BILDER = 122  /* Weitere Bilder, d.h. alle Bilder OHNE das Hauptbild, getrennt durch ein Tabulatorzeichen */
  public static CDLLF_BILDER_ZUM_THEMA = 123  /* Alle Bilder, deren Thema (z.Z. =Legende) mit dem nachfolgenden Begriff beginnt (keine Blanks zulässig), getrennt durch ein Tabulatorzeichen */
  public static CDLLF_LEGENDE_HAUPTBILD = 130  /* Legende zum Hauptbild */
  public static CDLLF_LEGENDE_BILD_VON = 131  /* Beginn des für die Legende für normale Einzelbilder reservierten Bereichs */
  public static CDLLF_LEGENDE_BILD1 = 131  /* Legende zu Bild 1 (entspricht dem Hauptbild) */
  public static CDLLF_LEGENDE_BILD2 = 132  /* Legende zu Bild 2 */
  public static CDLLF_LEGENDE_BILD3 = 133  /* Legende zu Bild 3 */
  public static CDLLF_LEGENDE_BILD_BIS = 149  /* Ende des für die Legende für normale Einzelbilder reservierten Bereichs */
  public static CDLLF_KAT_HAUPTBILD = 200  /* Hauptbild der Kategorie */
  public static CDLLF_KAT_BILD_VON = 201  /* Beginn des für normale Einzelbilder der Kategorie reservierten Bereichs */
  public static CDLLF_KAT_BILD1 = 201  /* Bild 1 der Kategorie (entspricht dem Hauptbild, jedoch möglicherweise in einer anderen Grösse) */
  public static CDLLF_KAT_BILD2 = 202  /* Bild 2 der Kategorie */
  public static CDLLF_KAT_BILD3 = 203  /* Bild 3 der Kategorie */
  public static CDLLF_KAT_BILD_BIS = 219  /* Ende des für normale Einzelbilder der Kategorie reservierten Bereichs */
  public static CDLLF_KAT_ALLE_BILDER = 221  /* alle Bilder der Kategorie (inkl. Hauptbild), getrennt durch ein Tabulatorzeichen */
  public static CDLLF_KAT_WEITERE_BILDER = 222  /* weitere Bilder der Kategorie, d.h. alle Bilder OHNE das Hauptbild, getrennt durch ein Tabulatorzeichen */
  public static CDLLF_KAT_LEGENDE_HAUPTBILD = 230  /* Legende zum Hauptbild der Kategorie */
  public static CDLLF_KAT_LEGENDE_BILD_VON = 231  /* Beginn des für die Legende für normale Einzelbilder der Kategorie reservierten Beeichs */
  public static CDLLF_KAT_LEGENDE_BILD1 = 231  /* Legende zu Bild 1 der Kategorie (entspricht dem Hauptbild) */
  public static CDLLF_KAT_LEGENDE_BILD2 = 232  /* Legende zu Bild 2 der Kategorie */
  public static CDLLF_KAT_LEGENDE_BILD3 = 233  /* Legende zu Bild 3 der Kategorie */
  public static CDLLF_KAT_LEGENDE_BILD_BIS = 249  /* Ende des für die Legende für normale Einzelbilder der Kategorie reservierten Bereichs */
  public static CDLLF_TAG_NR = 301  /* Tag Nr. während der Landleistung, relativ zum Reisebeginn (anfangs gemäss Beginn der Landleistung) */
  public static CDLLF_TAG_NR_ZWEITLETZTER = 302  /* Tag Nr. einen Tag vor Leistungsende, relativ zum Reisebeginn */
  public static CDLLF_TAG_NR_BIS = 303  /* Tag Nr. an der die Landleistung endet, relativ zum Reisebeginn */
  public static CDLLF_DATUM = 305  /* Datum während der Landleistung (anfangs mit Von-Datum gem. Code 31 identisch) */
  public static CDLLF_DATUM_ZWEITLETZTES = 306  /* Datum einen Tag vor Leistungsende */
  public static CDLLF_ODER_NAECHSTER_AUSFLUG = 310  /* Wort "oder", wenn sich der nächste Ausflug mit dem aktuellen zeitlich überschneidet */
  public static CDLLF_DESTTXB = 322  /* Textbaustein zur Destination */
  public static CDLLF_ORT_VON_GEOGR_LAENGE = 330  /* Längengrad des Abholortes */
  public static CDLLF_ORT_VON_GEOGR_BREITE = 331  /* Breitengrad des Abholortes */
  public static CDLLF_ORT_BIS_GEOGR_LAENGE = 335  /* Längengrad des Ankunftsortes */
  public static CDLLF_ORT_BIS_GEOGR_BREITE = 336  /* Breitengrad des Ankuntsortes */
  public static CDLLF_KAT_INDIV_BESCHR = 340  /* Individuelle Beschreibung der Kategorie gem. Textbaustein */
  public static CDLLF_ZUS_ANBIETER = 350  /* Obligatorische Zusatzleistungen, die an den Anbieter gemeldet werden sollen (aus Zusatzleistungsgruppen mit CDKURZBEZ "H4") */
  public static CDLLF_TEL2_BEM = 362  /* Bemerkungen zur zweiten Telefonnr. des Anbieters */
  public static CDLLF_FAX_BEM = 363  /* Bemerkungen zur Faxnr. des Anbieters */
  public static CDLLF_MOBILTEL_BEM = 367  /* Bemerkungen zur Mobiltelefonnr. des Anbieters */
  public static CDLLF_AG_TEL2_BEM = 372  /* Bemerkungen zur zweiten Telefonnr. des Agenten */
  public static CDLLF_AG_FAX_BEM = 373  /* Bemerkungen zur Faxnr. des Agenten */
  public static CDLLF_AG_MOBILTEL_BEM = 377  /* Bemerkungen zur Mobiltelefonnr. des Agenten */
  public static CDLLF_RESTKONT = 381  /* Restkontingent */
  public static CDLLF_BUSTAND_INKL = 382  /* Buchungsstand Inklusivtext */

  /*--------------------------------------------------------------------------*/

  public static TAB_DS_FELDER = 5306 /* Dossierfelder */
  public static CDDSF_RGART = 1    /* Rechnungsart */
  public static CDDSF_RGNR = 2    /* Rechnungsnummer */
  public static CDDSF_IHRE_REF = 3    /* Kundenreferenz */
  public static CDDSF_UNSERE_REF = 4    /* Unsere Referenz (Rechnungssachbearbeiter) */
  public static CDDSF_ABR_DAT = 5    /* Abreisedatum */
  public static CDDSF_RUECK_DAT = 6    /* Datum der Rückkehr */
  public static CDDSF_RG_DAT = 7    /* Rechnungsdatum */
  public static CDDSF_DEST = 8    /* Destination */
  public static CDDSF_ANZLG_BETR = 9    /* Anzahlungsbetrag */
  public static CDDSF_ANZLG_DAT = 10   /* Anzahlungsdatum */
  public static CDDSF_REST_BETR = 11   /* Restbetrag */
  public static CDDSF_REST_DAT = 12   /* Fälligkeit der Restzahlung */
  public static CDDSF_OBL_ZUS = 13   /* berechneter Preis für alle  obligatorischen Zuschläge */
  public static CDDSF_FAK_ZUS = 14   /* berechnet den Preis aller fakultativen Zuschläge */
  public static CDDSF_WAEH = 15   /* Währung */
  public static CDDSF_BEM = 16   /* Buchungs-Bemerkungen */
  public static CDDSF_SB_VORNAME = 17   /* Vorname des Dossier-Sachbearbeiters */
  public static CDDSF_SB_NACHNAME = 18   /* Nachname des Dossier-Sachbearbeiters */
  public static CDDSF_NOTIZ = 19   /* Dossierbemerkungen mit Laufnr <= 100 (externe Bemerkungen) */
  public static CDDSF_BESCHREIBUNG = 20   /* Dossier-Textbaustein */
  public static CDDSF_ABR_TAG = 21   /* Wochentag der Abreise */
  public static CDDSF_RUECK_TAG = 22   /* Wochentag der Rückreise */
  public static CDDSF_ANZ_PAX = 23   /* Anzahl Passagiere */
  public static CDDSF_KM_INLAND = 24   /* Kilometeranteil Inlandanteil */
  public static CDDSF_KM_AUSLAND = 25   /* Kilometeranteil Ausland */
  public static CDDSF_KM = 26   /* Kilometer insgesamt */
  public static CDDSF_SB_BEM = 27   /* Bemerkungen des Dossier-Sachbearbeiters */
  public static CDDSF_SB_TEL1 = 28   /* Telefon 1 (Privat) des Dossiersachbearbeiters */
  public static CDDSF_SB_TEL2 = 29   /* Telefon 2 (Direktdurchwahl) des Sachbearbeiters */
  public static CDDSF_SB_KOMM_MITTEL = 30   /* Elektronisches Kommunikationsmittel des Sachbearbeiters */
  public static CDDSF_SB_KOMM_NR = 31   /* Kommunikationsnummer (z.B. Faxnr.) des Sachbearbeiters  */
  public static CDDSF_SB_VIS = 32   /* Visum des Sachbearbeiters */
  public static CDDSF_SB_FAX = 33   /* Fax des Sachbearbeiters */
  public static CDDSF_SB_EMAIL = 34   /* Email des Sachbearbeiters */
  public static CDDSF_RUECKREISE_PROMPT = 35   /* Text aus Codetabelle 5013, Code 1; wird ausgefüllt, wenn ein Rückreisedatum vorhanden ist */
  public static CDDSF_NGEB_TEILNEHMER = 36   /* Teilnehmer, die eine bestimmte (Zusatz-)Leistung NICHT gebucht haben */
  public static CDDSF_VERFALLDAT = 37   /* (Optionen-)Verfalldatum */
  public static CDDSF_VERFALLZEIT = 38   /* (Optionen-)Verfallzeit */
  public static CDDSF_PNR = 39   /* Alle PNRs dieses Dossiers */
  public static CDDSF_KOSTENTR = 40   /* Kostenträger der Rechnung */
  public static CDDSF_UMSATZKONTO = 41   /* Umsatzkonto der Rechnung */
  public static CDDSF_VERANSTALTER = 42   /* Veranstalter gem. DSVERANSTALTERCODE */
  public static CDDSF_SB_MOBILTEL = 43   /* Mobiltelefonnr. des Sachbearbeiters */
  public static CDDSF_STATISTIKCODE = 44   /* Dossierstatistikcode */
  public static CDDSF_GASTRONOTIZ = 45   /* Dossiernotizen vom Typ "Gastro" (kann vom verbundenen Dossier sein) */
  public static CDDSF_HINWEIS = 46   /* Dossierhinweis */
  public static CDDSF_POSTEN_RGBETR = 47   /* Gesamtbetrag des Postens */
  public static CDDSF_POSTEN_BEZBETR = 48   /* Bereits bezahlter Betrag in diesem Posten */
  public static CDDSF_POSTEN_OFFENBETR = 49   /* Offener Betrag (des Postens oder angemahnter Betrag) */
  public static CDDSF_TEILNEHMER = 50   /* (Alle) Teilnehmer; die Codes von hier +1 bis MAX_TEILNEHMER sind für einen bestimmten Teilnehmer res. */
  public static CDDSF_MAX_TEILNEHMER = 69   /* Codes TEILNEHMER+1 bis hier sind für bestimmte Teilnehmer reserviert (Okt 2014 eff. bis 60 verwendet)...... */
  public static CDDSF_SPEZ_TEILNEHMER = 70   /* (Alle) Teilnehmer; als Textbaustein wird jedoch pro Teilnehmer der nachfolgend aufgeführte separate Textbaustein (statt TEILNEHM) verwendet */
  public static CDDSF_DOK_HINWEIS = 71   /* Dokumentenversandhinweis */
  public static CDDSF_FREMDDOSSIERNR = 72   /* Unter den Dossierkopfdaten fakultativ mögliche Fremddossiernr. */
  public static CDDSF_URSPR_RGNR = 73   /* Querverweis auf ursprüngliche Rechnungsnr, auf die sich das aktuelle Dokument bezieht (z.B. ersetzte Rechnung) */
  public static CDDSF_URSPR_RGDAT = 74   /* Querverweis auf das Erfassungsdatum der ursprünglichen Rechnung, auf das sich das aktuelle Dokument bezieht */
  public static CDDSF_ANZ_BETR_EU = 80   /* Anzahlungsbetrag, Format wie in EU-Ländern üblich (z.B. 1.2345,67) */
  public static CDDSF_REST_BETR_EU = 81   /* Restzahlungsbetrag, formatiert wie in EU-Ländern üblich ("." als 1000er-Trenn-, "," als Dez.trennzch).. */
  public static CDDSF_ANZ_BETR_11 = 82   /* Anzahlungsbetrag, 11-stellig in Rappen/Cents ohne Trennzeichen+Komma, mit Vornullen (f.AT-Zahlschein).. */
  public static CDDSF_REST_BETR_11 = 83   /* Restzahlungsbetrag, 11-stellig in Rappen/Cents ohne Trennzeichen+Komma, mit Vornullen (AT-Zahlschein) */
  public static CDDSF_REV_CHARGE = 84   /* Reverse-Charge-Hinweistext (gem. Codeart 5013, Code 220) bei Reisebüros oder Buchungsstellen im EU-Ausland */
  public static CDDSF_TEILN_ANREDE = 91   /* Anrede eines Teilnehmers *** ab Version 3.69 nur noch aus Kompatibilitätsgründen *** */
  public static CDDSF_TEILN_VORNAME = 92   /* Vorname eines Teilnehmers *** ab Version 3.69 nur noch aus Kompatibilitätsgründen *** */
  public static CDDSF_TEILN_NACHNAME = 93   /* Nachname eines Teilnehmers *** ab Version 3.69 nur noch aus Kompatibilitätsgründen *** */
  public static CDDSF_TEILN_VN_NN = 94   /* Vor- und Nachname eines Teilnehmers *** ab Version 3.69 nur noch aus Kompatibilitätsgründen *** */
  public static CDDSF_TEILN_GEBDAT = 95   /* Geburtsdatum eines Teilnehmers *** ab Version 3.69 nur noch aus Kompatibilitätsgründen *** */
  public static CDDSF_TEILN_BEM = 96   /* Bemerkung eines Teilnehmers *** ab Version 3.69 nur noch aus Kompatibilitätsgründen *** */
  public static CDDSF_TEILN_TICKETNR = 97   /* Alle Ticketnrn. eines Teilnehmers, fortlaufende Nrn. mit "/" +letzte 3 Stellen des letzten Tickets *** ab V. 3.69 nur noch Kompatiblität *** */
  public static CDDSF_TEILN_LAUFNR = 98   /* Teilnehmerlaufnr. *** ab Version 3.69 nur noch aus Kompatibilitätsgründen *** */

  /*--------------------------------------------------------------------------*/

  public static TAB_TLN_FELDER = 5307 /* Teilnehmerfelder */
  public static CDTLNF_ANREDE = 1    /* Anrede */
  public static CDTLNF_NAME = 2    /* Name */
  public static CDTLNF_VORNAME = 3    /* Vorname */
  public static CDTLNF_VORNAME_NAME = 4    /* Vor- und Nachname */
  public static CDTLNF_GEBDAT = 5    /* Geburtsdatum */
  public static CDTLNF_BEM = 6    /* Bemerkungen */
  public static CDTLNF_MOBILTEL = 8    /* Mobiltelefon (wird in 1. Priorität auf der Person gespeichert, in 2. auf Dossierpartner) */
  public static CDTLNF_EMAIL = 9    /* E-Mail (wird in 1. Priorität auf der Person gespeichert, in. 2. auf Dossierpartner) */
  public static CDTLNF_WAHL_PASS = 11   /* Pass ausgewählt */
  public static CDTLNF_WAHL_ID = 12   /* Identitätskarte ausgewählt */
  public static CDTLNF_GEBORT = 21   /* Geburtsort */
  public static CDTLNF_NATION = 22   /* Nationalität */
  public static CDTLNF_PASSNR = 23   /* Pass- oder ID-Nr. */
  public static CDTLNF_AUSST_DAT = 24   /* Aussstellungsdatum */
  public static CDTLNF_AUSST_ORT = 25   /* Aussstellungsort */
  public static CDTLNF_GUELTIG_BIS = 26   /* Gültigkeit des Passes bis */
  public static CDTLNF_BERUF = 31   /* Beruf */
  public static CDTLNF_FUNKTION = 41   /* Funktion */
  public static CDTLNF_LAUFNR = 42   /* Teilnehmerlaufnr. */
  public static CDTLNF_TICKETNR = 51   /* Alle Ticketnrn. eines Teilnehmers, fortlaufende Nrn. mit "/" +letzte 3 Stellen des letzten Tickets */
  public static CDTLNF_ZUSKRIT1 = 71   /* Zusatzkriterium 1 */
  public static CDTLNF_ZUSKRIT2 = 72   /* Zusatzkriterium 2 */
  public static CDTLNF_ZUSKRIT3 = 73   /* Zusatzkriterium 3 */
  public static CDTLNF_ZUSKRIT4 = 74   /* Zusatzkriterium 4 */
  public static CDTLNF_ZUSKRIT5 = 75   /* Zusatzkriterium 5 */
  public static CDTLNF_ZUSKRIT6 = 76   /* Zusatzkriterium 6 */
  public static CDTLNF_ZUSKRIT7 = 77   /* Zusatzkriterium 7 */
  public static CDTLNF_ZUSKRIT8 = 78   /* Zusatzkriterium 8 */

  /*--------------------------------------------------------------------------*/

  public static TAB_GSC_FELDER = 5310 /* Gutscheincodefelder */
  public static CDGSCF_NR = 31   /* Gutscheinnummer formatiert */
  public static CDGSCF_ART = 32   /* Art des Gutscheincodeprodukts */
  public static CDGSCF_LANGBEZ = 36   /* Langbezeichnung des Gutscheinprodukts */
  public static CDGSCF_WERT = 40   /* (Ursprünglicher) Wert des Gutscheins */
  public static CDGSCF_WERT_IN_WORTEN = 42   /* Wert des Gutscheins in Worten */
  public static CDGSCF_PUNKTE = 46   /* Gegenwert in Punkten (reine Anzeige, wird aus dem Gutscheinbetrag und dem Punktwert gem. ?C 5011/156 berechnet) */
  public static CDGSCF_GUELTIG_AB = 50   /* Gültig ab-Datum */
  public static CDGSCF_VERFALL = 51   /* Verfalldatum */
  public static CDGSCF_MUTDAT = 52   /* Mutationsdatum ohne Zeit */
  public static CDGSCF_MUTZEIT = 53   /* Mutationszeit */
  public static CDGSCF_REFERNZKUNDE = 80   /* Referenzkunde */
  public static CDGSCF_HINTERGRUND = 90   /* Hintergrundbild, das sowohl beim Druck als auch beim E-Mailversand aufbereitet wird */
  public static CDGSCF_HINTERGRUND_EMAIL = 91   /* Hintergrundbild, das nur beim E-Mailversand aufbereitet wird */

  /*--------------------------------------------------------------------------*/

  public static TAB_DI_FELDER = 5320 /* Dispositionsfelder (Intern werden die Codearten 5321-5329 für weitere Dispoteile verwendet) */
  public static CDDIF_NAME = 2    /* Name des Fremdfahrzeuganbieters */
  public static CDDIF_ORT = 10   /* Ort des Fremdfahrzeuganbieters */
  public static CDDIF_TITEL = 31   /* Ziel oder Zweck der Reise */
  public static CDDIF_ANZ_PAX = 32   /* Anzahl Passagiere Gesamtaufrag */
  public static CDDIF_ANZ_TEILE = 33   /* Fahrauftrag besteht aus .. separat aufbereiteten Teilen */
  public static CDDIF_ANZ_PAX_TEIL = 34   /* Anz. Teilnehmer (Fahrzeug) */
  public static CDDIF_GERAETE = 36   /* Mitzuführende Geräte */
  public static CDDIF_FANR = 40   /* Fahrauftragsnr.: entweder "DSID.RGNR (LAUFNR)" oder "TRK-DATUM" */
  public static CDDIF_FZART = 41   /* Fahrzeugart */
  public static CDDIF_FZNR = 42   /* Fahrzeugnr. bei Eigenfahrzeug bzw. Fremdobjekt bei Fremdfahrzeug */
  public static CDDIF_BEM = 43   /* Bemerkung für den Fahrer */
  public static CDDIF_KISTE = 44   /* X, wenn Fahrzeug mit Kiste */
  public static CDDIF_ANHAENGER = 45   /* X, wenn Fahrzeug mit Anhänger */
  public static CDDIF_PROGRAMM = 46   /* Kurzbeschreibung des Programmablaufs */
  public static CDDIF_HINWEIS = 47   /* Dispohinweis */
  public static CDDIF_FZAUSSTATTUNG = 48   /* Ausstattung des Fahrzeugs (nur bei Eigenfahrzeugen) */
  public static CDDIF_FZBEM = 49   /* Bemerkung des Fahrzeugs (nur bei Eigenfahrzeugen) */
  public static CDDIF_START_DAT = 50   /* Startdatum ab letztem Einsatz bzw. Garage */
  public static CDDIF_START_ZEIT = 51   /* Startzeit */
  public static CDDIF_BEREIT_DAT = 53   /* Bereitstellungsdatum am Abreiseort */
  public static CDDIF_BEREIT_ZEIT = 54   /* Bereitstellungszeit */
  public static CDDIF_ABF_DAT = 56   /* Abfahrtsdatum */
  public static CDDIF_ABF_ZEIT = 57   /* Abfahrtszeit */
  public static CDDIF_ABF_TAG = 58   /* Abfahrtstag */
  public static CDDIF_ABF_ORT = 59   /* Abfahrtsort */
  public static CDDIF_VIA_ANK_ZEIT = 61   /* Ankunftszeit am Via-Ort */
  public static CDDIF_VIA_ORT = 63   /* Via-Ort */
  public static CDDIF_VIA_ABF_ZEIT = 65   /* Abfahrtszeit am Via-Ort */
  public static CDDIF_BIS_DAT = 70   /* Enddatum */
  public static CDDIF_BIS_ZEIT = 71   /* Endzeit */
  public static CDDIF_BIS_TAG = 72   /* Endtag */
  public static CDDIF_BIS_ORT = 73   /* Ort, an dem der Kundenauftrag endet */
  public static CDDIF_ZURUECK_DAT = 74   /* Datum, ab wann Fahrzeug für nächsten Auftrag verfügbar ist */
  public static CDDIF_ZURUECK_ZEIT = 75   /* Zeit der Rückkehr */
  public static CDDIF_CHAUFFEUR1 = 80   /* 1. Fahrer (Vorname und Name) */
  public static CDDIF_CHAUFFEUR1_TEL1 = 81   /* Telefonnummer 1 (privat) des 1. Fahrers */
  public static CDDIF_CHAUFFEUR1_MOBILTEL = 83   /* Mobiltelefon des ersten Fahrers */
  public static CDDIF_CHAUFFEUR2 = 90   /* 2. Fahrer */
  public static CDDIF_CHAUFFEUR2_TEL1 = 91   /* Telefonnummer 1 (privat) des 2. Fahrers */
  public static CDDIF_CHAUFFEUR2_MOBILTEL = 93   /* Mobiltelefon des 2. Fahrers */
  public static CDDIF_REISELEITER_LOHNFUNK = 96   /* Lohnfunktion des Reiseleiters */
  public static CDDIF_REISELEITER_MOBILTEL = 97   /* Mobiltelefonnr. des Reiseleiters */
  public static CDDIF_REISELEITER = 98   /* Reiseleiter */
  public static CDDIF_REISELEITER_TEL1 = 99   /* Telefonnummer 1 (privat) des Reiseleiters */

  /*--------------------------------------------------------------------------*/

  public static TAB_DI_FELDER_MAX = 5329 /* Für die Dispoteile 2-9 ist analog Codeart 5320 bis hierhin alles reserviert (kein Eintrag in Codetab) */

  /*--------------------------------------------------------------------------*/

  public static TAB_BST_FELDER = 5340 /* Buchungsstellenfelder */
  public static CDBSTF_NAME = 3    /* Name einer allfälligen Buchungsstelle */
  public static CDBSTF_ORT = 8    /* Ort einer Buchungsstelle */
  public static CDBSTF_TEL2 = 12   /* 2. Telefonnr. (Geschäft) einer Buchungsstelle */
  public static CDBSTF_EMAIL = 14   /* E-Mailadresse der Buchungsstelle */

  /*--------------------------------------------------------------------------*/

  public static TAB_MAND_FELDER = 5350 /* Felder für den angemeldeten Mandanten */
  public static CDMANDF_ANREDE = 2    /* Anrede */
  public static CDMANDF_NAME = 3    /* (Nach-)Name */
  public static CDMANDF_VORNAME = 4    /* Vorname */
  public static CDMANDF_VN_NN = 5    /* Vorname und Nachname */
  public static CDMANDF_ADRZUS = 6    /* Adresszusatz */
  public static CDMANDF_STRASSE = 7    /* Strasse */
  public static CDMANDF_PLZ_ORT = 8    /* Land, PLZ und Ort */
  public static CDMANDF_ORT = 10   /* Ort */
  public static CDMANDF_TEL1 = 11   /* Erste Telefonnummer */
  public static CDMANDF_TEL2 = 12   /* 2. Telefonnummer */
  public static CDMANDF_FAX = 13   /* Fax */
  public static CDMANDF_EMAIL = 14   /* Email */
  public static CDMANDF_KOMM_MITTEL = 15   /* Art des Kommunikationsmittels */
  public static CDMANDF_KOMM_NR = 16   /* Kommunikationsnummer (z.B. Faxnr.) */
  public static CDMANDF_ADRZUS_STRASSE = 17   /* Adresszusatz oder Strasse, wenn Adr.zus. leer ist */
  public static CDMANDF_STRASSE_LEER = 18   /* Strasse oder leer, wenn Adr.zus. leer ist */
  public static CDMANDF_STRASSE_PLZ_ORT = 19   /* Strasse oder Land, PLZ und Ort, wenn Adr.zus. leer ist */
  public static CDMANDF_PLZ_ORT_LEER = 20   /* Land, PLZ und Ort oder leer, wenn Adr.zus. leer ist */
  public static CDMANDF_MOBILTEL = 29   /* Mobiltelefon */
  public static CDMANDF_HOMEPAGE = 30   /* Homepage */
  public static CDMANDF_MWSTNR = 31   /* Mehrwertsteuernummer */
  public static CDMANDF_BANKKONTONR = 32   /* Bankkontonr. */
  public static CDMANDF_BCNR = 33   /* Bankenclearingnr. */
  public static CDMANDF_BIC = 34   /* Bank Identifier Code (BIC) für Auslandzahlungen */
  public static CDMANDF_LOGO_ELEKTRONISCH = 35   /* Das Veranstalterlogo wird bei Aufbereitung als Datei (Email, Fax, RTF-Export, PDF) hier eingesetzt */
  public static CDMANDF_KONTO_LAUTEND_AUF = 36   /* Name, auf den das (Bank-)Konto lautet gem. 5013/200 */
  public static CDMANDF_ZUSKRIT1 = 41   /* Zusatzkriterium 1 des Mandanten */
  public static CDMANDF_ZUSKRIT2 = 42   /* Zusatzkriterium 2 des Mandanten */
  public static CDMANDF_ZUSKRIT3 = 43   /* Zusatzkriterium 3 des Mandanten */
  public static CDMANDF_ZUSKRIT4 = 44   /* Zusatzkriterium 4 des Mandanten */
  public static CDMANDF_ZUSKRIT5 = 45   /* Zusatzkriterium 5 des Mandanten */
  public static CDMANDF_ZUSKRIT6 = 46   /* Zusatzkriterium 6 des Mandanten */
  public static CDMANDF_BANK_ANREDE = 52   /* Bank-Anrede */
  public static CDMANDF_BANK_NAME = 53   /* Bank-Name */
  public static CDMANDF_BANK_VORNAME = 54   /* Bank-Vorname */
  public static CDMANDF_BANK_VN_NN = 55   /* Bank-Vor- und Nachname */
  public static CDMANDF_BANK_ADRZUS = 56   /* Bank-Adresszusatz */
  public static CDMANDF_BANK_STRASSE = 57   /* Bank-Strasse */
  public static CDMANDF_BANK_PLZ_ORT = 58   /* Bank-Land, PLZ und Ort */
  public static CDMANDF_BANK_ORT = 60   /* Bank-Ort */
  public static CDMANDF_BANK_ADRZUS_STRASSE = 67   /* Bank-Adresszusatz oder Strasse, wenn Adr.zus. leer */
  public static CDMANDF_BANK_STRASSE_LEER = 68   /* Bank-Strasse oder leer, wenn Adr.zus. leer ist */
  public static CDMANDF_BANK_STRASSE_PLZ_ORT = 69   /* Bank-Strasse oder Land, PLZ, Ort, wenn Adr.zus. leer */
  public static CDMANDF_BANK_PLZ_ORT_LEER = 70   /* Bank-Land, PLZ, Ort oder leer, wenn Adr.zus. leer ist */
  public static CDMANDF_AKT_SB_VIS = 90   /* Visum des angemeldeten Sachbearbeiters */
  public static CDMANDF_AKT_SB_NAME = 91   /* Nachname des angemeldeten Sachbearbeiters */
  public static CDMANDF_AKT_SB_VORNAME = 92   /* Vorname des angemeldeten Sachbearbeiters */
  public static CDMANDF_AKT_SB_BEM = 94   /* Bemerkungsfeld des aktuellen Sachbearbeiters */
  public static CDMANDF_AKT_SB_TEL1 = 95   /* Telefon 1 des angemeldeten Sachbearbeiters */
  public static CDMANDF_AKT_SB_TEL2 = 96   /* Telefon 2 des angemeldeten Sachbearbeiters */
  public static CDMANDF_AKT_SB_FAX = 97   /* Faxnr. des angemeldeten Sachbearbeiters */
  public static CDMANDF_AKT_SB_EMAIL = 98   /* Email-Adresse des angemeldeten Sachbearbeiters */
  public static CDMANDF_AKT_SB_MOBILTEL = 99   /* Mobiltelefonnr. des angemeldeten Sachbearbeiters */

  /*--------------------------------------------------------------------------*/

  public static TAB_WMAT_FELDER = 5360 /* Werbematerialfelder */
  public static CDWMATF_BEZ = 2    /* Bezeichnung */
  public static CDWMATF_BESCHR = 3    /* Beschreibung */
  public static CDWMATF_FAK_ANZ_BEZ = 11   /* Anzahl (fak.) und Bezeichnung */
  public static CDWMATF_TEXT = 50   /* Text */
  public static CDWMATF_BEILAGEN = 61   /* Beilagen */

  /*--------------------------------------------------------------------------*/

  public static TAB_FUNKTIONS_FELDER = 5380 /* Felder für Spezialfunktionen in der Textverarbeitung */
  public static CDFUNKF_TEXTBAUSTEIN = 1    /* Freier Textbaustein */
  public static CDFUNKF_NEUE_SEITE = 2    /* Seitenvorschub */
  public static CDFUNKF_HANDEINGABE = 3    /* Haltepunkt für Handeingabe */
  public static CDFUNKF_TABELLE = 4    /* Tabelle */
  public static CDFUNKF_ZEILE_LOESCHEN_1 = 5    /* Zeile löschen, wenn das vorgängige Feld auf dieser Zeile leer ist */
  public static CDFUNKF_NAECHSTER_TAG = 6    /* Setzt den Tag innerhalb einer Landleistung oder eines Transports einen Tag weiter */
  public static CDFUNKF_NEUE_ZEILE = 7    /* Einfügen eines Zeilenvorschubs (v.a. für den Dann- oder Sonst-Teil von Wenn-Bedingungen) */
  public static CDFUNKF_KOPF = 8    /* Kopfdaten */
  public static CDFUNKF_NEUE_SPALTE = 9    /* Manueller Spaltenwechsel bei mehrspaltiger Darstellungen */
  public static CDFUNKF_PREISTAB = 10   /* Preistabelle */
  public static CDFUNKF_ZAHLUNGSBED = 11   /* Textbaustein für die Zahlungsbedingungen */
  public static CDFUNKF_ZAHLUNGSART = 12   /* Textbaustein für die Zahlungsart */
  public static CDFUNKF_EZ = 13   /* Einzahlungsschein */
  public static CDFUNKF_CHECK = 14   /* Check-Formular */
  public static CDFUNKF_HINREISE = 15   /* Textbaustein(e) für die Hinreise(n) */
  public static CDFUNKF_RUECKREISE = 16   /* Textbaustein(e) für die Rückreise(n) */
  public static CDFUNKF_KAT = 17   /* Textbausteine für die Landleistungs-Kategorien */
  public static CDFUNKF_TEIL = 18   /* Neuen Offert- bzw. Auftragsteil generieren */
  public static CDFUNKF_NGEB_ZUS = 19   /* Textbausteine für nicht gebuchte, fakultative Zusatzleistung(en) */
  public static CDFUNKF_REISEPROG = 20   /* Reiseprogramm */
  public static CDFUNKF_TICKET = 21   /* Ticketteil */
  public static CDFUNKF_TICKET_IATA = 22   /* Ticketteil im IATA-Format */
  public static CDFUNKF_STRECKE = 23   /* Textbaustein(e) für die Transportstrecke(n) */
  public static CDFUNKF_GUTSCHEINE = 24   /* Textbaustein(e) für die Gutscheine (Preiszeilen mit PZLCODE 4) */
  public static CDFUNKF_MITGLIEDSCHAFT = 25   /* Textbaustein für die Mitgliedschaft (MGACODE <> 0) */
  public static CDFUNKF_KEINE_MITGLIEDSCHAFT = 26   /* Textbaustein, wenn keine Mitgliedschaft (MGACODE 0) ausgewählt */
  public static CDFUNKF_DOSSIERS = 27   /* Verbundene Dossiers bei Mahnungen aufbereiten */
  public static CDFUNKF_OBLIG_ZUS = 28   /* Textbausteine für obligatorische Zusatzleistungen */
  public static CDFUNKF_NEUE_MITGLIEDSCHAFT = 29   /* Textbaustein für eine neue, noch pendente Mitgliedschaft */
  public static CDFUNKF_ANBIETER_SPR = 30   /* Wechsel zur Sprache des Anbieters oder Englisch, wenn kein Anbieter vorhanden ist */
  public static CDFUNKF_KUNDEN_SPR = 31   /* Wechsel zur Kundensprache */
  public static CDFUNKF_FAK_ZUS = 32   /* Textbausteine für fakultative Zusatzleistungen */
  public static CDFUNKF_LLADR = 34   /* Textbaustein(e) für die Landleistungsadresse(n) */
  public static CDFUNKF_GUTSCHEINCODES = 35   /* Textbausteine für Gutscheincode-Produkte (Gutscheincode-Abgabe) */
  public static CDFUNKF_LLADR_ANBIETER = 36   /* Textbaustein(e) für die Landleistungsadresse(n) von Anbietern */
  public static CDFUNKF_LLADR_AGENTEN = 37   /* Textbaustein(e) für die Landleistungsadresse(n) von Agenten */
  public static CDFUNKF_KOPIE = 40   /* Durchschlagstext gem. Codeeintrag */
  public static CDFUNKF_ENDE_KOPIE = 41   /* Druckende bei Kopie */
  public static CDFUNKF_ENDE_FAX = 42   /* Druckende bei Fax */
  public static CDFUNKF_ZEILE_AB_HIER_NUR_KOPIE = 43   /* Druck den Rest der Zeile nur auf Kopien aus */
  public static CDFUNKF_STORNO = 45   /* Text Storno, falls es sich um einen Storno handelt */
  public static CDFUNKF_KAT1_VORDEF = 50   /* 1. Hotelkategorie, die aus einem vordefinierten Produkt gebucht wurde (VPRHERKUNFTSCODE NULL) */
  public static CDFUNKF_KAT_AUS_KONTINGENT = 51   /* Textbausteine für die Landleistungs-Kategorien, welche aus dem Kontingent gebucht wurden (v.a. für Requesttexte) */
  public static CDFUNKF_AUSFLUEGE_GEBUCHT = 52   /* Liste gebuchter Ausflüge (zieht Textbaustein "AUSFLGEB" ein, mit Tabelle aller gebuchter Ausflüge) */
  public static CDFUNKF_AUSFLUEGE_NICHT_GEBUCHT = 53   /* Liste nicht gebuchter Ausflüge (zieht Textbaustein "AUSFLNGE" ein, mit Tabelle aller nicht gebuchter Ausflüge) */
  public static CDFUNKF_KAT_BUSTAND = 54   /* Hotelkategorien Buchungsstand */
  public static CDFUNKF_WENN = 55   /* Wenn mindestens eines der folgenden Felder ausgefüllt ist ... */
  public static CDFUNKF_DANN = 57   /* Wenn die vorgängige Bedingung erfüllt ist, den nachfolgenden Text anzeigen */
  public static CDFUNKF_SONST = 58   /* Wenn die vorgängige Bedingung NICHT erfüllt ist, den nachfolgenden Text anzeigen */
  public static CDFUNKF_WENN_ENDE = 59   /* Ende des Wenn-Dann-Sonst-Blocks */
  public static CDFUNKF_POS1 = 61   /* Vertikale Position 1 auf der Seite */
  public static CDFUNKF_POS2 = 62   /* Vertikale Position 2 auf der Seite */
  public static CDFUNKF_POS3 = 63   /* Vertikale Position 3 auf der Seite */
  public static CDFUNKF_SCHACHT_DECKBLATT = 70   /* Druckerschacht nur für die aktuelle Seite verwenden */
  public static CDFUNKF_SCHACHT1 = 71   /* Druckerschacht 1 verwenden */
  public static CDFUNKF_SCHACHT2 = 72   /* Druckerschacht 2 verwenden */
  public static CDFUNKF_SCHACHT3 = 73   /* Druckerschacht 3 verwenden */
  public static CDFUNKF_SCHACHT4 = 74   /* Druckerschacht 4 verwenden */
  public static CDFUNKF_SCHACHT5 = 75   /* Druckerschacht 5 verwenden */
  public static CDFUNKF_TITEL = 80   /* Offert- bzw. Auftragstitel erstellen */
  public static CDFUNKF_MIN_TITEL = 81   /* Erster möglicher Offert- bzw. Auftragstitel */
  public static CDFUNKF_MAX_TITEL = 89   /* Letzter möglicherOffert- bzw. Auftragstitel */
  public static CDFUNKF_ABSCHL_TEIL = 90   /* Beginn des Abschlussteils */
  public static CDFUNKF_MIN_PREISTEIL = 91   /* Erster Wert für Preistabellenteile */
  public static CDFUNKF_MAX_PREISTEIL = 99   /* Höchstmöglicher Wert für Preistabellen */
  public static CDFUNKF_AUSWAHL_TXT1 = 101  /* Auswahl Texte 1 */
  public static CDFUNKF_MIN_AUSWAHL_TXT = 101  /* Erster für die Auswahl von Texten aus allgemeinen Zusatzkriterien (ZKRID 5401 uff.) reservierter Code */
  public static CDFUNKF_AUSWAHL_TXT2 = 102  /* Auswahl Texte 2 */
  public static CDFUNKF_AUSWAHL_TXT3 = 103  /* Auswahl Texte 3 */
  public static CDFUNKF_AUSWAHL_TXT4 = 104  /* Auswahl Texte 4 */
  public static CDFUNKF_AUSWAHL_TXT5 = 105  /* Auswahl Texte 5 */
  public static CDFUNKF_AUSWAHL_TXT6 = 106  /* Auswahl Texte 6 */
  public static CDFUNKF_AUSWAHL_TXT7 = 107  /* Auswahl Texte 7 */
  public static CDFUNKF_AUSWAHL_TXT8 = 108  /* Auswahl Texte 8 */
  public static CDFUNKF_AUSWAHL_TXT9 = 109  /* Auswahl Texte 9 */
  public static CDFUNKF_MAX_AUSWAHL_TXT = 110  /* Letzter für die Auswahl von Texten aus allgemeinen Zusatzkriterien reservierter Code */
  public static CDFUNKF_AUSWAHL_TXT10 = 110  /* Auswahl Texte 10 */
  public static CDFUNKF_AUSWAHL_TXTZL1 = 121  /* Auswahl Texte 1, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_MIN_AUSWAHL_TXTZL = 121  /* Erster für die Auswahl von Texten aus allgemeinen Zusatzkriterien (ZKRID 5401 uff.) reservierter Code, pro Tabellenzeile ein Text */
  public static CDFUNKF_AUSWAHL_TXTZL2 = 122  /* Auswahl Texte 2, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_AUSWAHL_TXTZL3 = 123  /* Auswahl Texte 3, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_AUSWAHL_TXTZL4 = 124  /* Auswahl Texte 4, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_AUSWAHL_TXTZL5 = 125  /* Auswahl Texte 5, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_AUSWAHL_TXTZL6 = 126  /* Auswahl Texte 6, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_AUSWAHL_TXTZL7 = 127  /* Auswahl Texte 7, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_AUSWAHL_TXTZL8 = 128  /* Auswahl Texte 8, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_AUSWAHL_TXTZL9 = 129  /* Auswahl Texte 8, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_AUSWAHL_TXTZL10 = 130  /* Auswahl Texte 10, jeder Wert wird in einer separaten Tabellenzeile aufbereitet */
  public static CDFUNKF_MAX_AUSWAHL_TXTZL = 130  /* Letzter für die Auswahl von Texten aus allgemeinen Zusatzkriterien reservierter Code, pro Tabellenzeile ein Text */
  public static CDFUNKF_NAECHSTES_ZEICHEN_LOESCHEN = 140  /* Nächstes Zeichen löschen */
  public static CDFUNKF_TRF_VON_ART = 150  /* Transportfelder von Art */

  /*--------------------------------------------------------------------------*/

  public static TAB_ESR_FELDER = 5390 /* Spezialfelder für Ein- und Auszahlungsscheine */
  public static CDESRF_QRIBAN_ODER_IBAN = 10   /* QR-IBAN oder IBAN */
  public static CDESRF_QRREF_ODER_CREDITOR_REF = 13   /* QR-Referenz oder Creditor Reference */
  public static CDESRF_QRCODE_MIT_BETRAG = 16   /* QR-Code mit Betrag */
  public static CDESRF_QRCODE_OHNE_BETRAG = 17   /* QR-Code ohne Betrag */
  public static CDESRF_WAEH = 19   /* Währung */
  public static CDESRF_BETRAG = 20   /* Anzahlungs-, Restzahlungs- bzw. Buchungsbetrag (2 Blanks zwischen Franken und Rappen) */
  public static CDESRF_KTONR = 21   /* Teilnehmerkontonummer (PC-Konto bzw. Checkkonto) */
  public static CDESRF_BETRAG_IN_WORTEN = 22   /* Betrag in Worten */
  public static CDESRF_TAGESDATUM = 23   /* Tagesdatum */
  public static CDESRF_LAUFNR = 24   /* Laufnr (z.B. BUABLAUFNR2 für VASR) */
  public static CDESRF_TEXTID = 25   /* Text-ID (Name des Textbausteins ohne fixen Stammteil) */
  public static CDESRF_BETRAG_GESPERRT = 26   /* wie Betrag, jedoch zwischen den Ziffern je ein Blank (für den orangen ESR+) */
  public static CDESRF_CODIERZL = 31   /* Codierzeile ohne Teilnehmer und Betrag */
  public static CDESRF_CODIERZL_TLN = 32   /* Codierzeile mit Teilnehmer-Kennung */
  public static CDESRF_CODIERZL_BETR = 33   /* Codierzeile mit Betrag */
  public static CDESRF_CODIERZL_TLN_BETR = 34   /* Codierzeile mit Teilnehmerkennung und Betrag */
  public static CDESRF_CODIERZL_TEIL = 40   /* Basis der Codierzeilen-Teile (für die einzelnen Teile sind +1 bis CODIERZL_ENDE reserviert) */
  public static CDESRF_CODIERZL1 = 41   /* Teil 1 der Codierzeile */
  public static CDESRF_CODIERZL2 = 42   /* Teil 2 der Codierzeile */
  public static CDESRF_CODIERZL3 = 43   /* Teil 3 der Codierzeile */
  public static CDESRF_CODIERZL4 = 44   /* Teil 4 der Codierzeile */
  public static CDESRF_CODIERZL5 = 45   /* Teil 5 der Codierzeile */
  public static CDESRF_CODIERZL6 = 46   /* Teil 6 der Codierzeile */
  public static CDESRF_CODIERZL_ENDE = 49   /* Letztmöglicher Define für die Codierzeilenteile */
  public static CDESRF_BAZL1 = 50   /* Deutsche Codierzeile, Teil 1 */
  public static CDESRF_BAZL2 = 51   /* Deutsche Codierzeile, Teil 2 */
  public static CDESRF_ASR = 60   /* ASR-Codierzeile */
  public static CDESRF_ASR_REF = 61   /* ASR-Referenznr. */
  public static CDESRF_ASR_FRIST = 62   /* Einlösefrist des ASR's */
  public static CDESRF_PZL_TEXT = 70   /* Preiszeilentext */
  public static CDESRF_PZL_TEXTNR = 71   /* Erste Nummer des Preiszeilentextes (Gutscheinnr.) */
  public static CDESRF_PZL_TEXT_O_NR = 72   /* Text der Preiszeile nach der ersten Nummer und nachfolgenden Leerzeichen  */
  public static CDESRF_PZL_ANZAHL = 73   /* Anzahl der Preiszeile (Dezimalstellen nur wenn nötig aufbereiten) */
  public static CDESRF_PZL_ANSATZ = 75   /* Ansatz der Preiszeile */
  public static CDESRF_PZL_BETRAG = 76   /* Betrag der Preiszeile */
  public static CDESRF_PZL_BETRAG_IN_WORTEN = 77   /* Betrag der Preiszeile in Worten */

  /*--------------------------------------------------------------------------*/

  public static TAB_FELDGRUPPENCODE_RESERVE = 5399 /* Reserviert bis hierhin für die Feldgruppen */

  /*--------------------------------------------------------------------------*/

  public static TAB_ALLG_ZUSKRIT_VON = 5401 /* Erstes für allgemeine Textauswahlen (wie Textbausteine) reserviertes Zusatzkriterium */

  /*--------------------------------------------------------------------------*/

  public static TAB_ALLG_ZUSKRIT_BIS = 5410 /* Letztes für allgemeine Textauswahlen reserviertes Zusatzkriterium */

  /*--------------------------------------------------------------------------*/

  public static TAB_GESTALTUNGSELEMENT_VON = 5501 /* Erster für allgemeine Gestaltungselemente reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_GESTALTUNGSELEMENT_BIS = 5599 /* Letzter für allgemeine Gestaltungselemente reservierter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSWERTGRUPPENCODE = 5800 /* Auswertungsgruppe (nur Codes 1-9 erlaubt) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ACCESS_BEGINN = 5800 /* Erste fürs Access reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB____AUSWERTUNGEN___ = 5800 /* === Beginn der Auswertungs-Codearten (Access und List & Label) === */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSWERT_UNTERMENU_VON = 5801 /* Erster für Untermenus reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSWERT_UNTERMENU_BIS = 5809 /* Letzter für Untermenus reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_STEUERPARAM = 5811 /* Steuerungsparameter für die Auswertungen (Access und List- & Label) */
  public static CDAWST_MANDANT = 1    /* Mandantenkürzel für indiv. Views, Mandanten-Logo usw. (Platzhalter "#" wird durch die Mandantennr. ersetzt) */
  public static CDAWST_MAND_WECHSEL = 10   /* Ab dieser Berechtigungsstufe ist kein Mandantenwechsel mehr erlaubt; "M" davor=Wechsel über alle Mand. erlaubt (Default gleicher CDMandant) */
  public static CDAWST_TDLISTEN_TAGE = 11   /* Anzahl Tage, die Listen im Access nach Umstellung angezeigt werden, wenn sie nur noch im TD ausgeführt werden dürfen (0-9999; Default 9999) */
  public static CDAWST_PATCH_ZEICHEN = 900  /* 1-5 Zeichen Hex-Code, z.B. 0A=Linefeed, die hinter Namen+ Bemerkungen auf Flug-, Hotel- + Ein-/Ausstiegslisten angezeigt werden (nur Access) */

  /*--------------------------------------------------------------------------*/

  public static TAB_ACCESS_VERSION_ALT = 5812 /* Wird nicht mehr verwendet, neu Codeart 5920 */

  /*--------------------------------------------------------------------------*/

  public static TAB_PREISZEILENSTATISTIK = 5813 /* Regeln für die Preiszeilenstatistik */
  public static CDPZLST_EP_UEBERM_VON = 9900 /* Beginn des Bereichs, der (auch) für Einstandspreisübermittlungen auf Ebene Standardreise verwendet wird */
  public static CDPZLST_EP_UEBERM_UEBRIGE = 9900 /* Eintrag, der bei Einstandspreisübermittlungen für übrige, nicht zuordnungsbaren Leistungen verwendet wird */
  public static CDPZLST_EP_UEBERM_BIS = 9909 /* Ende des Bereichs, der (auch) für Einstandspreisübermittlungen auf Ebene Standardreise verwendet wird */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_ZUSATZINFO = 5814 /* Zusatzinformationen für spezielle Auswertungen */
  public static CDAWZ_MAND_ADRZEILE4 = 1    /* 4. Adresszeile für Flug- und Hotelliste an Stelle Land, PLZ, Ort (z.Z. nur Access) */
  public static CDAWZ_MAND_KURZBEZ = 2    /* Kurzbezeichnung des Mandanten, die auf vielen Listen oben links angezeigt wird (an Stelle von Name, Ort) -> gem. Login-Mandant! */
  public static CDAWZ_DEST_SORT = 10   /* Formel im Access-SQL-Format für die Sortierung der Destinationen bei Streckenauslastung und Transportverfügbarkeit */
  public static CDAWZ_FLS_BUKLASSEN = 11   /* Gruppen von je 1-5 Buchungsklassen für die Streckenauslastung mit Option -B, mehrere Gruppen durch ";" getrennt */
  public static CDAWZ_TRV_HAUPTDEST = 12   /* Hauptflughäfen für die Transportverfügbarkeit mit Option -H (min. 2, getrennt durch ",") */
  public static CDAWZ_LOHNABR_KONTEN = 20   /* Konten für die Lohnabrechnungen (Lohn, Betrag, Nachtzulage, Spesen, Trinkgeldersatz), durch Kommas getrennt */
  public static CDAWZ_LOHNAUFR_FAKT = 21   /* Lohnaufrechnungsfaktor, falls in Dispo der Nettobetrag eingegeben wird, aber auf der Lohnabrechnung der Bruttobetrag ausgewiesen werden muss */
  public static CDAWZ_HOTAA_KAT_GRUPPEN = 30   /* Max. 3 Kategoriengruppen für die Hotelauslastung Ankünfte bei gesetzter Option -T jeweils mit Kurz- und Langbez. (getrennt durch Kommas) */
  public static CDAWZ_BDD_ZENTRALE = 40   /* Kundennr. der eigenen Zentrale an die BDD-Belastungen anders übermittelt werden (sep. Export u/o interne Verrechnung) */

  /*--------------------------------------------------------------------------*/

  public static TAB_HSZ_ZUSATZLEISTUNGEN = 5815 /* Gruppierte Zus.leist. für die Hotelstatistik Zusatzleistungen (pro Eintrag max. 7 durch Kommas getrennt, sprachabh. für Anzeige in Auswahl) */

  /*--------------------------------------------------------------------------*/

  public static TAB_KASSAJOURNAL = 5816 /* Steuerparameter für das Kassajournal (Geldwechsel) */

  /*--------------------------------------------------------------------------*/

  public static TAB_PREISZEILENSTATISTIK2 = 5817 /* Steuerparameter für die Preiszeilenstatistik 2 */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_ZWECK = 5819 /* Auswertungszweck für individuelle Auswertungen */
  public static CDKD_VON = 100  /* Erster für Kunden reservierter Bereich */
  public static CDKD_BIS = 199  /* Letzter für Kunden reservierter Bereich */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_GRUPPIERUNG = 5820 /* Mögliche Feldfunktionen für die Gruppierung von individuellen Auswertungen, das #-Zeichen wird duch den jeweiligen Feldnamen ersetzt */
  public static CDEINZELN = 0    /* Jeder Wert separat */
  public static CDMIN_TEXT = 100  /* Erste für Zeichenketten reservierte Gruppierungsfunktion */
  public static CDMAX_TEXT = 199  /* Letzte für Zeichenketten reservierte Gruppierungsfunktion */
  public static CDMIN_ZAHL = 200  /* Erste für Zahlen reservierte Gruppierungsfunktion */
  public static CDMAX_ZAHL = 299  /* Letzte für Zahlen reservierte Gruppierungsfunktion */
  public static CDMIN_DATUM = 300  /* Erste für Datumfelder reservierte Gruppierungsfunktion */
  public static CDMAX_DATUM = 301  /* Letzte für Datumfelder reservierte Gruppierungsfunktion */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_FELDER_KD = 5821 /* Kundenauswertungsfelder */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_FELDER_VON = 5821 /* Erste für Auswertungsfelder pro Partnerart reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_FELDER_ANB = 5822 /* Anbieterauswertungsfelder */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_FELDER_BIS = 5829 /* Letzte für Auswertungsfelder pro Partnerart reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB_AVPRIOCODE = 5830 /* Auswertungsvorlagen-Priorität */
  public static CDAVPRIO_KEINE = 0    /* NULL=keine spezielle Priorität */
  public static CDAVPRIO_DEFAULT = 1

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_DATUM = 5831 /* Vordefinierte Datumsvariablen für Vergleiche mit "=" und "<>" */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_DATUM_VON = 5832 /* Vordefinierte Datumsvariablen für "<" oder ">=" sowie den ersten Wert bei "zwischen" bzw. "nicht zwischen" (Beginn-Daten) */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_DATUM_BIS = 5833 /* Vordefinierte Datumsvariablen für ">" oder "<=" sowie den zwiten Wert bei "zwischen" bzw. "nicht zwischen" (End-Daten) */

  /*--------------------------------------------------------------------------*/

  public static TAB_AUSW_TAB = 5835 /* Tabellenbez. für die Codes der Felder unter Codeart 5821-5829. CDBEZ sprachunabhängig=kommagetrennte Auflistung der 2-stelligen Tab.codes */

  /*--------------------------------------------------------------------------*/

  public static TAB_ACCESSSTART = 5850 /* Parameter für den automatischen Start von Accessauswertungen aus dem Programm heraus */
  public static CDACC_TEILNEHMERLISTE = 2100 /* Teilnehmerliste Standardreise */
  public static CDACC_TRANSPORTLISTE = 2300 /* Transportliste (Flugliste) */
  public static CDACC_EINSTIEGSLISTE = 2350 /* Ein-/Ausstiegsliste */
  public static CDACC_HOTELLISTE = 2500 /* Hotelliste */

  /*--------------------------------------------------------------------------*/

  public static TAB_ACCESS_ENDE = 5899 /* Letzte fürs Access reservierte Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB____VERSIONEN___ = 5900 /* === Beginn der für die Tourdata-Versionen verwendeten Codearten (nur in der Tournet-Datenbank) === */

  /*--------------------------------------------------------------------------*/

  public static TAB_VGPROGCODE = 5900 /* Programm, in dem die Änderung/Erweiterung durchgeführt wurde (datür reservierte Codeart=5900+Code) */
  public static CDVGP_TOURDATA = 10   /* Programm (Tourdata 3) */
  public static CDVGP_ACCESS = 20   /* Access-Auswertungen (Access 97, 2000, XP, 2003, 2007, 2010, 2013) */
  public static CDVGP_LIST_LABEL = 21   /* List- & Label-Auswertungen */
  public static CDVGP_INTERNET = 30   /* Tournet, Reisemarkt, Homepage */
  public static CDVGP_APP = 40   /* Apps (Kunden-, Reiseleiter-, Chauffeur-App) */
  public static CDVGP_SETUP = 70   /* Setup-Programm */

  /*--------------------------------------------------------------------------*/

  public static TAB_VGAENDERUNGSART = 5901 /* Art der vorgenommenen Programmänderung (gespeichert wird die CDKURZBEZ) */

  /*--------------------------------------------------------------------------*/

  public static TAB_VGKATEGORIE = 5902 /* Von der Änderung betroffene Kundenkategorie (gespeichert wird die CDKURZBEZ) */

  /*--------------------------------------------------------------------------*/

  public static TAB_VGPROGCODE_RESERVE = 5999 /* Für die Versionsgeschichte sind die bis Codearten bis hierhin reserviert */

  /*--------------------------------------------------------------------------*/

  public static TAB____UMFRAGE___ = 6000 /* === Beginn der Umfrage-Codearten === */

  /*--------------------------------------------------------------------------*/

  public static TAB_UMFID = 6000 /* Umfragebezeichnung */

  /*--------------------------------------------------------------------------*/

  public static TAB_UMFCODE = 6001 /* Art der Umfrage */
  public static CDUMF_BUCHUNG = 1    /* Buchungsbezogene Umfrage */
  public static CDUMF_KUNDE = 10   /* Generelle Kundenumfrage */
  public static CDUMF_ANONYM = 90   /* Anonyme Umfrage */
  public static CDUMF_AUSWERTUNG = 100  /* Auswertungszusammenzug */

  /*--------------------------------------------------------------------------*/

  public static TAB_UMFZNUMCODE = 6002 /* Art, wie die Frage bzw. Fragengruppe innerhalb der Umfrage nummeriert werden soll */
  public static CDUMFZN_KEINE = 0    /* nicht nummeriert */
  public static CDUMFZN_ERSTE = 1    /* Erste Nummerierungsebene (Zahlen) */
  public static CDUMFZN_ZWEITE = 2    /* Zweite Nummerierungsebene (Zahlen) */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRID = 6003 /* Frage bzw. Fragegruppe einer Umfrage */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRCODE = 6004 /* Art der Frage bzw. Fragegruppe */
  public static CDFR_GRUPPE = 1    /* Fragegruppe */
  public static CDFR_HINWEIS = 5    /* Hinweistext nur zur Anzeige (keine Eingabe möglich, kein Auswahlliste) */
  public static CDFR_BEGRUENDUNG = 10   /* Texteingabe für eine Begründung */
  public static CDFR_TEXT = 11   /* Textabfrage als Zusatzinformation */
  public static CDFR_AUSWAHL_VON = 20   /* Erster für Auswahllisten reservierter Bereich */
  public static CDFR_AUSWAHL = 20   /* Auswahlliste */
  public static CDFR_VEROEFFENTLICHEN = 25   /* Ja-/Nein-Auswahl für die Frage, ob die Umfrage im Internet veröffentlicht werden darf */
  public static CDFR_AUSWAHL_BIS = 29   /* Letzter für Auswahllisten reservierter Bereich */
  public static CDFR_KD_VON = 100  /* Beginn des für Kunden bezogene Codes reservierter Bereich */
  public static CDFR_ADR = 100  /* Adresse */
  public static CDFR_NEWSLETTER = 110  /* Newsletterbestellung */
  public static CDFR_MITGLIED = 120  /* Mitgliedschaft anmelden */
  public static CDFR_PROSPEKT_FREUNDE = 130  /* Prospekt für Freunde bestellen */
  public static CDFR_KD_BIS = 199  /* Ende des für Kunden bezogene Codes reservierter Bereich */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRAUSWAHLCODE = 6005 /* Zur Frage gehörende Auswahlmöglichkeiten (nur Codes 6100-6999 zulässig) */
  public static CDFRAUSW_MIN_CODE = 6100 /* Erster erlaubter Code */
  public static CDFRAUSW_MAX_CODE = 6999 /* Letzter erlaubter Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRSTATUS = 6006 /* Art wie Fragen verwendet werden können */
  public static CDFRS_AKTIV = 0    /* Die Frage ist aktiv, d.h. kann normal verwendet werden */
  public static CDFRS_GELOESCHT = 9    /* Die Frage ist gelöscht und kann nicht mehr neuen Umfragen zugeordnet werden */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRDARSTELLUNGSCODE = 6007 /* Art, wie die Frage dargestellt werden soll */
  public static CDFRDARST_UNSICHTBAR = 0    /* unsichtbar (nur für Fragegruppen erlaubt) */
  public static CDFRDARST_NORMAL = 1    /* Normale Darstellung */
  public static CDFRDARST_FETT = 2    /* Fettschrift */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRBEZUGSCODE = 6008 /* Bezug der Frage */
  public static CDFRBEZUG_KEIN = 0    /* Kein spezieller Bezug */
  public static CDFRBEZUG_STR = 1    /* Standardreise */
  public static CDFRBEZUG_LL = 5    /* Landleistung */
  public static CDFRBEZUG_FAHRER1 = 101  /* 1. Fahrer */
  public static CDFRBEZUG_REISELEITUNG = 105  /* Reiseleiter */

  /*--------------------------------------------------------------------------*/

  public static TAB_UMFASTATUS = 6009 /* Status der Antwort auf eine Umfrage */
  public static CDUMFAS_NICHT_GEPRUEFT = 0    /* Umfrageantwort wurde nicht geprüft */
  public static CDUMFAS_NICHT_VEROEFFENTLICHEN = 2    /* Kunde wünscht keine Veröffentlichung der Antworten */
  public static CDUMFAS_VEROEFFENTLICHEN_VON = 4    /* Erster für Umfragen, die ganz oder teilweise veröffentlicht werden dürfen, vorgesehener Code */
  public static CDUMFAS_OK_OHNE_KOMMENTAR = 4    /* Als Ok bestätigt, aber nur ohne Kommentare online stellen */
  public static CDUMFAS_OK = 5    /* Als Ok bestätigt */
  public static CDUMFAS_OK_ANTWORT = 6    /* Als Ok bestätigt, dem Kunden wurde direkt geantwortet */
  public static CDUMFAS_VEROEFFENTLICHEN_BIS = 7    /* Letzter für Umfragen, die ganz oder teilweise veröffentlicht werden dürfen, vorgesehener Code */
  public static CDUMFAS_GELOESCHT = 9    /* Antwort wurde gelöscht */

  /*--------------------------------------------------------------------------*/

  public static TAB_UMFATEXTCODE = 6010 /* Art der manuellen Textergänzungen */
  public static CDUMFAT_KEINE = 0    /* Keine Texteingaben */
  public static CDUMFAT_TEXT = 1    /* nur Textzugabe */
  public static CDUMFAT_GRUND = 2    /* Begründung einer Bewertung bzw. Auswahl */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRSTATISTIKCODE = 6011 /* Individueller, fakultativer Statistikcode für Auswertungen */
  public static CDFRBST_GESAMTBEWERTUNG = 9000 /* Frage zur Gesamtbewertung; kann bei guter Bewertung zu einer speziellen Abschlussseite führen */

  /*--------------------------------------------------------------------------*/

  public static TAB____UMFRAGE_BEREICHE___ = 6100 /* === Beginn der Umfrage-Codearten, welche für Codeartenbereiche verwendet werden === */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRAUSWAHL_VON = 6100 /* Erster für Frage-Auswahlantworten vorgesehene Codeart */
  public static CDFRAUSW_GEWICHTET_VON = 0    /* Erster für gewichtete Einträge vorgesehener Code */
  public static CDFRAUSW_GEWICHTET_BIS = 8999 /* Letzter für gewichtete Einträge vorgesehener Code */

  /*--------------------------------------------------------------------------*/

  public static TAB_FRAUSWAHL_BIS = 6199 /* Letzte für Frage-Auswahlantworten vorgesehene Codeart */

  /*--------------------------------------------------------------------------*/

  public static TAB____SPEZIAL___ = 9000 /* === Nicht näher definierte Codearten === */

  /*--------------------------------------------------------------------------*/

  public static TAB_FREMDCODES_VON = 9800 /* Erste für Codearten, die im Tourdata weder geführt noch verlinkt werden (stattdessen z.B. in einer sep. Internet-DB), reservierter Bereich */

  /*--------------------------------------------------------------------------*/

  public static TAB_FREMDCODES_BIS = 9999 /* Letzte für Codearten, die im Tourdata weder geführt noch verlinkt werden (stattdessen z.B. in einer sep. Internet-DB), reservierter Bereich */

  /*--------------------------------------------------------------------------*/
}
