import {NachrichtDto} from "./nachricht-dto";

export class Nachricht {
  className: string = 'Nachricht';

  id: string = '';
  einsatzId: string = '';
  datum: string = '';
  betreff: string = '';
  nachricht: string = '';
  status: number = 0;

  map(dto: NachrichtDto) {
    this.id = dto.id || '';
    this.einsatzId = dto.einsatzId || '';
    this.datum = dto.datum || '';
    this.betreff = dto.betreff || '';
    this.nachricht = dto.nachricht || '';
    this.status = dto.status || 0;
  }
}
