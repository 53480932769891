import {BenutzerDto} from "./benutzer-dto";

export class Benutzer {
  className: string = 'Benutzer';

  id: string = '';
  name: string = '';
  vorname: string = '';
  email: string = '';
  accessToken: string = '';
  refreshToken: string = '';
  isEntwickler: boolean = false;

  map(dto: BenutzerDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.vorname = dto.vorname;
    this.email = dto.email;
    this.isEntwickler = dto.isEntwickler;
  }
}
