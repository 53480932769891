import { Capacitor } from "@capacitor/core";

export class Environment {

  static isNativePlatform(){
    return Capacitor.isNativePlatform();
  }

  static isLocalhost() {
    const hostname = window.location.hostname;
    return hostname === 'localhost' || /^192\.168\.\d{1,3}\.\d{1,3}$/.test(hostname) || /^10\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname);
  }

  static isDarkMode() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  static isTestFlight() {
    return navigator.userAgent.includes('TestFlight');
  }
}
