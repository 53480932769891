import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatabaseService } from './services/database/database.service';

import * as SentryAngular from "@sentry/angular";
import * as Sentry from "@sentry/capacitor";
import { SharedModule } from './shared.module';
import { Environment } from './utils/environment/environment';

Sentry.init(
  {
    dsn: "https://a34db81040a847d3f914e74b131688f7@o1086244.ingest.us.sentry.io/4507898570539008",

    // Set your release version, such as "getsentry@1.0.0"
    release: "TourDriver@24.10.0",
    // Set your dist version, such as "1"
    dist: "1",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      SentryAngular.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],

    environment: (() => {
      if (Environment.isLocalhost()) {
        return 'development';
      }
      if (Environment.isTestFlight()) {
        return 'stage';
      }
      return 'production';
    })(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /*/^https:\/\/yourserver\.io\/api/*/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);




@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios' }),
    SharedModule,
    AppRoutingModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatabaseService,
    Storage,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler(),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [SentryAngular.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
