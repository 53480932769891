import { MenuFileDto } from "../menufile/menu-file-dto";
import { MenuDto } from "./menu-dto";

export class Menu {
  className = 'Menu'

  bezeichnung: string = '';
  parentId: string = '';
  id: string = '';
  mandant: string = '';
  mnCode: number = 0;
  mpCode: number = 0;
  sprachcode: number = 0;
  laufnr: number = 0;
  darstellungscode: number = 0;
  zusatzinfo: string = '';
  iconTyp: number = 0;
  inhaltBase64: string = '';
  iconBase64: string = '';

  //inhaltBase64: ArrayBuffer = new ArrayBuffer(0);
  //iconBase64: ArrayBuffer = new ArrayBuffer(0);
  subMenuInformation: Menu[] = []

  map(dto: MenuDto) {
    this.bezeichnung = dto.bezeichnung ?? '';
    this.parentId = dto.parentId ?? '';
    this.id = dto.id ?? '';
    this.mnCode = dto.mnCode ?? 0;
    this.mpCode = dto.mpCode ?? 0;
    this.sprachcode = dto.sprachcode ?? 0;
    this.laufnr = dto.laufnr ?? 0;
    this.darstellungscode = dto.darstellungscode ?? 0;
    this.zusatzinfo = dto.zusatzinfo ?? '';
    this.subMenuInformation = dto.subMenuInformation.map(x => {
      var menu = new Menu();
      menu.map(x);
      return menu;
    });
  }

  mapFile(dto: MenuFileDto){
    this.inhaltBase64 = dto.inhaltBase64 ?? '';
    this.iconBase64 = dto.iconBase64 ?? '';
    this.iconTyp = dto.iconTyp ?? 0;
  }
}
